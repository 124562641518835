import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RolesService } from "./../../../../services//roles.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-del-rol',
  templateUrl: './del-rol.component.html',
  styleUrls: ['./del-rol.component.css']
})
export class DelRolComponent implements OnInit {

  id: string = "";
  actividad: string = "";
  local_data;
  message: string = "¿Seguro que deseas continuar?";
  confirmButtonText = "Si";
  cancelButtonText = "No";
  loading: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<DelRolComponent>,
    public rolesService: RolesService,
    private _snackBar: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.local_data = this.data;
    this.id = this.local_data.data.id;

  }

  ngOnInit(): void {

  }


  onConfirmClick() {
    this.rolesService.delRol({ _id: this.id }).subscribe(response => {
      this.rolesService.rolSer$.emit();
      this.onClose();
      this.openSnackBar(response.status, 'ok');
    }, error => this.openSnackBar("Ha ocurrido un error", "Error"));
  }


  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onClose() {
    this.dialogRef.close();
  }

}
