<section class="ct-pro">
    <div class="titulo-row-b">
        <button mat-icon-button [routerLink]="['/dashboard']">
            <mat-icon>inventory</mat-icon>
        </button>
        Listado de Productos
    </div>
    <mat-divider></mat-divider>
    <mat-form-field class="mat-form-field" appearance="fill">
        <mat-label>Filtro</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Ejemplo: fresa" autocomplete="off" />
    </mat-form-field>

    <div class="ct-header">
        <div class="ct-model-header">
            <div></div>


            <div class="ct-model-nom">
                Producto
            </div>

            <div class="ct-model-vol">
                Volumen
            </div>

            <div></div>
            <div></div>

            <div class="ct-model-total">
                Venta MXN
            </div>

            <div *ngIf="celusd" class="ct-model-total_usd">
                Venta USD
            </div>



        </div>
    </div>



    <div class="table-container-r">
        <mat-accordion class="ct-row-producto">
            <div *ngFor="let env of dataPro | async | filter: searchText, let i = index;">


                <mat-expansion-panel class="ct-row-producto {{getColorl(i)}}" (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>


                        <!-- <mat-card class="mat-card-pre"> -->
                        <div class="ct-model">
                            <div class="ct-model-ima">
                                <app-ima-producto matTooltip="" [idproducto]="env._id_producto"></app-ima-producto>
                            </div>
                            <div class="ct-model-nom">
                                <span class="ct-lie">{{i+1 }}</span> <span class="idE"> {{ env.numparte }} </span><br>
                                {{ env.nombre }}
                            </div>
                            <div class="ct-model-vol">
                                {{ env.volumen | number: '1.2-2' }}
                            </div>
                            <div class="ct-model-unidad">
                                {{ env.unidad }}
                            </div>
                            <div class="ct-model-box">

                                <div *ngIf="boxGet(env.numparte)">
                                    <mat-icon matTooltip="{{msnMas}}">{{icoMas}}</mat-icon>
                                  </div>

                            </div>
                            <div class="ct-model-total">
                                {{ env.venta | number: '1.2-2' }}
                            </div>
                            <div *ngIf="celusd"
                                matTooltip="  {{ env.venta_uds | number: '1.2-2' }} USD X {{ env.cambio_uds }} USD =   {{ env.venta | number: '1.2-2' }} MXN"
                                class="ct-model-total_usd">
                                {{ env.venta_uds | number: '1.2-2' }}
                            </div>
                        </div>

                        <!-- </mat-card> -->
                    </mat-expansion-panel-header>


                    <div class="ct-det-pro">
                        <div class="table-container">
                            <app-pvd-producto [vYear]="vYear" [vD]="vD" [vH]="vH" [vCli]="idd" [idproducto]="env._id_producto">
                            </app-pvd-producto>
                        </div>
                        <div>
                            <app-pdv-producto-price-histo [idproducto]="env._id_producto" [idd]="idd"
                                [idcliente]="idepicor">
                            </app-pdv-producto-price-histo>
                        </div>
                    </div>

                </mat-expansion-panel>
            </div>
        </mat-accordion>
    </div>




    <!-- *ngIf="loading; else nodata" -->
</section>