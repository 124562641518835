<div class="conte-pro">


  <div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/dashboard']">
      <mat-icon>navigate_before</mat-icon>
    </button>
    Listado de Productos

  </div>
  <mat-divider></mat-divider>


  <div class="columna1">
    <mat-form-field>
      <mat-label>Filtro</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Frio Frut" #input autocomplete="off">

    </mat-form-field>
  </div>


  <div class="columna2">

    <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button (click)="onDialogAdd()">
      <mat-icon matListIcon>add</mat-icon> Registrar Nuevo
    </button>

  </div>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table-data mat-elevation-z8">

    <ng-container matColumnDef="numparte">
      <th mat-header-cell *matHeaderCellDef> Codigo EPICOR </th>
      <td class="s" mat-cell *matCellDef="let element"> {{element.numparte}} </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td class="s" mat-cell *matCellDef="let element"> {{element.nombre.toUpperCase()}} </td>
    </ng-container>

    <ng-container matColumnDef="descripcion">
      <th mat-header-cell *matHeaderCellDef> Descripcion </th>
      <td class="s" mat-cell *matCellDef="let element"> {{element.unidad.descripcion}} </td>
    </ng-container>


    <ng-container matColumnDef="Opciones">
      <th mat-header-cell *matHeaderCellDef> Opciones </th>
      <td class="s"  mat-cell *matCellDef="let element">

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">

          <button matTooltip="" matTooltipClass="example-tooltip-red" mat-menu-item
            (click)="onDialogUp(element._id,element.numparte, element.nombre, element.descripcion, element.presentacion,element.tipo_proyecto,element.imagen,element.unidad._id,element.canal,element.ubox, element.cbox, element.cbag, element.moneda)">
            <mat-icon>update</mat-icon>
            <span>Actualizar</span>
          </button>


          <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
            mat-menu-item (click)="onDialogDel(element._id)">
            <mat-icon>delete_forever</mat-icon>
            <span>Eliminar</span>
          </button>


        </mat-menu>
      </td>
    </ng-container>


    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <app-pro-price-hist [idPro]="element._id" [idProEp]="element.numparte" ></app-pro-price-hist>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>







  </table>


  <mat-paginator [length]="3" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>


<br>

<app-grafica-top10-productos></app-grafica-top10-productos>


</div>