import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DelClienteComponent } from 'src/app/components/customer/del-cliente/del-cliente.component';
import { AreaService } from 'src/app/services/area.service';
import { DialogData } from '../../task-table/task-up/task-up.component';

@Component({
  selector: 'app-del-task-scheme',
  templateUrl: './del-task-scheme.component.html',
  styleUrls: ['./del-task-scheme.component.css']
})
export class DelTaskSchemeComponent implements OnInit {
  id: string = '';
  actividad: string = '';
  local_data;
  message: string = '¿Seguro que deseas continuar?';
  confirmButtonText = 'Si';
  cancelButtonText = 'No';
  constructor(
    public dialogRef: MatDialogRef<DelClienteComponent>,
    public areaService: AreaService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.local_data = this.data;
    this.id = this.local_data.data.id;
  }

  ngOnInit() {}

  onConfirmClick() {
    this.areaService.delTaskShchemeMicro({ id: this.id }).subscribe(
      (response) => {
        this.onClose();
        this.openSnackBar(response.status, 'ok');
        this.dialogRef.close(true);
        this.areaService.oTask$.emit();
        this.areaService.cBeem$.emit(false);

      },
      (error) => this.openSnackBar('Ha ocurrido un error', 'Error')
    );
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
