import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EnviosService } from "../../../services/envios.service";
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-panel-admin-envios',
  templateUrl: './panel-admin-envios.component.html',
  styleUrls: ['./panel-admin-envios.component.css']
})
export class PanelAdminEnviosComponent implements OnInit {
  public searchText: any;
  ae: number;
  ag: number;
  constructor(
    private enviosService: EnviosService,
    public datepipe: DatePipe,

  ) { }
  dataSource: MatTableDataSource<null>;
  displayedColumns: string[] = ['ima', 'razon', 'fechas', 'fechall', 'estado', 'ci'];
  s4: boolean;
  gra4: boolean;
  aa: number;
  ab: number;
  ac: number;
  ad: number;
  acc: number;
  colspa: number;
  colspb: number;
  colspc: number;
  colspas: number;
  breakpoint: number;
  date: Date;
  selecm = new FormControl();

  wt:boolean=true;


  ngOnInit(): void {
    this.renderWie();
    this.getListadoActivoCri({ ops: "ENTREGADO" });

  }




  getListadoActivoCri(data) {
    this.s4 = true;
    this.wt=true;
    
    this.enviosService.getListadoActivoCri(data).subscribe((resp) => {
      this.dataSource = new MatTableDataSource(resp);

      

      if (resp.length > 0) {
        this.gra4 = true;
      } else {
        this.gra4 = false;
      }
      this.s4 = false;
      this.wt=false;
    });
  }


  updateAns(event) {
    let objq = {};
    const tipo = this.selecm.value;
    if (tipo === 2 || tipo === "") {
      objq = { ops: "" };
    } else {
      objq = { ops: "ENTREGADO" };
    }


    this.getListadoActivoCri(objq);
    


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    var resp: any[] = this.dataSource.filteredData;

  }
  renderWie() {
    this.breakpoint = window.innerWidth <= 600 ? 1 :11;
    this.colspas = window.innerWidth <= 600 ? 1 : 2;
    this.colspa = window.innerWidth <= 600 ? 1 : 2;
    this.colspb = window.innerWidth <= 600 ? 1 : 2;
    this.colspc = window.innerWidth <= 600 ? 1 : 5;
    this.aa = window.innerWidth <= 600 ? 1 : 30;
    this.ab = window.innerWidth <= 600 ? 1 : 16;
    this.ac = window.innerWidth <= 600 ? 1 : 2;
    this.ad = window.innerWidth <= 600 ? 1 : 2;
    this.ae = window.innerWidth <= 600 ? 1 : 7;
    this.ag = window.innerWidth <= 600 ? 1 : 3;
  }
  onResize(event) {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 11;
    this.colspas = window.innerWidth <= 600 ? 1 : 2;
    this.colspa = event.target.innerWidth <= 600 ? 1 : 2;
    this.colspb = event.target.innerWidth <= 600 ? 1 : 2;
    this.colspc = event.target.innerWidth <= 600 ? 1 : 5;
    this.aa = window.innerWidth <= 600 ? 1 : 30;
    this.ab = window.innerWidth <= 600 ? 1 : 16;
    this.ac = window.innerWidth <= 600 ? 1 : 2;
    this.ad = window.innerWidth <= 600 ? 1 : 2;
    this.ae = window.innerWidth <= 600 ? 1 : 7;
    this.ag = window.innerWidth <= 600 ? 1 : 3;
  }



  formatDate(fecha) {
    return fecha.substr(0,10);
  }

  formatDateFull(fecha) {
    this.date = new Date();
    let latest_date = this.datepipe.transform(fecha, 'yyyy-MM-dd HH:mm:ss');
    return latest_date;
  }

  formatRazonSocial(val) {
    var rzo: String;
    if (val.length > 80) {
      rzo = val.substr(0, 10) + "...";
    } else {
      rzo = val;
    }
    return rzo;
  }


}
