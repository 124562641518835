import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from "./../../../services/product.service";
import * as moment from 'moment';
@Component({
  selector: 'app-pro-price-hist',
  templateUrl: './pro-price-hist.component.html',
  styleUrls: ['./pro-price-hist.component.css']
})
export class ProPriceHistComponent implements OnInit {
  @Input() idPro: string;
  @Input() idProEp: string;
  data: any;
  show: boolean;
  listRango: any[] = [];
  tVol: any;
  tTol: any;
  tUni: any;
  tTolu: any;
  datar: any;
  shows: boolean;
  showw: boolean;
  icoMas: string;
  msnMas: string;
  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    var y = moment().format('YYYY');
    this.setList();
    this.porPreciHisto(this.idPro, y);
    this.porPreciHistoGlo(this.idPro);
    this.porPreciHistoYear(this.idPro, y);
  }

  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }

  updateAn(vYear) {
    this.porPreciHisto(this.idPro, vYear);
    this.porPreciHistoYear(this.idPro, vYear);
  }
  porPreciHisto(_id_producto, vYear) {
    this.productService.porPreciHisto({ _id_producto: _id_producto, vYear: vYear }).subscribe(
      resp => {
        const numRe = resp.length;
        if (numRe > 0) {
          this.data = resp;
          this.show = true;
        } else {
          this.show = false;
        }
      });
  }

  porPreciHistoGlo(_id_producto) {
    this.productService.porPreciHistoGlo({ _id_producto: _id_producto }).subscribe(
      resp => {
        const numRe = resp.length;
        if (numRe > 0) {
          this.datar = resp;
          this.showw = true;
        } else {
          this.showw = false;
        }
      });
  }

  porPreciHistoYear(_id_producto, vYear) {
    this.productService.porPreciHistoYear({ _id_producto: _id_producto, vYear: vYear }).subscribe(
      resp => {
        if (resp) {
          this.tVol = resp.vol;
          this.tTol = resp.tol;
          this.tTolu = resp.tolu;
          this.tUni = resp.unidad;
        } else {
          this.tVol = 0;
          this.tTol = 0;
          this.tTolu = 0;
          this.tUni = 0;
        }
      });
  }


  boxGet(id) {



    const numValor = id.substring(3, 6);
  
    if (numValor==="593") {
      this.icoMas="shopping_bag";
      this.msnMas="BOLSA EMPAQUE";
      return true;
    } else if (numValor==="503") { 
      this.icoMas="archive";
      this.msnMas="CAJA MASTER";
      return true;
    }else{
      this.icoMas="ac_unit";
      this.msnMas="VOL";
    }

  }

}
