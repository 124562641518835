<div class="conte-pro">
    <div class="titulo-row-b">
        <button mat-icon-button [routerLink]="['/presupuesto']">
            <mat-icon>navigate_before</mat-icon>
        </button>Unidades
    </div>
    <mat-divider></mat-divider>
    <div class="wrapper" *ngIf="loading;else content">
        <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
    </div>
    <ng-template #content>
        <div class="col1">
            <mat-form-field>
                <mat-label>Filtro</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ejemplo Libras" #input autocomplete="off">
            </mat-form-field>
        </div>
        <div class="col2">
            <button class="btn mat-list-item" color="primary" mat-raised-button mat-button (click)="onDialogAdd()">
                <mat-icon matListIcon>add</mat-icon> Registrar Nuevo
            </button>
        </div>
        <table mat-table [dataSource]="dataResult" class="mat-elevation-z8" class="table-vieg">
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef> Fecha de Registro </th>
                <td mat-cell *matCellDef="let element"> {{formatDate(element.createdAt)}} </td>
            </ng-container>
            <ng-container matColumnDef="unidad">
                <th mat-header-cell *matHeaderCellDef> Unidad </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>
            <ng-container matColumnDef="acro">
                <th mat-header-cell *matHeaderCellDef> Acronimo </th>
                <td mat-cell *matCellDef="let element"> {{element.acro}} </td>
            </ng-container>
            <ng-container matColumnDef="across">
                <th mat-header-cell *matHeaderCellDef> Acronimo </th>
                <td mat-cell *matCellDef="let element"> {{element.across}} </td>
            </ng-container>
            <ng-container matColumnDef="op">
                <th mat-header-cell *matHeaderCellDef> Opciones </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button matTooltip="¿Seguro que deseas borrar este proyecto?"
                            matTooltipClass="example-tooltip-red" mat-menu-item (click)="onDelAsig(element._id)">
                            <mat-icon>delete_forever</mat-icon>
                            <span>Eliminar</span>
                        </button>

                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [length]="3" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </ng-template>
</div>