


<div class="container-sms-list ">
 

    <div class="des">

        <div class="block-sms">
            <div class="g-box">
                <div class="s-txt">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi perspiciatis magnam possimus nulla
                    quaerat ullam non. Cumque sint placeat, natus beatae dignissimos modi, facilis libero explicabo
                    ratione
                    pariatur distinctio quaerat.
                </div>
            </div>
            <div class="g-box-dem">
                <div class="s-ima">
                    <app-ima-user-gantt [vUser]="'6066596961607218a9a96240'"></app-ima-user-gantt>
                </div>
                <div class="s-nom">Jose Ignacio Espinoza</div>
                <div class="s-fecha">2020-11-12 09:49 am</div>
            </div>
        </div>
    </div>

    <div class="rem">
        <div class="block-sms rem">
            <div class="g-boxr">
                <div class="s-txt">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi perspiciatis magnam possimus nulla
                    quaerat ullam non. Cumque sint placeat, natus beatae dignissimos modi, facilis libero explicabo
                    ratione
                    pariatur distinctio quaerat.
                </div>
            </div>
            <div class="g-box-rem">

                <div class="s-nomr">Jose Ignacio Espinoza</div>
                <div class="s-fechar">2020-11-12 09:49 am</div>
                <div class="s-ima">
                    <app-ima-user-gantt [vUser]="'6066596961607218a9a96240'"></app-ima-user-gantt>
                </div>


            </div>
        </div>

    </div>

    <div class="des">

        <div class="block-sms">
            <div class="g-box">
                <div class="s-txt">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi perspiciatis magnam possimus nulla
                    quaerat ullam non. Cumque sint placeat, natus beatae dignissimos modi, facilis libero explicabo
                    ratione
                    pariatur distinctio quaerat.
                </div>
            </div>
            <div class="g-box-dem">
                <div class="s-ima">
                    <app-ima-user-gantt [vUser]="'6066596961607218a9a96240'"></app-ima-user-gantt>
                </div>
                <div class="s-nom">Jose Ignacio Espinoza</div>
                <div class="s-fecha">2020-11-12 09:49 am</div>
            </div>
        </div>
    </div>

    <div class="rem">
        <div class="block-sms rem">
            <div class="g-boxr">
                <div class="s-txt">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi perspiciatis magnam possimus nulla
                    quaerat ullam non. Cumque sint placeat, natus beatae dignissimos modi, facilis libero explicabo
                    ratione
                    pariatur distinctio quaerat.
                </div>
            </div>
            <div class="g-box-rem">

                <div class="s-nomr">Jose Ignacio Espinoza</div>
                <div class="s-fechar">2020-11-12 09:49 am</div>
                <div class="s-ima">
                    <app-ima-user-gantt [vUser]="'6066596961607218a9a96240'"></app-ima-user-gantt>
                </div>


            </div>
        </div>

    </div>

    <div class="des">

        <div class="block-sms">
            <div class="g-box">
                <div class="s-txt">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi perspiciatis magnam possimus nulla
                    quaerat ullam non. Cumque sint placeat, natus beatae dignissimos modi, facilis libero explicabo
                    ratione
                    pariatur distinctio quaerat.
                </div>
            </div>
            <div class="g-box-dem">
                <div class="s-ima">
                    <app-ima-user-gantt [vUser]="'6066596961607218a9a96240'"></app-ima-user-gantt>
                </div>
                <div class="s-nom">Jose Ignacio Espinoza</div>
                <div class="s-fecha">2020-11-12 09:49 am</div>
            </div>
        </div>
    </div>

    <div class="rem">
        <div class="block-sms rem">
            <div class="g-boxr">
                <div class="s-txt">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi perspiciatis magnam possimus nulla
                    quaerat ullam non. Cumque sint placeat, natus beatae dignissimos modi, facilis libero explicabo
                    ratione
                    pariatur distinctio quaerat.
                </div>
            </div>
            <div class="g-box-rem">

                <div class="s-nomr">Jose Ignacio Espinoza</div>
                <div class="s-fechar">2020-11-12 09:49 am</div>
                <div class="s-ima">
                    <app-ima-user-gantt [vUser]="'6066596961607218a9a96240'"></app-ima-user-gantt>
                </div>


            </div>
        </div>

    </div>
</div>    