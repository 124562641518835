import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddRolComponent } from "./add-rol/add-rol.component"
import { DelRolComponent } from "./del-rol/del-rol.component"
import { RolesService } from "../../../services/roles.service";
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  dataRoles: any;
  displayedColumns: string[] = ['nombre', 'fecha', 'opciones'];
  dataSource = new MatTableDataSource<any>();
  date: Date;

  constructor(
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private rolesService: RolesService,

  ) { }


  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.resfresa();
    this.getAllRoles();

  }

  resfresa() {

    this.rolesService.rolSer$.subscribe(() => {
      this.getAllRoles();
    });

  }


  onDialogAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    this.dialog.open(AddRolComponent, dialogConfig);
  }


  getAllRoles() {

    this.rolesService.getAllRoles()
      .subscribe(
        resp => {
          this.dataSource.data = resp;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  formatDate(fecha) {
    // this.date = new Date();
    // let latest_date = this.datepipe.transform(fecha, 'yyyy-MM-dd HH:mm:ss');
    // return latest_date;
    return fecha.substr(0,10);
  }

  onDialogDel(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "40%";
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(DelRolComponent, dialogConfig);
  }



}
