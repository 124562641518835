<div class="conte-pro">
        <div class="titulo-row-b">
          <button mat-icon-button [routerLink]="['/dashboard']">
            <mat-icon>navigate_before</mat-icon>
          </button>
          Listado Usuarios
        </div>
        <mat-divider></mat-divider>
        <div class="columna1">
          <mat-form-field>
            <mat-label>Filtro</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input autocomplete="off">
          </mat-form-field>
        </div>
      
        <div class="columna2">
          <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button (click)="onDialogAdd()">
            <mat-icon matListIcon>add</mat-icon> Registrar Nuevo
          </button>
        </div>
      
        <table mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">
      
          <ng-container matColumnDef="cod_epicor">
            <th mat-header-cell *matHeaderCellDef> Codigo </th>
            <td mat-cell *matCellDef="let element"> {{element.cod_epicor}} </td>
          </ng-container>
      
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
          </ng-container>
      
          <ng-container matColumnDef="apellido">
            <th mat-header-cell *matHeaderCellDef> Apellido </th>
            <td mat-cell *matCellDef="let element"> {{element.apellido}} </td>
          </ng-container>
      
    
          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element">
      
              <mat-slide-toggle [checked]="element.estado == 1 ? 'true' : 'false'" (change)="changeEstado($event,element._id)"
                color="primary"></mat-slide-toggle>
      
            </td>
          </ng-container>
      
      
          <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
      
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
      
      
                  <button matTooltip="" matTooltipClass="example-tooltip-red" mat-menu-item (click)="getGenereClave(element._id)">
                      <mat-icon>password</mat-icon>
                      <span>Generar Clave Encriptada</span>
                    </button>
      
    
      
                <button matTooltip="" matTooltipClass="example-tooltip-red" mat-menu-item
                  (click)="onDialogUp(element._id,element.cod_epicor, element.nombre, element.apellido, element.telefono,element.email,element.clave,element.imagen,element.fecha_n,element.rol)">
                  <mat-icon>update</mat-icon>
                  <span>Actualizar</span>
                </button>
      
      
                <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
                  mat-menu-item (click)="onDialogDel(element._id)">
                  <mat-icon>delete_forever</mat-icon>
                  <span>Eliminar</span>
                </button>
      
      
              </mat-menu>
            </td>
          </ng-container>
      
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
      
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No hay producto con ese criterio "{{input.value}}"</td>
          </tr>
        </table>
      
      
        <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      
      
      
      </div>