<div class="tbq sec">

  <button (mouseup)="copyData()" mat-icon-button>
    <mat-icon [ngClass]="cio=='check_circle' ? 'material-icons done' : 'material-icons color_white'">{{cio}}</mat-icon>
  </button>
  Venta acumulada, productos.
</div>
<mat-divider></mat-divider>

<mat-form-field class="mat-form-field-filtro" appearance="fill">
  <mat-label>Filtro</mat-label>
  <input matInput (keyup)="applyFilters($event)" placeholder="Ejemplo: GREEN SMOOTHIE" autocomplete="off" />
</mat-form-field>

<div *ngIf="show; else wnoda">
  <div *ngIf="ss1; else rsnoda">
    <div class="table-containera">

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z0">

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>PRODUCTO</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.id_epicor }}-{{ element.nombre }}">
          <span class="ct-nom-pro">  {{ getNombre(element.nombre) }} </span> 
          </td>
          <td mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>

        <ng-container matColumnDef="pres_vol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VOL PRE</th>
          <td mat-cell *matCellDef="let element">
            {{ element.pres_vol | number: "1.0-2" }}
            <span class="ct-di">{{ element.pres_uni }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ pres_vol | number: "1.0-2" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="oc_vol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VOL OC</th>
          <td mat-cell *matCellDef="let element">
            {{ element.oc_vol | number: "1.0-2" }}
            <span class="ct-di">{{ element.oc_uni }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ oc_vol | number: "1.0-2" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="venta_vol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VOL VEN</th>
          <td mat-cell *matCellDef="let element">
            {{ element.venta_vol | number: "1.0-2" }}
            <span class="ct-di">{{ element.venta_uni }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ venta_vol | number: "1.0-2" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="pres_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>PRE</th>
          <td mat-cell *matCellDef="let element">
            {{ element.pres_tol | number: "1.2-2" }}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ pres_tol | number: "1.2-2" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="oc_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>OC</th>
          <td mat-cell *matCellDef="let element">
            {{ element.oc_tol | number: "1.2-2" }}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ oc_tol | number: "1.2-2" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="venta_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VEN</th>
          <td mat-cell *matCellDef="let element">
            {{ element.venta_tol | number: "1.2-2" }}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ venta_tol | number: "1.2-2" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="indi">
          <th mat-header-cell *matHeaderCellDef>%</th>

          <td mat-cell *matCellDef="let element">
            <app-scg-indicador [vVen]="element.venta_tol" [vSm]="'sm'" [vPre]="element.pres_tol"></app-scg-indicador>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail"
              [@detailExpand]=" element == expandedElement ? 'expanded' : 'collapsed'">

              <mat-tab-group>
                <mat-tab label="Acumulado General">
                  <app-scg-acum-pro-g [vPro]="element._id_producto" [vYear]="vYear" [vCh]="vCh"></app-scg-acum-pro-g>
                </mat-tab>
                <mat-tab label="Acumulado Cliente">
                  <app-scg-table-pro-x-cli [vPro]="element._id_producto" [vYear]="vYear" [vCh]="vCh">
                  </app-scg-table-pro-x-cli>
                </mat-tab>

              </mat-tab-group>






            </div>
          </td>
        </ng-container>

        <tr class="table-h" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>

    </div>
  </div>

  <ng-template #rsnoda>
    <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]="">
    </app-info-data>
  </ng-template>
</div>
<ng-template #wnoda>
  <div class="ct-cadew">
    <ngx-skeleton-loader count="9" [theme]="{
        'border-radius': '5px',
        width: '900px',
        height: '30px',
        'background-color': '#daddd8'
      }">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
<div class="info-text">Listado de productos solo con ventas, para ver aquellos productos presupuestados sin venta puedes
  ir a la sección <a target="_blank" href="./budget">presupuesto</a></div>