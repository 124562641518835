<div class="table-data">



    <mat-tab-group>
        <mat-tab label="PRODUCTOS">


            <mat-divider></mat-divider>

            <div class="table-container special">
                <table class="table_data" mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="pro">
                        <th mat-header-cell *matHeaderCellDef> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element._id_producto.nombre}} </td>
                        <td mat-footer-cell *matFooterCellDef> Total </td>
                    </ng-container>

                    <ng-container matColumnDef="qty">
                        <th mat-header-cell *matHeaderCellDef> Volumen </th>
                        <td mat-cell *matCellDef="let element"> {{formatNumber(element.volumen)}} </td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotalgb() | number}} </td>
                    </ng-container>
                    <ng-container matColumnDef="uni">
                        <th mat-header-cell *matHeaderCellDef> Unidad </th>
                        <td mat-cell *matCellDef="let element"> {{element._id_unidad.across}} </td>
                        <td mat-footer-cell *matFooterCellDef>  </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
            </div>

        </mat-tab>
        <mat-tab label="OBSERVACIONES">
            <div class="table-container special">
                <table class="table_data" mat-table [dataSource]="dataSources">
                    <ng-container matColumnDef="pro">
                        <th mat-header-cell *matHeaderCellDef> Fecha </th>
                        <td mat-cell *matCellDef="let element"> {{formatDate(element.createdAt)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="qty">
                        <th mat-header-cell *matHeaderCellDef> Comentario </th>
                        <td mat-cell *matCellDef="let element"> {{element.ob.toUpperCase()}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnss"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnss;"></tr>
                </table>
            </div>

        </mat-tab>

    </mat-tab-group>






</div>