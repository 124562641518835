import {SelectionModel} from '@angular/cdk/collections';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { CustomerService } from "./../../../services/customer.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {TraslProSinAgrupacionComponent } from "./../trasl-pro-sin-agrupacion/trasl-pro-sin-agrupacion.component";
import { MatSort } from '@angular/material/sort';

export interface PeriodicElement {
  ima: string;
  cod: number;
  nombre: number;
}

@Component({
  selector: 'app-ch-asignador-pro',
  templateUrl: './ch-asignador-pro.component.html',
  styleUrls: ['./ch-asignador-pro.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ChAsignadorProComponent implements OnInit {
 
  expandedElement: [PeriodicElement] | false;
  displayedColumns: string[] = ['select', 'ima', 'cod','fechar', 'nombre','listCli' ];
  dataSource = new MatTableDataSource<PeriodicElement>();
  selection = new SelectionModel<PeriodicElement>(true, []);
  ctpb: boolean;
  showp: boolean;
  loadingp: boolean;
  btt: boolean;
  bttn: number;
  removable = true;

  constructor(
    private customerService: CustomerService,
    private dialog: MatDialog,


  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.customerService.listProSn$.subscribe((valor) => {
      this.loadingp = true;
      this.getCustChannelAllp();
    });
    this.getCustChannelAllp(); 
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getCustChannelAllp() {
    this.customerService.getCustChannelAllp({  }).subscribe(resp => {
      this.ctpb = true;
      if (resp.length > 0) {
        this.showp = true;
      } else {
        this.showp = false;
      }
      this.dataSource.data = resp;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loadingp = false;
      this.selection.clear();
    });
  }

  onDialogTras() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.data = {vPro:this.selection.selected} ;
    this.dialog.open(TraslProSinAgrupacionComponent, dialogConfig);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    if (this.selection.selected.length > 0) {
      this.btt = true;
      this.bttn = this.selection.selected.length;
    } else {
      this.btt = false;
      this.bttn = 0;
      this.selection.clear();
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  remove(vCli): void {
    const index = this.selection.selected.indexOf(vCli);
    if (index >= 0) {
      this.selection.selected.splice(index, 1);
    }
    if (this.selection.selected.length == 0) {
      this.selection.clear();
    }
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.cod + 1}`;
  }
}
