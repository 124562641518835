import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class AssignedCustomerService {

  private URL = GlobalVariable.BASE_API_URL;
  public clienteAsignacionSer$ = new EventEmitter<any>();
  constructor(private http: HttpClient, private router: Router) {}

  asignarCliente(cliente) {
    return this.http.post<any>(this.URL + 'asignarCliente', cliente);
  }

  getAllClienteAsignado(id) {
    return this.http.post<any>(this.URL + 'getAllClienteAsignado', id);
  }

  delAsignacionCliente(id) {
    return this.http.post<any>(this.URL + 'delAsignacionCliente', id);
  }

  getCountClienteId() {
    return this.http.get<any>(this.URL + 'getCountClienteId');
  }

  getClienteIdVendedor(id) {
    return this.http.post<any>(this.URL + 'getClienteIdVendedor', id);
  }

  delAsignacionMaster(data) {
    return this.http.post<any>(this.URL + 'delAsignacionMaster', data);
  }

  getAllClienteAsignado_impli() {
    return this.http.get<any>(this.URL + 'getAllClienteAsignado_impli');
  }


  getAllClienteAsignadoMes(data) {
    return this.http.post<any>(this.URL + 'getAllClienteAsignadoMes', data);
  }

  uptadeAsigTras(data) {
    return this.http.post<any>(this.URL + 'uptadeAsigTras', data);
  }


  
}
