import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VenSemAdminService } from "../../../../services/sales/ven-sem-admin.service";
@Component({
  selector: 'app-per-cli-cvp',
  templateUrl: './per-cli-cvp.component.html',
  styleUrls: ['./per-cli-cvp.component.css']
})
export class PerCliCvpComponent implements OnInit {

  @Input() vCliente: number;
  @Input() vYear: number;
  @Input() vD: Date;
  @Input() vH: Date;
  @Input() vSem: string;

  loading: boolean = false;
  dataSemYear = new BehaviorSubject<any>(null);
  arrayWait = [1, 2, 3, 4, 5, 6];

  graf$: Observable<any[]>;
  vGrafica: any;
  constructor(
    private venSemAdminService: VenSemAdminService
  ) { }

  ngOnInit(): void {
    this.getYearPreVenSem(this.vCliente, this.vYear, this.vD, this.vH , this.vSem);
  }

  getYearPreVenSem(vCliente, vYear, vD, vH,vS) {
    this.venSemAdminService.getYearPreVenSem({ _id_cliente: vCliente, yeara: vYear, d:vD, h:vH, vs:vS }).subscribe(resp => {      
      this.dataSemYear.next(resp);    
      this.loading=true;
      let muestra = resp.map((dg) => {
        let obj = {
          name: String(dg.year),
          value: dg.vVa.tVenSem,
        }
        return obj;
      });
      this.venSemAdminService.aadGraf(muestra);
    });
  }

  getVen(value) {
    var v;
    if (value) {
      v = value;
    } else {
      v = Number(0);
    }
    return v;
  }


  getPre(value) {
    var valueGet = value[0];
    var v;
    if (valueGet) {
      v = valueGet;
    } else {
      v = 0;
    }
    return v;
  }

}
