import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from "./../../../services/product.service";

@Component({
  selector: 'app-pro-price-hist-gra',
  templateUrl: './pro-price-hist-gra.component.html',
  styleUrls: ['./pro-price-hist-gra.component.css']
})
export class ProPriceHistGraComponent implements OnInit {
  @Input() idPro: string;
  @Input() idCli: string;
  @Input() idUni: string;
  multi: any[];
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = false;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = false;
  colorScheme = {
    domain: ['#e6a927', '#34ad15']
  };
  autoScale = true;
  showGra: boolean;
  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.porPreciHistoGra(this.idPro, this.idCli, this.idUni);
  }

  porPreciHistoGra(_id_producto, _id_cliente, _id_unidad) {
      this.productService.porPreciHistoGra({ _id_producto: _id_producto, _id_cliente: _id_cliente, _id_unidad:_id_unidad }).subscribe(
      resp => {
        this.showGra = true;
        this.multi=resp;
      });
  }

}
