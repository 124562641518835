import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SLCEspcificoComponent } from './.././../s-l-c-espcifico/s-l-c-espcifico.component';
import { channelGroupProductServices } from '../../../../../services/sales/channel-group-product.services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VenMesAdminService } from '../../../../../services/sales/ven-mes-admin.service';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { ClipboardService } from 'src/app/services/clipboard.service';

@Component({
  selector: 'app-scg-table-pro',
  templateUrl: './scg-table-pro.component.html',
  styleUrls: ['./scg-table-pro.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed, void',
        style({ height: '0px', minHeight: '0', display: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ScgTableProComponent implements OnInit {
  expandedElement: PeriodicElement | null;
  @Input() vCh: string;
  @Input() vYear: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    // 'id_epicor',
    'nombre',
    'pres_vol',
    'oc_vol',
    'venta_vol',
    'pres_tol',
    'oc_tol',
    'venta_tol',
    'indi',
  ];
  ss1: boolean;
  show: boolean;
  venta_tol: any;
  pres_tol: any;
  venta_vol: any;
  pres_vol: any;
  pres_vols: any;
  oc_tol: any;
  oc_vol: any;
  cioa: boolean;
  cio: any;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  constructor(
    private _channelGroupProductServices: channelGroupProductServices,
    private clipboardService: ClipboardService,
    private venMesAdminService: VenMesAdminService
  ) {}

  applyFilters(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnChanges(changes: any) {
    this.show = false;
    const nvYear = changes.vYear.currentValue;
    this.sgcTablePro(this.vCh, String(nvYear));
  }

  ngOnInit() {
    this.cio = 'file_copy';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.show = false;
    // this.acuCliGenGrupoTable(this.vCh, this.vYear);
  }
  sgcTablePro(vCh, vYear) {
    this._channelGroupProductServices
      .sgcTablePro({ vCh: vCh, vYear: vYear })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }
        this.show = true;

        this.dataSource.data = resp;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.venta_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_tol;
        }, 0);

        this.pres_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_tol;
        }, 0);

        this.oc_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_tol;
        }, 0);

        this.venta_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_vol;
        }, 0);

        this.pres_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_vol;
        }, 0);

        this.oc_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_vol;
        }, 0);
      });
  }

  async venCSVScgTableAcumPro(vCh, vYear) {
    let respuesta;
    await this.venMesAdminService
      .venCSVScgTableAcumPro({ vCh: vCh, vYear: vYear })
      .toPromise()
      .then((response) => {
        respuesta = response;
      })
      .catch((e) => console.error(e));
    return respuesta;
  }

  getNombre(nombre) {
    const numNombe = nombre.length;

    // if (numNombe >= 20) {
    //   return nombre.substring(0, 20) + ' ...';
    // }

    return nombre;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async copyData() {
    this.getChangeIco('hourglass_empty');
    let valor = await this.venCSVScgTableAcumPro(this.vCh, this.vYear);



    let valorPro = valor.map((val) => val.prod);
    let titlePro = Object.keys(valorPro[0][0]);
    let title = Object.keys(valor[0]);

    const result = this.clipboardService.copyDataLeveThre(
      valor,
      valorPro,
      title,
      titlePro
    );

    navigator.clipboard.writeText(result);
    this.getChangeIco('check_circle');
    this.cioa = true;
  }

  getChangeIco(ico) {
    this.cio = ico;
    if (ico === 'check_circle') {
      setTimeout(() => {
        this.cio = 'file_copy';
      }, 6000);
    }
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
