import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UnidadesService } from '../../../services/unidades.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';



@Component({
    selector: 'app-add-unidades',
    templateUrl: './add-unidades.component.html',
    styleUrls: ['./add-unidades.component.css'],
})

export class AddUnidadesComponent implements OnInit {
    form: FormGroup;
    newUnidad = {}

    constructor(
        private unidadesService: UnidadesService,
        private _snackBar: MatSnackBar,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<AddUnidadesComponent>,

    ) { }



    ngOnInit() {
        this.crearForm();
    }


    add() {

        if (this.form.valid) {
            const nombre = this.form.get('nombre').value;
            const acro = this.form.get('acro').value;
            const across = this.form.get('across').value;

            this.newUnidad = {
                nombre: nombre,
                acro: acro,
                across: across,
            }

            this.unidadesService.registroUnidades(this.newUnidad)
                .subscribe(
                    res => {
                        this.openSnackBar(res.status, 'ok');
                        this.unidadesService.unidadesSer$.emit();
                        this.onClose();
                    });
        }
    }

    crearForm() {
        this.form = this.fb.group({
            nombre: ['', Validators.required],
            acro: ['', Validators.required],
            across: ['', Validators.required],
        });
    }


    openSnackBar(message: string, tipo: string) {
        this._snackBar.open(message, '', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: [tipo]
        });
    }

    onClose() {
        this.dialogRef.close();
    }
}
