<div class="titulo-row-b sec">
  <button mat-icon-button [routerLink]="['/dashboard']">
    <mat-icon>list</mat-icon>
  </button>
  Venta Acumulada Clientes
</div>

<mat-divider></mat-divider>

<div *ngIf="show;else wnoda">
  <div *ngIf="ss1;else rsnoda">
    <div class="table-containera">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z0">

        <ng-container matColumnDef="id_epicor">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <div class="ct-ima-con" matTooltip="{{element.id_epicor}}">
              <app-ima-cliente [idcliente]="element.id_epicor"></app-ima-cliente>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="razon_social">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> CLIENTE </th>
          <td mat-cell *matCellDef="let element" matTooltip="{{element.razon_social}}">
            {{getNombre(element.razon_social)}} <br>

          </td>
          <td mat-footer-cell *matFooterCellDef> Total </td>

        </ng-container>

    
        <ng-container matColumnDef="pres_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> PRE </th>
          <td mat-cell *matCellDef="let element"> {{element.pres_tol | number: '1.2-2'}}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{pres_tol | number: '1.2-2'}} </td>

        </ng-container>

        <ng-container matColumnDef="oc_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> OC </th>
          <td mat-cell *matCellDef="let element"> {{element.oc_tol | number: '1.2-2'}}
            <span class="ct-di">{{ element.oc_uni_m}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{oc_tol | number: '1.2-2'}} </td>

        </ng-container>

        <ng-container matColumnDef="venta_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> VEN </th>
          <td mat-cell *matCellDef="let element"> {{element.venta_tol | number: '1.2-2'}}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{venta_tol | number: '1.2-2'}} </td>

        </ng-container>


        <ng-container matColumnDef="indi">

          <th mat-header-cell *matHeaderCellDef> % </th>

          <td mat-cell *matCellDef="let element">

            <app-scg-indicador [vVen]="element.venta_tol" [vSm]="'sm'" [vPre]="element.pres_tol"></app-scg-indicador>

          </td>
          <td mat-footer-cell *matFooterCellDef> </td>

        </ng-container>


        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">


              <mat-tab-group>
                <mat-tab label="Productos">
                  <app-tbl-pro-cli [vCli]="element._id_cliente" [vCh]="vCh" [vYear]="vYear"></app-tbl-pro-cli>
                </mat-tab>
                <mat-tab label="Acumulado Particular">
                  <ng-template matTabContent>
                    <app-scg-grafica-cli-mxn [vCli]="element.id_epicor" [vYear]="vYear" [vCh]="vCh">
                    </app-scg-grafica-cli-mxn>

                    <app-scg-grafica-cli-year-vol [vCli]="element._id_cliente" [vYear]="vYear" [vCh]="vCh">
                    </app-scg-grafica-cli-year-vol>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>








            </div>
          </td>
        </ng-container>

        <tr class="table-h" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

      </table>
    </div>


  </div>


  <ng-template #rsnoda>

    <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]=""> </app-info-data>

  </ng-template>

 
</div>



<ng-template #wnoda>

  <div class="ct-cadew">
    <ngx-skeleton-loader count="9" [theme]="{ 
            'border-radius': '5px', 
            'width': '900px',
            'height': '30px',
            'background-color': '#daddd8'
        }">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
