import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VenMesAdminService } from '../../../../services/sales/ven-mes-admin.service';

@Component({
  selector: 'app-per-cli-cpv-mes',
  templateUrl: './per-cli-cpv-mes.component.html',
  styleUrls: [
    './../../panel-venta-semana-admin-detalle/per-cli-cvp/per-cli-cvp.component.css',
  ],
})
export class PerCliCpvMesComponent implements OnInit {
  @Input() vCliente: string;
  @Input() vYear: number;
  @Input() vMes: number;

  loading: boolean = false;
  dataSemYear = new BehaviorSubject<any>(null);
  arrayWait = [1, 2, 3, 4, 5, 6];

  graf$: Observable<any[]>;
  vGrafica: any;
  constructor(private venMesAdminService: VenMesAdminService) {}

  ngOnInit(): void {
    this.getYearPreVenMes(this.vCliente, this.vYear, this.vMes);
  }

  getYearPreVenMes(vCliente, vYear, vMes) {
    this.venMesAdminService
      .getYearPreVenMes({ _id_cliente: vCliente, yeara: vYear, mes: vMes })
      .subscribe((resp) => {
        this.dataSemYear.next(resp);

        this.loading = true;
        let muestra = resp.map((dg) => {
          let obj;

          let pre = dg.vVa.tPreSem[0] ? dg.vVa.tPreSem[0] : 0;
          let ven = dg.vVa.tVenSem ? dg.vVa.tVenSem : 0;

          obj = {
            name: String(dg.year) + '-' + vMes,
            series: [
              {
                name: 'PRE',
                value: pre,
              },
              {
                name: 'VEN',
                value: ven,
              },
            ],
          };

          return obj;
        });

        muestra.sort((a, b) => {
          let da = a.name.replace('-', '');
          let db = b.name.replace('-', '');

          return da - db;
        });

        this.venMesAdminService.aadGraf(muestra);
      });
  }

  getVen(value) {
    var v;
    if (value) {
      v = value;
    } else {
      v = Number(0);
    }
    return v;
  }

  getPre(value) {
    var valueGet = value[0];
    var v;
    if (valueGet) {
      v = valueGet;
    } else {
      v = 0;
    }
    return v;
  }
}
