import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { CustomerService } from "../../services/customer.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { AddClienteComponent } from "./add-cliente/add-cliente.component";
import { DelClienteComponent } from "./del-cliente/del-cliente.component";
import { UpClienteComponent } from "./up-cliente/up-cliente.component";
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DirClienteComponent } from "./dir-cliente/dir-cliente.component";
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as moment from 'moment';
import { AcuCliGComponent } from "../../components/sales/panel-venta-mes-admin-detalle/acu-cli-g/acu-cli-g.component";

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomerComponent implements OnInit {
  @ViewChild(AcuCliGComponent) _acuCliGComponent: AcuCliGComponent;
  expandedElement: PeriodicElement | null;
  displayedColumns: string[] = ['ima', 'cod', 'razon_social', 'estado', 'opciones'];
  dataSource = new MatTableDataSource<any>();
  aFormGroup: FormGroup;
  fFormGroup: FormGroup;
  photoSelected: String | ArrayBuffer;
  loading: boolean = true;
  classCss = "ima_cliente";
  listRango: any = [];
  vYear: string;
  constructor(
    private customerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,

  ) { }


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.vYear = "2022";
    this.setList();
    this.customerService.clienteSer$.subscribe(() => {
      this.getAllClientes();
    });
    this.getAllClientes();

    this.aFormGroup = this._formBuilder.group({
      tipo_proyecto: ['', Validators.required]
    });

    this.fFormGroup = this._formBuilder.group({
      rb: [Validators.required],
    });

  }





  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }

  getAllClientes() {
    this.customerService.getAllClientes().subscribe(
      resp => {
        this.dataSource.data = resp.clientes;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.changeDetectorRef.detectChanges();
        this.loading = false;
      });
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  onDialogAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    this.dialog.open(AddClienteComponent, dialogConfig);
  }

  onDialogUp(_id, cod_epicor, razon_social, descripcion, ima_cliente, ciudad, provincia, telefono, grupo, _id_canal, canales) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";
    dialogConfig.data = {
      data: {
        _id: _id,
        id_epicor: cod_epicor,
        razon_social: razon_social,
        descripcion: descripcion,
        ima_cliente: ima_cliente,
        ciudad: ciudad,
        provincia: provincia,
        telefono: telefono,
        grupo: grupo,
        _id_canal: _id_canal,
        canales: canales
      }
    };
    this.dialog.open(UpClienteComponent, dialogConfig);
  }


  onDialogDir(_id, razon_social, ima_cliente, id_epicor) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";
    dialogConfig.height = "70%";
    dialogConfig.data = {
      data: {
        _id: _id,
        razon_social: razon_social,
        ima_cliente: ima_cliente,
        id_epicor: id_epicor
      }
    };
    this.dialog.open(DirClienteComponent, dialogConfig);
  }

  onDialogDel(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "40%";
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(DelClienteComponent, dialogConfig);
  }


  changeEstado(e, id) {
    this.customerService.updateCliente({ _id: id, estado: e.checked })
      .subscribe(
        res => {
          this.openSnackBar(res.status, 'ok');
        },
        err => {
          this.openSnackBar(err.error.error, 'alert');
        });
  }


  validateIma(valor) {
    if (valor != "null")
      return valor;
    else
      return "assets/ima/no-ima.png";
  }






}


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}