import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from './../../../../services/sales/sales.service';
@Component({
  selector: 'app-list-search-pro',
  templateUrl: './list-search-pro.component.html',
  styleUrls: ['./list-search-pro.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ListSearchProComponent implements OnInit {
  expandedElement: PeriodicElement | null;
  @Input() vCli: String;
  @Input() vD: String;
  @Input() vH: String;
  @Input() vT: String;
  @Input() vCh: String;
  dataPro = new BehaviorSubject<any>(null);
  panelOpenState = false;

  displayedColumns: string[] = [
    'numparte',
    'nombre',
    'volumen',
    'unidad',
    'total',
  ];
  dataSource = new MatTableDataSource<any>();
  constructor(private salesService: SalesService) {}

  ngOnInit(): void {
    if (this.vT === 'C') {
      this.getListProductosCliSearchChannel(
        this.vCli,
        this.vD,
        this.vH,
        this.vCh
      );
    } else {
      this.getListProductosCliSearch(this.vCli, this.vD, this.vH);
    }
  }

  getListProductosCliSearch(vCli, vD, vH) {
    this.salesService
      .getListProductosCliSearch({ vCli: vCli, vD: vD, vH: vH })
      .subscribe((resp) => {

        const busqueda = resp.reduce((acc, persona) => {
          acc[persona.nombre] = ++acc[persona.nombre] || 0;
          return acc;
        }, {});

        const duplicados = resp.filter((persona) => {
          return busqueda[persona.nombre];
        });

        resp.forEach((object) => {
          let resulto = duplicados.includes(object);
          if (resulto) {
            object.dupli = 'true';
          }
          return object;
        });


        

        this.dataPro.next(resp);
      });
  }

  getListProductosCliSearchChannel(vCli, vD, vH, vCh) {
    this.salesService
      .getListProductosCliSearchChannel({
        vCli: vCli,
        vD: vD,
        vH: vH,
        vCh: vCh,
      })
      .subscribe((resp) => {
        this.dataPro.next(resp);
      });
  }

  getColorl(i) {
    const val = i % 2;
    if (val === 0) {
      return 'divRowb';
    } else {
      return 'divRoww';
    }
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
