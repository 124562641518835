import { Component, Input, OnInit, Pipe } from '@angular/core';
import { SalesService } from "../../../services/sales/sales.service";

import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-grafica-year',
  templateUrl: './grafica-year.component.html',
  styleUrls: ['./grafica-year.component.css']
})
export class GraficaYearComponent implements OnInit {
  @Input() vyear: number;
  @Input() vpre: number;
  multi: any[];
  view: any[] = [300, 70];

  // options
  showXAxis: boolean = false;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Country';
  showDataLabel: boolean = false;
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'MXN';
  animations: boolean = true;
  legenda: string = "Leyenda";
  roundDomains: boolean = true;
  colorScheme = {
    domain: ['#3498DB', '#26a116', '#85C1E9', '#F39C12']
  };
  loadinga = false;
  transactions: any;
  dataGra: any[] = [];
  constructor(
    private salesService: SalesService,
    private decimalPipe: DecimalPipe
  ) {

  }

  ngOnInit(): void {
    this.getGraficaVentaYearCri(this.vyear, this.vpre);
  }

  getGraficaVentaYearCri(vYear, vPre) {
    this.salesService.getGraficaVentaYearCri({ vYear: String(vYear) }).subscribe(
      resp => {
        let dar = resp.map((val) => {
          this.dataGra.push(
            {
              name: "PRE ",
              value: Number(this.getFormarPre(vPre)),
            },
            {
              name: val.name,
              value: val.value,
            }
          );
          return val;
        });
        this.dataGra = [...this.dataGra];
      });
  }


  onSelect(event) { }


  kNumber(number) {
    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }


  getFormarPre(valor) {
    var pre;
    pre = this.decimalPipe.transform(valor, '1.2-2');
    return pre = pre.replace(/,/g, "");

  }


}
