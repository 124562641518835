import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ChannelService } from 'src/app/services/channel.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ProductService } from 'src/app/services/product.service';
import { PurshaseOrderService } from 'src/app/services/purshase-order.service';
import { UnidadesService } from 'src/app/services/unidades.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-purchase-order-up-rq',
  templateUrl: './purchase-order-up-rq.component.html',
  styleUrls: ['./purchase-order-up-rq.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PurchaseOrderUpRqComponent implements OnInit {
  @ViewChild('elementPro') elementPro: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  formPurchaseOrder: FormGroup;
  clienteF = new FormControl();
  productoF = new FormControl();
  optionCliente: any[] = [];
  optionProducto: any[] = [];
  unidades: any[] = [];
  filteredOptionsCliente: Observable<string[]>;
  filteredOptionsProducto: Observable<string[]>;
  bso: boolean;
  obsCh = new BehaviorSubject<any>(null);

  displayedColumns: string[] = [
    'producto',
    'volumen',
    'unidad',
    'precio',
    'monto',
    'opciones',
  ];
  dataSource = new MatTableDataSource<any>();
  vCli: any;
  vPro: any;
  vOc: string;
  msj: string;
  btn_add: boolean;
  gItems: any;
  btn_up: boolean;
  constructor(
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private customerService: CustomerService,
    private productService: ProductService,
    private channelService: ChannelService,
    private unidadesService: UnidadesService,
    private purshaseOrderService: PurshaseOrderService,
    private route: ActivatedRoute
  ) {
    this.vOc = this.route.snapshot.paramMap.get('vOc');
  }

  ngOnInit(): void {
    this.getOneOrder(this.vOc);
    this.getAllItemOrd(this.vOc);
    this.createForm();
    this.filOper();
    this.getAllClientes();
    this.getAllUnidades();
    this.getProductos();
    this.getAllCanal();
    this.getEstadoInicial();
    this.refresh();
  }

  getEstadoInicial() {
    this.btn_add = true;
    this.btn_up = false;
    this.msj = this.formPurchaseOrder.controls.ci.value;
    this.formPurchaseOrder.controls.oc.enable();
    this.formPurchaseOrder.controls.fecha_em.enable();
    this.formPurchaseOrder.controls.fecha_ent.enable();
    this.formPurchaseOrder.controls.fecha_oc.enable();
    this.formPurchaseOrder.controls.ci.enable();
    this.formPurchaseOrder.controls.canal.enable();
    this.clienteF.enable();
    this.formPurchaseOrder.controls.afor.enable();

    this.formPurchaseOrder.controls.bfor.disable();
    this.formPurchaseOrder.controls.unidad.disable();
    this.formPurchaseOrder.controls.precio.disable();
    this.formPurchaseOrder.controls.volumen.disable();
    this.formPurchaseOrder.controls.monto.disable();
    this.formPurchaseOrder.controls.moneda.disable();
    this.formPurchaseOrder.controls.upc.disable();
    this.formPurchaseOrder.controls.ci.disable();
    this.productoF.disable();


    this.formPurchaseOrder.controls.precio.reset();
    this.formPurchaseOrder.controls.volumen.reset();
    this.formPurchaseOrder.controls.monto.reset();
    this.formPurchaseOrder.controls.moneda.reset();
    this.formPurchaseOrder.controls.upc.reset();
    this.productoF.reset();

  }

  getEstadoProducto() {
    this.btn_add = false;
    this.btn_up = true;

    this.formPurchaseOrder.controls.oc.disable();
    this.formPurchaseOrder.controls.fecha_em.disable();
    this.formPurchaseOrder.controls.fecha_ent.disable();
    this.formPurchaseOrder.controls.fecha_oc.disable();
    this.formPurchaseOrder.controls.ci.disable();
    this.formPurchaseOrder.controls.canal.disable();
    this.clienteF.disable();
    this.formPurchaseOrder.controls.afor.disable();

    this.formPurchaseOrder.controls.bfor.enable();
    this.formPurchaseOrder.controls.unidad.enable();
    this.formPurchaseOrder.controls.precio.enable();
    this.formPurchaseOrder.controls.volumen.enable();
    this.formPurchaseOrder.controls.monto.enable();
    this.formPurchaseOrder.controls.moneda.enable();
    this.formPurchaseOrder.controls.upc.enable();
    this.productoF.enable();

    this.formPurchaseOrder.controls.precio.reset();
    this.formPurchaseOrder.controls.volumen.reset();
    this.formPurchaseOrder.controls.monto.reset();
    this.formPurchaseOrder.controls.moneda.reset();
    this.formPurchaseOrder.controls.upc.reset();
    this.productoF.reset();
  }

  filOper() {
    this.filteredOptionsCliente = this.clienteF.valueChanges.pipe(
      startWith('SIN CLIENTE'),
      map((value) => this._filterCliente(value))
    );

    this.filteredOptionsProducto = this.productoF.valueChanges.pipe(
      startWith('SIN PRODUCTO'),
      map((value) => this._filterProducto(value))
    );
  }

  createForm() {
    this.formPurchaseOrder = new FormGroup({
      fecha_oc: new FormControl(moment().format('YYYY-MM-DD'), [
        Validators.required,
      ]),
      fecha_em: new FormControl('', [Validators.required]),
      canal: new FormControl('', [Validators.required]),
      fecha_ent: new FormControl('', [Validators.required]),
      cliente: new FormControl('', [Validators.required]),
      producto: new FormControl('', [Validators.required]),
      upc: new FormControl(''),
      monto: new FormControl('', [Validators.required]),
      volumen: new FormControl('', [Validators.required]),
      precio: new FormControl('', [Validators.required]),
      moneda: new FormControl('', [Validators.required]),
      unidad: new FormControl('5fa2086e44d7d2149c8c522a', [
        Validators.required,
      ]),
      bfor: new FormControl('numparte', [Validators.required]),
      afor: new FormControl('id_epicor', [Validators.required]),
      ci: new FormControl('', [Validators.required]),
      oc: new FormControl('', [Validators.required]),
    });
  }

  setCliente(valor) {
    this.formPurchaseOrder.controls.cliente.setValue(valor);
    const cliente = this.formPurchaseOrder.get('cliente').value;
    this.vCli = valor;
  }

  setProducto(valor) {
    this.formPurchaseOrder.controls.producto.setValue(valor);
    this.vPro = valor;
    this.getProIddata(valor);
  }

  _filterCliente(value: string) {
    return this.optionCliente.filter((optionCliente) =>
      optionCliente.razon_social.toLowerCase().includes(value.toLowerCase())
    );
  }

  _filterProducto(value: string) {
    if (value) {
      const criFor = this.formPurchaseOrder.controls.bfor.value;
      return this.optionProducto.filter((option) => {
        return option[criFor]?.toLowerCase().includes(value.toLowerCase());
      });
    }
  }

  getAllClientes() {
    this.customerService.getAllClientes().subscribe((resp) => {
      this.optionCliente = resp.clientes;
    });
  }

  getAllItemOrd(ci) {
    this.purshaseOrderService.getAllItemOrd({ ci: ci }).subscribe((resp) => {
      this.dataSource.data = resp;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getAllUnidades() {
    this.unidadesService.getAllUnidades().subscribe((resp) => {
      this.unidades = resp.unidades;
    });
  }

  getProductos() {
    this.productService.getAllProductos().subscribe((resp) => {
      this.optionProducto = resp.productos;
    });
  }

  ct($even) {
    const volumen = this.formPurchaseOrder.controls.volumen.value;
    const precio = this.formPurchaseOrder.controls.precio.value;
    const a = volumen * precio;
    this.formPurchaseOrder.controls.monto.setValue(a);
    return a;
  }

  up() {

    let newObj;
    if (this.btn_add) {

      const ci = this.formPurchaseOrder.controls.ci.value;
      const fecha_em = this.formPurchaseOrder.controls.fecha_em.value;
      const fecha_ent = this.formPurchaseOrder.controls.fecha_ent.value;
      const fecha_oc = this.formPurchaseOrder.controls.fecha_oc.value;
      const _id_canal = this.formPurchaseOrder.controls.canal.value;
      newObj= {
        ci: ci,
        _id_cliente: this.vCli,
        _id_canal: _id_canal,
        fecha_em: fecha_em,
        fecha_ent: fecha_ent,
        fecha_oc: fecha_oc,
        yeara: moment(fecha_oc).format('YYYY'),
        m: moment(fecha_oc).format('M'),
      };
      this.getAllItemOrd(ci);

      this.purshaseOrderService.updateBasicOc(newObj).subscribe((resp) => {
        this.purshaseOrderService.listItem$.emit(newObj.ci);
        this.openSnackBar(resp.status, 'ok');
      });
   
    } 
    
    if(!this.btn_up){


      const ci = this.formPurchaseOrder.controls.ci.value;
      const moneda = this.formPurchaseOrder.controls.moneda.value;
      const monto = this.formPurchaseOrder.controls.monto.value;
      const precio = this.formPurchaseOrder.controls.precio.value;
      const upc = this.formPurchaseOrder.controls.upc.value;
      const volumen = this.formPurchaseOrder.controls.volumen.value;
      const _id_unidad = this.formPurchaseOrder.controls.unidad.value;
      newObj= {
        _id:this.gItems,
        ci: ci,
        moneda:moneda,
        monto:monto,
        precio:precio,
        upc:upc,
        volumen:volumen,
        _id_unidad:_id_unidad,
        _id_producto: this.vPro,
  
      };
      this.getAllItemOrd(ci);

      this.purshaseOrderService.updateOrdeItemPro(newObj).subscribe((resp) => {
        this.purshaseOrderService.listItem$.emit(newObj.ci);
        this.openSnackBar(resp.status, 'ok');
      });
      this.getEstadoInicial();
    }


    

  
  }

  add() {
    if (this.formPurchaseOrder.valid) {
      const ci = this.formPurchaseOrder.controls.ci.value;
      const fecha_em = this.formPurchaseOrder.controls.fecha_em.value;
      const fecha_ent = this.formPurchaseOrder.controls.fecha_ent.value;
      const fecha_oc = this.formPurchaseOrder.controls.fecha_oc.value;
      const moneda = this.formPurchaseOrder.controls.moneda.value;
      const monto = this.formPurchaseOrder.controls.monto.value;
      const oc = this.formPurchaseOrder.controls.oc.value;
      const precio = this.formPurchaseOrder.controls.precio.value;
      const upc = this.formPurchaseOrder.controls.upc.value;
      const volumen = this.formPurchaseOrder.controls.volumen.value;
      const _id_unidad = this.formPurchaseOrder.controls.unidad.value;
      const _id_canal = this.formPurchaseOrder.controls.canal.value;

      let newObj = {
        _id_cliente: this.vCli,
        _id_producto: this.vPro,
        _id_unidad: _id_unidad,
        _id_canal: _id_canal,
        ci: ci,
        fecha_em: fecha_em,
        fecha_ent: fecha_ent,
        fecha_oc: fecha_oc,
        moneda: moneda,
        monto: monto,
        oc: oc,
        precio: precio,
        upc: upc,
        volumen: volumen,
        yeara: moment(fecha_oc).format('YYYY'),
        m: moment(fecha_oc).format('M'),
      };

      this.purshaseOrderService.registroOrde(newObj).subscribe((resp) => {
        this.purshaseOrderService.listItem$.emit(ci);
        this.openSnackBar(resp.status, 'ok');
      });

      this.productoF.setValue('');
      this.formPurchaseOrder.controls.producto.setValue('');
      this.elementPro.nativeElement.focus();
      this.getAllItemOrd(ci);
    } else {
      this.openSnackBar('Error en la validacion de la información', 'alert');
    }
  }

  refresh() {
    this.purshaseOrderService.listItem$.subscribe((ci) => {
      this.getAllItemOrd(ci);
    });
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  getOneOrder(vOc) {
    this.purshaseOrderService.getOneOrder({ vOc: vOc }).subscribe((resp) => {
      this.setCliente(resp._id_cliente);
      this.clienteF.setValue(resp.razon_social);

      this.formPurchaseOrder.patchValue({
        ci: resp.ci,
        fecha_oc: resp.fecha_oc,
        fecha_em: resp.fecha_em,
        fecha_ent: resp.fecha_ent,
        moneda: resp.moneda,
        _id_cliente: resp._id_cliente,
        unidad: resp._id_unidad,
        canal: resp._id_canal,
        oc: resp.oc,
      });
    });
  }

  getProIddata(_id_producto) {
    this.productService
      .getProIddata({ _id: _id_producto })
      .subscribe((resp) => {
        this.formPurchaseOrder.patchValue({
          upc: resp.cbag,
          moneda: resp.moneda,
        });
      });
  }
  onDelAsig(_id, ci) {
    this.purshaseOrderService
      .delOrdenItemList({
        _id: _id,
      })
      .subscribe((resp) => {
        this.purshaseOrderService.listItem$.emit(ci);
        this.openSnackBar(resp.status, 'ok');
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getItem(data) {




    this.getEstadoProducto();




    this.btn_add = true;
    this.btn_up = false;


    
    this.gItems=data._id;
    this.msj = 'Producto ' + data._id_producto.nombre;
    this.getProIddata(data._id_producto._id);
    this.setProducto(data._id_producto._id);
    this.productoF.setValue(data._id_producto.nombre);
    this.formPurchaseOrder.controls.oc.disable();
    this.formPurchaseOrder.controls.fecha_em.disable();
    this.formPurchaseOrder.controls.fecha_ent.disable();
    this.formPurchaseOrder.controls.fecha_oc.disable();
    this.formPurchaseOrder.controls.ci.disable();
    this.formPurchaseOrder.controls.canal.disable();
    this.clienteF.disable();
    this.formPurchaseOrder.controls.afor.disable();

    this.formPurchaseOrder.patchValue({
      volumen: data.volumen,
      precio: data.precio,
      monto: data.monto,
      upc: data.upc,
      moneda: data.moneda,
    });
  }

  getTotalCost() {
    return this.dataSource.data
      .map((t) => t.monto)
      .reduce((acc, value) => acc + value, 0);
  }
  getTotalVol() {
    return this.dataSource.data
      .map((t) => t.volumen)
      .reduce((acc, value) => acc + value, 0);
  }

  montoValue(value) {
    const resultado = formatCurrency(
      value,
      'en-US',
      getCurrencySymbol('USD', 'wide')
    );
    return resultado;
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe((resp) => {
      this.obsCh.next(resp);
    });
  }
}
