import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SalesService } from "./../../../services/sales/sales.service";
import { PanelLogsComponent } from "./../../logs/panel-logs/panel-logs.component";
import { LogsService } from "./../../../services/logs.service";
import { MatSnackBar } from '@angular/material/snack-bar';


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};


@Component({
  selector: 'app-send-recla',
  templateUrl: './send-recla.component.html',
  styleUrls: ['./send-recla.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SendReclaComponent implements OnInit {

  @ViewChild(PanelLogsComponent, { static: false })
  listLogs: PanelLogsComponent;


  local_data: any;
  vCliente: String;
  vRcliente: String;
  vYear: String;
  vD: String;
  vH: String;
  vTPMxn: String;
  vTVMxn: String;
  aFormGroup: FormGroup;
  constructor(
    private salesService: SalesService,
    private _snackBar: MatSnackBar,
    private logsService: LogsService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SendReclaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.local_data = this.data.data;
    this.vCliente = this.local_data.vCliente;
    this.vRcliente = this.local_data.vRcliente;
    this.vYear = this.local_data.vYear;
    this.vD = this.local_data.vD;
    this.vH = this.local_data.vH;
    this.vTPMxn = this.local_data.vTPMxn;
    this.vTVMxn = this.local_data.vTVMxn;
  }

  ngOnInit(): void {
    var date = new Date();
    this.aFormGroup = this._formBuilder.group({
      vFecha: [date, Validators.required],
      vPd: ['ME GUSTARÍA REVISAR ESTOS DATOS, POR FAVOR.', Validators.required],
      vLu: ["PROFUSA, PRÓXIMA JUNTA; EQUIPO VENTAS.", Validators.required],
    });
  }

  sendRecla(vPd, vDe, vLu, vCliente, vRcliente, vD, vH) {
    this.salesService.sendRecla({
      vPd: vPd,
      vDe: vDe,
      vLu: vLu,
      vCliente: vCliente,
      vRcliente: vRcliente,
      vD: vD,
      vH: vH
    }).subscribe(resp => {
    });
  }

  up() {
    const vFecha = this.aFormGroup.controls.vFecha.value;
    const vPd = this.aFormGroup.controls.vPd.value;
    const vLu = this.aFormGroup.controls.vLu.value;
    let obj = {
      vFecha: vFecha,
      vPd: vPd,
      vLu: vLu,
      vCliente: this.vCliente,
      vRcliente: this.vRcliente,
      vYear: this.vYear,
      vD: this.vD,
      vH: this.vH,
      vTPMxn: this.vTPMxn,
      vTVMxn: this.vTVMxn,

    }

    this.salesService.sendRecla(obj)
      .subscribe(
        res => {
          this.onClose();
          this.openSnackBar(res.status, 'ok');
          this.logsService.listNoti$.emit("redClass");

        },
        err => {
          console.log(err);
          this.openSnackBar(err.error.error, 'alert');
        });
  }

  onClose() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }
}
