import { Component, Input, OnInit } from '@angular/core';
import { SalesService } from "../../../services/sales/sales.service";


@Component({
  selector: 'app-pcr-pre',
  templateUrl: './pcr.component.html',
  styleUrls: ['./pcr.component.css']
})
export class PcrpreComponent implements OnInit {
  @Input() vyear: number;
  @Input() vpre: number;
  cals: string;
  icc: string;
  tven: any;
  value: number;
  ics: string;


  constructor(private salesService: SalesService) {
  }

  ngOnInit(): void {
    this.getGraficaVentaYearCri(this.vyear)

  }

  validateValue(value) {
    this.value = value;
    if (value <= 10) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";
    } else if (value > 10 && value < 50) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";

    } else if (value > 50) {
      this.cals = "gree";
      this.icc = "schedule";
      this.ics = "blink_me";
    }
  }



  verificarYear(vYear) {

    if (vYear != 2022) {
      this.cals = "bluer";
      this.icc = "hourglass_disabled";
      this.ics = "";
    }

  }

  getGraficaVentaYearCri(vYear) {
    this.salesService.getGraficaVentaYearCri({ vYear: String(vYear) }).subscribe(
      resp => {



        this.tven = resp[0].value;
        this.value = (this.tven / this.vpre) * 100;
        this.validateValue(this.value);
        this.verificarYear(vYear);
      });
  }

}
