<div class="ct-model">
  <div></div>
  <div></div>
  <div></div>
  <div class="item name-c">


    <form [formGroup]="criterioCh" method="post">
      <mat-form-field class="mat-form-field-filtro">
        <mat-label>Año:</mat-label>
        <mat-select (selectionChange)="updateCh()" formControlName="canal">
          <mat-option *ngFor="let lv of listRango" [value]="lv.vYear"> {{lv.vYear}} </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>

<div *ngIf="gd1m && gd2m;else nodatam">




  <div *ngIf="gd1 && gd2;else nodata" class="ct-global">
    <div *ngFor="let env of obsGlo | async" class="ct-g-info">
      <mat-card class="mt-pre" (click)="getTable()">
        <div class="ct-cli">
          <app-ima-cliente [idcliente]="vCli"></app-ima-cliente>
        </div>
        <div class="ct-vol">
          <div *ngFor="let euv of env.arUni" class="ct-vol-list-v">
            <div><span class="mp-vol-k"> {{euv.volumen | number: '1.2-2'}}</span> <span class="mp-ivo">
                {{euv.unidad}}</span> </div>
          </div>
          <div *ngFor="let eup of env.arUnipre" class="ct-vol-list-p">
            <div><span class="mp-vol-k"> {{eup.volumen | number: '1.2-2'}}</span> <span class="mp-ivo">
                {{eup.unidad}}</span> </div>
          </div>
          <div *ngFor="let euo of env.arUnioc" class="ct-vol-list-o">
            <div><span class="mp-vol-k"> {{euo.volumen | number: '1.2-2'}}</span> <span class="mp-ivo">
                {{euo.unidad}}</span> </div>
          </div>
        </div>
        <div class="ct-master">
          <div class="mp">



            <div *ngIf="env.ventamxn>0" class="mp-tm">{{env.ventamxn | number: '1.2-2'}}</div>
            <div *ngIf="env.ventamxn>0" class="mp-i">MXN</div>
            <div *ngIf="env.ventauds>0" class="mp-tu">{{env.ventauds | number: '1.2-2'}}</div>
            <div *ngIf="env.ventauds>0" class="mp-i">USD</div>
            <div class="mp-tt">
              <span class="mp-i">VEN</span> {{env.venta | number: '1.2-2'}}
            </div>
            <div class="mp-ti">MXN</div>
            <div *ngIf="env.premxn>0" class="mp-tmx">{{env.premxn | number: '1.2-2'}}</div>
            <div *ngIf="env.premxn>0" class="mp-i">MXN</div>
            <div *ngIf="env.preuds>0" class="mp-tmx">{{env.preuds | number: '1.2-2'}} <span class="mp-i">USD</span><span
                class="bro">{{env.tc}}</span>{{env.prevalueToMXN | number: '1.2-2'}} </div>
            <div *ngIf="env.preuds>0" class="mp-i">MXN</div>
            <div class="mp-tp">

              <div class="mp-toc" *ngIf="env.presupuesto==0"></div>

              <span class="mp-i">PRE</span> {{env.presupuesto | number: '1.2-2'}}

            </div>
            <div class="mp-ti">MXN</div>
            <div *ngIf="env.ocmxn>0" class="mp-tmx">{{env.ocmxn | number: '1.2-2'}}</div>
            <div *ngIf="env.ocmxn>0" class="mp-i">MXN</div>
            <div *ngIf="env.ocuds>0" class="mp-tmx">{{env.ocuds | number: '1.2-2'}} <span class="mp-i">USD</span><span
                class="bro">{{env.tc}}</span>{{env.ocvalueToMXN | number: '1.2-2'}} </div>
            <div *ngIf="env.ocuds>0" class="mp-i">MXN</div>
            <div class="mp-ttco">
              <div class="mp-toc" *ngIf="env.ordencompra==0"></div>
              <span class="mp-i">OC</span> {{env.ordencompra | number: '1.2-2'}}
            </div>
            <div class="mp-ti">MXN</div>
          </div>
        </div>
      </mat-card>
      <div class="mp-s">
        Desde {{env.vD}} hasta {{env.vH}}
      </div>
    </div>
    <div class="ct-g-gra ct-gra">




      <div *ngIf="!vtable">
        <div class="ct-leg-d">
          <div class="ct-grid-ct">
            <div class="item-ct-canal">
              <div class="item-ventaa"></div>
              <div class="ct-leg">VENTA</div>
            </div>
            <div class="item-ct-canal">
              <div class="item-ventab"></div>
              <div class="ct-leg">ORDEN DE COMPRA </div>
            </div>
            <div class="item-ct-canal">
              <div class="item-ventac"></div>
              <div class="ct-leg">PRESUPUESTO</div>
            </div>
          </div>

        </div>
        <div class="g1">
          <div class="g2">
            <div class="g3">

              <ngx-charts-line-chart [scheme]="colorScheme" [legend]="legend" [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel" [customColors]="customColors" [xAxis]="xAxis" [yAxis]="yAxis"
                [legendPosition]="" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline"
                [results]="multi">
              </ngx-charts-line-chart>

            </div>

          </div>
        </div>
      </div>

      <div [hidden]="!vtable" class="table-container">


        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">
          <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> MES </th>
            <td mat-cell *matCellDef="let element">
              <!-- {{element.num}} -  -->
              {{element.mes}}
            </td>
            <td mat-footer-cell *matFooterCellDef> Total </td>
          </ng-container>
          <ng-container matColumnDef="presupuesto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> MXN PRE </th>
            <td mat-cell *matCellDef="let element"> {{element.presupuesto | number: '1.2-2'}} </td>
            <td mat-footer-cell *matFooterCellDef> {{this.volSumaPre | number: '1.2-2'}} </td>
          </ng-container>
          <ng-container matColumnDef="oc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> MXN OC </th>
            <td mat-cell *matCellDef="let element"> {{element.oc | number: '1.2-2'}} </td>
            <td mat-footer-cell *matFooterCellDef> {{this.volSumaOc | number: '1.2-2'}} </td>
          </ng-container>
          <ng-container matColumnDef="venta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> MXN VEN </th>
            <td mat-cell *matCellDef="let element"> {{element.venta | number: '1.2-2'}} </td>
            <td mat-footer-cell *matFooterCellDef> {{this.volSumaVen | number: '1.2-2'}} </td>
          </ng-container>
          <ng-container matColumnDef="indi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td mat-cell *matCellDef="let element" matTooltip="Cumplimiento Venta Vs. Presupuesto">
              <app-scg-indicador [vVen]="element.venta" [vSm]="'sm'" [vPre]="element.presupuesto"></app-scg-indicador>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <app-scg-indicador [vVen]="volSumaVen" [vSm]="'sm'" [vPre]="volSumaPre"></app-scg-indicador>
            </td>
          </ng-container>
          <tr class="table-h" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>


      </div>









    </div>
  </div>
</div>

<ng-template #nodatam>
  <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]=""> </app-info-data>
</ng-template>
<ng-template #nodata>

  <div class="ct-global-w">
    <div class="ct-g-info">
      <mat-card class="mt-pre">
        <div class="ct-vol">
          <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '120px',
            'height': '35px',
            'background-color': '#daddd8'
        }">
          </ngx-skeleton-loader>
        </div>
        <div class="ct-master">
          <div class="mp-w">



            <div class="mp-tm">
              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '120px',
                'height': '15px',
                'background-color': '#daddd8'
            }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-i">

              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '20px',
                'height': '9px',
                'background-color': '#daddd8'
            }"> </ngx-skeleton-loader>

            </div>


            <div class="mp-tt">
              <span class="mp-i">
                <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '20px',
                'height': '9px',
                'margin-right': '5px',
                'background-color': '#daddd8'
            }"> </ngx-skeleton-loader>
              </span>
              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '150px',
                'height': '30px',
                'background-color': '#daddd8'
            }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-ti">
              <ngx-skeleton-loader count="1" [theme]="{ 
              'border-radius': '5px', 
              'width': '20px',
              'height': '9px',
              'background-color': '#daddd8'
          }"> </ngx-skeleton-loader>
            </div>
            <div class="mp-tmx">
              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '120px',
                'height': '15px',
                'margin-top': '7px',
                'background-color': '#daddd8'
            }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-i">
              <ngx-skeleton-loader count="1" [theme]="{ 
              'border-radius': '5px', 
              'width': '20px',
              'height': '9px',
              'margin-top': '5px',
              'background-color': '#daddd8'
          }"> </ngx-skeleton-loader>
            </div>

            <div class="mp-tp">

              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '20px',
                'height': '9px',
                'margin-right': '5px',
                'background-color': '#daddd8'
            }"> </ngx-skeleton-loader>

              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '150px',
                'height': '30px',
                'background-color': '#daddd8'
            }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-ti">
              <ngx-skeleton-loader count="1" [theme]="{ 
              'border-radius': '5px', 
              'width': '20px',
              'height': '9px',
              'background-color': '#daddd8'
          }"> </ngx-skeleton-loader>
            </div>

            <div class="mp-tp mp-toc">

              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '20px',
                'height': '9px',
                'margin-right': '5px',
                'background-color': '#daddd8'
            }"> </ngx-skeleton-loader>

              <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '150px',
                'height': '30px',
                'background-color': '#daddd8'
            }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-ti">
              <ngx-skeleton-loader count="1" [theme]="{ 
              'border-radius': '5px', 
              'width': '20px',
              'height': '9px',
              'background-color': '#daddd8'
          }"> </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </mat-card>
      <div class="mp-s">
        <ngx-skeleton-loader count="1" [theme]="{ 
          'border-radius': '5px', 
          'width': '180px',
          'height': '9px',
          'margin-top': '5px',
          'background-color': '#daddd8'
      }"> </ngx-skeleton-loader>
      </div>
    </div>
    <div class="ct-g-gra ct-gra">

      <div class="g1">
        <div class="g2">
          <div class="g3">

            <div class="block-wiat">
              <ngx-skeleton-loader *ngFor="let iWiat of wiat" count="1" [theme]="iWiat">
              </ngx-skeleton-loader>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>