<!-- 
    <mat-card class="mat-card mat-elevation-z0" >
        <mat-card-content>
            <img mat-card-avatar class="imgfluid" [src]="this.datosEjecutivos.imagen || 'assets/ima/no-ima.png'">
        </mat-card-content>
        <mat-card-title class="tit">{{this.datosEjecutivos.nombre}} {{this.datosEjecutivos.apellido}}
        </mat-card-title>
        <mat-card-subtitle>{{datosEjecutivos.email}}
            <br>
            <span class="vendedor--panel-asignado"> Ejecutivo Asignado</span>
        </mat-card-subtitle>
    </mat-card> -->


    <mat-grid-list cols="10" rowHeight="150px">
        <mat-grid-tile colspan="2">
            <img mat-card-avatar class="imgfluid" [src]="this.datosEjecutivos.imagen || 'assets/ima/no-ima.png'">
        </mat-grid-tile>
        <mat-grid-tile colspan="8">
    
          <mat-card class="mat-card mat-elevation-z0">
            <mat-card-title class="tit">{{ this.datosEjecutivos.nombre }} {{ this.datosEjecutivos.apellido }}
            </mat-card-title>
            <mat-card-subtitle>{{ this.datosEjecutivos.email }}
            </mat-card-subtitle>
        
            <mat-card-subtitle>Ejecutivo Asignado (Actual)</mat-card-subtitle>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>