import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../global';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private URL = GlobalVariable.BASE_API_URL;
  public listItem$ = new EventEmitter<any>();
  constructor(private http: HttpClient, private router: Router) {}
  public oArea$ = new EventEmitter<any>();
  

  getRoomId(data) {
    return this.http.post<any>(this.URL + 'getRoomId', data);
  }

  activateRoom(data) {
    return this.http.post<any>(this.URL + 'activateRoom', data);
  }

  
}
