<div class="ct-gant-mf">
  <div class="ct-side" (click)="scrollLeft()">
    <mat-icon>arrow_back_ios</mat-icon>
  </div>
  <div #widgetsContent class="msf">
    <div *ngIf="data;else nodata">
      <div class="ct-m">
        <div class="chart-wrapper">
          <ul class="chart-values">
            <li #customLabel *ngFor="let ar of arrayDateOb | async">
              {{ar.dias}}

              <ng-container *ngIf="ar.sta; else newDeb">
                <div #target [class]="ar.iddClas">Hoy</div>
              </ng-container>
              <ng-template #newDeb>
                <div [class]="ar.iddClas"></div>
              </ng-template>


              <ng-container *ngIf="ar.stas.val_id">
                <div [class]="ar.stas.val_st" >{{ar.stas.val_de}}</div>
              </ng-container>
        



            </li>
          </ul>
          <ul class="chart-bars">
            <li id="one" *ngFor="let env of resuArra"
              [ngStyle]="{  'height': 'auto', 'word-wrap': 'break-word','left':env.left,'width':env.width,'text-align':'start','color': '#fff', 'min-width': '50px','background-color': env.color,'opacity': '1'}">

              <div class="ct-band">
                <div>
                  <app-ima-user-gantt [vUser]="env.responsable"></app-ima-user-gantt>
                </div>
                <div class="ct-ti">
                  <span class="ct-t-v" matTooltip="{{env.nombre}} - {{env.des}}" [ngStyle]="{'color':env.color}">
                    {{env.cit}} </span>
                </div>
                <div class="ct-cal">
                  <mat-icon class="ct-ico ct-v-c" matTooltip="{{env.fecha_star}} - {{env.fecha_end}}">today</mat-icon>
                </div>
              </div>
            </li>



          </ul>
        </div>
      </div>
    </div>
    <ng-template #nodata>
    </ng-template>

  </div>

  <div class="ct-side" (click)="scrollRight()">
    <mat-icon>arrow_forward_ios</mat-icon>
  </div>

</div>

<!-- <div *ngIf="data;else nodata">


  <div class="ct-m">
    <div class="chart-wrapper">
      <ul class="chart-values">
        <li #customLabel *ngFor="let ar of arrayDateOb | async">
          {{ar.dias}}
        </li>
      </ul>
      <ul class="chart-bars">
        <li id="one" *ngFor="let env of resuArra"
          [ngStyle]="{'left':env.left,'width':env.width,'text-align':'start','color': '#fff','background-color': env.color,'opacity': '1'}">

          <div class="ct-band">
            <div>
              <app-ima-user-gantt [vUser]="env.responsable"></app-ima-user-gantt>
            </div>
            <div class="ct-ti">
              <span class="ct-t-v" matTooltip="{{env.nombre}} - {{env.des}}" [ngStyle]="{'color':env.color}">
                {{env.cit}} </span>
            </div>
            <div class="ct-cal">
              <mat-icon class="ct-ico ct-v-c" matTooltip="{{env.fecha_star}} - {{env.fecha_end}}">today</mat-icon>
            </div>

          </div>


        </li>

  

      </ul>
    </div>

  </div>
</div>
<ng-template #nodata>
</ng-template> -->