<div class="ct-m">
  <div>

    <div *ngIf="gd1; else wnodata">

      <div *ngIf="gd1m; else rnodata">
        <div *ngFor="let env of obsGlo | async">
          <mat-card class="mt-pre">
            <!-- <span class="ct-numer">{{vNum+1}}</span> -->
            <div class="ct-p">
              <div class="ct-ima">
                <div class="ct-ima-con">
                <div class="ct-numer-st"> {{vNum+1}}</div>
                  <!-- <app-ima-cliente [idcliente]="vCli"></app-ima-cliente> -->
                </div>
              </div>
              <div class="ct-nom">
                <span class="ct-id"> {{vCli}}</span> <br>
                {{vRs}}
              </div>
            </div>
    
            <mat-divider></mat-divider>
    
            <div class="ct-master">
              <div class="mpr">
                <div class="mp-tt" >
                  <span class="mp-i">VEN</span> {{env.venta | number: '1.2-2'}} <span class="mp-ti">MXN</span>
                </div>
                <div class="mp-tp" matTooltip="{{env.preuds | number: '1.2-2'}}} X {{env.tc}} = {{env.presupuesto | number: '1.2-2'}}}" >
                  <div class="ct-value-cero" *ngIf="env.presupuesto;else nodatap">
                    <span class="mp-i">PRE</span> {{env.presupuesto | number: '1.2-2'}} <span class="mp-ti">MXN</span>
                  </div>
                  <ng-template #nodatap>
                    <div class="item-value-cero"> Sin Presupuesto </div>
                  </ng-template>
                 
                </div>

                <div class="mp-oc"  matTooltip="{{env.ocuds | number: '1.2-2'}}} X {{env.tc}} = {{env.ordencompra | number: '1.2-2'}}}"  >
                  <span class="mp-i">OC</span> {{env.ordencompra | number: '1.2-2'}} <span class="mp-ti">{{env.oc_moneda }}</span>
                </div>
                <div class="ct-iv">
                  <div *ngFor="let euv of env.arUni" class="ct-vol-list">
                    <div>
                      <span class="mp-vol-k"> {{euv.volumen | number: '1.0-2'}}</span>
                      <span class="mp-ivo"> {{euv.unidad}}</span>
                    </div>
                  </div>
                </div>
                <div class="ct-ip">
                  <div *ngFor="let euvp of env.arUnipre" class="ct-vol-listp">
                    <div>
                      <span class="mp-vol-k"> {{euvp.volumen | number: '1.0-2'}}</span>
                      <span class="mp-ivo"> {{euvp.unidad}}</span>
                    </div>
                  </div>
                </div>

                <div class="ct-oc">
                  <div *ngFor="let euo of env.arUnioc"    class="ct-vol-list-oc">
                    <div>
                      <span class="mp-vol-k"> {{euo.volumen | number: '1.0-2'}}</span>
                      <span class="mp-ivo-oc"> {{euo.unidad}}</span>
                    </div>
                  </div>
                </div>
                <div class="mp-im">
                  {{getResultado(env.arUni,env.arUnipre) | number: '1.0-2'}} <mat-icon class="rl-ico"
                    matTooltip="Caja Master">archive
                  </mat-icon><br>
                  {{getResBol(env.arUni,env.arUnipre) | number: '1.0-2'}} <mat-icon class="rl-ico"
                    matTooltip="Bolsa Empaque">
                    shopping_bag
                  </mat-icon>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    
      <ng-template #rnodata>
    
      </ng-template>
    
    
    
    </div>
    
    <ng-template #wnodata>
    
      <div>
        <mat-card class="mt-pre">
          <span class="ct-numer">
    
            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '10px',
                'height': '15px',
                'background-color': '#daddd8'
            }">
            </ngx-skeleton-loader>
    
    
          </span>
          <div class="ct-p">
            <div class="ct-ima">
              <div class="ct-ima-con">
                <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    'width': '50px',
                    'height': '50px',
                    'background-color': '#daddd8'
                }">
                </ngx-skeleton-loader>
              </div>
            </div>
            <div class="ct-nom">
              <ngx-skeleton-loader count="1" [theme]="{ 
                  'border-radius': '5px', 
                  'width': '80px',
                  'height': '10px',
                  'background-color': '#daddd8'
              }">
              </ngx-skeleton-loader>
              <br>
              <ngx-skeleton-loader count="1" [theme]="{ 
                  'border-radius': '5px', 
                  'width': '180px',
                  'height': '20px',
                  'background-color': '#daddd8'
              }">
              </ngx-skeleton-loader>
            </div>
          </div>
    
          <mat-divider></mat-divider>
    
          <div class="ct-master">
            <div class="mpr">
              <div class="mp-tt">
                <span class="mp-i">
                  <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '15px',
                      'height': '10px',
                      'background-color': '#daddd8'
                  }">
                  </ngx-skeleton-loader>
                </span>
    
                <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    'width': '140px',
                    'height': '30px',
                    'background-color': '#daddd8',
                    'margin-left':'5px'
                }">
                </ngx-skeleton-loader>
    
                <span class="mp-ti">
                  <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '15px',
                      'height': '10px',
                      'background-color': '#daddd8',
                      'margin-left':'5px'
                  }">
                  </ngx-skeleton-loader>
                </span>
              </div>
              <div class="mp-tp">
                <div class="ct-value-cero">
                  <span class="mp-i">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '15px',
                        'height': '10px',
                        'background-color': '#daddd8'
                    }">
                    </ngx-skeleton-loader>
                  </span>
    
    
                  <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    'width': '140px',
                    'height': '30px',
                    'background-color': '#daddd8',
                    'margin-left':'5px'
                }">
                  </ngx-skeleton-loader>
    
                  <span class="mp-ti">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '15px',
                        'height': '10px',
                        'background-color': '#daddd8',
                        'margin-left':'5px'
                    }">
                    </ngx-skeleton-loader>
                  </span>
                </div>
    
              </div>
              <div class="ct-iv">
                <div class="ct-vol-list">
                  <div>
                    <span class="mp-vol-k">
                      <ngx-skeleton-loader count="1" [theme]="{ 
                          'border-radius': '5px', 
                          'width': '40px',
                          'height': '20px',
                          'background-color': '#daddd8',
                          'margin-left':'5px'
                      }">
                      </ngx-skeleton-loader>
                    </span>
                    <span class="mp-ivo">
                      <ngx-skeleton-loader count="1" [theme]="{ 
                          'border-radius': '5px', 
                          'width': '20px',
                          'height': '20px',
                          'background-color': '#daddd8',
                          'margin-left':'5px'
                      }">
                      </ngx-skeleton-loader>
                    </span>
                  </div>
                </div>
              </div>
              <div class="ct-ip">
                <div class="ct-vol-listp">
                  <div>
                    <span class="mp-vol-k">
                      <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '40px',
                        'height': '20px',
                        'background-color': '#daddd8',
                        'margin-left':'5px'
                    }">
                      </ngx-skeleton-loader>
                    </span>
                    <span class="mp-ivo">
                      <ngx-skeleton-loader count="1" [theme]="{ 
                          'border-radius': '5px', 
                          'width': '20px',
                          'height': '20px',
                          'background-color': '#daddd8',
                          'margin-left':'5px'
                      }">
                      </ngx-skeleton-loader>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mp-im">
                <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '80px',
                'height': '20px',
                'background-color': '#daddd8',
                'margin-top':'0px'
            }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '80px',
                'height': '20px',
                'background-color': '#daddd8',
                'margin-top':'0px'
            }">
                </ngx-skeleton-loader>
              </div>
            </div>
          </div>
    
        </mat-card>
      </div>
    </ng-template>



  </div>
  <div >

<app-scg-gra-glo-yea-bar-cli [vYear]="vYear" [vCh]="vCh" [vCli]="vCliD" [vCliEpi]="vCli" ></app-scg-gra-glo-yea-bar-cli>

    
  </div>



</div>

<section class="footer">
</section>