import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from "../../../services/customer.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChannelService } from "./../../../services/channel.service";
import { BehaviorSubject } from 'rxjs';

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}


@Component({
  selector: 'app-add-cliente',
  templateUrl: './add-cliente.component.html',
  styleUrls: ['./add-cliente.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class AddClienteComponent implements OnInit {

  aFormGroup: FormGroup;
  hide = true;
  file: File;
  ima64: String | ArrayBuffer;
  ima64Decode: String | ArrayBuffer;
  photoSelected: String | ArrayBuffer;
  cod_epicor = new FormControl('', [Validators.required]);
  nombre = new FormControl('', [Validators.required]);
  descripcion = new FormControl('', [Validators.required]);
  ima = new FormControl('');
  ciudad = new FormControl('', [Validators.required]);
  provincia = new FormControl('', [Validators.required]);
  // telefono = new FormControl('', [Validators.required]);
  grupo = new FormControl('', [Validators.required]);
  newCliente = {}
  obs = new BehaviorSubject<any>(null);
  arrayCanales: any = [];
  canales = new FormControl('', [Validators.required]);


  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private fb: FormBuilder,
    private channelService: ChannelService,
    public customerService: CustomerService,
    public dialogRef: MatDialogRef<AddClienteComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: '') { }

  ngOnInit() {
    this.getAllCanal();
    this.aFormGroup = this._formBuilder.group({
      cod_epicor: ['', Validators.required],
      nombre: ['', Validators.required],
      ima: ['',],
      descripcion: ['',],
      ciudad: ['',],
      provincia: ['',],
      telefono: ['',],
      grupo: ['',],
      // canal: ['', Validators.required],
    });
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obs.next(resp);

    });
  }

  getValues(event) {
    this.arrayCanales.push(event.source.value);
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onClose() {
    this.dialogRef.close();
  }


  onFileSelected(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file = <File>event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.photoSelected = reader.result;
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.ima64 = reader.result;
        this.photoSelected = reader.result;
      };
    }
  }




  add() {

    const dateNa = new Date();
    const ima = this.aFormGroup.get('ima').value;
    if (this.aFormGroup.valid) {
      const cod_epicor = this.aFormGroup.get('cod_epicor').value;
      const razon_social = this.aFormGroup.get('nombre').value;
      const des = this.aFormGroup.get('descripcion').value;
      const ima = this.aFormGroup.get('ima').value;
      const provincia = this.aFormGroup.get('provincia').value;
      const ciudad = this.aFormGroup.get('ciudad').value;
      const grupo = this.aFormGroup.get('grupo').value;
      const canales = this.canales.value;
      this.newCliente = {
        provincia:provincia,
        ciudad:ciudad,
        grupo:grupo,
        id_epicor: cod_epicor,
        razon_social: razon_social,
        descripcion: des,
        ima_cliente: this.ima64,
        canales: canales
      }

      this.customerService.registroCliente(this.newCliente)
        .subscribe(
          res => {
            this.onClose();
            this.openSnackBar(res.status, 'ok');
            this.customerService.clienteSer$.emit();
          },
          err => {
            console.log(err);
            this.openSnackBar(err.error, 'alert');
          });

    }
  }

  decodeBase64(ima64Endode) {
    this.photoSelected = ima64Endode;
  }

  getErrorMessageCodEpicor() {
    if (this.cod_epicor.hasError('required')) {
      return 'Se requiere el Codigo EPICOR';
    }
  }

  getErrorMessageRazon_social() {
    if (this.nombre.hasError('required')) {
      return 'Se requiere el nombre del cliente';
    }
  }


  getErrorMessageDes() {
    if (this.descripcion.hasError('required')) {
      return 'Se requiere la descripcion del cliente';
    }
  }


  getErrorMessageCiudad() {
    if (this.ciudad.hasError('required')) {
      return 'Se requiere la descripcion de la ciudad';
    }
  }

  getErrorMessageProvincia() {
    if (this.provincia.hasError('required')) {
      return 'Se requiere la descripcion de la provincia';
    }
  }


  // getErrorMessageTelefono() {
  // if (this.telefono.hasError('required')) {
  // return 'Se requiere la descripcion de la telefono';
  // }
  // }

  getErrorMessageGrupo() {
    if (this.grupo.hasError('required')) {
      return 'Se requiere la descripcion de la grupo';
    }
  }


}
