import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { TaskDelComponent } from './task-del/task-del.component';
import { TaskUpComponent } from './task-up/task-up.component';
import { AreaService } from 'src/app/services/area.service';
var moment = require('moment');

@Component({
  selector: 'app-task-table',
  templateUrl: './task-table.component.html',
  styleUrls: ['./task-table.component.css'],
})
export class TaskTableComponent implements OnInit {
  data: boolean;
  obsTask = new BehaviorSubject<any>(null);

  @Input() vAr: string;

  constructor(private areaService: AreaService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getAllArea(this.vAr);
    this.getRefreshTask(this.vAr);

    // this.getCronograma('2022-07-27', '2022-08-10');
  }

  getRefreshTask(vAr) {
    this.areaService.oTask$.subscribe((val) => {
      this.getAllArea(vAr);
    });
  }

  getFormatDate(date) {
    return moment(date).format('MMM. DD');
  }

  getAllArea(vAr) {
    this.areaService.getTaskAreaId({ vAr: vAr }).subscribe((resp) => {
      if (resp.length > 0) {
        this.data = true;
      } else {
        this.data = false;
      }


      this.obsTask.next(resp);
    });
  }

  trackByFn(index: number, _item: unknown) {
    return index;
  }

  getDayDiffRest(dateStart, dateEnd) {
    let rdays;
    let rToSay = moment();
    let rStar = moment(dateStart);
    let rEnd = moment(dateEnd);

    if (moment().isBetween(dateStart, dateEnd)) {
      rdays = rEnd.diff(rToSay, 'days');
    } else {
      rdays = rEnd.diff(rStar, 'days');
    }

    return rdays + 1;
  }

  getDayDiff(dateStart, dateEnd) {
    let rStar = moment(dateStart);
    let rEnd = moment(dateEnd);
    let rdays = rEnd.diff(rStar, 'days');

    return rdays + 1;
  }


  getFortmat(date) {
    let rStar = moment(date).format("YYYY-MM-DD");


    return rStar;
  }


  getCronograma(dateStart, dateEnd) {
    let rStar = moment(dateStart);
    let rEnd = moment(dateEnd);

    let rdays = rEnd.diff(rStar, 'days');
    let rToDay = moment(new Date());
    let rFaldays = rEnd.diff(rToDay, 'days');
    let resul = (rFaldays / rdays) * 100;

    return resul + 1;
  }

  onDialogDel(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(TaskDelComponent, dialogConfig);
  }

  onDialogUp(vTa) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80%';
    dialogConfig.data = { vTa: vTa, vAr: this.vAr };
    this.dialog.open(TaskUpComponent, dialogConfig);
  }
}
