import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VenSemAdminService } from "../../../../../services/sales/ven-sem-admin.service";

@Component({
  selector: 'app-list-num-pro',
  templateUrl: './list-num-pro.component.html',
  styleUrls: ['./list-num-pro.component.css']
})
export class ListNumProComponent implements OnInit {
  dataPro = new BehaviorSubject<any>(null);
  @Input() idproducto: String;
  @Input() vYear: Number;
  @Input() vD: Date;
  @Input() vH: Date;
  celusd: boolean;
  constructor(
    private venSemAdminService: VenSemAdminService
  ) { }

  ngOnInit(): void {
    this.getGraVenSemProFolio(this.idproducto, this.vYear, this.vD, this.vH)
  }

  getGraVenSemProFolio(idproducto, vYear, vD, vH) {
    this.venSemAdminService.getGraVenSemProFolio({
      _id_producto: idproducto,
      desde: vD,
      hasta: vH,
      yeara: vYear
    }).subscribe((resp) => {
      this.dataPro.next(resp);
      var total = resp.map(d => d.venta_uds).reduce((sum, d) => sum + d, 0);
      if (total>0) {
        this.celusd=true;
      }      
    });
  }

}