import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { VenMesAdminService } from './../../../../services/sales/ven-mes-admin.service';
import { BehaviorSubject } from 'rxjs';
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
@Component({
  selector: 'app-s-l-c-espcifico',
  templateUrl: './s-l-c-espcifico.component.html',
  styleUrls: ['./s-l-c-espcifico.component.css'],
})
export class SLCEspcificoComponent implements OnInit {
  @Input() vYear: any;
  @Input() vCli: string;
  @Input() vCh: string;
  @Input() vRs: string;
  @Input() vNum: number;
  @Input() vCliD: number;

  wiat = [];
  multi: any[];
  view: any[] = [700, 300];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'MXN';
  timeline: boolean = false;
  legendPosition = 'below';
  customColors: any[] = [
    { name: 'PRE 2022', value: '#0f69f1' },
    { name: 'C MINIMO 2022', value: '#efb810' },
    { name: 'VEN 2022', value: '#26a116' },

    { name: 'PRE 2021', value: '#0f69f1' },
    { name: 'C MINIMO 2021', value: '#efb810' },
    { name: 'VEN 2021', value: '#26a116' },

    { name: 'PRE 2020', value: '#0f69f1' },
    { name: 'C MINIMO 2020', value: '#efb810' },
    { name: 'VEN 2020', value: '#26a116' },

    { name: 'PRE 2019', value: '#0f69f1' },
    { name: 'C MINIMO 2019', value: '#efb810' },
    { name: 'VEN 2019', value: '#26a116' },

    { name: 'PRE 2018', value: '#0f69f1' },
    { name: 'C MINIMO 2018', value: '#efb810' },
    { name: 'VEN 2018', value: '#26a116' },

    { name: 'PRE 2017', value: '#0f69f1' },
    { name: 'C MINIMO 2017', value: '#efb810' },
    { name: 'VEN 2017', value: '#26a116' },
  ];
  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };

  obsGlo = new BehaviorSubject<any>(null);
  data: any;
  gd1m: boolean;
  gd1: boolean;
  gd2m: boolean;
  gd2: boolean;
  gra1: boolean;
  constructor(private venMesAdminService: VenMesAdminService) {}
  ngOnInit(): void {
    this.gd2 = false;
    this.gra1=false;
    this.generateRandomInt();
    // this.acuCliGen(this.vYear, this.vCli, this.vCh);
    // this.masterPreCusVenYearBlo(this.vYear, this.vCli);
  }

  ngOnChanges(changes: any) {
    this.gd2 = false;
    this.gra1=false;
    const nvYear = changes.vYear.currentValue;
    this.acuCliGen(String(nvYear), this.vCli, this.vCh);
    // this.masterPreCusVenYearBlo(this.vYear, this.vCli);
  }

  // masterPreCusVenYearBlo(vYear, vCliEP) {
  //   this.venMesAdminService
  //     .masterPreCusVenYearBlo({ vYear: vYear, vCliEP: vCliEP })
  //     .subscribe((resp) => {
  //       if (resp.length > 0) {
  //         this.gd2m = true;
  //       } else {
  //         this.gd2m = false;
  //       }
  //       this.multi = resp;
  //       this.gd2 = true;
  //     });
  // }

  acuCliGen(vYear, vCliEP, vCh) {
    this.venMesAdminService
      .acuCliGenGrupo({ vYear: vYear, vCliEP: vCliEP, vCh: vCh })

      .subscribe(async (resp) => {
        if (resp.length > 0) {
          this.gd1m = true;
        } else {
          this.gd1m = false;
        }
      

        if (resp[0].vCliD === '1050116005') {
          const respOpeCD = resp.find((dat) => dat.vCliD === '1050116005');
          const respOpeCDIndex = resp.findIndex(
            (dat) => dat.vCliD === '1050116005'
          );

          let opeReinosa = await this.getNormalizacion(
            vYear,
            '1050116004',
            vCh
          );


          let rventa = respOpeCD.venta;
          let rventamxn = respOpeCD.ventamxn;
          let rventauds = respOpeCD.ventauds;
          let voc_a = respOpeCD.ordencompra;
          let oc_moneda = respOpeCD.oc_moneda;
          let rpremxn = respOpeCD.premxn;
          let rpresupuesto = respOpeCD.premxn;
          let rpreuds = respOpeCD.preuds;
          let rtc = respOpeCD.tc;
          let rvCli = respOpeCD.vCli;
          let rvCliD = respOpeCD.vCliD;
          let rvD = respOpeCD.vD;
          let rvH = respOpeCD.vH;
          let rvolPre = respOpeCD.arUni[0].volumen;
          let rvolUniPre = respOpeCD.arUnipre[0].volumen;

          let nventa = opeReinosa[0].venta;
          let nventamxn = opeReinosa[0].ventamxn;
          let nventauds = opeReinosa[0].ventauds;
          let nvolPre = opeReinosa[0].arUni[0].volumen;

          let voc_b = opeReinosa[0].ordencompra;

          let newObj = {
            arUni: [
              { volumen: Number(rvolPre) + Number(nvolPre), unidad: 'Pzas.' },
            ],
            arUnipre: [
              { volumen: Number(rvolUniPre), unidad: 'Pzas.' },
            ],
         
            premxn: rpremxn,
            presupuesto: rpresupuesto,
            preuds: rpreuds,
            tc: rtc,
            vCli: rvCli,
            vCliD: rvCliD,
            vD: rvD,
            vH: rvH,
            venta: Number(rventa) + Number(nventa),
            ventamxn: Number(rventamxn) + Number(nventamxn),
            ventauds: Number(rventauds) + Number(nventauds),
            ordencompra:  Number(voc_a) + Number(voc_b),
            oc_moneda:  oc_moneda ? oc_moneda: "MXN"
          };

          resp.splice(respOpeCDIndex, 1);

          resp.push(newObj);
          this.obsGlo.next(resp);
        } else {
          this.obsGlo.next(resp);
        }

        this.gd1 = true;
      });
  }

  getResultado(a, b) {
    if (a.length > 0 && b.length > 0) {
      var va = a[0].volumen;
      var vb = b[0].volumen;
      return vb - va;
    } else {
      return '';
    }
  }

  getResBol(a, b) {
    if (a.length > 0 && b.length > 0) {
      var va = a[0].volumen;
      var vb = b[0].volumen;
      return (vb - va) * 8;
    } else {
      return '';
    }
  }

  generateRandomInt() {
    for (let i = 0; i < 5; i++) {
      this.wiat.push({
        'border-radius': '5px',
        width: '30px',
        padding: '5px',
        margin: '7px',
        height: Math.floor(Math.random() * (180 - 20) + 20) + 'px',
        'background-color': '#daddd8',
      });
    }
    return this.wiat;
  }

  async getNormalizacion(vYear, vCliEP, vCh) {
    // this.venMesAdminService
    // .acuCliGenGrupo({ vYear: vYear, vCliEP: vCliEP, vCh: vCh }).subscribe(data => {

    // });

    let respuesta;
    await this.venMesAdminService
      .acuCliGenGrupo({ vYear: vYear, vCliEP: vCliEP, vCh: vCh })
      .toPromise()
      .then((response) => {
        respuesta = response;
      })
      .catch((e) => console.error(e));
    return respuesta;
  }




  
}
