<div class="conte-pro">
    <div class="titulo-row-b">
        <button mat-icon-button>
            <mat-icon>insert_chart_outlined</mat-icon>
        </button>
        Canal de venta
        <mat-divider></mat-divider>
    </div>
    <mat-tab-group class="tabs-master-cli">

        <mat-tab label="Listado Especifico">
<app-ch-list-grupo ></app-ch-list-grupo>
        </mat-tab>
        <mat-tab label="Listado de Canales">
            <div class="wrapper" *ngIf="loading;else content">
                <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
            </div>
            <ng-template #content>
                <div class="ct-panel">
                    <div> <button class="btn mat-list-item" color="primary" mat-raised-button mat-button
                            (click)="onDialogAdd()">
                            <mat-icon matListIcon>add</mat-icon> Registrar Nuevo
                        </button>
                    </div>
                    <div class="table-data">
                        <table mat-table [dataSource]="dataResult" class="mat-elevation-z8 table-data">
                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef> Fecha de Registro </th>
                                <td mat-cell *matCellDef="let element"> {{formatDate(element.createdAt)}} </td>
                            </ng-container>
                            <ng-container matColumnDef="canal">
                                <th mat-header-cell *matHeaderCellDef> Canal </th>
                                <td mat-cell *matCellDef="let element"> {{element.canal}} </td>
                            </ng-container>
                            <ng-container matColumnDef="descri">
                                <th mat-header-cell *matHeaderCellDef> Descripción </th>
                                <td mat-cell *matCellDef="let element"> {{element.obs}} </td>
                            </ng-container>
                            <ng-container matColumnDef="op">
                                <th mat-header-cell *matHeaderCellDef> Opciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                                        <mat-icon>more_horiz</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button matTooltip="¿Seguro que deseas borrar este canal?"
                                            matTooltipClass="example-tooltip-red" mat-menu-item
                                            (click)="onDelAsig(element._id)">
                                            <mat-icon>delete_forever</mat-icon>
                                            <span>Eliminar</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <!-- <mat-paginator [length]="3" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator> -->
                    </div>
                </div>



            </ng-template>
        </mat-tab>
        <mat-tab label="Agrupación">
            <ng-template matTabContent>
                <div class="ct-tra" *ngIf="bttn>0">
                    <div>
                        <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                            <mat-chip class="example-box chip" cdkDrag *ngFor="let ens of selection.selected"
                                [removable]="removable" (removed)="remove(ens)">
                                {{ens.razon_social}}
                                <mat-icon class="ico-rs" matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                    <div>
                        <button class="dbtnas" [disabled]="!btt" color="accent" mat-raised-button mat-button
                            (click)="onDialogTras()">
                            <mat-icon>swap_horiz</mat-icon> Trasladar a <span *ngIf="bttn>0"> ({{bttn}}) </span>
                        </button>
                    </div>
                </div>
                <div class="ct-panel-a">
                    <div class="ct-sa">
                        <div class="ct-canal" *ngFor="let env of obs | async" (click)="exeCustData(env.canal, env._id)"
                            [class.ct-canals]="condi===env.canal">
                            <div class="ct-canal-nom">
                                <div class="ct-ico">
                                    <mat-icon class="ct-ico-f">{{env.ico}}</mat-icon>
                                </div>
                                <div class="ct-nom-c"> {{env.canal}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="wrapper" *ngIf="loadingc;else contentc">
                        <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
                    </div>
                    <ng-template #contentc> -->
                    <div [hidden]="!schemaStatus">
                        <mat-form-field class="mat-form-field-filtro" appearance="fill">
                            <mat-label>Filtro</mat-label>
                            <input matInput [formControl]="fils"  (keyup)="applyFilter($event)" placeholder="Ejemplo: Profusa Product"
                                autocomplete="off" />
                        </mat-form-field>

                        <table mat-table [dataSource]="dataSourceCli" multiTemplateDataRows class="table-data">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="cod">
                                <th mat-header-cell *matHeaderCellDef> Código </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.id_epicor}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ima">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                    <app-ima-cliente [idcliente]="element.id_epicor" [classCss]="'sm'">
                                    </app-ima-cliente>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="razon_social">
                                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                <td mat-cell *matCellDef="let element"> {{element.razon_social}} </td>
                            </ng-container>
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsCli.length">

                                    <div class="example-element-detail"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <app-ch-product-asig [vCh]="vCh" [vCli]="element._id"></app-ch-product-asig>
                                    </div>

                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsCli" class="ct-he"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumnsCli;"
                                class="example-element-row " [class.example-expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row">
                            </tr>
                        </table>
                        <mat-paginator #primaryPaginator [length]="5" [pageSize]="5"
                            [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator>
                    </div>
                <!-- </ng-template> -->
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Cliente Sin Agrupación">
            <ng-template matTabContent>
                <app-ch-asignador></app-ch-asignador>
            </ng-template>
        </mat-tab>

        <mat-tab label="Producto Sin Agrupación">
            <ng-template matTabContent>
                <app-ch-asignador-pro></app-ch-asignador-pro>
            </ng-template>
        </mat-tab>



    </mat-tab-group>
</div>