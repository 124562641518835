import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VenMesAdminService } from './../../../../services/sales/ven-mes-admin.service';
import * as moment from 'moment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-scg-global-year',
  templateUrl: './scg-global-year.component.html',
  styleUrls: ['./scg-global-year.component.css'],
})
export class ScgGlobalYearComponent implements OnInit {
  @Input() vYear: string;
  @Input() vCh: string;
  listRango: any = [];
  obsGlo = new BehaviorSubject<any>(null);
  show: boolean;
  gd1m: boolean;
  gd1: boolean;

  constructor(
    private venMesAdminService: VenMesAdminService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.show = false;
    this.gd1m=true;
    this.acuCliGenGlobalYear(this.vYear, this.vCh);
  }

  ngOnChanges(changes: any) {
    this.show = true;
    const nvYear = changes.vYear.currentValue;
    this.acuCliGenGlobalYear(String(nvYear), this.vCh);
  }

  acuCliGenGlobalYear(vYear, vCh) {
    this.venMesAdminService
      .acuCliGenGlobalYear({ vYear: vYear, vCh: vCh })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.gd1m = true;
          this.obsGlo.next(resp);
        } else {
          this.gd1m = false;
        }
        this.show = true;
      });
  }
}
