import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from "./../../../../services/customer.service";


@Component({
  selector: 'app-ejecutivo-id',
  templateUrl: './ejecutivo-id.component.html',
  styleUrls: ['./ejecutivo-id.component.css']
})
export class EjecutivoIdComponent implements OnInit {

  datosEjecutivos: Ejecutivo = new Ejecutivo();
  @Input() idejecutivo: string;
  @Input() arraydata: [];
  
  constructor(
    private customerService: CustomerService

  ) { }

  ngOnInit(): void {

    this.getDatosEjecutivo_ID(this.idejecutivo);
  }

  getDatosEjecutivo_ID(idejecutivo) {
    this.customerService.getDatosEjecutivo_ID({ _id_ejecutivo: idejecutivo }).subscribe(
      resp => {
       
        this.datosEjecutivos = resp;
      });
  }

  validateIma(valor) {
    if (valor != "null")
      return valor;
    else
      return "assets/ima/no-ima.png";
  }

}
export class Ejecutivo {
  id: string;
  nombre: string;
  apellido: string;
  email: string;
  imagen?: string;
}

