import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { GlobalVariable } from '../global';
@Injectable({
providedIn: 'root'
})
export class ProyectoService {

private URL = GlobalVariable.BASE_API_URL;
public proyectoSer$ = new EventEmitter<any>();
public idProyecto:any;
constructor( private http: HttpClient,
private router: Router, ) { }

registroProyecto(proyecto){
return this.http.post<any>(this.URL + 'registraProyecto', proyecto);
}

getAllProyecto() {
return this.http.get<any>(this.URL + 'getAllProyecto');
}

getAllProyectoVendedor(){
return this.http.get<any>(this.URL + 'getAllProyectoVendedor');
}

delProyecto(id){
return this.http.post<any>(this.URL + 'borraProyecto', id);
}

getOneProyecto(id){
return this.http.post<any>(this.URL + 'getOneProyecto', id);
}

getUpdateProyecto(proyecto){
return this.http.post<any>(this.URL + 'updateProyecto', proyecto);
}

getCountProyectoId(){
return this.http.get<any>(this.URL + 'getCountProyectoId');
}

getImageRem(){
return this.http.get<any>(this.URL + 'getImageRem');
}

getAllTipoProyecto(){
return this.http.get<any>(this.URL + 'getAllTipoProyecto');
}

setId(id: string) {
this.idProyecto = id;
}

getId() {
return this.idProyecto;
}


}
