<div class="ct-mats">
    <mat-card class="mat-card-pre">
        <div *ngIf="loading; else nodata" class="ct-model">
            <div class="item">
                <app-ima-cliente [idcliente]="idepicor"></app-ima-cliente>
            </div>
            <div class="item">
                {{ datacliNombre | async}}
            </div>
        </div>

        <ng-template #nodata>
            <div class="ct-models">
                <div class="item">
                    <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '55px',
            'padding':'7px',
            'margin':'7px',
            'height': '40px',
            'background-color': '#daddd8'
        }">
                    </ngx-skeleton-loader>
                </div>
                <div class="item">
                    <ngx-skeleton-loader count="2" [theme]="{ 
            'border-radius': '5px', 
            'width': '280px',
            'padding':'1px',
            'margin':'1px',
            'height': '15px',
            'background-color': '#daddd8'
        }">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </ng-template>




        <app-per-cli-cvp [vYear]="vYear" [vSem]="vSem" [vD]="vD" [vH]="vH" [vCliente]="idepicor"></app-per-cli-cvp>

    </mat-card>
</div>