<div class="conte-pro">
  <div class="titulo-row-b">
    Presupuesto Detalle Año <span class="ct-vYear">{{vYear}} </span>
  </div>
  <mat-divider></mat-divider>

  <section class="ct-pre-firs">
    <div class="ct-pre-gra">
      <app-gra-gru-mes-year [vYear]="vYear"></app-gra-gru-mes-year>
    </div>
    <div class="ctp-panel">
      <app-gra-ven-pre [vYear]="vYear"></app-gra-ven-pre>
    </div>
  </section>





  <section class="ct-pre-secons">
    <div class="titulo-row-b">
      <button mat-icon-button>
        <mat-icon>insert_chart_outlined</mat-icon>
      </button>
      Presupuesto Mes:
      <mat-form-field class="sele" (change)="updateAn($event.target.value)">
        <select matNativeControl>
          <option *ngFor="let lv of listRango" [value]="lv.mesNumber">
            {{ lv.mesNombre }}
          </option>
        </select>
      </mat-form-field>

    </div>
    <mat-divider></mat-divider>
    <section class="grafspace">
      <app-gra-cli-mes *ngIf="grafCli" [vYear]="vYear" [vMes]="vMes"></app-gra-cli-mes>
    </section>


    <mat-divider></mat-divider>

    <div class="ct-titulo-glo">

      <div class="titulo-row-b">
        <button mat-icon-button>
          <mat-icon>attach_money</mat-icon>
        </button>
        Actividad Global Pre Mes: <span class="datef"> <span class="datefg"> {{getMes(this.vRowGet.vMesL)}} </span> <span
            class="datefgy">{{this.vRowGet.vYeara}}</span> </span>
      </div>
  
      <div>
        <form [formGroup]="criterioCh" method="post">
          <mat-form-field appearance="fill" class="mat-form-f">
            <mat-label>Canal</mat-label>
            <mat-select (selectionChange)="updateCh()" formControlName="canal" required>
  
              <mat-option [value]=""> TODOS </mat-option>
              <mat-option *ngFor="let ch of obsCh | async" [value]="ch._id"> {{ch.canal}} </mat-option>
  
            </mat-select>
          </mat-form-field>
  
        </form>
  
      </div>
  
    </div>
  

<div *ngIf="gGlo;else nodaglo">
  <div *ngFor="let env of obsGlo | async">
    <app-pre-mensual [tCop]="tCop" [vEnv]="env"></app-pre-mensual>
  </div>
</div>
   


    <ng-template #nodaglo>

      <app-info-data [mensaje]="'No hay venta por mostrar'" [pd]=""> </app-info-data>
    
    </ng-template>




  </section>
  <section class="footer">
  </section>
</div>