<!-- <div class="ct-ima" matTooltip="{{obsNombreApellido | async}}">
    <img [src]="validateIma(obs | async) " 
        alt="Imagen usuario"
        class="ct-ima-layout ct-ima-post" 
        width="46"
        height="46">
</div> -->


<div class="ct-ima" *ngIf="ig; else no_ima">

    <div *ngIf="iga; else no_ima_p" matTooltip="{{obsNombreApellido | async}}">
        <img [src]="validateIma(imagen)" class="ct-ima-layout ct-ima-post" width="30" height="30">
    </div>

    <ng-template #no_ima_p>
        <div class="ct-imas" matTooltip="{{obsNombreApellido | async}}">
            <div class="ct-inf">
                {{ getSter(obsNombreApellido | async) }}
            </div>
        </div>

    </ng-template>

</div>
<ng-template #no_ima>
    <div class="ct-imas">
        <ngx-skeleton-loader appearance="circle" count="1" [theme]="{ 
            'border-radius': '50px', 
            'width': '30px',
            'padding':'0px',
            'margin':'0px',
            'height': '30px',
            'background-color': '#daddd8'
        }">
        </ngx-skeleton-loader>
    </div>
</ng-template>