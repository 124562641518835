<div class="ct">

    <div>
        <div class="ct-ti-b">
            <div> <button mat-icon-button>
                    <mat-icon>navigate_before</mat-icon>
                </button></div>
            <div> Nuevo Integrante</div>
            <div class="ct-ti-b-s"> <span class="ct-bt-c" (click)="ptakOff()">Cancelar</span></div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <form [formGroup]="formPurchaseOrder">


        <div class="ct-for">

            <div>
                <mat-form-field class="in-for">
                    <mat-label>Responsable</mat-label>
                    <mat-select formControlName="responsable">
                        <mat-option *ngFor="let cl of miAr" [value]="cl._id"> {{cl.nombre}} {{cl.apellido}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="ct-bt">
                <button (click)="add();" class="btn" color="primary" mat-raised-button mat-button>
                    <mat-icon matListIcon>add</mat-icon>
                </button>
            </div>
        </div>


    </form>

    <mat-divider></mat-divider>


</div>