
<form [formGroup]="criterioCliente">

<div class="c1 titulo-row-b">
  Nuevo Presupuesto
  <mat-divider></mat-divider>
</div>

<div class="c2">
  <mat-form-field appearance="fill">
  <mat-label>Año</mat-label>
  <mat-select formControlName="an" (selectionChange)="listado($event)" >
    <mat-option [value]="2022">2022</mat-option>
    <mat-option [value]="2021">2021</mat-option>
    <mat-option [value]="2020">2020</mat-option>
    <mat-option [value]="2019">2019</mat-option>
    <mat-option [value]="2018">2018</mat-option>
    <mat-option [value]="2017">2017</mat-option>
  </mat-select>
  </mat-form-field>
</div>

<div class="c3">

  <mat-form-field appearance="fill">
  <mat-label>Mes</mat-label>
  <mat-select formControlName="mes" (selectionChange)="listado($event)"  >
  <mat-option value="ENERO">ENERO</mat-option>
  <mat-option value="FEBRERO">FEBRERO</mat-option>
  <mat-option value="MARZO">MARZO</mat-option>
  <mat-option value="ABRIL">ABRIL</mat-option>
  <mat-option value="MAYO">MAYO</mat-option>
  <mat-option value="JUNIO">JUNIO</mat-option>
  <mat-option value="JULIO">JULIO</mat-option>
  <mat-option value="AGOSTO">AGOSTO</mat-option>
  <mat-option value="SEPTIEMBRE">SEPTIEMBRE</mat-option>
  <mat-option value="OCTUBRE">OCTUBRE</mat-option>
  <mat-option value="NOVIEMBRE">NOVIEMBRE</mat-option>
  <mat-option value="DICIEMBRE">DICIEMBRE</mat-option>

  </mat-select>
  </mat-form-field>

</div>






<div class="bloque-a">


    <mat-form-field>
      <input [formControl]="clienteF" type="text" placeholder="Cliente" aria-label="Number" matInput [matAutocomplete]="autoCliente">
      <mat-autocomplete autoActiveFirstOption #autoCliente="matAutocomplete">
        <mat-option *ngFor="let optionCliente of filteredOptionsCliente | async" [value]="optionCliente.razon_social" (click)="setCliente(optionCliente._id);">
          <div class="listadoProducto">
            {{optionCliente.id_epicor}} - {{optionCliente.razon_social}}
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>


    <mat-form-field class="example-full-width">
      <input type="text" placeholder="Ejemplo: Fresa..." aria-label="Number" matInput [formControl]="productoF" [matAutocomplete]="autoProducto">
      <mat-autocomplete autoActiveFirstOption #autoProducto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre" (click)="setProducto(option._id);">
          <div class="listadoProducto">
            {{option.numparte}} - {{option.nombre}}
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

<div class="banda">
<div class="columna1">
<mat-form-field appearance="fill">
<mat-label>Unidad</mat-label>
<mat-select formControlName="unidad">
  <mat-option *ngFor="let uni of unidades" [value]="uni._id">
    {{uni.nombre}}
  </mat-option>
</mat-select>
</mat-form-field>
</div>
<div class="columna2">
  <mat-form-field  appearance="fill">
  <mat-label>Volumen</mat-label>
  <input  matInput formControlName="volumen" autocomplete="off" type="number" (keyup)="ct($event.target.value)"/>
  </mat-form-field>
</div>
<div class="columna3">
  <mat-form-field  appearance="fill">
  <mat-label>Precio x Unidad</mat-label>
  <input  matInput formControlName="precio" autocomplete="off"  currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }" (keyup)="ct($event.target.value)"/>
  </mat-form-field>
</div>
<div class="columna4">
<mat-form-field  appearance="fill">
<mat-label>Total</mat-label>
<input  matInput formControlName="monto" autocomplete="off"  currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }" />
</mat-form-field>
</div>
<div class="columna5">

    <div class="btn">
      <button (click)="add();" class="mat-list-item" color="primary" mat-raised-button mat-button >
        <mat-icon matListIcon>add</mat-icon> Agregar
      </button>

  </div>
</div>
</div>
</div>
</form>
<div  *ngIf="this.table">

  <table mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">
      <!-- <ng-container matColumnDef="an">
      <th mat-header-cell *matHeaderCellDef> A#o </th>
      <td mat-cell *matCellDef="let element"> {{element.an}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="mes">
      <th mat-header-cell *matHeaderCellDef> Mes </th>
      <td mat-cell *matCellDef="let element"> {{element.mes}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
      </ng-container> -->

      <ng-container matColumnDef="producto">
      <th mat-header-cell *matHeaderCellDef> Producto </th>
      <td mat-cell *matCellDef="let element"> {{element._id_producto.nombre}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="volumen">
      <th mat-header-cell *matHeaderCellDef> Volumen </th>
      <td mat-cell *matCellDef="let element"> {{element.volumen}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="unidad">
      <th mat-header-cell *matHeaderCellDef> Unidad </th>
      <td mat-cell *matCellDef="let element"> {{element._id_unidad.acro}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="precio">
      <th mat-header-cell *matHeaderCellDef> Precio </th>
      <td mat-cell *matCellDef="let element"> {{montoValue(element.precio)}} </td>
      <td mat-footer-cell *matFooterCellDef><b>TOTAL</b></td>
      </ng-container>

      <ng-container matColumnDef="monto">
      <th mat-header-cell *matHeaderCellDef> Monto </th>
      <td mat-cell *matCellDef="let element"> {{montoValue(element.monto)}} </td>
      <td mat-footer-cell *matFooterCellDef> <b>{{getTotalCost() | currency}}</b> </td>
      </ng-container>

      <ng-container matColumnDef="opciones">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
      <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
      <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red" mat-menu-item (click)="onDelAsig(element._id)">
      <mat-icon>delete_forever</mat-icon>
      <span>Eliminar</span>
      </button>

      </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <mat-paginator [length]="3" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>

</div>
