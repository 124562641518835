<div class="titulo-row-b">
  Nuevo Producto
</div>
<mat-divider></mat-divider>

<form [formGroup]="aFormGroup" method="post">



  <div class="ct-p">

    <div>
      <input formControlName="ima" value="" hidden (change)="onFileSelected($event)" #fileInput type="file" id="ima">
      <br>
      <mat-card class="mat-card">
        <mat-card-title class="titulo-row-b">Imagen de Producto</mat-card-title>
        <mat-card-content>
          <img mat-card-avatar [src]="photoSelected || 'assets/ima/no-ima.png'" class="imgfluid"
            (click)="fileInput.click()">
        </mat-card-content>
        <mat-card-subtitle>Click para seleccionar una imagen</mat-card-subtitle>
      </mat-card>
    </div>
    <div>
      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Codigo EPICOR</mat-label>
        <input matInput formControlName="cod_epicor" value="" required autocomplete="off">
        <mat-error *ngIf="id_parte.invalid">{{getErrorMessageCodEpicor()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" value="" required autocomplete="off">
        <mat-error *ngIf="nombre.invalid">{{getErrorMessageNombre()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="mat-form-f">
        <mat-label>Canal</mat-label>
        <mat-select formControlName="canal" required>
          <mat-option *ngFor="let env of obs | async" value="{{env._id}}"> {{env.canal}} </mat-option>

        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Descripcion</mat-label>
        <input matInput formControlName="des" value="" required autocomplete="off">
        <mat-error *ngIf="nombre.invalid">{{getErrorMessageDes()}}</mat-error>
      </mat-form-field>


      <mat-form-field class="mat-form" appearance="fill" autocomplete="off">
        <mat-label>Presentacion</mat-label>
        <input matInput formControlName="pre" value="" required>
        <mat-error *ngIf="nombre.invalid">{{getErrorMessagePre()}}</mat-error>
      </mat-form-field>



      <mat-form-field appearance="fill">
        <mat-label>Unidad de Negocio</mat-label>
        <mat-select formControlName="unidad_negocio" required>
          <mat-option class="mpanel" *ngFor="let uni of unidadNegocio" [value]="uni._id">
            <mat-grid-list cols="1" rowHeight="2:1">
              <mat-grid-tile>
                <div class="let-div">
                  {{uni.descripcion}} - {{uni._id_grupo.grupo}}
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="nombre.invalid">{{getErrorMessageUnidad()}}</mat-error>
      </mat-form-field>

      <button class="btn" color="primary" mat-raised-button mat-button (click)="add()">Registrar</button>
    </div>

    <div>


      <mat-form-field class="mat-form" appearance="fill" autocomplete="off">
        <mat-label>Unidades por caja</mat-label>
        <input matInput formControlName="ubox" value="">
      </mat-form-field>

      <mat-form-field class="mat-form" appearance="fill" autocomplete="off">
        <mat-label>Codigo de Barra Empaque</mat-label>
        <input matInput formControlName="cbag" value="">
      </mat-form-field>

      <mat-form-field class="mat-form" appearance="fill" autocomplete="off">
        <mat-label>Codigo de Barra Caja Master</mat-label>
        <input matInput formControlName="cbox" value="">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Moneda</mat-label>
        <mat-select formControlName="moneda">
          <mat-option class="mpanel" value="MX">
            MXN
          </mat-option>
          <mat-option class="mpanel" value="USD">
            USD
          </mat-option>
        </mat-select>
      </mat-form-field>


    </div>

  </div>

</form>