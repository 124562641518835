import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { default as _rollupMoment, Moment } from 'moment';
import * as _moment from 'moment';
import { SalesService } from '../../../services/sales/sales.service';
import { ChannelService } from '../../../services/channel.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-search-ven',
  templateUrl: './search-ven.component.html',
  styleUrls: ['./search-ven.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SearchVenComponent implements OnInit {
  clienteF = new FormControl();
  grupo = new FormControl();
  fil = new FormControl();
  fils = new FormControl();
  canal = new FormControl();

  optionCliente: any[] = [];
  filteredOptionsCliente: Observable<string[]>;
  obs = new BehaviorSubject<any>(null);
  obsp = new BehaviorSubject<any>(null);
  obspc = new BehaviorSubject<any>(null);
  obs_canal = new BehaviorSubject<any>(null);
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  vCli: any;
  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  public searchText: any;
  filt: boolean = false;
  showc = true;
  showp = true;
  sc: boolean;
  sp: boolean;
  spch: boolean;
  slech: boolean;
  spcha: boolean;
  showpq: boolean;
  masterVch: any;
  constructor(
    private customerService: CustomerService,
    private salesService: SalesService,
    private channelService: ChannelService,
    private _snackBar: MatSnackBar,

  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > this.showScrollHeight
    ) {
      this.showScroll = true;
    } else if (
      this.showScroll &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) < this.hideScrollHeight
    ) {
      this.showScroll = false;
    }
  }

  ngOnInit(): void {

 

  }

  exeR(event: any) {

    this.canal.reset();

    this.fils.reset();
    this.grupo.reset();
    this.obspc = new BehaviorSubject<any>(null);

  }


  exeData() {
    let matDataSe: any = {};
    let date = this.range.value;
    let grupo = this.grupo.value;


    if (date.start != null && date.end != null && grupo) {
      if (date) {
        matDataSe.vD = date.start;
        matDataSe.vH = date.end;
      }

      if (grupo) {
        matDataSe.vGro = grupo.value;
      }

      if (grupo == 'C1') {
        this.spcha = false;
        this.fil.reset();
        this.sp = false;
        this.sc = true;
        this.showc = true;

        this.salesSearchC(matDataSe);

        this.obsp = new BehaviorSubject<any>(null);
      } else if (grupo == 'C2') {
        this.spcha = false;
        this.fils.reset();
        this.sc = false;
        this.sp = true;
        this.showp = true;
        this.salesSearchP(matDataSe);
        this.obs = new BehaviorSubject<any>(null);
      } else if (grupo == 'C3') {
        this.sc = false;
        this.sp = false;
        this.spcha = true;
        this.slech = true;
        this.getAllCanal();
        this.fils.reset();

        this.salesSearchCh(matDataSe);
        this.obs = new BehaviorSubject<any>(null);
      }

      this.filt = true;
    } else {

      this.openSnackBar("El campo fecha, esta vacio", 'alert');
      this.grupo.reset();
      this.range.reset();
      this.canal.reset();
    }
  }

  updateAn() {
    this.masterVch = this.canal.value;

    

    let date = this.range.value;
    let grupo = this.grupo.value;
    let matDataCanl: any = {};
    if (date) {
      matDataCanl.vD = date.start;
      matDataCanl.vH = date.end;
      matDataCanl.vCh = this.masterVch;
    }

    this.salesFilterCh(matDataCanl);
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe((resp) => {
      this.obs_canal.next(resp);
    });
  }

  salesSearchC(data) {
    this.salesService.salesSearch(data).subscribe((resp) => {
      if (resp.length > 0) {
        this.showc = true;
        this.obs.next(resp);
      } else {
        this.showc = false;
      }
    });
  }
  salesSearchP(data) {
    this.salesService.salesSearchPro(data).subscribe((resp) => {
      if (resp.length > 0) {
        this.showp = true;
        this.obsp.next(resp);
      } else {
        this.showp = false;
      }
    });
  }
  salesSearchCh(data) {
    this.spch = true;
    this.salesService.salesSearchCh(data).subscribe((resp) => {
      if (resp.length > 0) {
        this.showpq = true;
        this.obspc.next(resp);
      } else {
        this.showpq = false;
      }
    });
  }

  salesFilterCh(data) {
    this.spch = true;
    this.salesService.salesFilterCh(data).subscribe((resp) => {
      if (resp.length > 0) {
        this.showpq = true;
        this.obspc.next(resp);
      } else {
        this.showpq = false;
      }
    });
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 5);
      }
    })();
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: [tipo]
    });
}
}
