import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pcr',
  templateUrl: './pcr.component.html',
  styleUrls: ['./pcr.component.css'],
})
export class PcrComponent implements OnInit {
  @Input() value: number;
  cals: string;
  constructor() {}

  ngOnInit(): void {
    this.validateValue();
  }

  validateValue() {
    if (this.value > 15) {
      this.cals = 'gree';
    } 
    else if (this.value < 0){ this.cals = 'gred';}
    else if (this.value >= 0 && this.value < 15){ this.cals = 'gry';}
  }
}
