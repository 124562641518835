import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BudgetService } from '../../../services/budget.service';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChannelService } from '../../../services/channel.service';

@Component({
  selector: 'app-budget-year',
  templateUrl: './budget-year.component.html',
  styleUrls: ['./budget-year.component.css'],
})
export class BudgetYearComponent implements OnInit {
  vYear: string;
  obsGlo = new BehaviorSubject<any>(null);
  obsCh = new BehaviorSubject<any>(null);
  gGlo:boolean;
  tCop = 'mM';
  selecm = new FormControl();
  listRango: any[];
  vMes: any;
  grafCli = false;
  vRowGet: any;
  criterioCh: FormGroup;
  criterioChVen: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private channelService: ChannelService,
    private budgetService: BudgetService
  ) {
    this.vYear = this.route.snapshot.paramMap.get('yeara');
  }

  ngOnInit() {
    this.getAllCanal();
    this.crearFormCh();
    let dataRest = this.getWeek(this.vYear);


    this.getPreMesAdminGlobal(
      dataRest.yeara,
      dataRest.mouth,
      dataRest.dA.convera,
      dataRest.dA.converb,
      ''
    );
    this.semCombo(dataRest.mouth);
  }

  crearFormCh() {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(),
    });
  }

  updateCh() {
    const vCh = this.criterioCh.controls.canal.value;


    this.getPreMesAdminGlobal(
      this.vRowGet.vYeara,
      this.vRowGet.vMes,
      this.vRowGet.vD,
      this.vRowGet.vH,
      vCh
    );
  }

  getWeek(vYear) {
    this.vRowGet = {};
    var mouth = moment().format('M');
    var aconvera = moment().format(vYear + '-MM-01');
    var aconverb = moment().format(vYear + '-MM-') + moment().daysInMonth();
    var yeara = moment().format(vYear);
    var yearb = moment().subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + '-MM-01');
    var bconverb = moment().format(yearb + '-MM-') + moment().daysInMonth();
    this.selecm.setValue(mouth);
    this.vMes = mouth;
    this.grafCli = true;

    this.vRowGet.vYeara = yeara;
    this.vRowGet.vD = aconvera;
    this.vRowGet.vH = aconverb;
    this.vRowGet.vMes = mouth;

    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb,
      },
      dB: {
        convera: bconvera,
        converb: bconverb,
      },
    };
    return dataFecha;
  }

  getPreMesAdminGlobal(
    vYear: String,
    vMes: String,
    vD: String,
    vH: String,
    vCh
  ) {
    this.budgetService
      .getPreMesAdminGlobal({
        vYear: vYear,
        vMes: vMes,
        vD: vD,
        vH: vH,
        vCh: vCh,
      })
      .subscribe((resp) => {
        this.obsGlo.next(resp);
        this.grafCli = true;
        this.vMes = vMes;

        if (resp.length > 0) {
          this.gGlo = true;
        } else {
          this.gGlo = false;
        }
      });
  }

  verificarMes(mes) {
    const MESES = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return MESES[mes];
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe((resp) => {
      this.obsCh.next(resp);
    });
  }

  updateAn(event) {
    this.grafCli = false;
    var mouth = event;
    var aconvera = moment().format('YYYY-' + mouth + '-01');
    var aconverb =
      moment().format('YYYY-' + mouth + '-') + moment(aconvera).daysInMonth();
    var yeara = moment().format('YYYY');
    var yearb = moment().subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + '-' + mouth + '-01');
    var bconverb =
      moment().format(yearb + '-' + mouth + '-') +
      moment(bconverb).daysInMonth();
    this.selecm.setValue(mouth);
    this.vMes = mouth;

    this.vRowGet.vYeara = yeara;
    this.vRowGet.vD = aconvera;
    this.vRowGet.vH = aconverb;
    this.vRowGet.vMes = mouth;

    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb,
      },
      dB: {
        convera: bconvera,
        converb: bconverb,
      },
    };

    this.getPreMesAdminGlobal(
      dataFecha.yeara,
      dataFecha.mouth,
      dataFecha.dA.convera,
      dataFecha.dA.converb,
      ''
    );
  }

  semCombo(sem) {
    let listSemana: any[] = [];
    for (let i = 1; i <= sem; i++) {
      listSemana.push({ mesNumber: i, mesNombre: this.getMes(i) });
    }
    this.listRango = listSemana.sort(function (a, b) {
      if (a.mesNumber < b.mesNumber) {
        return 1;
      }
      if (a.mesNumber > b.mesNumber) {
        return -1;
      }
      return 0;
    });
  }

  getMes(mes) {
    const MESES = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return MESES[mes];
  }
}
