import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-sales-channel-group',
  templateUrl: './sales-channel-group.component.html',
  styleUrls: ['./sales-channel-group.component.css'],
})
export class SalesChannelGroupComponent implements OnInit {
  vCh: string;
  criterioCh: FormGroup;
  listRango: any = [];
  vYear: any;

  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder
  ) {
    this.vCh = this.route.snapshot.paramMap.get('vCh');
  }
  ngOnInit(): void {

    const vY =this.valYearActual();
    this.vYear=vY;
    this.setList();
    this.crearFormCh(vY);
  }

  updateCh() {
    this.vYear = this.criterioCh.controls.canal.value;
  }

  valYearActual(){
    const yeara = moment().format('YYYY');
    return yeara;
  }

  crearFormCh(vY) {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(Number(vY)),
    });
  }

  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }
}
