import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ComboCharttComponent } from './components/budget/gra-com/combo-chartt.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ComboSeriesVerticalComponent } from './components/budget/gra-com/combo-series-vertical.component';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { InfoDataComponent } from './components/config-system/info-data/info-data.component';
import { WarDataComponent } from './components/config-system/war-data/war-data.component';
import { NoDataComponent } from './components/config-system/no-data/no-data.component';
import { SuccessDataComponent } from './components/config-system/success-data/success-data.component';
import { ImaClienteComponent } from './components/customer/ima-cliente/ima-cliente.component';
import { ImaProductoComponent } from './components/product/ima-producto/ima-producto.component';
import { ProyectoFilterPipe } from './components/project/proyecto.filter.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './components/config-system/paginator/dutch-paginator-intl';
//SERVICE
import { LoginService } from './services/login.service';
import { CustomerService } from './services/customer.service';
import { TokenInterceptorService } from './services/token-interceptor.service';

//COMPONENT
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MenuComponent } from './components/menu/menu.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DrolesDirective } from './directive/droles.directive';
import { BudgetComponent } from './components/budget/budget.component';
import { AddPresupuestoComponent } from './components/budget/add-presupuesto/add-presupuesto.component';
import { DelPresupuestoComponent } from './components/budget/del-presupuesto/del-presupuesto.component';
import { GraficaYearComponent } from './components/budget/grafica-year/grafica-year.component';
import { TotalvenComponent } from './components/budget/totalven/totalven.component';
import { PcrpreComponent } from './components/budget/pcrpre/pcr.component';
import { GraGruMesComponent } from './components/budget/gra-gru-mes/gra-gru-mes.component';
import { GraVenPreComponent } from './components/budget/gra-ven-pre/gra-ven-pre.component';
import { SalesComponent } from './components/sales/sales.component';
import { PanelVentaSemanaAdminComponent } from './components/sales/panel-venta-semana-admin/panel-venta-semana-admin.component';
import { CvenpreComponent } from './components/sales/panel-venta-semana-admin/cvenpre/cvenpre.component';
import { PcrvenComponent } from './components/sales/panel-venta-semana-admin/pcrven/pcrven.component';
import { CarVenSemComponent } from './components/sales/panel-venta-semana-admin/car-ven-sem/car-ven-sem.component';
import { PcrVenPreComponent } from './components/sales/panel-venta-semana-admin/car-ven-sem/pcr-ven-pre/pcr-ven-pre.component';
import { PanelVentaSemanaAdminDetalleComponent } from './components/sales/panel-venta-semana-admin-detalle/panel-venta-semana-admin-detalle.component';
import { GraVenDayComponent } from './components/sales/panel-venta-semana-admin-detalle/gra-ven-day/gra-ven-day.component';
import { PerCliVdiComponent } from './components/sales/panel-venta-semana-admin-detalle/per-cli-vdi/per-cli-vdi.component';
import { PerCliCvpComponent } from './components/sales/panel-venta-semana-admin-detalle/per-cli-cvp/per-cli-cvp.component';
import { PerCliIndComponent } from './components/sales/panel-venta-semana-admin-detalle/per-cli-ind/per-cli-ind.component';
import { PanelVentaProductosComponent } from './components/sales/panel-venta-semana-admin-detalle/panel-venta-productos/panel-venta-productos.component';
import { PvdProductoComponent } from './components/sales/panel-venta-semana-admin-detalle/panel-venta-productos/pvd-producto/pvd-producto.component';
import { PanelVentaMesAdminComponent } from './components/sales/panel-venta-mes-admin/panel-venta-mes-admin.component';
import { CarVenMesComponent } from './components/sales/panel-venta-mes-admin/car-ven-mes/car-ven-mes.component';
import { PanelVentaMesAdminDetalleComponent } from './components/sales/panel-venta-mes-admin-detalle/panel-venta-mes-admin-detalle.component';
import { GreVenMesDayComponent } from './components/sales/panel-venta-mes-admin-detalle/gre-ven-mes-day/gre-ven-mes-day.component';
import { PerCliVdiMesComponent } from './components/sales/panel-venta-mes-admin-detalle/per-cli-vdi-mes/per-cli-vdi-mes.component';
import { PerCliCpvMesComponent } from './components/sales/panel-venta-mes-admin-detalle/per-cli-cpv-mes/per-cli-cpv-mes.component';
import { PdvProductoPriceHistoComponent } from './components/sales/panel-venta-semana-admin-detalle/panel-venta-productos/pdv-producto-price-histo/pdv-producto-price-histo.component';
import { PreMensualComponent } from './components/budget/pre-mensual/pre-mensual.component';
import { PreMensualClienteLisComponent } from './components/budget/pre-mensual/pre-mensual-cliente-lis/pre-mensual-cliente-lis.component';
import { PreMensualProductoListComponent } from './components/budget/pre-mensual/pre-mensual-producto-list/pre-mensual-producto-list.component';
import { PreMensualIndComponent } from './components/budget/pre-mensual/pre-mensual-ind/pre-mensual-ind.component';
import { MargenComponent } from './components/margen/margen.component';
import { PcrComponent } from './components/margen/pcr/pcr.component';
import { MargenProductoComponent } from './components/margen/margen-producto/margen-producto.component';
import { PcrsmaComponent } from './components/margen/pcrsma/pcrsma.component';
import { MargenProductoDetalleComponent } from './components/margen/margen-producto-detalle/margen-producto-detalle.component';
import { AddClienteComponent } from './components/customer/add-cliente/add-cliente.component';
import { DelClienteComponent } from './components/customer/del-cliente/del-cliente.component';
import { UpClienteComponent } from './components/customer/up-cliente/up-cliente.component';
import { CustomerComponent } from './components/customer/customer.component';
import { DirClienteComponent } from './components/customer/dir-cliente/dir-cliente.component';
import { UpProductoComponent } from './components/product/up-producto/up-producto.component';
import { AddProductoComponent } from './components/product/add-producto/add-producto.component';
import { DelProductoComponent } from './components/product/del-producto/del-producto.component';
import { ProductoComponent } from './components/product/producto.component';
import { AsigEjecutivoComponent } from './components/customer/asig-ejecutivo/asig-ejecutivo.component';
import { AsigTrasladoComponent } from './components/customer/asig-ejecutivo/asig-traslado/asig-traslado.component';
import { EjecutivoComponent } from '././components/user/ejecutivo/ejecutivo.component';
import { EjecutivoIdComponent } from '././components/user/ejecutivo/ejecutivo-id/ejecutivo-id.component';
import { UsuariosComponent } from './components/user/usuarios.component';
import { RolesComponent } from './components/user/roles/roles.component';
import { PerfilUsuarioComponent } from './components/user/perfilUsuario/perfilUsuario.component';
import { AddRolComponent } from './components/user/roles/add-rol/add-rol.component';
import { DelRolComponent } from './components/user/roles/del-rol/del-rol.component';
import { AddUsuariosComponent } from './components/user/add-usuarios/add-usuarios.component';
import { UpUsuariosComponent } from './components/user/up-usuarios/up-usuarios.component';
import { DelUsuariosComponent } from './components/user/del-usuarios/del-usuarios.component';
import { ProPriceHistComponent } from './components/product/pro-price-hist/pro-price-hist.component';
import { ProPriceHistGraComponent } from './components/product/pro-price-hist-gra/pro-price-hist-gra.component';
import { ListProductComponent } from './components/customer/list-product/list-product.component';
import { BudgetYearComponent } from './components/budget/budget-year/budget-year.component';
import { GraGruMesYearComponent } from './components/budget/budget-year/gra-gru-mes-year/gra-gru-mes-year.component';
import { GraCliMesComponent } from './components/budget/budget-year/gra-cli-mes/gra-cli-mes.component';
import { SVenPreComponent } from './components/sales/s-ven-pre/s-ven-pre.component';
import { SalesYearComponent } from './components/sales/sales-year/sales-year.component';
import { SVenPreMesComponent } from './components/sales/s-ven-pre-mes/s-ven-pre-mes.component';
import { SearchVenComponent } from './components/sales/search-ven/search-ven.component';
import { SalesProducComponent } from './components/sales/search-ven/sales-produc/sales-produc.component';
import { SalesCliComponent } from './components/sales/search-ven/sales-cli/sales-cli.component';
import { ImaClientIdComponent } from './components/customer/ima-client-id/ima-client-id.component';
import { SalesNumProComponent } from './components/sales/search-ven/sales-cli/sales-num-pro/sales-num-pro.component';
import { ListSearchProComponent } from './components/sales/search-ven/list-search-pro/list-search-pro.component';
import { ListSearchCliComponent } from './components/sales/search-ven/list-search-cli/list-search-cli.component';
import { CriSearchRadComponent } from './components/sales/search-ven/cri-search-rad/cri-search-rad.component';
import { GraficaTop10Component } from './components/customer/grafica-top10/grafica-top10.component';
import { GraVenCusYearComponent } from './components/customer/gra-ven-cus-year/gra-ven-cus-year.component';
import { GraficaTop10ProductosComponent } from './components/product/grafica-top10-productos/grafica-top10-productos.component';
import { ChannelComponent } from './components/channel/channel.component';
import { AddChannelComponent } from './components/channel/add-channel/add-channel.component';
import { DelChannelComponent } from './components/channel/del-channel/del-channel.component';
import { TrasChannelComponent } from './components/channel/tras-channel/tras-channel.component';
import { ChProductComponent } from './components/channel/ch-product/ch-product.component';
import { TrasChannelProComponent } from './components/channel/tras-channel-pro/tras-channel-pro.component';
import { ChProductAsigComponent } from './components/channel/ch-product-asig/ch-product-asig.component';
import { ChAsignadorComponent } from './components/channel/ch-asignador/ch-asignador.component';
import { ChAsignadorProComponent } from './components/channel/ch-asignador-pro/ch-asignador-pro.component';
import { ListCustomerGeneralProductComponent } from './components/channel/ch-asignador-pro/list-customer-general-product/list-customer-general-product.component';
import { ListNumProComponent } from './components/sales/search-ven/list-search-cli/list-num-pro/list-num-pro.component';
import { CriSearchVdhComponent } from './components/sales/search-ven/cri-search-vdh/cri-search-vdh.component';
import { TraslCliSinAgrupacionComponent } from './components/channel/trasl-cli-sin-agrupacion/trasl-cli-sin-agrupacion.component';
import { TraslProSinAgrupacionComponent } from './components/channel/trasl-pro-sin-agrupacion/trasl-pro-sin-agrupacion.component';
import { LogsComponent } from './components/logs/logs.component';
import { PanelLogsComponent } from './components/logs/panel-logs/panel-logs.component';
import { MenuLogsComponent } from './components/logs/menu-logs/menu-logs.component';
import { SendReclaComponent } from './components/sales/send-recla/send-recla.component';
import { AcuCliGComponent } from './components/sales/panel-venta-mes-admin-detalle/acu-cli-g/acu-cli-g.component';
import { SalesChannelGroupComponent } from './components/sales/sales-channel-group/sales-channel-group.component';
import { ScgListCliComponent } from './components/sales/sales-channel-group/scg-list-cli/scg-list-cli.component';
import { ChListGrupoComponent } from './components/channel/ch-list-grupo/ch-list-grupo.component';
import { DelCliListComponent } from './components/channel/ch-list-grupo/del-cli-list/del-cli-list.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SLCEspcificoComponent } from './components/sales/sales-channel-group/s-l-c-espcifico/s-l-c-espcifico.component';
import { ScgGlobalYearComponent } from './components/sales/sales-channel-group/scg-global-year/scg-global-year.component';
import { ScgGraficaGlobalYearComponent } from './components/sales/sales-channel-group/scg-grafica-global-year/scg-grafica-global-year.component';
import { ScgGraficaGlobalYearVolComponent } from './components/sales/sales-channel-group/scg-grafica-global-year-vol/scg-grafica-global-year-vol.component';
import { ScgTableAcumCliComponent } from './components/sales/sales-channel-group/scg-table-acum-cli/scg-table-acum-cli.component';

import { MatSortModule } from '@angular/material/sort';
import { ScgGraficaCliYearVolComponent } from './components/sales/sales-channel-group/scg-grafica-cli-year-vol/scg-grafica-cli-year-vol.component';
import { ScgGraGloYeaBarComponent } from './components/sales/sales-channel-group/scg-grafica-global-year/scg-gra-glo-yea-bar/scg-gra-glo-yea-bar.component';
import { ScgIndicadorComponent } from './components/sales/sales-channel-group/scg-indicador/scg-indicador.component';
import { ScgGraficaCliMxnComponent } from './components/sales/sales-channel-group/scg-grafica-cli-mxn/scg-grafica-cli-mxn.component';
import { ScgGraGloYeaBarCliComponent } from './components/sales/sales-channel-group/scg-grafica-global-year/scg-gra-glo-yea-bar-cli/scg-gra-glo-yea-bar-cli.component';
import { SgcProductosComponent } from './components/sales/sales-channel-group/sgc-productos/sgc-productos.component';
import { ScgTableProComponent } from './components/sales/sales-channel-group/sgc-productos/scg-table-pro/scg-table-pro.component';
import { ScgTableProXCliComponent } from './components/sales/sales-channel-group/sgc-productos/scg-table-pro-x-cli/scg-table-pro-x-cli.component';
import { ScgCardClienteComponent } from './components/sales/sales-channel-group/sgc-productos/scg-table-pro-x-cli/scg-card-cliente/scg-card-cliente.component';
import { ScgTableGraMxnComponent } from './components/sales/sales-channel-group/sgc-productos/scg-table-pro-x-cli/scg-table-gra-mxn/scg-table-gra-mxn.component';
import { ScgTableGraVolComponent } from './components/sales/sales-channel-group/sgc-productos/scg-table-pro-x-cli/scg-table-gra-vol/scg-table-gra-vol.component';
import { PurchaseOrderComponent } from './components/purchase-order/purchase-order.component';

import { EnviosComponent } from './components/shipping/envios.component';
import { AddEnviMultipleComponent } from './components/shipping/add-envi-multiple/add-envi-multiple.component';
import { BloqueClienteComponent } from './components/shipping/add-envi-multiple/bloque-cliente/bloque-cliente.component';
import { PanelAdminEnviosComponent } from './components/shipping/panel-admin-envios/panel-admin-envios.component';
import { PanelAdminEnviosDetalleComponent } from './components/shipping/panel-admin-envios-detalle/panel-admin-envios-detalle.component';

import { AddEnviosComponent } from './components/shipping/add-envios/add-envios.component';
import { DelEnviosComponent } from './components/shipping/del-envios/del-envios.component';
import { TableProductosEnviosComponent } from './components/shipping/table-productos-envios/table-productos-envios.component';
import { TableEstadosEnviosComponent } from './components/shipping/table-estados-envios/table-estados-envios.component';
import { UpEstadosComponent } from './components/shipping/up-estados/up-estados.component';
import { PurchaseOrderAddRqComponent } from './components/purchase-order/purchase-order-add-rq/purchase-order-add-rq.component';
import { SalesChannelGenericoComponent } from './components/sales/sales-channel-generico/sales-channel-generico.component';
import { GenGraGloVolComponent } from './components/sales/sales-channel-generico/gen-gra-glo-vol/gen-gra-glo-vol.component';
import { UnidadesComponent } from './components/units/unidades.component';
import { AddUnidadesComponent } from './components/units/add-unidades/add-unidades.component';
import { DelUnidadesComponent } from './components/units/del-unidades/del-unidades.component';
import { TblProCliComponent } from './components/sales/sales-channel-group/scg-table-acum-cli/tbl-pro-cli/tbl-pro-cli.component';
import { AcuCliComponent } from './components/sales/sales-channel-generico/acu-cli/acu-cli.component';
import { ListCliComponent } from './components/sales/sales-channel-generico/acu-cli/list-cli/list-cli.component';
import { PurchaseOrderUpRqComponent } from './components/purchase-order/purchase-order-up-rq/purchase-order-up-rq.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ChartComponent } from './components/chart/chart.component';
import { GComboChartComponent } from './components/chart/g-combo-chart/g-combo-chart.component';
import { AreaComponent } from './components/area/area.component';
import { CardAreaComponent } from './components/area/card-area/card-area.component';
import { ImaUserIdComponent } from './components/user/ima-user-id/ima-user-id.component';
import { CardInfoComponent } from './components/area/card-info/card-info.component';
import { ImaClienIdCircleComponent } from './components/customer/ima-clien-id-circle/ima-clien-id-circle.component';
import { AreaDetailComponent } from './components/area/area-detail/area-detail.component';
import { ImaCardAreaComponent } from './components/customer/ima-card-area/ima-card-area.component';
import { TaskAddComponent } from './components/area/area-detail/task-add/task-add.component';
import { RatingModule } from 'ng-starrating';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ScgAcumProGComponent } from './components/sales/sales-channel-group/sgc-productos/scg-acum-pro-g/scg-acum-pro-g.component';
import { AcumProMxnComponent } from './components/sales/sales-channel-group/sgc-productos/scg-acum-pro-g/acum-pro-mxn/acum-pro-mxn.component';
import { AcumProVolComponent } from './components/sales/sales-channel-group/sgc-productos/scg-acum-pro-g/acum-pro-vol/acum-pro-vol.component';
import { TaskTableComponent } from './components/area/area-detail/task-table/task-table.component';
import { TaskDelComponent } from './components/area/area-detail/task-table/task-del/task-del.component';
import { TaskGanttComponent } from './components/area/area-detail/task-gantt/task-gantt.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { GanttChartAngularModule } from 'gantt-chart-angular';
import { ImaUserGanttComponent } from './components/user/ima-user-gantt/ima-user-gantt.component';
import { TaskUpComponent } from './components/area/area-detail/task-table/task-up/task-up.component';
import { AreaDelComponent } from './components/area/area-del/area-del.component';
import { TaskSchemeComponent } from './components/area/area-detail/task-scheme/task-scheme.component';
import { DelTaskSchemeComponent } from './components/area/area-detail/task-scheme/del-task-scheme/del-task-scheme.component';
import { UpTaskSchemeComponent } from './components/area/area-detail/task-scheme/up-task-scheme/up-task-scheme.component';
import { TaskStatusComponent } from './components/area/area-detail/task-status/task-status.component';
import { TaskAddShComponent } from './components/area/area-detail/task-add-sh/task-add-sh.component';
import { TaskMbUserComponent } from './components/area/area-detail/task-mb-user/task-mb-user.component';
import { ChatComponent } from './components/chat/chat.component';
import { SmsSendComponent } from './components/chat/sms-send/sms-send.component';
import { SmsListComponent } from './components/chat/sms-list/sms-list.component';
import { SmsActivadorComponent } from './components/chat/sms-activador/sms-activador.component';
import { TaskTableMicroComponent } from './components/area/area-detail/task-table-micro/task-table-micro.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    LoaderComponent,
    DashboardComponent,
    DrolesDirective,
    BudgetComponent,
    AddPresupuestoComponent,
    DelPresupuestoComponent,
    GraficaYearComponent,
    TotalvenComponent,
    PcrpreComponent,
    GraGruMesComponent,
    ComboCharttComponent,
    ComboSeriesVerticalComponent,
    GraVenPreComponent,
    SalesComponent,
    PanelVentaSemanaAdminComponent,
    CvenpreComponent,
    PcrvenComponent,
    CarVenSemComponent,
    PcrVenPreComponent,
    PanelVentaSemanaAdminDetalleComponent,
    GraVenDayComponent,
    PerCliVdiComponent,
    PerCliCvpComponent,
    PerCliIndComponent,
    PanelVentaProductosComponent,
    PvdProductoComponent,
    PanelVentaMesAdminComponent,
    CarVenMesComponent,
    PanelVentaMesAdminDetalleComponent,
    GreVenMesDayComponent,
    PerCliVdiMesComponent,
    PerCliCpvMesComponent,
    PdvProductoPriceHistoComponent,
    PreMensualComponent,
    PreMensualClienteLisComponent,
    PreMensualProductoListComponent,
    PreMensualIndComponent,
    SuccessDataComponent,
    InfoDataComponent,
    WarDataComponent,
    NoDataComponent,
    ImaClienteComponent,
    ImaProductoComponent,
    ProyectoFilterPipe,
    MargenComponent,
    PcrComponent,
    MargenProductoComponent,
    PcrsmaComponent,
    MargenProductoDetalleComponent,
    CustomerComponent,
    AddPresupuestoComponent,
    DelPresupuestoComponent,
    AddClienteComponent,
    DelClienteComponent,
    UpClienteComponent,
    DirClienteComponent,
    AddProductoComponent,
    UpProductoComponent,
    DelProductoComponent,
    ProductoComponent,
    AsigEjecutivoComponent,
    AsigTrasladoComponent,
    EjecutivoIdComponent,
    EjecutivoComponent,
    UsuariosComponent,
    RolesComponent,
    PerfilUsuarioComponent,
    AddUsuariosComponent,
    UpUsuariosComponent,
    DelUsuariosComponent,
    AddRolComponent,
    DelRolComponent,
    ProPriceHistComponent,
    ProPriceHistGraComponent,
    ListProductComponent,
    BudgetYearComponent,
    GraGruMesYearComponent,
    GraCliMesComponent,
    SVenPreComponent,
    SalesYearComponent,
    SVenPreMesComponent,
    SearchVenComponent,
    SalesProducComponent,
    SalesCliComponent,
    ImaClientIdComponent,
    SalesNumProComponent,
    ListSearchProComponent,
    ListSearchCliComponent,
    CriSearchRadComponent,
    GraficaTop10Component,
    GraVenCusYearComponent,
    GraficaTop10ProductosComponent,
    ChannelComponent,
    AddChannelComponent,
    DelChannelComponent,
    TrasChannelComponent,
    ChProductComponent,
    TrasChannelProComponent,
    ChProductAsigComponent,
    ChAsignadorComponent,
    ChAsignadorProComponent,
    ListCustomerGeneralProductComponent,
    ListNumProComponent,
    CriSearchVdhComponent,
    TraslCliSinAgrupacionComponent,
    TraslProSinAgrupacionComponent,
    LogsComponent,
    PanelLogsComponent,
    MenuLogsComponent,
    SendReclaComponent,
    AcuCliGComponent,
    SalesChannelGroupComponent,
    ScgListCliComponent,
    ChListGrupoComponent,
    DelCliListComponent,
    SLCEspcificoComponent,
    ScgGlobalYearComponent,
    ScgGraficaGlobalYearComponent,
    ScgGraficaGlobalYearVolComponent,
    ScgTableAcumCliComponent,
    ScgGraficaCliYearVolComponent,
    ScgGraGloYeaBarComponent,
    ScgIndicadorComponent,
    ScgGraficaCliMxnComponent,
    ScgGraGloYeaBarCliComponent,
    SgcProductosComponent,
    ScgTableProComponent,
    ScgTableProXCliComponent,
    ScgCardClienteComponent,
    ScgTableGraMxnComponent,
    ScgTableGraVolComponent,
    PurchaseOrderComponent,
    EnviosComponent,
    AddEnviosComponent,
    DelEnviosComponent,
    TableProductosEnviosComponent,
    TableEstadosEnviosComponent,
    UpEstadosComponent,
    AddEnviMultipleComponent,
    BloqueClienteComponent,
    PanelAdminEnviosComponent,
    PanelAdminEnviosDetalleComponent,
    PurchaseOrderAddRqComponent,
    SalesChannelGenericoComponent,
    GenGraGloVolComponent,
    UnidadesComponent,
    AddUnidadesComponent,
    DelUnidadesComponent,
    TblProCliComponent,
    AcuCliComponent,
    ListCliComponent,
    PurchaseOrderUpRqComponent,
    ChartComponent,
    GComboChartComponent,
    AreaComponent,
    CardAreaComponent,
    ImaUserIdComponent,
    CardInfoComponent,
    ImaClienIdCircleComponent,
    AreaDetailComponent,
    ImaCardAreaComponent,
    TaskAddComponent,
    GalleryComponent,
    ScgAcumProGComponent,
    AcumProMxnComponent,
    AcumProVolComponent,
    TaskTableComponent,
    TaskDelComponent,
    TaskGanttComponent,
    ImaUserGanttComponent,
    TaskUpComponent,
    AreaDelComponent,
    TaskSchemeComponent,
    DelTaskSchemeComponent,
    UpTaskSchemeComponent,
    TaskStatusComponent,
    TaskAddShComponent,
    TaskMbUserComponent,
    ChatComponent,
    SmsSendComponent,
    SmsListComponent,
    SmsActivadorComponent,
    TaskTableMicroComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatGridListModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatStepperModule,
    MatMenuModule,
    MatTooltipModule,
    MatSelectModule,
    NgxChartsModule,
    MatChipsModule,
    MatBadgeModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTabsModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatCheckboxModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxSkeletonLoaderModule,
    MatFormFieldModule,
    IvyCarouselModule,
    MatSortModule,
    ClipboardModule,
    GoogleChartsModule,
    RatingModule,
    GanttChartAngularModule,
    DragDropModule
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  entryComponents: [
    AddPresupuestoComponent,
    DelPresupuestoComponent,
    AddClienteComponent,
    DelClienteComponent,
    UpClienteComponent,
    AddRolComponent,
    DelRolComponent,
    // AddUsuariosComponent,
    // UpUsuariosComponent,
    // DelUsuariosComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
