<div class="titulo-row-b">
    <div class="ct-t">
        <div class="ct-ico">

            <mat-icon>mark_unread_chat_alt</mat-icon>


        </div>
        <div class="ct-titulo">
            REPARACION LUMINARIA
        </div>
        <div class="ct-su-titulo">
            Chat Room
        </div>

        <div class="ct-info">
            Jose Ignacio esta escribiendo
        </div>
    </div>

</div>


<mat-divider></mat-divider>

<div class="ct-form">
    <div class="locura "></div>
    <div class="sms-list">
        <app-sms-list></app-sms-list>
    </div>
    <div class="sms-send">

        <mat-divider></mat-divider>
        <app-sms-send [vRo]="vRo" [vUs]="vUs"></app-sms-send>
    </div>



</div>