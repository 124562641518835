import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-g-combo-chart',
  templateUrl: './g-combo-chart.component.html',
  styleUrls: ['./g-combo-chart.component.css'],
})
export class GComboChartComponent implements OnInit {
  @Input() vData: any;
  @Input() vYearOls: string;
  @Input() vYearOld: string;
  show: boolean;
  constructor() {}

  title = 'Company Hiring Report';
  type = 'ComboChart';
  columnNames = [];
  options = {
    title: 'ACUMULADO ORDEN DE COMPRAS',
    titleTextStyle: {
      color: '#4a4e69',
      fontSize: 12,
      bold: false,
    },

    vAxis: { title: 'MXN' },
    // hAxis: { title: 'AÑOS' },
    seriesType: 'bars',

    series: {
      3: {
        type: 'line',
        annotations: {
          stem: {
            length: 20,
          },
          textStyle: {
            // bold: true,
            color: '#000000',
            auraColor: 'none',
          },
          boxStyle: {
            stroke: '#fefae0',
            strokeWidth: 10,
            gradient: {
              color1: '#ffb703',
              color2: '#ffb703',
              x1: '0%',
              y1: '0%',
              x2: '100%',
              y2: '100%',
            },
          },
        },
        // targetAxisIndex: 1,
        color: '#ffb703',
        pointShape: 'square',
        pointsVisible: true,
      },
    },
    colors: ['#0081a7', '#c5e0b5', '#2f8f40', '#ffbe05'],
    displayAnnotations: true,
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        auraColor: 'none',
        color: '#555',
      },
      boxStyle: {
        stroke: '#edede9',
        strokeWidth: 0,
        gradient: {
          color1: '#edede9',
          color2: '#edede9',
          x1: '0%',
          y1: '0%',
          x2: '100%',
          y2: '100%',
        },
      },
    },
  };

  width = 1000;
  height = 400;

  ngOnChanges(changes: any) {
    if (this.vYearOls && changes.vYearOls) {
      const nvYear = changes.vYearOls.currentValue;

      const nvYearold = moment(String(nvYear))
        .subtract(1, 'year')
        .format('YYYY');

      this.columnNames = [
        'MES',
        'PRE ' + nvYearold,
        'PRE ' + nvYear,
        'VEN  ' + nvYear,
        { role: 'annotation' },
        'OC  ' + nvYear,
        { role: 'annotation' },
      ];
    }


    if (this.vData && changes.vData) {
      const nvData = changes.vData.currentValue;

      if (nvData) {
        this.show=true;
      }
    
    }


  }

  ngOnInit() {

    this.columnNames = [
      'MES',
      'PRE ' + this.vYearOld,
      'PRE ' + this.vYearOls,
      'VEN  ' + this.vYearOls,
      { role: 'annotation' },
      'OC  ' + this.vYearOls,
      { role: 'annotation' },
    ];
  }
}
