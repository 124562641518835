import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalVariable } from '../global';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  public listNoti$ = new EventEmitter<any>();

  private URL = GlobalVariable.BASE_API_URL;

  constructor(
    private _snackBar: MatSnackBar) {
  }

  msj(message: string, tipo:string) {

    return  this._snackBar.open(message, '', {
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [tipo],
      
    });


  }

 
}
