import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { LoginService } from '../../services/login.service';
import { CustomerService } from '../../services/customer.service';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;
  public emailUser: string;
  email = new FormControl('', [Validators.required, Validators.email]);
  clave = new FormControl('', [Validators.required]);
  hide = true;
  log: boolean;
  isLoggedIn$: Observable<boolean>;
  loading: boolean;
  constructor(
    private loginService: LoginService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
  ) {

    
  }


  usuario = {};

  ngOnInit() {
    this.loginIn();
    this.form = this.fb.group({
      email: ['', Validators.email],
      clave: ['', Validators.required],
    });

  
  }

  loginIn() {
    if (this.loginService.loginIn() && this.loginService.loginIN === true) {
      this.router.navigate(['home']);
      return true;
    }
    this.loginService.loginOut();
    this.router.navigate(['login']);
    return false;
  }

  vectrim(value) {
    return value.replace(/\s/g, '');
  }


  login() {
    if (this.form.valid) {
      this.loading = true;
      const email = this.form.get('email').value;
      const clave = this.form.get('clave').value;
      this.usuario = {
        email: this.vectrim(email),
        clave: this.vectrim(clave),
      };

      this.loginService.loginUsuario(this.usuario).subscribe((res: any) => {

      

         this.loginService.setRoles(res.rol);
        this.loginService.setvUser(res.usuario);
        localStorage.setItem('token', res.token);
        localStorage.setItem('email', res.usuario);
        localStorage.setItem('nombre', res.nombre);
        localStorage.setItem('imagen', res.imagen);
        localStorage.setItem('rol', res.rol);
        this.router.navigate(['home']);
        this.openSnackBar(res.status, 'ok');
      });
    } else {
      this.loading = false;
    }
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  getErrorMessageEmail() {
    if (this.email.hasError('required')) {
      this.loading = false;
      return 'Por favor ingresa un correo válido';
    }
    this.loading = false;
    return this.email.hasError('email')
      ? 'Por favor ingresa un correo válido'
      : '';
  }

  getErrorMessageClave() {
    if (this.clave.hasError('required')) {
      this.loading = false;
      return 'Por favor ingresa tu contraseña';
    }
  }

}
function trim(clave: any) {
  throw new Error('Function not implemented.');
}
