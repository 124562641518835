import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ProductoInterface } from "../models/producto-interface";
import { GlobalVariable } from '../global';
@Injectable({
    providedIn: 'root'
})
export class UnidadesService {
    private URL = GlobalVariable.BASE_API_URL;
    public unidadesSer$ = new EventEmitter<any>();
    constructor(private http: HttpClient,
        private router: Router, ) { }

    getAllUnidades() {
        return this.http.get<any>(this.URL + 'getAllUnidades');
    }

    registroUnidades(data) {
        return this.http.post<any>(this.URL + 'registroUnidades', data);
    }

    delUnidades(data) {
        return this.http.post<any>(this.URL + 'delUnidades', data);
    }
    getAllUnidadesVenta(data) {
        return this.http.post<any>(this.URL + 'getAllUnidadesVenta', data);
      }
      

}
