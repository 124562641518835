<div class="conte-pro">
  <div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/dashboard']">
      <mat-icon>navigate_before</mat-icon>
    </button>
    Asignación de Clientes - Ejecutivos
  </div>
  <mat-divider></mat-divider>
  <mat-grid-list cols="10" rowHeight="100px">


    <mat-grid-tile [colspan]="2">
      <mat-form-field class="mfs">
        <mat-label>Tipo</mat-label>
        <mat-select [formControl]="selec" name="selec" (selectionChange)="updateAn($event)">
          <mat-option value="1">CLIENTE</mat-option>
          <mat-option value="2">EJECUTIVO</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="5">
      <div class="divi" *ngIf="m1">
        <div class="divi" *ngIf="!c1; else cactivo">
          <mat-form-field class="mat-form">
            <input type="text" placeholder="Ejemplo: AlFrut..." matInput [formControl]="clienteF"
              [matAutocomplete]="autoCliente" />
            <mat-autocomplete autoActiveFirstOption #autoCliente="matAutocomplete">
              <mat-option *ngFor="let optionCliente of filteredOptionsCliente | async"
                [value]="optionCliente.razon_social" (click)="setCliente(optionCliente._id)">
                <div class="listadoProducto">
                  {{ optionCliente.id_epicor }} -
                  {{ optionCliente.razon_social }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="divi" *ngIf="m2">
        <div class="divi" *ngIf="!c2; else cactivo">
          <mat-form-field class="mat-form">
            <input type="text" placeholder="Ejemplo: Juan..." matInput [formControl]="ejecutivoF"
              [matAutocomplete]="autoEje" />
            <mat-autocomplete class="divi" autoActiveFirstOption #autoEje="matAutocomplete">
              <mat-option *ngFor="let optionEjecutivo of filteredOptionsEjecutivo | async"
                [value]="optionEjecutivo.nombre" (click)="setEjecutivo(optionEjecutivo._id)">
                <div class="listadoProducto">
                  {{ optionEjecutivo.cod_epicor }} -
                  {{ optionEjecutivo.nombre }} {{ optionEjecutivo.apellido }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <ng-template class="pro" #cactivo>
        <div class="contepro">
          <mat-progress-bar class="pro" mode="indeterminate"></mat-progress-bar>
        </div>
      </ng-template>
    </mat-grid-tile>



    <mat-grid-tile [colspan]="2">
      <div *ngIf="m1 || m2">
        <mat-form-field class="mfs">
          <mat-label>Mes:</mat-label>
          <mat-select [formControl]="selecm" name="selecm" (selectionChange)="updateAns($event)">
            <mat-option [value]="1">ENERO</mat-option>
            <mat-option [value]="2">FEBRERO</mat-option>
            <mat-option [value]="3">MARZO</mat-option>
            <mat-option [value]="4">ABRIL</mat-option>
            <mat-option [value]="5">MAYO</mat-option>
            <mat-option [value]="6">JUNIO</mat-option>
            <mat-option [value]="7">JULIO</mat-option>
            <mat-option [value]="8">AGOSTO</mat-option>
            <mat-option [value]="9">SEPTIEMBRE</mat-option>
            <mat-option [value]="10">OCTUBRE</mat-option>
            <mat-option [value]="11">NOVIEMBRE</mat-option>
            <mat-option [value]="12">DICIEMBRE</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>


    <mat-grid-tile [colspan]="1">
      <div *ngIf="m1 || m2">
        <mat-form-field class="mfsa">
          <mat-label>Año:</mat-label>
          <mat-select [formControl]="seleca" name="selecm" (selectionChange)="updateAns($event)">
            <mat-option [value]="2022">2022</mat-option>
            <mat-option [value]="2021">2021</mat-option>
            <mat-option [value]="2020">2020</mat-option>
            <mat-option [value]="2019">2019</mat-option>
            <mat-option [value]="2018">2018</mat-option>
            <mat-option [value]="2017">2017</mat-option>

          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>

  </mat-grid-list>
  <div *ngIf="divglobal">
    <div class="g2" *ngIf="divEjecutivo; else noeje">
      <mat-grid-list cols="10" rowHeight="150px">
        <mat-grid-tile colspan="2">
          <img mat-card-avatar class="imgfluid" [src]="this.vEjecutivo.imagen || 'assets/ima/no-ima.png'" />
        </mat-grid-tile>
        <mat-grid-tile colspan="6">
          <mat-card class="mat-card mat-elevation-z0">
            <mat-card-title class="tit">{{ this.vEjecutivo.nombre }} {{ this.vEjecutivo.apellido }}
            </mat-card-title>
            <mat-card-subtitle>{{ this.vEjecutivo.email }} </mat-card-subtitle>
            <mat-card-subtitle>{{ this.vEjecutivo.telefono }}
            </mat-card-subtitle>
            <mat-card-subtitle>Ejecutivo Asignado (Actual)</mat-card-subtitle>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <div class="example-button-row">
            <button class="dbtn" color="warn" mat-raised-button mat-button (click)="delEjecutivo(this.vEjecutivo._id)">
              <mat-icon>delete_forever</mat-icon>Eliminar
            </button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <ng-template #noeje>
      <div *ngIf="btn_a" class="g-card">
        <mat-card class="g-card">
          <mat-card-title>
            <mat-icon class="ico-m" matListIcon>report_problem</mat-icon>
          </mat-card-title>
          <mat-card-subtitle> Ningún ejecutivo asignado </mat-card-subtitle>
        </mat-card>
      </div>
      <div *ngIf="btn_a" class="bna">
        <button class="dbtnas" color="primary" mat-raised-button mat-button (click)="divasi()">
          <mat-icon>published_with_changes</mat-icon>Asignar
        </button>
      </div>
    </ng-template>
  </div>
  <div class="mata" *ngIf="diva">
    <div class="titulo-row-b">
      <button mat-icon-button [routerLink]="['/dashboard']">
        <mat-icon>navigate_before</mat-icon>
      </button>
      Listado de Ejecutivos
    </div>
    <mat-divider></mat-divider>
    <div class="divAsinacion">
      <mat-grid-list cols="1" rowHeight="60px">
        <mat-grid-tile>
          <mat-form-field class="mata">
            <input type="text" placeholder="Ejemplo: Pedro..." matInput [formControl]="ejecutivoF"
              [matAutocomplete]="autoEje" />
            <mat-autocomplete class="divia" autoActiveFirstOption #autoEje="matAutocomplete">
              <mat-option *ngFor="let optionEjecutivo of filteredOptionsEjecutivo | async"
                [value]="optionEjecutivo.nombre" (click)="
                  setEjecutivoAsing(
                    optionEjecutivo._id,
                    optionEjecutivo.nombre,
                    optionEjecutivo.apellido
                  )
                ">
                <div class="listadoProducto">
                  {{ optionEjecutivo.cod_epicor }} -
                  {{ optionEjecutivo.nombre }} {{ optionEjecutivo.apellido }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <button *ngIf="bta_b" class="dbtnas" color="primary" mat-raised-button mat-button (click)="addAsing()">
            <mat-icon>published_with_changes</mat-icon>Aplicar
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

  </div>



  <div class="matb" *ngIf="divb">
    <div class="">
      <button mat-icon-button [routerLink]="['/dashboard']">
        <mat-icon>navigate_before</mat-icon>
      </button>
      Listado de Cliente
    </div>
    <mat-divider></mat-divider>
    <div class="divAsinacion">
      <mat-grid-list cols="10" rowHeight="60px">
        <mat-grid-tile colspan="8">

          <mat-form-field class="mat-form">
            <input type="text" placeholder="Ejemplo: AlFrut..." matInput [formControl]="clienteF"
              [matAutocomplete]="autoCliente" />
            <mat-autocomplete autoActiveFirstOption #autoCliente="matAutocomplete">
              <mat-option *ngFor="let optionCliente of filteredOptionsCliente | async"
                [value]="optionCliente.razon_social" (click)="setCliAsing_unclo(
                  optionCliente._id,
                  optionCliente.razon_social
                  )">
                <div class="listadoProducto">
                  {{ optionCliente.id_epicor }} -
                  {{ optionCliente.razon_social }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>





        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <button *ngIf="bta_b" class="dbtnas" color="primary" mat-raised-button mat-button (click)="addAsingCli()">
            <mat-icon>add</mat-icon>Agregar
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

  </div>



  <div class="wrapper" *ngIf="loading; else noa">
    <mat-spinner class="mat-spinner" diameter="80" strokeWidth="10"></mat-spinner>
  </div>

  <ng-template #noa>


    <mat-grid-list cols="10" rowHeight="60px">
      <mat-grid-tile colspan="8">

        <button *ngIf="asibtn_alterno" class="dbtnas" color="primary" mat-raised-button mat-button (click)="divasib()">
          <mat-icon>published_with_changes</mat-icon> Asignar
        </button>

      </mat-grid-tile>
      <mat-grid-tile colspan="2">

        <button *ngIf="asibtn_alterno" class="dbtnas" [disabled]="!btt"  color="accent" mat-raised-button mat-button (click)="onDialogAdd()">
          <mat-icon>swap_horiz</mat-icon> Trasladar <span *ngIf="bttn"> ({{bttn}}) </span>
        </button>

      </mat-grid-tile>
    </mat-grid-list>


    <div class="div-error" *ngIf="!loadingRow && vecr;else tablaAsignados">
      <br />
      <mat-card class="g-card">
        <mat-card-title>
          <mat-icon class="ico-m" matListIcon>report_problem</mat-icon>
        </mat-card-title>
        <mat-card-subtitle> Ningún cliente asignado </mat-card-subtitle>
      </mat-card>

      <div *ngIf="asibtn" class="bnb">
        <button class="dbtnas" color="primary" mat-raised-button mat-button (click)="divasib()">
          <mat-icon>published_with_changes</mat-icon>Asignar a
        </button>
      </div>
    </div>




    <ng-template #tablaAsignados>
      <div class="table-container" *ngIf="vecr">




        <table mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">


          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox 
              color="primary"
              (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>


          <ng-container matColumnDef="cod_epicor">
            <th mat-header-cell *matHeaderCellDef>Codigo</th>
            <td mat-cell *matCellDef="let element">
              {{ element._id_cliente.id_epicor }}
            </td>
          </ng-container>
          <ng-container matColumnDef="razon_social">
            <th mat-header-cell *matHeaderCellDef>Razon Social</th>
            <td mat-cell *matCellDef="let element">
              {{ element._id_cliente.razon_social }}
            </td>
          </ng-container>
          <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef>Opciones</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
                  mat-menu-item (click)="onDelAsig(element._id)">
                  <mat-icon>delete_forever</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"  (click)="selection.toggle(row)"></tr>
        </table>

      </div>

    </ng-template>

    <!-- </div> -->
    <!-- 
</div> -->

  </ng-template>



