import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { UsuarioService } from '../../../services/usuario.service';
import { AssignedCustomerService } from '../../../services/assignedclient.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { AsigTrasladoComponent } from "./asig-traslado/asig-traslado.component";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
@Component({
  selector: 'app-asig-ejecutivo',
  templateUrl: './asig-ejecutivo.component.html',
  styleUrls: ['./asig-ejecutivo.component.css'],
})
export class AsigEjecutivoComponent implements OnInit {
  clienteF = new FormControl();
  ejecutivoF = new FormControl();
  displayedColumns: string[] = ['select', 'cod_epicor', 'razon_social', 'opciones'];
  dataSource = new MatTableDataSource<any>();
  selec = new FormControl();
  selecm = new FormControl();
  seleca = new FormControl();

  filteredOptionsCliente: Observable<string[]>;
  filteredOptionsEjecutivo: Observable<string[]>;
  aFormGroup: FormGroup;
  optionCliente: any[] = [];
  optionEjecutivo: any[] = [];
  bFormGroup: FormGroup;
  xFormGroup: FormGroup;
  c1: boolean;
  private _id_cliente: any;
  c2: boolean;
  _id_ejecutivo: any;
  m2: boolean;
  m1: boolean;
  listadoAsif: any;
  divEjecutivo: boolean;
  vEjecutivo: any;
  ejecutivoF_id: any;
  relacion: any;
  divglobal: boolean;
  diva: boolean;
  btn_a: boolean;
  bta_b: boolean;
  btn_b: boolean;
  objEje: { _id: any; nombre: any; apellido: any };
  listadoAsignado: any;
  loadingRow: boolean;
  loading: boolean;
  divtable: boolean;
  divb: boolean;
  bnb: boolean;
  asibtn: boolean;
  objEjeCli: { _id: any; nombre: any };
  asibtn_alterno: boolean;
  vecr: boolean = false;
  selection = new SelectionModel<any>(true, []);
  btt: boolean;
  bttn: number;
  constructor(
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private customerService: CustomerService,
    private usuarioService: UsuarioService,
    private assignedCustomerService: AssignedCustomerService
  ) { }


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit(): void {

    const mes = this.getMes();
    const yeara = this.getAn();
    this.selecm.setValue(mes);
    this.seleca.setValue(yeara);

    this.aFormGroup = this._formBuilder.group({
      tipo: ['', Validators.required],
      name_tipo: ['', Validators.required],
    });
    this.filteredOptionsCliente = this.clienteF.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCliente(value))
    );
    this.filteredOptionsEjecutivo = this.ejecutivoF.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterEjecutivo(value))
    );
  }

  updateAn(value) {
    const mes = this.getMes();
    this.selecm.setValue(mes);
    this.divglobal = false;
    this.divtable = false;
    this.divb = false;
    this.vecr = false;
    this.asibtn = false;
    this.asibtn_alterno = false;
    const tipo = value.value;
    this.ejecutivoF.reset();
    this.clienteF.reset();
    if (tipo === '1') {
      this.c1 = true;
      this.m1 = true;
      this.m2 = false;
      this.getAllClientes();
    } else if (tipo === '2') {
      this.m1 = false;
      this.m2 = true;
      this.c2 = true;
      this.getAllEjecutivo();
    }
  }

  updateAns(value) {

    const tipo = this.selec.value;
    if (tipo === '1' && this.relacion) {
      const mes = this.selecm.value;
      const yeara = this.seleca.value;

      this.getClienteIdVendedor(this.relacion, mes, yeara);
      this.divglobal = true;
    } else if (tipo === '2') {

      if (this._id_ejecutivo != "") {
        this.setEjecutivo(this._id_ejecutivo)
      }
    }

  }

  getAllClientes() {
    this.customerService.getAllClientes().subscribe((resp) => {
      this.optionCliente = resp.clientes;
      this.c1 = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllEjecutivo() {
    this.usuarioService.getAllUsuarios().subscribe((resp) => {
      this.optionEjecutivo = resp.usuario;
      this.c2 = false;
    });
  }

  getClienteIdVendedor(_id, mes, yeara) {
    this.assignedCustomerService
      .getClienteIdVendedor({ _id_cliente: _id, mes: mes, yeara: yeara })
      .subscribe((resp) => {
        this.vEjecutivo = resp.vendedorAsig;
        if (this.vEjecutivo) {
          this.divEjecutivo = true;
          this.btn_a = false;
          this.diva = false;
        } else {
          this.divEjecutivo = false;
          this.btn_a = true;
        }
      });
  }

  setCliente(valor) {
    const mes = this.selecm.value;
    const yeara = this.seleca.value;
    this.relacion = valor;
    this.getClienteIdVendedor(valor, mes, yeara);
    this.divglobal = true;
  }

  setClienteAsing(valor) {
    this.divb = true;
    this.bnb = false;

  }

  setEjecutivo(_id) {
    this.selection = new SelectionModel<any>(true, []);
    this.divb = false;
    const mes = this.selecm.value;
    const yeara = this.seleca.value;

    this.vecr = true;
    this.loading = true;
    this._id_ejecutivo = _id;
    this.assignedCustomerService
      .getAllClienteAsignadoMes({ _id_vendedor: _id, mes: mes, yeara: yeara })
      .subscribe((resp) => {
        const numreps = resp.clientesAsignados.length;
        this.dataSource.data = resp.clientesAsignados;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
        if (numreps === 0) {
          this.loadingRow = false;
          this.asibtn = true;
          this.asibtn_alterno = false;
        } else {
          this.loadingRow = true;
        }
      });

    this.divtable = true;
    this.asibtn_alterno = true;
  }

  setEjecutivoAsing(valor, nombre, apellido) {
    this.objEje = { _id: valor, nombre: nombre, apellido: apellido };
    this._id_ejecutivo = valor;
    this.bta_b = true;
  }

  setCliAsing(_id_cliente, razon_social) {
    this.relacion = _id_cliente;
    this.objEjeCli = { _id: _id_cliente, nombre: razon_social };
    this.bta_b = true;
    this.clienteF.reset();
  }

  setCliAsing_unclo(_id_cliente, razon_social) {
    this.relacion = _id_cliente;
    this.objEjeCli = { _id: _id_cliente, nombre: razon_social };
    this.bta_b = true;
  }

  _filterCliente(value: string) {
    if (value)
      return this.optionCliente.filter((optionCliente) =>
        optionCliente.razon_social.toLowerCase().includes(value.toLowerCase())
      );
  }

  _filterEjecutivo(value: string) {
    if (value)
      return this.optionEjecutivo.filter((optionEjecutivo) =>
        optionEjecutivo.nombre.toLowerCase().includes(value.toLowerCase())
      );
  }

  delEjecutivo(_id_ejecutivo) {

    const mes = this.selecm.value;
    const yeara = this.seleca.value;
    this.assignedCustomerService
      .delAsignacionMaster({
        _id_cliente: this.relacion,
        _id_vendedor: _id_ejecutivo,
        mes: mes,
        yeara: yeara
      })
      .subscribe((resp) => {
        this.vEjecutivo = resp.vendedorAsig;
        this.divEjecutivo = true;

        this.getClienteIdVendedor(this.relacion, mes, yeara);
      });
  }

  divasi() {
    this.btn_a = false;
    this.getAllEjecutivo();
    this.diva = true;
  }

  divasib() {
    this.btn_b = false;
    this.bta_b = true;
    this.asibtn_alterno = false;
    this.getAllClientes();
    this.divb = true;
    this.asibtn = false;
  }

  addAsing() {

    const mes = this.selecm.value;
    const yeara = this.seleca.value;

    let data = {
      _id_cliente: this.relacion,
      _id_vendedor: this._id_ejecutivo,
      nombre_vendedor: this.objEje.nombre,
      apellido_vendedor: this.objEje.apellido,
      mes: mes,
      yeara: yeara
    };

    this.assignedCustomerService.asignarCliente(data).subscribe(
      (resp) => {
        this.openSnackBar(resp.status, 'ok');
        this.getClienteIdVendedor(this.relacion, mes, yeara);
      },
      (error) => {
        this.openSnackBar(error.error.error, 'alert');
      }
    );
  }

  addAsingCli() {
    const mes = this.selecm.value;
    const yeara = this.seleca.value;
    let data = {
      _id_cliente: this.relacion,
      _id_vendedor: this._id_ejecutivo,
      nombre_vendedor: this.objEjeCli.nombre,
      mes: mes,
      yeara: yeara
    };

    this.assignedCustomerService.asignarCliente(data).subscribe((resp) => {
      this.openSnackBar(resp.status, 'ok');
      this.setEjecutivo(this._id_ejecutivo);
      this.clienteF.reset();
      this.asibtn_alterno = false;
    },
      (error) => { this.openSnackBar(error.error.error, 'alert') }
    );
  }

  onDelAsig(id) {
    this.assignedCustomerService.delAsignacionCliente({ id: id }).subscribe(
      (resp) => {
        this.openSnackBar(resp.status, 'ok');
        this.setEjecutivo(this._id_ejecutivo);
        this.divb = false;
      },
      (error) => this.openSnackBar('Ha ocurrido un error', 'Error')
    );
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  getMes() {
    const fecha = new Date();
    const mesActual = fecha.getMonth() + 1;
    return mesActual;
  }

  getAn() {
    const fecha = new Date();
    const anActual = fecha.getFullYear();
    return anActual;
  }


  trasladar() {
    const mes = this.selecm.value;
    const yeara = this.seleca.value;
  }


  onDialogAdd() {

    const mes = this.selecm.value;
    const yeara = this.seleca.value;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.data = {
      data: this.selection.selected,
      mes: mes,
      yeara: yeara,
      _id_ejecutivo_actual: this._id_ejecutivo
    };
    this.dialog.open(AsigTrasladoComponent, dialogConfig);
  }




  isAllSelected() {

    if (this.selection.selected.length > 0) {
      this.btt = true;
      this.bttn = this.selection.selected.length;
    } else {
      this.btt = false;
      this.bttn = 0;
    }


    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: any): string {

    if (this.selection.selected.length > 0) {
      this.btt = true;
      this.bttn = this.selection.selected.length;
    } else {
      this.btt = false;
      this.bttn = 0;
    }


    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }



}
