<div class="conte-pro">
    <div class="titulo-row-b">
        <button mat-icon-button>
            <mat-icon>local_shipping</mat-icon>
        </button>
        Panel Envios
    </div>
    <mat-divider></mat-divider>





<div class="ct-p">
<div>
    <mat-form-field>
        <mat-label>Filtro</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ejemplo: Profusa Products" #input
            autocomplete="off">
    </mat-form-field>
</div>
<div>
    <mat-form-field class="mfs">
        <mat-label>Envios:</mat-label>
        <mat-select [formControl]="selecm" name="selecm" (selectionChange)="updateAns($event)">
            <mat-option [value]="2">TODOS</mat-option>
            <mat-option [value]="1">ACTIVOS</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div>
    <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button
    [routerLink]="['/nuevo_envio_multiple']">
    <mat-icon matListIcon>add</mat-icon> 
</button>
</div>

</div>

    <!-- <mat-grid-list [cols]="aa" rowHeight="80px">
        <mat-grid-tile [colspan]="ab">
           
        </mat-grid-tile>
        <mat-grid-tile [colspan]="ae">
            <div>
                <mat-form-field class="mfs">
                    <mat-label>Envios:</mat-label>
                    <mat-select [formControl]="selecm" name="selecm" (selectionChange)="updateAns($event)">
                        <mat-option [value]="2">TODOS</mat-option>
                        <mat-option [value]="1">ACTIVOS</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="ag">
            <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button
                [routerLink]="['/nuevo_envio_multiple']">
                <mat-icon matListIcon>add</mat-icon> 
            </button>
        </mat-grid-tile>

    </mat-grid-list> -->

    <ngx-skeleton-loader *ngIf="wt;else ctdata" count="8" [theme]="{ 
        'border-radius': '5px',
        'padding-top': '10px',
        
        height: '40px',
        'background-color': '#daddd8'
    }">
    </ngx-skeleton-loader>


    <ng-template #ctdata>

    <div *ngIf="gra4;else nodata" #exceltable>
        <mat-table  [dataSource]="dataSource" class="table_data"  matSort>
            <ng-container matColumnDef="ima">
                <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="Cliente">
                    <app-ima-cliente [idcliente]="row.id_epicor"></app-ima-cliente>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="razon">
                <mat-header-cell *matHeaderCellDef mat-sort-header>CLIENTE</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="Razon Social">
                    {{formatRazonSocial(row.razon)}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fechas">
                <mat-header-cell *matHeaderCellDef mat-sort-header>SALIDAD</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label=" Salida"> {{formatDate(row.fechas)}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fechall">
                <mat-header-cell *matHeaderCellDef mat-sort-header>LLEGADA</mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color" data-label=" Llegada">
                    {{formatDate(row.fechall)}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="estado">
                <mat-header-cell *matHeaderCellDef mat-sort-header>ESTADO</mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color" data-label=" Estado">
                    <div matTooltip="{{row.destino}}" class="row-pro-f {{row.estado}}"    [routerLink]="['/listado_envios_detalles', row.ci, row.op]"    >{{row.estado}}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ci">
                <mat-header-cell *matHeaderCellDef mat-sort-header> INFO </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color" data-label=" Info">
                    <mat-icon class="icomma" matTooltip="Código Interno: {{row.ci}}">sell</mat-icon>
                    <mat-icon class="icommb" matTooltip="Código Operación: {{row.op}}">local_shipping</mat-icon>
                    <mat-icon class="icommc" matTooltip="Fecha de Registro: {{formatDateFull(row.createdAt)}}">event
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
    </div>
    <ng-template #nodata>
        <app-no-data [mensaje]="'No hay envios activos'" [pd]=""> </app-no-data>
    </ng-template>
</ng-template>
</div>