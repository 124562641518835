import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sgc-productos',
  templateUrl: './sgc-productos.component.html',
  styleUrls: ['./sgc-productos.component.css']
})
export class SgcProductosComponent implements OnInit {
  @Input() vCh: string;
  @Input() vYear: string;
  constructor() { }

  ngOnInit(): void {
  }

}
