<div class="conte-pro">
    <div class="ct-model">
        <div class="ct-m-t">
            <div class="titulo-row-b">
                <button mat-icon-button>
                    <mat-icon>list</mat-icon>
                </button>
                Acumulado General
            </div>
        </div>
        <div></div>
        <div></div>
        <div class="item name-c">
            <form [formGroup]="criterioCh" method="post">
                <mat-form-field class="mat-form-field-filtro">
                    <mat-label>Año:</mat-label>
                    <mat-select (selectionChange)="updateCh()" formControlName="canal">
                        <mat-option *ngFor="let lv of listRango" [value]="lv.vYear"> {{lv.vYear}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </div>

    <mat-divider></mat-divider>

    <mat-tab-group class="tabs-master-cli">
        <mat-tab label="Acumulado Anual">
            <ng-template matTabContent>
                <div class="ct-p">
                    <div>
                        <app-scg-global-year [vCh]="vCh" [vYear]="vYear"></app-scg-global-year>
                    </div>
                    <div>
                        <app-scg-gra-glo-yea-bar [vCh]="vCh" [vYear]="vYear"></app-scg-gra-glo-yea-bar>
                    </div>
                </div>
                <div class="titulo-row-b sec">
                    <button mat-icon-button>
                        <mat-icon>list</mat-icon>
                    </button>
                    Venta Acumulada mes
                </div>
                <mat-divider></mat-divider>
                <app-scg-grafica-global-year [vCh]="vCh" [vYear]="vYear"></app-scg-grafica-global-year>
                <app-gen-gra-glo-vol [vCh]="vCh" [vYear]="vYear"></app-gen-gra-glo-vol>
            </ng-template>
        </mat-tab>
        <mat-tab label="Acumulado Cliente">

            <app-acu-cli [vCh]="vCh" [vYear]="vYear" ></app-acu-cli>

        </mat-tab>
    </mat-tab-group>
</div>

<section class="footer">
</section>