import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UsuarioService } from "./../../services/usuario.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddUsuariosComponent } from "./add-usuarios/add-usuarios.component";
import { DelUsuariosComponent } from "./del-usuarios/del-usuarios.component";
import { UpUsuariosComponent } from "./up-usuarios/up-usuarios.component";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-usuarios',
    templateUrl: './usuarios.component.html',
    styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

    checked: boolean = false;
    displayedColumns: string[] = ['cod_epicor', 'nombre', 'apellido', 'estado', 'opciones'];
    dataSource = new MatTableDataSource<any>();
    formGroup: FormGroup;
    constructor(
        private usuarioService: UsuarioService,
        private _snackBar: MatSnackBar,
        private dialog: MatDialog,
        private _formBuilder: FormBuilder,
    ) {

        this.formGroup = _formBuilder.group({
            estado: ['', Validators.requiredTrue]
        });
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    ngOnInit() {
        this.usuarioService.usuarioSer$.subscribe(() => {
            this.getAllUsuarios();
        });
        this.getAllUsuarios();
    }

    getAllUsuarios() {
        this.usuarioService.getAllUsuarios().subscribe(
            resp => {
                this.dataSource.data = resp.usuario;
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            });
    }

    openSnackBar(message: string, tipo: string) {
        this._snackBar.open(message, '', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: [tipo]
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    onDialogAdd() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "80%";
        this.dialog.open(AddUsuariosComponent, dialogConfig);
    }

    onDialogUp(_id, cod_epicor, nombre, apellido, telefono, email, clave, imagen, fecha_n, rol) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.width = "80%";
        dialogConfig.data = { data: { _id: _id, cod_epicor: cod_epicor, nombre: nombre, apellido: apellido, telefono: telefono, email: email, clave: clave, imagen: imagen, fecha_n: fecha_n, rol: rol } };
        this.dialog.open(UpUsuariosComponent, dialogConfig);
    }

    onDialogDel(id) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.width = "40%";
        dialogConfig.data = { data: { id: id } };
        this.dialog.open(DelUsuariosComponent, dialogConfig);
    }

    upEstado() {
        const varEstado = this.formGroup.value;
    }

    changeEstado(e, id) {
        this.usuarioService.updateUsuarioEstado({ _id: id, estado: e.checked })
            .subscribe(
                res => {
                    this.openSnackBar(res.status, 'ok');
                    this.usuarioService.usuarioSer$.emit();
                },
                err => {
                    this.openSnackBar(err.error.error, 'alert');
                });
    }

    getGenereClave(id) {
        this.usuarioService.getGenereClave({ _id: id })
            .subscribe(
                resp => {
                });
    }




}
