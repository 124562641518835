
<div class="ct-p">
    <div>
  
    </div>
    <div>
  
      <div *ngIf="show; else wnode">
  
        <div class="ct-master-a">
          <div class="ct-master-b">
            <div class="ct-master-c">
  
              <div class="block-wiat">
                <ngx-skeleton-loader *ngFor="let iWiat of wiat" count="1" [theme]="iWiat">
                </ngx-skeleton-loader>
              </div>
  
            </div>
          </div>
        </div>
  
  
      </div>
  
      <ng-template #wnode>
        <div *ngIf="gd2m">
  
          <div class="ct-master-a">
            <div class="ct-master-b">
              <div class="ct-master-c">
                <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="listGraAl" [gradient]="gradient"
                  [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                  [legendTitle]="legendTitle">
                </ngx-charts-bar-vertical>
              </div>
            </div>
          </div>
  
        </div>
  
        <ng-template #rnode>
          <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]=""> </app-info-data>
        </ng-template>
  
  
      </ng-template>
  
  
    </div>
  </div>