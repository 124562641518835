
    <div *ngIf="e1" class="wit">
        <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: '200px' }"></ngx-skeleton-loader>
    </div>

    <div *ngIf="e1;else coteq" class="wit">
        <ngx-skeleton-loader count="7" [theme]="{ 'border-radius': '0', height: '20px' }"></ngx-skeleton-loader>
    </div>
   <ng-template #coteq>
    <div style="height: 100%; width:100%;">
        <div style="display:flex; flex-direction:column;">
            <div style="height:200px">

                <ngx-charts-bar-horizontal [scheme]="colorScheme" [results]="mat" [gradient]="gradient"
                    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel" [legendTitle]="legendTitle" [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel" [customColors]="customColors">
                </ngx-charts-bar-horizontal>
            </div>
        </div>
    </div>
<br>
<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="id_epicor">
        <th mat-header-cell *matHeaderCellDef> Epicor </th>
        <td mat-cell *matCellDef="let element"> {{element.id_epicor}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
    </ng-container>

    <ng-container matColumnDef="volumen">
        <th mat-header-cell *matHeaderCellDef> Volumen </th>
        <td mat-cell *matCellDef="let element"> {{element.volumen | number}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalv() | number}} </td>

    </ng-container>

    <ng-container matColumnDef="unidad">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"> {{element.unidad}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="importe_venta">
        <th mat-header-cell *matHeaderCellDef> Importe Venta </th>
        <td mat-cell *matCellDef="let element"> {{element.importe_venta |  number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotala() |  number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="utilidad_neta">
        <th mat-header-cell *matHeaderCellDef> Utilidad Neta </th>
        <td mat-cell *matCellDef="let element"> {{element.utilidad_neta |  number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalb() |  number: '1.2-2'}} </td>
    </ng-container>
    <ng-container matColumnDef="pcr">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
            <app-pcrsma [value]="element.pcr"></app-pcrsma>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="sec">
                <app-margen-producto-detalle 
                [idpro]="element._id_producto"
                [idcli]="element._id_cliente"
                [mes]="mes" 
                [yeara]="yeara"
                ></app-margen-producto-detalle> 
            </div>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row  *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
</ng-template>