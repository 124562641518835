<div class="ct-master-gra" >
  <div class="ct-pd-header">
    <div>Vida Histórica (Precio)</div>
  
  </div>
  <div class="ct-master-a">
    <div class="ct-master-b">
      <div class="ct-master-c">
        <ngx-charts-line-chart [scheme]="colorScheme" [legend]="legend" [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel" [timeline]="timeline"  [results]="multi"   (select)="onSelect($event)">
        </ngx-charts-line-chart>
      </div>
    </div>
  </div>
  

</div>







