<div class="content-graf" *ngIf="g1">
  <div class="titulo-row-b">
    <button mat-icon-button>
      <mat-icon>insert_chart_outlined</mat-icon>
    </button>
    Top <mat-form-field class="mat-ftop" (change)="updateS($event.target.value)">
      <select matNativeControl>
        <option selected value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
        <option value="50">50</option>
      </select>
    </mat-form-field> Productos:
    <mat-form-field (change)="updateA($event.target.value)">
      <select matNativeControl>
        <option *ngFor="let lv of listRango" [value]="lv.vYear">
          {{ lv.vYear }}
        </option>
        <!-- <option selected value="2022">2022</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
        <option value="2017">2017</option> -->
      </select>
    </mat-form-field>
    <mat-form-field (change)="updateB($event.target.value)">
      <select matNativeControl>
        <option  *ngFor="let env of obs | async " [selected]="env.canal=='INDUSTRIAL'" value="{{env.canal}}">{{env.canal}}</option>
      </select>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="wrapper" *ngIf="loading;else content">
    <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
  </div>
  <ng-template #content>
    <div class="content-graf">
      <div *ngIf="transactions.length>0;else graficab">
        <mat-grid-list [cols]="breakpoint"  rowHeight="380px" (window:resize)="onResize($event)">
          <mat-grid-tile [colspan]="colspa" class="spar_grid">
            <div style="height: 100%; width:85%">
              <div style="display:flex; flex-direction:column;">
                <div style="height:380px">
                  <div class="my-flex-mat-card">
                  <ngx-charts-bar-vertical [scheme]="acolorScheme" [results]="transactions" [gradient]="agradient"
                    [xAxis]="ashowXAxis" [yAxis]="ashowYAxis" [legend]="ashowLegend" [legendTitle]="alegendTitle"
                    [showXAxisLabel]="ashowXAxisLabel" [showYAxisLabel]="ashowYAxisLabel" [xAxisLabel]="axAxisLabel"
                    [yAxisLabel]="ayAxisLabel" [maxXAxisTickLength]="4" (select)="onSelectTopA($event)">
                  </ngx-charts-bar-vertical>
                </div>
              </div>
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="colspb">
            <div class="table-container">
              <table mat-table [dataSource]="transactions" class="mat-elevation-z8" class="table-vieg2">
                <ng-container matColumnDef="num">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                  <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>
                <ng-container matColumnDef="producto">
                  <th mat-header-cell *matHeaderCellDef> Producto </th>
                  <td mat-cell *matCellDef="let transactions"> {{transactions.name}} </td>
                  <td mat-footer-cell *matFooterCellDef> Total </td>
                </ng-container>
                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef class="text-right"> Venta MXN </th>
                  <td mat-cell *matCellDef="let transactions"> {{transactions.value | currency}} </td>
                  <td mat-footer-cell *matFooterCellDef> {{getTotalga() | currency}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
              </table>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <ng-template #graficab>
     
          <app-no-data [mensaje]="'No hay ventas registradas.'" [pd]=""> </app-no-data>
      </ng-template>
    </div>
  </ng-template>
</div>
<div class="content-graf" *ngIf="g2">
  
  <div class="titulo-row-b">
    <button mat-icon-button (click)="resetDivGlobal();">
      <mat-icon>insert_chart_outlined</mat-icon>
    </button>
    {{this.vYear}} {{this.vName}}
    <mat-form-field (change)="updateC($event.target.value)">
      <select matNativeControl>
        <option selected value="total">Venta MXN</option>
        <option value="volumen">Volumen</option>
      </select>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="wrapper" *ngIf="loadingb;else contentb">
    <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
  </div>
  <ng-template #contentb>
    <div class="content-graf">
        <mat-grid-list [cols]="breakpoint"  rowHeight="380px" (window:resize)="onResize($event)">
        <mat-grid-tile [colspan]="colspa" class="spar_grid">
          <div style="height: 100%; width:85%">
            <div style="display:flex; flex-direction:column;">
              <div style="height:380px">
                <div class="my-flex-mat-card">
                <ngx-charts-bar-vertical [scheme]="bcolorScheme" [results]="dataResultb" [gradient]="bgradient"
                  [xAxis]="bshowXAxis" [yAxis]="bshowYAxis" [legend]="bshowLegend" [legendTitle]="blegendTitle"
                  [showXAxisLabel]="bshowXAxisLabel" [showYAxisLabel]="bshowYAxisLabel" [xAxisLabel]="bxAxisLabel"
                  [yAxisLabel]="byAxisLabel" [maxXAxisTickLength]="4" (select)="onSelectTopD($event)">
                </ngx-charts-bar-vertical>
              </div>
            </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="colspb">
          <div class="table-container">
            <table mat-table [dataSource]="transactionsb" class="mat-elevation-z8" class="table-vieg2">
              <ng-container matColumnDef="mes">
                <th mat-header-cell *matHeaderCellDef> Mes </th>
                <td mat-cell *matCellDef="let transactionsb"> {{transactionsb.name}} </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef> Venta MXN </th>
                <td mat-cell *matCellDef="let transactionsb"> {{transactionsb.value | currency}} </td>
                <td mat-footer-cell *matFooterCellDef> {{getTotalgbe() | currency}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsb"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsb;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumnsb"></tr>
            </table>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </ng-template>
</div>
<div class="content-graf" *ngIf="g3">
  <div class="titulo-row-b">
    <button mat-icon-button (click)="resetDivGlobal();">
      <mat-icon>insert_chart_outlined</mat-icon>
    </button>
    {{this.vYear}} {{this.vName}}
    <mat-form-field (change)="updateD($event.target.value)">
      <select matNativeControl>
        <option value="total">Venta MXN</option>
        <option selected value="volumen">Volumen</option>
      </select>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="wrapper" *ngIf="loadingc;else contentc">
    <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
  </div>
  <ng-template #contentc>
    <div class="content-graf">
        <mat-grid-list [cols]="breakpoint"  rowHeight="380px" (window:resize)="onResize($event)">
        <mat-grid-tile [colspan]="colspa" class="spar_grid">
          <div style="height: 100%; width:85%">
            <div style="display:flex; flex-direction:column;">
              <div style="height:380px">
                <div class="my-flex-mat-card">
                <ngx-charts-bar-vertical [scheme]="ccolorScheme" [results]="dataResultc" [gradient]="cgradient"
                  [xAxis]="cshowXAxis" [yAxis]="cshowYAxis" [legend]="cshowLegend" [legendTitle]="clegendTitle"
                  [showXAxisLabel]="cshowXAxisLabel" [showYAxisLabel]="cshowYAxisLabel" [xAxisLabel]="cxAxisLabel"
                  [yAxisLabel]="cyAxisLabel" [maxXAxisTickLength]="4" (select)="onSelectTopD($event)">
                </ngx-charts-bar-vertical>
              </div>
            </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="colspb">
          <div class="table-container">
            <table mat-table [dataSource]="transactionsc" class="mat-elevation-z8" class="table-vieg2">
              <ng-container matColumnDef="mes">
                <th mat-header-cell *matHeaderCellDef> Mes </th>
                <td mat-cell *matCellDef="let transactionsc"> {{transactionsc.name}} </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
              </ng-container>
              <ng-container matColumnDef="unidad">
                <th mat-header-cell *matHeaderCellDef> Unidad </th>
                <td mat-cell *matCellDef="let transactionsc"> {{transactionsc.extra.unidad}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef> Volumen </th>
                <td mat-cell *matCellDef="let transactionsc"> {{transactionsc.value}} </td>
                <td mat-footer-cell *matFooterCellDef> {{getTotalgc() }} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsc"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsc;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumnsc"></tr>
            </table>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </ng-template>
</div>

<div class="content-graf" *ngIf="g4">
  <div class="titulo-row-b">
    <button mat-icon-button (click)="resetDivGlobal();">
      <mat-icon>insert_chart_outlined</mat-icon>
    </button>
    {{this.vYear}} {{this.vMes}} {{this.vName}}
  </div>
  <mat-divider></mat-divider>
  <div class="wrapper" *ngIf="loadingd;else contentd">
    <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
  </div>
  <ng-template #contentd>
    <div class="content-graf">
        <mat-grid-list [cols]="breakpoint"   rowHeight="500px" (window:resize)="onResize($event)">
        <mat-grid-tile [colspan]="colspa" class="spar_grid">
          <div style="height: 100%; width:85%">
            <div style="display:flex; flex-direction:column;">
              <div style="height:380px">
                <div class="my-flex-mat-card">
                <ngx-charts-pie-chart [scheme]="dcolorScheme" [results]="dataResultd" [gradient]="dgradient"
                  [legend]="dshowLegend" [legendPosition]="dlegendPosition" [labels]="dshowLabels"
                  [doughnut]="disDoughnut">
                </ngx-charts-pie-chart>
              </div>
            </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="colspb">
          <div class="table-container">
            <table mat-table [dataSource]="transactionsd" class="mat-elevation-z8" class="table-vieg2">
              <ng-container matColumnDef="cliente">
                <th mat-header-cell *matHeaderCellDef> Cliente </th>
                <td mat-cell *matCellDef="let transactionsd"> {{transactionsd.name}} </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
              </ng-container>
              <ng-container matColumnDef="volumen">
                <th mat-header-cell *matHeaderCellDef> Vol </th>
                <td mat-cell *matCellDef="let transactionsd"> {{transactionsd.extra.volumen | number}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
              </ng-container>
              <ng-container matColumnDef="unidad">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let transactionsd"> {{transactionsd.extra.unidad }} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
              </ng-container>
              <ng-container matColumnDef="tot">
                <th mat-header-cell *matHeaderCellDef> Total </th>
                <td mat-cell *matCellDef="let transactionsd"> {{transactionsd.value | currency}} </td>
                <td mat-footer-cell *matFooterCellDef> {{getTotalgd() | currency}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsd"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsd;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumnsd"></tr>
            </table>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </ng-template>
</div>