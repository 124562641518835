import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { AreaService } from 'src/app/services/area.service';
import { StarRatingComponent } from 'ng-starrating';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { DelTaskSchemeComponent } from './del-task-scheme/del-task-scheme.component';
import { UpTaskSchemeComponent } from './up-task-scheme/up-task-scheme.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-task-scheme',
  templateUrl: './task-scheme.component.html',
  styleUrls: ['./task-scheme.component.css'],
})
export class TaskSchemeComponent implements OnInit {
  displayedColumns: string[] = ['pro', 'des', 'fec'];
  obsTask = new BehaviorSubject<any>(null);

  dataSource = new MatTableDataSource<any>();
  objData = new BehaviorSubject(null);
  formPurchaseOrder: FormGroup;
  miAr: any;
  toppingList: any;
  raking: number;
  idPro: any;
  data: boolean;
  codTas: any;
  op: boolean;
  taskadd: boolean;
  constructor(private areaService: AreaService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getTaskAllShchemes();
    this.createForm();
    this.getMiembrosGen();
    this.getRefreshTask();
  }

  getRefreshTask() {
    this.areaService.oTaskSchemen$.subscribe((val) => {
      this.getTaskShow(val);
    });
  }

  createForm() {
    this.formPurchaseOrder = new FormGroup({
      tarea: new FormControl('', [Validators.required]),
      des: new FormControl('', [Validators.required]),
      entre: new FormControl('', [Validators.required]),
      responsable: new FormControl('', [Validators.required]),
      dias: new FormControl('', [Validators.required]),
      pre: new FormControl(''),
      colora: new FormControl(this.generarNuevoColor(), [Validators.required]),
    });
  }

  getTaskAllShchemes() {
    this.areaService.getTaskAllShchemes().subscribe((resp) => {
      // this.dataSource.data = resp;
      this.objData.next(resp);
    });
  }

  getTaskShow(vTs) {
    this.idPro = vTs;

    this.getAllTaskPro(this.idPro);
    this.getTaskAllShchemesId(this.idPro);
    this.getTaskAllShchemeMicroUltimate(this.idPro);

    this.op = true;
  }

  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    this.raking = $event.newValue;
  }

  getMiembrosGen() {
    this.areaService.getMiembrosGen().subscribe((resp) => {
      this.miAr = resp;
    });
  }

  getNeTask() {
    this.taskadd = true;
  }

  getNeTaskClo() {
    this.taskadd = false;
  }

  getTaskAllShchemesId(vTs) {
    this.areaService
      .getTaskAllShchemeMicroIdPro({ vTs: vTs })
      .subscribe((resp) => {
        this.toppingList = resp;
      });
  }

  getTaskAllShchemeMicroUltimate(vTs) {
    this.areaService
      .getTaskAllShchemeMicroUltimate({ vTs: vTs })
      .subscribe((resp) => {
        this.codTas = resp.cod_interno;
      });
  }

  add() {
    if (this.formPurchaseOrder.valid) {
      const cit = this.codTas;
      const tarea = this.formPurchaseOrder.controls.tarea.value;
      const des = this.formPurchaseOrder.controls.des.value;
      const responsable = this.formPurchaseOrder.controls.responsable.value;
      const prioridad = this.raking;
      const dias = this.formPurchaseOrder.controls.dias.value;
      const entre = this.formPurchaseOrder.controls.entre.value;
      const pre = this.formPurchaseOrder.controls.pre.value;
      const color = this.formPurchaseOrder.controls.colora.value;

      let newObj = {
        _id_pro: this.idPro,
        cit: cit,
        nombre: tarea,
        des: des,
        entre: entre,
        responsable: responsable,
        dias: dias,
        prioridad: prioridad,
        color: color,
        pre: pre,
        _id_status: '62f6691aaab86d26ed2318d9',
      };

      this.areaService.addTaskShchemeMicro(newObj).subscribe((resp) => {});
    } else {
    }

    this.getAllTaskPro(this.idPro);
    this.getTaskAllShchemesId(this.idPro);
    this.getTaskAllShchemeMicroUltimate(this.idPro);

    this.createForm();
  }
  ptakOff() {}

  getAllTaskPro(vAr) {
    this.areaService
      .getTaskAllShchemeMicroIdPro({ vTs: vAr })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.data = true;
        } else {
          this.data = false;
        }

        this.obsTask.next(resp);
      });
  }

  generarNuevoColor() {
    const minL = 0.75;
    const maxL = 0.9;
    const interval = 3000;
    const colors = {
      bgColor: '',
      txtColor: '',
      btnColor: '',
      btnFocus: '',
    };

    const hBase = Math.random();
    const newH = Math.floor(hBase * 360);
    const newL = Math.floor(Math.random() * 16) + 75;

    colors.bgColor = `hsl(${newH}, 100%, ${newL}%)`;
    colors.txtColor = `hsl(${newH}, 100%, 5%)`;
    colors.btnColor = `hsl(${newH}, 100%, 98%)`;
    colors.btnFocus = `hsl(${newH}, 100%, 95%)`;

    const [r, g, b] = this.HSLtoRGB(hBase, 1, newL * 0.01);

    // update css vars
    Object.entries(colors).forEach(([k, v]) => {
      document.documentElement.style.setProperty(`--${k}`, `${v}`);
    });

    return this.RGBtoHex(r, g, b);
  }

  HSLtoRGB(h, s, l) {
    let r, g, b;

    const rd = (a) => {
      return Math.floor(Math.max(Math.min(a * 256, 255), 0));
    };

    const hueToRGB = (m, n, o) => {
      if (o < 0) o += 1;
      if (o > 1) o -= 1;
      if (o < 1 / 6) return m + (n - m) * 6 * o;
      if (o < 1 / 2) return n;
      if (o < 2 / 3) return m + (n - m) * (2 / 3 - o) * 6;
      return m;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRGB(p, q, h + 1 / 3);
    g = hueToRGB(p, q, h);
    b = hueToRGB(p, q, h - 1 / 3);

    return [rd(r), rd(g), rd(b)];
  }

  RGBtoHex(r, g, b) {
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  }

  onDialogDel(vTs) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.data = { data: { id: vTs } };
    this.dialog.open(DelTaskSchemeComponent, dialogConfig);
  }

  onDialogUp(vTs, vPro) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80%';
    dialogConfig.data = { vTs: vTs, vPro: vPro };
    this.dialog.open(UpTaskSchemeComponent, dialogConfig);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
