import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UsuarioService } from "./../../../services/usuario.service";
import { MatSnackBar } from '@angular/material/snack-bar';
export interface DialogData {

  }


@Component({
  selector: 'app-del-usuarios',
  templateUrl: './del-usuarios.component.html',
  styleUrls: ['./del-usuarios.component.css']
})
export class DelUsuariosComponent implements OnInit  {

    id: string="";
    actividad: string="";
    local_data;
    message: string = "¿Seguro que deseas continuar?";
    confirmButtonText = "Si";
    cancelButtonText = "No";
    loading: boolean = true;
    constructor(
    public dialogRef: MatDialogRef<DelUsuariosComponent>,
    public usuarioService: UsuarioService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.local_data=this.data;
    this.id=this.local_data.data.id;

    }

    ngOnInit(): void {



    }


    onConfirmClick(){

    this.usuarioService.delUsuario({id:this.id}).subscribe(response => {
    this.onClose();
    this.openSnackBar(response.status, 'ok');
    this.usuarioService.usuarioSer$.emit();
    this.dialogRef.close(true);
  }, error => this.openSnackBar("Ha ocurrido un error","Error"));
    }

    openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    panelClass: [tipo]
    });
    }

    onClose() {
    this.dialogRef.close();
    }



}
