import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pcrsma',
  templateUrl: './pcrsma.component.html',
  styleUrls: ['./pcrsma.component.css']
})
export class PcrsmaComponent implements OnInit {
  @Input() value: number;
  cals: string;
  icc: string;

  constructor() { }

  ngOnInit(): void {
    this.validateValue();

  }

  validateValue() {
    // if (this.value > 0) {
    //   this.cals = "gree";
    //   this.icc = "trending_up";
    // } else {
    //   this.cals = "gred";
    //   this.icc = "trending_down";
    // }

    if (this.value > 15) {
      this.cals = "gree";
      this.icc = "trending_up";
    } 
    else if (this.value < 0){ 
      
      this.cals = "gred";
      this.icc = "trending_down";

    }
    else if (this.value >= 0 && this.value < 15){
      this.cals = "gry";
      this.icc = "warning";
      
      }


  }


}
