import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { VenSemAdminService } from "../../../services/sales/ven-sem-admin.service";
import { CustomerService } from "./../../../services/customer.service";
@Component({
  selector: 'app-panel-venta-semana-admin-detalle',
  templateUrl: './panel-venta-semana-admin-detalle.component.html',
  styleUrls: ['./panel-venta-semana-admin-detalle.component.css']
})
export class PanelVentaSemanaAdminDetalleComponent implements OnInit {
  ci: string;
  sm: string;
  ye: string;
  convera: string;
  converb: string;
  dataResultgra: any;
  graf$: Observable<any[]>;
  vGrafica: any;
  cid: string;
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showDataLabel: boolean = false;
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'MXN';
  animations: boolean = true;
  legenda: string = "Leyenda";
  roundDomains: boolean = true;
  colorScheme = {
    domain: ['#76a7f4', '#6b8ce6', '#6071d7', '#8671e1', '#ab71eb', '#b188ef']
  };
  noBarWhenZero: true;
  datagra=false;
  idd: any;
  showdetail: boolean=false;
  texts: string="Acumulado";
  constructor(
    private route: ActivatedRoute,
    private venSemAdminService: VenSemAdminService,
    private customerService:CustomerService
  ) {

    this.ci = this.route.snapshot.paramMap.get('id');
    this.sm = this.route.snapshot.paramMap.get('sem');
    this.ye = this.route.snapshot.paramMap.get('yeara');
    this.getRagDay(this.sm, this.ye);
    this.getMaDate();
  }

  ngOnInit(): void {
    this.getDataSubGrafica();
    this.getIdEpicorCli(this.ci);
  }

  getIdEpicorCli(_id) {
    this.customerService.getIdEpicorCli({
      id_epicor: _id,
    
    }).subscribe((resp) => {
      this.idd=resp._id;
         
    });
  }

  showdetailF() {
    if (this.showdetail) {
      this.showdetail = false;
      this.texts="Acumulado";
    } else {
      this.showdetail = true;
      this.texts="Cerrar ";

    }


  }


  getDataSubGrafica() {
    this.graf$ = this.venSemAdminService.getGraf$();
    this.graf$.subscribe(val => {
      this.dataResultgra = val[0];
      if (this.dataResultgra.length>0) {
        this.datagra=true;
      }
    });
  }

  getRagDay(week, yeara) {
    const vSem = this.getSem(week);
    let dateMaster = this.getWeek();
    const ra = this.getWeekDayFl(yeara, Number(vSem), 'a');
    const rb = this.getWeekDayFl(yeara, Number(vSem), 'b');
    this.convera = this.formatoDate(ra);
    this.converb = this.formatoDate(rb);

  }

  getWeek() {
    const week = moment().format('W');
    const yeara = moment().format('YYYY');
    const yearb = moment().subtract(1, 'year').format('YYYY');
    const p = this.getWeekDayFl(yeara, week, 'a');
    const u = this.getWeekDayFl(yeara, week, 'b');
    const pr = moment(p).format('YYYY-MM-DD');
    const ul = moment(u).format('YYYY-MM-DD');
    const mes = moment().format('M');

    let dataFecha = {
      week: week,
      wa: week + "-" + yeara,
      wb: week + "-" + yearb,
      firstday: pr,
      lastday: ul,
      ya: yeara,
      yb: yearb,
      mes: mes
    };
    return dataFecha;
  }
  getWeekDayFl(year, week, ve) {
    var primerdia = new Date(year, 0, 1);
    var correccion = 6 - primerdia.getDay();
    var data;
    if (week * 7 + correccion > 365) {
      alert('El valor para semana no es válido');
      return false;
    }
    if (ve == 'a') {
      data = new Date(year, 0, (week - 1) * 7 + 3 + correccion);
    }
    if (ve == 'b') {
      data = new Date(year, 0, (week - 1) * 7 + 9 + correccion);
    }

    return data;
  }

  formatoDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }


  getSem(vSema) {
    let semMatriz = vSema.split('-');
    return semMatriz[0];
  }
  getMaDate() {
    let listYears = [];
    var vYearInicio = 2017;
    var vYearFin = Number(moment().format('YYYY'));
    while (vYearFin >= vYearInicio) {
      listYears.push(vYearInicio);
      vYearInicio++
    }

    let muestra = listYears.map(function (year) {
      let obj = {
        yeara: year,
        yearb: year
      }
      return obj;
    });
  }



}
