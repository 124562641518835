import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BudgetService } from "../../../../services/budget.service";

@Component({
  selector: 'app-pre-mensual-producto-list',
  templateUrl: './pre-mensual-producto-list.component.html',
  styleUrls: ['./pre-mensual-producto-list.component.css']
})
export class PreMensualProductoListComponent implements OnInit {

  @Input() vCliente: String;
  @Input() tCop: String;
  @Input() vEpicor: Number;
  @Input() vD: Date;
  @Input() vH: Date;
  @Input() vMes: Number;
  @Input() vYear: Number;
  @Input() vCh: String;




  // vCliente = "5fb810e5e54dec7576d77430";
  // vEpicor = "1050103004";
  // vD = "2022-04-01";
  // vH = "2022-04-30";
  // vMes = "4";
  // vYear = "2022";
  obs = new BehaviorSubject<any>(null);
  obsOut = new BehaviorSubject<any>(null);
  obsP = new BehaviorSubject<any>(null);
  obsV = new BehaviorSubject<any>(null);
  obsVfuera = new BehaviorSubject<any>(null);

  matrizGra: any;
  outVen: boolean;
  total: any;
  totalPreMes: any;
  totalPreMes_MXN: any;
  totalPreMes_USD: any;
  constructor(
    private budgetService: BudgetService

  ) { }

  ngOnInit(): void {

    if (this.tCop == "sM") {

      this.getPMAGCListProductoSem(this.vCliente, this.vD, this.vH, this.vMes, this.vYear);
      this.getPMAGCListProductoOutSem(this.vCliente, this.vD, this.vH, this.vMes, this.vYear);
      this.getPM_CTV_Sem(this.vCliente, this.vD, this.vH, this.vMes, this.vYear);
      this.getPM_CTP_Sem(this.vCliente, this.vD, this.vH, this.vMes, this.vYear);

    } else {

      this.getPMAGCListProducto(this.vCliente, this.vD, this.vH, this.vMes, this.vYear, this.vCh);
      this.getPMAGCListProductoOut(this.vCliente, this.vD, this.vH, this.vMes, this.vYear);
      this.getPM_CTV(this.vCliente, this.vD, this.vH, this.vMes, this.vYear);
      this.getPM_CTP(this.vCliente, this.vD, this.vH, this.vMes, this.vYear, this.vCh);

    }



  }

  getPMAGCListProductoOut(vCliente, vD, vH, vMes, vYear) {
    this.budgetService.getPMAGCListProductoOut({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vYear: vYear,
      vMes: vMes
    }).subscribe(
      resp => {

        let outvent = resp.filter(epic => epic.pre === false);
        this.obsOut.next(outvent);


        this.totalPreMes_MXN = outvent.map(item => item.vTVMxn).reduce((count, item) => count + item, 0);
        this.totalPreMes_USD = outvent.map(item => item.vTUsd).reduce((count, item) => count + item, 0);



        if (outvent.length > 0) {
          this.outVen = true;

        } else {
          this.outVen = false;
        }
      })
  }

  getPMAGCListProducto(vCliente, vD, vH, vMes, vYear, vCh) {
    this.budgetService.getPMAGCListProducto({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear,
      vCh:vCh
    }).subscribe(
      resp => {
        this.obs.next(resp);



      });
  }

  getPM_CTV(vCliente, vD, vH, vMes, vYear) {
    this.budgetService.getPM_CTV({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear
    }).subscribe(
      resp => {
        this.obsV.next(resp);

      });
  }
  getPM_CTP(vCliente, vD, vH, vMes, vYear, vCh) {
    this.budgetService.getPM_CTP({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear,
      vCh:vCh
    }).subscribe(
      resp => {
        this.obsP.next(resp);

      });
  }






  getPMAGCListProductoOutSem(vCliente, vD, vH, vMes, vYear) {
    this.budgetService.getPMAGCListProductoOutSem({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vYear: vYear,
      vMes: vMes
    }).subscribe(
      resp => {



        let outvent = resp.filter(epic => epic.pre === false);

        this.totalPreMes_MXN = outvent.map(item => item.vTVMxn).reduce((count, item) => count + item, 0);
        this.totalPreMes_USD = outvent.map(item => item.vTUsd).reduce((count, item) => count + item, 0);



        this.obsOut.next(outvent);

        if (outvent.length > 0) {
          this.outVen = true;

        } else {
          this.outVen = false;
        }
      })
  }

  getPMAGCListProductoSem(vCliente, vD, vH, vMes, vYear) {
    this.budgetService.getPMAGCListProductoSem({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear
    }).subscribe(
      resp => {
        this.obs.next(resp);

      });
  }

  getPM_CTV_Sem(vCliente, vD, vH, vMes, vYear) {
    this.budgetService.getPM_CTV_Sem({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear
    }).subscribe(
      resp => {
        this.obsV.next(resp);

      });
  }
  getPM_CTP_Sem(vCliente, vD, vH, vMes, vYear) {
    this.budgetService.getPM_CTP_Sem({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear
    }).subscribe(
      resp => {
        this.obsP.next(resp);

      });
  }



  getCal(p, v) {
    if (p === undefined || v === undefined) {
      return 0;
    } else {
      return v * 100 / p;
    }
  }


  getInfoPro(_id_producto, _id_cliente, vD, vH) {

    window.open('/sales_search_cri_vdh/' + _id_cliente + '/' + _id_producto + '/' + vD + '/' + vH, '_blank');

  }

}
