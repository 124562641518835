<div class="conte-pro">
    <div class="titulo-row-b">
        Busqueda
    </div>
    <mat-divider></mat-divider>
    <div class="ct-panel">
        <div>
            <mat-form-field class="mat-form">
                <mat-label>Fecha:</mat-label>
                <mat-date-range-input  [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Desde" (dateChange)="exeR($event)">
                    <input matEndDate formControlName="end" placeholder="Hasta" (dateChange)="exeR($event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                </mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>

            </mat-form-field>
        </div>
        <div class="btse">
            <mat-form-field class="mat-form">
                <mat-label>Tipo:</mat-label>
                <mat-select disableRipple (selectionChange)="exeData()" [formControl]="grupo">
                    <mat-option value="C1">CLIENTE</mat-option>
                    <mat-option value="C2">PRODUCTO</mat-option>
                    <mat-option value="C3">CANAL</mat-option>


                </mat-select>
            </mat-form-field>
        </div>
        <!-- <div class="btse">
            <button mat-raised-button color="primary" (click)="exeData()">
                <mat-icon>search</mat-icon>
                Buscar
            </button>
        </div> -->
    </div>

    <div *ngIf="sc">
        <div *ngIf="showc; else nodatq">
            <mat-form-field *ngIf="filt" class="mat-form-field">
                <mat-label>Filtro</mat-label>
                <input [formControl]="fil" matInput [(ngModel)]="searchText" placeholder="Ejemplo: Alfrut"
                    autocomplete="off" />
            </mat-form-field>
            <div *ngFor="let env of obs | async  | filter: searchText">
                <app-sales-cli [vCli]="env._id_cliente" [vD]="env.vD" [vH]="env.vH"></app-sales-cli>
            </div>
        </div>
        <ng-template #nodatq>
            <div class="divRespo">
                <app-info-data [pd]="" [mensaje]="'No se encontraron ventas'"></app-info-data>
            </div>
        </ng-template>
    </div>
    <div *ngIf="sp">

        <div *ngIf="showp; else nodataq">
            <mat-form-field *ngIf="filt" class="mat-form-field">
                <mat-label>Filtro</mat-label>
                <input matInput [formControl]="fils" [(ngModel)]="searchText" placeholder="Ejemplo: Frio Frut"
                    autocomplete="off" />
            </mat-form-field>
            <div *ngFor="let env of obsp | async  | filter: searchText">
                <app-sales-produc [vPro]="env._id_producto" [vD]="env.vD" [vH]="env.vH"></app-sales-produc>
            </div>
        </div>
        <ng-template #nodataq>
            <div class="divRespo">
                <app-info-data [pd]="" [mensaje]="'No se encontraron ventas'"></app-info-data>
            </div>
        </ng-template>

    </div>

    <div *ngIf="spcha">

        <div class="ct-data-canal">
            <div>
                <mat-form-field *ngIf="filt" class="mat-form-field">
                    <mat-label>Filtro</mat-label>
                    <input matInput [formControl]="fils" [(ngModel)]="searchText" placeholder="Ejemplo: Frio Frut"
                        autocomplete="off" />
                </mat-form-field>
            </div>
            <div>

                <!-- <mat-form-field class="sele" (change)="updateAn($event.target.value)">
                    <mat-label>Canal</mat-label>
                    <select [formControl]="prueba" matNativeControl>
                        <option [value]="">
                            TODOS
                        </option>
                        <option *ngFor="let env of obs_canal | async" [value]="env._id">
                            {{ env.canal }}
                        </option>
                    </select>
                </mat-form-field> -->

                <mat-form-field class="mat-form">
                    <mat-label>Canal:</mat-label>
                    <mat-select disableRipple (selectionChange)="updateAn()" [formControl]="canal">
                        <mat-option value=""> TODOS</mat-option>
                        <mat-option *ngFor="let env of obs_canal | async" value="{{env._id}}"> {{ env.canal }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="showpq; else nodataq">
            <div *ngFor="let env of obspc | async  | filter: searchText">
                <app-sales-cli [vT]="'C'" [vCh]="masterVch" [vCli]="env._id_cliente" [vD]="env.vD" [vH]="env.vH">
                </app-sales-cli>
            </div>
        </div>
        <ng-template #nodataq>
            <div class="divRespo">
                <app-info-data [pd]="" [mensaje]="'No se encontraron ventas'"></app-info-data>
            </div>
        </ng-template>
    </div>
</div>
<div class="ct-footer"></div>
<button [ngClass]="{'show-scroll': showScroll}" (click)="scrollToTop()" class="scroll-to-top bbutton">
    <mat-icon matTooltip="Volver arriba">arrow_upward</mat-icon>
</button>