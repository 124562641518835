import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { GlobalVariable } from '../global';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private URL = GlobalVariable.BASE_API_URL;
  public nombreUser = new BehaviorSubject<string>("-");
  public apellidoUser = new Subject<string>();
  public emailUser = new Subject<string>();
  public vUser = new BehaviorSubject<string>("-");
  public nombre$ = new EventEmitter<string>();
  private loggedIn = new BehaviorSubject<boolean>(false);
  public email$ = new EventEmitter<string>();
  public nomUser$ = new EventEmitter<string>();
  public roles = new Subject<any[]>();
  loginIN: boolean;
  constructor(private http: HttpClient, private router: Router) {
    this.configSubject = new Subject();
    this.mainObservable = this.configSubject.asObservable();


  }

  setvUser(data) {
    this.vUser.next(data);

  }

  getvUser() {
    return this.vUser.asObservable();
  }

  setApellido(data) {
    this.nombreUser.next(data);
  }

  setEmail(data) {
    this.emailUser.next(data);
  }

  getEmailOb() {
    // this.emailUser.next(data);
    return this.emailUser.asObservable();
  }

  setRoles(data) {
    this.roles.next(data);
  }

  loginUsuario(usuario) {
    return this.http.post(this.URL + 'login', usuario);
  }

  registroUsuario(usuario) {
    return this.http.post(this.URL + 'registro', usuario);
  }

  loginIn() {

    const tk = localStorage.getItem('token');
    if (tk !== '') {
      this.loggedIn.next(true);

    } else {
      this.loggedIn.next(false);
    }
    return !!localStorage.getItem('token');
  }

  loginOut() {


    localStorage.removeItem('token');
    localStorage.removeItem('nombre');
    localStorage.removeItem('email');
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
    this.nombreUser.next("");
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getNombre() {
    return localStorage.getItem('nombre');
  }

  getEmail() {
    return localStorage.getItem('email');
  }

  infoUsuario() {
    return this.http.get<any>(this.URL + 'perfil');
  }

  getAllUsuarios() {
    return this.http.get<any>(this.URL + 'getAllUsuarios');
  }

  updateUsuario(usuario) {
    return this.http.post<any>(this.URL + 'perfil', usuario);
  }

  getRoles() {
    return this.roles.asObservable();
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  public mainObservable = new Observable((observer) => {
    observer.next();
    observer.complete();
  });
  private configSubject: Subject<string>;

  changeTheme(theme) {
    this.configSubject.next(theme);
  }

  getOldConect() {
    return this.http.get<any>(this.URL + 'getOldConect');
  }

}
