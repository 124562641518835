<div class="conte-pro">
    <div class="ct-dat">
        <div>
            <div class="ct-ima" *ngIf="ig; else no_ima">
                <div *ngIf="iga; else no_ima_p">
                    <img [src]="validateIma(imagen)" class="ct-ima-layout ct-ima-post" width="46" height="46">
                </div>
                <ng-template #no_ima_p>
                    <div class="ct-imas">
                        <div class="ct-inf">
                            {{ getSter(nameUser) }}
                        </div>
                    </div>

                </ng-template>
            </div>
            <ng-template #no_ima>
                <div class="ct-imas">
                    <ngx-skeleton-loader appearance="circle" count="1" [theme]="{ 
                        'border-radius': '50px', 
                        'width': '80px',
                        'padding':'7px',
                        'margin':'5px',
                        'height': '80px',
                        'background-color': '#daddd8'
                    }">
                    </ngx-skeleton-loader>
                </div>
            </ng-template>

        </div>




        <div class="ct-sal">
           <span> Hola! </span> <span *ngIf="Odlfecha" class="ct-date-conection">Ǘlt. vez: <b>{{Odlfecha}}</b></span>
        </div>
        <div class="ct-nom">
            {{nameUser}}
        </div>
    </div>
</div>



<div [className]="this.show ? 'conte-pro' : 'conte-mo'" (window:resize)="onResize($event)">
    <mat-tab-group class="tabs-master-cli" mat-align-tabs="end">
        <div *appDroles="rol_Admin" class="container-graf">
            <mat-tab label="Venta Mensual">
                <ng-template matTabContent>
                    <app-panel-venta-mes-admin></app-panel-venta-mes-admin>
                </ng-template>
            </mat-tab>
            <mat-tab label="Venta Semanal">
                <ng-template matTabContent>
                    <app-panel-venta-semana-admin></app-panel-venta-semana-admin>
                </ng-template>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>