import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
// import { UpClienteComponent } from 'src/app/components/customer/up-cliente/up-cliente.component';
import { BudgetService } from "../../../../services/budget.service";
import { AssignedCustomerService } from "./../../../../services/assignedclient.service";
import { SendReclaComponent } from "./../../../sales/send-recla/send-recla.component";
@Component({
  selector: 'app-pre-mensual-cliente-lis',
  templateUrl: './pre-mensual-cliente-lis.component.html',
  styleUrls: ['./pre-mensual-cliente-lis.component.css']
})
export class PreMensualClienteLisComponent implements OnInit {
  loading = false;
  @Input() vCliente: String;
  @Input() tCop: String;
  @Input() vNombre: String;
  @Input() vEpicor: Number;
  @Input() vD: Date;
  @Input() vH: Date;
  @Input() vMes: Number;
  @Input() vYear: Number;
  @Input() vCh: String;
  
  showXAxis: boolean = false;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  roundDomains: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  customColors: any[] = [
  ];
  colorScheme = {
    domain: ['#26a116', '#3498DB']
  };

  obs = new BehaviorSubject<any>(null);
  // dattres = new BehaviorSubject<any>(null);
  dattres;
  panelOpenState = false;
  matrizGra: any[] = [];
  dataresp: boolean;
  loadinga: boolean;
  loadingb: boolean;
  constructor(
    private budgetService: BudgetService,
    private assignedCustomerService: AssignedCustomerService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,



  ) { }

  ngOnInit(): void {

    this.getClienteIdVendedor(this.vCliente, this.vMes, this.vYear)
    if (this.tCop === 'sM') {
      setTimeout(() => {
        this.getPMAGCListSem(this.vCliente, this.vD, this.vH, this.vMes, this.vYear);
      }, 1000)

    }

    if (this.tCop === 'mM') {
      setTimeout(() => {
        this.getPMAGCList(this.vCliente, this.vD, this.vH, this.vMes, this.vYear, this.vCh);
      }, 1000)
    }
  }

  getPMAGCList(vCliente, vD, vH, vMes, vYear, vCh) {
    this.budgetService.getPMAGCList({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear,
      vCh:vCh
    }).subscribe(
      resp => {
        this.obs.next(resp);
        this.loadinga = true;
      });
  }

  getClienteIdVendedor(vCliente, vMes, vYear) {
    this.assignedCustomerService.getClienteIdVendedor({
      _id_cliente: vCliente,
      mes: vMes,
      yeara: vYear
    }).subscribe(
      resp => {
        this.dattres = resp.vendedorAsig;
        if (this.dattres != undefined) {
          this.dataresp = true;
        } else {
          this.dataresp = false;
        }
        this.loadingb = true;
      });
  }


  getPMAGCListSem(vCliente, vD, vH, vMes, vYear) {
    this.budgetService.getPMAGCListSem({
      vCliente: vCliente,
      vD: vD,
      vH: vH,
      vMes: vMes,
      vYear: vYear
    }).subscribe(
      resp => {
        this.obs.next(resp);
        this.loadinga = true;
      });
  }

  getCal(p, v) {
    if (p === undefined || v === undefined) {
      return 0;
    } else {
      return v * 100 / p;
    }
  }



  onDialogSend(vNombre, vEpicor, vMes,
    vYear,
    vD,
    vH,
    vTPMxn,
    vTVMxn
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";
    dialogConfig.data = {
      data: {
        vCliente: vEpicor,
        vRcliente: vNombre,
        vMes: vMes,
        vYear: vYear,
        vD: vD,
        vH: vH,
        vTPMxn: vTPMxn,
        vTVMxn: vTVMxn
      }
    };
    this.dialog.open(SendReclaComponent, dialogConfig);
  }


  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }


}
