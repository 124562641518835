import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BloqueClienteComponent } from "./bloque-cliente/bloque-cliente.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnviosService } from "../../../services/envios.service";
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-add-envi-multiple',
  templateUrl: './add-envi-multiple.component.html',
  styleUrls: ['./add-envi-multiple.component.css']
})
export class AddEnviMultipleComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  obs = new BehaviorSubject<any>(null);
  cod_interno: any;
  panelOpenState = false;

  date: Date;
  bso: boolean=true
  constructor(
    private enviosService: EnviosService,
    public datepipe: DatePipe,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,

  ) {


  }

  ngOnInit(): void {
    this.createForm();
    this.getCodInterno();
    this.envlc();
    this.getWeek();

  }

  getWeek() {
    return moment().format('W');
  }

  envlc() {
    this.enviosService.envlc$.subscribe(() => {
      this.getAllEnvio();
    });
  }

  createForm() {
    this.firstFormGroup = this._formBuilder.group({
      op: [{ value: 'Cargando nuevo codigo...', disabled: true }, Validators.required],
      lugar_actual: ['ZAMORA, MICHOACÁN', Validators.required],
      sem: [{value:this.getWeek(), disabled:true}, Validators.required],
    });

  }


  regEnvioCodInterno() {

    const ci = this.firstFormGroup.get('op').value;
    let nuevoCodio = {
      ci: ci,
      estado: true
    }

    this.enviosService.regEnvioCodInterno(nuevoCodio)
      .subscribe(
        res => {
          this.openSnackBar(res.status, 'ok');
        });
    this.router.navigate(['shipping']);
  }


  onDialogAdd() {
    if (this.firstFormGroup.valid) {
      var op = this.firstFormGroup.get('op').value
      var sem = this.firstFormGroup.get('sem').value
      var lg = this.firstFormGroup.get('lugar_actual').value;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "80%";
      dialogConfig.data = { data: { op: op, lg: lg, sem: sem } };
      this.dialog.open(BloqueClienteComponent, dialogConfig);
    } else {
      this.openSnackBar("Verificar los valores iniciales del envio", 'alert');
    }
  }

  onDialogPro() {
    if (this.firstFormGroup.valid) {
      var op = this.firstFormGroup.get('op').value;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "80%";
      dialogConfig.data = { data: { op: op } };
      this.dialog.open(BloqueClienteComponent, dialogConfig);
    } else {
      this.openSnackBar("Verificar los valores iniciales del envio", 'alert');
    }
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  getAllEnvio() {
    var op = this.firstFormGroup.get('op').value;
    this.enviosService.getAllEnvioId({ ci: op }).subscribe(
      resp => {


        this.obs.next(resp.envios);
      });
  }

  getCodInterno() {
    this.bso=true;
    this.enviosService.getCodInterno().subscribe(
      res => {
        const cod_interno = res.cod_interno;
        this.firstFormGroup.patchValue({
          op: cod_interno
        });
        
        this.getAllEnvio();
        this.bso=false;
      });
  }

  validateIma(valor) {
    const v = valor;
    if (v !== 'null' && v !== undefined) return valor;
    else return 'assets/ima/no-ima.png';
  }

  onDelAsig(_id, op, ci, _id_cliente) {
    this.enviosService.rmdelallEnvio({ _id: _id, op: op, ci: ci, _id_cliente: _id_cliente }).subscribe(
      resp => {
        this.enviosService.envlc$.emit();
        this.openSnackBar(resp.status, 'ok');
      });
  }

  formatDate(fecha) {
    return fecha.substr(0,10);
  }

  formatRazonSocial(val) {
    var rzo: String;

    if (val.length > 50) {
      rzo = val.substr(0, 50) + "...";
    } else {
      rzo = val;
    }
    return rzo;

  }
}