import { Component, Input, OnInit } from '@angular/core';
import { SalesService } from "./../../../services/sales/sales.service";
import { BudgetService } from "./../../../services/budget.service";
@Component({
  selector: 'app-gra-ven-cus-year',
  templateUrl: './gra-ven-cus-year.component.html',
  styleUrls: ['./gra-ven-cus-year.component.css']
})
export class GraVenCusYearComponent implements OnInit {

  @Input() vYear: string;
  @Input() vCli: string;
  view = [500, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  legendTitle = '';
  legendPosition = 'below';
  showXAxisLabel = false;
  yRightTickFormat = false;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  yAxisLabel = 'PRE 2022';
  showGridLines = false;
  innerPadding = '10%';
  maxXAxisTickLength = 4;
  animations: boolean = true;
  barChart: any;
  linChart: any;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'ordinal',
    domain: ['#3498DB'],

  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#3498DB'],
  };

  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = 'Utilization';
  g: boolean = false;
  d: boolean = false;
  wiat: any[] = [];



  constructor(
    private salesService: SalesService,
    private budgetService: BudgetService
  ) { }

  ngOnInit(): void {

    

    this.getPreYearIdCliCus(this.vYear, this.vCli);
    this.masterPreCusVenYear(this.vYear, this.vCli);
    this.generateRandomInt();
  }


  getPreYearIdCliCus(vYear, vCli) {
    this.budgetService.getPreYearIdCliCus({ vYear: vYear, vCli: vCli }).subscribe(
      resp => {
        if (resp.length > 0) {
          this.barChart = resp;
          this.g = true;
        }
      });
  }

  masterPreCusVenYear(vYear, vCli) {
    this.budgetService.masterPreCusVenYear({ vYear: vYear, vCli: vCli  }).subscribe(
      resp => {     
        if (resp.length > 0) {
          this.linChart = resp;
          this.d = true;
        }
      });
  }

  generateRandomInt() {
    for (let i = 0; i < 11; i++) {
      this.wiat.push({
        'border-radius': '5px',
        'width': '30px',
        'padding': '5px',
        'margin': '7px',
        'height': Math.floor((Math.random() * (210 - 20)) + 20) + 'px',
        'background-color': '#daddd8'
      });
    }
    return this.wiat;
  }


}
