<section class="ct-ima-pro">
    <div class="ct-ima-pan-w"  *ngIf="datas; else noda1">
        <ngx-skeleton-loader [theme]="{
            'width': '100vw',
            'padding': '5px',
            'background-color': '#daddd8',
            'display': 'table-cell'
          }">
        </ngx-skeleton-loader>
    </div>
    <ng-template #noda1>
        <div class="ct-ima-pan-pro">
            <img class="ct-ima-pan-pro-ima" [src]="validateIma(obs | async) || 'assets/ima/no-ima.png'">
        </div>
    </ng-template>
</section>

