import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AreaService } from 'src/app/services/area.service';
import { DialogData } from '../../budget/del-presupuesto/del-presupuesto.component';
import { DelClienteComponent } from '../../customer/del-cliente/del-cliente.component';

@Component({
  selector: 'app-area-del',
  templateUrl: './area-del.component.html',
  styleUrls: ['./area-del.component.css'],
})
export class AreaDelComponent implements OnInit {
  id: string = '';
  actividad: string = '';
  local_data;
  message: string = '¿Seguro que deseas continuar?';
  confirmButtonText = 'Si';
  cancelButtonText = 'No';
  constructor(
    public dialogRef: MatDialogRef<DelClienteComponent>,
    public areaService: AreaService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.local_data = this.data;
    this.id = this.local_data.data.id;
  }

  ngOnInit() {}

  onConfirmClick() {
    this.areaService.delArea({ id: this.id }).subscribe(
      (response) => {
        this.onClose();
        this.openSnackBar(response.status, 'ok');
        this.dialogRef.close(true);
        this.goToLink();
      },
      (error) => this.openSnackBar('Ha ocurrido un error', 'Error')
    );
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  goToLink() {
    // window.close();
    window.open("/areas", "_self");
  }
}
