<div class="conte-pro">

  <div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/dashboard']">
      <mat-icon>navigate_before</mat-icon>
    </button>
    Actualización
  </div>

  <mat-divider></mat-divider>
  <form [formGroup]="form" method="post" (ngSubmit)="updatePerfil();">
    <div class="ct-content">
      <div class="ima-user">
        <input value="" required hidden (change)="onFileSelected($event)" #fileInput type="file" id="ima">
        <br>
        <div class="ct-data">
          <div class="titulo-row-b">Imagen de Perfil</div>
          <div>
            <img mat-card-avatar [src]="photoSelected || 'assets/ima/no-ima.png'" class="imgfluid"
              (click)="fileInput.click()">
          </div>
          <div class="ct-cli">Click para seleccionar una imagen</div>
        </div>
      </div>
      <div>
        <div class="example-container">

          <mat-form-field class="mat-form" appearance="fill">
            <mat-label>Epicor</mat-label>
            <input matInput formControlName="cod_epicor" [disabled]="false">
            <mat-error *ngIf="cod_epicor.invalid">{{getErrorMessageCod()}}</mat-error>
          </mat-form-field>
          <br>

          <mat-form-field class="mat-form" appearance="fill">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" value="" required>
            <mat-error *ngIf="nombre.invalid">{{getErrorMessageNombre()}}</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="apellido" required>
            <mat-error *ngIf="apellido.invalid">{{getErrorMessageApellido()}}</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Telefono</mat-label>
            <input matInput formControlName="telefono" required>
            <mat-error *ngIf="telefono.invalid">{{getErrorMessageTelefono()}}</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Correo</mat-label>
            <input matInput placeholder="pat@example.com" formControlName="email" required>
            <mat-error *ngIf="email.invalid">{{getErrorMessageEmail()}}</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Contraseña</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="clave">
            <mat-error *ngIf="clave.invalid">{{getErrorMessageClave()}}</mat-error>

          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Fecha de Nacimiento</mat-label>
            <input matInput [matDatepicker]="dp" formControlName="date" value="">
            <mat-error>{{getErrorMessageDp()}}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event)" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
          <br>

        </div>
      </div>
    </div>

    <div class="btn-enviar">
      <button class="btn-agregar" mat-raised-button color="primary">
        Actualizar
      </button>
    </div>
  </form>
</div>