<section class="master">
    <div class="contenedor">
        <div class="data-card">
            <div class="data-card-ico-m" ></div>
          <mat-icon class="data-card-ico" matListIcon>info</mat-icon>
          <div class="data-titulo">INFO</div>
          <div class="data-mensaje">
            {{mensaje.toUpperCase()}} {{pd}}
          </div>
        </div>
      </div>
    </section>