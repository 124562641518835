<div class="contenedor">
  <div class="data-card">
    <div class="data-card-ico-m" ></div>
    <mat-icon class="data-card-ico" matListIcon>report_problem</mat-icon>
    <div class="data-titulo">WARNING</div>
    <div class="data-mensaje">
      {{mensaje.toUpperCase()}} {{pd}}
    </div>
  </div>
</div>
