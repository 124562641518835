<div class="ct-panel">
    <div>
      <mat-form-field class="mat-form">
        <input type="text" placeholder="Ejemplo: Grupo Retail..." matInput [formControl]="grupoF"
          [matAutocomplete]="autoGrupo" />
        <mat-autocomplete autoActiveFirstOption #autoGrupo="matAutocomplete">
          <mat-option *ngFor="let optionGrupo of filteredOptionsGrupo | async"
            [value]="optionGrupo.nombre" (click)="setGrupo(optionGrupo._id)">
            <div class="listadoProducto">
              {{ optionGrupo.nombre }}
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div >
        <mat-form-field class="mat-form">
            <input type="text" placeholder="Ejemplo: AlFrut..." matInput [formControl]="clienteF"
              [matAutocomplete]="autoCliente" />
            <mat-autocomplete autoActiveFirstOption #autoCliente="matAutocomplete">
              <mat-option *ngFor="let optionCliente of filteredOptionsCliente | async"
                [value]="optionCliente.razon_social" (click)="setCliente(optionCliente._id)">
                <div class="listadoProducto">
                  {{ optionCliente.id_epicor }} -
                  {{ optionCliente.razon_social }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
    </div>
    <div class="ino">
      <button class="bnb" color="primary" mat-raised-button mat-button (click)="add()">
        <mat-icon>add</mat-icon>Agregar
    </button>
    </div>
</div>

<table *ngIf="show" mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">
  <ng-container matColumnDef="ima">
    <th mat-header-cell *matHeaderCellDef> 
    </th>
    <td mat-cell *matCellDef="let element">     <app-ima-cliente [idcliente]="element.id_epicor" [classCss]="'sm'"></app-ima-cliente>    </td>
  </ng-container>
  <ng-container matColumnDef="cod">
    <th mat-header-cell *matHeaderCellDef> Cod </th>
    <td mat-cell *matCellDef="let element"> {{element.id_epicor}} </td>
  </ng-container>
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef> Cliente </th>
      <td mat-cell *matCellDef="let element"> {{element.razon_social}} </td>
    </ng-container>
    <ng-container matColumnDef="opciones">
      <th mat-header-cell *matHeaderCellDef> Opciones </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button matTooltip="¿Seguro que deseas borrar este cliente del grupo?" matTooltipClass="example-tooltip-red"
            mat-menu-item (click)="onDialogDel(element._id)">
            <mat-icon>delete_forever</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
