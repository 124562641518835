import { Component, Input, OnInit } from '@angular/core';
import { VenSemAdminService } from "../../../../../services/sales/ven-sem-admin.service";

@Component({
  selector: 'app-pdv-producto-price-histo',
  templateUrl: './pdv-producto-price-histo.component.html',
  styleUrls: ['./pdv-producto-price-histo.component.css']
})
export class PdvProductoPriceHistoComponent implements OnInit {
  @Input() idproducto: String;
  @Input() idcliente: String;
  @Input() idd: String;

  multi: any[];
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = false;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = false;
  colorScheme = {
    domain: ['#e6a927', '#34ad15']
  };
  autoScale = true;

  constructor(
    private venSemAdminService: VenSemAdminService
  ) { }

  ngOnInit(): void {
    this.getGraVenSemProFolio(this.idproducto, this.idd);
  }
  getGraVenSemProFolio(idproducto, idd) {
    this.venSemAdminService.getPdvProductoPriceHisto({
      _id_producto: idproducto,
      _id_cliente: idd
    }).subscribe((resp) => {
      this.multi = resp;

      
    });
  }


  onSelect(event) {


    window.open('/sales_search_cri_p/' + this.idd + '/' + this.idproducto + '/' + event.name, '_blank');



  }

}
