import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LogsService } from "./../../../services/logs.service";
@Component({
  selector: 'app-panel-logs',
  templateUrl: './panel-logs.component.html',
  styleUrls: ['./panel-logs.component.css']
})
export class PanelLogsComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);
  show: boolean;

  constructor(
    private logsService: LogsService
  ) { }

  ngOnInit(): void {
    this.logsService.listNoti$.subscribe((objeto) => {
      this.getLogs();
    });
    this.logsService.listNoti$.emit();
  }

  getLogs() {
    this.logsService.getLogs().subscribe((resp) => {
      this.obs.next(resp);


      if (resp.length>0) {

        this.show=true;
        
      } else {
        this.show=false;
      }

    });

  }


  onCli(){
    this.logsService.listNoti$.emit("");
  }


}
