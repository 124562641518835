import { trigger, state, style, transition, animate } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { TrasChannelComponent } from '../tras-channel/tras-channel.component';
import * as moment from 'moment';
import { ChannelService } from "./../../../services/channel.service";
import { CustomerService } from "./../../../services/customer.service";
import { FormControl } from '@angular/forms';

import { TraslCliSinAgrupacionComponent } from "./../trasl-cli-sin-agrupacion/trasl-cli-sin-agrupacion.component";


@Component({
  selector: 'app-ch-asignador',
  templateUrl: './ch-asignador.component.html',
  styleUrls: ['./ch-asignador.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ChAsignadorComponent implements OnInit {
  expandedElement: [PeriodicElement] | false;
  @ViewChild(MatPaginator) paginators: MatPaginator;
  @ViewChild(MatPaginator) paginators_p: MatPaginator;
  dataSourceClis = new MatTableDataSource<any>();
  displayedColumnsClis: string[] = ['select', 'cod', 'ima', 'razon_social'];
  dataSourceClip = new MatTableDataSource<any>();
  displayedColumnsClip: string[] = ['select', 'cod', 'nombre'];
  selection = new SelectionModel<PeriodicElement>(true, []);
  obsCust = new BehaviorSubject<any>(null);
  loading = true;
  public searchText: any;
  show: boolean;
  listRango: any = [];
  btt: boolean;
  bttn: number;
  removable = true;
  selec = new FormControl();
  selep = new FormControl();
  ctpa: boolean;
  loadingp = true;
  ctpb: boolean;
  showp: boolean;
  constructor(
    private channelService: ChannelService,
    private customerService: CustomerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.setList();
    const vYear = moment().format('YYYY');
    this.selep.setValue(vYear);
    this.selec.setValue('1');
    this.getCustChannelAll(vYear);
    this.customerService.listCliSn$.subscribe((valor) => {
      this.ctpa = true;
      this.loading = true;
      this.getCustChannelAll(vYear);
    });
  }

  ngAfterViewInit() {
    this.dataSourceClis.paginator = this.paginators;
  }

  applyFilters(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceClis.filter = filterValue.trim().toLowerCase();
  }



  getCustChannelAll(vYear) {
    this.customerService.getCustChannelAll({ vYear: vYear }).subscribe(resp => {

      this.ctpa = true;
      if (resp.length > 0) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.dataSourceClis.data = resp;
      this.dataSourceClis.paginator = this.paginators;
      this.selection.clear();
      this.loading = false;


    });

  }



  onDialogTras() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.data = this.selection.selected;
    this.dialog.open(TraslCliSinAgrupacionComponent, dialogConfig);
  }

  remove(vCli): void {
    const index = this.selection.selected.indexOf(vCli);
    if (index >= 0) {
      this.selection.selected.splice(index, 1);
    }
    if (this.selection.selected.length == 0) {
      this.selection.clear();
    }
  }

  isAllSelected() {

    if (this.selection.selected.length > 0) {
      this.btt = true;
      this.bttn = this.selection.selected.length;
    } else {
      this.btt = false;
      this.bttn = 0;
      this.selection.clear();
    }

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceClis.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceClis.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row._id + 1}`;
  }

  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }

  updateAn() {


    const vYear = this.selep.value;
    if (vYear) {
      this.getCustChannelAll(vYear);
      this.ctpa = true;
    }
  
  }

}

export interface PeriodicElement {
  _id: string;
  razon_social: number;
}
