import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GanttChartAngularModule } from 'gantt-chart-angular';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { SafeSubscriber } from 'rxjs/internal/Subscriber';
import { AreaService } from 'src/app/services/area.service';
@Component({
  selector: 'app-task-gantt',
  templateUrl: './task-gantt.component.html',
  styleUrls: ['./task-gantt.component.css'],
})
export class TaskGanttComponent implements OnInit, AfterViewInit {
  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent: ElementRef<any>;
  @ViewChildren('customLabel') labels: QueryList<ElementRef>;
  @ViewChild('target', { read: ElementRef })
  public target: ElementRef<any>;
  @Input() vAr: string;
  arrayDate = [];
  resuArra = [];
  resuLif = [];
  arrayDateOb = new BehaviorSubject<any>(null);
  introText = 'Welcome';
  tasks = [
    { nombre: 'TASK 1', start: 'Jul. 18', end: 'Aug. 01', color: '#b03532' },
    { nombre: 'TASK 2', start: 'Jul. 21', end: 'Jul. 29', color: '#33a8a5' },
  ];
  dateStar: any;
  dateEnd: any;
  taskmaster: any;
  data: boolean;
  constructor(private areaService: AreaService, elementRef: ElementRef) {}

  async ngOnInit() {
    this.areaService.cBeem$.subscribe(async (val) => {
      this.resuArra = [];
      this.arrayDate = [];
      this.resuLif = [];

      let data = await this.getTasDate(this.vAr);

      if (data) {
        await this.getDias(data.dateStar, data.dateEnd);
      }

      this.getAlltaks(this.vAr);
    });

    let data = await this.getTasDate(this.vAr);

    if (data) {
      await this.getDias(data.dateStar, data.dateEnd);
    }

    this.getAlltaks(this.vAr);
  }

  getAlltaks(vAr) {
    this.areaService.getAllTaskAreaId({ vAr: vAr }).subscribe((resp) => {
      this.taskmaster = resp;
    });
  }

  async getTasDate(vAr) {
    let obj;
    await this.areaService
      .getTaskLienzo({ vAr: vAr })
      .toPromise()
      .then((resp) => {
        if (resp.length > 0) {
          obj = resp[0];
          this.data = true;
        } else {
          this.data = false;
        }
      })
      .catch((e) => console.error(e));
    return obj;
  }

  getDias(a, b) {
    var desde = moment(a);
    var hasta = moment(b);
    this.arrayDate = this.diasEntreFechas(desde, hasta);


    this.arrayDateOb.next(this.arrayDate);
  }

  diasEntreFechas(a, b) {
    var dia_actual = a;
    var fechas = [];
    while (dia_actual.isSameOrBefore(b)) {
      fechas.push({
        dias: dia_actual.format('MMM. DD'),
        iddClas:
          dia_actual.format('MMM. DD') == moment().format('MMM. DD')
            ? 'toDay'
            : 'afDay',
        sta:
          dia_actual.format('MMM. DD') == moment().format('MMM. DD')
            ? true
            : false,
        stas: this.isEndWeek(dia_actual),
      });
      dia_actual.add(1, 'days');
    }

    return fechas;
  }

  createGrafic(arraDat) {
    const daysArray = this.resuLif;

    arraDat.forEach((tas) => {
      const startDay = moment(tas.fecha_star).format('MMM. DD');
      const endDay = moment(tas.fecha_end).format('MMM. DD');

      let left = 0,
        width = 0;

      const filteredArrayS = daysArray.filter((day) => day.d == startDay);

      const filteredArrayE = daysArray.filter((day) => day.d == endDay);

      left = filteredArrayS[0].l;
      width = filteredArrayE[0].l + filteredArrayE[0].w - left;

      this.resuArra.push({
        cit: tas.cit,
        fecha_star: moment(tas.fecha_star).format('YYYY-MM-DD'),
        fecha_end: moment(tas.fecha_end).format('YYYY-MM-DD'),
        nombre: tas.nombre,
        des: tas.des,
        responsable: tas.responsable,
        color: tas.color,
        left: `${left}px`,
        width: `${width}px`,
        idd: tas.des,
      });
    });
  }

  scroll() {
    let onj = {};

    this.labels.forEach((label) => {
      let text = label.nativeElement.innerHTML.trim().substring(0, 7);
      let wina = label.nativeElement.offsetWidth;
      let wlet = Number(label.nativeElement.offsetLeft);

      if (moment(text).format('MMM. DD') == moment().format('MMM. DD')) {
        this.widgetsContent.nativeElement.scrollTo({
          left: this.widgetsContent.nativeElement.scrollLeft + 300 + wlet / 2,
          behavior: 'smooth',
        });
      }
    });

    // var middleOffset = this.target.nativeElement;

    // console.log(middleOffset.offsetLeft);
    // console.log(middleOffset.innerHTML);

    // this.widgetsContent.nativeElement.scrollTo({
    //   left:
    //     this.widgetsContent.nativeElement.scrollLeft +
    //     middleOffset.offsetLeft / 2,
    //   behavior: 'smooth',
    // });
  }

  async ngAfterViewInit() {
    this.areaService.cBeem$.subscribe((val) => {
      this.resuLif = [];

      setTimeout(() => {
        this.labels.forEach((label) => {
          let obj = {
            d: label.nativeElement.innerHTML.trim().substring(0, 7),
            w: label.nativeElement.offsetWidth,
            l: Number(label.nativeElement.offsetLeft),
          };
          this.resuLif.push(obj);
        });

        this.createGrafic(this.taskmaster);
      }, 5000);
    });

    setTimeout(() => {
      this.labels.forEach((label) => {
        let obj = {
          // d: label.nativeElement.innerHTML.trim(),
          d: label.nativeElement.innerHTML.trim().substring(0, 7),
          w: label.nativeElement.offsetWidth,
          l: Number(label.nativeElement.offsetLeft),
        };
        this.resuLif.push(obj);
      });

      this.createGrafic(this.taskmaster);
    }, 5000);

    setTimeout(() => {
      this.scroll();
    }, 1000);
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
  }

  isEndWeek(a) {
    let obj; 
    if (moment(a).format('dddd') == 'Saturday') {
      obj = {
        val_id: true,
        val_de: 'Sab.',
        val_st: 'toDayEnd',
      };
    } else if (moment(a).format('dddd') == 'Sunday') {
     obj = {
        val_id: true,
        val_de: 'Dom.',
        val_st: 'toDayEnd',
      };
    }

    else  {
      obj = {
         val_id: false,
       };
     }

    return obj;
  }
}
