import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ChatService } from './../../../services/chat.service';
@Component({
  selector: 'app-sms-activador',
  templateUrl: './sms-activador.component.html',
  styleUrls: ['./sms-activador.component.css'],
})
export class SmsActivadorComponent implements OnInit {
  @Input() vRo: string;
  @Input() vIn: string;
  obsCharoom: any;
  constructor(
    private chatService: ChatService,
    private snackbarService: SnackbarService
  ) {}

  formActivate: FormGroup;
  ngOnInit(): void {
    this.createForm();
    this.getRoomId(this.vRo);
  }

  getRoomId(vRo) {
    this.chatService.getRoomId({ vRo: vRo }).subscribe((resp) => {


      
      this.obsCharoom = resp;
    });
  }

  createForm() {
    this.formActivate = new FormGroup({
      resumen: new FormControl(''),
      des: new FormControl(this.vIn || 'Sala de Obervacion', [
        Validators.required,
      ]),
      nombre: new FormControl(this.vIn || 'Room Chat Pro', [
        Validators.required,
      ]),
    });
  }

  add() {
    if (this.formActivate.valid) {
      const resumen = this.formActivate.controls.resumen.value;
      const des = this.formActivate.controls.des.value;
      const nombre = this.formActivate.controls.nombre.value;
      let obj = {
        _id_room: this.vRo,
        nombre: nombre,
        des: des,
        notiemail: resumen || false,
      };

      this.chatService.activateRoom(obj).subscribe(
        (resp) => {

          this.snackbarService.msj(resp.status, 'ok');
        },

        (error) => {
          console.log(error);

          this.snackbarService.msj(error.error.status, 'alert');
        }
      );
    } else {
      this.snackbarService.msj('Verifica los campos requerido', 'alert');
    }
  }
}
