<div class="conte-pro-m" *ngIf="ass">
    <div class="titulo-pro">
        TRASLADO DE ASIGNACIONES Y VENTA
    </div>
    <mat-divider></mat-divider>
    <div class="container">
        <div class="item-a">
            <app-ejecutivo-id [idejecutivo]="idvendedor" [arraydata]="arraydata"></app-ejecutivo-id>
        </div>
        <div class="item-b">
            <div class="titulo-row-b-m">
                Seleccionar Nuevo Ejecutivo
            </div>
            <mat-divider></mat-divider>
            <div class="fomr">
                <mat-grid-list cols="10" rowHeight="60px">
                    <mat-grid-tile [colspan]="6">
                        <mat-form-field class="mata">
                            <input type="text" placeholder="Ejemplo: Pedro..." matInput [formControl]="ejecutivoF"
                                [matAutocomplete]="autoEje" />
                            <mat-autocomplete class="divia" autoActiveFirstOption #autoEje="matAutocomplete">
                                <mat-option *ngFor="let optionEjecutivo of filteredOptionsEjecutivo | async"
                                    [value]="optionEjecutivo.nombre" (click)="
                setEjecutivoAsing(
                  optionEjecutivo._id,
                  optionEjecutivo.nombre,
                  optionEjecutivo.apellido
                )
              ">
                                    <div class="listadoProducto">
                                        {{ optionEjecutivo.cod_epicor }} -
                                        {{ optionEjecutivo.nombre }} {{ optionEjecutivo.apellido }}
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2">
                        <mat-form-field class="mfs">
                            <mat-label>Mes:</mat-label>
                            <mat-select [formControl]="selecm" name="selecm" (selectionChange)="updateAns($event)">
                                <mat-option [value]="1">ENERO</mat-option>
                                <mat-option [value]="2">FEBRERO</mat-option>
                                <mat-option [value]="3">MARZO</mat-option>
                                <mat-option [value]="4">ABRIL</mat-option>
                                <mat-option [value]="5">MAYO</mat-option>
                                <mat-option [value]="6">JUNIO</mat-option>
                                <mat-option [value]="7">JULIO</mat-option>
                                <mat-option [value]="8">AGOSTO</mat-option>
                                <mat-option [value]="9">SEPTIEMBRE</mat-option>
                                <mat-option [value]="10">OCTUBRE</mat-option>
                                <mat-option [value]="11">NOVIEMBRE</mat-option>
                                <mat-option [value]="12">DICIEMBRE</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2">
                        <mat-form-field class="mfsa">
                            <mat-label>Año:</mat-label>
                            <mat-select [formControl]="seleca" name="selecm" (selectionChange)="updateAns($event)">
                                <mat-option [value]="2022">2022</mat-option>
                                <mat-option [value]="2021">2021</mat-option>
                                <mat-option [value]="2020">2020</mat-option>
                                <mat-option [value]="2019">2019</mat-option>
                                <mat-option [value]="2018">2018</mat-option>
                                <mat-option [value]="2017">2017</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            <button class="bnb" color="primary" mat-raised-button mat-button (click)="addtrasla()">
                <mat-icon>published_with_changes</mat-icon>Aplicar
            </button>
        </div>
    </div>
</div>
<div *ngIf="bss" class="contendor-secudario">
    <div class="titulo-pro">
        RESULTADO TRASLADO
    </div>
    <mat-divider></mat-divider>

    <div class="marco">
        <div class="contenedor-lista">
            <div class="table-container">
                <mat-grid-list cols="10" rowHeight="55px" *ngFor="let data of reslist">
                    <mat-grid-tile [colspan]="2">
                        <app-ima-cliente [idcliente]="data.id_epicor"></app-ima-cliente>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6">
                        <div class="info-razon">
                            {{ data.razon_social }}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile matTooltip="{{data.descripcion}}" [colspan]="1">
                        <mat-icon [class]="data.value">{{getAler(data.value)}}</mat-icon>
                    </mat-grid-tile>
                    <mat-grid-tile matTooltip="{{data.ventas}} Registros Actualizados" [colspan]="1">
                        <mat-icon [class]="data.ventasClass">monetization_on</mat-icon>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
        <div class="container-info">
            <div class="itt">
                <div *ngIf="!info_a; else noda1">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                    width: '70px',
                    height: '70px',
                    'background-color': '#daddd8'
                  }">
                    </ngx-skeleton-loader>
                </div>
                <ng-template #noda1>

                    <div *ngIf="info_aa; else noda1a">
                        <app-success-data [pd]="" [mensaje]="'Asignando el nuevo ejecutivo'"></app-success-data>
                    </div>
                    <ng-template #noda1a>
                        <app-war-data [pd]="numA+' de '+ numb" [mensaje]="'Asignaciones ejecutadas'"></app-war-data>

                    </ng-template>

                </ng-template>
            </div>
            <div class="itt">
                <div *ngIf="!info_b; else noda2">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                    width: '70px',
                    height: '70px',
                    'background-color': '#daddd8'
                  }">
                    </ngx-skeleton-loader>
                </div>
                <ng-template #noda2>
                    <!-- <app-success-data [pd]="" [mensaje]="'Revisión histoŕicos de venta'"></app-success-data> -->
               
               
               
                    <div *ngIf="info_ab; else noda1b">
                        <app-success-data [pd]="" [mensaje]="'Asignando el nuevo ejecutivo'"></app-success-data>
                    </div>
                    <ng-template #noda1b>
                        <app-war-data [pd]="numA+' de '+ numb" [mensaje]="'Revisión ejecutadas'"></app-war-data>

                    </ng-template>
               
               
               
               
               
                </ng-template>
            </div>

            <div class="itt">
                <div *ngIf="!info_c; else noda3">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                    width: '70px',
                    height: '70px',
                    'background-color': '#daddd8'
                  }">
                    </ngx-skeleton-loader>
                </div>
                <ng-template #noda3>
                    <div *ngIf="!info_ca; else noda4">
                        <app-info-data [pd]="" [mensaje]="'No se encotrarón ventas'"></app-info-data>
                    </div>
                    <ng-template #noda4>
                        <app-success-data [pd]="" [mensaje]="'Se actualizó registros de ventas'"></app-success-data>
                    </ng-template>

                </ng-template>
                <!-- <div *ngIf="info_c; else noda2">
                    <ngx-skeleton-loader appearance="circle" [theme]="{
                    width: '70px',
                    height: '70px',
                    'background-color': '#daddd8'
                  }">
                    </ngx-skeleton-loader>
                </div>
                <ng-template #noda2>
                    <app-info-data [pd]="" [mensaje]="'No se encotraron registros de ventas'"></app-info-data>
                </ng-template> -->
            </div>
        </div>
    </div>
    <div class="mar">
        <button class="bnc" [disabled]="!info_c" color="primary" mat-raised-button mat-button (click)="onClose()">
            <mat-icon>close</mat-icon>Cerrar
        </button>
    </div>
</div>