<div *ngIf="loading;else nodata">
    <mat-tab-group class="tabs-master-cli">
        <mat-tab label="Incremento Ventas">
            <div class="ct-content">
                <div class="ct-for" *ngFor="let env of dataSemYear | async; index as i">
                    <div *ngIf="i>0" class="mtdd">
                         <mat-divider ></mat-divider>
                    </div>
                    <div class="ct-containerVen {{env.status}}">
                        <div class="porcv">
                            <app-per-cli-ind *ngIf="getVen(env.vVa.tVenSem)" [va]="getVen(env.vVa.tVenSem)"
                                [vb]="getVen(env.vVb.tVenSem)">
                            </app-per-cli-ind>
                        </div>
                        <div class="syearva">
                            <span>VEN {{env.vVa.year}} </span>
                        </div>
                        <div class="syearvb">
                            <span>VEN {{env.vVb.year}} </span>
                        </div>
                        <div class="venev">
                            {{getVen(env.vVa.tVenSem) | number: '1.2-2' }} <span class="item-moneda">MXN</span>
                        </div>
                        <div class="presv">
                            {{getVen(env.vVb.tVenSem) | number: '1.2-2' }} <span class="item-moneda">MXN</span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Cumplimiento Histórico">
            <div class="ct-content">
                <div *ngFor="let env of dataSemYear | async ; index as i">
                    <div *ngIf="i>0" class="mtdd">
                        <mat-divider ></mat-divider>
                   </div>
                    <div class="ct-totals {{env.status}}">

                        <div class="porc">
                            <span class="mat-card-pre-title v{{env.year}}">{{env.year}}</span>
                        </div>
                        <div class="vene">
                            <span class="item-ind">VEN</span> {{getVen(env.vVa.tVenSem) | number: '1.2-2' }} <span
                                class="item-moneda">MXN</span>
                        </div>
                        <div class="pres">
                            <span class="item-ind">PRE</span> {{getPre(env.vVa.tPreSem) | number: '1.2-2' }} <span
                                class="item-moneda">MXN</span>
                        </div>
                        <div class="iconx">
                            <app-pcrven *ngIf="getVen(env.vVa.tVenSem)" [sf]="'sf'" [vYear]="env.year"
                                [tVen]="getVen(env.vVa.tVenSem)" [vpre]="getPre(env.vVa.tPreSem)">
                            </app-pcrven>
                        </div>
                      
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #nodata>

    <div class="tabss">
        <ngx-skeleton-loader count="1" [theme]="{ 
        'border-top-left-radius': '13px', 
        'border-top-right-radius': '0px', 
        'border-bottom-right-radius': '0px', 
        'width': '140px',
        'padding':'2px',
        'margin-left':'5px',
        'height': '18px',
        'background-color': '#948cd5'
    }">

        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ 
        'border-top-left-radius': '0px', 
        'border-top-right-radius': '13px', 
        'border-bottom-right-radius': '0px', 
        'width': '140px',
        'padding':'2px',
        'margin-left':'0px',
        'height': '18px',
        'background-color': '#948cd5'
    }">

        </ngx-skeleton-loader>

    </div>

    <div class="nodata" *ngFor="let env of arrayWait">
        <mat-divider></mat-divider>
        <div class="ct-model">
            <div class="item">
                <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '55px',
            'padding':'5px',
            'margin':'5px',
            'height': '34px',
            'background-color': '#daddd8'
        }">
                </ngx-skeleton-loader>
            </div>
            <div class="item">
                <ngx-skeleton-loader count="2" [theme]="{ 
            'border-radius': '5px', 
            'width': '80px',
            'padding':'1px',
            'margin':'1px',
            'height': '14px',
            'background-color': '#daddd8'
        }">
                </ngx-skeleton-loader>
            </div>
            <div class="item">
                <ngx-skeleton-loader count="2" [theme]="{ 
            'border-radius': '5px', 
            'width': '210px',
            'padding':'1px',
            'margin':'1px',
            'height': '14px',
            'background-color': '#daddd8'
        }">
                </ngx-skeleton-loader>
            </div>
        </div>

    </div>
</ng-template>