import { Component, OnInit } from '@angular/core';
import { GraficasService } from "../../../services/graficas.service";
import { ChannelService } from "./../../../services/channel.service";
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'app-grafica-top10-productos',
  templateUrl: './grafica-top10-productos.component.html',
  styleUrls: ['./grafica-top10-productos.component.css']
})
export class GraficaTop10ProductosComponent implements OnInit {
g1: boolean = true;
g2: boolean = false;
g3: boolean = false;
g4: boolean = false;
obs = new BehaviorSubject<any>(null);
dataResulta: any[];
dataResultb: any[];
dataResultc: any[];
dataResultd: any[];
transactions = [];
transactionsb = [];
transactionsc = [];
transactionsd = [];
vYear;
vMes:String;
it=1;
vCanal="Industrial";
vCliente:String;
vCri="10";
vCriterio=String;
vMed:String;
vProducto:String;
vName: String;
loading: boolean = true;
loadingb: boolean = false;
loadingc: boolean = false;
loadingd: boolean = false;
displayedColumns: string[] = ['num','producto','value'];
displayedColumnsb: string[] = ['mes','value'];
displayedColumnsc: string[] = ['mes','value','unidad'];
displayedColumnsd: string[] = ['cliente','volumen','unidad','tot'];

//Grafica A
aview: any[] = [700, 380];
ashowXAxis = true;
ashowYAxis = true;
agradient = false;
ashowLegend = false;
ashowXAxisLabel = false;
axAxisLabel = '';
alegendTitle='Prodcutos';
ashowYAxisLabel = true;
ayAxisLabel = 'MXN';
acolorScheme = {
domain: ['#422b69', '#83c5be', '#fb8500', '#1a73d9','#4cbd84', '#ffc80d', '#0e7c57', '#e83f6f','#4d3fe8', '#ef2917', '#1b1b3c', '#d35985']
};

//Grafica B
bview: any[] = [700, 380];
bshowXAxis = true;
bshowYAxis = true;
bgradient = false;
bshowLegend = false;
bshowXAxisLabel = false;
bxAxisLabel = '';
blegendTitle='Prodcutos';
bshowYAxisLabel = true;
byAxisLabel = 'MXN';
bcolorScheme = {
domain: ['#422b69', '#83c5be', '#fb8500', '#1a73d9','#4cbd84', '#ffc80d', '#0e7c57', '#e83f6f','#4d3fe8', '#ef2917', '#1b1b3c', '#d35985']
};


//Grafica C
cview: any[] = [650, 380];
cshowXAxis = true;
cshowYAxis = true;
cgradient = true;
cshowLegend = false;
cshowXAxisLabel = false;
cxAxisLabel = '';
clegendTitle='Prodcutos';
cshowYAxisLabel = true;
cyAxisLabel = 'MXN';
ccolorScheme = {
domain: ['#422b69', '#83c5be', '#fb8500', '#1a73d9','#4cbd84', '#ffc80d', '#0e7c57', '#e83f6f','#4d3fe8', '#ef2917', '#1b1b3c', '#d35985']
};

// GRAFICA D
dview: any[] = [800, 450];
dgradient: boolean = true;
dshowLegend: boolean = false;
dshowLabels: boolean = true;
disDoughnut: boolean = false;
dlegendPosition: string = 'below';
dcolorScheme = {
  domain: ['#422b69', '#83c5be', '#fb8500', '#1a73d9','#d35985']
};
  breakpoint: number;
  colspa: number;
  colspb: number;
  listRango:any=[];

  constructor(public graficaService: GraficasService,private channelService:ChannelService) {
    this.vYear=this.getYeara();
   }

  ngOnInit() {
    this.getAllCanal();
    this.setList();
    this.renderWie();
    this.getGraficaVentaTop10Producto(this.vYear,this.vCanal,this.vCri);
    this.refreshga();
    this.refreshgb();
    this.refreshgc();
    this.refreshgd();
  }


  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obs.next(resp);

    });
  }


  refreshga(){
  this.graficaService.getGraficaVentaTop10Producto$.subscribe(() => {
  this.getGraficaVentaTop10Producto(this.vYear,this.vCanal,this.vCri);
  });
  }

  refreshgb(){
  this.graficaService.getGraficaVentaTop10ProMes$.subscribe(() => {
  this.getGraficaVentaTop10ProMes(this.vYear,this.vProducto);
  });
  }

  refreshgc(){
  this.graficaService.getGraficaVentaTop10ProMesVol$.subscribe(() => {
  this.getGraficaVentaTop10ProMesVol(this.vYear,this.vProducto);
  });
  }

  refreshgd(){
  this.graficaService.getGraficaVentaTop10ProMesVolCli$.subscribe(() => {
  this.getGraficaVentaTop10ProMesVolCli(this.vProducto,this.vYear,this.vMes);
  });
  }


  getGraficaVentaTop10Producto(vYear,vCanal,vCri){
  this.graficaService.getGraficaVentaTop10Producto({vYear:vYear,vCanal:vCanal,vCri:vCri}).subscribe(  resp => {
  this.dataResulta = resp;
  this.transactions = resp;
  this.loading=false;
  });
  }

  getGraficaVentaTop10ProMes(vYear,vProducto){
  this.graficaService.getGraficaVentaTop10ProMes({vYear:vYear,vProducto:vProducto}).subscribe(  resp => {
  this.dataResultb = resp;
  this.transactionsb = this.dataResultb;
  this.loadingb=false;
  });
  }



  getGraficaVentaTop10ProMesVol(vYear,vProducto){
  this.graficaService.getGraficaVentaTop10ProMesVol({vYear:vYear,vProducto:vProducto}).subscribe(  resp => {
  this.dataResultc = resp;
  this.transactionsc = this.dataResultc;
  this.loadingc=false;
  });
  }


  getGraficaVentaTop10ProMesVolCli(vProducto,vYear,vMes){
  this.graficaService.getGraficaVentaTop10ProMesVolCli({vProducto:vProducto,vYear:vYear,vMes:vMes}).subscribe(  resp => {
  this.dataResultd = resp;
  this.transactionsd = this.dataResultd;
  this.loadingd=false;
  });
  }


  getTotalga() {
  return this.transactions.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }

  getTotalgbe() {
  return this.transactionsb.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }

  getTotalgc() {
  return this.transactionsc.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }

  getTotalgd() {
  return this.transactionsd.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }


  updateA(event) {
  this.vYear = event;
  this.vCanal = this.vCanal;
  this.vCri = this.vCri;
  this.graficaService.getGraficaVentaTop10Producto$.emit();
  }

  updateB(event) {
   const wm = event.toLowerCase();
   const wcanal = wm.charAt(0).toUpperCase() + wm.slice(1);
  this.vCanal = wcanal;
  this.vYear = this.vYear;
  this.vCri = this.vCri;
  this.graficaService.getGraficaVentaTop10Producto$.emit();
  }

  updateS(event) {
  this.vCanal = this.vCanal
  this.vYear = this.vYear;
  this.vCri = event;
  this.graficaService.getGraficaVentaTop10Producto$.emit();
  }

  updateC(event) {
  this.g1=false;
  this.g2=false;
  this.g3=true;
  this.graficaService.getGraficaVentaTop10ProMesVol$.emit();
  }

  updateD(event) {
  this.g2=true;
  this.g3=false;
  this.graficaService.getGraficaVentaTop10ProMes$.emit();
  }


  onSelectTopA(event){
  this.loadingb=true;  
  this.vMed="total";
  this.vName=event.extra.codeName;
  this.vProducto=event.extra.code;
  this.graficaService.getGraficaVentaTop10ProMes$.emit();
  this.g1=false;
  this.g2=true;

  }

  onSelectTopB(event){

  }

  onSelectTopC(event){

  }

  onSelectTopD(event){
    this.loadingc=true;
    this.vMes=event.name;
    this.g1=false;
    this.g2=false;
    this.g3=false;
    this.g4=true;
this.graficaService.getGraficaVentaTop10ProMesVolCli$.emit();
  }


  resetDivGlobal(){
  this.g1=true;
  this.g2=false;
  this.g3=false;
  this.g4=false;
  this.vYear=this.getYeara();
  this.vCri="10";
  this.vCanal="Industrial";
  this.vMed="total";
  this.graficaService.getGraficaVentaTop10Producto$.emit();
  }

  getYeara() {
    var date = new Date();
    return moment(date).format('YYYY');
  }

  renderWie(){
    this.breakpoint = (window.innerWidth <= 450) ? 1:7;
    this.colspa = (window.innerWidth <= 450) ? 1:4;
    this.colspb = (window.innerWidth <= 450) ? 1:3;
   
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 450) ? 1 : 7;
    this.colspa = (event.target.innerWidth <= 450) ? 1:4;
    this.colspb = (event.target.innerWidth <= 450) ? 1:3;
    

  }

  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }



}
