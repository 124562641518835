import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scg-indicador',
  templateUrl: './scg-indicador.component.html',
  styleUrls: ['./scg-indicador.component.css']
})
export class ScgIndicadorComponent implements OnInit {
  @Input() vVen:Number;
  @Input() vPre:Number;
  @Input() vSm:String;
  cals: string;
  icc: string;
  value: any;
  ics: string;

  constructor() { }

  ngOnInit(): void {
    this.getValuePro(this.vVen, this.vPre);
  }

  validateValue(value) {
    this.vVen = value;
    if (value <= 10) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";
    } else if (value > 10 && value < 50) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";

    } else if (value > 50) {
      this.cals = "gree";
      this.icc = "schedule";
      this.ics = "blink_me";
    }
  }

  getValuePro(vVen,vPre) {

 

    if (vPre === undefined || vPre === null) {
      this.value = 0;
    }
    else if (vVen===0 && vPre === 0) {
      this.value = 0;
    }
    else {
      this.value = (vVen / vPre) * 100;
    }
    this.validateValue(this.value);


  }

}