import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "./../../../../services/sales/sales.service";

@Component({
  selector: 'app-list-search-cli',
  templateUrl: './list-search-cli.component.html',
  styleUrls: ['./list-search-cli.component.css']
})
export class ListSearchCliComponent implements OnInit {
  @Input() vPro: string;
  @Input() vD: string;
  @Input() vH: string;
  @Input() vCli: string;
  
  dataPro = new BehaviorSubject<any>(null);
  panelOpenState = false;
  constructor(private salesService: SalesService) { }

  ngOnInit(): void {

    this.getProductoCliAgroup(this.vPro, this.vD, this.vH, this.vCli);
  }


  getProductoCliAgroup(vPro, vD, vH, vCli) {
    this.salesService.getProductoCliAgroup({ vPro: vPro, vD: vD, vH: vH, vCli:vCli }).subscribe(
      resp => {
        
        this.dataPro.next(resp);
      });
  }

  getColorl(i) {

    const val = i % 2;
    if (val === 0) {
      return "divRowb";
    } else {
      return "divRoww";
    }

  }

}
