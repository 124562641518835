import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VenSemAdminService } from "../../../../services/sales/ven-sem-admin.service";

@Component({
  selector: 'app-panel-venta-productos',
  templateUrl: './panel-venta-productos.component.html',
  styleUrls: ['./panel-venta-productos.component.css']
})
export class PanelVentaProductosComponent implements OnInit {
  @Input() idepicor: String;
  @Input() idd: String;
  @Input() vYear: Number;
  @Input() vD: Date;
  @Input() vH: Date;
  dataPro = new BehaviorSubject<any>(null);
  loading = false;
  dataUd = false;
  panelOpenState = false;
  public searchText: any;

  celusd: boolean;
  icoMas: string;
  msnMas: string;
  constructor(
    private venSemAdminService: VenSemAdminService
  ) { }

  ngOnInit(): void {
    this.getGraVenSemPro(this.idepicor, this.vYear, this.vD, this.vH);
  }


  getGraVenSemPro(idepicor, vYear, vD, vH) {
    this.venSemAdminService.getGraVenSemPro({
      _id_cliente: idepicor,
      desde: vD,
      hasta: vH,
      yeara: vYear
    }).subscribe((resp) => {


      var total = resp.map(d => d.venta_uds).reduce((sum, d) => sum + d, 0);
      if (total > 0) {
        this.celusd = true;
      }
      this.dataPro.next(resp);
      this.loading = true;
    });
  }

  getColorl(i) {


    const val = i % 2;
    if (val === 0) {
      return "divRowb";
    } else {
      return "divRoww";
    }
  }

  boxGet(id) {



    const numValor = id.substring(3, 6);
  
    if (numValor==="593") {
      this.icoMas="shopping_bag";
      this.msnMas="BOLSA EMPAQUE";
      return true;
    } else if (numValor==="503") { 
      this.icoMas="archive";
      this.msnMas="CAJA MASTER";
      return true;
    }else{
      this.icoMas="ac_unit";
      this.msnMas="VOL";
    }

  }


}
