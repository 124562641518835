export const GlobalVariable = Object.freeze({

    // BASE_API_URL: 'http://localhost:3000/api/',
    // BASE_API_URL_SOCKET: 'http://localhost:3300/',
    // BASE_API_URL_WHATSAPP: 'http://localhost:3400/',

    // BASE_API_URL: 'http://192.168.1.66:3000/api/',
    // BASE_API_URL_SOCKET: 'http://192.168.1.66:3300',

    // BASE_API_URL: 'https://approfusa.herokuapp.com/api/',
    // BASE_API_URL_SOCKET: 'https://approsocket.herokuapp.com/',
    // BASE_API_URL_WHATSAPP:'https://approwhats.herokuapp.com/api/',

    BASE_API_URL: 'https://profusa.appro.cloud/api/',
    // BASE_API_URL_SOCKET: 'https://profusa.appro.cloud/',
    // BASE_API_URL_WHATSAPP:'https://profusa.appro.cloud/api/',

    


});

