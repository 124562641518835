import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerService } from "../../../../services/customer.service";
import { VenSemAdminService } from "../../../../services/sales/ven-sem-admin.service";
import * as moment from 'moment';

@Component({
  selector: 'app-per-cli-vdi',
  templateUrl: './per-cli-vdi.component.html',
  styleUrls: ['./per-cli-vdi.component.css']
})
export class PerCliVdiComponent implements OnInit {
  @Input() idepicor: String;
  @Input() vYear: Number;
  @Input() vMes: Number;
  @Input() vD: Date;
  @Input() vH: Date;
  @Input() vSem: Date;
  datacliNombre = new BehaviorSubject<any>(null);
  datacliId = new BehaviorSubject<any>(null);
  dataVenPre = new BehaviorSubject<any>(null);
  vVen= new BehaviorSubject<any>(null);
  vPre= new BehaviorSubject<any>(null);
  loading: boolean = false;

  constructor(
    private customerService: CustomerService,
    private venSemAdminService: VenSemAdminService
  ) { }

  ngOnInit(): void {
    this.getIdEpicorCli(this.idepicor);
    // this.getVenCliSemPreId(this.idepicor, this.vYear, this.vD, this.vH);
  }

  getIdEpicorCli(idepicor) {
    this.customerService.getIdEpicorCli({ id_epicor: idepicor }).subscribe((resp) => {
      this.datacliNombre.next(resp.razon_social);
      this.datacliId.next(resp.razon_social);
      this.loading=true;
    });
  }

  // getVenCliSemPreId(_id_cliente, yeara, d, h) {
  //   this.venSemAdminService.getVenCliSemPreId({ _id_cliente: _id_cliente, yeara: yeara, d: d, h: h }).subscribe((resp) => {
  //     this.vVen.next(resp.tVentaSem);
  //     this.vPre.next(resp.tPreSem);
  //   });
  // }
}
