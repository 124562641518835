import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AreaService } from 'src/app/services/area.service';

@Component({
  selector: 'app-task-mb-user',
  templateUrl: './task-mb-user.component.html',
  styleUrls: ['./task-mb-user.component.css'],
})
export class TaskMbUserComponent implements OnInit {
  miAr: any;
  formPurchaseOrder: FormGroup;

  @Input() vAr: string;

  constructor(private areaService: AreaService) {}

  ngOnInit(): void {
    this.getMiembrosGen();
    this.createForm();
  }

  ptakOff() {
    this.areaService.cBeem$.emit(false);
  }

  createForm() {
    this.formPurchaseOrder = new FormGroup({
      responsable: new FormControl('', [Validators.required]),
    });
  }

  getMiembrosGen() {
    this.areaService.getMiembrosGen().subscribe((resp) => {
      this.miAr = resp;
    });
  }

  add() {
    if (this.formPurchaseOrder.valid) {
      const responsable = this.formPurchaseOrder.controls.responsable.value;

      let newObj = {
        _id:this.vAr,
        miembros: responsable
      };


      this.areaService.getMiembrosAddUni(newObj).subscribe((resp) => {
        this.areaService.cBeem$.emit(false);
        this.areaService.oTask$.emit();
      });
    } else {
    }
  }
}
