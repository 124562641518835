
    <app-panel-admin-envios></app-panel-admin-envios>

<!-- <div class="conte-pro">
    <div class="titulo-row-b">
        <button mat-icon-button [routerLink]="['/dashboard']">
            <mat-icon>navigate_before</mat-icon>
        </button>
        Envios Activos
    </div>
    <mat-divider></mat-divider>
 -->
















    <!-- <mat-grid-list cols="10" rowHeight="100px">
        <mat-grid-tile colspan="5">
            <mat-form-field class="mat-form-field">
                <mat-label>Filtro</mat-label>
                <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchText"  placeholder="Ejemplo: Target" autocomplete="off">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
            <div>
            <mat-form-field class="mfs" >
              <mat-label>Envios:</mat-label>
              <mat-select [formControl]="selecm" name="selecm" (selectionChange)="updateAns($event)">
                <mat-option [value]="2">TODOS</mat-option>
                <mat-option [value]="1">ACTIVOS</mat-option>
              
           
              </mat-select>
            </mat-form-field>
          </div>
          </mat-grid-tile>

        <mat-grid-tile colspan="2">
            <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button
                [routerLink]="['/nuevo_envio_multiple']">
                <mat-icon matListIcon>add</mat-icon> Registrar Nuevo
            </button>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
            <button mat-raised-button color="accent" class="ipdf" [disabled]="pdfb">
                <mat-icon>picture_as_pdf</mat-icon>PDF
              </button>
        </mat-grid-tile>
    </mat-grid-list>


    <div class="wrapper" *ngIf="loading;else content">
        <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
      </div>

      <ng-template #content>
    <div *ngIf="(obs| async)?.length > 0; else nodata">
        <div class="mat-card conter-ficha" *ngFor="let env of obs | async | filter: searchText">
            <div class="dse">
                <button mat-button matTooltip="Fecha de Registro: {{formatDateFull(env.envios.createdAt)}}">
                    <span class="icom">{{env.envios.ci}}</span>
                    <mat-icon class="icom" matListIcon>local_shipping</mat-icon>
                </button>
            </div>
            <mat-grid-list cols="20" rowHeight="100px">
                <mat-grid-tile colspan="2">

                    <app-ima-cliente  [idcliente]="env.cli.id_epicor"  ></app-ima-cliente>  

                  
                </mat-grid-tile>
                <mat-grid-tile colspan="6">
                    <div class="text-inside-grid">
                        <table class="table-data">
                            <tr>
                                <td> <span class="sut">{{env.envios.op}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="titulo-cli">{{formatRazonSocial(env.cli.razon)}}
                                </td>
                            </tr>
                            <tr>
                                <td><span class="sut">{{env.envios.destino.toUpperCase() }}</span></td>
                            </tr>
                        </table>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile class="cone" colspan="11">
                    <div class="cone">
                        <app-table-estados-envios ci="{{env.envios.ci}}" idcliente="{{env.cli._id}}" op="{{env.envios.op}}"
                            idenv="{{env.envios._id}}" dateRepro="{{env.envios.fecha_rep}}"
                            datellegada="{{env.envios.fecha_llegada}}">
                        </app-table-estados-envios>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button matTooltip="¿Seguro que deseas borrar este proyecto?"
                            matTooltipClass="example-tooltip-red" mat-menu-item
                            (click)="onDelAsig(env.envios._id, env.envios.op, env.envios.ci,env.cli._id);">
                            <mat-icon>delete_forever</mat-icon>
                            <span>Eliminar</span>
                        </button>
                    </mat-menu>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="fondo">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>unfold_more</mat-icon> Detalles
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="table-data-detalle">
                        <tr class="mat-header-row">
                            <th class="mat-header-cell">SALIDA</th>
                            <th class="mat-header-cell">LLEGADA</th>
                            <th *ngIf="env.envios.repro==true" class="mat-header-cell"> >> REPROGRAMADA</th>
                            <th class="mat-header-cell">UBICACIÓN ACTUAL</th>
                            <th class="mat-header-cell">MAPA</th>
                            <th class="mat-header-cell">ESTADO</th>
                        </tr>
                        <tr class="mat-row">
                            <td class="mat-cell">{{formatDate(env.envios.fecha_salida)}} </td>
                            <td *ngIf="env.envios.repro==true" class="mat-cell">
                                <del> {{formatDate(env.envios.fecha_llegada)}} </del>
                            </td>
                            <td *ngIf="env.envios.repro==null" class="mat-cell">
                                {{formatDate(env.envios.fecha_llegada)}}
                            </td>
                            <td *ngIf="env.envios.repro==true" class="mat-cell">{{formatDate(env.envios.fecha_rep)}}
                            </td>
                            <td class="mat-cell">{{env.envios.lugar_actual}} </td>
                            <td class="mat-cell">
                                <a target="_blank" class="btn" color="primary" mat-button mat-raised-button
                                    href="https://maps.google.com/?q={{env.envios.lugar_actual}}">
                                    <mat-icon>location_on</mat-icon>
                                </a>
                            </td>
                            <td class="mat-cell">
                                <div class="row-pro-f {{env.envios.repro}}">{{env.envios.estado_general}}</div>
                            </td>
                        </tr>
                    </table>
                    <mat-grid-list cols="1" rowHeight="300px">
                        <mat-grid-tile class="mat-figure">
                            <div class="table-data">
                                <app-table-productos-envios ci="{{env.envios.ci}}" op="{{env.envios.op}}"
                                    idcliente="{{env.cli._id}}"></app-table-productos-envios>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <ng-template #nodata>
        <div class="nodata">
            <div class="g-card">
                <mat-card class="g-card">
                    <mat-card-title>
                        <mat-icon class="ico-m" matListIcon>report_problem</mat-icon>
                    </mat-card-title>
                    <mat-card-subtitle>
                        No hay envios registrados.
                    </mat-card-subtitle>
                </mat-card>
            </div>
        </div>
    </ng-template>

</ng-template> -->
    <!-- 
    <div class="mat-card conter-ficha" *ngFor="let env of envm.envios">
        <mat-grid-list cols="20" rowHeight="150px">
            <mat-grid-tile [colspan]="2">
                <img class="ima-cliente" [src]="env._id_cliente.ima_cliente || 'assets/ima/no-ima.png'">
            </mat-grid-tile>
            <mat-grid-tile [colspan]="6">
                <div class="text-inside-grid">
                    <table class="table-data">
                        <tr>
                            <td>{{env.op}}
                            </td>
                        </tr>
                        <tr>
                            <td class="titulo-cli">{{env._id_cliente.razon_social}}
                            </td>
                        </tr>
                        <tr>
                            <td>{{env.destino}}</td>
                        </tr>
                    </table>
                </div>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="12">
                <div class="table-data-c">
                    <app-table-estados-envios op="{{env.op}}" idenv="{{env._id}}" dateRepro="{{env.fecha_rep}}" datellegada="{{env.fecha_llegada}}"></app-table-estados-envios>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="fondo">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>unfold_more</mat-icon> More details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="table-data-detalle">
                    <tr class="mat-header-row">
                        <th class="mat-header-cell">DEPARTURE</th>
                        <th class="mat-header-cell">ARRIVAL</th>
                        <th *ngIf="env.repro==true" class="mat-header-cell">RESCHEDULED ARRIVAL</th>
                        <th class="mat-header-cell">CURRENT LOCATION</th>
                        <th class="mat-header-cell">MAP</th>
                        <th class="mat-header-cell">STATUS</th>
                    </tr>
                    <tr class="mat-row">
                        <td class="mat-cell">{{formatDate(env.fecha_salida)}} </td>
                        <td class="mat-cell">{{formatDate(env.fecha_llegada)}} </td>
                        <td *ngIf="env.repro==true" class="mat-cell">{{formatDate(env.fecha_rep)}} </td>
                        <td class="mat-cell">{{env.lugar_actual}} </td>
                        <td class="mat-cell">
                            <a target="_blank" class="btn" color="primary" mat-button mat-raised-button
                                href="https://maps.google.com/?q={{env.lugar_actual}}">
                                <mat-icon>location_on</mat-icon>
                            </a>
                        </td>
                        <td class="mat-cell">
                            <div class="row-pro-f {{env.repro}}">{{env.estado_general}}</div>
                        </td>
                    </tr>
                </table>
                <mat-grid-list cols="1" rowHeight="300px">
                    <mat-grid-tile class="mat-figure">
                        <div class="table-data">
                            <app-table-productos-envios op="{{env.op}}"></app-table-productos-envios>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-expansion-panel>
        </mat-accordion>
    </div> -->

