import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MargenService } from '../../../services/margen.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-margen-producto-detalle',
  templateUrl: './margen-producto-detalle.component.html',
  styleUrls: ['./margen-producto-detalle.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class MargenProductoDetalleComponent implements OnInit {
  @Input() idcli: number;
  @Input() idpro: number;
  @Input() yeara: number;
  @Input() mes: number;
  @ViewChild(MatPaginator) paginators: MatPaginator;
  @ViewChild(MatSort) sorts: MatSort;

  expandedElement: PeriodicElement | null;

  dataSource = new MatTableDataSource<any>();
  // displayedColumns: string[] = ['fecha', 'factura', 'volumen', 'unidad', 'pv', 'imv', 'mp', 'mo', 'gif',  'ic', 'ub', 'ca','cf', 'ct', 'un'];
  displayedColumns: string[] = [
    // 'fecha',
    // 'factura',
    // 'volumen',
'info',
    'pv',
    'imv',
    'mp',
    'mo',
    'gif',
    'ic',
    'ub',
    'UTC',
    'UTV',
    'ca',
    'ct',
    'un',
    'UTN'
  ];
  s3: boolean;
  constructor(private margenServices: MargenService) {}
  ngOnInit(): void {
    this.graficaMargenClienPPDetalle(
      this.idcli,
      this.idpro,
      this.yeara,
      this.mes
    );
  }

  graficaMargenClienPPDetalle(_id_cliente, _id_producto, yeara, mes) {
    this.margenServices
      .graficaMargenClienPPDetalle({
        _id_cliente: _id_cliente,
        _id_producto: _id_producto,
        yeara: yeara,
        mes: mes,
      })
      .subscribe((resp) => {
        this.dataSource.data = resp;
        this.dataSource.paginator = this.paginators;
        this.dataSource.sort = this.sorts;
        if (resp.length > 0) {
          this.s3 = true;
        } else {
          this.s3 = false;
        }
      });
  }

  getUTC(a, b) {
    return (b / a) * 100;
  }

  getObersv(valor) {
    var v;
    if (valor === null || valor === 'NULL') {
      v = 'S/O';
    } else {
      v = valor;
    }
    return v;
  }

  formatDate(fecha) {
    return fecha.substr(0, 10);
  }
  getTotala() {
    return this.dataSource.data
      .map((t) => t.cantidad)
      .reduce((acc, cantidad) => acc + cantidad, 0);
  }

  getTotalpv() {
    return this.dataSource.data
      .map((t) => t.precio_venta)
      .reduce((acc, precio_venta) => acc + precio_venta, 0);
  }

  getTotalimv() {
    return this.dataSource.data
      .map((t) => t.importe_venta)
      .reduce((acc, importe_venta) => acc + importe_venta, 0);
  }

  getTotalmp() {
    return this.dataSource.data
      .map((t) => t.materia_prima)
      .reduce((acc, materia_prima) => acc + materia_prima, 0);
  }

  getTotalgif() {
    return this.dataSource.data
      .map((t) => t.gif)
      .reduce((acc, gif) => acc + gif, 0);
  }

  getTotalcf() {
    return this.dataSource.data
      .map((t) => t.costo_flete)
      .reduce((acc, costo_flete) => acc + costo_flete, 0);
  }
  getTotalic() {
    return this.dataSource.data
      .map((t) => t.importe_costo)
      .reduce((acc, importe_costo) => acc + importe_costo, 0);
  }
  getTotalub() {
    return this.dataSource.data
      .map((t) => t.utilidad_bruta)
      .reduce((acc, utilidad_bruta) => acc + utilidad_bruta, 0);
  }
  getTotalct() {
    return this.dataSource.data
      .map((t) => t.costo_total)
      .reduce((acc, costo_total) => acc + costo_total, 0);
  }
  getTotalun() {
    return this.dataSource.data
      .map((t) => t.utilidad_neta)
      .reduce((acc, utilidad_neta) => acc + utilidad_neta, 0);
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
