import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { EnviosService } from "../../../services/envios.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};


@Component({
  selector: 'app-up-estados',
  templateUrl: './up-estados.component.html',
  styleUrls: ['./up-estados.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UpEstadosComponent implements OnInit {
  firstFormGroup: FormGroup;
  secundFormGroup: FormGroup;
  displayedColumns: string[] = ['fecha','ob', 'op'];
  dataSource = new MatTableDataSource<any>();
  table: boolean;
  local_data: any;
  gid: String;
  g_in: String;
  repro: boolean = true;
  gestado: String;
  newOb: { _id_estado: String; ob: any; op: any; ci: any; _id_cliente: any };
  gva: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  gop: any;
  ico: any;
  datellegada: any;
  dateRepro: any
  id_envio: any;
  g_id_cliente: any;
  gci: String;
  constructor(
    public dialogRef: MatDialogRef<UpEstadosComponent>,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private enviosService: EnviosService,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.local_data = this.data;
    this.gid = this.local_data.data._id;
    this.g_in = this.local_data.data._in;
    this.gestado = this.local_data.data.estado;
    this.gva = this.local_data.data.value;
    this.gop = this.local_data.data.op;
    this.ico = this.local_data.data.ico;
    this.gci = this.local_data.data.ci;
    this.g_id_cliente = this.local_data.data._id_cliente;
    this.datellegada = this.formatDate(this.local_data.data.datellegada);
    this.id_envio = this.local_data.data.idenv;

    this.dateRepro = this.formatDate(this.local_data.data.dateRepro);
    this.getAllEstadoID(this.gid);
  }

  ngOnInit(): void {
    this.creadFron();
    this.refresh();
  }

  refresh() {
    this.enviosService.envEstadolist$.subscribe(() => {
      this.getAllEstadoID(this.gid);
    });
  }

  creadFron() {
    this.firstFormGroup = this._formBuilder.group({
      ob: ['', Validators.required],
    });
    this.secundFormGroup = this._formBuilder.group({
      // date: [this.datellegada,disabled: true, Validators.required]
      date: [{ value: this.dateRepro, disabled: true }, Validators.required],
      adate: [{ value: this.datellegada, disabled: true }, Validators.required]
    });
  }

  add() {

    if (this.firstFormGroup.valid) {
      const ob = this.firstFormGroup.get('ob').value;
      const op = this.gop;
      const ci = this.gci;
      const _id_cliente = this.g_id_cliente;
      this.newOb = {
        _id_estado: this.gid,
        ob: ob,
        op: op,
        ci: ci,
        _id_cliente: _id_cliente
      }

      this.enviosService.regEstadoHist(this.newOb)
        .subscribe(
          res => {
            this.enviosService.envSer$.emit();
            this.openSnackBar(res.status, 'ok');
            this.enviosService.envEstadolist$.emit();
            this.enviosService.envlcg$.emit();

            this.firstFormGroup.reset();
          });
    }
  }

  getAllEstadoID(_id) {
    this.enviosService.getAllEstadoID({ _id: _id }).subscribe(
      resp => {

        this.dataSource.data = resp.segObs;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        let num = resp.segObs.length;
        if (num === 0) {
          this.table = false;
        } else {
          this.table = true;
        }
      });
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }


  changeEstado(event, _id) {
    this.enviosService.updateEstadoUp({ _id: _id, value: event.checked })
      .subscribe(
        res => {
          this.openSnackBar(res.status, 'ok');
          this.enviosService.envEstado$.emit();
        },
        err => {
          this.openSnackBar(err.error.error, 'alert');
        });


    if (this.ico === "done" && event.checked === true) {

      this.enviosService.updateSeguimiento({ op: this.gop, ci: this.gci, entregado: true, estado_general: "ENTREGADO" })
        .subscribe(res => {
          this.enviosService.envEstado$.emit();
          this.enviosService.envlcg$.emit();
          this.regHisto("ENTREGADO", this.gop, this.gci, this.g_id_cliente);
        });
    } else if (this.dateRepro) {

      this.enviosService.updateSeguimiento({ op: this.gop, ci: this.gci, entregado: false, estado_general: "REPROGRAMADO" })
        .subscribe(res => {
          this.enviosService.envEstado$.emit();
          this.enviosService.envlcg$.emit();
          this.regHisto("HA OCURRIDO UNA REPROGRAMACION", this.gop, this.gci, this.g_id_cliente);
        })
    } else {
      this.enviosService.updateSeguimiento({ op: this.gop, ci: this.gci, entregado: false, estado_general: "A TIEMPO" })
        .subscribe(res => {
          this.enviosService.envEstado$.emit();
          this.enviosService.envlcg$.emit();


        })
    }



    if (this.ico === "fact_check" && event.checked === true) {
      this.regHisto("EL PROCESO DE CARGA FINALIZÓ", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "local_shipping" && event.checked === true) {
      this.regHisto("EL CAMIÓN ESTA EN CAMINO", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "directions_boat_filled" && event.checked === true) {
      this.regHisto("EN PROCESO ADUANAL", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "downloading" && event.checked === true) {
      this.regHisto("DESCARGANDO PRODUCTOS EN DESTINO", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "done" && event.checked === true) {
      this.regHisto("PRODUCTOS ENTREGADOS EN DESTINO", this.gop, this.gci, this.g_id_cliente);
    }


    if (this.ico === "fact_check" && event.checked === false) {
      this.regHisto("EL PROCESO DE CARGA ESTA AÚN PENDIENTE", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "local_shipping" && event.checked === false) {
      this.regHisto("SALIDA DEL ENVIO AÚN ESTA PENDIENTE", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "directions_boat_filled" && event.checked === false) {
      this.regHisto("EL PROCESO EN ADUANA AÚN ESTA PENDIENTE", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "downloading" && event.checked === false) {
      this.regHisto("EL PROCESO DE DESCARGA AÚN ESTA PENDIENTE", this.gop, this.gci, this.g_id_cliente);
    }
    else if (this.ico === "done" && event.checked === false) {
      this.regHisto("EL PROCESO DE ENTREGA AÚN ESTA PENDIENTE", this.gop, this.gci, this.g_id_cliente);
    }


    this.onClose();

  }

  changeHola(_id, text) {
    this.enviosService.updateEstadoUp({ _id: _id, value: text })
      .subscribe(
        res => {
          this.openSnackBar(res.status, 'ok');
          this.enviosService.envEstado$.emit();
        },
        err => {

          this.openSnackBar(err.status, 'alert');
        });
  }

  changeRepro(event, _id) {
    if (event.checked) {
      this.secundFormGroup.controls.date.enable();
    } else {
      this.secundFormGroup.controls.date.disable();
    }
  }




  updateEstadoUpRe(event, id) {

    var fa = moment(event.value).format("YYYY-MM-DD");
    var fb = moment(this.datellegada).format("YYYY-MM-DD");


    if (fa < fb) {


    } else {

      this.enviosService.regEnvioMulta({ op: this.gop, ci: this.id_envio, _id_cliente: this.g_id_cliente }).subscribe();

    }

    let obj = { fecha_rep: event.value, repro: true, _id: id, estado_general: "REPROGRAMADO" };
    this.enviosService.updateEstadoUpRe(obj)
      .subscribe(
        res => {
          this.openSnackBar(res.status, 'ok');
          this.enviosService.envEstado$.emit();
          this.enviosService.envlcg$.emit();
          this.changeHola(this.gid, "sr");
          this.regHisto("HA OCURRIDO UNA REPROGRAMACION", this.gop, this.gci, this.g_id_cliente);   
        },
        err => {
          this.openSnackBar(err.error, 'alert');
        });
  }



  // onDelAsig(id){}

  formatDate(fecha) {
    return fecha.substr(0,10);
  }


  onDelAsig(_id) {

    this.enviosService.delEveHist({ _id: _id })
      .subscribe(
        res => {
          this.openSnackBar(res.status, 'ok');
          this.enviosService.envEstadolist$.emit();
        },
        err => {
          this.openSnackBar(err.error.error, 'alert');
        });

  }



  regHisto(val, op, ci, _id_cliente) {


    let newOb = {
      _id_estado: this.gid,
      ob: val,
      op: op,
      ci: ci,
      _id_cliente: _id_cliente
    }

    this.enviosService.regEstadoHist(newOb)
      .subscribe(
        res => {
        });
  }

  onClose() {
    this.dialogRef.close();
  }

}
