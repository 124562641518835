import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LogsService } from "./../../../services/logs.service";

@Component({
  selector: 'app-menu-logs',
  templateUrl: './menu-logs.component.html',
  styleUrls: ['./menu-logs.component.css']
})
export class MenuLogsComponent implements OnInit {
  rol_Admin = ["admin", "gerente", "admin_vendedor"];
  // cClas = new BehaviorSubject<any>(null);
  clas: any;
  constructor(
    private logsService: LogsService
  ) { }

  ngOnInit(): void {

    this.logsService.listNoti$.subscribe((objeto) => {

      this.clas = objeto;

    
    });


  }

}
