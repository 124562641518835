<div class="conte-pro">
  <div class="titulo-row-b">
    <div class="ct-t">
      <div>
        <button mat-icon-button>
          <mat-icon>insert_chart_outlined</mat-icon>
        </button>
      </div>
      <div>
        Áreas de Gestión
      </div>

      <div class="ct-btn">
        <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button [routerLink]="['/area_scheme']">
          <mat-icon matListIcon>event_note</mat-icon>
        </button>
        <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button (click)="newArea()">
          <mat-icon matListIcon>add</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <mat-divider></mat-divider>
  <section *ngIf="newObj">

    <form [formGroup]="formPurchaseOrder">

      <div class="ct-ca">
        <div>

          <mat-form-field class="mat-form-field">
            <mat-label>Tipo:</mat-label>
            <mat-select formControlName="tipo">
              <mat-option value="PROYECTO">PROYECTO</mat-option>
              <mat-option value="SEGUIMIENTO">SEGUIMIENTO</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="mat-form-f">
            <mat-label>Canal</mat-label>
            <mat-select formControlName="canal" required>
              <mat-option *ngFor="let env of obs | async" value="{{env._id}}"> {{env.canal}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Buscar por:</mat-label>
            <mat-select formControlName="afor">
              <mat-option value="razon_social">NOMBRE</mat-option>
              <mat-option value="id_epicor">ID EPICOR</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="example-full-width">
            <input [formControl]="clienteF" type="text" placeholder="Cliente" aria-label="Number" matInput
              [matAutocomplete]="autoCliente" required>
            <mat-autocomplete autoActiveFirstOption #autoCliente="matAutocomplete">
              <mat-option *ngFor="let optionCliente of filteredOptionsCliente | async"
                [value]="optionCliente.razon_social" (click)="setCliente(optionCliente._id);">
                <div class="listadoProducto">
                  {{optionCliente.id_epicor}} - {{optionCliente.razon_social}}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="ct-cb">
        <div>
          <mat-form-field class="mat-form-fields">
            <mat-label>Requerimientos:</mat-label>
            <input matInput formControlName="nombre" autocomplete="off" type="text" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="mat-form-fields">
            <mat-label>Descripcion:</mat-label>
            <input matInput formControlName="descripcion" autocomplete="off" type="text" />
          </mat-form-field>
        </div>
      </div>

      <div class="ct-cc">
        <div>
          <mat-form-field class="mat-form-fields">
            <mat-label>Miembros:</mat-label>
            <mat-select formControlName="miembros" multiple>
              <mat-option *ngFor="let topping of toppingList" [value]="topping._id">{{topping.nombre}}
                {{topping.apellido}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="mat-form-field">
            <mat-label>Acceso:</mat-label>
            <mat-select formControlName="visible">
              <mat-option value="pu">PÚBLICO</mat-option>
              <mat-option value="pr">PRIVADO</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

 
      </div>


      <div class="ct-colors">
        <div>
          <mat-form-field>
            <mat-label>Color:</mat-label>
            <input matInput formControlName="colora" autocomplete="off" type="color" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Color:</mat-label>
            <input matInput formControlName="colorb" autocomplete="off" type="color" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Color:</mat-label>
            <input matInput formControlName="colorc" autocomplete="off" type="color" />
          </mat-form-field>
        </div>
      </div>

      <div class="btn">
        <button (click)="add();" class="btn" color="primary" mat-raised-button mat-button>
          <mat-icon matListIcon>add</mat-icon> Agregar
        </button>
      </div>

    </form>
  </section>

  <section>
    <div class="titulo-row-b">
      <button mat-icon-button [routerLink]="['/dashboard']">
        <mat-icon>navigate_before</mat-icon>
      </button>
      Mis aŕeas
    </div>
    <mat-divider></mat-divider>
    <!-- <div class="ct-carg" *ngFor="let obja of obsArea | async">
      <app-card-area [Env]="obja"></app-card-area>
    </div> -->



    <div *ngIf="data;else nodata" class="ct-mat-data" fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
      <div class="mat-card-pre" *ngFor="let obja of obsArea | async | filter: searchText; index as i" fxFlex.sm="0 1
      calc(50%-5px)" fxFlex.lt="0 1 calc(20%-5px)" fxFlex.md="0 1 calc(20%-5px)" fxFlex.gt-md="0 1 calc(20%-5px)">
      <app-card-area [Env]="obja"></app-card-area>
      </div>


    </div>
    <ng-template #nodata>

      <app-info-data [mensaje]="'Aun no tienes áreas de trabajo'" [pd]=""> </app-info-data>
    </ng-template>

  </section>

</div>

<div class="footer"></div>