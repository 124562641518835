<div class="ct-model">
    <!-- <div class="item name-a">
      <span class="denon">VOL</span> {{tVol | number: '1.2-2'}} <span class="denom"> {{tUni}}</span>
    </div> -->

    <div></div>
    <div class="item name-a">
      <span class="denon">VEN</span> {{tTolu | number: '1.2-2'}} <span class="denom">USD</span>
    </div>
    <div class="item name-a">
      <span class="denon">VEN</span> {{tTol | number: '1.2-2'}} <span class="denom">MXN</span>
    </div>
    <div class="item name-c">
   
      <form [formGroup]="criterioCh" method="post">
        <mat-form-field  class="mat-form-field-filtro">
          <mat-label>Año:</mat-label>
          <mat-select (selectionChange)="updateAn()" formControlName="canal">
            <mat-option *ngFor="let lv of listRango" [value]="lv.vYear"> {{lv.vYear}} </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
  
   
   
      <!-- <mat-form-field class="selem" (change)="updateAn($event.target.value)">
        <select matNativeControl >
          <option  *ngFor="let lv of listRango" [value]="lv.vYear">
            {{ lv.vYear }}
          </option>
        </select>
      </mat-form-field> -->
    </div>
  </div>

  <mat-divider></mat-divider>
  
<div *ngIf="show;else nodata" class="ct-list table-container">
    <div *ngFor="let env of data; let i = index;" class="ct-mat">
        <div class="ct-data {{getColorl(i)}}">

            <div class="ct-data-ima">
                {{env.ide}}
            </div>
            <div class="ct-data-nombre">
                {{env.nom}}
            </div>
            <div class="ct-data-vol">
                {{env.vol | number: '1.2-2'}}

            </div>
            <div class="ct-data-uni">
                {{env.unidad}}

            </div>
            <div class="ct-data-total">
                <span *ngIf="env.tol>0"> {{env.tol | number: '1.2-2'}} </span>
                <br>
                <span *ngIf="env.tolu>0"> {{env.tolu | number: '1.2-2'}} </span>
            </div>
            <div class="ct-data-mon">
                <span *ngIf="env.tol>0"> MX </span>
                <br>
                <span *ngIf="env.tolu>0"> {{env.mon}} </span>
            </div>

        </div>


    </div>
</div>

<ng-template #nodata>
    <app-info-data [mensaje]="'Este cliente no tiene venta'" [pd]=""> </app-info-data>
  
  </ng-template>