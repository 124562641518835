<div class="conte-pro">
  <!-- <div class="titulo-row-b">

    <div class="ct-membrete">
      <div class="ct-membrete-ico">
        <button mat-icon-button>
          <mat-icon>insert_chart_outlined</mat-icon>
        </button>
      </div>
      <div class="ct-membrete-titulo">Venta Detalle</div>
      <div class="ct-membrete-sub-titulo">
        Semana {{getSem(sm)}} desde <span class="datef">{{ convera }}</span> hasta <span class="datef">{{ converb }}</span>
      </div>
    </div>
    <div class="ct-m-b">
      <button class="btn-d" (click)="showdetailF();" mat-flat-button>
        <mat-icon>functions</mat-icon> {{texts}} </button>
      </div>

  </div> -->

  <div class="titulo-row-b">
    <div class="ct-membrete">
      <div class="ct-membrete-ico">
        <button mat-icon-button>
          <mat-icon>fact_check</mat-icon>
        </button>
      </div>
      <div class="ct-membrete-titulo">Venta Detalle</div>
      <div class="ct-membrete-sub-titulo">
        Semana {{getSem(sm)}} desde <span class="datef">{{ convera }}</span> hasta <span class="datef">{{ converb }}</span>
      </div>

      <div class="ct-m-b">
      <button class="btn-d" (click)="showdetailF();" mat-flat-button>
        <mat-icon>functions</mat-icon> {{texts}} </button>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>


  <section *ngIf="showdetail">
    <mat-tab-group class="tabs-master-cli">
      <mat-tab label="Acumulado General">
        <ng-template matTabContent>
  
          <app-acu-cli-g [vYear]="ye" [vCli]="ci"></app-acu-cli-g>
        </ng-template>
  
  
  
      </mat-tab>
      <mat-tab label="Venta Vs. Presupuesto">
        <ng-template matTabContent>
          <div class="ct-master-a">
            <div class="ct-master-b">
              <div class="ct-master-c">
                <app-gra-ven-cus-year *ngIf="idd" [vYear]="ye" [vCli]="idd"></app-gra-ven-cus-year>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
  
    </mat-tab-group>

    <mat-divider></mat-divider>
    </section>


  <div class="ct-vd">
    <div class="ct-vd-content-day">
      <app-gra-ven-day [vYear]="ye" [vSem]="convera" [vCliente]="ci"></app-gra-ven-day>
    </div>
    <div class="ct-vd-content-tabla">
      <app-per-cli-vdi [idepicor]="ci" [vD]="convera" [vSem]="sm" [vH]="converb" [vYear]="ye" [vMes]="ci">
      </app-per-cli-vdi>
    </div>
    <div class="ct-vd-content-grafica aliga">
      <div *ngIf="datagra;else nogra" class="ct-grafica">
        <div class="ct-master-a">
          <div class="ct-master-b">
            <div class="ct-master-c">
              <ngx-charts-bar-vertical [roundDomains]="roundDomains" [maxXAxisTickLength]="'3'" [legendTitle]="''"
                [scheme]="colorScheme" [results]="dataResultgra" [gradient]="gradient" [showDataLabel]="showDataLabel"
                [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                [animations]="animations" [noBarWhenZero]="noBarWhenZero">
              </ngx-charts-bar-vertical>
            </div>
          </div>
        </div>
      </div>


      <ng-template #nogra>

        <div class="ct-wit">
          <div class="ct-wit-item">
            <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '50px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '300px',
                        'background-color': '#daddd8'}">
            </ngx-skeleton-loader>
          </div>

          <div class="ct-wit-item">

            <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '50px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '270px',
                        'background-color': '#daddd8'}">
            </ngx-skeleton-loader>
          </div>

          <div class="ct-wit-item">

            <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '50px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '150px',
                        'background-color': '#daddd8'}">
            </ngx-skeleton-loader>
          </div>
        </div>
      </ng-template>


    </div>
  </div>




<app-panel-venta-productos 
[idepicor]="ci" 
[idd]="idd" 
[vD]="convera" 
[vH]="converb" 
[vYear]="ye" 
></app-panel-venta-productos>



</div>