
<div class="content-graf" *ngIf="g1">
  <div class="titulo-row-b-g">
    <button mat-icon-button>
      <mat-icon>insert_chart_outlined</mat-icon>
    </button>
    Top 10 Clientes:
    <mat-form-field (change)="updateAn($event.target.value)">
      <select matNativeControl>
        <option selected value="2022">2022</option>
        <option  value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
        <option value="2017">2017</option>
      </select>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div class="wrapper" *ngIf="loading;else content">
    <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
  </div>
  <ng-template #content>
    <div class="content-graf">
      <mat-grid-list class="gpr" [cols]="breakpoint" rowHeight="380px" (window:resize)="onResize($event)">
        <mat-grid-tile [colspan]="colspa" class="spar_grid"> 

       


                <div class="da">
                  <div class="db">
                    <div class="dc">

                      <div class="my-flex-mat-card">
                <ngx-charts-bar-vertical [scheme]="bcolorScheme" [results]="transactions" [gradient]="bgradient"
                  [xAxis]="bshowXAxis" [yAxis]="bshowYAxis" [legend]="bshowLegend" [legendTitle]="blegendTitle"
                  [showXAxisLabel]="bshowXAxisLabel" [showYAxisLabel]="bshowYAxisLabel" [xAxisLabel]="bxAxisLabel"
                  [yAxisLabel]="byAxisLabel" [maxXAxisTickLength]="4" (select)="onSelectTopB($event)">
                </ngx-charts-bar-vertical>
                  </div>
              </div>
            </div>
          </div>

      
        </mat-grid-tile>
        <mat-grid-tile [colspan]="colspb">
          <div class="table-container special">
            <table mat-table [dataSource]="transactions" class="mat-elevation-z8" class="table-vieg2">
              <ng-container matColumnDef="canal">
                <th mat-header-cell *matHeaderCellDef> Cliente </th>
                <td mat-cell *matCellDef="let transactions"> {{transactions.name}} </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef> Venta MXN </th>
                <td mat-cell *matCellDef="let transactions"> {{transactions.value | currency}} </td>
                <td mat-footer-cell *matFooterCellDef> {{getTotalgb() | currency}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </ng-template>
</div>




<div class="content-graf" *ngIf="g2">
  <div class="titulo-row-b">
    <button mat-icon-button (click)="resetDivGlobal();">
      <mat-icon>insert_chart_outlined</mat-icon>
    </button>
    {{this.vName}} - {{this.vYear}}
  </div>
  <mat-divider></mat-divider>

  <div class="wrapper" *ngIf="loadingb;else contentb">
      <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
    </div>
    <ng-template #contentb> 
  <div class="content-graf-con">
    <div style="height: 100%; width:100%">
      <div style="display:flex; flex-direction:column;">
        <div style="height:380px">
       



<app-gra-ven-cus-year [vYear]="vYear" [vCli]="vCli"></app-gra-ven-cus-year>

              
       
        </div>
      </div>
    </div>
  </div>
</ng-template>
</div>
