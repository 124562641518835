<div class="conte-mo">
  <form [formGroup]="form" method="post" (ngSubmit)="registro();">
    <div class="titulo-row-b">
      Registro Usuario</div>

    <mat-divider></mat-divider>



    <div class="columna1">
      <input formControlName="ima" value="" hidden (change)="onFileSelected($event)" #fileInput type="file"
        id="ima">
      <img mat-card-avatar [src]="photoSelected || 'assets/ima/no-ima.png'" class="imgfluid"
        (click)="fileInput.click()">

    </div>

    <div class="columna2">
      <div class="bar">


        <div class="ms">
          <mat-slide-toggle (change)="changeEstado($event)" formControlName="es" color="primary">Vendedor?
          </mat-slide-toggle>
        </div>


        <mat-form-field appearance="fill">
          <mat-label>Correo</mat-label>
          <input matInput placeholder="pat@example.com" formControlName="email" autocomplete="off" required>
          <mat-error *ngIf="email.invalid">{{getErrorMessageEmail()}}</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>Contraseña</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" autocomplete="off"  formControlName="clave">
          <mat-error *ngIf="clave.invalid">{{getErrorMessageClave()}}</mat-error>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>Roles</mat-label>
          <mat-select formControlName="rol" multiple>
            <mat-option *ngFor="let uni of dataRoles" [value]="uni._id" (onSelectionChange)="getValues($event)">
              {{uni.name.toUpperCase()}}</mat-option>
          </mat-select>
        </mat-form-field>
        <br>


      </div>

    </div>
    <div class="columna3">
      <div class="bar">
        <div class="example-container">




        <mat-form-field class="mat-form" appearance="fill">
            <mat-label>Epicor</mat-label>
            <input matInput formControlName="cod_epicor" autocomplete="off"  required>
            <mat-error *ngIf="cod_epicor.invalid">{{getErrorMessageCod()}}</mat-error>
          </mat-form-field>
          <br>
  
          <mat-form-field class="mat-form" appearance="fill">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" autocomplete="off"  required>
            <mat-error *ngIf="nombre.invalid">{{getErrorMessageNombre()}}</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Apellido</mat-label>
            <input matInput formControlName="apellido" autocomplete="off" required>
            <mat-error *ngIf="apellido.invalid">{{getErrorMessageApellido()}}</mat-error>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Telefono</mat-label>
            <input matInput formControlName="telefono" autocomplete="off" type="number" required>
            <mat-error *ngIf="telefono.invalid">{{getErrorMessageTelefono()}}</mat-error>
          </mat-form-field>
          <br>

          <mat-form-field appearance="fill">
            <mat-label>Fecha de Nacimiento</mat-label>
            <input matInput [matDatepicker]="dp" autocomplete="off"  formControlName="date">
            <mat-error>{{getErrorMessageDp()}}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event)" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
          <br>

        </div>
      </div>

    </div>




    <div class="btn-enviar">
      <button class="btn-agregar" mat-raised-button color="primary">
        Registrar
      </button>
    </div>

  </form>
</div>