import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VenSemAdminService } from '../../../../../services/sales/ven-sem-admin.service';

@Component({
  selector: 'app-pvd-producto',
  templateUrl: './pvd-producto.component.html',
  styleUrls: ['./pvd-producto.component.css'],
})
export class PvdProductoComponent implements OnInit {
  dataPro = new BehaviorSubject<any>(null);
  @Input() idproducto: String;
  @Input() vYear: Number;
  @Input() vD: Date;
  @Input() vH: Date;
  @Input() vCli: String;
  @Input() vUni: String;
  celusd: boolean;
  mostrar: boolean;
  constructor(private venSemAdminService: VenSemAdminService) {}

  ngOnInit(): void {
    this.mostrar = true;
    this.getGraVenSemProFolio(
      this.idproducto,
      this.vYear,
      this.vD,
      this.vH,
      this.vCli,
      this.vUni
    );
  }

  getGraVenSemProFolio(idproducto, vYear, vD, vH, vCli,vUni) {
    this.venSemAdminService
      .getGraVenSemProFolio({
        _id_producto: idproducto,
        desde: vD,
        hasta: vH,
        yeara: vYear,
        vCli: vCli,
        vUni:vUni
      })
      .subscribe((resp) => {
        this.dataPro.next(resp);
        var total = resp.map((d) => d.venta_uds).reduce((sum, d) => sum + d, 0);
        if (total > 0) {
          this.celusd = true;
        }
        this.mostrar = false;
      });
  }

  getColorl(i) {
    const val = i % 2;
    if (val === 0) {
      return 'divRowb';
    } else {
      return 'divRoww';
    }
  }
}
