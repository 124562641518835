import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductService } from "./../../../services/product.service";
import { TrasChannelProComponent } from "./../tras-channel-pro/tras-channel-pro.component";

@Component({
  selector: 'app-ch-product-asig',
  templateUrl: './ch-product-asig.component.html',
  styleUrls: ['./ch-product-asig.component.css']
})
export class ChProductAsigComponent implements OnInit {
  @Input() vCli: string;
  @Input() vCh: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select','nump', 'nombre', 'ico'];
  selection = new SelectionModel<any>(true, []);
  dataResult = new MatTableDataSource<any>();
  btt: boolean;
  bttn: number;
  show: boolean;

  constructor(
    private productService:ProductService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.listProductAllCustAsig(this.vCli, this.vCh);

    
  
  }

  listProductAllCustAsig(_id_cliente, vCh) {
    this.productService.listProductAllCustAsig({ _id_cliente: _id_cliente, vCh:vCh}).subscribe(
      resp => {    
        if (resp.length > 0) {
          this.show = true;
        } else {
          this.show = false;
        }
            
        this.dataResult.data = resp;
        this.dataResult.paginator = this.paginator;
        this.dataResult.sort = this.sort;
      });
  }

    isAllSelected() {


      if (this.selection.selected.length > 0) {
        this.btt = true;
        this.bttn = this.selection.selected.length;
      } else {
        this.btt = false;
        this.bttn = 0;
        this.selection.clear(); 
      }


      const numSelected = this.selection.selected.length;
      const numRows = this.dataResult.data.length;
      return numSelected === numRows;
    }
  
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataResult.data.forEach(row => this.selection.select(row));
    }
  
    checkboxLabel(row?: any): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataResult.filter = filterValue.trim().toLowerCase();
    }
  

    onDialogTras() {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "50%";
      dialogConfig.data = {vPro:this.selection.selected,vCli:this.vCli} ;
      this.dialog.open(TrasChannelProComponent, dialogConfig);
    }


  }