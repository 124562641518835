import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BudgetService } from "../../../services/budget.service";
export interface DialogData {
vPro:[],
vCli:String
  }

@Component({
  selector: 'app-del-presupuesto',
  templateUrl: './del-presupuesto.component.html',
  styleUrls: ['./del-presupuesto.component.css']
})
export class DelPresupuestoComponent implements OnInit  {

    id: string="";
    actividad: string="";
    local_data;
    message: string = "¿Seguro que deseas continuar?";
    confirmButtonText = "Si";
    cancelButtonText = "No";
    loading: boolean = true;
    constructor(
    public dialogRef: MatDialogRef<DelPresupuestoComponent>,
    public budgetService: BudgetService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any =[]) {

    this.local_data=this.data;
    this.id=this.local_data.data.id;

    }

    ngOnInit(): void {

    }

 


    onConfirmClick(){
    this.budgetService.delPresupuestoAdd({an:this.id}).subscribe(response => {
    this.onClose();
    this.openSnackBar(response.status, 'ok'); 
    this.budgetService.ventaSerListadoPre$.emit();
    }, error => this.openSnackBar("Ha ocurrido un error","Error"));
}


    openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    panelClass: [tipo]
    });
    }

    onClose() {
    this.dialogRef.close();
    }
}
