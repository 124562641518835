import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VenMesAdminService } from './../../../../services/sales/ven-mes-admin.service';
import * as moment from 'moment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-acu-cli-g',
  templateUrl: './acu-cli-g.component.html',
  styleUrls: ['./acu-cli-g.component.css'],
})
export class AcuCliGComponent implements OnInit {
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  @Input() vYear: string;
  @Input() vCli: string;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['num', 'presupuesto', 'oc', 'venta', 'indi'];
  listRango: any = [];
  obsGlo = new BehaviorSubject<any>(null);
  multi: any[];
  view: any[] = [700, 300];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'MXN';
  timeline: boolean = false;
  legendPosition = 'below';
  customColors: any[] = [
    { name: 'PRE 2022', value: '#0f69f1' },
    { name: 'OC 2022', value: '#efb810' },
    { name: 'VEN 2022', value: '#26a116' },

    { name: 'PRE 2021', value: '#0f69f1' },
    { name: 'OC 2021', value: '#efb810' },
    { name: 'VEN 2021', value: '#26a116' },

    { name: 'PRE 2020', value: '#0f69f1' },
    { name: 'OC 2020', value: '#efb810' },
    { name: 'VEN 2020', value: '#26a116' },

    { name: 'PRE 2019', value: '#0f69f1' },
    { name: 'OC 2019', value: '#efb810' },
    { name: 'VEN 2019', value: '#26a116' },

    { name: 'PRE 2018', value: '#0f69f1' },
    { name: 'OC 2018', value: '#efb810' },
    { name: 'VEN 2018', value: '#26a116' },

    { name: 'PRE 2017', value: '#0f69f1' },
    { name: 'OC 2017', value: '#efb810' },
    { name: 'VEN 2017', value: '#26a116' },
  ];

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  wiat = [];
  gd1: boolean = false;
  gd2: boolean = false;
  gd1m: boolean = true;
  gd2m: boolean = true;
  criterioCh: FormGroup;
  volSumaPre: any;
  volSumaVen: any;
  vtable: boolean;
  volSumaOc: any;

  constructor(
    private venMesAdminService: VenMesAdminService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.setList();
    this.crearFormCh();
    this.generateRandomInt();

    this.acuCliGen(this.vYear, this.vCli);
    this.masterPreCusVenYearBlo(this.vYear, this.vCli);
  }

  crearFormCh() {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(Number(this.vYear)),
    });
  }

  updateCh() {
    this.gd1 = false;
    this.gd2 = false;

    const vYears = this.criterioCh.controls.canal.value;

    this.acuCliGen(String(vYears), String(this.vCli));
    this.masterPreCusVenYearBlo(vYears, this.vCli);
  }

  acuCliGen(vYear, vCliEP) {
    this.venMesAdminService
      .acuCliGen({ vYear: vYear, vCliEP: vCliEP })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.gd1m = true;
          this.obsGlo.next(resp);
        } else {
          this.gd1m = false;
        }
        this.gd1 = true;
      });
  }

  masterPreCusVenYearBlo(vYear, vCliEP) {
    this.venMesAdminService
      .masterPreCusVenYearBlo({ vYear: vYear, vCliEP: vCliEP })
      .subscribe((resp) => {

        if (resp.length > 0) {
          this.gd2m = true;
        } else {
          this.gd2m = false;
        }
        this.multi = resp;
        this.gd2 = true;

        let da = resp.map((val) => val.series);
        
        let dataGra = [];
        for (let i = 0; i < 1; i++) {
          for (let j = 0; j < da[i].length; j++) {
            const elementa = da[0][j];
            const elementc = da[1][j];
            const elementb = da[2][j];

            dataGra.push({
              num: j + 1,
              mes: elementa.name,
              presupuesto: elementa.value,
              oc: elementc.value,
              venta: elementb.value,
            });
          }
        }

        

        this.dataSource.data = dataGra;

        this.volSumaPre = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.presupuesto;
        }, 0);


        this.volSumaOc = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.oc;
        }, 0);

        this.volSumaVen = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.venta;
        }, 0);
      });
  }

  getTable() {
    if (this.vtable) {
      this.vtable = false;
    } else {
      this.vtable = true;
    }
  }

  generateRandomInt() {
    for (let i = 0; i < 11; i++) {
      this.wiat.push({
        'border-radius': '5px',
        width: '30px',
        padding: '5px',
        margin: '7px',
        height: Math.floor(Math.random() * (210 - 20) + 20) + 'px',
        'background-color': '#daddd8',
      });
    }
    return this.wiat;
  }

  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }
}
