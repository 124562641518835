<div *ngIf="showp;else nodata">
    <div *ngFor="let env of obs | async">
        <div class="ct-panel">
            <div class="ct-ima">
                <app-ima-cliente [idcliente]="env.id_epicor">
                </app-ima-cliente>
            </div>
            <div class="ct-nom">{{env.razon_social}}</div>
        </div>

    </div>
</div>

<ng-template #nodata>
    <div class="ct-value-cero">
        <div class="item-value-cero"> Sin venta </div>
    </div>
</ng-template>