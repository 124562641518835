import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "../../../../../services/sales/sales.service";

@Component({
  selector: 'app-sales-num-pro',
  templateUrl: './sales-num-pro.component.html',
  styleUrls: ['./sales-num-pro.component.css']
})
export class SalesNumProComponent implements OnInit {
  @Input() vCli: string;
  @Input() vD: Date;
  @Input() vH: Date;
  @Input() vT: String;
  @Input() vCh: String;
  obs = new BehaviorSubject<any>(null);

  constructor(
    private salesService: SalesService
  ) { }

  ngOnInit(): void {
    if (this.vT === "C") {
      this.getDataCliSearchNumProChannel(this.vCli, this.vD, this.vH, this.vCh);
    } else {
      this.getDataCliSearchNumPro(this.vCli, this.vD, this.vH);
    }
  }

  getDataCliSearchNumProChannel(vCli, vD, vH, vCh) {
    this.salesService.getDataCliSearchNumProChannel({
      vCli: vCli,
      vD: vD,
      vH: vH,
      vCh: vCh,
    }).subscribe((resp) => {      
      this.obs.next(resp);
    });
  }

  getDataCliSearchNumPro(vCli, vD, vH) {
    this.salesService.getDataCliSearchNumPro({
      vCli: vCli,
      vD: vD,
      vH: vH,
    }).subscribe((resp) => {      
      this.obs.next(resp);
    });
  }


}
