import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { GlobalVariable } from '../global';
@Injectable({
  providedIn: 'root'
})
export class GraficasService {
  private URL = GlobalVariable.BASE_API_URL;
  public getGraficaPreFir$ = new EventEmitter<any>();
  public getGraficaCanal$ = new EventEmitter<any>();
  public getGraficaCanalP$ = new EventEmitter<any>();
  public getGraficaCanalPC$ = new EventEmitter<any>();
  public getGraficaCanalPCP$ = new EventEmitter<any>();
  public getGraficaTop10$ = new EventEmitter<any>();
  public getGraficaTop10Cli$ = new EventEmitter<any>();
  public getGraficaVentaTop10Producto$ = new EventEmitter<any>();
  public getGraficaVentaTop10ProMes$ = new EventEmitter<any>();
  public getGraficaVentaTop10ProMesVol$ = new EventEmitter<any>();
  public getGraficaVentaTop10ProMesVolCli$ = new EventEmitter<any>();
  public getGraficaVentaTop10Ven$ = new EventEmitter<any>();
  public getGraficaVentaTop10VenCliB$ = new EventEmitter<any>();


  constructor(
    private http: HttpClient,
    private router: Router) { }

  getGraficaPreFir(monto) {
    return this.http.post<any>(this.URL + 'getGraficaPreFir', monto);
  }

  getGraficaCanal(data) {
    return this.http.post<any>(this.URL + 'getGraficaCanal', data);
  }

  getGraficaCanalPeriodo(data) {
    return this.http.post<any>(this.URL + 'getGraficaCanalPeriodo', data);
  }

  getGraficaCanalPeriodoCliente(data) {
    return this.http.post<any>(this.URL + 'getGraficaCanalPeriodoCliente', data);
  }

  getGraficaCanalPeriodoProducto(data) {
    return this.http.post<any>(this.URL + 'getGraficaCanalPeriodoProducto', data);
  }

  getGraficaVentaTop10(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10', data);
  }

  getGraficaVentaTop10Cli(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10Cli', data);
  }

  getGraficaVentaTop10Pre(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10Pre', data);
  }

  getGraficaVentaTop10Producto(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10Producto', data);
  }

  getGraficaVentaTop10ProMes(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10ProMes', data);
  }

  getGraficaVentaTop10ProMesVol(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10ProMesVol', data);
  }

  getGraficaVentaTop10ProMesVolCli(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10ProMesVolCli', data);
  }
  getGraficaVentaTop10Ven(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10Ven', data);
  }

  getGraficaVentaTop10VenCliB(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10VenCliB', data);
  }


  getComMesCliVent(data) {
    return this.http.post<any>(this.URL + 'getComMesCliVent', data);
  }

  getGraficaCanalYearMes(data) {
    return this.http.post<any>(this.URL + 'getGraficaCanalYearMes', data);
  }

  volAgruVenProduc(data) {
    return this.http.post<any>(this.URL + 'volAgruVenProduc', data);
  }

  getGraficaVentaTop10ProMesUS(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10ProMesUS', data);
  }


  getGraficaVentaTop10ProMes_CLI(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10ProMes_CLI', data);
  }

  getGraficaVentaTop10ProMesVol_CLI(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaTop10ProMesVol_CLI', data);
  }


  
  
}
