import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AreaService } from 'src/app/services/area.service';
import * as moment from 'moment';

@Component({
  selector: 'app-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.css'],
})
export class AreaDetailComponent implements OnInit {
  @ViewChild('target', { read: ElementRef })
  public target: ElementRef<any>;
  data: boolean;
  obsArea = [];
  vAr: string;
  ngTaks: boolean;
  resol: any;
  ngTaksChema: boolean;
  ngMiembro: boolean;

  constructor(private areaService: AreaService, private route: ActivatedRoute) {
    this.vAr = this.route.snapshot.paramMap.get('vAr');
  }

  async ngOnInit(): Promise<any> {
    this.getAreaId(this.vAr);
    this.areaService.cBeem$.subscribe(async (val) => {
      this.ngTaks = val;
      this.ngTaksChema = val;
      this.ngMiembro=val;
      this.resol = await this.getTasDate(this.vAr);
    });

    this.resol = await this.getTasDate(this.vAr);
  }

  upArea() {}

  getAreaId(vAr) {
    this.areaService.getAreaId({ vAr: vAr }).subscribe((resp) => {
      this.obsArea = resp;
    });
  }

  getDate(date) {
    return moment(String(date)).format('YYYY-MM-DD');
  }

  getNeTask() {
    this.ngTaks = true;
    this.ngTaksChema = false;
    this.ngMiembro=false;
  }

  getNeTaskChema() {
    this.ngTaksChema = true;
    this.ngTaks = false;
    this.ngMiembro=false;

  }


  getUserMie() {
    this.ngTaksChema = false;
    this.ngTaks = false;
    this.ngMiembro = true;

  }

  getNeTaskOffChema() {
    this.ngTaks = false;
  }

  getNeTaskOff() {
    this.ngTaks = false;
  }

  getcolor(a, b, c) {
    let stb = {
      background:
        'radial-gradient(circle at top left, ' + a + ', ' + b + ', ' + c + ')',
    };

    return stb;
  }

  async getTasDate(vAr) {
    let obj;
    await this.areaService
      .getRangoProject({ vAr: vAr })
      .toPromise()
      .then((resp) => {
        let oriG = resp;

        if (oriG.length > 0) {
          let dRange = resp;
          let a = dRange.shift();
          let b = dRange.pop();

          if (!b) {
            b = { fecha_end: a.fecha_end };
          }

          obj = {
            data: oriG,
            dateStar: moment(a.fecha_star).format('YYYY-MM-DD'),
            dateEnd: moment(b.fecha_end).format('YYYY-MM-DD'),
          };
        }
      })
      .catch((e) => console.error(e));

    return obj;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent: ElementRef<any>;
  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.scroll(this.target.nativeElement);
  //   }, 3000);
  // }
}
