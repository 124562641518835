<div class="conte-mo">
  <div class="titulo-row-b">
    Registro Unidades de Medidas
  </div>
  <mat-divider></mat-divider>
  <div class="diForm">
  <form [formGroup]="form" method="post" (ngSubmit)="add();">
    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="nombre" required>
    </mat-form-field>
    <br>
    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Abreviatura Singular</mat-label>
      <input matInput formControlName="acro" required>
    </mat-form-field>
    <br>
    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Abreviatura Plural</mat-label>
      <input matInput formControlName="across" required>
    </mat-form-field>
    <br>
    <div class="btn-enviar">
      <button class="btn-agregar" mat-raised-button color="primary">
        Registrar
      </button>
    </div>
  </form>
</div>
</div>