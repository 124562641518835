<div *ngIf="data;else nodata">

    <div class="ct-h">
        <div class="ct-h-t">Tareas</div>
        <div class="ct-h-i">Resp.</div>
        <div class="ct-h-i">Estado</div>
        <div class="ct-h-i">Cronograma</div>
        <div class="ct-h-i">Vence</div>
        <div class="ct-h-i">Prioridad</div>
        <div class="ct-h-i">Opción</div>
    </div>

    <div *ngFor="let env of obsTask | async" class="ct-d">

        <div class="ct-d-t" [ngStyle]="{'border-left':env.color+' solid 3px'}">
            <span class="ct-d-ti"> <span class="ct-cit"
                    [ngStyle]="{'background-color':env.color}">{{env.cit}}</span>{{env.nombre}}</span>
            <span class="ct-d-tis"> {{env.des}}</span>
        </div>
        <!-- {{getCronograma(env.fecha_star,env.fecha_end)}} -->

        <div class="ct-d-i">
            <app-ima-user-id [vUser]="env.responsable"></app-ima-user-id>
        </div>
        <div >


            <app-task-status [vAr]="vAr" [vTa]="env._id"></app-task-status>



        </div>
        <div class="ct-d-i">
            <div class="ctdi-ls"  matTooltip="{{getFortmat(env.fecha_star)}} - {{getFortmat(env.fecha_end)}}">
                <div class="ctdi-ls-a">
                    Días {{getDayDiffRest(env.fecha_star,env.fecha_end)}}/{{getDayDiff(env.fecha_star,env.fecha_end)}}
                </div>
                <div>
                    <mat-progress-bar mode="determinate" value="{{getCronograma(env.fecha_star,env.fecha_end)}}">
                    </mat-progress-bar>
                </div>
            </div>


        </div>
        <div class="ct-d-i">{{getFormatDate(env.fecha_end)}}</div>
        <div class="ct-d-i">
            <star-rating value="{{env.prioridad}}" [totalstars]='5' checkedcolor="gold" uncheckedcolor="gray"
                size="24px" readonly="true"></star-rating>
        </div>
        <div class="ct-d-i">
            <mat-icon matTooltip="Eliminar tarea" class="color_white" (click)="onDialogDel(env._id)">delete</mat-icon>
            <mat-icon class="color_up" matTooltip="Actualizar tarea" (click)="onDialogUp(env._id)">update</mat-icon>
        </div>
    </div>

</div>

<ng-template #nodata>
    <app-info-data [mensaje]="'Aun no hay tareas asignadas'" [pd]=""> </app-info-data>
</ng-template>