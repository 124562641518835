import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DirectorioService } from '../../../services/directorio.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-dir-cliente',
  templateUrl: './dir-cliente.component.html',
  styleUrls: ['./dir-cliente.component.css'],
})
export class DirClienteComponent implements OnInit {
  gname: string;
  gima: string;
  local_data: any;
  gid: any;
  displayedColumns: string[] = [
    'nombre',
    'cargo',
    'telefono',
    'ext',
    'mail',
    'opciones',
  ];
  datav: boolean;
  loading: boolean;
  cCliente: FormGroup;
  obs = new BehaviorSubject<any>(null);

  classCss = "ima_cliente";
  lstsem = [];
  lstdat = [];
  idepicor: any;
  constructor(
    public dialogRef: MatDialogRef<DirClienteComponent>,
    private directorioService: DirectorioService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.local_data = this.data;
    this.gid = this.local_data.data._id;
    this.gname = this.local_data.data.razon_social;
    this.gima = this.local_data.data.ima_cliente;
    this.idepicor=this.local_data.data.id_epicor;
    
    this.getAllDir(this.gid);
  }

  ngOnInit(): void {
    this.createForm();
    this.directorioService.dirlist$.subscribe(() => {
      this.getAllDir(this.gid);
    });
  }

  createForm() {
    this.cCliente = this._formBuilder.group({
      nombre: new FormControl('', [Validators.required]),
      cargo: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required]),
      ext: new FormControl(''),
      mail: new FormControl('', [Validators.required]),
    });
  }

  validateIma(valor) {
    if (valor != 'null') return valor;
    else return 'assets/ima/no-ima.png';
  }

  add() {
    if (this.cCliente.valid) {
      const _id_cliente = this.gid;
      const nombre = this.cCliente.controls.nombre.value;
      const cargo = this.cCliente.controls.cargo.value;
      const telefono = this.cCliente.controls.telefono.value;
      const mail = this.cCliente.controls.mail.value;
      const ext = this.cCliente.controls.ext.value;

      let newDirectorio = {
        _id_cliente: _id_cliente,
        nombre: nombre,
        cargo: cargo,
        telefono: telefono,
        mail: mail,
      };

      this.directorioService.registrarDir(newDirectorio).subscribe(
        (res) => {
          this.openSnackBar(res.status, 'ok');
          this.directorioService.dirlist$.emit();
          this.cCliente.reset();
        },
        (err) => {
          this.openSnackBar(err.error.error, 'alert');
        }
      );
    } else {
      this.openSnackBar('Alguno de los campos son obligatorios', 'alert');
    }
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  getAllDir(id) {


    this.loading = true;
    this.directorioService.getAllDirList({ _id_cliente: id }).subscribe(
      (res) => {
        let data = res;



        if (data.length>0) {
          this.lstsem = data;
          this.obs.next(data);
          this.datav = true;
        } else {
          this.datav = false;
        }

    
        this.loading = false;
      },
      (err) => {
        this.openSnackBar(err.error.error, 'alert');
      }
    );
  }

  onDialogDel(_id) {
    this.directorioService.delDir({ _id: _id }).subscribe(
      (res) => {
        this.directorioService.dirlist$.emit();
      },
      (err) => {
        this.openSnackBar(err.error.error, 'alert');
      }
    );
  }

  onClose() {
    this.dialogRef.close();
  }
}
