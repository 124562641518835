import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { VenMesAdminService } from '../../../services/sales/ven-mes-admin.service';
import { SalesService } from '../../../services/sales/sales.service';
import { ChannelService } from '../../../services/channel.service';

@Component({
  selector: 'app-sales-year',
  templateUrl: './sales-year.component.html',
  styleUrls: ['./sales-year.component.css'],
})
export class SalesYearComponent implements OnInit {
  // options
  pshowXAxis = true;
  pshowYAxis = true;
  pgradient = false;
  pshowLegend = false;
  pshowXAxisLabel = false;
  pxAxisLabel = 'Country';
  pshowYAxisLabel = false;
  pyAxisLabel = 'Population';

  pcolorScheme = {
    domain: [],
  };

  ccolorScheme = {
    domain: [
      '#cdb4db',
      '#0077b6',
      '#00afb9',
      '#d4a373',
      '#f07167',
      '#f4a261',
      '#a8dadc',
      '#0077b6',
    ],
  };

  bmulti: any[] = [];
  blegend: boolean = false;
  bshowLabels: boolean = true;
  banimations: boolean = true;
  bxAxis: boolean = true;
  byAxis: boolean = true;
  bshowYAxisLabel: boolean = false;
  bshowXAxisLabel: boolean = false;
  bxAxisLabel: string = 'Histórico mes';
  byAxisLabel: string = 'Population';
  btimeline: boolean = false;
  bcolorScheme = {
    domain: ['#26a116'],
  };

  obsCh = new BehaviorSubject<any>(null);

  vYear: string;
  obsGlo = new BehaviorSubject<any>(null);
  obs = new BehaviorSubject<any>(null);
  graMes = new BehaviorSubject<any>(null);
  public searchText: any;
  tCop = 'mM';
  selecm = new FormControl();
  listRango: any[];
  vMes: any;
  grafCli = false;
  obsVenMes = new BehaviorSubject<any>(null);
  obsMes = new BehaviorSubject<any>(null);
  vRowGet: any;
  pmulti: any[];
  cmulti: any;
  criterioChVen: FormGroup;
  gGlomes:boolean;
  constructor(
    private venMesAdminService: VenMesAdminService,
    private route: ActivatedRoute,
    private channelService: ChannelService,
    private _formBuilder: FormBuilder,
    private salesService: SalesService
  ) {
    this.vYear = this.route.snapshot.paramMap.get('yeara');
    let dataRest = this.getWeek(this.vYear);
    this.semCombo(dataRest.mouth, this.vYear);
  }

  ngOnInit(): void {
    this.crearFormCh();
    this.getAllCanal();
    this.getColor();
    this.randomGet();
    let dataRest = this.getWeek(this.vYear);
    this.semCombo(dataRest.mouth, this.vYear);
    this.orqVenYearDefinido(this.vYear);
    this.orqVenYearDMes(this.vYear, dataRest.mouth, dataRest.dA.convera,  dataRest.dA.converb);
    this.getVenCliMesPre(
      dataRest.yeara,
      dataRest.mouth,
      dataRest.dA.convera,
      dataRest.dA.converb,
      ''
    );
    this.graMesVenYear(this.vYear, this.vMes);
    this.graMesVenYearProducto(this.vYear, this.vMes);
    this.graMesVenYearCliente(this.vYear, this.vMes);
  }

  crearFormCh() {
    this.criterioChVen = this._formBuilder.group({
      canalven: new FormControl(),
    });
  }

  updateChVen() {
    const vCh = this.criterioChVen.controls.canalven.value;


    

    this.getVenCliMesPre(
      this.vRowGet.vYeara,
      this.vRowGet.vMes ,
      this.vRowGet.vD,
      this.vRowGet.vH,
      vCh
    );
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe((resp) => {
      this.obsCh.next(resp);
    });
  }

  orqVenYearDefinido(vYear) {
    this.salesService.orqVenYearDefinido({ vYear: vYear }).subscribe((resp) => {
      let b = resp.filter((element) => element.cdo === 'VEN');
      this.obs.next(resp);
    });
  }

  orqVenYearDMes(vYear, vMes, vD, vH) {
    this.salesService
      .orqVenYearDMes({ vYear: vYear, vMes: vMes, vD:vD, vH:vH })
      .subscribe((resp) => {
        this.obsMes.next(resp);
      });
  }

  graMesVenYear(vYear, vMes) {
    this.salesService
      .graMesVenYear({ vYear: vYear, vMess: vMes })
      .subscribe((resp) => {
        this.bmulti = [];

        resp.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });

        this.bmulti.push({
          name: 'Venta',
          series: resp,
        });
        this.bmulti = [...this.bmulti];
      });
  }

  graMesVenYearProducto(vYear, vMes) {
    this.pmulti = [];
    this.salesService
      .graMesVenYearProducto({ vYear: vYear, vMess: vMes })
      .subscribe((resp) => {
        this.pmulti = resp;
      });
  }

  graMesVenYearCliente(vYear, vMes) {
    this.cmulti = [];
    this.salesService
      .graMesVenYearCliente({ vYear: vYear, vMess: vMes })
      .subscribe((resp) => {
        this.cmulti = resp;
      });
  }

  getVenCliMesPre(year, mes, d, h, vCh) {
    this.venMesAdminService
      .getVenCliMesPre({ yeara: year, mes: mes, d: d, h: h, vCh: vCh })
      .subscribe((resp) => {
        this.obsVenMes.next(resp);
        
        
        if (resp.length > 0) {
          this.gGlomes = true;
        } else {
          this.gGlomes = false;
        }



      });
  }

  verificarMes(mes) {
    const MESES = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return MESES[mes];
  }

  updateAn(event) {
    this.criterioChVen.controls.canalven.reset();
    var vYear = this.vYear;
    this.grafCli = false;
    var mouth = event;

    this.vRowGet = {};
    var aconvera = moment().format(vYear + '-' + mouth + '-01');
    var aconverb = moment().format(vYear + '-' + mouth + '-') + moment(aconvera).daysInMonth();
    var yeara = moment().format(vYear);
    var yearb = moment().subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + '-' + mouth + '-01');
    var bconverb = moment().format(yearb + '-' + mouth + '-') + moment(bconvera).daysInMonth();
    this.selecm.setValue(mouth);
    this.vMes = mouth;

    this.vRowGet.vYeara = vYear;
    this.vRowGet.vD = aconvera;
    this.vRowGet.vH = aconverb;
    this.vRowGet.vMes = mouth;

    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb,
      },
      dB: {
        convera: bconvera,
        converb: bconverb,
      },
    };




    this.orqVenYearDefinido(vYear);
    this.orqVenYearDMes(vYear, dataFecha.mouth, dataFecha.dA.convera,  dataFecha.dA.converb );
    this.getVenCliMesPre(
      dataFecha.yeara,
      mouth,
      dataFecha.dA.convera,
      dataFecha.dA.converb,
      ''
    );
    this.graMesVenYear(vYear, mouth);
    this.graMesVenYearProducto(this.vYear, this.vMes);
    this.graMesVenYearCliente(this.vYear, this.vMes);
  }

  getWeek(vYear) {
    this.vRowGet = {};
    var mouth = moment().format('MM');

    var aconvera = moment().format(vYear + '-MM-01');
    var aconverb = moment().format(vYear + '-MM-') + moment().daysInMonth();
    var yeara = moment().format(vYear);
    var yearb = moment().subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + '-MM-01');
    var bconverb = moment().format(yearb + '-MM-') + moment().daysInMonth();
    this.selecm.setValue(mouth);
    this.vMes = mouth;
    this.grafCli = true;

    this.vRowGet.vYeara = vYear;
    this.vRowGet.vD = aconvera;
    this.vRowGet.vH = aconverb;
    this.vRowGet.vMes = mouth;

    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb,
      },
      dB: {
        convera: bconvera,
        converb: bconverb,
      },
    };
    return dataFecha;
  }

  semCombo(sem,vYear) {

    var vYeara = moment().format('YYYY');

    if (vYeara!=vYear) {

      sem=12;
      
    }


    let listSemana: any[] = [];
    for (let i = 1; i <= sem; i++) {
      listSemana.push({
        mesNumber: this.getFormaMes(i),
        mesNombre: this.getMes(i),
      });
    }
    this.listRango = listSemana.sort(function (a, b) {
      if (a.mesNumber < b.mesNumber) {
        return 1;
      }
      if (a.mesNumber > b.mesNumber) {
        return -1;
      }
      return 0;
    });
  }

  getMes(mes) {
    const MESES = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return MESES[mes];
  }

  randomGet() {
    let myColors = new Array(
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F'
    );
    const cutIn = 0;
    var myRandone = myColors[Math.floor(Math.random() * myColors.length)];
    var myRandtwo = myColors[Math.floor(Math.random() * myColors.length)];
    var myRandthree = myColors[Math.floor(Math.random() * myColors.length)];
    var myRandfour = myColors[Math.floor(Math.random() * myColors.length)];
    var myRandfive = myColors[Math.floor(Math.random() * myColors.length)];
    var myRandsix = myColors[Math.floor(Math.random() * myColors.length)];
    var sixDigitRandom =
      '#' +
      myRandone +
      myRandtwo +
      myRandthree +
      myRandfour +
      myRandfive +
      myRandsix;
    return sixDigitRandom;
  }

  getColor() {
    for (let i = 0; i < 30; i++) {
      this.pcolorScheme.domain.push(this.randomGet());
    }
  }

  getFormaMes(mes) {
    if (mes < 10) {
      return '0' + mes;
    } else {
      return mes;
    }
  }
}
