import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SalesService } from "../../../services/sales/sales.service";

@Component({
  selector: 'app-totalven',
  templateUrl: './totalven.component.html',
  styleUrls: ['./totalven.component.css']
})
export class TotalvenComponent implements OnInit {
  @Input() vyear: number;
  @Input() vpre: number;
  tven: any;





  constructor(
    private salesService: SalesService,

  ) { }

  ngOnInit(): void {
    this.getGraficaVentaYearCri(this.vyear);
  }

  getGraficaVentaYearCri(vYear) {
    this.salesService.getGraficaVentaYearCri({ vYear: String(vYear) }).subscribe(
      resp => {
    
        this.tven=resp[0].value;

   

      
      });
  }

 
  

}
