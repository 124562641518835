import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { VenMesAdminService } from "../../../services/sales/ven-mes-admin.service";
import { CustomerService } from "./../../../services/customer.service";


@Component({
  selector: 'app-panel-venta-mes-admin-detalle',
  templateUrl: './panel-venta-mes-admin-detalle.component.html',
  styleUrls: ['./../panel-venta-semana-admin-detalle/panel-venta-semana-admin-detalle.component.css']
})
export class PanelVentaMesAdminDetalleComponent implements OnInit {
  ci: string;
  mes: string;
  ye: string;
  convera;
  converb;
  dataResultgra: any;
  graf$: Observable<any[]>;
  vGrafica: any;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showDataLabel: boolean = false;
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'MXN';
  animations: boolean = true;
  legenda: string = "Leyenda";
  roundDomains: boolean = true;
  colorScheme = {
    domain: ['#76a7f4', '#26a116']
  };
  dataTer;
  noBarWhenZero: true;
  datagra = false;
  idd: any;

  showdetail: boolean=false;
  texts: string="Acumulado";


  constructor(
    private route: ActivatedRoute,
    private venMesAdminService: VenMesAdminService,
    private customerService: CustomerService

  ) {

    this.ci = this.route.snapshot.paramMap.get('id');
    this.mes = this.route.snapshot.paramMap.get('mes');
    this.ye = this.route.snapshot.paramMap.get('yeara');

  }

  ngOnInit(): void {
    this.getDataSubGrafica();
    this.dataTer = this.getWeek(this.ye, this.mes);
    this.getIdEpicorCli(this.ci);

  }

  showdetailF() {
    if (this.showdetail) {
      this.showdetail = false;
      this.texts="Acumulado";
    } else {
      this.showdetail = true;
      this.texts="Cerrar ";

    }


  }


  getIdEpicorCli(_id) {
    this.customerService.getIdEpicorCli({
      id_epicor: _id,

    }).subscribe((resp) => {
      this.idd=resp._id;

    });
  }

  getDataSubGrafica() {

    this.graf$ = this.venMesAdminService.getGraf$();
    this.graf$.subscribe(val => {
      this.dataResultgra = val[0];
      if (this.dataResultgra.length > 0) {
        this.datagra = true;
      }
    });
  }

  getWeek(yearas, mes) {

    var mouth = mes;
    var aconvera = moment().format(yearas + "-" + mes + "-01");
    var aconverb = moment(aconvera).format(yearas + "-" + mes + "-") + moment().daysInMonth();
    const yeara = moment().format('YYYY');
    const yearb = moment(yeara).subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + "-MM-01");
    var bconverb = moment().format(yearb + "-MM-") + moment().daysInMonth();



    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb
      },
      dB: {
        convera: bconvera,
        converb: bconverb
      }
    };




    return dataFecha;
  }


  getMes(mes) {
    const MESES = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return MESES[mes];
  }

  getFormaMes(mes) {
    if (mes < 10) {
      return "0" + mes;
    } else {
      return mes;
    }

  }


}
