import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pcrven',
  templateUrl: './pcrven.component.html',
  styleUrls: ['./pcrven.component.css']
})
export class PcrvenComponent implements OnInit {
  @Input() tVen: number;
  @Input() vYear: number;
  @Input() vpre: number;
  @Input() sf: string;
  
  cals: string;
  icc: string;

  value: number;
  ics: string;

  constructor() { }

  ngOnInit(): void {

    this.getCalPro(this.vYear,this.tVen,this.vpre);    
  }

  validateValue(value) {
    this.value = value;
    if (value <= 10) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";
    } else if ( value >10 && value < 50) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";

    } else if ( value >50 ) {
      this.cals = "gree";
      this.icc = "schedule";
      this.ics = "blink_me";
    }
  }

  verificarYear(vYear) {

    if (vYear != 2022) {
      this.cals = "bluer";
      this.icc = "hourglass_disabled";
      this.ics = "";
    }

  }


  getCalPro(vYear, vVen, vPre) {

        this.value = (vVen / vPre) * 100;
        this.validateValue(this.value);
        this.verificarYear(vYear);
  
  }


}
