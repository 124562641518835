
<div *ngIf="loading;else nodata" class="ct-data" >
    <div class="ct-sem-content">


        <div class="ct-{{getActivo(env.value)}} ct-items" *ngFor="let env of dataResult">
            <span class="ct-day"> {{diaSemana(env.name)}} </span>
            <span class="ct-mes"> {{mesSemana(env.name)}} </span>
            <mat-divider></mat-divider>
            <div class="ct-money tdc-monto-gv-{{getActMon(env.value)}} "> {{env.value | number: '1.2-2' }} <span
                    class="table-data-conte-moneda"> MXN </span> </div>
        </div>


        <div class="item"></div>
        <div class="item"></div>

    </div>
</div>
<ng-template #nodata>
    <div class="ct-model">
        <div class="items" *ngFor="let env of arrayWait">
           
                <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '142px',
            'height': '45px',
            'background-color': '#daddd8'
        }">
                </ngx-skeleton-loader>
          
        </div>
    </div>
</ng-template>
