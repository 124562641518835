import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './services/login.service';
import { Router } from "@angular/router";
import { map, take } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class AuthLoginGuard implements CanActivate {
  rola: any[] = [];
  privileges: any[] = [];
  constructor(
    private loginService: LoginService,
    private router: Router,
  ) { }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authorization = this.loginService.loginIn();
    let hasPermission;
    if (this.loginService.loginIn()) {
      this.privileges = route.data['privileges'];
      var token = localStorage.getItem('token');
      var varTo: { rol: string } = jwt_decode(token);
      var  permissionFound:any; 


      var roles = varTo.rol;


      if (roles) {
        var datal = Object.keys(roles).map(function (key) {
          let pair = [];
          var obj = roles[key].name;
          pair.push(obj);
          return pair;
        });
      }
      if (datal) {
        for (let l of datal) {
          this.rola.push(l[0]);
        }
      }

      if (this.privileges) {
        for (const checkPermission of this.rola) {
          permissionFound = this.privileges.find((p: string) => {
            return p.toUpperCase() === checkPermission.toUpperCase();
          });

          if (permissionFound) {
            hasPermission = true;
            break;
          }
        }

        if (!permissionFound) {
          this.router.navigate(['dashboard']);
          return false;
        }

      }
      return true;
    } else {

      this.router.navigate(['login']);
      return false;
    }





  }


}


