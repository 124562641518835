import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AddPresupuestoComponent } from "./add-presupuesto/add-presupuesto.component";
import { DelPresupuestoComponent } from "./del-presupuesto/del-presupuesto.component";
import { BudgetService } from "../../services/budget.service";
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css']
})
export class BudgetComponent implements OnInit {

  displayedColumns: string[] = ['an', 'monto', 'opciones'];
  dataSource = new MatTableDataSource<any>();
  obs = new BehaviorSubject<any>(null);
  total: number = 0;
  tca: number = 21.05;
  constructor(
    public datepipe: DatePipe,
    private dialog: MatDialog,

    private budgetService: BudgetService,
  ) { }

  ngOnInit() {
    this.getPreDecte();
  }

  getPreDecte() {
    this.budgetService.getPreDecte().subscribe(
      resp => {
        this.obs.next(resp);
      });
  }

  onDialogAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    this.dialog.open(AddPresupuestoComponent, dialogConfig);
  }

  montoValue(value) {
    const resultado = formatCurrency(value, 'en-US', getCurrencySymbol('USD', 'wide'));
    return resultado;
  }

  onDialogDel(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "40%";
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(DelPresupuestoComponent, dialogConfig);
  }


}
