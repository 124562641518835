import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GraficasService } from "./../../../services/graficas.service";
import { ComboCharttComponent, ComboSeriesVerticalComponent } from './../../budget/gra-com/index';
import * as moment from 'moment';
@Component({
  selector: 'app-grafica-top10',
  templateUrl: './grafica-top10.component.html',
  styleUrls: ['./grafica-top10.component.css']
})



export class GraficaTop10Component implements OnInit {



  g1: boolean = true;
  g2: boolean = false;
  loading: boolean = true;
  loadingb: boolean = false;
  displayedColumns: string[] = ['canal', 'value'];
  transactions = [];
  transactionsc = [];
  transactionsd = [];
  dataResultb: any[];
  dataResultc: any[];
  dataResultd: any[];
  vYear;
  vCliente: String;
  vName: String;
  //BAR GRAFICA B
  bview: any[] = [700, 380];
  bshowXAxis = true;
  bshowYAxis = true;
  bgradient = false;
  bshowLegend = false;
  bshowXAxisLabel = false;
  bxAxisLabel = '';
  blegendTitle = 'Cliente';
  bshowYAxisLabel = true;
  byAxisLabel = 'MXN';


  bcolorScheme = {
    domain: ['#FF1493', '#00BFFF', '#FFD700', '#1a73d9', '#D2B48C', '#800080', '#90EE90', '#e83f6f', '#B0E0E6', '#ef2917', '#CD853F', '#708090']
  };

  

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };

  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  breakpoint: number;
  colspa: number;
  colspb: number;
  vCli: any;
  constructor(public graficaService: GraficasService,) {
    this.vYear = this.getYeara();
  }

  ngOnInit() {
    this.renderWie();
    this.refreshga();
    this.refreshgb();

    this.getGraficaVentaTop10(this.vYear);

  }


  ngAfterViewInit() {

    this.transactions = [...this.transactions];

  }

  getYeara() {
    var date = new Date();
    return moment(date).format('YYYY');
  }

  refreshga() {
    this.graficaService.getGraficaTop10$.subscribe(() => {
      this.getGraficaVentaTop10(this.vYear);
    });
  }

  refreshgb() {
    this.graficaService.getGraficaTop10Cli$.subscribe(() => {
    });
  }

  getGraficaVentaTop10(vYear) {
    this.graficaService.getGraficaVentaTop10({ vYear: vYear }).subscribe(resp => {   
      this.dataResultb = resp;
      this.transactions = resp;
      this.loading = false;
    });
  }

  getTotalgb() {
    return this.transactions.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }

  updateAn(event) {
    this.vYear = event;
    this.graficaService.getGraficaTop10$.emit();
  }

  onSelectTopB(event) {
    this.loadingb = false;
    this.vName = event.name;
    this.vCli = event.extra.code
   


    // this.vCliente = event.extra.code;
    // this.graficaService.getGraficaTop10Cli$.emit();
    this.g1 = false;
    this.g2 = true;
  }

  onSelectTopC(event) {

  }

  resetDivGlobal() {
    this.g1 = true;
    this.g2 = false;
    this.vYear = "2022";
    this.graficaService.getGraficaTop10$.emit();
  }

  renderWie() {
    this.breakpoint = (window.innerWidth <= 450) ? 1 : 7;
    this.colspa = (window.innerWidth <= 450) ? 1 : 4;
    this.colspb = (window.innerWidth <= 450) ? 1 : 3;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 450) ? 1 : 7;
    this.colspa = (event.target.innerWidth <= 450) ? 1 : 4;
    this.colspb = (event.target.innerWidth <= 450) ? 1 : 3;
  }


}
