import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { UsuarioService } from '../../../services/usuario.service';
import { ChannelService } from '../../../services/channel.service';
import { timeStamp } from 'console';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DelCliListComponent } from './del-cli-list/del-cli-list.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-ch-list-grupo',
  templateUrl: './ch-list-grupo.component.html',
  styleUrls: ['./ch-list-grupo.component.css'],
})
export class ChListGrupoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  clienteF = new FormControl();
  grupoF = new FormControl();
  filteredOptionsCliente: Observable<string[]>;
  filteredOptionsGrupo: Observable<string[]>;
  aFormGroup: FormGroup;
  optionCliente: any[] = [];
  optionGrupo: any[] = [];
  vGr: any;
  vCli: any;
  displayedColumns: string[] = ['ima', 'cod', 'nombre', 'opciones'];
  dataSource = new MatTableDataSource<any>();
  show: boolean;
  constructor(
    private customerService: CustomerService,
    private usuarioService: UsuarioService,
    private channelService: ChannelService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.channelService.chCliList$.subscribe((id) => {
      this.getAllGrupoId(id);
    });
    this.getAllClientes();
    this.getAllGrupo();
    this.filteredOptionsCliente = this.clienteF.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCliente(value))
    );
    this.filteredOptionsGrupo = this.grupoF.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterGrupo(value))
    );
  }

  _filterCliente(value: string) {
    if (value)
      return this.optionCliente.filter((optionCliente) =>
        optionCliente.razon_social.toLowerCase().includes(value.toLowerCase())
      );
  }

  _filterGrupo(value: string) {
    if (value)
      return this.optionGrupo.filter((optionGrupo) =>
        optionGrupo.nombre.toLowerCase().includes(value.toLowerCase())
      );
  }

  getAllClientes() {
    this.customerService.getAllClientes().subscribe((resp) => {
      this.optionCliente = resp.clientes;
    });
  }

  getAllGrupo() {
    this.channelService.getAllGrupo().subscribe((resp) => {
      this.optionGrupo = resp;
    });
  }

  getAllGrupoId(vGr) {
    this.channelService.getAllGrupoId({ vGr: vGr }).subscribe((resp) => {

      if (resp.length > 0) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.dataSource.data = resp;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  setGrupo(_id) {
    this.vGr = _id;
    this.getAllGrupoId(_id);
  }
  setCliente(_id) {
    this.vCli = _id;
  }

  add() {
    this.channelService
      .regGrupoCli({ _id_grupo: this.vGr, _id_cliente: this.vCli })
      .subscribe((resp) => {
        this.channelService.chCliList$.emit(this.vGr);
      });

    this.clienteF.reset();

  }

  onDialogDel(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(DelCliListComponent, dialogConfig);
  }






  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
