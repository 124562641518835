

<div class="ct-panel" *ngFor="let env of obs | async">
    <mat-accordion class="ct-row-producto">

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="ct-row-header">
                <mat-panel-title>
                    <div class="ct-model">
                        <div class="item ima-cli">
                            <app-ima-client-id [idcliente]="env._id"></app-ima-client-id>
                       
                        </div>
                        <div class="item name-cli">
                            <span class="idepc"> {{env.id_epicor}} </span><br>
                            {{env.nombre}}<br>
                            <app-sales-num-pro [vCli]="vCli" [vD]="vD" [vH]="vH"  [vT]="vT" [vCh]="vCh"></app-sales-num-pro>
                        </div>

                        <div class="item name-a">
                            <span class="denon">VEN</span> {{env.venta_uds | number: '1.2-2' }} <span
                                class="denom">USD</span>
                        </div>

                        <div class="item name-a">
                            <span class="denon">VEN</span> {{env.venta | number: '1.2-2' }} <span
                                class="denom">MXN</span>
                        </div>
                    </div>
                </mat-panel-title>

            </mat-expansion-panel-header>
            <app-list-search-pro [vCli]="vCli" [vD]="vD"  [vH]="vH" [vT]="vT" [vCh]="vCh" ></app-list-search-pro>
        </mat-expansion-panel>
    </mat-accordion>
</div>