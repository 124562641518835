<form [formGroup]="formSmsSend">
    <div class="container-sms-send">
        <div class="css-it">
            <div class="btn-plu">
                <mat-icon class="btn-fico">add</mat-icon>
            </div>
        </div>
        <div>
            <div class="inputMsjMar">
                <input placeholder="Escribe algo ..." class="inputMsj" matInput formControlName="mensaje" autocomplete="off" type="text" />
            </div>
        </div>
        <div class="css-it">
            <div class="btn-add" (click)="add()">
                <mat-icon class="btn-fica">send</mat-icon>
            </div>
        </div>
    </div>
</form>