import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-sales-channel-generico',
  templateUrl: './sales-channel-generico.component.html',
  styleUrls: ['./sales-channel-generico.component.css'],
})
export class SalesChannelGenericoComponent implements OnInit {
  vCh: string;
  vUni: string;
  criterioCh: FormGroup;
  criteriovUni: FormGroup;
  listRango: any = [];
  vYear: any;
  vYearRemo: string;

  constructor(
    private route: ActivatedRoute,

    private _formBuilder: FormBuilder
  ) {
    this.vCh = this.route.snapshot.paramMap.get('vCh');
    this.vYearRemo = this.route.snapshot.paramMap.get('vYear');
  }

  ngOnInit(): void {
    const vY = this.valYearActual();
    this.vYear =  this.vYearRemo  ? this.vYearRemo : vY ;


    this.vUni = '5fa08bbbb839ec17210f432d';
    this.setList();
    this.crearFormCh(this.vYear);
  }

  updateCh() {
    this.vYear = this.criterioCh.controls.canal.value;
  }



  valYearActual() {
    const yeara = moment().format('YYYY');
    return yeara;
  }
  crearFormCh(vY) {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(Number(vY)),
    });

    this.criteriovUni = this._formBuilder.group({
      unidads: new FormControl('5fa08bbbb839ec17210f432d'),
    });
  }

  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }
}
