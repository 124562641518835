import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { ChannelService } from '../../../../services/channel.service';
import { VenMesAdminService } from '../../../../services/sales/ven-mes-admin.service';
@Component({
  selector: 'app-scg-list-cli',
  templateUrl: './scg-list-cli.component.html',
  styleUrls: ['./scg-list-cli.component.css'],
})
export class ScgListCliComponent implements OnInit {
  @Input() vCh: string;
  @Input() vYear: string;
  show: boolean;
  obsCli = new BehaviorSubject<any>(null);
  ss1: boolean;
  constructor(private channelService: ChannelService) {}

  ngOnChanges(changes: any) {
    this.show = false;
    const nvYear = changes.vYear.currentValue;
    this.getAllCanalGrupoId(this.vCh, String(nvYear));
  }

  ngOnInit(): void {
    this.show = false;
    this.ss1 = true;
  }

  getAllCanalGrupoId(vGr, vYear) {
    this.channelService
      .getAllCanalGrupoId({ vGr: vGr, vYear: vYear })
      .subscribe((resp) => {
        this.obsCli.next(resp);

        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }
        this.show = true;
      });
  }
}
