import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { StarRatingComponent } from 'ng-starrating';
import { BehaviorSubject } from 'rxjs';
import { AreaService } from 'src/app/services/area.service';

export interface DialogData {
  vTa: string;
  vAr: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-task-up',
  templateUrl: './task-up.component.html',
  styleUrls: ['./task-up.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TaskUpComponent implements OnInit {
  obsCh = new BehaviorSubject<any>(null);
  formPurchaseOrder: FormGroup;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  obsAllSta = new BehaviorSubject<any>(null);
  miAr = [];
  raking: number;
  obsArea: any;
  hola: FormGroup;
  minDate: moment.Moment;
  vTa: any;
  vAr: any;
  proVetor: any;
  cit: any;
  boxge: boolean;
  toppingList: any;
  jose: any;
  repreNew: any;
  t2: boolean;
  t5: boolean;
  t4: boolean;
  t3: boolean;

  constructor(
    private areaService: AreaService,
    public dialogRef: MatDialogRef<TaskUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.vTa = this.data.vTa;
    this.vAr = this.data.vAr;
  }

  ngOnInit(): void {
    this.boxge = true;

    this.getTaskAllStatus();
    this.createForm();
    this.getMiembrosArea(this.vAr);
    this.getCiTaskUltimate(this.vAr);
    this.getTaskId(this.vTa);
    this.getTaskAreaId(this.vAr);
    this.getTaskDependiene(this.vAr, this.vTa);

    // this.beforeIndepe();
  }

  getCiTaskUltimate(vAr) {
    this.areaService.getCiTaskUltimate({ vAr: vAr }).subscribe((resp) => {
      this.obsArea = resp.cod_interno;
    });
  }

  getTaskAreaId(vAr) {
    this.areaService.getTaskAreaId({ vAr: vAr }).subscribe((resp) => {
      this.toppingList = resp;
    });
  }

  beforeIndepe() {
    let languageID = ['62ee9bf63f277532b44911a3'];

    this.formPurchaseOrder.patchValue({
      pre: languageID,
    });
  }

  getTaskDependiene(vAr, vTa) {
    this.areaService
      .getTaskDependiene({ vAr: vAr, vTs: vTa })
      .subscribe((resp) => {
        this.repreNew = resp;


        this.formPurchaseOrder.patchValue({
          pre: resp,
        });

      });
  }

  updateCh() {}

  getDate(value) {
    return moment(value);
  }

  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    this.raking = $event.newValue;
  }

  createForm() {
    this.formPurchaseOrder = new FormGroup({
      tarea: new FormControl('', [Validators.required]),
      des: new FormControl('', [Validators.required]),
      responsable: new FormControl('', [Validators.required]),
      status_acr: new FormControl('', [Validators.required]),
      colora: new FormControl(this.generarNuevoColor(), [Validators.required]),
      pre: new FormControl(),
    });
  }

  ptakOff() {
    this.areaService.cBeem$.emit(false);
  }

  getTaskAllStatus() {
    this.areaService.getTaskAllStatus().subscribe((resp) => {
      this.obsAllSta.next(resp);

      this.t2=true;
    });
  }

  add() {
    if (this.formPurchaseOrder.valid) {
      this.boxge = false;

      let date = this.range.value;
      const tarea = this.formPurchaseOrder.controls.tarea.value;
      const des = this.formPurchaseOrder.controls.des.value;
      const responsable = this.formPurchaseOrder.controls.responsable.value;
      const prioridad = this.raking;
      const fecha_inicio = date.start;
      const fecha_fin = date.end;
      const color = this.formPurchaseOrder.controls.colora.value;
      const status_acr = this.formPurchaseOrder.controls.status_acr.value;
      const pre = this.formPurchaseOrder.controls.pre.value;

      let newObj = {
        _id_area: this.vAr,
        _id: this.vTa,
        cit: this.cit,
        nombre: tarea,
        des: des,
        responsable: responsable,
        fecha_star: fecha_inicio,
        fecha_end: fecha_fin,
        prioridad: prioridad,
        color: color,
        _id_status: status_acr,
        dias: this.diasVentorGet(fecha_inicio, fecha_fin),
      };

      if (pre) {
        newObj['pre'] = pre;
      }

      this.areaService.getTaskUpdate(newObj).subscribe((resp) => {
        this.areaService.cBeem$.emit(false);
        this.areaService.oTask$.emit();

        this.onClose();
      });
    } else {
    }
  }

  updateInfo() {
    let color = this.generarNuevoColor();
    this.formPurchaseOrder.controls.colora.setValue(color);
    this.getCiTaskUltimate(this.vAr);
  }

  getMiembrosArea(vAr) {
    this.areaService.getMiembrosArea({ vAr: vAr }).subscribe((resp) => {
      this.miAr = resp;
      this.t3=true;

    });
  }

  getTaskId(vTa) {
    this.areaService.getTaskId({ vTa: vTa }).subscribe((resp) => {
      this.range.setValue({
        start: resp[0].fecha_star,
        end: resp[0].fecha_end,
      });

      this.proVetor = resp[0].prioridad;
      this.raking = resp[0].prioridad;
      this.cit = resp[0].cit;

      this.formPurchaseOrder.patchValue({
        tarea: resp[0].nombre,
        des: resp[0].des,
        cit: resp[0].cit,
        responsable: resp[0].responsable,
        status_acr: resp[0]._id_status,
        prioridad: resp[0].prioridad,
        colora: resp[0].color,
      });
      this.t4=true;

    });
  }

  generarNuevoColor() {
    const minL = 0.75;
    const maxL = 0.9;
    const interval = 3000;
    const colors = {
      bgColor: '',
      txtColor: '',
      btnColor: '',
      btnFocus: '',
    };

    const hBase = Math.random();
    const newH = Math.floor(hBase * 360);
    const newL = Math.floor(Math.random() * 16) + 75;

    colors.bgColor = `hsl(${newH}, 100%, ${newL}%)`;
    colors.txtColor = `hsl(${newH}, 100%, 5%)`;
    colors.btnColor = `hsl(${newH}, 100%, 98%)`;
    colors.btnFocus = `hsl(${newH}, 100%, 95%)`;

    const [r, g, b] = this.HSLtoRGB(hBase, 1, newL * 0.01);

    // update css vars
    Object.entries(colors).forEach(([k, v]) => {
      document.documentElement.style.setProperty(`--${k}`, `${v}`);
    });

    return this.RGBtoHex(r, g, b);
  }

  HSLtoRGB(h, s, l) {
    let r, g, b;

    const rd = (a) => {
      return Math.floor(Math.max(Math.min(a * 256, 255), 0));
    };

    const hueToRGB = (m, n, o) => {
      if (o < 0) o += 1;
      if (o > 1) o -= 1;
      if (o < 1 / 6) return m + (n - m) * 6 * o;
      if (o < 1 / 2) return n;
      if (o < 2 / 3) return m + (n - m) * (2 / 3 - o) * 6;
      return m;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRGB(p, q, h + 1 / 3);
    g = hueToRGB(p, q, h);
    b = hueToRGB(p, q, h - 1 / 3);

    return [rd(r), rd(g), rd(b)];
  }

  RGBtoHex(r, g, b) {
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  }

  onClose() {
    this.dialogRef.close();
  }

  diasVentorGet(a, b) {
    var dia_actual = moment(a);
    var i = 0;
    while (dia_actual.isSameOrBefore(b)) {
      if (
        moment(dia_actual).format('dddd') != 'Saturday' &&
        moment(dia_actual).format('dddd') != 'Sunday'
      ) {
        i++;
      }

      dia_actual.add(1, 'days');
    }
    return i;
  }

  evaPre(evaPre) {
    this.range.setValue({
      start: null,
      end: null,
    });

    if (evaPre.length > 0) {
      let verfificaDate;
      this.areaService
        .getStarEndDateTasks({ vPre: evaPre })
        .subscribe((resp) => {
          let afte = resp.fecha_end;

          if (moment(afte).format('dddd') == 'Saturday') {
            verfificaDate = moment(afte).add(2, 'days');
          } else if (moment(afte).format('dddd') == 'Sunday') {
            verfificaDate = moment(afte).add(1, 'days');
          } else {
            verfificaDate = afte;
          }

          this.range.setValue({
            start: verfificaDate,
            end: null,
          });

 

        });
    } else {
      this.range.setValue({
        start: null,
        end: null,
      });
    }
  }
}
