import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-car-ven-mes',
  templateUrl: './car-ven-mes.component.html',
  styleUrls: ['./car-ven-mes.component.css']
})
export class CarVenMesComponent implements OnInit {
  @Input() env: any;
  @Input() vRowGet: any;
  @Input() vNum: any;
  

  mats: boolean = false;
  _id_epicor: any;
  _id_cliente: any;
  name_cli: any;
  vMes;
  vYeara;
  vendedor;


  series = new BehaviorSubject<any>(null);
  total: number;
  constructor() { }

  ngOnInit(): void {


    this.vMes = this.vRowGet.vMes;
    this.vYeara = this.vRowGet.vYeara;
    this._id_epicor = this.env._id_epicor;
    this.vendedor = this.env.vendedor;
    this._id_cliente = this.env._id_cliente;
    this.name_cli = this.env.name;
    this.series.next(this.env.series);
  }

  goToLink(id, mes, yeara) {

    window.open('/ventas_detalles_mes/' + id + '/' + mes + '/' + yeara, '_blank');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mats = true;
    }, 2000)
  }

  getValueDat(value) {

    var objValue;
    if (value === undefined || value === null) {
      objValue = 0;
    } else if (Array.isArray(value) && value.length === 0) {
      objValue = 0;
    }
    else {
      objValue = Number(value);
    }
    return objValue;
  }

  getValuePro(datArray) {
    var lven = datArray.filter(element => element.extra.tipo === "VEN");
    var lpre = datArray.filter(element => element.extra.tipo === "PRE");
    var vPre = lpre[0].value;
    var vVen = lven[0].value;
    if (vPre === undefined || vPre === null) {
      return 0;
    }
    else if (Array.isArray(vPre) && vPre.length === 0) {
      return 0;
    }
    else {
      this.total = (vVen / vPre) * 100;
    }
  }

  getVentaDetalle(_id_epicor, sem, yeara) {
  }

  getFormaMes(mes) {
    if (mes<10) {
      return "0" + mes;
    } else {
      return mes;
    }
  }


}
