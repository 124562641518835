import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EnviosService } from "../../../services/envios.service";

@Component({
  selector: 'app-panel-admin-envios-detalle',
  templateUrl: './panel-admin-envios-detalle.component.html',
  styleUrls: ['./panel-admin-envios-detalle.component.css']
})
export class PanelAdminEnviosDetalleComponent implements OnInit {
  private _id_envio: string;
  private op: string;
  loading: boolean;
  panelOpenState = true;

  obs = new BehaviorSubject<any>(null);
  ci: string;
  date: Date;
  constructor(
    private route: ActivatedRoute,
    private enviosService: EnviosService,
    public datepipe: DatePipe,
  ) {
    this.ci = this.route.snapshot.paramMap.get('id');
    this.op = this.route.snapshot.paramMap.get('op');
    this.getAllEnvioCriDet(this.ci, this.op);
  }

  ngOnInit(): void {
  }

  getAllEnvioCriDet(ci, op) {
    this.enviosService.getAllEnvioCriDet({ ci: ci, op: op }).subscribe(
      resp => {
        this.obs.next(resp);
        if (resp.length > 0) {
        }
        this.loading = false;
      });
    this.loading = false;
  }

  formatDate(fecha) {
    return fecha.substr(0,10);
  }

  formatDateFull(fecha) {
    this.date = new Date();
    let latest_date = this.datepipe.transform(fecha, 'yyyy-MM-dd HH:mm:ss');
    return latest_date;
  }

  formatRazonSocial(val) {
    var rzo: String;
    if (val.length > 50) {
      rzo = val.substr(0, 50) + "...";
    } else {
      rzo = val;
    }
    return rzo;
  }

  onDelAsig(_id, op, ci, _id_cliente) {
    this.enviosService.rmdelallEnvio({ _id: _id, op: op, ci: ci, _id_cliente: _id_cliente }).subscribe(
      resp => {
        this.enviosService.envlcg$.emit();
      });
  }


}
