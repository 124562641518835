import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { RolesService } from "./../../../../services/roles.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-rol',
  templateUrl: './add-rol.component.html',
  styleUrls: ['./add-rol.component.css']
})
export class AddRolComponent implements OnInit {
  formMa: FormGroup;
  newRol: { name: any; descripcion: any; };
  constructor(
    public dialogRef: MatDialogRef<AddRolComponent>,
    private rolService: RolesService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,

  ) { }

  ngOnInit() {

    this.newForm();

  }



  newForm() {

    this.formMa = this._formBuilder.group({
      name: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      
    });
  }

  registro() {
    const name = this.formMa.get('name').value;
    const descripcion = this.formMa.get('descripcion').value;
   
    this.newRol = {
      name: name,
      descripcion: descripcion,

    }

    this.rolService.regRol(this.newRol)
    .subscribe(
      res => {
        this.openSnackBar(res.status, 'ok');
        this.rolService.rolSer$.emit();
        this.onClose();
      });



  }


  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }


  onClose() {
    this.dialogRef.close();
  }


}
