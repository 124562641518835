import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-success-data',
  templateUrl: './success-data.component.html',
  styleUrls: ['./success-data.component.css',],
  encapsulation: ViewEncapsulation.Emulated
})
export class SuccessDataComponent implements OnInit {
  @Input() mensaje: String;
  @Input() pd: number;
  constructor() { }

  ngOnInit(): void {
  }

}
