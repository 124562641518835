import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { UsuarioService } from './../../../services/usuario.service';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {}

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-up-usuarios',
  templateUrl: './up-usuarios.component.html',
  styleUrls: ['./up-usuarios.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UpUsuariosComponent implements OnInit {
  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;
  file: File;
  ima64: String | ArrayBuffer;
  ima64Decode: String | ArrayBuffer;
  photoSelected: String | ArrayBuffer;
  newUsuario = {};
  hide = true;
  date = new FormControl(moment(), [Validators.required]);
  nombre = new FormControl('', [Validators.required]);
  apellido = new FormControl('', [Validators.required]);
  telefono = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  clave = new FormControl('', [Validators.required]);
  dp = new FormControl('', [Validators.required]);
  cod_epicor = new FormControl('', [Validators.required]);
  rol = new FormControl('', [Validators.required]);

  local_data: any;

  gid: String;
  gcod_epicor: String;
  gnombre: String;
  gapellido: String;
  gtelefono: String;
  gemail: String;
  gclave: String;
  gdate: Date;
  gimagen: String;
  grol: String;
  dataRoles: any;
  arrayRoles: any = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public usuarioService: UsuarioService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,

    public dialogRef: MatDialogRef<UpUsuariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.local_data = this.data;
    this.gid = this.local_data.data._id;
    this.gcod_epicor = this.local_data.data.cod_epicor;
    this.gnombre = this.local_data.data.nombre;
    this.gapellido = this.local_data.data.apellido;
    this.gtelefono = this.local_data.data.telefono;
    this.gemail = this.local_data.data.email;
    // this.gclave = this.local_data.data.clave;
    this.gdate = this.local_data.data.fecha_n;
    this.gimagen = this.local_data.data.imagen;
    this.grol = this.local_data.data.rol;
  }

  ngOnInit() {
    this.rol.setValue(this.grol);

    this.form = this._formBuilder.group({
      cod_epicor: [this.gcod_epicor, Validators.required],
      nombre: [this.gnombre, Validators.required],
      apellido: [this.gapellido, Validators.required],
      telefono: [this.gtelefono, Validators.required],
      email: [this.gemail, Validators.email],
      // clave: [this.gclave, Validators.required],
      date: [this.gdate, Validators.required],
      ima: [this.gimagen],
    });
    this.decodeBase64(this.gimagen);

    this.getAllRoles();
  }

  getValues(event) {
    const valor = event.source.value;
    this.arrayRoles.push(valor);


  }

  up() {
    if (this.form.valid) {
      const nombre = this.form.get('nombre').value;
      const apellido = this.form.get('apellido').value;
      const telefono = this.form.get('telefono').value;
      const email = this.form.get('email').value;
 
      const cod_epicor = this.form.get('cod_epicor').value;
      const rol = this.arrayRoles;
      const date = _moment(this.form.get('date').value).format('YYYY-MM-DD');

      this.newUsuario = {
        _id: this.gid,
        email: email,
        nombre: nombre,
        apellido: apellido,
        telefono: telefono,
        date: date,
        imagen: this.ima64,
        fecha_n: date,
        rol: this.rol.value,
        cod_epicor: cod_epicor,
      };


    

      this.usuarioService.updateUsuario(this.newUsuario).subscribe(
        (res) => {
          this.onClose();
          this.openSnackBar(res.status, 'ok');
          this.usuarioService.usuarioSer$.emit();
        },
        (err) => {
          this.openSnackBar(err.error.error, 'alert');
        }
      );
    } else {
    }
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  decodeBase64(ima64Endode) {
    this.photoSelected = ima64Endode;
  }
  getErrorMessageNombre() {
    if (this.nombre.hasError('required')) {
      return 'Por favor, ingresa tu nombre principal';
    }
  }

  getErrorMessageApellido() {
    if (this.apellido.hasError('required')) {
      return 'Por favor ingresa tu apellido';
    }
  }

  getErrorMessageTelefono() {
    if (this.telefono.hasError('required')) {
      return 'Por favor ingresa tu telefono';
    }
  }

  getErrorMessageEmail() {
    if (this.email.hasError('required')) {
      return 'Por favor ingresa un correo valido';
    }
    return this.email.hasError('email')
      ? 'Por favor ingresa un correo valido'
      : '';
  }

  getErrorMessageClave() {
    if (this.clave.hasError('required')) {
      return 'Por favor, elige una contraseña';
    }
  }

  getErrorMessageDate() {
    if (this.date.hasError('required')) {
      return 'Por favor, indicanos tu fecha de nacimiento';
    }
  }

  getErrorMessageDp() {
    if (this.dp.hasError('required')) {
      return 'Por favor, indicanos tu fecha de nacimiento';
    }
  }

  getErrorMessageCod() {
    if (this.cod_epicor.hasError('required')) {
      return 'Por favor, indicanos tu cod en EPICOR';
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenDayHandler(normalizedDay: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.day(normalizedDay.day());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  chosenMonthHandler(normalizedMonth: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
  }

  onFileSelected(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file = <File>event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.photoSelected = reader.result);
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.ima64 = reader.result;
        this.photoSelected = reader.result;
      };
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getAllRoles() {
    this.usuarioService.getAllRoles().subscribe((resp) => {
      this.dataRoles = resp;
    });
  }
}
