<div class="conte-modal">
  <div class="titulo-row-b">
    Registro nuevo rol</div>

  <mat-divider></mat-divider>
  <br>
  <form [formGroup]="formMa" method="post" (ngSubmit)="registro();">
    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Nombre</mat-label>
      <input matInput formControlName="name" required autocomplete="off">
    </mat-form-field>
    <br>

    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Descripcion</mat-label>
      <input matInput formControlName="descripcion" autocomplete="off" required>
    </mat-form-field>
    <br>


    <div class="btn-enviar">
      <button class="btn-agregar" mat-raised-button color="primary">
        Registrar
      </button>
    </div>

  </form>
</div>