import { Component, OnInit } from '@angular/core';
import { SalesService } from "../../../services/sales/sales.service";
import { BudgetService } from "../../../services/budget.service";


@Component({
  selector: 'app-gra-gru-mes',
  templateUrl: './gra-gru-mes.component.html',
  styleUrls: ['./gra-gru-mes.component.css']
})
export class GraGruMesComponent implements OnInit {
  view = [500, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  legendTitle = '';
  legendPosition = 'below';
  showXAxisLabel = false;
  yRightTickFormat = false;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  yAxisLabel = 'PRE 2022';
  showGridLines = false;
  innerPadding = '10%';
  maxXAxisTickLength = 4;
  animations: boolean = true;
  barChart: any;
  linChart: any;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'ordinal',
    // domain: ['#3498DB'],
    domain: ['#3498DB'],

  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#3498DB'],
  };

  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = 'Utilization';
  g: boolean = false;
  d: boolean = false;
  wiat: any[] = [];

  constructor(
    private salesService: SalesService,
    private budgetService: BudgetService
  ) {  }

  ngOnInit(): void {
    this.masterOrqPreMes();
    this.getAllPreYearMes();
    this.generateRandomInt();
  }

  getAllPreYearMes() {
    this.budgetService.getAllPreYearMes({ vYear: 2022, tc:this.getCamb(2022) }).subscribe(
      resp => {
        this.barChart = resp;
        
        if (resp.length > 0) {
          this.g = true;
        }


        this.barChart= [... this.barChart];

      });
  }

  masterOrqPreMes() {
    this.salesService.masterOrqPreMes().subscribe(
      resp => {
        this.linChart = resp;
        if (resp.length > 0) {
          this.d = true;
        }

        this.linChart= [... this.linChart];
      });
  }

  generateRandomInt() {
    for (let i = 0; i < 11; i++) {
      this.wiat.push({
        'border-radius': '5px',
        width: '40px',
        'padding': '7px',
        'margin': '7px',
        'height': Math.floor((Math.random() * (250 - 20)) + 20) + 'px',
        'background-color': '#daddd8'
      });
    }
    return this.wiat;
  }

  getCamb(valor) {
    let cambio =
    {
      2017: 22.04,
      2018: 20.96,
      2019: 20.26,
      2020: 25.75,
      2021: 22.15,
      2022: 21.46
    };
    
    return cambio[valor];
  }
}
