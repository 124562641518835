import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "../../../services/sales/sales.service";

@Component({
  selector: 'app-s-ven-pre',
  templateUrl: './s-ven-pre.component.html',
  styleUrls: ['./s-ven-pre.component.css']
})
export class SVenPreComponent implements OnInit {
  @Input() vYear: number;
  @Input() env: Object;
  @Input() vPre: number;
  obs = new BehaviorSubject<any>(null);
  total = new BehaviorSubject<any>(null);
  tVen: any;
  dataGra: any[] = [];
  mats = false;
  // options
  showXAxis: boolean = false;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  roundDomains: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';

  customColors;

  colorScheme = {
    domain: ['#3498DB', '#26a116']
  };
  constructor(
    private salesService: SalesService
  ) { }

  ngOnInit(): void {
    this.getVenYearCanalMaster(this.vYear);
  }


  getVenYearCanalMaster(vYear) {
    let series = [];
    this.salesService.getVenYearCanalMaster({ vYear: vYear }).subscribe((resp) => {
      
      this.obs.next(resp);
      const sumall = resp.map(item => item.value).reduce((prev, curr) => prev + curr, 0);
      this.total.next(sumall);
      this.tVen = sumall;
      let dar = resp.map((val) => {
        series.push(val
        );
        return val;
      });
      this.dataGra.push(
        {
          name: "VEN ",
          series: dar
        },
      );
      this.mats = true;
      this.dataGra = [...this.dataGra];
    });
  }

getNameMatriz(name){
  var nombreCanal = name.split(" ", 3); 

  return nombreCanal[0]
}



goToLinkGenerico(_id_canal) {

  
  window.open('/sales_channel_generico/'+_id_canal+'/'+this.vYear, '_blank');
}



goToLink(yeara) {
  window.open('/sales_detail/'+yeara, '_blank');
}



}
