
    <div class="ct-ti-b">
        <div> <button mat-icon-button>
                <mat-icon>navigate_before</mat-icon>
            </button></div>
        <div> Nueva tarea</div>
        <div class="ct-ti-b-s"> <span class="ct-bt-c" (click)="ptakOff()">Cancelar</span></div>
    </div>

<mat-divider></mat-divider>
<form [formGroup]="formPurchaseOrder">
    <div class="ct-for">

        <div>
            <mat-form-field class="in-for">
                <mat-label>Tarea</mat-label>
                <input matInput formControlName="tarea" autocomplete="off" type="text" />
                <mat-error *ngIf="formPurchaseOrder.controls.tarea.hasError('required')">Nombre de la tarea requerida
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="in-for">
                <mat-label>Responsable</mat-label>
                <mat-select (selectionChange)="updateCh()" formControlName="responsable">
                    <mat-option *ngFor="let cl of miAr" [value]="cl._id"> {{cl.nombre}} {{cl.apellido}} </mat-option>
                </mat-select>
                <mat-error *ngIf="formPurchaseOrder.controls.responsable.hasError('required')">Responsable requerid@
                </mat-error>
            </mat-form-field>
        </div>
        <div class="ct-pri-con">


            <mat-form-field class="in-for">
                <mat-label>Prelación:</mat-label>
                <mat-select formControlName="pre" (selectionChange)="evaPre($event.value)" multiple>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping._id_pre">{{topping.cit}} -
                        {{topping.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div>
            <mat-form-field class="in-for">
                <mat-label>Rango Fecha:</mat-label>

                <mat-date-range-input [formGroup]="range"  [rangePicker]="picker" [min]="minDate"   [dateFilter]="rangeFilter">
                    <input matStartDate formControlName="start" placeholder="Desde"  (dateChange)="exeR($event)">
                    <input matEndDate formControlName="end" placeholder="Hasta" (dateChange)="exeR($event)">
                </mat-date-range-input>

                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                </mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>

            </mat-form-field>

            <div class="divErr" *ngIf="getError">{{getText}}
            </div>

        </div>



    </div>

    <div class="ct-for-b">
        <div>
            <mat-form-field class="in-for-des">
                <mat-label>Descripción</mat-label>
                <input matInput formControlName="des" autocomplete="off" type="text" />
                <mat-error *ngIf="formPurchaseOrder.controls.responsable.hasError('required')">Descripción requerida
                </mat-error>
            </mat-form-field>
        </div>

        <!-- <div class="ct-bt-s">
            <mat-form-field class="mat-form-field-filtro">
                <mat-label>Estado</mat-label>
                <mat-select (selectionChange)="updateCh()" formControlName="status_acr">
                    <mat-option *ngFor="let sl of obsAllSta | async" [value]="sl._id"> {{sl.nam}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->


        <div class="ct-pri-con">





            <span class="ct-pri">Prioridad</span> <br>
            <star-rating value="0" [totalstars]='5' checkedcolor="gold" uncheckedcolor="gray" size="24px"
                readonly="false" (rate)="onRate($event)"></star-rating>








            <!-- <mat-form-field class="in-for">
                <mat-label>Prelación:</mat-label>
                <mat-select formControlName="pre" multiple>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping._id">{{topping.cit}} -
                        {{topping.nombre}}</mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>

        <div class="ct-bt-s">
            <mat-form-field class="cfb-color">
                <mat-label>Color:</mat-label>
                <input matInput formControlName="colora" autocomplete="off" type="color" />
            </mat-form-field>
        </div>
        <div class="ct-bt">
            <button (click)="add();" class="btn" color="primary" mat-raised-button mat-button>
                <mat-icon matListIcon>add</mat-icon>
            </button>
        </div>
    </div>


</form>

<mat-divider></mat-divider>