import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../global';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private URL = GlobalVariable.BASE_API_URL;
  public listItem$ = new EventEmitter<any>();
  constructor(private http: HttpClient, private router: Router) {}
  public oArea$ = new EventEmitter<any>();
  public cBeem$ = new EventEmitter<any>();
  public oTask$ = new EventEmitter<any>();
  public oTaskSchemen$ = new EventEmitter<any>();


  

  getCodInterno() {
    return this.http.get<any>(this.URL + 'getCodInternoOC');
  }

  addArea(data) {
    return this.http.post<any>(this.URL + 'addArea', data);
  }

  delArea(data) {
    return this.http.post<any>(this.URL + 'delArea', data);
  }

  addTask(data) {
    return this.http.post<any>(this.URL + 'addTask', data);
  }

  getAllArea() {
    return this.http.get<any>(this.URL + 'getAllArea');
  }

  getCiTask() {
    return this.http.get<any>(this.URL + 'getCiTask');
  }

  getAreaId(data) {
    return this.http.post<any>(this.URL + 'getAreaId', data);
  }

  getMiembrosArea(data) {
    return this.http.post<any>(this.URL + 'getMiembrosArea', data);
  }

  getMiembrosGen() {
    return this.http.get<any>(this.URL + 'getMiembrosGen');
  }

  getTaskAreaId(data) {
    return this.http.post<any>(this.URL + 'getTaskAreaId', data);
  }

  delTask(data) {
    return this.http.post<any>(this.URL + 'delTask', data);
  }

  getAllTaskAreaId(data) {
    return this.http.post<any>(this.URL + 'getAllTaskAreaId', data);
  }

  getCiTaskUltimate(data) {
    return this.http.post<any>(this.URL + 'getCiTaskUltimate', data);
  }

  getTaskLienzo(data) {
    return this.http.post<any>(this.URL + 'getTaskLienzo', data);
  }

  getTaskId(data) {
    return this.http.post<any>(this.URL + 'getTaskId', data);
  }

  getTaskUpdate(data) {
    return this.http.post<any>(this.URL + 'getTaskUpdate', data);
  }

  getTaskAllStatus() {
    return this.http.get<any>(this.URL + 'getTaskAllStatus');
  }


  getTasKCountTer(data) {
    return this.http.post<any>(this.URL + 'getTasKCountTer', data);
  }

  getTasKCount(data) {
    return this.http.post<any>(this.URL + 'getTasKCount',data);
  }


  getTaskAllShchemes() {
    return this.http.get<any>(this.URL + 'getTaskAllShchemes');
  }


  getTaskAllShchemesId(data) {
    return this.http.post<any>(this.URL + 'getTaskAllShchemesId',data);
  }


  addTaskShchemeMicro(data) {
    return this.http.post<any>(this.URL + 'addTaskShchemeMicro',data);
  }

  delTaskShchemeMicro(data) {
    return this.http.post<any>(this.URL + 'delTaskShchemeMicro',data);
  }


  getTaskAllShchemeMicro(data) {
    return this.http.post<any>(this.URL + 'getTaskAllShchemeMicro',data);
  }

  getTaskAllShchemeMicroIdPro(data) {
    return this.http.post<any>(this.URL + 'getTaskAllShchemeMicroIdPro',data);
  }


  getTaskAllShchemeMicroUltimate(data) {
    return this.http.post<any>(this.URL + 'getTaskAllShchemeMicroUltimate',data);
  }


  getVerStaTaskId(data) {
    return this.http.post<any>(this.URL + 'getVerStaTaskId',data);
  }

  getMacroSchemeGlo(data) {
    return this.http.post<any>(this.URL + 'getMacroSchemeGlo',data);
  }

  getStarEndDateTasks(data) {
    return this.http.post<any>(this.URL + 'getStarEndDateTasks',data);
  }

  getRangoProject(data) {
    return this.http.post<any>(this.URL + 'getRangoProject',data);
  }

  getTaskDependiene(data) {
    return this.http.post<any>(this.URL + 'getTaskDependiene',data);
  }


  getMiembrosAddUni(data) {
    return this.http.post<any>(this.URL + 'getMiembrosAddUni',data);
  }


  getTaskOneShcheme(data) {
    return this.http.post<any>(this.URL + 'getTaskOneShcheme',data);
  }


  getTaskSchemeDepe(data) {
    return this.http.post<any>(this.URL + 'getTaskSchemeDepe',data);
  }



  upTaksShchemeMi(data) {
    return this.http.post<any>(this.URL + 'upTaksShchemeMi',data);
  }


  
  
}
