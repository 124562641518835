<div class="titulo-row-b">
  Actualizar Cliente
</div>
<mat-divider></mat-divider>
<form [formGroup]="aFormGroup" method="post">
  <div class="ct-panel">
    <div>
      <input formControlName="ima" value="" hidden (change)="onFileSelected($event)" #fileInput type="file" id="ima">
      <br>
      <mat-card class="mat-card">
        <mat-card-title class="titulo-row-b"></mat-card-title>
        <mat-card-content>
          <img [src]="photoSelected || 'assets/ima/no-ima.png'" class="imgfluid" (click)="fileInput.click()">
        </mat-card-content>
        <mat-card-subtitle>Click para seleccionar una imagen</mat-card-subtitle>
      </mat-card>
    </div>
    <div>
      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Codigo EPICOR</mat-label>
        <input matInput formControlName="id_epicor" value="" required autocomplete="off">
        <mat-error *ngIf="id_epicor.invalid">{{getErrorMessageCodEpicor()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="razon_social" value="" required autocomplete="off">
        <mat-error *ngIf="razon_social.invalid">{{getErrorMessageNombre()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Descripcion</mat-label>
        <input matInput formControlName="descripcion" value="" autocomplete="off">
        <mat-error *ngIf="descripcion.invalid">{{getErrorMessageDes()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Ciudad</mat-label>
        <input matInput formControlName="ciudad" value="" autocomplete="off">
        <mat-error *ngIf="ciudad.invalid">{{getErrorMessageCiudad()}}</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Estado</mat-label>
        <input matInput formControlName="provincia" value="" autocomplete="off">
        <mat-error *ngIf="provincia.invalid">{{getErrorMessageProvincia()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Telefono</mat-label>
        <input matInput formControlName="telefono" value="" autocomplete="off">
        <mat-error *ngIf="telefono.invalid">{{getErrorMessageTelefono()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Grupo</mat-label>
        <input matInput formControlName="grupo" value="" autocomplete="off">
        <mat-error *ngIf="grupo.invalid">{{getErrorMessageGrupo()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Canales</mat-label>
        <mat-select [formControl]="canales" multiple>
          <mat-option *ngFor="let uni of obs | async" [value]="uni._id" (onSelectionChange)="getValues($event)">
            {{uni.canal}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="btn" color="primary" mat-raised-button mat-button (click)="up()">Actualizar</button>
    </div>
  </div>
</form>