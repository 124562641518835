import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UsuarioService } from '../services/usuario.service';

@Directive({
  selector: '[appDroles]',
})
export class DrolesDirective implements OnInit {
  private permissions: any[] = [];
  currentUser: [];
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private usuarioService: UsuarioService
  ) { }

  @Input()
  set appDroles(val: Array<string>) {
    this.permissions = val;
    this.updateView();
  }

  ngOnInit() {
    this.getRolCurrentUser();
    this.updateView();
  }

  getRolCurrentUser() {
    this.usuarioService.getRolCurrentUser().subscribe((resp) => {
      this.currentUser = resp[0].rol;
      this.updateView();
    });
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkPermission(): boolean {
    let hasPermission = false;
    if (this.currentUser) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentUser.find((p: string) => {
          return p.toUpperCase() === checkPermission.toUpperCase();
        });
        if (permissionFound) {
          hasPermission = true;
          break;
        }
      }
    }
    return hasPermission;
  }
}