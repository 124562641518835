<div *ngIf="show; else nod_noti" class="ct-panel">
    <div class="ct-p-list" *ngFor="let env of obs | async">
        <div class="ct-p" (click)="onCli()">
            <div class="ct-p-ico">
                <mat-icon class="ct-p-ico">{{env.ico}}</mat-icon>
            </div>
            <div class="ct-p-fecha">
                {{env.fecha_registro}}
            </div>
            <div class="ct-p-ori">
                <span class="ct-p-ori-p {{env.ori}}">{{env.ori}}</span>
            </div>
            <div class="ct-p-msj">{{env.msj}}</div>
        </div>
        <mat-divider></mat-divider>
    </div>
</div>

<ng-template #nod_noti>
    <div class="ct-panel-s">
        <span class="ct-pr"> NO TIENES NOTIFICACIONES </span>

    </div>

</ng-template>