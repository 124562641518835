<div *ngFor="let env of dataPro | async; let i = index;" class="ct-mat">
    <mat-accordion >
        <mat-expansion-panel class="{{getColorl(i)}}" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>

                <div class="ct-data">
                    <div class="ct-data-ima">
                    <div> {{env.id_epicor}} </div>    <div class="v-{{env.dupli}}"></div> 
                    </div>
                    <div class="ct-data-nombre">
                        {{env.nombre}}
                    </div>
                    <div class="ct-data-vol">
                        {{env.volumen | number: '1.2-2'}}
                    </div>
                    <div class="ct-data-uni">
                        {{env.unidad}}
                    </div>
                    <div class="ct-data-total">
                        <span *ngIf="env.venta>0"> {{env.venta | number: '1.2-2'}} </span>
                        <br>
                        <span *ngIf="env.venta_uds>0"> {{env.venta_uds | number: '1.2-2'}} </span>
                    </div>
                    <div class="ct-data-mon">
                        <span *ngIf="env.venta>0"> MX </span>
                        <br>
                        <span *ngIf="env.venta_uds>0"> USD </span>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <app-pvd-producto [vYear]="'null'" [vCli]="env.vCli" [vD]="vD" [vH]="vH" [vUni]="env.unidadi" [idproducto]="env._id_producto"></app-pvd-producto>
        </mat-expansion-panel>
    </mat-accordion>
</div>





