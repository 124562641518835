<div *ngIf="mats; else nomats" class="ct-mats"  fxFlex.sm="0 1
calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(33%-10px)"  >
    <mat-card class="mat-card-pre" >
        <div class="ct-model">
            <div class="item">
                <span class="mat-card-pre-title{{vYear}}" (click)="goToLink(vYear)"  >{{vYear}}</span>
            </div>
            <div class="item">
                <div class="g1">
                    <div class="g2">
                        <div class="g3">
                            <ngx-charts-bar-horizontal-normalized [scheme]="colorScheme" [results]="dataGra"
                                [roundDomains]="roundDomains" [customColors]="customColors" [gradient]="gradient"
                                [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
                            </ngx-charts-bar-horizontal-normalized>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="ct-m-mast">
            <div class="ct-model-c-item" *ngFor="let env of obs | async">
                <div class="item-canal" >
                    <div class="item-ct-canal">
                        <div class="item-canal-id-{{getNameMatriz(env.name)}}"></div>
                        <div>
                            <span class="bton" (click)="goToLinkGenerico(env._id_canal)" >{{env.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="item-value">
                    {{env.value | number: '1.2-2' }} <span class="item-moneda">MXN</span>
                </div>
            </div>
        </div>

        <div class="ct-totals">
            <div class="item-value-total ct-totals-item1">
                <app-pcrven *ngIf="tVen" [vYear]="vYear" [tVen]="tVen" [vpre]="vPre"></app-pcrven>
            </div>
            <div class="item-value-total">
                <span class="item-ind">VEN</span> {{tVen | number: '1.2-2' }} <span class="item-moneda">MXN</span>
            </div>
            <div class="item-value-total-pre">
                <span class="item-ind">PRE</span> {{vPre | number: '1.2-2' }} <span class="item-moneda">MXN</span>
            </div>
        </div>
    </mat-card>
</div>



<ng-template #nomats>
    <div class="ct-master-ver">

        <mat-card class="mat-card-pre" fxFlex.sm="0 1
    calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(33%-10px)">

            <div class="ct-model">
                <div class="item">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '55px',
                'padding':'7px',
                'margin':'7px',
                'height': '34px',
                'background-color': '#daddd8'
            }">
                    </ngx-skeleton-loader>
                </div>

                <div class="item">

                    <ngx-skeleton-loader count="2" [theme]="{ 
                'border-radius': '5px', 
                'width': '230px',
                'padding':'1px',
                'margin':'1px',
                'height': '14px',
                'background-color': '#daddd8'
            }">
                    </ngx-skeleton-loader>

                </div>
            </div>
            <mat-divider></mat-divider>
            <table class="table-data">
                <tr class="table-data-conte">
                    <td>
                        <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '140px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '10px',
                        'background-color': '#daddd8'
                    }">
                        </ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '140px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '10px',
                        'background-color': '#daddd8'
                    }">
                        </ngx-skeleton-loader>
                    </td>
                </tr>
                <tr class="table-data-conte-c">
                    <td>
                        <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '80px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '30px',
                        'background-color': '#daddd8'
                    }">
                        </ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '140px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '15px',
                        'background-color': '#daddd8'
                    }">
                        </ngx-skeleton-loader>
                    </td>
                </tr>
            </table>
        </mat-card>

    </div>



</ng-template>