

    <div>
        <div class="ct-ti-b">
            <div> <button mat-icon-button>
                    <mat-icon>navigate_before</mat-icon>
                </button></div>
            <div> Nuevas tarea desde esquema</div>
            <div class="ct-ti-b-s"> <span class="ct-bt-c" (click)="ptakOff()">Cancelar</span></div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <form [formGroup]="formPurchaseOrder">


        <div class="ct-for-b">

            <div>
                <mat-form-field class="in-for-des">
                    <mat-label>Esquema</mat-label>
                    <mat-select formControlName="esquema">
                        <mat-option *ngFor="let sl of obsAreaSv" [value]="sl._id"> {{sl.nombre}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="ct-for-b">

                <mat-form-field class="in-for-des">
                    <mat-label>Fecha de Inicio</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="fechaStar">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

            </div>



            <div class="ct-bt">
                <button (click)="add();" class="btn" color="primary" mat-raised-button mat-button>
                    <mat-icon matListIcon>add</mat-icon>
                </button>
            </div>
        </div>


    </form>

    <mat-divider></mat-divider>

