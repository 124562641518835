import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SLCEspcificoComponent } from './../s-l-c-espcifico/s-l-c-espcifico.component';
import { VenMesAdminService } from '../../../../services/sales/ven-mes-admin.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClipboardService } from './../../../../services/clipboard.service';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SnackbarService } from 'src/app/services/snackbar.service';
export interface dataElement {
  ima: string;
  nombre: number;
  pre: number;
  ven: string;
}

@Component({
  selector: 'app-scg-table-acum-cli',
  templateUrl: './scg-table-acum-cli.component.html',
  styleUrls: ['./scg-table-acum-cli.component.css'],
  animations: [
    // trigger('detailExpand', [
    //   state(
    //     'void',
    //     style({ height: '0px', minHeight: '0', visibility: 'hidden' })
    //   ),
    //   state('expanded', style({ height: '*', visibility: 'visible' })),
    //   transition(
    //     'expanded <=> void',
    //     animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
    //   ),
    // ]),

    trigger('detailExpand', [
      state(
        'collapsed, void',
        style({ height: '0px', minHeight: '0', display: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ScgTableAcumCliComponent {
  expandedElement: PeriodicElement | null;
  copyToClipboard: any;

  obsCsv = new BehaviorSubject<any>(null);

  @Input() vCh: string;
  @Input() vYear: string;
  dataSource = new MatTableDataSource<any>();
  dataSourceCsv = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'id_epicor',
    'razon_social',
    'pres_vol',
    'oc_vol',
    'venta_vol',
    'pres_tol',
    'oc_tol',
    'venta_tol',
    'indi',
  ];
  ss1: boolean;
  show: boolean;
  venta_tol: any;
  pres_tol: any;
  venta_vol: any;
  pres_vol: any;
  pres_vols: any;
  oc_vol: any;
  oc_tol: any;
  cio: string;
  cioa: boolean;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  constructor(
    private clipboardService: ClipboardService,
    private venMesAdminService: VenMesAdminService
  ) {
    this.cio = 'file_copy';
  }

  ngOnChanges(changes: any) {
    this.show = false;
    const nvYear = changes.vYear.currentValue;
    this.acuCliGenGrupoTable(this.vCh, String(nvYear));
  }

  ngOnInit() {
    this.show = false;

    // this.acuCliGenGrupoTable(this.vCh, this.vYear);
  }

  async venCSVScgTableAcumCli(vCh, vYear) {
    let respuesta;
    await this.venMesAdminService
      .venCSVScgTableAcumCli({ vCh: vCh, vYear: vYear })
      .toPromise()
      .then((response) => {
        respuesta = response;
      })
      .catch((e) => console.error(e));
    return respuesta;
  }

  acuCliGenGrupoTable(vCh, vYear) {
    this.venMesAdminService
      .acuCliGenGrupoTable({ vCh: vCh, vYear: vYear })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }
        this.show = true;

        const respOpeCD = resp.find((dat) => dat.id_epicor === '1050116005');

        const respOpeCDIndex = resp.findIndex(
          (dat) => dat.id_epicor === '1050116005'
        );

        const respOpeReinosa = resp.find(
          (dat) => dat.id_epicor === '1050116004'
        );

        const respOpeReinosaIndex = resp.findIndex(
          (dat) => dat.id_epicor === '1050116004'
        );

        resp.splice(respOpeReinosaIndex, 1);
        resp.splice(respOpeCDIndex, 1);

        let newOperadora = {
          razon_social: respOpeCD.razon_social,
          id_epicor: respOpeCD.id_epicor,
          _id_cliente: respOpeCD._id_cliente,
          pres_tol: Number(respOpeCD.pres_tol),
          venta_tol:
            Number(respOpeCD.venta_tol) + Number(respOpeReinosa.venta_tol),
          pres_uni: respOpeCD.pres_uni,
          pres_vol: Number(respOpeCD.pres_vol),
          venta_vol:
            Number(respOpeCD.venta_vol) + Number(respOpeReinosa.venta_vol),
          venta_uni: respOpeCD.venta_uni,
          oc_tol: Number(respOpeCD.oc_tol) + Number(respOpeReinosa.oc_tol),
          oc_vol: Number(respOpeCD.oc_vol) + Number(respOpeReinosa.oc_vol),
          oc_uni: respOpeCD.oc_uni,
          oc_uni_m: respOpeCD.oc_uni_m,

          tc: respOpeCD.tc,
          vD: respOpeCD.vD,
          vH: respOpeCD.vH,
        };

        resp.push(newOperadora);

        resp.sort((a, b) => b.venta_tol - a.venta_tol);

        this.dataSource.data = resp;

        this.venta_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_tol;
        }, 0);

        this.pres_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_tol;
        }, 0);

        this.venta_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_vol;
        }, 0);

        this.oc_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_vol;
        }, 0);

        this.oc_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_tol;
        }, 0);

        this.pres_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_vol;
        }, 0);
      });
  }

  getNombre(nombre) {
    return nombre.substring(0, 10) + ' ...';
  }

  async copyData() {
    this.getChangeIco('hourglass_empty');
    let valor = await this.venCSVScgTableAcumCli(this.vCh, this.vYear);
    let valorPro = valor.map((val) => val.prod);
    let titlePro = Object.keys(valorPro[0][0]);
    let title = Object.keys(valor[0]);

    const result = this.clipboardService.copyDataLeveTwo(
      valor,
      valorPro,
      title,
      titlePro
    );

    navigator.clipboard.writeText(result);
    this.getChangeIco('check_circle');
    this.cioa = true;
  }

  cleanArray(query, words) {
    const result = words.filter((word) => {
      return word.v.includes(query);
    });
    return result;
  }

  getChangeIco(ico) {
    this.cio = ico;
    if (ico === 'check_circle') {
      setTimeout(() => {
        this.cio = 'file_copy';
      }, 6000);
    }
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
