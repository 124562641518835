<div class="tbq sec">
  <button (mouseup)="copyData()" [cdkCopyToClipboard]="copyToClipboard"   mat-icon-button>
    <mat-icon class="material-icons color_white">file_copy</mat-icon>
  </button>
    Venta acumulada 
  </div>
  <mat-divider></mat-divider>
  <div class="ct-p">
    <div>
      <div *ngIf="showa; else wnoda">
        <div *ngIf="ss1; else rsnoda">
          <div class="table-container">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="mat-elevation-z0"
            >
              <ng-container matColumnDef="num">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MES</th>
                <td mat-cell *matCellDef="let element">
                  <!-- {{element.num}} -  -->
                  {{ element.mes }}
                </td>
                <td mat-footer-cell *matFooterCellDef>Total</td>
              </ng-container>
              <ng-container matColumnDef="presupuesto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MXN PRE</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.presupuesto | number: "1.2-2" }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ this.volSumaPre | number: "1.2-2" }}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="oc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MXN OC</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.oc | number: "1.2-2" }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ this.volSumaOc | number: "1.2-2" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="venta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MXN VEN</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.venta | number: "1.2-2" }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ this.volSumaVen | number: "1.2-2" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="indi">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>%</th>
                <td mat-cell *matCellDef="let element">
                  <app-scg-indicador
                    [vVen]="element.venta"
                    [vSm]="'ss'"
                    [vPre]="element.presupuesto"
                  ></app-scg-indicador>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  <app-scg-indicador
                    [vVen]="volSumaVen"
                    [vSm]="'ss'"
                    [vPre]="volSumaPre"
                  ></app-scg-indicador>
                </td>
              </ng-container>
              <tr
                class="table-h"
                mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
          </div>
        </div>
  
        <ng-template #rsnoda>
          <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]="">
          </app-info-data>
        </ng-template>
      </div>
      <ng-template #wnoda>
        <div class="ct-cadew">
          <ngx-skeleton-loader
            count="5"
            [theme]="{
              'border-radius': '5px',
              width: '400px',
              height: '30px',
              'background-color': '#daddd8'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </ng-template>
    </div>
    <div>
      <div class="g1">
        <div class="g2">
          <div class="g3">
            <ngx-charts-line-chart
              [scheme]="colorScheme"
              [legend]="legend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [customColors]="customColors"
              [xAxis]="xAxis"
              [yAxis]="yAxis"
              [legendPosition]=""
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [timeline]="timeline"
              [results]="multi"
            >
            </ngx-charts-line-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  