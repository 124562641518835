import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SLCEspcificoComponent } from './.././../s-l-c-espcifico/s-l-c-espcifico.component';
import { channelGroupProductServices } from '../../../../../services/sales/channel-group-product.services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-scg-table-pro-x-cli',
  templateUrl: './scg-table-pro-x-cli.component.html',
  styleUrls: ['./scg-table-pro-x-cli.component.css'],
})
export class ScgTableProXCliComponent implements OnInit {
  @Input() vCh: string;
  @Input() vNum: string;
  @Input() vYear: string;
  @Input() vPro: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  panelOpenState = false;
  obsLis = new BehaviorSubject<any>(null);
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'id_epicor',
    'nombre',
    'pres_vol',
    'venta_vol',
    'pres_tol',
    'venta_tol',
    'indi',
  ];
  ss1: boolean;
  show: boolean;
  venta_tol: any;
  pres_tol: any;
  venta_vol: any;
  pres_vol: any;
  pres_vols: any;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  constructor(
    private _channelGroupProductServices: channelGroupProductServices
  ) {}

  ngOnChanges(changes: any) {
    this.show = false;
    const nvYear = changes.vYear.currentValue;
    this.sgcTableProXCli(this.vCh, String(nvYear), this.vPro);
  }

  ngOnInit() {
    this.show = false;
    // this.sgcTableProXCli(this.vCh, this.vYear, this.vPro);
  }
  sgcTableProXCli(vCh, vYear, vPro) {

    this._channelGroupProductServices
      .sgcTableProXCli({ vCh: vCh, vYear: vYear, vPro: vPro })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }



        this.show = true;

        this.obsLis.next(resp);

        // this.dataSource.data = resp;
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;

        // this.venta_tol = resp.reduce(function (total, currentValue) {
        //   return total + currentValue.venta_tol;
        // }, 0);

        // this.pres_tol = resp.reduce(function (total, currentValue) {
        //   return total + currentValue.pres_tol;
        // }, 0);

        // this.venta_vol = resp.reduce(function (total, currentValue) {
        //   return total + currentValue.venta_vol;
        // }, 0);

        // this.pres_vol = resp.reduce(function (total, currentValue) {
        //   return total + currentValue.pres_vol;
        // }, 0);
      });
  }

  getNombre(nombre) {
    const numNombe = nombre.length;

    if (numNombe >= 39) {
      return nombre.substring(0, 40) + ' ...';
    }

    return nombre;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
