<div class="conte-pro">
  <div class="titulo-row-b">
    Venta Detalle Año <span class="ct-vYear">{{vYear}} </span>
  </div>
  <mat-divider></mat-divider>

  <section class="ct-pre-firs">
    <div class="ct-pre-gra">
      <app-gra-gru-mes-year [vYear]="vYear"></app-gra-gru-mes-year>
    </div>
    <div class="ctp-panel">
      <div class="ct-mat-data" fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
        <div class="dataYear" *ngFor="let env of obs | async">
          <app-s-ven-pre [vYear]="env.vYear" [env]="env" [vPre]="env.vPre.value"></app-s-ven-pre>
        </div>
      </div>
    </div>
  </section>
  <section class="ct-pre-secons">
    <div class="titulo-row-b">

      <button mat-icon-button>
        <mat-icon>insert_chart_outlined</mat-icon>
      </button>Venta Mes:
      <mat-form-field class="sele" (change)="updateAn($event.target.value)">
        <select matNativeControl>
          <option *ngFor="let lv of listRango" [value]="lv.mesNumber">
            {{ lv.mesNombre }}
          </option>
        </select>
      </mat-form-field>
      <mat-divider></mat-divider>


    </div>
    <div class="ct-gen">
      <div class="ctg-card-ven">
        <div class="dataYear" *ngFor="let env of obsMes | async">
          <app-s-ven-pre-mes [vYear]="env.vYear" [vD]="env.vD" [vH]="env.vH" [vMesa]="env.vMes" [vMes]="env.vMesn" [env]="env" [vPre]="env.vPre.value">
          </app-s-ven-pre-mes>
        </div>
      </div>
      <div>
        <div class="ctg-gra-ven">
          <div class="gv1">
            <div class="gv2">
              <div class="gv3">
                <ngx-charts-line-chart [scheme]="bcolorScheme" [legend]="blegend" [showXAxisLabel]="bshowXAxisLabel"
                  [showYAxisLabel]="bshowYAxisLabel" [xAxis]="bxAxis" [yAxis]="byAxis" [xAxisLabel]="bxAxisLabel"
                  [yAxisLabel]="byAxisLabel" [timeline]="btimeline" [results]="bmulti">
                </ngx-charts-line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="titulo-row-b">
      <button mat-icon-button>
        <mat-icon>insert_chart_outlined</mat-icon>
      </button>Top 10 Clientes / Top 10 Productos
    </div>
    <mat-divider></mat-divider>
    <div class="ct-genp">

      <div>

        <div class="gv1p">
          <div class="gv2p">
            <div class="gv3p">
              <ngx-charts-bar-vertical [scheme]="ccolorScheme" [results]="cmulti" [gradient]="pgradient"
                [xAxis]="pshowXAxis" [yAxis]="pshowYAxis" [legend]="pshowLegend" [showXAxisLabel]="pshowXAxisLabel"
                [showYAxisLabel]="pshowYAxisLabel" [xAxisLabel]="pxAxisLabel" [yAxisLabel]="pyAxisLabel"
                [maxXAxisTickLength]="5" [rotateXAxisTicks]="true">
              </ngx-charts-bar-vertical>
            </div>
          </div>
        </div>


      </div>


      <div>

        <div class="gv1p">
          <div class="gv2p">
            <div class="gv3p">
              <ngx-charts-bar-vertical [scheme]="pcolorScheme" [results]="pmulti" [gradient]="pgradient"
                [xAxis]="pshowXAxis" [yAxis]="pshowYAxis" [legend]="pshowLegend" [showXAxisLabel]="pshowXAxisLabel"
                [showYAxisLabel]="pshowYAxisLabel" [xAxisLabel]="pxAxisLabel" [yAxisLabel]="pyAxisLabel"
                [maxXAxisTickLength]="5" [rotateXAxisTicks]="true">
              </ngx-charts-bar-vertical>
            </div>
          </div>
        </div>


      </div>
    </div>


  <mat-divider></mat-divider>

    <div class="titulo-row-b">
      <button mat-icon-button>
        <mat-icon>insert_chart_outlined</mat-icon>
      </button>Detalle Cliente
    </div>
    <mat-divider></mat-divider>
   
    
    
    <div class="ct-titulo-glo">
      <div>
        <mat-form-field class="mat-form-field-filtro" appearance="fill">
          <mat-label>Filtro</mat-label>
          <input matInput [(ngModel)]="searchText" placeholder="Ejemplo: DANTRADE" autocomplete="off" />
        </mat-form-field>
      </div>
      <div>
  
        <form [formGroup]="criterioChVen" method="post">
          <mat-form-field appearance="fill" class="mat-form-field-filtro">
            <mat-label>Canal</mat-label>
            <mat-select (selectionChange)="updateChVen()" formControlName="canalven">
  
              <mat-option [value]=""> TODOS </mat-option>
              <mat-option *ngFor="let ch of obsCh | async" [value]="ch._id"> {{ch.canal}} </mat-option>
  
            </mat-select>
          </mat-form-field>
        </form>
  
  
      </div>
  
    </div>
  
    
    
    
    
  <div *ngIf="gGlomes;else nodaglo">
    <div class="ct-mat-data" fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
      <div class="mat-card-pre" *ngFor="let env of obsVenMes | async | filter: searchText; index as i" fxFlex.sm="0 1
          calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(33%-10px)"
        fxFlex.gt-md="0 1 calc(33%-10px)">
        <app-car-ven-mes [vNum]="i"  [env]="env" [vRowGet]="vRowGet"></app-car-ven-mes>
      </div>
    </div>
  </div>

    <ng-template #nodaglo>
      <app-info-data [mensaje]="'No hay venta por mostrar'" [pd]=""> </app-info-data>
    </ng-template>
    

  </section>
  <section class="footer">
  </section>
</div>