<mat-tab-group class="tabs-master-cli">
  <mat-tab label="Productos Presupuestados">

    <div class="ct-sem-content">

      <div *ngFor="let env of obs | async; index as i ">


        <div class="ct-prod ">
          <div class="ct-prod-ima">
            {{i+1}}
          </div>
          <div class="ct-prod-nombre" >
            <span class="idE" (click)="getInfoPro(env._id_producto, env._id_cliente, env.vD,  env.vH)"> {{env._id_epicor}}</span><br>
            {{env.nombre}}
          </div>
          <div class="ct-infi">

            <div class="ct-info-pre">
              <div class="ct-pre-g">
                <div *ngIf="env.pre.pretKg>0">{{ env.pre.precio_u | number: '1.2-2' }} <span class="ct-id">{{
                    env.pre.moneda }}</span> {{ env.pre.pretKg | number }} <span class="ct-id">KGS</span>
                </div>
                <div *ngIf="env.pre.pretLb>0">{{ env.pre.precio_u | number: '1.2-2' }} <span class="ct-id">{{
                    env.pre.moneda }}</span> {{ env.pre.pretLb | number }} <span class="ct-id">LBS</span>
                </div>
                <div *ngIf="env.pre.pretPz>0">{{ env.pre.precio_u | number: '1.2-2' }} <span class="ct-id">{{
                    env.pre.moneda }}</span> {{ env.pre.pretPz | number }} <span class="ct-id">PZA</span>
                </div>
                <div *ngIf="env.pre.pTUsd>0"> {{ env.pre.pTUsd | number: '1.2-2' }} <span class="ct-id">USD</span></div>
                <div class="ct-info-pre-total" *ngIf="env.pre.vpTotalMXN>0"> {{ env.pre.vpTotalMXN | number: '1.2-2' }}
                  <span class="ct-id">MXN</span>
                </div>

              </div>

              <div class="ct-cu-g">
                <div class="ct-cumpli">
                  <app-pre-mensual-ind [value]="getCal(env.pre.vpTotalMXN,env.ven.vTVMxn)"></app-pre-mensual-ind>
                </div>
              </div>

              <div class="ct-ven-g">
                <div *ngIf="env.ven.venTKg>0"> <span class="ct-id">KGS </span> {{ env.ven.venTKg | number }} <span
                    class="ct-id">{{ env.ven.moneda }}</span> {{ env.ven.precio_u | number: '1.2-2' }}
                </div>
                <div *ngIf="env.ven.venTLb>0"> <span class="ct-id">LBS </span>{{ env.ven.venTLb | number }} <span
                    class="ct-id">{{ env.ven.moneda }}</span> {{ env.ven.precio_u | number: '1.2-2' }}
                </div>
                <div *ngIf="env.ven.venTPz>0"> <span class="ct-id">PZA </span>{{ env.ven.venTPz | number}} <span
                    class="ct-id">{{ env.ven.moneda }}</span> {{ env.ven.precio_u | number: '1.2-2' }}
                </div>
                <div *ngIf="env.ven.vTUsd>0"> <span class="ct-id">USD </span>{{ env.ven.vTUsd | number: '1.2-2' }}
                </div>

                <div class="ct-info-ven-total" *ngIf="env.ven.vTVMxn>0"> <span class="ct-id">MXN</span> {{
                  env.ven.vTVMxn | number: '1.2-2' }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <!-- <div>
        <div>
          <div *ngFor="let enp of obsP | async">
          pre  {{enp.pTVUsd}} <br> {{enp.pTVMxn}}
          </div>
        </div>
        <div>
          <div *ngFor="let env of obsV | async">
            ven  {{env.vTVUsd}} <br> {{env.vTVMxn}}
          </div>
        </div>
      </div> -->



      <div class="ct-prod">
        <div class="ct-prod-ima">
        </div>
        <div class="ct-prod-nombre">
        </div>
        <div class="ct-infi">

          <div class="ct-info-pre">
            <div class="ct-pre-g" *ngFor="let enp of obsP | async">

              <div *ngIf="enp.pTVUsd>0"> {{ enp.pTVUsd | number: '1.2-2' }} <span class="ct-id">USD</span></div>
              <div class="ct-info-pre-stotal" *ngIf="enp.pTVMxn>0"> {{ enp.pTVMxn | number: '1.2-2' }}
                <span class="ct-id">MXN</span>
              </div>

            </div>

            <div class="ct-cu-g">
              <div class="ct-cumpli">
              </div>
            </div>

            <div class="ct-ven-g" *ngFor="let env of obsV | async">

              <div *ngIf="env.vTVUsd>0"> <span class="ct-id">USD </span>{{ env.vTVUsd | number: '1.2-2' }}
              </div>

              <div class="ct-info-ven-stotal" *ngIf="env.vTVMxn>0"> <span class="ct-id">MXN</span> {{
                env.vTVMxn | number: '1.2-2' }}
              </div>
            </div>
          </div>
        </div>
      </div>














    </div>

  </mat-tab>
  <mat-tab label="Venta Fuera del Presupuesto">
    <!-- *ngIf="outVen;else nodata"  -->
    <div *ngIf="outVen;else nodata" class="ct-sem-content">
      <div class="ct-model ">
        <div *ngFor="let envo of obsOut | async ; index as i ">
          <div class="ct-prod">
            <div class="ct-prod-ima">
              {{i+1}}
            </div>
            <div class="ct-prod-nombre">
              <span class="idE" (click)="getInfoPro(envo._id_producto, envo._id_cliente, envo.vD,  envo.vH)"> {{envo._id_epicor}}</span><br>
              {{envo.nombre}}
            </div>
            <div class="ct-infi">
              <div class="ct-info-pre">
                <div class="ct-ven-g">
                  <div *ngIf="envo.venTKg>0"><span class="ct-id">KGS </span> {{ envo.venTKg | number: '1.2-2' }} <span
                      class="ct-id">{{ envo.moneda }}</span> {{ envo.precio_u | number: '1.2-2' }} </div>
                  <div *ngIf="envo.venTLb>0"><span class="ct-id">LBS </span>{{ envo.venTLb | number: '1.2-2' }} <span
                      class="ct-id">{{ envo.moneda }}</span> {{ envo.precio_u | number: '1.2-2' }} </div>
                  <div *ngIf="envo.venTPz>0"><span class="ct-id">PZA </span>{{ envo.venTPz | number: '1.2-2' }} <span
                      class="ct-id">{{ envo.moneda }}</span> {{ envo.precio_u | number: '1.2-2' }} </div>
                  <div *ngIf="envo.vTUsd>0"><span class="ct-id">USD </span> {{ envo.vTUsd | number: '1.2-2' }}
                  </div>
                  <div class="ct-info-ven-total" *ngIf="envo.vTVMxn>0"> <span class="ct-id">MXN </span> {{ envo.vTVMxn |
                    number: '1.2-2' }}</div>
                </div>
                <div class="ct-cu-g">
                  <div class=ct-cumpli>
                    <!-- <app-pre-mensual-ind [value]="getCal(env.pre.vpTotalMXN,env.ven.vTVMxn)"></app-pre-mensual-ind> -->
                  </div>
                </div>

              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>





      <div class="ct-prod">
        <div class="ct-prod-ima">
        </div>
        <div class="ct-prod-nombre">
        </div>
        <div class="ct-infi">

          <div class="ct-info-pre">


            <div class="ct-cu-g">
              <div class="ct-cumpli">
              </div>
            </div>

            <div class="ct-ven-g">

              <div *ngIf="totalPreMes_USD>0"> <span class="ct-id">USD </span> {{totalPreMes_USD | number: '1.2-2'}}</div>
              <div class="ct-info-ven-stotal" *ngIf="totalPreMes_MXN>0"> <span class="ct-id">MXN</span> {{totalPreMes_MXN | number: '1.2-2' }}</div>


            </div>
          </div>
        </div>
      </div>








    </div>
    <ng-template #nodata>
      <app-no-data [mensaje]="'No hay ventas registradas'" [pd]=""> </app-no-data>
    </ng-template>
  </mat-tab>
</mat-tab-group>






<!-- <div class="ct-pro-pre-g">

    <div>

    </div>
    <div>

    </div>

</div> -->