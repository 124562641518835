<div class="titulo-row-b">
    <div class="ct-titulo">
        <div class="ct-titulo-items">
            <button mat-icon-button [routerLink]="['/dashboard']">
                <mat-icon>navigate_before</mat-icon>
            </button>
            Nueva Revisión
        </div>
    </div>
</div>
<mat-divider></mat-divider>

<form [formGroup]="aFormGroup" method="post">




    <div class="ct-p">
        <div class="ct-c-ina">

            <mat-form-field class="ct-c-ina ct-c-area"  appearance="fill">
                <mat-label>Lugar</mat-label>
                <input matInput formControlName="vLu" value="" required autocomplete="off">
            </mat-form-field>
        </div>
        <div class="ct-c-inb">
            <mat-form-field class="ct-c-inb ct-c-area" appearance="fill">
                <mat-label>Fecha de la reunión</mat-label>
                <input matInput formControlName="vFecha" [matDatepicker]="picker1">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="ct-c-inc">
            <p>
                SE HA DEFINIDO UN NUEVO PUNTO DE REVISIÓN, SOBRE LAS VENTAS Y PRESUPUESTOS DEFINDOS PARA EL CLIENTE
                <b>{{vCliente}}</b> - <b>{{vRcliente}}</b> EN EL RANGO DE FECHA, DESDE <b>{{vD}}</b> HASTA <b>{{vH}}.</b>
            </p>
            <p>PRESUPUESTO: {{vTPMxn | number}} MXN | VENTA: {{vTVMxn | number}} MXN</p>
        </div>
        <div class="ct-c-ind">
            <mat-form-field class="ct-c-area" appearance="fill">
                <mat-label>Texto Adicional</mat-label>
                <textarea formControlName="vPd" matInput>

                </textarea>
            </mat-form-field>
        </div>
       
    </div>

    <div class="btnin">
        <button class="btn" color="primary" mat-raised-button mat-button (click)="up()">Enviar</button>
    </div>

</form>