import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerService } from "./../../../services/customer.service";

@Component({
  selector: 'app-ima-client-id',
  templateUrl: './ima-client-id.component.html',
  styleUrls: ['./ima-client-id.component.css']
})
export class ImaClientIdComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);
  loadingIma = true;
  @Input() idcliente: string;
  @Input() classCss: string;
  imaf: boolean;
  constructor(
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {

    

    this.imaClienteId(this.idcliente);

  }

  imaClienteId(_id_cliente) {
    this.customerService.imaClienteId({ _id_cliente: _id_cliente }).subscribe((resp) => {
      if (resp.length > 0 && resp[0].ima_cliente != null) {
        this.obs.next(resp[0].ima_cliente);

      } else {
        this.obs.next(null);
      }
      this.imaf = true;
    });
  }

  validateIma(valor) {
    if (valor === undefined || valor === null || valor === "" || valor == "null" || valor == "") {
      return "assets/ima/no-ima.png";
    }
    else { return valor; }
  }

}
