import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-war-data',
  templateUrl: './war-data.component.html',
  styleUrls: ['./war-data.component.css']
})
export class WarDataComponent implements OnInit {
  @Input() mensaje: String;
  @Input() pd: number;
  constructor() { }

  ngOnInit(): void {
  }

}
