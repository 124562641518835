import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
// import { ProductoInterface } from "../models/producto-interface";
import { GlobalVariable } from '../global';
@Injectable({
    providedIn: 'root'
})
export class ProductService {
    private URL = GlobalVariable.BASE_API_URL;
    public productoSer$ = new EventEmitter<any>();

    constructor(private http: HttpClient,
        private router: Router,) { }

    registroProducto(producto) {
        return this.http.post<any>(this.URL + 'registroProducto', producto);
    }

    getAllProductos() {
        return this.http.get<any>(this.URL + 'getAllProducto');
    }

    delProducto(id) {
        return this.http.post<any>(this.URL + 'delProducto', id);
    }

    updateProducto(producto) {
        return this.http.post<any>(this.URL + 'updateProducto', producto);
    }

    countProductos() {
        return this.http.get<any>(this.URL + 'countProductos');
    }


    getProId(data) {
        return this.http.post<any>(this.URL + 'getProId', data);
    }


    getproductImage(data) {
        return this.http.post<any>(this.URL + 'getproductImage', data);
    }


    porPreciHisto(data) {
        return this.http.post<any>(this.URL + 'porPreciHisto', data);
    }

    porPreciHistoGra(data) {
        return this.http.post<any>(this.URL + 'porPreciHistoGra', data);
    }


    listProduct(data) {
        return this.http.post<any>(this.URL + 'listProduct', data);
    }

    porPreciHistoYear(data) {
        return this.http.post<any>(this.URL + 'porPreciHistoYear', data);
    }

    porPreciHistoGlo(data) {
        return this.http.post<any>(this.URL + 'porPreciHistoGlo', data);
    }
    

    listProductYear(data) {
        return this.http.post<any>(this.URL + 'listProductYear', data);
    }

    listProductAllCust(data) {
        return this.http.post<any>(this.URL + 'listProductAllCust', data);
    }

    listProductAllCustAsig(data) {
        return this.http.post<any>(this.URL + 'listProductAllCustAsig', data);
    }

    getProIddata(data) {
        return this.http.post<any>(this.URL + 'getProIddata', data);
    }
    
    
}
