import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChannelService } from "./../../../services/channel.service";
@Component({
  selector: 'app-add-channel',
  templateUrl: './add-channel.component.html',
  styleUrls: ['./add-channel.component.css']
})
export class AddChannelComponent implements OnInit {

  form: FormGroup;


  constructor(
    private channelService: ChannelService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddChannelComponent>,

  ) { }



  ngOnInit() {
    this.crearForm();
  }


  add() {

    if (this.form.valid) {
      const canal = this.form.get('canal').value;
      const obs = this.form.get('obs').value;
      let newCanal = {
        canal: canal.toUpperCase(),
        obs: obs,
      }

      this.channelService.registrarCanal(newCanal)
        .subscribe(
          res => {
            this.openSnackBar(res.status, 'ok');
            this.channelService.canalSer$.emit();
            this.onClose();
          });
    }
  }

  crearForm() {
    this.form = this.fb.group({
      canal: ['', Validators.required],
      obs: ['', Validators.required],
  
    });
  }


  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
