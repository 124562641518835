import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-list',
  templateUrl: './sms-list.component.html',
  styleUrls: ['./sms-list.component.css']
})
export class SmsListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
