import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pcr-ven-pre',
  templateUrl: './pcr-ven-pre.component.html',
  styleUrls: ['./pcr-ven-pre.component.css']
})
export class PcrVenPreComponent implements OnInit {
  @Input() vVen:[]=[];
  cals: string;
  icc: string;
  value: any;
  ics: string;

  constructor() { }

  ngOnInit(): void {
    this.getValuePro(this.vVen);
  }

  validateValue(value) {
    this.vVen = value;
    if (value <= 10) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";
    } else if (value > 10 && value < 50) {
      this.cals = "yell";
      this.icc = "schedule";
      this.ics = "blink_me";

    } else if (value > 50) {
      this.cals = "gree";
      this.icc = "schedule";
      this.ics = "blink_me";
    }
  }

  getValuePro(datArray) {

    var lven = datArray.filter(element => element.extra.tipo === "VEN");
    var lpre = datArray.filter(element => element.extra.tipo === "PRE");
    var vPre = lpre[0].value;
    var vVen = lven[0].value;

    if (vPre === undefined || vPre === null) {
      this.value = 0;
    }
    else if (Array.isArray(vPre) && vPre.length === 0) {
      this.value = 0;
    }
    else {
      this.value = (vVen / vPre) * 100;
    }
    this.validateValue(this.value);


  }

}
