import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { StarRatingComponent } from 'ng-starrating';
import { AreaService } from 'src/app/services/area.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-task-add',
  templateUrl: './task-add.component.html',
  styleUrls: ['./task-add.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

// [dateFilter]="rangeFilter"
export class TaskAddComponent implements OnInit {
  @Input() vAr: string;

  obsCh = new BehaviorSubject<any>(null);
  formPurchaseOrder: FormGroup;
  range: FormGroup;

  obsAllSta = new BehaviorSubject<any>(null);
  miAr = [];
  raking: number;
  obsArea: any;
  hola: FormGroup;
  minDate: moment.Moment;
  toppingList: any;
  getError: boolean;
  getText: any;
  constructor(private areaService: AreaService) {
    // const today = new Date();
    // const month = today.getMonth();
    // const year = today.getFullYear();
    // this.hola = new FormGroup({
    //   start: new FormControl(moment('2022-07-13')),
    //   end: new FormControl(moment('2022-07-29')),
    // });
  }

  ngOnInit(): void {
    this.createForm();
    this.getTaskAllStatus();
    this.getMiembrosArea(this.vAr);
    this.getCiTaskUltimate(this.vAr);
    this.getTaskAreaId(this.vAr);
  }

  exeR(event: any) {
    this.revDateRange();
  }

  rangeFilter(date: Date): boolean {
    const day = moment(date).day();

    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }
  getCiTaskUltimate(vAr) {
    this.areaService.getCiTaskUltimate({ vAr: vAr }).subscribe((resp) => {
      this.obsArea = resp.cod_interno;
    });
  }

  getTaskAreaId(vAr) {
    this.areaService.getTaskAreaId({ vAr: vAr }).subscribe((resp) => {
      this.toppingList = resp;
    });
  }

  updateCh() {}

  getDate(value) {
    return moment(value);
  }

  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    this.raking = $event.newValue;
  }

  createForm() {
    this.formPurchaseOrder = new FormGroup({
      tarea: new FormControl('', [Validators.required]),
      des: new FormControl('', [Validators.required]),
      responsable: new FormControl('', [Validators.required]),
      status_acr: new FormControl('p', [Validators.required]),
      pre: new FormControl(),

      colora: new FormControl(this.generarNuevoColor(), [Validators.required]),
    });

    this.range = new FormGroup({
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required]),
    });
  }

  ptakOff() {
    this.areaService.cBeem$.emit(false);
  }

  revDateRange() {
    let date = this.range.value;
    let ve_text;

    if (!this.range.valid) {
      if (!date.star && !date.end) {
        ve_text = 'El rango de fecha es requerido';

        this.range.setValue({
          start: date.start,
          end: date.end,
        });
      }

      this.getError = true;
      this.getText = ve_text;
    } else {
      this.getError = false;
    }
  }

  add() {
    this.revDateRange();

    if (this.formPurchaseOrder.valid && this.range.valid) {
      let date = this.range.value;
      const tarea = this.formPurchaseOrder.controls.tarea.value;
      const des = this.formPurchaseOrder.controls.des.value;
      const responsable = this.formPurchaseOrder.controls.responsable.value;
      const prioridad = this.raking;
      const fecha_inicio = date.start;
      const fecha_fin = date.end;
      const color = this.formPurchaseOrder.controls.colora.value;
      const pre = this.formPurchaseOrder.controls.pre.value;

      let newObj = {
        _id_area: this.vAr,
        cit: this.obsArea,
        nombre: tarea,
        des: des,
        responsable: responsable,
        fecha_star: fecha_inicio,
        fecha_end: fecha_fin,
        prioridad: prioridad,
        color: color,
        dias: this.diasVentorGet(fecha_inicio, fecha_fin),
      };


      if (pre) {
        newObj['pre'] = pre;
      }

      this.areaService.addTask(newObj).subscribe((resp) => {
        this.areaService.cBeem$.emit(false);
        this.areaService.oTask$.emit();
      });
    } else {
    }
  }

  getTaskAllStatus() {
    this.areaService.getTaskAllStatus().subscribe((resp) => {
      this.obsAllSta.next(resp);
    });
  }

  updateInfo() {
    let color = this.generarNuevoColor();
    this.formPurchaseOrder.controls.colora.setValue(color);
    this.getCiTaskUltimate(this.vAr);
  }

  getMiembrosArea(vAr) {
    this.areaService.getMiembrosArea({ vAr: vAr }).subscribe((resp) => {
      this.miAr = resp;
    });
  }

  generarNuevoColor() {
    const minL = 0.75;
    const maxL = 0.9;
    const interval = 3000;
    const colors = {
      bgColor: '',
      txtColor: '',
      btnColor: '',
      btnFocus: '',
    };

    const hBase = Math.random();
    const newH = Math.floor(hBase * 360);
    const newL = Math.floor(Math.random() * 16) + 75;

    colors.bgColor = `hsl(${newH}, 100%, ${newL}%)`;
    colors.txtColor = `hsl(${newH}, 100%, 5%)`;
    colors.btnColor = `hsl(${newH}, 100%, 98%)`;
    colors.btnFocus = `hsl(${newH}, 100%, 95%)`;

    const [r, g, b] = this.HSLtoRGB(hBase, 1, newL * 0.01);

    Object.entries(colors).forEach(([k, v]) => {
      document.documentElement.style.setProperty(`--${k}`, `${v}`);
    });

    return this.RGBtoHex(r, g, b);
  }

  HSLtoRGB(h, s, l) {
    let r, g, b;

    const rd = (a) => {
      return Math.floor(Math.max(Math.min(a * 256, 255), 0));
    };

    const hueToRGB = (m, n, o) => {
      if (o < 0) o += 1;
      if (o > 1) o -= 1;
      if (o < 1 / 6) return m + (n - m) * 6 * o;
      if (o < 1 / 2) return n;
      if (o < 2 / 3) return m + (n - m) * (2 / 3 - o) * 6;
      return m;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRGB(p, q, h + 1 / 3);
    g = hueToRGB(p, q, h);
    b = hueToRGB(p, q, h - 1 / 3);

    return [rd(r), rd(g), rd(b)];
  }

  RGBtoHex(r, g, b) {
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  }

  evaPre(evaPre) {

    this.range.setValue({
      start: null,
      end: null,
    });




    if (evaPre.length > 0) {
      let verfificaDate;
      this.areaService
        .getStarEndDateTasks({ vPre: evaPre })
        .subscribe((resp) => {
          let afte = resp.fecha_end;

          if (moment(afte).format('dddd') == 'Saturday') {
            verfificaDate = moment(afte).add(2, 'days');
          } else if (moment(afte).format('dddd') == 'Sunday') {
            verfificaDate = moment(afte).add(1, 'days');
          }else{
            verfificaDate=afte;
          }

          this.range.setValue({
            start: verfificaDate,
            end: null,
          });
        });
    } else {
      this.range.setValue({
        start: null,
        end: null,
      });
    }
  }

  diasVentorGet(a, b) {
    var dia_actual = moment(a);
    var i = 0;
    while (dia_actual.isSameOrBefore(b)) {
      if (moment(dia_actual).format('dddd') != 'Saturday' && moment(dia_actual).format('dddd') != 'Sunday') {
        i++;
      }

      dia_actual.add(1, 'days');
    }
    return i;
  }
}
