<div *ngIf="show;else wnoda">
   <google-chart #chart [title]="title" [type]="type" [data]="vData" [columns]="columnNames" [options]="options"
      [width]="width" [height]="height">
   </google-chart>
</div>
<ng-template #wnoda>

   <div class="ct-cadew">
     <ngx-skeleton-loader count="5" [theme]="{ 
           'border-radius': '5px', 
           'width': '900px',
           'height': '30px',
           'background-color': '#daddd8'
       }">
     </ngx-skeleton-loader>
   </div>
 </ng-template>