<div class="conte-pro">
    <div class="titulo-row-b">

        <div class="ct-titulo">
            <div class="ct-titulo-items">
                <button mat-icon-button [routerLink]="['/dashboard']">
                    <mat-icon>navigate_before</mat-icon>
                </button>
                Presupuesto
            </div>

            <div class="ct-titulo-items-a">
                <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button
                    (click)="onDialogAdd()">
                    <mat-icon matListIcon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>

    <app-gra-gru-mes></app-gra-gru-mes>


    <div class="ct-mat-data" fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
        <div  class="mat-card-pre" *ngFor="let env of obs | async" fxFlex.sm="0 1
        calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(33%-10px)"
            fxFlex.gt-md="0 1 calc(33%-10px)">
            <app-gra-ven-pre [vYear]="env.year"></app-gra-ven-pre>
        </div>
    </div>

</div>