import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../global';
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private URL = GlobalVariable.BASE_API_URL;
  public usuarioSer$ = new EventEmitter<any>();
  constructor(private http: HttpClient) {}

  getAllUsuarios() {
    return this.http.get<any>(this.URL + 'getAllUsuarios');
  }

  getAllUsuariosEjecutivos() {
    return this.http.get<any>(this.URL + 'getAllUsuariosEjecutivos');
  }

  getAllUsuariosAuditor() {
    return this.http.get<any>(this.URL + 'getAllUsuariosAuditor');
  }

  updateUsuario(usuario) {
    return this.http.post<any>(this.URL + 'updateUsuario', usuario);
  }

  updateUsuarioEstado(usuario) {
    return this.http.post<any>(this.URL + 'updateUsuarioEstado', usuario);
  }

  delUsuario(id) {
    return this.http.post<any>(this.URL + 'delUsuario', id);
  }

  getAllRoles() {
    return this.http.get<any>(this.URL + 'getAllRoles');
  }

  getGenereClave(id) {
    return this.http.post<any>(this.URL + 'getGenereClave', id);
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  countUsuarios(id) {
    return this.http.post<any>(this.URL + 'countUsuarios', id);
  }

  getRolCurrentUser() {
    return this.http.get<any>(this.URL + 'currentUser');
  }


  getAllUsuariosTrue() {
    return this.http.get<any>(this.URL + 'getAllUsuariosTrue');
  }

 
  

  getImaUser(id) {
    return this.http.post<any>(this.URL + 'getImaUser', id);
  }


}
