<!-- 
<div class="datag">

<div class="itemg">
  <span class="mat-card-pre-title">{{ vyear }}</span>

</div>
<div class="itemg">

  <div class="g1">
    <div class="g2">
      <div class="g3">
        <ngx-charts-bar-horizontal-stacked [roundDomains]="roundDomains" [maxXAxisTickLength]="'3'" [legendTitle]="''"
          [scheme]="colorScheme" [results]="dataGra" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
          [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [animations]="animations" (select)="onSelect($event)">
        </ngx-charts-bar-horizontal-stacked>
      </div>
    </div>


</div>

</div>
</div> -->





<div class="g1">
  <div class="g2">
    <div class="g3">
      <ngx-charts-bar-horizontal [roundDomains]="roundDomains" [maxXAxisTickLength]="'3'" [legendTitle]="''"
        [scheme]="colorScheme" [results]="dataGra" [gradient]="gradient" [showDataLabel]="showDataLabel" [xAxis]="showXAxis" [yAxis]="showYAxis"
        [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [animations]="animations" (select)="onSelect($event)">
      </ngx-charts-bar-horizontal>
    </div>
  </div>
