import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-ima-card-area',
  templateUrl: './ima-card-area.component.html',
  styleUrls: ['./ima-card-area.component.css']
})
export class ImaCardAreaComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);

  @Input() idcliente: string;
  @Input() classCss: string;

  datas: boolean;
  constructor(
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.datas = true;
    this.imaIdCliente(this.idcliente);
  }

  imaIdCliente(idcliente) {
    this.customerService.imaClienteId({ _id_cliente: idcliente }).subscribe((resp) => {



      this.obs.next(resp[0].ima_cliente)
      this.datas = false;
    });
  }

  validateIma(valor) {
    if (valor != "null")
      return valor;
    else
      return "assets/ima/no-ima.png";
  }
}
