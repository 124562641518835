import { Component, OnInit, ViewChild } from '@angular/core';
import { PurshaseOrderService } from '../../services/purshase-order.service';
import * as moment from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ChannelService } from 'src/app/services/channel.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PurchaseOrderComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  listRango = [];
  vData: any;
  vYearOld: string;
  vYearOls: any;
  ss1: boolean;
  show: boolean;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }

  obsCh = new BehaviorSubject<any>(null);

  displayedColumns: string[] = [
    'ci',
    'oc',
    'fecha_oc',
    'fecha_em',
    'fecha_ent',
    'razon_social',
    'value',
    'moneda',
    'opciones',
  ];
  dataSource = new MatTableDataSource<any>();
  criterioCh: FormGroup;
  criterioChvYear: FormGroup;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private _snackBar: MatSnackBar,
    private channelService: ChannelService,
    private _formBuilder: FormBuilder,
    private purshaseOrderService: PurshaseOrderService
  ) {
    var y = moment().format('YYYY');

    this.vYearOls = y;
    this.vYearOld = moment(y).subtract(1, 'year').format('YYYY');
  }

  ngOnInit(): void {
    var y = moment().format('YYYY');


    this.getGraVet(y, '');
    this.getAllOrder(y, '');
    this.setList();
    this.getAllCanal();
    this.refresh();

    this.crearFormCh(y);
  }

  crearFormCh(y) {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(),
    });
    this.criterioChvYear = this._formBuilder.group({
      yeara: new FormControl(Number(y)),
    });
  }

  refresh() {
    this.purshaseOrderService.listOc$.subscribe((vYea, vCh) => {
      this.getAllOrder(vYea, vCh);
    });
  }

  updateCh() {
    const vCh = this.criterioCh.controls.canal.value;

    const vYear = this.criterioChvYear.controls.yeara.value;
    this.vYearOls = vYear;
    this.vYearOld = moment(String(vYear)).subtract(1, 'year').format('YYYY');

    this.getGraVet(vYear, vCh);
    this.getAllOrder(vYear, vCh);
  }

  getAllOrder(vYear, vCh) {
    this.purshaseOrderService
      .getAllOrder({ vYear, vCh, vOc: '' })
      .subscribe((resp) => {

        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }
        this.show = true;


        this.dataSource.data = resp;
        this.dataSource.paginator = this.paginator;
      });
  }

  getGraVet(vYear, vCh) {
    this.purshaseOrderService
      .getGraVet({ vYear, vCh })
      .subscribe((resp) => {
        this.vData = resp;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDelAsig(ci) {
    this.purshaseOrderService.delOrdenItem({ ci: ci }).subscribe((resp) => {
      this.openSnackBar(resp.status, 'ok');
      this.purshaseOrderService.listOc$.emit();
    });

    // this.getAllOrder();
  }

  onSelect(event) {}

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe((resp) => {
      this.obsCh.next(resp);
    });
  }

  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }
}
