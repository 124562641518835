import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerService } from "./../../../../services/customer.service";
import { VenSemAdminService } from "../../../../services/sales/ven-sem-admin.service";
@Component({
  selector: 'app-per-cli-vdi-mes',
  templateUrl: './per-cli-vdi-mes.component.html',
  styleUrls: ['./../../panel-venta-semana-admin-detalle/per-cli-vdi/per-cli-vdi.component.css']
})
export class PerCliVdiMesComponent implements OnInit {
  @Input() idepicor: String;
  @Input() vYear: Number;
  @Input() vMes: Number;
  @Input() vD: Date;
  @Input() vH: Date;
  datacliNombre = new BehaviorSubject<any>(null);
  datacliId = new BehaviorSubject<any>(null);
  dataVenPre = new BehaviorSubject<any>(null);
  vVen= new BehaviorSubject<any>(null);
  vPre= new BehaviorSubject<any>(null);
  loading: boolean = false;

  constructor(
    private customerService: CustomerService,
    private venSemAdminService: VenSemAdminService
  ) { }

  ngOnInit(): void {
    this.getIdEpicorCli(this.idepicor);
  }

  getIdEpicorCli(idepicor) {
    this.customerService.getIdEpicorCli({ id_epicor: idepicor }).subscribe((resp) => {
      this.datacliNombre.next(resp.razon_social);
      this.datacliId.next(resp.razon_social);
      this.loading=true;
    });
  }

  
}
