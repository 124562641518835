<div class="tbq sec">
  <button mat-icon-button>
    <mat-icon>list</mat-icon>
  </button>
  Acumulado por producto
</div>
<mat-divider></mat-divider>


<div *ngIf="show; else wnoda">
  <div *ngIf="ss1; else rsnoda">
    <div class="table-containera">

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z0">


        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Producto</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.id_epicor }} ">
            {{ element.nombre }} 
          </td>
          <td mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>

        <ng-container matColumnDef="pres_vol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VOL PRE</th>
          <td mat-cell *matCellDef="let element">
            {{ element.pres_vol | number: "1.0-2" }}
            <span class="ct-di">{{ element.pres_uni }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="footcen"> {{ pres_vol | number: "1.0-2" }}</div>  
          </td>
        </ng-container>

        <ng-container matColumnDef="oc_vol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VOL OC</th>
          <td mat-cell *matCellDef="let element">
            {{ element.oc_vol | number: "1.0-2" }}
            <span class="ct-di">{{ element.oc_uni }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="footcen"> {{ oc_vol | number: "1.0-2" }}</div>  
          </td>
        </ng-container>

        <ng-container matColumnDef="venta_vol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VOL VEN</th>
          <td mat-cell *matCellDef="let element">
            {{ element.venta_vol | number: "1.0-2" }}
            <span class="ct-di">{{ element.venta_uni }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="footcen">  {{ venta_vol | number: "1.0-2" }}</div>  
          </td>
        </ng-container>

        <ng-container matColumnDef="pres_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>PRE</th>
          <td mat-cell *matCellDef="let element">
            {{ element.pres_tol | number: "1.2-2" }}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="footcen">   {{ pres_tol | number: "1.2-2" }}</div>  
          </td>
        </ng-container>

        <ng-container matColumnDef="oc_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>OC</th>
          <td mat-cell *matCellDef="let element">
            {{ element.oc_tol | number: "1.2-2" }}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="footcen">   {{ oc_tol | number: "1.2-2" }}</div>  
          </td>
        </ng-container>

        <ng-container matColumnDef="venta_tol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>VEN</th>
          <td mat-cell *matCellDef="let element">
            {{ element.venta_tol | number: "1.2-2" }}
            <span class="ct-di">MXN</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="footcen"> {{ venta_tol | number: "1.2-2" }} </div>  
          </td>
        </ng-container>
        <ng-container matColumnDef="indi">
          <th mat-header-cell *matHeaderCellDef>%</th>

          <td mat-cell *matCellDef="let element">
            <app-scg-indicador [vVen]="element.venta_tol" [vSm]="'sm'" [vPre]="element.pres_tol"></app-scg-indicador>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>


        <tr class="table-h" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="table-h" mat-footer-row *matFooterRowDef="displayedColumns"></tr>

      </table>

    </div>
  </div>

  <ng-template #rsnoda>
    <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]="">
    </app-info-data>
  </ng-template>
</div>
<ng-template #wnoda>
  <div class="ct-cadew">
    <ngx-skeleton-loader count="9" [theme]="{
          'border-radius': '5px',
          width: '900px',
          height: '30px',
          'background-color': '#daddd8'
        }">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
