import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
// import { VentaadminService } from "../../../services/ventaadmin.service";
import { VenMesAdminService } from "../../../services/sales/ven-mes-admin.service";
import { BudgetService } from "../../../services/budget.service";
import { ChannelService } from "../../../services/channel.service";
@Component({
  selector: 'app-panel-venta-mes-admin',
  templateUrl: './panel-venta-mes-admin.component.html',
  styleUrls: ['./../panel-venta-semana-admin/panel-venta-semana-admin.component.css']
})
export class PanelVentaMesAdminComponent implements OnInit {
  // Grafica A
  showXAxis = true;
  tCop = "mM";
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = false;
  showYAxisLabel = true;
  legendTitle = '';
  yAxisLabel = 'MXN';
  colorScheme = {
    domain: ['#33a8c7', '#0081a7', '#7fd14d', '#26a116'],
  };
  listRango: any[];
  listGraAl;
  selec = new FormControl();
  conver: string;
  convera: string;
  converb: string;
  obs = new BehaviorSubject<any>(null);
  obsGlo = new BehaviorSubject<any>(null);
  obsCh = new BehaviorSubject<any>(null);
  mats = false;
  dataTer;
  obsVenMes = new BehaviorSubject<any>(null);
  dataCli: boolean;
  vRowGet: any;
  respmats: boolean;
  dataCliPre: boolean;
  public searchText: any;
  vCh: string;
  criterioCh: FormGroup;
  criterioChVen: FormGroup;
  filte: boolean=true;
  gGlo:boolean;
  constructor(
    private venMesAdminService: VenMesAdminService,
    private _formBuilder: FormBuilder,
    private channelService: ChannelService,
    private budgetService: BudgetService) { }

  ngOnInit() {
    this.getAllCanal();

    this.crearFormCh();
    let dataRest = this.getWeek();
    this.semCombo(dataRest.mouth);
    this.orqVenSem(
      dataRest.yeara,
      dataRest.yearb,
      dataRest.mouth,
      dataRest.dA.convera,
      dataRest.dA.converb,
      dataRest.dB.convera,
      dataRest.dB.converb,
      this.vCh = ""
    );

    this.dataTer = dataRest;
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obsCh.next(resp);
    });
  }



  crearFormCh() {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(),
    });

    this.criterioChVen = this._formBuilder.group({
      canalven: new FormControl(),
    });

  }


  updateCh() {
    const vCh = this.criterioCh.controls.canal.value;
    this.getPreMesAdminGlobal(this.vRowGet.vYeara, this.vRowGet.vMes, this.vRowGet.vD, this.vRowGet.vH, vCh);
  }

  updateChVen() {
    const vCh = this.criterioChVen.controls.canalven.value;
    this.getVenCliMesPre(this.vRowGet.vYeara, this.vRowGet.vMes, this.vRowGet.vD, this.vRowGet.vH, vCh);

  }

  aSelectVencli(event) {
    this.criterioCh.controls.canal.setValue("");
    this.vRowGet = {};
    const vYeara = event.extra.yeara;
    const vMes = event.extra.mes;
    const vD = event.extra.vD;
    const vH = event.extra.vH;
    var tip = event.extra.tip;
    this.vRowGet.vYeara = vYeara;
    this.vRowGet.vD = vD;
    this.vRowGet.vH = vH;
    this.vRowGet.vMes = vMes;
    this.vRowGet.vMesL = Number(vMes);
    this.dataCli = true;



    if (tip === "VEN") {
      this.dataCliPre = false;
      this.getVenCliMesPre(vYeara, vMes, vD, vH, "");
    }

    if (tip === "PRE") {
      this.dataCli = false;
      this.dataCliPre = true;

      this.getPreMesAdminGlobal(vYeara, vMes, vD, vH, "");
    }
  }

  updateAn(event) {
    this.criterioChVen.controls.canalven.reset();
    this.criterioCh.controls.canal.reset();
    this.vRowGet = {}
    this.dataCli = false;
    this.dataCliPre = false;
    var mouth = moment().format(event);
    var mnt = mouth;
    var aconvera = moment().format("YYYY-" + mnt + "-01");
    var aconverb = moment().format("YYYY-" + mnt + "-") + moment(aconvera).daysInMonth();
    const yeara = moment().format('YYYY');
    const yearb = moment().subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + "-" + mnt + "-01");
    var bconverb = moment().format(yearb + "-" + mnt + "-") + moment(bconvera).daysInMonth();





    this.vRowGet.vYeara = yeara;
    this.vRowGet.vD = aconvera;
    this.vRowGet.vH = aconverb;
    this.vRowGet.vMes = mouth;


    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb
      },
      dB: {
        convera: bconvera,
        converb: bconverb
      }
    };



    this.orqVenSem(
      dataFecha.yeara,
      dataFecha.yearb,
      dataFecha.mouth,
      dataFecha.dA.convera,
      dataFecha.dA.converb,
      dataFecha.dB.convera,
      dataFecha.dB.converb,
      this.vCh = ""
    );
    this.dataTer = dataFecha;
  }

  getPreMesAdminGlobal(vYear: Number, vMes: Number, vD: Date, vH: Date, vCh) {
    this.budgetService.getPreMesAdminGlobal({ vYear: vYear, vMes: vMes, vD, vH, vCh }).subscribe(
      resp => {
        this.obsGlo.next(resp);
        if (resp.length > 0) {
          this.gGlo = true;
        } else {
          this.gGlo = false;
        }
      });
  }

  getVenCliMesPre(year, mes, d, h, vCh) {
    this.venMesAdminService.getVenCliMesPre({ yeara: year, mes: mes, d: d, h: h, vCh })
      .subscribe((resp) => {

        if (resp.length>0) {
          this.filte = true;
        } else {
          this.filte = false;
        }
        this.obsVenMes.next(resp);
      });
  }

  orqVenSem(ya, yb, mes, da, ha, db, hb, ch) {
    this.respmats = true;
    this.venMesAdminService.orqVenMes({
      yeara: ya,
      yearb: yb,
      mes: mes,
      da: da,
      ha: ha,
      db: db,
      hb: hb,
      ch: ch,
    }).subscribe((resp) => {



      if (resp.cerror === false) {
        this.respmats = false;
      } else {
        this.respmats = true;
        this.listGraAl = resp;
        let b = resp.filter((element) => element.cdo === 'VEN');
        this.obs.next(b);
        this.mats = true;
      }
    });
  }

  getWeek() {

    var mouth = moment().format("MM");
    var aconvera = moment().format("YYYY-MM-01");
    var aconverb = moment().format("YYYY-MM-") + moment().daysInMonth();
    const yeara = moment().format('YYYY');
    const yearb = moment().subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + "-MM-01");
    var bconverb = moment().format(yearb + "-MM-") + moment().daysInMonth();

    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb
      },
      dB: {
        convera: bconvera,
        converb: bconverb
      }
    };



    return dataFecha;
  }

  formatoDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  semCombo(sem) {
    let listSemana: any[] = [];
    for (let i = 1; i <= sem; i++) {
      listSemana.push({ mesNumber: this.getFormaMes(i), mesNombre: this.getMes(i) });
    }
    this.listRango = listSemana.sort(function (a, b) {
      if (a.mesNumber < b.mesNumber) {
        return 1;
      }
      if (a.mesNumber > b.mesNumber) {
        return -1;
      }
      return 0;
    });
  }

  getWeekDayFl(year, week, ve) {
    var primerdia = new Date(year, 0, 1);
    var correccion = 6 - primerdia.getDay();
    var data;
    if (week * 7 + correccion > 365) {
      alert('El valor para semana no es válido');
      return false;
    }
    if (ve == 'a') {
      data = new Date(year, 0, (week - 1) * 7 + 3 + correccion);
    }
    if (ve == 'b') {
      data = new Date(year, 0, (week - 1) * 7 + 9 + correccion);
    }
    return data;
  }

  getMes(mes) {
    const MESES = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return MESES[mes];
  }

  getFormaMes(mes) {
    if (mes < 10) {
      return "0" + mes;
    } else {
      return mes;
    }
  }
}
