import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UsuarioService } from "../../../services/usuario.service";
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from "../../../services/customer.service";
import { BudgetService } from "../../../services/budget.service";
// import { ClienteAsignacionService } from '../../../services/clienteAsignacion.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { ProductService } from "../../../services/product.service";


@Component({
selector: 'app-add-venta',
templateUrl: './add-presupuesto.component.html',
styleUrls: ['./add-presupuesto.component.css'],
providers: [{
provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
}]
})

export class AddPresupuestoComponent implements OnInit {

clienteF = new FormControl();
productoF = new FormControl();
optionCliente: any[] = [];
filteredOptionsCliente: Observable<string[]>;

unidades: any[] = [
{_id: '5fa08bbbb839ec17210f432d', nombre: 'Kilogramos', 'acro':'Kg.', 'across':'Kgs.'},
{_id: '5fa08bc8b839ec17210f432e', nombre: 'Libras', 'acro':'Lb.', 'across':'Lbs.'},
{_id: '5fa2086e44d7d2149c8c522a', nombre: 'Cajas', 'acro':'Caj.', 'across':'Cajas.'},
];

options: any[] = [];
filteredOptions: Observable<string[]>;
criterioCliente: FormGroup;
forMes: FormGroup;
newPresupuesto = {}
total: Number;
table:boolean=false;

displayedColumns: string[] = ['producto','volumen','unidad','precio','monto','opciones'];
dataSource = new MatTableDataSource<any>();

constructor(
private _formBuilder: FormBuilder,
private router: Router,
public dialogRef: MatDialogRef<AddPresupuestoComponent>,
public customerService: CustomerService,
public budgetService: BudgetService,
private productService: ProductService,
private _snackBar: MatSnackBar,
@Inject(MAT_DIALOG_DATA) public data: '') {

}

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;

ngOnInit() {

this.getAllClientes();
this.createForm();
this.getProductos();

this.filteredOptionsCliente = this.clienteF.valueChanges.pipe(
startWith('SIN PRODUCTO'),
map(value => this._filterCliente(value)));

this.filteredOptions = this.productoF.valueChanges.pipe(
startWith('SIN PRODUCTO'),
map(value => this._filter(value)));

this.refresh();
}

refresh(){
this.budgetService.ventaSer$.subscribe(() => {

const id_cliente = this.criterioCliente.controls.cliente.value;
const an = this.criterioCliente.controls.an.value;
const mes = this.criterioCliente.controls.mes.value;

if(id_cliente && an && mes){

this.getAllClientesPre(id_cliente,an,mes);
}
});
}

add(){
if (this.criterioCliente.valid) {
const id_cliente = this.criterioCliente.controls.cliente.value;
const id_producto = this.criterioCliente.controls.producto.value;
const an = this.criterioCliente.controls.an.value;
const mes = this.criterioCliente.controls.mes.value;
const monto = this.criterioCliente.controls.monto.value;
const volumen = this.criterioCliente.controls.volumen.value;
const precio = this.criterioCliente.controls.precio.value;
const unidad = this.criterioCliente.controls.unidad.value;
const m = this.verificarMes(mes);
this.newPresupuesto = {
_id_cliente:id_cliente,
_id_producto:id_producto,
_id_unidad:unidad,
an:an,
mes:mes,
m:m,
volumen:volumen,
precio:precio,
monto:monto,

}
this.budgetService.registroPresupuesto(this.newPresupuesto)
.subscribe(
res => {
this.openSnackBar(res.status, 'ok');
this.budgetService.ventaSer$.emit();
});
this.refresh();
this.budgetService.ventaSer$.emit();
}else{
this.openSnackBar("Todos los campos son obligatorios.", 'alert')
}

}

createForm(){
this.criterioCliente = this._formBuilder.group({
cliente: new FormControl('',[Validators.required]),
producto: new FormControl('',[Validators.required]),
an: new FormControl('',[Validators.required]),
mes: new FormControl('',[Validators.required]),
monto: new FormControl('',[Validators.required]),
volumen: new FormControl('',[Validators.required]),
precio: new FormControl('',[Validators.required]),
unidad: new FormControl('',[Validators.required]),
});
}

getAllClientes(){
this.customerService.getAllClientes().subscribe(
resp => {
this.optionCliente = resp.clientes;
});
}

getAllClientesPre(id_cliente,an,mes){

this.budgetService.getAllClientePre({_id_cliente:id_cliente,an:an,mes:mes}).subscribe(
resp => {
this.dataSource.data = resp.presu;
this.dataSource.paginator = this.paginator;
this.dataSource.sort = this.sort;
if (JSON.stringify(this.dataSource.data)=='[]') {
this.table=false;

}else{

this.table=true;
}

});
}

getProductos(){
this.productService.getAllProductos()
.subscribe(
resp => {
this.options=resp.productos;
});
}

_filterCliente(value: string) {
return this.optionCliente.filter(optionCliente=>
optionCliente.razon_social.toLowerCase().includes(value.toLowerCase()));
}

_filter(value: string) {
return this.options.filter(option=>
option.nombre.toLowerCase().includes(value.toLowerCase()));
}

setCliente(valor){
this.criterioCliente.controls.cliente.setValue(valor);
const cliente = this.criterioCliente.get('cliente').value;
this.budgetService.ventaSer$.emit();
}


setProducto(valor){
this.criterioCliente.controls.producto.setValue(valor);
}


openSnackBar(message: string, tipo: string) {
this._snackBar.open(message, '', {
duration: 5000,
horizontalPosition: 'center',
verticalPosition: 'bottom',
panelClass: [tipo]
});
}

onDelAsig(id){
this.budgetService.delClientePre({id:id}).subscribe(response => {
this.openSnackBar(response.status, 'ok');
this.budgetService.ventaSer$.emit();
}, error => this.openSnackBar("Ha ocurrido un error","Error"));
}

montoValue(value) {
const resultado = formatCurrency(value, 'en-US', getCurrencySymbol('USD', 'wide'));
return resultado;
}

ct($even){

  const volumen = this.criterioCliente.controls.volumen.value;
  const precio = this.criterioCliente.controls.precio.value;
  const a = volumen*precio;
  this.criterioCliente.controls.monto.setValue(a);
  return a;
}

getTotalCost() {
return this.dataSource.data.map(t => t.monto).reduce((acc, value) => acc + value, 0);
}
getTotalVol() {
return this.dataSource.data.map(t => t.volumen).reduce((acc, value) => acc + value, 0);
}

listado($event){
this.budgetService.ventaSer$.emit();
}

verificarMes(mes){
  var m;
        switch (mes) {
        case "ENERO":
        m='1';
        break;
        case "FEBRERO":
        m='2';
        break;
        case "MARZO":
        m='3';
        break;
        case "ABRIL":
        m='4';
        break;
        case "MAYO":
        m='5';
        break;
        case "JUNIO":
        m='6';
        break;
        case "JULIO":
        m='7';
        break;
        case "AGOSTO":
        m='8';
        break;
        case "SEPTIEMBRE":
        m='9';
        break;
        case "OCTUBRE":
        m='10';
        break;
        case "NOVIEMBRE":
        m='11';
        break;
        case "DICIEMBRE":
        m='12';
        break;
        default:
        m='0';
        break;
        }

        return m;

}



}
