import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-ima-user-id',
  templateUrl: './ima-user-id.component.html',
  styleUrls: ['./ima-user-id.component.css'],
})
export class ImaUserIdComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);
  obsNombreApellido = new BehaviorSubject<any>(null);
  loadingIma = true;
  @Input() vUser: string;
  @Input() classCss: string;
  imaf: boolean;
  iga: boolean;
  imagen: any;
  ig: boolean;
  constructor(private usuarioService: UsuarioService) {}

  async ngOnInit(): Promise<void> {
    this.imagen = await this.getImaUser(this.vUser);
  }

  async getImaUser(_id_usuario) {
    let obj;
    await this.usuarioService
      .getImaUser({ _id: _id_usuario })
      .toPromise()
      .then((resp) => {
        this.obsNombreApellido.next(resp[0].nombre + ' ' + resp[0].apellido);
        if (resp.length > 0 && resp[0].imagen != null) {
          obj = resp[0].imagen;
        } else {
          obj = null;
        }
        this.imaf = true;
      })
      .catch((e) => console.error(e));

    return obj;
  }

  validateIma(valor) {
    if (valor === undefined || valor === null || valor === '') {
      this.iga = false;
    } else {
      this.iga = true;
    }

    return valor;
  }

  getSter(name) {
    if (name) {
      const spliName = name.split(' ', 3);
      const nameResult = spliName[0].charAt(0) + spliName[1].charAt(0);
      return nameResult;
    }
  }

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        this.ig = true;
        this.validateIma(this.imagen);
      }, 2000);
    } catch (error) {
      console.log('Se ha generado un error con el perfil del usuario');
    }
  }
}
