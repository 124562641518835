import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-scg-card-cliente',
  templateUrl: './scg-card-cliente.component.html',
  styleUrls: ['./scg-card-cliente.component.css'],
})
export class ScgCardClienteComponent implements OnInit {
  @Input() vEnm;
  @Input() vNum;
  gd1m: boolean;
  gd1: boolean;
  obsGlo = new BehaviorSubject<any>(null);
  constructor() {}

  ngOnInit(): void {
    // if (this.vEnm.length > 0) {
    //   this.gd1m = true;
    // } else {
    //   this.gd1m = false;
    // }
    this.gd1m = true;
    this.gd1 = true;

    this.obsGlo.next([this.vEnm]);
  }

  getResultado(a, b) {
    if (a > 0 && b > 0) {
      var a = a;
      var b = b;
      return b - a;
    } else {
      return '';
    }
  }

  getResBol(a, b) {
    if (a > 0 && b > 0) {
      return (b - a) * 8;
    } else {
      return '';
    }
  }
}
