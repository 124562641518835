<div *ngIf="data;else nodata">

    <div class="ct-h">
        <div class="ct-h-t">Tareas</div>
        <div class="ct-h-i">Resp.</div>
        <div class="ct-h-i">Estado</div>

    </div>

    <div *ngFor="let env of obsTask | async" class="ct-d">

        <div class="ct-d-t" [ngStyle]="{'border-left':env.color+' solid 3px'}">
            <span class="ct-d-ti"> <span class="ct-cit"
                    [ngStyle]="{'background-color':env.color}">{{env.cit}}</span>{{env.nombre}}</span>
            <span class="ct-d-tis"> {{env.des}}</span>
        </div>

        <div class="ct-d-i">
            <app-ima-user-id [vUser]="env.responsable"></app-ima-user-id>
        </div>
        <div>


            <app-task-status [vAr]="vAr" [vTa]="env._id"></app-task-status>



        </div>

    </div>

</div>

<ng-template #nodata>
    <app-info-data [mensaje]="'Aun no hay tareas asignadas'" [pd]=""> </app-info-data>
</ng-template>