import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ChannelService } from "./../../services/channel.service";
import { CustomerService } from "./../../services/customer.service";
import { AddChannelComponent } from "./add-channel/add-channel.component";
import { DelChannelComponent } from "./del-channel/del-channel.component";
import { TrasChannelComponent } from "./tras-channel/tras-channel.component";
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ChannelComponent implements OnInit {
  expandedElement: [PeriodicElement] | null;
  dataResult = new MatTableDataSource<any>();
  dataSourceCli = new MatTableDataSource<any>();
  displayedColumnsCli: string[] = ['select', 'cod', 'ima', 'razon_social'];
  classCss = "ima_cliente";
  selection = new SelectionModel<PeriodicElement>(true, []);
  displayedColumns: string[] = ['createdAt', 'canal', 'descri', 'op'];
  obs = new BehaviorSubject<any>(null);
  obsCust = new BehaviorSubject<any>(null);
  public date: any;
  public searchText: any;
  toggle = true;
  status = 'Enable';
  loading = false;
  condi: String;
  panelOpenState = false;
  btt: boolean;
  bttn: number;
  removable = true;
  vCh: any;
  schemaStatus = true;
  fils = new FormControl();
  constructor(
    private channelService: ChannelService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {  }

  @ViewChild(MatPaginator) paginators: MatPaginator;
  @ViewChild(MatSort) sorts: MatSort;


  ngOnInit(): void {
    this.btt = false;
    this.bttn = 0;
    this.getAllCanal();
    this.channelService.canalSer$.subscribe(() => {
      this.getAllCanal();
    });
    this.channelService.canalSerLi$.subscribe((valor) => {
      this.getCustChannelId(valor.vCh);
    });
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obs.next(resp);
      this.dataResult.data = resp;
      this.loading = false;
      this.schemaStatus = false;
    });
  }

  getCustChannelId(vCh) {
    this.customerService.getCustChannelId({ vCh: vCh }).subscribe(resp => {
      this.dataSourceCli.data = resp;
      this.dataSourceCli.paginator = this.paginators;
      this.dataSourceCli.sort = this.sorts;
      this.changeDetectorRef.detectChanges();
      this.schemaStatus = true;
    });
  }

  ngAfterViewInit() {
    this.dataSourceCli.paginator = this.paginators;
    this.dataSourceCli.sort = this.sorts;
  }

  onDialogAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    this.dialog.open(AddChannelComponent, dialogConfig);
  }

  onDialogTras() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.data = this.selection.selected;
    this.dialog.open(TrasChannelComponent, dialogConfig);
  }

  onDelAsig(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "40%";
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(DelChannelComponent, dialogConfig);
  }

  formatDate(fecha) {
    return fecha.substr(0, 10);
  }

  exeCustData(vNom, vCh) {
   
    this.schemaStatus = false;
    this.condi = vNom;
    this.getCustChannelId(vCh)
    this.vCh = vCh;
    this.fils.reset();
    const filterValue = "";
    this.dataSourceCli.filter = filterValue.trim().toLowerCase();
  }

  getColorl(i) {
    const val = i % 2;
    if (val === 0) {
      return "divRowb";
    } else {
      return "divRoww";
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCli.filter = filterValue.trim().toLowerCase();
  }

  remove(vCli): void {
    const index = this.selection.selected.indexOf(vCli);
    if (index >= 0) {
      this.selection.selected.splice(index, 1);
    }
    if (this.selection.selected.length == 0) {
      this.selection.clear();
    }
  }

  isAllSelected() {

    if (this.selection.selected.length > 0) {
      this.btt = true;
      this.bttn = this.selection.selected.length;
    } else {
      this.btt = false;
      this.bttn = 0;
      this.selection.clear();
    }

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceCli.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceCli.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row._id + 1}`;
  }
}

export interface PeriodicElement {
  _id: string;
  razon_social: number;
}