<section class="content">
  <section class="content-login">
    <span class="content-login--logo"></span>
    <form [formGroup]="form" method="post" (ngSubmit)="login();">
      <mat-form-field>
        <mat-label>Correo</mat-label>
        <input matInput placeholder="info@profusa.net" formControlName="email" required autocomplete="off">
        <mat-error *ngIf="email.invalid">{{getErrorMessageEmail()}}</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Contraseña</mat-label>
        <input matInput [type]="'password'" formControlName="clave" required autocomplete="off">

        <mat-error *ngIf="clave.invalid">{{getErrorMessageClave()}}</mat-error>
      </mat-form-field>


      <div class="btn-entrar-center">
        <button type="submit" class="btn-agregar" mat-raised-button color="primary">

          <!-- <div class="spinner-container" *ngIf="loading">
            <mat-spinner class="orange-spinner" diameter="24"></mat-spinner>
          </div> -->

          <div class="spinner-container" *ngIf="loading; else jose">
            <mat-spinner class="orange-spinner" diameter="24"></mat-spinner>
          </div>

          <ng-template #jose>
            <span > Entrar </span>
          </ng-template>


        </button>

      </div>
    </form>
  </section>
</section>
