import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UsuarioService } from '../../../../services/usuario.service';
import { AssignedCustomerService } from '../../../../services/assignedclient.service';

interface Ejecutivo {
  _id_vendedor_old?: string,
  _id_vendedor_now: string,
  nombre: string,
  apellido: string,
  yeara?: number,
  mes?: number,
  mest?: string,
  arrayDat?: []
}

@Component({
  selector: 'app-asig-traslado',
  templateUrl: './asig-traslado.component.html',
  styleUrls: ['./asig-traslado.component.css']
})
export class AsigTrasladoComponent implements OnInit {
  local_data: any;
  ejecutivoF = new FormControl();
  selec = new FormControl();
  selecm = new FormControl();
  seleca = new FormControl();
  filteredOptionsEjecutivo: Observable<string[]>;
  optionEjecutivo: any[] = [];
  objEje: Ejecutivo = {
    _id_vendedor_old: "",
    _id_vendedor_now: "",
    nombre: "",
    apellido: "",
    yeara: 0,
    mes: 0,
    mest: "",
    arrayDat: []
  }
  idvendedor: any;
  arraydata: any;
  info: any;
  info_a: any;
  info_b: any;

  ass: boolean = true; info_c: any;
  reslist: any;
  info_ca: boolean;
  info_aa: boolean;
  numA: number;
  numb: number;
  info_ab: boolean;
  ;
  bss: boolean;
  constructor(
    private usuarioService: UsuarioService,
    public dialogRef: MatDialogRef<AsigTrasladoComponent>,

    private assignedCustomerService: AssignedCustomerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getAllEjecutivo();
    this.local_data = this.data;
    this.idvendedor = this.data._id_ejecutivo_actual;
    this.arraydata = this.data.data;


    this.selecm.setValue(this.data.mes);
    this.seleca.setValue(this.data.yeara);

  }

  ngOnInit(): void {
    this.filteredOptionsEjecutivo = this.ejecutivoF.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterEjecutivo(value))
    );
  }

  getAllEjecutivo() {
    this.usuarioService.getAllUsuarios().subscribe((resp) => {
      this.optionEjecutivo = resp.usuario;
    });
  }

  _filterEjecutivo(value: string) {
    if (value)
      return this.optionEjecutivo.filter((optionEjecutivo) =>
        optionEjecutivo.nombre.toLowerCase().includes(value.toLowerCase())
      );
  }

  setEjecutivoAsing(valor, nombre, apellido) {
    this.objEje = { _id_vendedor_now: valor, nombre: nombre, apellido: apellido };

  }

  ver() {
    this.ass = false;
    this.bss = true;

  }

  addtrasla() {

    this.ass = false;
    this.bss = true;
 
    const mes = this.selecm.value;
    const yeara = this.seleca.value;
    this.objEje.yeara = yeara;
    this.objEje.mes = mes;
    this.objEje.mest = this.verificarMes(mes);
    this.objEje._id_vendedor_old = this.idvendedor;
    this.objEje.arrayDat = this.arraydata;

    this.uptadeAsigTras(this.objEje);

  }

  uptadeAsigTras(objeto) {
    this.assignedCustomerService.uptadeAsigTras(objeto).subscribe((resp) => {
      this.reslist = resp.data_vector;
      let asi =  this.reslist.filter(element => element.value=="false");
      let asit =  this.reslist.filter(element => element.value=="true");
      var resv = this.reslist.reduce((sum, value) => (sum + value.ventas), 0);


     if(asit){
       this.numA=asit.length;
     }else{
      this.numA=0;
     }

     if(this.reslist.length){
      this.numb=this.reslist.length;
    }


      if (asi.length > 0) {
        this.info_aa = false;
        this.info_ab = false;
      } else {
        this.info_aa = true;
        this.info_ab = true;
      }

      if (resv > 0) {
        this.info_ca = true;
      } else {
        this.info_ca = false;
      }


      this.info_a = true;
      this.info_b = true;
      this.info_c = true;

    });
  }




  updateAns(event) { }

  verificarMes(mes) {
    const MESES = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return MESES[mes];
  }


  onClose() {
    this.dialogRef.close();
  }



  getAler(valor) {
    var result: String;
    if (valor === "true") {
      result = "check_circle";
    } else {
      result = "running_with_errors";
    }
    return result;
  }
}
