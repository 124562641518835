<div class="conte-mo">
    <div class="titulo-row-b">
      Trasladar producto a otro canal:
    </div>
    <mat-divider></mat-divider>
    <div class="diForm">
    <form [formGroup]="form" method="post" (ngSubmit)="add()">
      <mat-form-field appearance="fill" class="mat-form-f">
        <mat-label>Canal</mat-label>
        <mat-select formControlName="canal" required>
          <mat-option *ngFor="let env of obs | async" value="{{env._id}}-{{env.canal}}"> {{env.canal}} </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <div class="btn-enviar">
        <button class="btn-agregar" mat-raised-button color="primary">
          Trasladar
        </button>
      </div>
    </form>
  </div>
  </div>