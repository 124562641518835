import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { SalesService } from "../../services/sales/sales.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  rol_Vendedor=["vendedor"];
  fecha: any;

  constructor(
    public loginService: LoginService,
    private salesService: SalesService,) { }

  ngOnInit(): void {
    this.getUltimaUp();
  }


  getUltimaUp() {
    this.salesService.getUltimaUp().subscribe(
      resp => {
        this.fecha = resp[0].fecha;
      });
  }
  

  formatDate(fecha) {
    if (fecha) {
      return fecha.substr(0, 10);
    }
  }

}
