<!-- <div *ngIf="show;else wnoda"> -->


    <div *ngIf=" ss1;else rnoda">





        <carousel *ngIf="this.show" class="ctg" [height]="'220'" [objectFit]="'cover'" [cellWidth]="'100%'"
            [arrows]="true" [arrowsOutside]="false" [autoplay]="false" [dots]="true" [loop]="false">
            <div class="carousel-cell" *ngFor="let env of obsCli | async; index as i">
                <app-s-l-c-espcifico [vNum]="i" [vCh]="vCh" [vYear]="vYear" [vCli]="env.id_epicor" [vCliD]="env._id_cliente"
                    [vRs]="env.razon_social">
                </app-s-l-c-espcifico>
            </div>
        </carousel>



    </div>
    <ng-template #rnoda>

        <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]=""> </app-info-data>

    </ng-template>
<!-- </div>

<ng-template #wnoda>

    <div class="ct-cadew">
        <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '450px',
            'height': '430px',
            'background-color': '#daddd8'
        }">
        </ngx-skeleton-loader>
    </div>
</ng-template> -->