import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "../../../services/sales/sales.service";

@Component({
  selector: 'app-s-ven-pre-mes',
  templateUrl: './s-ven-pre-mes.component.html',
  styleUrls: ['./s-ven-pre-mes.component.css']
})
export class SVenPreMesComponent implements OnInit {
  @Input() vYear: number;
  @Input() vMes: number;
  @Input() vMesa: string;
  @Input() vD: string;
  @Input() vH: string;
  @Input() env: Object;
  @Input() vPre: number;
  obs = new BehaviorSubject<any>(null);
  total = new BehaviorSubject<any>(null);
  tVen: any;
  dataGra: any[] = [];
  mats = false;
  // options
  showXAxis: boolean = false;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  roundDomains: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  customColors: any[] = [
    { name: "INDUSTRIAL", value: '#99c1de' },
    { name: "RETAIL", value: '#eddcd2' },
    { name: "FRANQUICIA", value: '#c7f9cc' },
    { name: "FOOD SERVICE", value: '#c4f2cc' },
    { name: "SUMINISTROS", value: '#c25cd8' },
    { name: "MAQUILAS", value: '#f3e440' },
  ];

  colorScheme = {
    domain: ['#3498DB', '#26a116']
  };
  constructor(
    private salesService: SalesService
  ) { }

  ngOnInit() {

    

    this.getVenYearCanalMasterMes(this.vYear, this.vMes, this.vD, this.vH);
  }

  getVenYearCanalMasterMes(vYear, vMes, vD, vH) {
    let series = [];
    this.salesService.getVenYearCanalMasterMes({ vYear: vYear, vMess:vMes, vD:vD, vH:vH }).subscribe((resp) => {
      this.obs.next(resp);
      
      const sumall = resp.map(item => item.value).reduce((prev, curr) => prev + curr, 0);
      this.total.next(sumall);
      this.tVen = sumall;
      let dar = resp.map((val) => {
        series.push(val
        );
        return val;
      });
      this.dataGra.push(
        {
          name: "VEN ",
          series: dar
        },
      );
      this.mats = true;
      this.dataGra = [...this.dataGra];
    });
  }

getNameMatriz(name){
  var nombreCanal = name.split(" ", 3); 
  return nombreCanal[0]
}


}
