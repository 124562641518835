import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  vRo:string="6303c1ccae5b0a1ee6ab439a";
  vUs:string="6066596961607218a9a96240";
  constructor() { }

  ngOnInit(): void {
  }

}
