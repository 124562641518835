<div class="conten">
    <div class="ct-value-cero" *ngIf="value==0;else nodata">
        <div class="item-value-cero">  </div>
    </div>
    <ng-template #nodata>
        <div class="{{cals}}">{{value | number: '1.2-2' }}% </div>
    </ng-template>
</div>

<!-- 08930037590116910596
75120241120316844035
73260036006216910594 -->