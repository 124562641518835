<div class="pw" fxLayout="row wrap" fxLayoutGap="1px grid">

    <div class="divpa" *ngFor="let item of lista;let i = index">

        <mat-card class="int {{item.value}}"
            (click)="onDelAsigG(item._id,item.in,item.estado,item.value, this.op, item.ico, this.datellegada, this.dateRepro)">
            <div class="row-pro-r">{{item.in}}</div>
            <div>
                <mat-icon class="ico {{item.value}}">{{item.ico}}</mat-icon>
            </div>
            <mat-card-title class="txte">{{item.estado}}</mat-card-title>
        </mat-card>

    </div>

</div>





<!-- <div class="bb"><span class="bar"></span></div> -->
<!-- <div class="line1"></div> -->