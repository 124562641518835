import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "../../../../services/sales/sales.service";

@Component({
  selector: 'app-sales-produc',
  templateUrl: './sales-produc.component.html',
  styleUrls: ['./sales-produc.component.css']
})
export class SalesProducComponent implements OnInit {
  @Input() vPro:string;
  @Input() vD:Date;
  @Input() vH:Date;

  public searchText: any;
  dataPro = new BehaviorSubject<any>(null);
  panelOpenState = false;
  vlistWiat: any[] = [];
  vShow: boolean;

  constructor(
    private salesService: SalesService
  ) { }

  ngOnInit(): void {
    this.listWiat();
    this.getDataProductoSearch(this.vPro, this.vD, this.vH) 
  }
  getDataProductoSearch(vPro, vD, vH) {
    this.salesService.getDataProductoSearch({
      _id_producto: vPro,
      desde: vD,
      hasta: vH,
    }).subscribe((resp) => {

      this.dataPro.next(resp);
      this.vShow=true;
    });
  }
  listWiat() {
    for (let i = 0; i < 5; i++) {
      this.vlistWiat.push({idat:i});
    }
  }

}
