import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-per-cli-ind',
  templateUrl: './per-cli-ind.component.html',
  styleUrls: ['./per-cli-ind.component.css']
})
export class PerCliIndComponent implements OnInit {
  @Input() va: number;
  @Input() vb: number;
  cals: string;
  icc: string;
  total: number;
  vtotal: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.getValor(this.va, this.vb);
  }

  getValor(va, vb) {
    if (va && vb) {
      this.total = ((va - vb) / vb) * 100;
      this.validateValue(this.total);
      this.vtotal = true;
    }else if(vb===0 && va>0){
      this.total = 100;
      this.vtotal = true;
      this.validateValue(this.total);
    
  }else if(va===0 && vb>0){
    this.total = Number(-100);
    this.vtotal = true;
    this.validateValue(this.total);
  }
  }

  validateValue(value) {
    if (value > 0) {
      this.cals = "gree";
      this.icc = "trending_up";
    } else {
      this.cals = "gred";
      this.icc = "trending_down";
    }
  }


}
