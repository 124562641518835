<div class="conte-pro-m">
  <div class="titulo-row-m">
    <button mat-icon-button>
      <mat-icon>{{this.ico}}</mat-icon>
    </button>
    {{this.gestado}}

    <span class="completed">Culminado exitosamente: </span>
    <mat-slide-toggle [checked]="this.gva == 'true' ? 'true' : 'false'" (change)="changeEstado($event,this.gid)"
      color="primary"></mat-slide-toggle>

  </div>




  <mat-divider></mat-divider>
  <mat-grid-list class="fechas" cols="7" rowHeight="70px">
    <form [formGroup]="secundFormGroup" class="field">
      <mat-grid-tile colspan="3">
        <mat-form-field appearance="fill" class="mat-form-f">
          <mat-label>Fecha original</mat-label>
          <input matInput [matDatepicker]="dp2" formControlName="adate">
          <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
          <mat-datepicker #dp2>
          </mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <div class="repro">
          <mat-slide-toggle class="repro" 
            (change)="changeRepro($event,this.gid)" color="primary"></mat-slide-toggle>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-form-field appearance="fill" class="mat-form-f">
          <mat-label>
            Nueva Fecha</mat-label>
          <input matInput [matDatepicker]="dp1" formControlName="date"
            (dateChange)="updateEstadoUpRe($event,this.id_envio);">
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1>
          </mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
    </form>
  </mat-grid-list>

<mat-grid-list cols="10" rowHeight="70px">
  <mat-grid-tile colspan="8">
    <form [formGroup]="firstFormGroup" class="field">
      <mat-form-field class="field">
        <mat-label>Comentario</mat-label>
        <input matInput formControlName="ob" required autocomplete="off">
      </mat-form-field>
    </form>
  </mat-grid-tile>

  <mat-grid-tile colspan="2">
    <button (click)="add();" class="mat-list-item" color="primary" mat-raised-button mat-button>
      <mat-icon matListIcon>add</mat-icon>
    </button>
  </mat-grid-tile>
</mat-grid-list>



  <div *ngIf="this.table; else nodata">
    <div class="table-container special">
    <table mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">
      <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{formatDate(element.createdAt)}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="ob">
        <th mat-header-cell *matHeaderCellDef> Comentario </th>
        <td mat-cell *matCellDef="let element"> {{element.ob.toUpperCase()}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>


      <ng-container matColumnDef="op">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button matTooltip="¿Seguro que deseas borrar este item?" matTooltipClass="example-tooltip-red"
              mat-menu-item (click)="onDelAsig(element._id)">
              <mat-icon>delete_forever</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>

  </div>
</div>


  <ng-template #nodata>


    <div class="g-card">
      <mat-card class="g-card">
        <mat-card-title>
          <mat-icon class="ico-m" matListIcon>report_problem</mat-icon>
        </mat-card-title>
        <mat-card-subtitle>
        Aun no hay comentarios
        </mat-card-subtitle>
      </mat-card>
    </div>

  </ng-template>



</div>