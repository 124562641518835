import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ProductService } from '../../../services/product.service';
import { ProyectoService } from '../../../services/proyecto.service';
import { UnidadNegocio } from '../../../services/unidadNegocio.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChannelService } from './../../../services/channel.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface DialogData {
  animal: string;
  name: string;
}

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-up-producto',
  templateUrl: './up-producto.component.html',
  styleUrls: ['./up-producto.component.css'],
})
export class UpProductoComponent implements OnInit {
  aFormGroup: FormGroup;
  hide = true;
  file: File;
  ima64: String | ArrayBuffer;
  ima64Decode: String | ArrayBuffer;
  photoSelected: String | ArrayBuffer;
  id_parte = new FormControl('', [Validators.required]);
  nombre = new FormControl('', [Validators.required]);
  ima = new FormControl('');
  tipos = [];
  unidadNegocio = [];
  newProducto = {};
  local_data: any;
  obs = new BehaviorSubject<any>(null);
  gid: String;
  gnombre: String;
  gdescriocion: String;
  gpresentacion: String;
  gunidad: String;
  gcanal: String;
  gimagen: String;
  gnumeroparte;
  gprcanal;
  gubox: any;
  upc: any;
  moneda: any;
  gcbag: any;
  gcbox: any;

  constructor(
    private channelService: ChannelService,
    private productoService: ProductService,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public unidadNegocioService: UnidadNegocio,
    public proyectoService: ProyectoService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpProductoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.local_data = this.data;
    this.gid = this.local_data.data._id;
    this.gnumeroparte = this.local_data.data.numeroparte;
    this.gnombre = this.local_data.data.nombre;
    this.gdescriocion = this.local_data.data.descripcion;
    this.gpresentacion = this.local_data.data.presentacion;
    this.gunidad = this.local_data.data.unidad;
    this.gcanal = this.local_data.data.canal;
    this.gimagen = this.local_data.data.imagen;
    this.gprcanal = this.local_data.data.canal;

    this.gubox = this.local_data.data.ubox;
    this.upc = this.local_data.data.upc;
    this.moneda = this.local_data.data.moneda;

    this.gcbag = this.local_data.data.cbag;
    this.gcbox = this.local_data.data.cbox;
  }

  ngOnInit() {
    this.getAllCanal();
    this.aFormGroup = this._formBuilder.group({
      cod_epicor: [this.gnumeroparte, Validators.required],
      nombre: [this.gnombre, Validators.required],
      ima: [''],
      unidad_negocio: [this.gunidad, Validators.required],
      des: [this.gdescriocion, Validators.required],
      pre: [this.gpresentacion, Validators.required],
      canal: [this.gprcanal, Validators.required],
      ubox: [this.gubox],
      upc: [this.upc],
      moneda: [this.moneda],
      cbox: [this.gcbox],
      cbag: [this.gcbag],
    });

    this.decodeBase64(this.gimagen);
    this.getAllTipo();
    this.getAllUnidadNegocio();
  }

  up() {
    const ima = this.aFormGroup.get('ima').value;

    if (this.aFormGroup.valid) {
      const cod_epicor = this.aFormGroup.get('cod_epicor').value;
      const nombre = this.aFormGroup.get('nombre').value;
      const unidad_negocio = this.aFormGroup.get('unidad_negocio').value;
      const des = this.aFormGroup.get('des').value;
      const pre = this.aFormGroup.get('pre').value;
      const ima = this.aFormGroup.get('ima').value;
      const vCh = this.aFormGroup.get('canal').value;
      const upc = this.aFormGroup.get('upc').value;
      const ubox = this.aFormGroup.get('ubox').value;
      const moneda = this.aFormGroup.get('moneda').value;
      const cbox = this.aFormGroup.get('cbox').value;
      const cbag = this.aFormGroup.get('cbag').value;
      this.newProducto = {
        _id: this.gid,
        numparte: cod_epicor,
        nombre: nombre,
        descripcion: des,
        presentacion: pre,
        canal: vCh,
        unidad: unidad_negocio,
        upc: upc,
        moneda: moneda,
        ubox: ubox,
        cbox: cbox,
        cbag: cbag,
        imagen: this.ima64,
      };

      this.productoService.updateProducto(this.newProducto).subscribe(
        (res) => {
          this.onClose();
          this.openSnackBar(res.status, 'ok');
        },
        (err) => {
          console.log(err);
          this.openSnackBar(err.error.error, 'alert');
        }
      );
    }
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe((resp) => {
      this.obs.next(resp);
    });
  }

  getAllTipo() {
    this.proyectoService.getAllTipoProyecto().subscribe(
      (resp) => {
        this.tipos = resp.tipos;
      },
      (err) => {}
    );
  }

  getAllUnidadNegocio() {
    this.unidadNegocioService.getAllUnidadNegocio().subscribe(
      (resp) => {
        this.unidadNegocio = resp.UnidadNegocioRespo;
      },
      (err) => {}
    );
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  onFileSelected(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file = <File>event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.photoSelected = reader.result);
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.ima64 = reader.result;
        this.photoSelected = reader.result;
      };
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getErrorMessageCodEpicor() {
    if (this.id_parte.hasError('required')) {
      return 'Se requiere el Codigo EPICOR';
    }
  }

  getErrorMessageNombre() {
    if (this.nombre.hasError('required')) {
      return 'Se requiere el nombre del producto';
    }
  }

  getErrorMessagePre() {
    if (this.nombre.hasError('required')) {
      return 'Se requiere la presentacion del producto';
    }
  }

  getErrorMessageDes() {
    if (this.nombre.hasError('required')) {
      return 'Se requiere la descripcion del producto';
    }
  }

  decodeBase64(ima64Endode) {
    this.photoSelected = ima64Endode;
  }
}
