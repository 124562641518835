import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-mensual-ind',
  templateUrl: './pre-mensual-ind.component.html',
  styleUrls: ['./pre-mensual-ind.component.css']
})
export class PreMensualIndComponent implements OnInit {

  @Input() value: number;
  cals: string;
  constructor() { }

  ngOnInit(): void {
    this.validateValue();
  }


  validateValue() {
    if (this.value >= 0  && this.value < 11) {
      this.cals = "gred";
    }

    else if (this.value > 10 && this.value < 41) {
      this.cals = "na";

    }
    else if (this.value > 40 && this.value < 61) {
      this.cals = "yeel";
    }

    else if (this.value > 60  && this.value < 100) {
      this.cals = "ble";
    }

    else if (this.value >= 100) {
      this.cals = "gree";
    }

  }

}
