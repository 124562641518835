<div class="divi" *ngIf="!c1; else cactivo">

  <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Envio</ng-template>


        <mat-grid-list cols="10" rowHeight="75px">
          <mat-grid-tile colspan="10">

            <mat-form-field class="example-full-sa">
              <mat-label>COP</mat-label>
              <input matInput placeholder="" formControlName="cop" required autocomplete="off">
            </mat-form-field>

            <mat-form-field class="example-full-s">
              <mat-label>Codigo de Operación</mat-label>
              <input matInput placeholder="" formControlName="ops" required autocomplete="off">
            </mat-form-field>

            <mat-form-field class="example-full-s">
              <mat-label>Tipo de envio</mat-label>
              <mat-select formControlName="te">
                <mat-option value="Nacional">
                  Nacional
                </mat-option>
                <mat-option value="Internacional">
                  Internacional
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="10">
            <mat-form-field class="example-full-width">
              <input type="text" placeholder="Ejemplo: AlFrut..." aria-label="Number" matInput [formControl]="clienteF"
                [matAutocomplete]="autoCliente">
              <mat-autocomplete autoActiveFirstOption #autoCliente="matAutocomplete">
                <mat-option *ngFor="let optionCliente of filteredOptionsCliente | async"
                  [value]="optionCliente.razon_social" (click)="setCliente(optionCliente._id);">
                  <div class="listadoProducto">
                    {{optionCliente.id_epicor}} - {{optionCliente.razon_social}}
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>



            <mat-form-field class="invisible">
              <input matInput formControlName="_id_cliente" autocomplete="off">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="10">
            <mat-form-field>
              <mat-label>Destino</mat-label>
              <input matInput placeholder="" formControlName="destino" required autocomplete="off">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="10">
            <mat-form-field appearance="fill" class="mat-form-fielda">
              <mat-label>Fecha de Salida</mat-label>
              <input matInput [matDatepicker]="dp1" formControlName="fecha_salida">
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1>

              </mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" class="mat-form-fielda">
              <mat-label>Fecha de Llegada</mat-label>
              <input matInput [matDatepicker]="dp2" formControlName="fecha_llegada" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2>
              </mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <div class="done">

          <button mat-button class="mat-list-item-m" color="primary" mat-raised-button matStepperNext>Siguiente
            ></button>
        </div>


      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Producto</ng-template>
        <mat-grid-list cols="10" rowHeight="66px">
          <mat-grid-tile colspan="10">
            <div class="dataGrid">
              <mat-form-field class="full-width">
                <input type="text" placeholder="Ejemplo: Fresa..." aria-label="Number" matInput
                  [formControl]="productoF" [matAutocomplete]="autoProducto">
                <mat-autocomplete autoActiveFirstOption #autoProducto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre"
                    (click)="setProducto(option._id);">
                    <div class="listadoProducto">
                      {{option.numparte}} - {{option.nombre}}
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="4">

            <mat-form-field>
              <mat-label>Unidad</mat-label>
              <mat-select formControlName="unidad">
                <mat-option *ngFor="let uni of dataResultUni" [value]="uni._id">
                  {{uni.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>



          </mat-grid-tile>
          <mat-grid-tile colspan="4">
            <div class="dataGrid">
              <mat-form-field>
                <mat-label>Volumen</mat-label>
                <input matInput placeholder="" autocomplete="off" type="number" formControlName="volumen">
              </mat-form-field>
            </div>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">

            <button (click)="addItems();" autocomplete="off" class="mat-list-item-m" color="primary" mat-raised-button
              mat-button>
              <mat-icon matListIcon>add</mat-icon>
            </button>

            <button (click)="getAllProductoEnvio();" class="mat-list-item-m" color="primary" mat-raised-button
              mat-button>
              <mat-icon matListIcon>update</mat-icon>
            </button>

          </mat-grid-tile>

        </mat-grid-list>



        <div *ngIf="this.table">
          <div class="table-container special">
          <table mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">
            <ng-container matColumnDef="pro">
              <th mat-header-cell *matHeaderCellDef> Producto </th>
              <td mat-cell *matCellDef="let element"> {{element._id_producto.nombre}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="vol">
              <th mat-header-cell *matHeaderCellDef> Volumen </th>
              <td mat-cell *matCellDef="let element"> {{element.volumen}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="op">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                  <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button matTooltip="¿Seguro que deseas borrar este item?" matTooltipClass="example-tooltip-red"
                    mat-menu-item (click)="onDelAsig(element._id)">
                    <mat-icon>delete_forever</mat-icon>
                    <span>Eliminar</span>
                  </button>
                </mat-menu>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
          </table>
        </div>
        </div>
        <div class="done">
          <button mat-button class="mat-list-item-m" color="primary" mat-raised-button matStepperPrevious>
            < Atras</button>
              <button mat-button class="mat-list-item-m" color="primary" mat-raised-button matStepperNext>Siguiente
                ></button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Finalizar</ng-template>

      <div class="done">
        <h2>Tu envío iniciará con el estado: Preparando envio</h2>
        <button mat-button class="mat-list-item-m" color="primary" mat-raised-button matStepperPrevious>
          < Atras</button>
            <button mat-button class="mat-list-item-m" color="primary" mat-raised-button
              (click)="add();">Guardar</button>

      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>


<ng-template #cactivo>

  <div class="contepro" #cactivo>
    <div class="wrapper">
      <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>

    </div>

  </div>


</ng-template>