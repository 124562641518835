<div class="conte-pro">
  <div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/dashboard']">
      <mat-icon>navigate_before</mat-icon>
    </button>
    Listado de roles
  </div>
  <mat-divider></mat-divider>
  <div class="columna1">
    <mat-form-field>
      <mat-label>Filtro</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input autocomplete="off">
    </mat-form-field>
  </div>

  <div class="columna2">
    <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button (click)="onDialogAdd()">
      <mat-icon matListIcon>add</mat-icon> Nuevo Rol
    </button>

  </div>

  <table mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let element"> {{element.name.toUpperCase()}} </td>
    </ng-container>

    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef> Fecha </th>
      <td mat-cell *matCellDef="let element"> {{formatDate(element.createdAt)}} </td>
    </ng-container>





    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell *matCellDef="let element">

        <!--        <mat-slide-toggle [checked]="element.estado == 1 ? 'true' : 'false'" (change)="changeEstado($event,element._id)"
                      color="primary"></mat-slide-toggle> -->

      </td>
    </ng-container>


    <ng-container matColumnDef="opciones">
      <th mat-header-cell *matHeaderCellDef> Opciones </th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">



          <button matTooltip="¿Seguro que deseas borrar este rol?" matTooltipClass="example-tooltip-red" mat-menu-item
            (click)="onDialogDel(element._id)">
            <mat-icon>delete_forever</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>



      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No hay producto con ese criterio "{{input.value}}"</td>
    </tr>
  </table>