import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerService } from "../../../../services/customer.service";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ProductService } from "./../../../../services/product.service";
import { EnviosService } from "../../../../services/envios.service";
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { DelEnviosComponent } from "../../del-envios/del-envios.component";
import { UnidadesService } from "../../../../services/unidades.service";

import * as _moment from 'moment';
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-bloque-cliente',
  templateUrl: './bloque-cliente.component.html',
  styleUrls: ['./bloque-cliente.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class BloqueClienteComponent implements OnInit {
  displayedColumns: string[] = ['pro', 'vol', 'op'];
  dataSource = new MatTableDataSource<any>();
  firstFormGroup: FormGroup;
  clienteF = new FormControl();
  optionCliente: any[] = [];
  productoF = new FormControl();
  options: any[] = [];
  filteredOptionsCliente: Observable<string[]>;
  filteredOptions: Observable<string[]>;
  secondFormGroup: FormGroup;
  local_data: any;
  gid: any;
  gop: any;
  newEnvio: {};
  newProducto: {};
  c1: Boolean;
  dataResultUni: any;

  table: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  glg: any;
  gsem: any;

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private productService: ProductService,
    private customerService: CustomerService,
    private enviosService: EnviosService,
    private unidadesService: UnidadesService,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<BloqueClienteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {


    this.local_data = this.data;
    this.gop = this.local_data.data.op;
    this.glg = this.local_data.data.lg;
    this.gsem = this.local_data.data.sem;


    this.createForm();
    this.getCodIntOp();
    this.getAllClientes();
    this.getAllProductos();
    this.getAllUnidades();
    this.refresh();
  }

  ngOnInit(): void {

  }


  refresh() {
    this.enviosService.envSer$.subscribe(() => {
      this.getAllProductoEnvio();
    });
  }


  getCodIntOp() {
    this.enviosService.getCodIntOp().subscribe(
      res => {
        const cod_interno = res.cod_interno;
        this.firstFormGroup.patchValue({
          cop: cod_interno
        });


      });
  }

  createForm() {

    this.firstFormGroup = this._formBuilder.group({
      cop: [{ value: 'Cargando nuevo codigo...', disabled: true }, Validators.required],
      ops: ['', Validators.required],
      te: ['', Validators.required],
      _id_cliente: ['', Validators.required],
      destino: ['', Validators.required],
      fecha_salida: ['', Validators.required],
      fecha_llegada: ['', Validators.required],

    });
    this.secondFormGroup = this._formBuilder.group({
      producto: ['',],
      volumen: ['',],
      unidad: ['',]
    });

    this.filteredOptions = this.productoF.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)));

    this.filteredOptionsCliente = this.clienteF.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCliente(value)));
  }


  _filter(value: string) {
    if (value) {
      return this.options.filter(option =>
        option.numparte.toLowerCase().includes(value.toLowerCase()));
    }


  }

  _filterCliente(value: string) {
    if (value) {
      return this.optionCliente.filter(optionCliente =>
        optionCliente.razon_social.toLowerCase().includes(value.toLowerCase()));
    }
  }


  getAllClientes() {
    this.c1 = true;
    this.customerService.getAllClientes().subscribe(
      resp => {
        this.optionCliente = resp.clientes;
        this.c1 = false;
      });
  }

  getAllProductos() {

    this.productService.getAllProductos().subscribe(
      resp => {
        this.options = resp.productos;


      });
  }

  getAllProductoEnvio() {


    if (this.firstFormGroup.valid) {
      const sop = this.firstFormGroup.get('ops').value;
      const cop = this.firstFormGroup.get('cop').value;
      const op = cop + "-" + sop;
      const ci = this.gop;
      const _id_cliente = this.firstFormGroup.get('_id_cliente').value;
      this.enviosService.getAllProductoEnvio({ op: op, ci: ci, _id_cliente: _id_cliente }).subscribe(
        resp => {

          //this.dataSource = resp.segProd;
          this.dataSource.data = resp.segProd;
          let datap = resp.segProd;


          if (datap.length > 0) {

            this.table = true;
          } else {
            this.table = false;


          }


        });

    } else {
      this.openSnackBar("Existe un error, verifica los datos", 'alert');
    }
  }



  setCliente(valor) {
    this.firstFormGroup.controls._id_cliente.setValue(valor);
  }

  setProducto(valor) {
    this.secondFormGroup.controls.producto.setValue(valor);

  }


  onClose() {
    this.dialogRef.close();
  }


  // addCliente() {


  //   if (this.firstFormGroup.valid) {
  //     const _id_cliente = this.firstFormGroup.get('_id_cliente').value;
  //     const destino = this.firstFormGroup.get('destino').value;
  //     const fecha_salida = this.firstFormGroup.get('fecha_salida').value;
  //     const fecha_llegada = this.firstFormGroup.get('fecha_llegada').value;


  //     let nCli = {
  //       _id_cliente: _id_cliente,
  //       op: this.gop,
  //       destino: destino,
  //       fecha_salida: fecha_salida,
  //       fecha_llegada: fecha_llegada,
  //     }


  //     this.enviosService.rEnCli(nCli)
  //       .subscribe(
  //         res => {

  //           this.enviosService.envlc$.emit();
  //           this.openSnackBar(res.status, "ok");
  //         });
  //     this.onClose();


  //   } else {

  //     this.openSnackBar("Debes colocar la información completa.", "alert");
  //   }


  // }



  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }


  addItems() {

    if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
      const _id_cliente = this.firstFormGroup.get('_id_cliente').value;
      const cop = this.firstFormGroup.get('cop').value;
      const op = this.firstFormGroup.get('ops').value;
      const producto = this.secondFormGroup.get('producto').value;
      const volumen = this.secondFormGroup.get('volumen').value;
      const unidad = this.secondFormGroup.get('unidad').value;
      this.newProducto = {
        ci: this.gop,
        op: cop + "-" + op,
        _id_cliente: _id_cliente,
        _id_producto: producto,
        _id_unidad: unidad,
        volumen: volumen,

      }

      this.enviosService.regProductoEnvio(this.newProducto)
        .subscribe(
          res => {
            this.enviosService.envSer$.emit();
            this.openSnackBar(res.status, 'ok');
            this.secondFormGroup.reset();
            this.productoF.reset();
          });

    } else {
      this.openSnackBar("Debes indicar el numero de OP", 'alert');
    }
  }

  onDelAsig(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "40%";
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(DelEnviosComponent, dialogConfig);

  }

  getAllUnidades() {
    this.unidadesService.getAllUnidades().subscribe(resp => {
      this.dataResultUni = resp.unidades;
    });
  }



  add() {



    if (this.firstFormGroup.valid) {

      const ci = this.gop;
      // const op = this.firstFormGroup.get('ops').value;
      const sop = this.firstFormGroup.get('ops').value;
      const cop = this.firstFormGroup.get('cop').value;
      const op = cop + "-" + sop;
      const _id_cliente = this.firstFormGroup.get('_id_cliente').value;
      const destino = this.firstFormGroup.get('destino').value;
      const fecha_salida = this.firstFormGroup.get('fecha_salida').value;
      const fecha_llegada = this.firstFormGroup.get('fecha_llegada').value;
      const lugar_actual = this.glg;
      const te = this.firstFormGroup.get('te').value;


      this.newEnvio = {
        _id_cliente: _id_cliente,
        sem:this.gsem,
        op: op,
        ci: ci,
        tipo_envio: te,
        destino: destino,
        fecha_salida: fecha_salida,
        fecha_llegada: fecha_llegada,
        lugar_actual: lugar_actual,
        estado_general: "A TIEMPO",
        entregado: false


      }




      this.enviosService.regEnvio(this.newEnvio)
        .subscribe(
          res => {
            this.openSnackBar(res.status, 'ok');
            this.firstFormGroup.reset();
            this.secondFormGroup.reset();
            this.productoF.reset();
            this.clienteF.reset();
          });

      let lista = [
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "1",
          estado: "EN PROCESO",
          ico: "fact_check",
          value: "s"
        },
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "2",
          estado: "EN CAMINO",
          ico: "local_shipping",
          value: ""
        },
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "3",
          estado: "DESCARGANDO",
          ico: "downloading",
          value: ""
        },
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "4",
          estado: "ENTREGADO",
          ico: "done",
          value: ""
        },
      ];


      let listai = [
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "1",
          estado: "EN PROCESO",
          ico: "fact_check",
          value: "s"
        },
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "2",
          estado: "EN CAMINO",
          ico: "local_shipping",
          value: ""
        },
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "3",
          estado: "ADUANA",
          ico: "directions_boat_filled",
          value: ""
        },
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "4",
          estado: "DESCARGANDO",
          ico: "downloading",
          value: ""
        },
        {
          op: op,
          ci: ci,
          _id_cliente: _id_cliente,
          in: "5",
          estado: "ENTREGADO",
          ico: "done",
          value: ""
        },
      ];


      if (te === "Nacional") {
        this.enviosService.regEstadoEnvios(lista)
          .subscribe(
            res => {

            });
      } else {
        this.enviosService.regEstadoEnvios(listai)
          .subscribe(
            res => {

            });
      }



      let newOb = {
        _id_estado: this.gid,
        ob: "PREPARANDO ENVIO",
        op: op,
        ci: ci,
        _id_cliente: _id_cliente
      }

      this.enviosService.regEstadoHist(newOb)
        .subscribe(
          res => {
            this.enviosService.envSer$.emit();
            this.openSnackBar(res.status, 'ok');
            this.enviosService.envEstadolist$.emit();
            this.firstFormGroup.reset();
          });




      this.enviosService.envlc$.emit();
      this.onClose();


    } else {
      this.openSnackBar("Por favor verifica los datos del envio", 'alert');
    }





    this.enviosService.envlc$.emit();


  }






}
