import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { VenSemAdminService } from "../../../services/sales/ven-sem-admin.service";
import { BudgetService } from "../../../services/budget.service";
import { ChannelService } from "../../../services/channel.service";

@Component({
  selector: 'app-panel-venta-semana-admin',
  templateUrl: './panel-venta-semana-admin.component.html',
  styleUrls: ['./panel-venta-semana-admin.component.css']
})
export class PanelVentaSemanaAdminComponent implements OnInit {
  // Grafica A
  showXAxis = true;
  tCop="sM";
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = false;
  showYAxisLabel = true;
  legendTitle = '';
  yAxisLabel = 'MXN';
  colorScheme = {
    domain: ['#33a8c7', '#0081a7', '#7fd14d', '#26a116'],
  };
  listRango: any[];
  listGraAl;
  selec = new FormControl();
  conver: string;
  convera: string;
  converb: string;
  sem: any;
  obs = new BehaviorSubject<any>(null);
  obsVenSem = new BehaviorSubject<any>(null);
  obsGlo = new BehaviorSubject<any>(null);
  obsCh = new BehaviorSubject<any>(null);
  public searchText: any;
  mats = false;
  vSemana: any;
  vYeara: any;
  vTc: any;
  vMes: any;
  vRowGet: any;
  tonvera: string;
  tonverb: string;
  dataCli: boolean;
  respmats: boolean;
  dataCliPre: boolean;
  criterioCh: FormGroup;
  criterioChVeSem:FormGroup;
  filte: boolean=true;

  constructor(
    private venSemAdminService: VenSemAdminService,
    private budgetService: BudgetService,
    private _formBuilder: FormBuilder,
    private channelService: ChannelService,

  ) { }

  ngOnInit(): void {
    this.crearFormCh();
    this.getAllCanal();
    let dateM = this.getWeek();
    this.semCombo(dateM.week);
    this.getValorSemana();
  }

  crearFormCh() {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(),
    });

    this.criterioChVeSem = this._formBuilder.group({
      canalvensem: new FormControl(),
    });


  }


  updateCh() {
    const vCh = this.criterioCh.controls.canal.value;
    this.getPreSemAdminGlobals(this.vRowGet.vYeara, this.vRowGet.vMes, this.vRowGet.vD, this.vRowGet.vH, vCh);
  }

  updateChVenSem() {
    const vCh = this.criterioChVeSem.controls.canalvensem.value;
    
    this.getVenCliSem(this.vRowGet.vSemana, this.vRowGet.vSemana_b, this.vRowGet.vYeara, this.vRowGet.vMes, this.vRowGet.vD, this.vRowGet.vH, vCh);
  }

  orqVenSem(ya, yb, sema, semb, mes, da, ha, db, hb) {

    this.respmats = true;
    this.venSemAdminService.orqVenSem({ yeara: ya, yearb: yb, sema: sema, semb: semb, mes: mes, tca: this.getCamb(ya), tcb: this.getCamb(yb), da: da, ha: ha, db: db, hb: hb }).subscribe((resp) => {

      if (resp.cerror === false) {
        this.respmats = false;
      } else {
        this.listGraAl = resp;
        let b = resp.filter((element) => element.cdo === 'VEN');
        this.obs.next(b);
        this.mats = true;
      }
    });
  }


  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obsCh.next(resp);
    });
  }


  aSelectVencli(event) {
    this.vRowGet = {};
    this.criterioCh.controls.canal.setValue("");
    var tip = event.extra.tip;


    if (tip === "VEN") {
      var vSema = event.extra.sema;
      var vYeara = event.extra.yeara;
      const ra = this.getWeekDayFl(vYeara, Number(this.getSemTT(vSema)), 'a');
      const rb = this.getWeekDayFl(vYeara, Number(this.getSemTT(vSema)), 'b');
      const d = this.formatoDate(ra);
      const h = this.formatoDate(rb);
      var vYeara = event.extra.yeara;
      var vMes = event.extra.mes;
      var tip = event.extra.tip;
      var vSemb = this.getSemB(vYeara, vSema);
      var tc = this.getCamb(vYeara);

      this.getVenCliSem(vSema, vSemb, vYeara, vMes, d, h, "");

      this.vRowGet.vSemana = vSema;
      this.vRowGet.vSemana_b = vSemb;
      this.vRowGet.vYeara = vYeara;
      this.vRowGet.vTc = tc;
      this.vRowGet.vD = d;
      this.vRowGet.vH = h;
      this.vRowGet.vMes = vMes;
      this.dataCli = true;
      this.dataCliPre = false;

    }

    if (tip === "PRE") {



      this.vRowGet = {};
      const vYeara = event.extra.yeara;
      const vMes = event.extra.mes;
      const vD = event.extra.vD;
      const vH = event.extra.vH;
      var tip = event.extra.tip;
      this.vRowGet.vYeara = vYeara;
      this.vRowGet.vD = vD;
      this.vRowGet.vH = vH;
      this.vRowGet.vMes = vMes;
      this.dataCli = false;
      this.dataCliPre = true;

      this.getPreSemAdminGlobals(vYeara, vMes, vD, vH,"");

    }




  }


  getPreSemAdminGlobals(vYear: Number, vMes: Number, vD: Date, vH: Date, vCh:String) {
    this.budgetService.getPreSemAdminGlobals({ vYear: vYear, vMes: vMes, vD, vH, vCh }).subscribe(
      resp => {



        this.obsGlo.next(resp);
      });
  }

  getVenCliSem(sema, semb, year,  mes, d, h, vCh) {
    this.venSemAdminService.getVenCliSemPre({ sema: sema, semb: semb, yeara: year, mes: mes, d: d, h: h, vCh:vCh })
      .subscribe((resp) => {

        if (resp.length>0) {
          this.filte = true;
        } else {
          this.filte = false;
        }
        
        this.obsVenSem.next(resp);
      });
  }


  async updateAn(event) {
    this.criterioChVeSem.controls.canalvensem.reset();
    this.criterioCh.controls.canal.reset();
    this.dataCli=false;
    this.dataCliPre=false;
    this.obsVenSem = new BehaviorSubject<any>(null);
    this.dataCli = false;
    this.sem = event;
    let dateMaster = this.getWeek();
    const ra = this.getWeekDayFl(dateMaster.ya, Number(event), 'a');
    const rb = this.getWeekDayFl(dateMaster.ya, Number(event), 'b');
    this.convera = this.formatoDate(ra);
    this.converb = this.formatoDate(rb);

    const ta = this.getWeekDayFl(dateMaster.yb, Number(event), 'a');
    const tb = this.getWeekDayFl(dateMaster.yb, Number(event), 'b');
    this.tonvera = this.formatoDate(ta);
    this.tonverb = this.formatoDate(tb);

    const mes = moment(this.convera).format('M');
    this.orqVenSem(dateMaster.ya, dateMaster.yb, Number(event) + "-" + dateMaster.ya, Number(event) + "-" + dateMaster.yb, mes, this.convera, this.converb, this.tonvera, this.tonverb);

  }

  async getValorSemana() {

    let dateMaster = this.getWeek();

    const ra = this.getWeekDayFl(dateMaster.ya, dateMaster.week, 'a');
    const rb = this.getWeekDayFl(dateMaster.ya, dateMaster.week, 'b');

    this.convera = this.formatoDate(ra);
    this.converb = this.formatoDate(rb);

    const ta = this.getWeekDayFl(dateMaster.yb, dateMaster.week, 'a');
    const tb = this.getWeekDayFl(dateMaster.yb, dateMaster.week, 'b');

    this.tonvera = this.formatoDate(ta);
    this.tonverb = this.formatoDate(tb);

    this.orqVenSem(dateMaster.ya, dateMaster.yb, dateMaster.wa, dateMaster.wb, dateMaster.mes, this.convera, this.converb, this.tonvera, this.tonverb);

  }

  formatoDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  getWeek() {
    const week = moment().format('W');
    const yeara = moment().format('YYYY');
    const yearb = moment().subtract(1, 'year').format('YYYY');
    const p = this.getWeekDayFl(yeara, week, 'a');
    const u = this.getWeekDayFl(yeara, week, 'b');
    const pr = moment(p).format('YYYY-MM-DD');
    const ul = moment(u).format('YYYY-MM-DD');
    const mes = moment().format('M');

    const ra = this.getWeekDayFl(yeara, Number(week), 'a');
    const rb = this.getWeekDayFl(yeara, Number(week), 'b');
    const ta = this.getWeekDayFl(yeara, Number(week), 'a');
    const tb = this.getWeekDayFl(yeara, Number(week), 'b');

    const da = this.formatoDate(ra);
    const ha = this.formatoDate(rb);
    const db = this.formatoDate(ra);
    const hb = this.formatoDate(rb);

    let dataFecha = {
      week: week,
      wa: week + "-" + yeara,
      wb: week + "-" + yearb,
      firstday: pr,
      lastday: ul,
      ya: yeara,
      yb: yearb,
      mes: mes,
      da: da,
      ha: ha,
      db: db,
      hb: hb,

    };
    return dataFecha;
  }
  getWeekDayFl(year, week, ve) {
    var primerdia = new Date(year, 0, 1);
    var correccion = 6 - primerdia.getDay();
    var data;
    if (week * 7 + correccion > 365) {
      alert('El valor para semana no es válido');
      return false;
    }
    if (ve == 'a') {
      data = new Date(year, 0, (week - 1) * 7 + 3 + correccion);
    }
    if (ve == 'b') {
      data = new Date(year, 0, (week - 1) * 7 + 9 + correccion);
    }

    return data;
  }

  semCombo(sem) {
    let listSemana: any[] = [];
    for (let i = 1; i <= sem; i++) {
      listSemana.push({ semana: i });
    }
    this.listRango = listSemana.sort(function (a, b) {
      if (a.semana < b.semana) {
        return 1;
      }
      if (a.semana > b.semana) {
        return -1;
      }
      return 0;
    });
  }

  getCamb(valor) {
    let cambio =
    {
      2017: 22.04,
      2018: 20.96,
      2019: 20.26,
      2020: 25.75,
      2021: 22.15,
      2022: 21.46
    };

    return cambio[valor];
  }

  getSemB(vYeara, vSema) {

    const semb = moment(vYeara).subtract(1, 'years').format('YYYY');
    let semMatriz = vSema.split('-');
    return semMatriz[0] + '-' + semb;
  }

  getSemTT(vSema) {

    let semMatriz = vSema.split('-');
    return semMatriz[0];
  }




}
