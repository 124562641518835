import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductService } from "../../../services/product.service";
@Component({
  selector: 'app-ima-producto',
  templateUrl: './ima-producto.component.html',
  styleUrls: ['./ima-producto.component.css']
})
export class ImaProductoComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);
  @Input() idproducto: string;
  datas: boolean;

  constructor(
    private _productService: ProductService
  ) { }

  ngOnInit(): void {
    this.datas=true;
    this.getproductImage(this.idproducto);
  }

  getproductImage(idproducto) {
    this._productService.getproductImage({ _id: idproducto }).subscribe((resp) => {
            this.obs.next(resp[0].imagen);
            this.datas=false;
    });
  }

  validateIma(valor) {
    if (valor != "null")
      return valor;
    else
      return "assets/ima/no-ima.png";
  }
}



