import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { exit } from 'process';
import { VenMesAdminService } from './../../../../services/sales/ven-mes-admin.service';
import { SnackbarService } from './../../../../services/snackbar.service';
import { ClipboardService } from './../../../../services/clipboard.service';

@Component({
  selector: 'app-scg-grafica-global-year',
  templateUrl: './scg-grafica-global-year.component.html',
  styleUrls: ['./scg-grafica-global-year.component.css'],
})
export class ScgGraficaGlobalYearComponent implements OnInit {
  @Input() vYear: string;
  @Input() vCh: string;
  copyToClipboard: any;

  multi: any[];
  view: any[] = [700, 300];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'MXN';
  timeline: boolean = false;
  legendPosition = 'below';
  customColors: any[] = [
    { name: 'PRE 2022', value: '#0f69f1' },
    { name: 'OC 2022', value: '#efb810' },
    { name: 'VEN 2022', value: '#26a116' },

    { name: 'PRE 2021', value: '#0f69f1' },
    { name: 'OC 2021', value: '#efb810' },
    { name: 'VEN 2021', value: '#26a116' },

    { name: 'PRE 2020', value: '#0f69f1' },
    { name: 'OC 2020', value: '#efb810' },
    { name: 'VEN 2020', value: '#26a116' },

    { name: 'PRE 2019', value: '#0f69f1' },
    { name: 'OC 2019', value: '#efb810' },
    { name: 'VEN 2019', value: '#26a116' },

    { name: 'PRE 2018', value: '#0f69f1' },
    { name: 'OC 2018', value: '#efb810' },
    { name: 'VEN 2018', value: '#26a116' },

    { name: 'PRE 2017', value: '#0f69f1' },
    { name: 'OC 2017', value: '#efb810' },
    { name: 'VEN 2017', value: '#26a116' },
  ];

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  show: boolean;
  gd2m: boolean;
  wiat = [];

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['num', 'presupuesto', 'oc', 'venta', 'indi'];
  showa: boolean;
  ss1: boolean;
  volSuma: any;
  volSumaPre: any;
  volSumaVen: any;
  volSumaOc: any;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  constructor(
    private venMesAdminService: VenMesAdminService,
    private snackbarService: SnackbarService,
    private clipboardService: ClipboardService


  ) {}

  ngOnInit(): void {
    this.generateRandomInt();
    this.show = true;
    this.showa = false;

    this.scgGraficaGlobalYear(this.vYear, this.vCh);
  }

  ngOnChanges(changes: any) {
    this.show = true;
    this.showa = false;

    const nvYear = changes.vYear.currentValue;
    this.scgGraficaGlobalYear(String(this.vYear), this.vCh);
  }

  scgGraficaGlobalYear(vYear, vCh) {
    this.venMesAdminService
      .scgGraficaGlobalYear({ vYear: vYear, vCh: vCh })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.gd2m = true;
          this.ss1 = true;
        } else {
          this.gd2m = false;
          this.ss1 = false;
        }
        this.multi = resp;

        let da = resp.map((val) => val.series);
        let dataGra = [];
        for (let i = 0; i < 1; i++) {
          for (let j = 0; j < da[i].length; j++) {
            const elementa = da[0][j];
            const elementc = da[1][j];
            const elementb = da[2][j];

            dataGra.push({
              num: j + 1,
              mes: elementa.name,
              presupuesto: elementa.value,
              oc: elementc.value,
              venta: elementb.value,
            });
          }
        }

        this.volSumaPre = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.presupuesto;
        }, 0);

        this.volSumaOc = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.oc;
        }, 0);

        this.volSumaVen = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.venta;
        }, 0);

        this.dataSource.data = dataGra;

        this.show = false;
        this.showa = true;
      });
  }

  generateRandomInt() {
    for (let i = 0; i < 10; i++) {
      this.wiat.push({
        'border-radius': '5px',
        width: '30px',
        padding: '5px',
        margin: '7px',
        height: Math.floor(Math.random() * (210 - 20) + 20) + 'px',
        'background-color': '#daddd8',
      });
    }
    return this.wiat;
  }

 
  copyData() {
    let valor = this.dataSource.data;
    let title =Object.keys(this.dataSource.filteredData[0]);
    this.copyToClipboard=this.clipboardService.copyData(valor, title);
  }
 
}
