import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UnidadesService } from '../../services/unidades.service';
import { AddUnidadesComponent } from './add-unidades/add-unidades.component';
import { DelUnidadesComponent } from './del-unidades/del-unidades.component';

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.css'],
})
export class UnidadesComponent implements OnInit {
  loading = false;
  dataResult = new MatTableDataSource<any>();
  displayedColumns: string[] = ['createdAt', 'unidad', 'acro', 'across', 'op'];
  public date: any;
  constructor(
    private unidadesService: UnidadesService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.getAllUnidades();

    this.unidadesService.unidadesSer$.subscribe(() => {
      this.getAllUnidades();
    });
  }

  getAllUnidades() {
    this.unidadesService.getAllUnidades().subscribe((resp) => {
      this.dataResult.data = resp.unidades;
      this.dataResult.paginator = this.paginator;
      this.dataResult.sort = this.sort;
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.dataResult.paginator = this.paginator;
    this.dataResult.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataResult.filter = filterValue.trim().toLowerCase();
  }

  formatDate(fecha) {
    return fecha.substr(0, 10);
  }

  onDialogAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    this.dialog.open(AddUnidadesComponent, dialogConfig);
  }

  onDelAsig(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(DelUnidadesComponent, dialogConfig);
  }
}
