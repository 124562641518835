import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EnviosService } from "../../../services/envios.service";
import { UpEstadosComponent } from "../up-estados/up-estados.component";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-table-estados-envios',
  templateUrl: './table-estados-envios.component.html',
  styleUrls: ['./table-estados-envios.component.css']
})
export class TableEstadosEnviosComponent implements OnInit {

  lista: any[];

  constructor(
    private enviosService: EnviosService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
  ) { }

  @Input() op: String;
  @Input() ci: String;
  @Input() idcliente: String;
  @Input() idenv: String;
  @Input() dateRepro: String;
  @Input() datellegada: Date;
  ngOnInit(): void {
    this.getAllEstado();
    this.listeRefres();

    let data = { op: this.op, ci: this.ci, _id_cliente: this.idcliente }


  }


  listeRefres() {
    this.enviosService.envEstado$.subscribe(() => {
      this.getAllEstado();
    });
  }


  getAllEstado() {
    this.enviosService.getAllEstado({ op: this.op, ci: this.ci, _id_cliente: this.idcliente }).subscribe(
      resp => {
        this.lista = resp.segEstado;

        

      });
  }

  activaEstado(_id) {

  }

  onDelAsigG(_id, _in, estado, value, op, ico, datellegada, dateRepro) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%";
    dialogConfig.data = { data: { _id: _id, _in: _in, estado: estado, value: value, op, ico, datellegada: datellegada, idenv: this.idenv, dateRepro: this.dateRepro, ci: this.ci, _id_cliente: this.idcliente } };
    this.dialog.open(UpEstadosComponent, dialogConfig);

  }

  formatDate(fecha) {
    return fecha.substr(0,10);
  }

  rmdelallEnvio(_id, op) {
    this.enviosService.rmdelallEnvio({ _id: _id, op: op }).subscribe(
      resp => {
        this.enviosService.envSer$.emit();
      });
  }
}