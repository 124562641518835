import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from "../../../services/customer.service";
import { ChannelService } from "./../../../services/channel.service";

import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
export interface DialogData {
  animal: string;
  name: string;
}

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-up-cliente',
  templateUrl: './up-cliente.component.html',
  styleUrls: ['./up-cliente.component.css']
})
export class UpClienteComponent implements OnInit {
  aFormGroup: FormGroup;
  hide = true;
  file: File;
  ima64: String | ArrayBuffer;
  ima64Decode: String | ArrayBuffer;
  photoSelected: String | ArrayBuffer;
  id_epicor = new FormControl('', [Validators.required]);
  razon_social = new FormControl('', [Validators.required]);
  descripcion = new FormControl('', [Validators.required]);
  ima = new FormControl('');

  ciudad = new FormControl('', [Validators.required]);
  provincia = new FormControl('', [Validators.required]);
  telefono = new FormControl('', [Validators.required]);
  grupo = new FormControl('', [Validators.required]);
  obs = new BehaviorSubject<any>(null);
  canales = new FormControl('', [Validators.required]);
  newCliente = {}
  local_data: any;
  cData: any;
  gid: String;
  gid_epicor: String;
  grazon_social: String;
  gdescriocion: String;
  gimagen: String;
  gcod_epicor: String;
  gciudad: String;
  gprovincia: String;
  gtelefono: String;
  ggrupo: String;
  gcanal: any;
  arrayCanales: any = [];
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private channelService: ChannelService,
    private _snackBar: MatSnackBar,
    public customerService: CustomerService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpClienteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.local_data = this.data;
    this.gid = this.local_data.data._id;
    this.gid_epicor = this.local_data.data.id_epicor;
    this.grazon_social = this.local_data.data.razon_social;
    this.gdescriocion = this.local_data.data.descripcion;
    this.gimagen = this.local_data.data.ima_cliente;

    this.gciudad = this.local_data.data.ciudad;
    this.gprovincia = this.local_data.data.provincia;
    this.gtelefono = this.local_data.data.telefono;
    this.ggrupo = this.local_data.data.grupo;
    this.gcanal = this.local_data.data._id_canal;
    this.cData = this.local_data.data.canales;



  }


  ngOnInit() {
    this.canales.setValue(this.cData);
    this.getAllCanal();
    this.aFormGroup = this._formBuilder.group({
      id_epicor: [this.gid_epicor, Validators.required],
      razon_social: [this.grazon_social, Validators.required],
      descripcion: [this.gdescriocion,],
      ima: [this.gimagen,],
      ciudad: [this.gciudad,],
      provincia: [this.gprovincia,],
      telefono: [this.gtelefono,],
      grupo: [this.ggrupo,],
      // canal: [this.gcanal, Validators.required],

    });

    this.decodeBase64(this.gimagen);


  }


  up() {

    const ima = this.aFormGroup.get('ima').value;
    if (this.aFormGroup.valid) {
      const id_epicor = this.aFormGroup.get('id_epicor').value;
      const razon_social = this.aFormGroup.get('razon_social').value;
      const descripcion = this.aFormGroup.get('descripcion').value;
      // const _id_canal = this.aFormGroup.get('canal').value;
      const cana = this.canales.value;
      const provincia = this.aFormGroup.get('provincia').value;
      const ciudad = this.aFormGroup.get('ciudad').value;
      const grupo = this.aFormGroup.get('grupo').value;
      const ima = this.aFormGroup.get('ima').value;
      const canales = this.canales.value;
      this.newCliente = {
        provincia:provincia,
        ciudad:ciudad,
        grupo:grupo,
        _id: this.gid,
        id_epicor: id_epicor,
        razon_social: razon_social,
        descripcion: descripcion,
        ima_cliente: this.ima64,
        canales: canales
      }

      this.customerService.updateCliente(this.newCliente)
        .subscribe(
          res => {
            this.onClose();
            this.openSnackBar(res.status, 'ok');
            this.customerService.clienteSer$.emit();
          },
          err => {
            console.log(err);
            this.openSnackBar(err.error.error, 'alert');
          });

    }
  }

  getValues(event) {
    this.arrayCanales.push(event.source.value);    
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onFileSelected(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file = <File>event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.photoSelected = reader.result;
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.ima64 = reader.result;
        this.photoSelected = reader.result;
      };
    }
  }


  onClose() {
    this.dialogRef.close();
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obs.next(resp);

    });
  }
  getErrorMessageCodEpicor() {
    if (this.id_epicor.hasError('required')) {
      return 'Se requiere el Codigo EPICOR';
    }
  }

  getErrorMessageNombre() {
    if (this.razon_social.hasError('required')) {
      return 'Se requiere el nombre del Cliente';
    }
  }


  getErrorMessageDes() {
    if (this.descripcion.hasError('required')) {
      return 'Se requiere la descripcion del Cliente';
    }
  }


  getErrorMessageCiudad() {
    if (this.ciudad.hasError('required')) {
      return 'Se requiere la descripcion de la ciudad';
    }
  }

  getErrorMessageProvincia() {
    if (this.provincia.hasError('required')) {
      return 'Se requiere la descripcion de la provincia';
    }
  }


  getErrorMessageTelefono() {
    if (this.telefono.hasError('required')) {
      return 'Se requiere la descripcion de la telefono';
    }
  }

  getErrorMessageGrupo() {
    if (this.grupo.hasError('required')) {
      return 'Se requiere la descripcion de la grupo';
    }
  }


  decodeBase64(ima64Endode) {
    this.photoSelected = ima64Endode;
  }



}
