import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../../global';
@Injectable({
  providedIn: 'root',
})
export class channelGroupProductServices {
  private URL = GlobalVariable.BASE_API_URL;
  constructor(private http: HttpClient) {}

  sgcTablePro(data) {
    return this.http.post<any>(this.URL + 'sgcTablePro', data);
  }


  sgcTableProXCli(data) {
    return this.http.post<any>(this.URL + 'sgcTableProXCli', data);
  }


  scgTableProXCliGraMXN(data) {
    return this.http.post<any>(this.URL + 'scgTableProXCliGraMXN', data);
  }

  scgTableProXCliGraVOL(data) {
    return this.http.post<any>(this.URL + 'scgTableProXCliGraVOL', data);
  }

  sgcTableProSecAcuCli(data) {
    return this.http.post<any>(this.URL + 'sgcTableProSecAcuCli', data);
  }


  scgTableProXCliGraMXNa(data) {
    return this.http.post<any>(this.URL + 'scgTableProXCliGraMXNa', data);
  }


  scgTableProXCliGraVOLa(data) {
    return this.http.post<any>(this.URL + 'scgTableProXCliGraVOLa', data);
  }

  
  
}



