import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DialogData } from '../../budget/del-presupuesto/del-presupuesto.component';
import { ChannelService } from "./../../../services/channel.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-tras-channel',
  templateUrl: './tras-channel.component.html',
  styleUrls: ['./tras-channel.component.css']
})
export class TrasChannelComponent implements OnInit {
  form: FormGroup;
  obs = new BehaviorSubject<any>(null);
  canales = new FormControl('', [Validators.required]);
  local_data: any;
  constructor(
    public dialogRef: MatDialogRef<TrasChannelComponent>,
    private channelService: ChannelService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.data;
  }

  ngOnInit(): void {

    this.getAllCanal();
    this.crearForm();
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obs.next(resp);
    });
  }

 

  add() {
    const vCh = this.form.controls.canal.value;
    this.channelService.trasChannel({ vCli: this.data, vCh: vCh }).subscribe(resp => {
      this.onClose();
      this.openSnackBar(resp.status, 'ok');
      this.channelService.canalSerLi$.emit({ vCh: vCh });
      this.channelService.canalSerLisin$.emit({ });
    });
  }

  crearForm() {
    this.form = this.fb.group({
      canal: ['', Validators.required],

    });
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
