<mat-tab-group class="tabs-master-cli" mat-align-tabs="start">
  <mat-tab label="Histórico Volumen">

    <div class="ct-model">
      <div class="item name-a">
        <span class="denon">VOL</span> {{tVol | number: '1.2-2'}} <span class="denom"> {{tUni}}</span>
      </div>

      <div class="item name-a">
        <span class="denon">VEN</span> {{tTolu | number: '1.2-2'}} <span class="denom">USD</span>
      </div>

      <div class="item name-a">
        <span class="denon">VEN</span> {{tTol | number: '1.2-2'}} <span class="denom">MXN</span>
      </div>

      <div class="item name-c">
        <mat-form-field class="selem" (change)="updateAn($event.target.value)">
          <select matNativeControl>
            <option *ngFor="let lv of listRango" [value]="lv.vYear">
              {{ lv.vYear }}
            </option>
          </select>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div *ngIf="show; else nodata">
      <div class="ct-gri">
        <div *ngFor="let env of data">
          <mat-card class="matps">


            <div class="ct-master-cli">
              <div class="ct-logo">
                <app-ima-cliente [idcliente]="env.id_epicor"></app-ima-cliente>
              </div>
              <div class="ct-vol-ico">
                <span class="ct-dat-cli-mon"> {{env.unidad}} </span>
              </div>

              <div class="ct-usd">
                {{ env.tolu | number: '1.2-2'}}
              </div>
              <div class="ct-usd-i"> <span class="ct-dat-cli-mon"> USD </span></div>
              <div class="ct-mxn">
                {{ env.tol | number: '1.2-2'}}
              </div>
              <div class="ct-mxn-i">
                <span class="ct-dat-cli-mon"> MXN
                </span>
              </div>
              <div class="ct-vol">
                <span *ngIf="env.vol>0"> {{ env.vol | number: '1.2-2'}} </span>
              </div>
              <div class="ct-razo">
                {{ env.name}}
              </div>
              <div class="ct-unis">
                <div *ngIf="boxGet(idProEp)">
                  <mat-icon matTooltip="{{msnMas}}">{{icoMas}}</mat-icon>
                </div>
           
              </div>
              <div class="ct-usd-ii">

              </div>
              <div class="ct-mxn-ii">

              </div>
            </div>



          </mat-card>
        </div>
      </div>
    </div>
    <ng-template #nodata>
      <app-info-data [mensaje]="'Este producto no tiene venta'" [pd]=""> </app-info-data>
    </ng-template>
  </mat-tab>
  <mat-tab label="Histórico Precio Unitario">

    <ng-template matTabContent>
      <div *ngIf="showw; else nodataw">
        <div class="ct-pd-header">
          <div class="ct-his-cliente">
            Cliente
          </div>
          <div class="ct-his-gra">
            Grafica Precio
          </div>
        </div>
        <div *ngFor="let env of datar">
          <div class="ct-his">
            <div class="ct-his-ficha">
              <mat-card class="matp">
                <div class="ct-dat-cliente">
                  <div class="ctdatc-ima">
                    <app-ima-cliente [idcliente]="env.id_epicor"></app-ima-cliente>
                  </div>
                  <div class="ctdatc-tol">
                    <span *ngIf="env.vol>0"> {{ env.vol | number: '1.2-2'}} <span class="ct-dat-cli-mon"> {{env.unidad}}
                      </span></span>
                    <br>
                    <span *ngIf="env.tol>0"> {{ env.tol | number: '1.2-2'}} <span class="ct-dat-cli-mon"> MXN
                      </span></span>
                    <br>
                    <span *ngIf="env.tolu>0"> {{ env.tolu | number: '1.2-2'}} <span class="ct-dat-cli-mon"> USD
                      </span></span>
                  </div>
                  <div class="ctdatc-nom"> {{ env.name}} </div>
                </div>
              </mat-card>
            </div>
            <div class="ct-his-gra">
              <app-pro-price-hist-gra [idPro]="env._id_producto" [idCli]="env._id_cliente" [idUni]="env._id_unidad">
              </app-pro-price-hist-gra>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <ng-template #nodataw>
        <app-info-data [mensaje]="'Este producto no tiene venta'" [pd]=""> </app-info-data>
      </ng-template>
    </ng-template>
  </mat-tab>
</mat-tab-group>