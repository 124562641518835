<div *ngIf="obsCharoom; else nodata">

    <div class="ct-osp">
        <div class="ct-div-tareas">
            <app-task-table-micro [vAr]="vRo"></app-task-table-micro>
        </div>
        <div class="ct-div-tareas">
            <app-chat></app-chat>
        </div>   
    </div>
    <mat-divider></mat-divider>
</div>


<ng-template #nodata>
    <form [formGroup]="formActivate">
        <div class="container">
            <div class="ct-pre"> ¿Deseas activar una sala de observaciones?</div>
            <div class="ct-opciones">


                <mat-form-field class="in-for">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre" autocomplete="off" type="text" />
                    <mat-error *ngIf="formActivate.controls.nombre.hasError('required')">Nombre requerido
                    </mat-error>
                </mat-form-field>
                <br>
                <mat-form-field class="in-for">
                    <mat-label>Descripcion</mat-label>
                    <input matInput formControlName="des" autocomplete="off" type="text" />
                    <mat-error *ngIf="formActivate.controls.des.hasError('required')">Descripción
                    </mat-error>
                </mat-form-field>

                <br>

                <mat-checkbox color="primary" formControlName="resumen">Enviar un resume semanal del caso por email.
                </mat-checkbox>




            </div>
            <div class="ct-btn">
                <button class="btn-agregar" mat-raised-button color="primary" (click)="add()">
                    Activar
                </button>
            </div>
        </div>
    </form>
</ng-template>