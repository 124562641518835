<div class="tool-master">
  <mat-toolbar class="tool" color="primary">
    <mat-toolbar-row>
      <button *ngIf="this.loginService.loginIn();" mat-icon-button (click)="sidenav.toggle()" fxShow="true"
        fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
      <div class="ct-logo">
        <div> <span *ngIf="this.loginService.loginIn();" class="logo-s">appro </span></div>
        <div><span class="version">v2.0</span></div>
      </div>



      <div fxShow="true" fxHide.lt-md>
        <app-menu></app-menu>
      </div>
      <span class="menu-spacer"></span>
      <div fxShow="true" fxHide.lt-md>
        <ng-container *ngIf="!this.loginService.loginIn(); else loggedIn">

        </ng-container>
        <ng-template #loggedIn>
          <ng-container *appDroles="rol_Logistica">
            <button matTooltip="Retail" mat-icon-button routerLink="/sales_channel_group/60215d0c6a2bbf1d34a2f81b">
              <mat-icon>storefront</mat-icon>
            </button>
          </ng-container>
          <ng-container *appDroles="rol_Admin">
            <button matTooltip="Envios" mat-icon-button routerLink="/shipping">
              <mat-icon>local_shipping</mat-icon>
            </button>
          </ng-container>
          <!-- <ng-container *appDroles="rol_Admin">
            <button matTooltip="Galería" mat-icon-button routerLink="/gallery">
              <mat-icon>image</mat-icon>
            </button>
          </ng-container> -->
          <ng-container *appDroles="rol_Admin">
            <button matTooltip="Ventas" mat-icon-button [matMenuTriggerFor]="menu_admin">
              <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #menu_admin="matMenu">
              <ng-container *appDroles="rol_Admin">
                <button mat-menu-item routerLink="/areas">
                  <mat-icon>task_alt</mat-icon>
                  <span>Áreas</span>
                   <span class="spaa">Nuevo</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/customer">
                  <mat-icon>perm_contact_calendar</mat-icon>
                  <span>Clientes</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/product">
                  <mat-icon>all_inbox</mat-icon>
                  <span>Productos</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/channel">
                  <mat-icon>all_inbox</mat-icon>
                  <span>Canal</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/customer_asig">
                  <mat-icon>group</mat-icon>
                  <span>Asignar Cliente- Ejecutivo</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/sales">
                  <mat-icon>attach_money</mat-icon>
                  <span>Ventas</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/sales_search">
                  <mat-icon>search</mat-icon>
                  <span>Buscar Ventas RCP</span> <span class="spaa">Nuevo</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/purchase_order">
                  <mat-icon>list_alt</mat-icon>
                  <span>Ordenes de Compra</span> <span class="spaa">Nuevo</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/budget">
                  <mat-icon>price_check</mat-icon>
                  <span>Presupuesto</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/margen">
                  <mat-icon>query_stats</mat-icon>
                  <span>Margenes</span> <span class="spaa">Nuevo</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item routerLink="/units">
                  <mat-icon>format_color_fill</mat-icon>
                  <span>Unidades</span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
          <app-menu-logs></app-menu-logs>
          <ng-container *appDroles="rol_Config">
            <button matTooltip="Configuración" mat-icon-button [matMenuTriggerFor]="menu_admin_conf">
              <mat-icon>settings</mat-icon>
            </button>
            <mat-menu #menu_admin_conf="matMenu">
              <button mat-menu-item routerLink="/rol">
                <mat-icon>toggle_on</mat-icon>
                <span>Roles</span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item routerLink="/users">
                <mat-icon>3p</mat-icon>
                <span>Usuarios</span>
              </button>
            </mat-menu>
          </ng-container>
          <button mat-button [matMenuTriggerFor]="menu" class="example-icon">
            <mat-icon>account_circle</mat-icon>
            <span fxShow="true" fxHide.lt-md="true">
              {{ emailUser }}
            </span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/pusers">
              <mat-icon>system_update_alt</mat-icon>
              <span>Actualizar Datos</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="this.loginService.loginOut()">
              <mat-icon>power_settings_new</mat-icon>
              <span>Cerrar sesión </span>
            </button>
          </mat-menu>
        </ng-template>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <a (click)="sidenav.toggle()" href="" mat-list-item>Cerrar</a>
        <ng-container *ngIf="!this.loginService.loginIn(); else loggedInMo">
          <button mat-menu-item routerLink="/login">
            <mat-icon>account_circle</mat-icon>
            <span>Iniciar Sesion</span>
          </button>
        </ng-container>
        <ng-template #loggedInMo>
          <ng-container *appDroles="rol_Logistica">
            <button mat-menu-item [matMenuTriggerFor]="menu_admin_log">
              <mat-icon>account_tree</mat-icon>
              <span>Logistica</span>
            </button>
            <mat-menu #menu_admin_log="matMenu">

              <button mat-menu-item routerLink="/listado_envios">
                <mat-icon>local_shipping</mat-icon>
                <span>Envios</span>
              </button>
            </mat-menu>
            <mat-divider></mat-divider>
          </ng-container>


          <button mat-menu-item [matMenuTriggerFor]="menu_ventas">
            <mat-icon>view_module</mat-icon>
            <span>Ventas</span>
          </button>

          <mat-menu #menu_ventas="matMenu">
            <ng-container *appDroles="rol_Vendedor">

              <button mat-menu-item routerLink="/sales">
                <mat-icon>show_chart</mat-icon>
                <span>Venta</span>
              </button>
              <mat-divider></mat-divider> <button mat-menu-item routerLink="/budget">
                <mat-icon>show_chart</mat-icon>
                <span>Presupuesto</span>
              </button>




            </ng-container>

          </mat-menu>
          <mat-divider></mat-divider>

          <ng-container *appDroles="rol_Config">
            <button mat-menu-item [matMenuTriggerFor]="menu_admin_conf">
              <mat-icon>settings</mat-icon>
              <span>Configuracion</span>
            </button>
            <mat-menu #menu_admin_conf="matMenu">
              <button mat-menu-item routerLink="/roles">
                <mat-icon>toggle_on</mat-icon>
                <span>Roles</span>
              </button>

              <button mat-menu-item routerLink="/usuarios">
                <mat-icon>3p</mat-icon>
                <span>Usuarios</span>
              </button>
            </mat-menu>
            <mat-divider></mat-divider>
          </ng-container>


          <button mat-menu-item [matMenuTriggerFor]="menu" class="example-icon">
            <mat-icon>account_circle</mat-icon>
            <span>Perfil</span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/perfil">
              <mat-icon>system_update_alt</mat-icon>
              <span>Actualizar Datos</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="this.loginService.loginOut()">
              <mat-icon>power_settings_new</mat-icon>
              <span>Cerrar sesión </span>
            </button>
          </mat-menu>
          <mat-divider></mat-divider>
        </ng-template>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content fxFlexFill [class]="theme">
      <app-loader></app-loader>
      <div class="mattcon">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>