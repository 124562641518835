<div *ngIf="show">





<div class="ct-p">

    <div class="ct-it">
        <div class="ct-ico" matTooltip="Tareas">
            <mat-icon class="ct-icm">splitscreen</mat-icon>
        </div>
        <div class="ct-num">{{numTaskTer}}/{{numTask}}</div>
    </div>

    <div class="ct-it">
        <div class="ct-ico" matTooltip="Miembros">
            <mat-icon class="ct-icm">people_alt</mat-icon>
        </div>
        <div class="ct-num">{{this.vMie}}</div>
    </div>

    <div class="ct-it">
        <div class="ct-ico" matTooltip="Notificaciones">
            <mat-icon class="ct-icm">mark_email_unread</mat-icon>
        </div>
        <div class="ct-num">12</div>
    </div>

    <div class="ct-it-d" (click)="onDialogDel(vTas)">
        <div class="ct-ico-d" matTooltip="Eliminar">
            <mat-icon class="ct-icm-d">delete_forever</mat-icon>
        </div>
        <!-- <div class="ct-num">12</div> -->
    </div>

</div>
<div class="ct-pro">
    <mat-progress-bar mode="determinate" value="{{getCronograma(numTaskTer,numTask)}}">
    </mat-progress-bar>
</div>

<div class="ct-foo">
    <div class="ct-prog"><span class="ct-foo-a" >Avance</span> <span class="ct-foo-b">{{getCronograma(numTaskTer,numTask)| number: '1.0-0'}}  %</span></div>
    <div class="ct-dias"><span class="ct-foo-a">Días</span>  <span class="ct-foo-b">   {{getDayDiffRest(date.fecha_star,date.fecha_end)}}/{{getDayDiff(date.fecha_star,date.fecha_end)}}</span></div>
</div>

</div>