<div class="conte-pro">
  <div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/shipping']">
      <mat-icon>navigate_before</mat-icon>
    </button>
    Nuevo Envio Multiple
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="firstFormGroup">

    <mat-form-field>
      <mat-label>Codigo Interno</mat-label>
      <input matInput placeholder="" formControlName="op" required autocomplete="off">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Lugar Actual</mat-label>
      <input matInput placeholder="Punto de Partida" formControlName="lugar_actual" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Semana</mat-label>
      <input matInput placeholder="Semana del Año" formControlName="sem" required>
    </mat-form-field>

  </form>
  <div class="done">

    <mat-grid-list cols="10" rowHeight="50px">
      <mat-grid-tile colspan="8">

        <button [disabled]="bso" class="mat-list-item" color="primary" mat-raised-button mat-button (click)="onDialogAdd()">
          <mat-icon matListIcon>add</mat-icon> Agregar Cliente
        </button>

      </mat-grid-tile>

      <mat-grid-tile colspan="2">


        <button [disabled]="bso" class="mat-list-item" color="accent" mat-raised-button mat-button (click)="regEnvioCodInterno()">
          <mat-icon matListIcon>save</mat-icon> Guardar
        </button>
      </mat-grid-tile>


    </mat-grid-list>




  </div>

  <div *ngIf="(obs| async)?.length > 0; else nodata">

    <div *ngFor="let env of obs | async">
      <div class="mat-card conter-ficha">
        <mat-grid-list cols="20" rowHeight="100px">
          <mat-grid-tile colspan="2"> <img class="imagel" [src]="validateIma(env._id_cliente.ima_cliente)" />
          </mat-grid-tile>
          <mat-grid-tile colspan="6">
            <div class="text-inside-grid">
              <table class="table-data">
                <tr>
                  <td> <span class="sut">{{env.op}}</span>
                  </td>
                </tr>
                <tr>
                  <td class="titulo-cli">{{formatRazonSocial(env._id_cliente.razon_social)}}
                  </td>
                </tr>
                <tr>
                  <td><span class="sut">{{env.destino}}</span></td>
                </tr>
              </table>
            </div>
          </mat-grid-tile>
          <mat-grid-tile class="cone" colspan="11">
            <div class="cone">
              <app-table-estados-envios ci="{{env.ci}}" idcliente="{{env._id_cliente._id}}" ci="{{env.ci}}"
                op="{{env.op}}" idenv="{{env._id}}" dateRepro="{{env.fecha_rep}}" datellegada="{{env.fecha_llegada}}">
              </app-table-estados-envios>


            </div>


          </mat-grid-tile>
          <mat-grid-tile colspan="1">


            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
                mat-menu-item (click)="onDelAsig(env._id, env.op, env.ci,env._id_cliente._id);">
                <mat-icon>delete_forever</mat-icon>
                <span>Eliminar</span>
              </button>

            </mat-menu>




            <!-- <button class="bote" (click)="onDelAsig(env._id)" matTooltip="¿Seguro que deseas borrar este cliente?"
              mat-raised-button mat-button>
            <mat-icon class="icon" matListIcon>delete_forever</mat-icon>
          </button> -->
          </mat-grid-tile>
        </mat-grid-list>


        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="fondo">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>unfold_more</mat-icon> Detalles
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="table-data-detalle">
              <tr class="mat-header-row">
                <th class="mat-header-cell">SALIDA</th>
                <th class="mat-header-cell">LLEGADA</th>

                <th *ngIf="env.repro==true" class="mat-header-cell">LLEGADA REPROGRAMADA</th>


                <th class="mat-header-cell">UBICACIÓN ACTUAL</th>
                <th class="mat-header-cell">MAPA</th>
                <th class="mat-header-cell">ESTADO</th>
              </tr>
              <tr class="mat-row">
                <td class="mat-cell">{{formatDate(env.fecha_salida)}} </td>
                <td class="mat-cell">{{formatDate(env.fecha_llegada)}} </td>
                <td *ngIf="env.repro==true" class="mat-cell">{{formatDate(env.fecha_rep)}} </td>
                <td class="mat-cell">{{env.lugar_actual}} </td>
                <td class="mat-cell">

                  <a target="_blank" class="btn" color="primary" mat-button mat-raised-button
                    href="https://maps.google.com/?q={{env.lugar_actual}}">
                    <mat-icon>location_on</mat-icon>
                  </a>

                </td>
                <td class="mat-cell">
                  <div class="row-pro-f {{env.repro}}">{{env.estado_general}}</div>
                </td>
              </tr>
            </table>

            <mat-grid-list cols="1" rowHeight="300px">

              <mat-grid-tile class="mat-figure">
                <div class="table-data">
                  <app-table-productos-envios ci="{{env.ci}}" op="{{env.op}}" idcliente="{{env._id_cliente._id}}">
                  </app-table-productos-envios>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
        </mat-accordion>

      </div>






    </div>

  </div>
  <ng-template #nodata>
    <div class="nodata">
      <div class="g-card">
        <mat-card class="g-card">
          <mat-card-title>
            <mat-icon class="ico-m" matListIcon>report_problem</mat-icon>
          </mat-card-title>
          <mat-card-subtitle>
            No hay envios registrados.
          </mat-card-subtitle>
        </mat-card>
      </div>
    </div>
  </ng-template>



</div>