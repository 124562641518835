<div *ngIf="mostrar;else dataFull">
    <div class="ct-cadew">
        <ngx-skeleton-loader count="5" [theme]="{ 
              'border-radius': '5px', 
              'width': '900px',
              'height': '30px',
              'background-color': '#daddd8'
          }">
        </ngx-skeleton-loader>
    </div>


</div>
<ng-template #dataFull>

    <div class="ct-pd-header{{celusd}}">
        <div class="vfecha">Fecha</div>
        <div>Folio</div>
        <div>Volumen</div>
        <div>Unidad</div>
        <div class="vmxn">Precio MXN</div>
        <div class="vmxn">Total MXN</div>
        <div *ngIf="celusd" class="vusds">Precio USD</div>
        <div *ngIf="celusd" class="vusds">Total USD</div>
    </div>
    <div *ngFor="let env of dataPro | async;let i = index;" class="ct-pd">

        <div *ngFor="let evd of env.data">
            <div class="ct-pdv-content{{celusd}} {{getColorl(i)}}">
                <div class="vfecha">{{evd.fecha}}</div>
                <div>{{env.folio}}</div>
                <div>{{evd.volumen | number}}</div>
                <div>{{evd.unidad}}</div>
                <div>{{evd.precio | number: '1.2-2'}}</div>
                <div>{{evd.total | number: '1.2-2'}}</div>
                <div *ngIf="celusd">{{evd.precio_u_uds | number: '1.2-2'}}</div>
                <div *ngIf="celusd">{{evd.total_uds | number: '1.2-2'}}</div>
            </div>

        </div>

        <div class="ct-pd-foot">
        </div>

        <mat-divider></mat-divider>
    </div>

</ng-template>