import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { ChannelService } from 'src/app/services/channel.service';
import { DialogData } from '../../budget/del-presupuesto/del-presupuesto.component';
import { TrasChannelComponent } from '../tras-channel/tras-channel.component';
import { CustomerService } from "./../../../services/customer.service";

@Component({
  selector: 'app-trasl-pro-sin-agrupacion',
  templateUrl: './trasl-pro-sin-agrupacion.component.html',
  styleUrls: ['./trasl-pro-sin-agrupacion.component.css']
})
export class TraslProSinAgrupacionComponent implements OnInit {
  form: FormGroup;
  obs = new BehaviorSubject<any>(null);
  canales = new FormControl('', [Validators.required]);
  local_data: any;
  vPro: any;
  vCli: String;
  constructor(
    public dialogRef: MatDialogRef<TrasChannelComponent>,
    private channelService: ChannelService,
    private customerService:CustomerService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.vPro=this.data.vPro;

  }

  ngOnInit(): void {


    this.getAllCanal();
    this.crearForm();
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obs.next(resp);
    });
  }

 

  add() {
    const vCh = this.form.controls.canal.value;
 
    this.channelService.trasladoProCanalSinAgrupacion({ vPro: this.vPro, vCh: vCh, vCli:this.vCli }).subscribe(resp => {
      this.onClose();
      this.openSnackBar(resp.status, 'ok');
      this.customerService.listProSn$.emit();
    });
  }

  crearForm() {
    this.form = this.fb.group({
      canal: ['', Validators.required],

    });
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
