import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { VenMesAdminService } from 'src/app/services/sales/ven-mes-admin.service';

@Component({
  selector: 'app-scg-grafica-cli-year-vol',
  templateUrl: './scg-grafica-cli-year-vol.component.html',
  styleUrls: ['./scg-grafica-cli-year-vol.component.css']
})
export class ScgGraficaCliYearVolComponent implements OnInit {
  volSumaOc: any;
  copyToClipboard: string;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  @Input() vYear: string;
  @Input() vCh: string;
  @Input() vCli: string;
  multi: any[];
  view: any[] = [700, 300];
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['num', 'presupuesto','oc', 'venta', 'indi'];
  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Ven Vs Pre Vol Acumulado';
  yAxisLabel: string = 'Unidades';
  timeline: boolean = false;
  legendPosition = 'below';
  customColors: any[] = [
    { name: 'PRE 2022', value: '#0f69f1' },
    { name: 'OC 2022', value: '#efb810' },
    { name: 'VEN 2022', value: '#26a116' },

    { name: 'PRE 2021', value: '#0f69f1' },
    { name: 'OC 2021', value: '#efb810' },
    { name: 'VEN 2021', value: '#26a116' },

    { name: 'PRE 2020', value: '#0f69f1' },
    { name: 'OC 2020', value: '#efb810' },
    { name: 'VEN 2020', value: '#26a116' },

    { name: 'PRE 2019', value: '#0f69f1' },
    { name: 'OC 2019', value: '#efb810' },
    { name: 'VEN 2019', value: '#26a116' },

    { name: 'PRE 2018', value: '#0f69f1' },
    { name: 'OC 2018', value: '#efb810' },
    { name: 'VEN 2018', value: '#26a116' },

    { name: 'PRE 2017', value: '#0f69f1' },
    { name: 'OC 2017', value: '#efb810' },
    { name: 'VEN 2017', value: '#26a116' },
  ];

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  show: boolean;
  gd2m: boolean;
  wiat = [];
  volSumaVen: any;
  volSumaPre: any;
  showa: boolean;
  ss1: boolean;
  constructor(
    private venMesAdminService: VenMesAdminService,
    private clipboardService: ClipboardService
  ) {}

  ngOnInit(): void {

    this.generateRandomInt();
    this.showa = false;
    this.show = true;

    

    // this.scgGraficaCliYearVol(this.vYear, this.vCh, this.vCli);
  }



  copyData() {
    let valor = this.dataSource.data;
    let title =Object.keys(this.dataSource.filteredData[0]);
    this.copyToClipboard=this.clipboardService.copyData(valor, title);
  }

  ngOnChanges(changes: any) {
    this.show = false;
    const nvYear = changes.vYear.currentValue;
    this.scgGraficaCliYearVol(String(this.vYear), this.vCh,  this.vCli);
  }


  scgGraficaCliYearVol(vYear, vCh, vCli) {
    this.venMesAdminService.scgGraficaCliYearVol({ vYear: vYear, vCh: vCh, vCli:vCli }).subscribe(
      resp => {
        
        if (resp.length > 0) {
          this.gd2m = true;
          this.ss1 = true;
        } else {
          this.gd2m = false;
          this.ss1 = false;
        }
        this.multi = resp;
        this.show = false;



        let dataGra = [];
        const result0 = resp.map((data) => data.series);

        result0[0].flatMap((data, i) => {
          dataGra.push({
            num: i + 1,
            mes: data.name,
            presupuesto: data.value,
            oc: result0[1][i].value,
            venta: result0[2][i].value,
          });
        });

        this.volSumaPre = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.presupuesto;
        }, 0);

        this.volSumaOc = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.oc;
        }, 0);


        this.volSumaVen = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.venta;
        }, 0);

        
        this.dataSource.data = dataGra;
        this.showa = true;



      });
  }




  generateRandomInt() {
    for (let i = 0; i < 9; i++) {
      this.wiat.push({
        'border-radius': '5px',
        width: '30px',
        padding: '5px',
        margin: '7px',
        height: Math.floor(Math.random() * (180 - 20) + 20) + 'px',
        'background-color': '#daddd8',
      });
    }
    return this.wiat;
  }


}
