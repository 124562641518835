import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { LogsService } from './../../services/logs.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  show: boolean;
  rol_Admin = ['admin', 'gerente', 'admin_vendedor'];
  rol_Logistica = ['logistica'];
  rol_Vendedor = ['vendedor'];
  nameUser: any;
  imagen: string;
  ig: boolean;
  iga: boolean;
  Odlfecha: any;
  constructor(
    private loginService: LoginService,
    private logsService: LogsService
  ) {
    this.ig = false;
  }

  ngOnInit(): void {
   
    this.nameUser = localStorage.getItem('nombre');
    this.loginService.nombreUser.subscribe((c) => {
      if (c === '-') {
        this.nameUser = localStorage.getItem('nombre');
      } else {
        this.nameUser = c;
      }
    });
    this.renderWie();
  }

  getOldConect() {
    this.loginService.getOldConect().subscribe((resp) => {
      this.Odlfecha = resp.fecha_login;
    });
  }

  renderWie() {
    this.show = window.innerWidth <= 450 ? false : true;
  }

  onResize(event) {
    this.show = event.target.innerWidth <= 450 ? false : true;
  }

  getInfo() {
    if (this.loginService.getNombre()) {
      this.loginService.nomUser$.emit(this.loginService.getNombre());
    }
  }

  validateIma(valor) {
    if (valor === undefined || valor === null || valor === '') {
      this.iga = false;
    } else {
      this.iga = true;
    }

    return valor;
  }

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        this.getOldConect();
        this.nameUser = localStorage.getItem('nombre');
        this.imagen = localStorage.getItem('imagen');
        this.ig = true;

        this.validateIma(this.imagen);
      }, 2000);
    } catch (error) {
      console.log('Se ha generado un error con el perfil del usuario');
    }
  }

  modelo() {
    this.logsService.listNoti$.emit('redClass');
  }

  getSter(name) {
    const spliName = name.split(' ', 3);

    const nameResult = spliName[0].charAt(0) + spliName[1].charAt(0);

    return nameResult;
  }
}
