import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "../../../services/sales/sales.service";
import { BudgetService } from "../../../services/budget.service";
import {finalize} from 'rxjs/operators';
@Component({
  selector: 'app-gra-ven-pre',
  templateUrl: './gra-ven-pre.component.html',
  styleUrls: ['./gra-ven-pre.component.css']
})
export class GraVenPreComponent implements OnInit {
  @Input() vYear: number;
  obs = new BehaviorSubject<any>(null);
  tca: any;
  mats: Boolean = false;
  obsTc: any;
  constructor(private budgetService: BudgetService) { }

   ngOnInit() {
    this.orqTCallPreYear();
  }


  orqTCallPreYear(){
    this.budgetService.getTipoCambioArray()
    .pipe(finalize( () => this.getAllPresupuestoAdd(this.vYear)))
    .subscribe(resp => {
      this.obsTc = resp;
    });
}

  getTipoCambioArray() {
    this.budgetService.getTipoCambioArray().subscribe(
      resp => {
        this.obsTc = resp;
      });
  }


  getAllPresupuestoAdd(vYear) {
    this.budgetService.getAllPresupuestoAddCri({ vYear: vYear }).subscribe(
      resp => {
        if (resp.length > 0) {
          this.mats = true;
          this.obs.next(resp);
        } else {
          this.mats = false;
        }
      });
  }

  getCambio(m, vc) {
    var result: any;
    if (m === "MXN" || m === null) {
      result = '';
    } else {
      result = this.getCamb(vc);
    }
    return result;
  }

  getTotal(m, mo, vc) {
    var result;
    if (mo === "MXN" || mo === "--" || mo === null) {
      result = m;
    } else {
      result = m * this.getCamb(vc);
    }
    return result;
  }

  getPrem(array, vc) {
    let a = array.filter(element => element.moneda === "MXN");
    let b = array.filter(element => element.moneda === "USD");
    var totalm = a.map(d => d.total);
    var totalu = b.map(d => d.total);
    var tt = (Number(totalm) + Number(totalu * this.getCamb(vc)));
    return tt;
  }

  getCamb(valor) {
    if (valor) {
      let cambio = this.obsTc;
      let b = cambio.filter(element => element.year === valor);
      var tc = b.map(d => d.tc);
      return tc[0];
    }
  }

  goToLink(yeara) {
    window.open('/budget_detail/' + yeara, '_blank');
  }


}
