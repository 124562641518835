import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UnidadesService } from '../../../services/unidades.service';
export interface DialogData {

}

@Component({
  selector: 'app-del-unidades',
  templateUrl: './del-unidades.component.html',
  styleUrls: ['./del-unidades.component.css']
})
export class DelUnidadesComponent implements OnInit {

  id: string = "";
  actividad: string = "";
  local_data: any;
  message: string = "¿Seguro que deseas continuar?";
  confirmButtonText = "Si";
  cancelButtonText = "No";
  loading: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<DelUnidadesComponent>,
    public unidadesService: UnidadesService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.local_data = this.data;
    this.id = this.local_data.data.id;
  }

  ngOnInit(): void {

  }

  onConfirmClick() {
    this.unidadesService.delUnidades({ id: this.id }).subscribe(response => {
      this.onClose();
      this.openSnackBar(response.status, 'ok'); this.openSnackBar(response.status, 'ok');
      this.unidadesService.unidadesSer$.emit();
    }, error => this.openSnackBar("Ha ocurrido un error", "Error"));
  }
  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
