import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MargenService } from "../../../services/margen.service";
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-margen-producto',
  templateUrl: './margen-producto.component.html',
  styleUrls: ['./margen-producto.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MargenProductoComponent implements OnInit {


  expandedElement: PeriodicElement | null;

  @Input() mes: any;
  @Input() yeara: number;
  @Input() idcliente: number;

  multi: any[];
  view: any[] = [700, 200];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  animations: boolean = true;
  legendTitle: string = "Productos";

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };

  customColors = (name) => {
    const value = this.mat.find(s => s.name === name).value;
    if (value > 0) {
      return "#1cb70a";
    } else {
      return "#ed0000";
    }
  }
  s1: boolean;

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['id_epicor', 'nombre', 'volumen', 'unidad', 'importe_venta', 'utilidad_neta', 'pcr'];


  s2: boolean;
  cals: string;
  mat: any;
  idproducto: any;
  sf: boolean;
  sc: boolean;
  e1: boolean;


  constructor(
    private margenServices: MargenService
  ) { }

  ngOnInit(): void {


    

    this.graficaMargenClienProducto(this.idcliente, this.yeara, this.mes);


    // this.graficaMargenClienProducto("5fb810e4e54dec7576d77428", 2021, 12);
  }


  graficaMargenClienProducto(_id_cliente, yeara, mes) {
    this.e1 = true;
    // this.margenServices.graficaMargenClienProducto({
    //   "_id_cliente": "5fb810e4e54dec7576d77428",
    //   "yeara": 2021,
    //   "mes": 12
    // }).subscribe(
    //   resp => {
    //     this.dataSource.data = resp;
    //     if (resp.length > 0) {
    //       this.s2 = true;
    //     } else {
    //       this.s2 = false;
    //     }
    //   });

    this.margenServices.graficaMargenClienProducto({ _id_cliente: _id_cliente, yeara: yeara, mes: mes }).subscribe(
      resp => {
        this.dataSource.data = resp;


        this.e1 = false;
        if (resp.length > 0) {
          this.s2 = true;
        } else {
          this.s2 = false;
        }
      });


    this.margenServices.graficaMargenClienPP({ _id_cliente: _id_cliente, yeara: yeara, mes: mes }).subscribe(
      resp => {

   
        

        this.mat = resp;

      });
  }

  validateValue(value) {

    if (value) {
      if (value > 0)
        this.cals = "gree";
      else
        this.cals = "gred";
    }
    return value;

  }


  getTotala() {
    return this.dataSource.data.map(t => t.importe_venta).reduce((acc, importe_venta) => acc + importe_venta, 0);
  }

  getTotalb() {
    return this.dataSource.data.map(t => t.utilidad_neta).reduce((acc, utilidad_neta) => acc + utilidad_neta, 0);
  }

  getTotalv() {
    return this.dataSource.data.map(t => t.volumen).reduce((acc, volumen) => acc + volumen, 0);
  }

  verData(row) {
    this.sf = false;
    this.sc = true;
    this.idproducto=row._id._id_producto;
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}