import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { CustomerService } from "../../../services/customer.service";
import { MatSnackBar } from '@angular/material/snack-bar';
export interface DialogData {

  }


@Component({
  selector: 'app-del-cliente',
  templateUrl: './del-cliente.component.html',
  styleUrls: ['./del-cliente.component.css']
})
export class DelClienteComponent implements OnInit  {

    id: string="";
    actividad: string="";
    local_data;
    message: string = "¿Seguro que deseas continuar?";
    confirmButtonText = "Si";
    cancelButtonText = "No";
    constructor(
    public dialogRef: MatDialogRef<DelClienteComponent>,
    public customerService: CustomerService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    this.local_data=this.data;
    this.id=this.local_data.data.id;


    }

    ngOnInit() {




    }


    onConfirmClick(){

    this.customerService.delCliente({id:this.id}).subscribe(response => {
    this.onClose();
    this.openSnackBar(response.status, 'ok');
    this.customerService.clienteSer$.emit();
    this.dialogRef.close(true);
  }, error => this.openSnackBar("Ha ocurrido un error","Error"));
    }

    openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    panelClass: [tipo]
    });
    }

    onClose() {
    this.dialogRef.close();
    }



}
