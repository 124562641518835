import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { GlobalVariable } from '../../global';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VenSemAdminService {

  private URL = GlobalVariable.BASE_API_URL;
  constructor(private http: HttpClient) { }
  private dataGraf$: Subject<DataGrafa[]> = new Subject<DataGrafa[]>();
  private dataF: DataGrafa[] = [];

  aadGraf(arraGra: DataGrafa) {
    this.dataF.push(arraGra);
    this.dataGraf$.next(this.dataF);
  }

  getGraf$(): Observable<any[]> {
    return this.dataGraf$.asObservable();
  }

  orqVenSem(data) {
    return this.http.post<any>(this.URL + 'orqVenSem', data);
  }

  getVenYearCanal(data) {
    return this.http.post<any>(this.URL + 'getVenYearCanal', data);
  }

  getVenCliSemPre(data) {
    return this.http.post<any>(this.URL + 'getVenCliSemPre', data);
  }

  orqVenSemDet(data) {
    return this.http.post<any>(this.URL + 'orqVenSemDet', data);
  }

  getVenCliSemPreId(data) {
    return this.http.post<any>(this.URL + 'getVenCliSemPreId', data);
  }

  getYearPreVenSem(data) {
    return this.http.post<any>(this.URL + 'getYearPreVenSem', data);
  }

  getGraVenSemPro(data) {
    return this.http.post<any>(this.URL + 'getGraVenSemPro', data);
  }

  getGraVenSemProFolio(data) {
    return this.http.post<any>(this.URL + 'getGraVenSemProFolio', data);
  }
  getPdvProductoPriceHisto(data) {
    return this.http.post<any>(this.URL + 'getPdvProductoPriceHisto', data);
  }



}

export interface DataGrafa {
  year: number;
  series: {
    name: string,
    value: number
  }

}