import { Component, OnInit } from '@angular/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { LoginService } from './../../../services/login.service';
import { UsuarioService } from './../../../services/usuario.service';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ChangeDetectorRef, AfterContentChecked } from '@angular/core';

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-add-usuarios',
  templateUrl: './add-usuarios.component.html',
  styleUrls: ['./add-usuarios.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AddUsuariosComponent implements OnInit {
  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;
  file: File;
  ima64: String | ArrayBuffer;
  ima64Decode: String | ArrayBuffer;
  photoSelected: String | ArrayBuffer;
  newUsuario = {};
  newCliente = {};
  hide = true;
  date = new FormControl(moment(), [Validators.required]);
  nombre = new FormControl('', [Validators.required]);
  apellido = new FormControl('', [Validators.required]);
  telefono = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9 ]{10}'),
  ]);
  email = new FormControl('', [Validators.required, Validators.email]);
  clave = new FormControl('', [Validators.required]);
  dp = new FormControl('', [Validators.required]);

  cod_epicor = new FormControl('', [Validators.required]);
  dataRoles: any;
  arrayRoles: any = [];
  isChecked = true;
  constructor(
    private loginService: LoginService,
    private usuarioService: UsuarioService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<AddUsuariosComponent>
  ) {}

  ngOnInit() {
    this.getAllRoles();
    this.form = this.fb.group({
      cod_epicor: ['', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      telefono: ['', Validators.required],
      email: ['', Validators.email],
      clave: ['', Validators.required],
      date: ['', Validators.required],
      rol: ['', Validators.required],
      ima: [''],
      es: ['true', Validators.required],
    });
  }

  registro() {
    if (this.form.valid) {
      const nombre = this.form.get('nombre').value;
      const apellido = this.form.get('apellido').value;
      const telefono = this.form.get('telefono').value;
      const email = this.form.get('email').value;
      const clave = this.form.get('clave').value;
      const cod_epicor = this.form.get('cod_epicor').value;
      const date = _moment(this.form.get('date').value).format('YYYY-MM-DD');
      const rol = this.arrayRoles;
      this.newUsuario = {
        email: email,
        clave: clave,
        nombre: nombre,
        apellido: apellido,
        telefono: telefono,
        date: date,
        imagen: this.ima64,
        fecha_n: date,
        rol: rol,
        estado: '0',
        cod_epicor: cod_epicor,
      };

      this.loginService.registroUsuario(this.newUsuario).subscribe(
        (res:any) => {
          this.openSnackBar(res.status, 'ok');
          this.usuarioService.usuarioSer$.emit();
          this.onClose();
        },
        (err) => {
          this.openSnackBar(err.error.error, 'alert');
        }
      );
    }else{
      this.openSnackBar("La validacioón de la informacion no paso", 'alert');

    }
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo],
    });
  }

  getErrorMessageNombre() {
    if (this.nombre.hasError('required')) {
      return 'Por favor, ingresa tu nombre principal';
    }
  }

  getErrorMessageApellido() {
    if (this.apellido.hasError('required')) {
      return 'Por favor ingresa tu apellido';
    }
  }

  getErrorMessageTelefono() {
    if (this.telefono.hasError('required')) {
      return 'Por favor ingresa tu telefono';
    }
  }

  getErrorMessageEmail() {
    if (this.email.hasError('required')) {
      return 'Por favor ingresa un correo valido';
    }
    return this.email.hasError('email')
      ? 'Por favor ingresa un correo valido'
      : '';
  }

  getErrorMessageClave() {
    if (this.clave.hasError('required')) {
      return 'Por favor, elige una contraseña';
    }
  }

  getErrorMessageDate() {
    if (this.date.hasError('required')) {
      return 'Por favor, indicanos tu fecha de nacimiento';
    }
  }

  getErrorMessageDp() {
    if (this.dp.hasError('required')) {
      return 'Por favor, indicanos tu fecha de nacimiento';
    }
  }

  getErrorMessageCod() {
    if (this.cod_epicor.hasError('required')) {
      return 'Por favor, indicanos tu cod en EPICOR';
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenDayHandler(normalizedDay: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.day(normalizedDay.day());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  chosenMonthHandler(normalizedMonth: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
  }

  onFileSelected(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file = <File>event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => (this.photoSelected = reader.result);
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.ima64 = reader.result;
        this.photoSelected = reader.result;
      };
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getAllRoles() {
    this.usuarioService.getAllRoles().subscribe((resp) => {
      this.dataRoles = resp;
    });
  }

  getValues(event) {
    const valor = event.source.value;
    this.arrayRoles.push(valor);
  }

  changeEstado(event) {
    const valor = event.checked;
    if (!valor) {
      this.usuarioService.countUsuarios({}).subscribe((resp) => {
        this.form.patchValue({ cod_epicor: 'U-' + resp });
        this.openSnackBar('Generando ID', 'ok');
        this.usuarioService.usuarioSer$.emit();
      });
    } else {
      this.form.patchValue({ cod_epicor: '' });
    }
  }
}
