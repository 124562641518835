import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { GlobalVariable } from '../../global';
@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private URL = GlobalVariable.BASE_API_URL;
  constructor(
    private http: HttpClient,
  ) { }

  getUltimaUp() {
    return this.http.get<any>(this.URL + 'getUltimaUp');
  }

  masterOrqPreMes() {
    return this.http.get<any>(this.URL + 'masterOrqPreMes');
  }

  getGraficaVentaYearCri(data) {
    return this.http.post<any>(this.URL + 'getGraficaVentaYearCri', data);
  }

  masterOrqPreMesCriYear(data) {
    return this.http.post<any>(this.URL + 'masterOrqPreMesCriYear', data);
  }

  orqVenYear() {
    return this.http.get<any>(this.URL + 'orqVenYear');
  }

  getVenYearCanalMaster(data) {
    return this.http.post<any>(this.URL + 'getVenYearCanalMaster', data);
  }

  orqVenYearDefinido(data) {
    return this.http.post<any>(this.URL + 'orqVenYearDefinido',data);
  }

  orqVenYearDMes(data) {
    return this.http.post<any>(this.URL + 'orqVenYearDMes',data);
  }


  getVenYearCanalMasterMes(data) {
    return this.http.post<any>(this.URL + 'getVenYearCanalMasterMes',data);
  }

  graMesVenYear(data) {
    return this.http.post<any>(this.URL + 'graMesVenYear',data);
  }

  graMesVenYearProducto(data) {
    return this.http.post<any>(this.URL + 'graMesVenYearProducto',data);
  }

  graMesVenYearCliente(data) {
    return this.http.post<any>(this.URL + 'graMesVenYearCliente',data);
  }

  salesSearch(data) {
    return this.http.post<any>(this.URL + 'salesSearch',data);
  }

  getDataProductoSearch(data) {
    return this.http.post<any>(this.URL + 'getDataProductoSearch',data);
  }

  getDataCliSearch(data) {
    return this.http.post<any>(this.URL + 'getDataCliSearch',data);
  }


  getDataCliSearchNumPro(data) {
    return this.http.post<any>(this.URL + 'getDataCliSearchNumPro',data);
  }


  getListProductosCliSearch(data) {
    return this.http.post<any>(this.URL + 'getListProductosCliSearch',data);
  }



  salesSearchPro(data) {
    return this.http.post<any>(this.URL + 'salesSearchPro',data);
  }


  getProductoCliAgroup(data) {
    return this.http.post<any>(this.URL + 'getProductoCliAgroup',data);
  }


  salesSearchProID(data) {
    return this.http.post<any>(this.URL + 'salesSearchProID',data);
  }


  salesSearchCh(data) {
    return this.http.post<any>(this.URL + 'salesSearchCh',data);
  }


  salesFilterCh(data) {
    return this.http.post<any>(this.URL + 'salesFilterCh',data);
  }


  getDataCliSearchChannel(data) {
    return this.http.post<any>(this.URL + 'getDataCliSearchChannel',data);
  }

  getListProductosCliSearchChannel(data) {
    return this.http.post<any>(this.URL + 'getListProductosCliSearchChannel',data);
  }


  getDataCliSearchNumProChannel(data) {
    return this.http.post<any>(this.URL + 'getDataCliSearchNumProChannel',data);
  }


  sendRecla(data) {
    return this.http.post<any>(this.URL + 'sendRecla',data);
  }


  
  
}
