import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {
  title = 'appro';
  rol_Config = ["admin"];
  rol_Admin = ["admin", "gerente", "admin_vendedor"];
  rol_Auditor = ["admin", "gerente", "auditor"];
  rol_Vendedor = ["admin", "gerente", "vendedor", "admin_vendedor"];
  rol_Logistica = ["admin", "gerente", "logistica"];
  public ventas: any;
  public emailUser: string;
  isLoggedIn$: Observable<boolean>;
  theme: any = "";
  private subscription: Subscription;
  constructor(
    public router: Router,
    public loginService: LoginService) {
    this.isLoggedIn$ = this.loginService.isLoggedIn;
  }

  ngOnInit() {

    this.loginService.mainObservable.subscribe(data => {
      this.theme = data;
    })
    this.loginService.vUser.subscribe(c => {
      if (c === "-") {
        this.emailUser = localStorage.getItem('email');
      } else {
        this.emailUser = c;
      }
    });
  }

}