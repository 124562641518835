import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private URL = GlobalVariable.BASE_API_URL;
  public canalSer$ = new EventEmitter<any>();
  public canalSerLi$ = new EventEmitter<any>();
  public canalSerLisin$ = new EventEmitter<any>();
  public chCliList$ = new EventEmitter<any>();
  public chYearGropu$ = new EventEmitter<any>();
  constructor(private http: HttpClient, private router: Router) {}
  public rolSer$ = new EventEmitter<any>();

  getAllChannel() {
    return this.http.get<any>(this.URL + 'getAllChannel');
  }

  registrarCanal(data) {
    return this.http.post<any>(this.URL + 'registrarCanal', data);
  }

  delChannel(data) {
    return this.http.post<any>(this.URL + 'delChannel', data);
  }

  trasChannel(data) {
    return this.http.post<any>(this.URL + 'trasChannel', data);
  }

  getCustChannelIdParitcular(data) {
    return this.http.post<any>(this.URL + 'getCustChannelIdParitcular', data);
  }

  trasChannelPro(data) {
    return this.http.post<any>(this.URL + 'trasChannelPro', data);
  }

  trasladoCliCanalSinAgrupacion(data) {
    return this.http.post<any>(
      this.URL + 'trasladoCliCanalSinAgrupacion',
      data
    );
  }

  trasladoProCanalSinAgrupacion(data) {
    return this.http.post<any>(
      this.URL + 'trasladoProCanalSinAgrupacion',
      data
    );
  }

  getAllGrupo() {
    return this.http.get<any>(this.URL + 'getAllGrupo');
  }

  getAllGrupoId(data) {
    return this.http.post<any>(this.URL + 'getAllGrupoId', data);
  }

  regGrupoCli(data) {
    return this.http.post<any>(this.URL + 'regGrupoCli', data);
  }

  delGrupoCli(data) {
    return this.http.post<any>(this.URL + 'delGrupoCli', data);
  }

  getAllCanalGrupoId(data) {
    return this.http.post<any>(this.URL + 'getAllCanalGrupoId', data);
  }
}
