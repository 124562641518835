import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DialogData } from '../../budget/del-presupuesto/del-presupuesto.component';
import { ChannelService } from "./../../../services/channel.service";
import { CustomerService } from "./../../../services/customer.service";

import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-tras-channel-pro',
  templateUrl: './tras-channel-pro.component.html',
  styleUrls: ['./tras-channel-pro.component.css']
})
export class TrasChannelProComponent implements OnInit {
  form: FormGroup;
  obs = new BehaviorSubject<any>(null);
  canales = new FormControl('', [Validators.required]);
  local_data: any;
  vPro: any;
  vCli: any;

  constructor(
    public dialogRef: MatDialogRef<TrasChannelProComponent>,
    private channelService: ChannelService,
    private customerService:CustomerService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  this.vPro=this.data.vPro;
  this.vCli=this.data.vCli;


  }

  ngOnInit(): void {

    this.getAllCanal();
    this.crearForm();
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe(resp => {
      this.obs.next(resp);
    });
  }

 

  add() {
    const vCh = this.form.controls.canal.value;
    let vChr = vCh.split("-");
    let vChf = vChr[0];
    let vNhr = vChr[1];

    this.channelService.trasChannelPro({ vPro: this.vPro, vCh: vCh, vCli:this.vCli }).subscribe(resp => {
      this.onClose();
      this.openSnackBar(resp.status, 'ok');
      this.customerService.canalSerPro$.emit({ vCh: vChf });
      this.channelService.canalSerLi$.emit({ vCh: vChf });
    });
  }

  crearForm() {
    this.form = this.fb.group({
      canal: ['', Validators.required],

    });
  }

  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
