<div class="conte-pro">


  <div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/dashboard']">
      <mat-icon>navigate_before</mat-icon>
    </button>
    Listado de Clientes

  </div>
  <mat-divider></mat-divider>

  <div class="columna1">
    <mat-form-field>
      <mat-label>Filtro</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input autocomplete="off">

    </mat-form-field>
  </div>


  <div class="columna2">

    <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button (click)="onDialogAdd()">
      <mat-icon matListIcon>add</mat-icon> Registrar Nuevo
    </button>

  </div>
  <mat-grid-list *ngIf="loading;" cols="1" rowHeight="200px">
    <mat-grid-tile>
      <mat-spinner diameter="70" strokeWidth="10"></mat-spinner>
    </mat-grid-tile>
  </mat-grid-list>


  <div *ngIf="!loading;">

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table-data mat-elevation-z8">

      <ng-container matColumnDef="cod">
        <th mat-header-cell *matHeaderCellDef> Código </th>
        <td mat-cell *matCellDef="let element">
          {{element.id_epicor}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ima">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
    
          
          <app-ima-cliente [idcliente]="element.id_epicor" [classCss]="'sm'"></app-ima-cliente>
        </td>
      </ng-container>

      <ng-container matColumnDef="razon_social">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.razon_social}} </td>
      </ng-container>


      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [checked]="element.estado=='true' || element.estado=='1' ? 'true' : 'false'"
            (change)="changeEstado($event,element._id)" color="primary"></mat-slide-toggle>
        </td>
      </ng-container>


      <ng-container matColumnDef="opciones">
        <th mat-header-cell *matHeaderCellDef> Opciones </th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">


            <button matTooltip="" matTooltipClass="example-tooltip-red" mat-menu-item (click)="onDialogDir(
          element._id,
          element.razon_social,
          element.ima_cliente,
          element.id_epicor
          )">
              <mat-icon>view_list</mat-icon>
              <span>Directorio</span>
            </button>


            <button matTooltip="" matTooltipClass="example-tooltip-red" mat-menu-item (click)="onDialogUp(
          element._id,element.id_epicor,
          element.razon_social,
          element.descripcion,
          element.ima_cliente,
          element.ciudad,
          element.provincia,
          element.telefono,
          element.grupo,
          element._id_canal,
          element.canales
          )">
              <mat-icon>update</mat-icon>
              <span>Actualizar</span>
            </button>


            <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
              mat-menu-item (click)="onDialogDel(element._id)">
              <mat-icon>delete_forever</mat-icon>
              <span>Eliminar</span>
            </button>


          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <mat-tab-group class="tabs-master-cli" mat-align-tabs="started">

              <div class="container-graf">
                <mat-tab label="Acumulado General">
                  <ng-template matTabContent>
                    <!-- <div class="ct-model">
                      <div></div>
                      <div></div>
                      <div></div>                   
                      <div class="item name-c">
                        <mat-form-field class="selem" (change)="updateAn($event.target.value, element.id_epicor)">
                          <select matNativeControl >
                            <option  *ngFor="let lv of listRango" [value]="lv.vYear">
                              {{ lv.vYear }}
                            </option>
                          </select>
                        </mat-form-field>
                      </div>
                    </div> -->
                    <app-acu-cli-g *ngIf="vYear" [vYear]="vYear" [vCli]="element.id_epicor"></app-acu-cli-g>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Listado de Productos">
                  <ng-template matTabContent>
                    <app-list-product [idCli]="element._id"></app-list-product>
                  </ng-template>
                </mat-tab>
              </div>
            </mat-tab-group>
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


    </table>


  </div>
  <mat-paginator [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>




  <app-grafica-top10></app-grafica-top10>


</div>