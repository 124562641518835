import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VenMesAdminService } from '../../../../../services/sales/ven-mes-admin.service';

@Component({
  selector: 'app-list-cli',
  templateUrl: './list-cli.component.html',
  styleUrls: ['./list-cli.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'hidden' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class ListCliComponent implements OnInit {
  expandedElement: PeriodicElement | null;
  @Input() vCh: string;
  @Input() vYear: string;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'id_epicor',
    'razon_social',
  
    'pres_tol',
    'oc_tol',
    'venta_tol',
    'indi',
  ];
  ss1: boolean;
  show: boolean;
  venta_tol: any;
  pres_tol: any;
  venta_vol: any;
  pres_vol: any;
  pres_vols: any;
  oc_vol: any;
  oc_tol: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  constructor(private venMesAdminService: VenMesAdminService) {}

  ngOnChanges(changes: any) {
    this.show = false;
    const nvYear = changes.vYear.currentValue;
    this.acuCliGenGrupoTableGen(this.vCh, String(nvYear));
  }

  ngOnInit() {
    this.show = false;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  acuCliGenGrupoTableGen(vCh, vYear) {
    this.venMesAdminService
      .acuCliGenGrupoTableGen({ vCh: vCh, vYear: vYear })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }
        this.show = true;

        this.dataSource.data = resp;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.venta_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_tol;
        }, 0);

        this.pres_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_tol;
        }, 0);

        this.venta_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_vol;
        }, 0);

        this.oc_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_vol;
        }, 0);


        this.oc_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_tol;
        }, 0);

        this.pres_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_vol;
        }, 0);
      });
  }

  getNombre(nombre) {
    // return nombre.substring(0, 10) + ' ...';
    return nombre;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  
}


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
