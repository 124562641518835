import { Injectable,  } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LoginService } from './login.service';
import { LoaderService } from './loader.service';
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        private loginService: LoginService,
        private loaderService: LoaderService,
        private router: Router,
        private _snackBar: MatSnackBar, ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.status$.emit(true);
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.loginService.getToken()}`,
        });
        const reqClone = req.clone({
            headers
        });

        return next.handle(reqClone).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.status === 200) {
                    this.loaderService.status$.emit(false);
                }
            }),
            catchError(error => {
                switch (error.status) {
                    case 0:
                        this.openSnackBar("Error! conexion con el servidor.", 'alert');
                        break;
                    case 409:
                        this.openSnackBar("Ya exite un registro para ese rango y cliente.", 'alert');
                        break;
                    case 200:
                        this.openSnackBar("Bien Bien", 'alert');
                        break;
                    case 401:
                        localStorage.removeItem('token');
                        localStorage.removeItem('nombre');
                        localStorage.removeItem('email');
                        this.openSnackBar("Usuario o clave invalida...", 'alert');
                        this.router.navigate(['login']);
                        
                        break;
                        case 402:
                            localStorage.removeItem('token');
                            localStorage.removeItem('nombre');
                            localStorage.removeItem('email');
                            this.openSnackBar("Tu sesión expiró...", 'alert');
                            this.router.navigate(['login']);
                            
                            break;
                }
                this.loaderService.status$.emit(false);
                return throwError(error);
            })
        );
    }

    openSnackBar(message: string, tipo: string) {
        this._snackBar.open(message, '', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: [tipo]
        });
    }
}
