<div class="conte-pro">
    <div class="titulo-row-b">
        Busqueda
    </div>
    <mat-divider></mat-divider>
    <div class="ct-panel">
        <div>
            <mat-form-field class="mat-form">
                <mat-label>Fecha:</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Desde">
                    <input matEndDate formControlName="end" placeholder="Hasta">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                </mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
        </div>
    
        <div class="btse">
            <button mat-raised-button color="primary" (click)="exeData()">
                <mat-icon>search</mat-icon>
                Buscar
            </button>
        </div>
    </div>
  

    <div *ngFor="let env of obsp | async  | filter: searchText">
        <app-sales-produc [vPro]="env._id_producto" [vD]="env.vD" [vH]="env.vH"></app-sales-produc>
    </div>


</div>