<div *ngIf="show; else nodatq">
<div class="ct-panel"> 
  <div>
    <mat-form-field class="mat-form-field-filtro" >
      <mat-label>Filtro</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ejemplo: Profusa Product"
          autocomplete="off" />
  </mat-form-field>
  </div>
  <div>
    <button class="dbtnas" [disabled]="!btt" color="accent" mat-raised-button mat-button
    (click)="onDialogTras()">
    <mat-icon>swap_horiz</mat-icon> Trasladar a <span *ngIf="bttn>0"> ({{bttn}}) </span>
</button>
  </div>
</div>

<table mat-table [dataSource]="dataResult" multiTemplateDataRows class="mat-elevation-z8 mat-table">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="nump">
    <th mat-header-cell *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.nump}} </td>
  </ng-container>

  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
  </ng-container>


  <ng-container matColumnDef="ico">
    <th mat-header-cell *matHeaderCellDef> Canal </th>
    <td mat-cell *matCellDef="let element">  
      <div class="ct-canal-nom">
        <div class="ct-ico">
          <mat-icon class="ct-ico-f">{{element.ico}}</mat-icon> 
        </div>
        <div class="ct-nom-c"> {{element.canal}}</div>
    </div>
   </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="ct-row"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [length]="5" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>

</div>

<ng-template #nodatq>
  <div class="divRespo">
  <app-info-data [pd]="" [mensaje]="'No tiene productos vendidos'"></app-info-data>
</div>
</ng-template>