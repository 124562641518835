<div *ngIf="show; else wnoda">
  <div *ngIf="ss1; else rsnoda">
    <div *ngFor="let orf of obsLis | async; index as i">
      <div class="ct-p">
        <div>
          <app-scg-card-cliente [vEnm]="orf" [vNum]="i"></app-scg-card-cliente>
        </div>
        <div></div>
      </div>

<div  class="ct-mark" >
  <mat-accordion>
    <mat-expansion-panel hideToggle>
  
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
         Ver detalle, MXN - VOL
        </mat-panel-title>
        <mat-panel-description>
          
        </mat-panel-description>
      </mat-expansion-panel-header>

      


      <app-scg-table-gra-mxn
      [vCli]="orf.idepicor"
      [vYear]="vYear"
      [vCh]="vCh"
      [vPro]="orf.vPro"
    ></app-scg-table-gra-mxn>


    
    <app-scg-table-gra-vol
      [vCli]="orf.idepicor"
      [vYear]="vYear"
      [vCh]="vCh"
      [vPro]="orf.vPro"
    ></app-scg-table-gra-vol>



    </mat-expansion-panel>
  </mat-accordion>




</div>
 


         <mat-divider></mat-divider> 

      <!-- 
      <carousel
        class="ctg"
        [height]="'300'"
        [objectFit]="'cover'"
        [cellWidth]="'90%'"
        [arrows]="true"
        [arrowsOutside]="false"
        [autoplay]="false"
        [dots]="true"
        [loop]="false"
      >
        <div class="carousel-cell">
          <app-scg-table-gra-mxn
            [vCli]="orf.idepicor"
            [vYear]="vYear"
            [vCh]="vCh"
            [vPro]="orf.vPro"
          ></app-scg-table-gra-mxn>
        </div>

        <div class="carousel-cell">
          <app-scg-table-gra-mxn
            [vCli]="orf.idepicor"
            [vYear]="vYear"
            [vCh]="vCh"
            [vPro]="orf.vPro"
          ></app-scg-table-gra-mxn>
        </div>
      </carousel> -->
    </div>

    <!-- <div class="table-containera">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z0">
  
          <ng-container matColumnDef="id_epicor">
            <th mat-header-cell *matHeaderCellDef> Epicor </th>
            <td mat-cell *matCellDef="let element">
              <div class="ct-ima-con" matTooltip="{{element.id_epicor}}">
                {{element.id_epicor}}
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
          </ng-container>
  
          <ng-container matColumnDef="nombre" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
            <td mat-cell *matCellDef="let element" matTooltip="{{element.nombre}}">
              {{getNombre(element.nombre)}} <br>
  
            </td>
            <td mat-footer-cell *matFooterCellDef> Total </td>
  
          </ng-container>
  
          <ng-container matColumnDef="pres_vol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Vol Pre </th>
            <td mat-cell *matCellDef="let element"> {{element.pres_vol | number: '1.0-2'}}
              <span class="ct-di">{{element.pres_uni}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef> {{pres_vol | number: '1.0-2'}} </td>
  
          </ng-container>
  
          <ng-container matColumnDef="venta_vol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Vol Ven </th>
            <td mat-cell *matCellDef="let element"> {{element.venta_vol | number: '1.0-2'}}
              <span class="ct-di">{{element.venta_uni}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef> {{venta_vol | number: '1.0-2'}} </td>
  
          </ng-container>
  
  
          <ng-container matColumnDef="pres_tol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pre </th>
            <td mat-cell *matCellDef="let element"> {{element.pres_tol | number: '1.2-2'}}
              <span class="ct-di">MXN</span>
            </td>
            <td mat-footer-cell *matFooterCellDef> {{pres_tol | number: '1.2-2'}} </td>
  
          </ng-container>
  
          <ng-container matColumnDef="venta_tol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ven </th>
            <td mat-cell *matCellDef="let element"> {{element.venta_tol | number: '1.2-2'}}
              <span class="ct-di">MXN</span>
            </td>
            <td mat-footer-cell *matFooterCellDef> {{venta_tol | number: '1.2-2'}} </td>
  
          </ng-container>
  
  
          <ng-container matColumnDef="indi">
  
            <th mat-header-cell *matHeaderCellDef> % </th>
  
            <td mat-cell *matCellDef="let element">
  
              <app-scg-indicador [vVen]="element.venta_tol" [vSm]="'sm'" [vPre]="element.pres_tol"></app-scg-indicador>
  
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
  
          </ng-container>
  
  
     
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        </table>

  

      </div> -->
  </div>

  <ng-template #rsnoda>
    <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]="">
    </app-info-data>
  </ng-template>
</div>
<ng-template #wnoda>
  <div class="ct-cadew">
    <ngx-skeleton-loader
      count="9"
      [theme]="{
        'border-radius': '5px',
        width: '900px',
        height: '30px',
        'background-color': '#daddd8'
      }"
    >
    </ngx-skeleton-loader>
  </div>
</ng-template>

<!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
