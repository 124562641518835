<div class="conte-pro">
    <div class="titulo-row-b">
        <button mat-icon-button [routerLink]="['/dashboard']">
            <mat-icon>navigate_before</mat-icon>
        </button>
        Esquema de Tareas
    </div>
    <mat-divider></mat-divider>
    <section>
        <div class="ct-p">
            <div class="ct-e" *ngFor="let env of objData | async">
                <mat-card class="ct-pre" (click)="getTaskShow(env._id)">
                    <mat-card-title>{{env.nombre}}</mat-card-title>
                    <mat-card-subtitle>{{env.createdAt}}</mat-card-subtitle>
                    <mat-divider></mat-divider>
                    <mat-card-content class="ctp-p">
                        <p>
                            {{env.des}}
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </section>


    <div class="ct-op" *ngIf="op">


        <div class="ct-menu">
            <button mat-button color="primary" (click)="getNeTask()">+ Tarea Simple</button>
      

        </div>
        <section class="st-a" *ngIf="taskadd">
            <div class="ct-ti-b">
                <div> <button mat-icon-button>
                        <mat-icon>navigate_before</mat-icon>
                    </button></div>
                <div> Nueva tarea</div>
                <div class="ct-ti-b-s"> <span class="ct-bt-c" (click)="getNeTaskClo()">Cancelar</span></div>
            </div>
            <mat-divider></mat-divider>
            <form [formGroup]="formPurchaseOrder">

                <div class="ct-for-a">

                    <div>
                        <mat-form-field class="in-for">
                            <mat-label>Tarea</mat-label>
                            <input matInput formControlName="tarea" autocomplete="off" type="text" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="in-for">
                            <mat-label>Descripcion</mat-label>
                            <input matInput formControlName="des" autocomplete="off" type="text" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="ct-for">


                    <div>
                        <mat-form-field class="in-for">
                            <mat-label>Entregable</mat-label>
                            <input matInput formControlName="entre" autocomplete="off" type="text" />
                        </mat-form-field>
                    </div>



                    <div class="ct-pri-con">
                        <span class="ct-pri">Prioridad</span> <br>
                        <star-rating value="0" [totalstars]='5' checkedcolor="gold" uncheckedcolor="gray" size="24px"
                            readonly="false" (rate)="onRate($event)"></star-rating>
                    </div>

                    <div>
                        <mat-form-field class="mat-form-field-filtro">
                            <mat-label>Responsable</mat-label>
                            <mat-select formControlName="responsable">
                                <mat-option *ngFor="let cl of miAr" [value]="cl._id"> {{cl.nombre}} {{cl.apellido}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="in-for">
                            <mat-label>Dias</mat-label>
                            <input matInput formControlName="dias" autocomplete="off" type="text" />
                        </mat-form-field>
                    </div>


                    <div>
                        <div class="ct-bt-s">
                            <mat-form-field class="cfb-color">
                                <mat-label>Color:</mat-label>
                                <input matInput formControlName="colora" autocomplete="off" type="color" />
                            </mat-form-field>
                        </div>
                    </div>




                </div>

                <div class="ct-for-c">
                    <div>
                        <mat-form-field class="in-for">
                            <mat-label>Prelación:</mat-label>
                            <mat-select formControlName="pre" multiple>
                                <mat-option *ngFor="let topping of toppingList" [value]="topping._id">{{topping.nombre}}
                                    {{topping.apellido}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="ct-bt">
                        <button (click)="add();" class="btn" color="primary" mat-raised-button mat-button>
                            <mat-icon matListIcon>add</mat-icon>
                        </button>
                    </div>

                </div>



            </form>

        </section>


        <section>
            <div class="ct-h">
                <div class="ct-h-t">Tareas</div>
                <div class="ct-h-i">Entregable</div>
                <div class="ct-h-i">Resp</div>
                <div class="ct-h-i">Prelación</div>
                <div class="ct-h-i">Dias</div>
                <div class="ct-h-i">Prioridad</div>
                <div class="ct-h-i">Opción</div>
            </div>
            <div *ngFor="let env of obsTask | async" class="ct-d">
                <div class="ct-d-t" [ngStyle]="{'border-left':env.color+' solid 3px'}">
                    <span class="ct-d-ti"> <span class="ct-cit"
                            [ngStyle]="{'background-color':env.color}">{{env.cit}}</span>{{env.nombre}}</span>
                    <span class="ct-d-tis"> {{env.des}}</span>
                </div>
    
                <div class="ct-d-i">
                    {{env.entre}}
                </div>
    
                <div class="ct-d-i">
                    <app-ima-user-id [vUser]="env.responsable"></app-ima-user-id>
                </div>
    
    
                <div class="ct-d-i">{{env.dias}}</div>
                <div class="ct-d-i">{{env.dias}}</div>
                <div class="ct-d-i">
                    <star-rating value="{{env.prioridad}}" [totalstars]='5' checkedcolor="gold" uncheckedcolor="gray"
                        size="24px" readonly="true"></star-rating>
                </div>
                <div class="ct-d-i">
                    <mat-icon matTooltip="Eliminar tarea" class="color_white" (click)="onDialogDel(env._id)">delete
                    </mat-icon>
                    <mat-icon class="color_up" matTooltip="Actualizar tarea" (click)="onDialogUp(env._id, env._id_pro)">update</mat-icon>
                </div>
            </div>
    
    
        </section>


    </div>





</div>
<div class="footer"></div>