import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = 'appro';
  rol_Config = ["admin"];
  rol_Admin = ["admin", "gerente", "admin_vendedor"];
  rol_Auditor = ["admin", "gerente", "auditor"];
  rol_Vendedor = ["admin", "gerente", "vendedor", "admin_vendedor"];
  rol_Logistica = ["admin", "gerente", "logistica"];
  subscription: any;
  public ventas: any;
  theme: any = "";
  public emailUser: string;
  isLoggedIn$: Observable<boolean>;
  constructor(
    public loginService: LoginService
  ) { }

  ngOnInit(): void {


    this.loginService.vUser.subscribe(c => {
      if (c === "-") {
        this.emailUser = localStorage.getItem('email');
      } else {
        this.emailUser = c;
      }
    });
  }
  getInfo() {

    if (this.loginService.getEmail()) {
      this.loginService.email$.emit(this.loginService.getEmail());
    }
  }

}
