<div class="conte-mo">
    <div class="titulo-row-b">
      Registro nuevo canal de ventas
    </div>
    <mat-divider></mat-divider>
    <div class="diForm">
    <form [formGroup]="form" method="post" (ngSubmit)="add()">
      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Canal</mat-label>
        <input matInput formControlName="canal" required>
      </mat-form-field>
      <br>
      <mat-form-field class="mat-form" appearance="fill">
        <mat-label>Descripción</mat-label>
        <input matInput formControlName="obs" required>
      </mat-form-field>
      <br>
    
      <br>
      <div class="btn-enviar">
        <button class="btn-agregar" mat-raised-button color="primary">
          Activar
        </button>
      </div>
    </form>
  </div>
  </div>