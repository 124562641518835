<div *ngIf="show; else nodata">



  <div *ngIf="gd1m;else nodatam" class="ct-global">
    <div *ngFor="let env of obsGlo | async" class="ct-g-info">
      <mat-card class="mt-pre">
        <div class="ct-desc">
          <span class="ct-desc-t">Acumulado Anual</span>
        </div>
        <div class="ct-vol">


          <div *ngFor="let euv of env.arUni" class="ct-vol-list">
            <div><span class="mp-vol-k"> {{euv.volumen | number: '1.2-2'}}</span> <span class="mp-ivo">
                {{euv.unidad}}</span> </div>
          </div>

          <div *ngFor="let euvp of env.arUnipre" class="ct-vol-listp">
            <div><span class="mp-vol-k"> {{euvp.volumen | number: '1.2-2'}}</span> <span class="mp-ivo">
                {{euvp.unidad}}</span> </div>
          </div>
          <div *ngFor="let euo of env.arUnioc" class="ct-vol-list-oc">
            <div><span class="mp-vol-k"> {{euo.volumen | number: '1.2-2'}}</span> <span class="mp-ivo-oc">
                {{euo.unidad}}</span> </div>
          </div>


        </div>
        <div class="ct-master">



          <div class="mp">





            <div *ngIf="env.ventamxn>0" class="mp-tm">{{env.ventamxn | number: '1.2-2'}}</div>
            <div *ngIf="env.ventamxn>0" class="mp-i">MXN</div>
            <div *ngIf="env.ventauds>0" class="mp-tu">{{env.ventauds | number: '1.2-2'}} </div>
            <div *ngIf="env.ventauds>0" class="mp-i">USD</div>
            <div class="mp-tt">
              <span class="mp-i">VEN</span> {{env.venta | number: '1.2-2'}}
            </div>
            <div class="mp-ti">MXN</div>
            <div *ngIf="env.premxn>0" class="mp-tmx">{{env.premxn | number: '1.2-2'}}</div>
            <div *ngIf="env.premxn>0" class="mp-i">MXN</div>
            <div *ngIf="env.preuds>0" class="mp-tm">{{env.preuds | number: '1.2-2'}}  <span class="mp-i">USD</span><span class="bro">{{env.tc}}</span>{{env.presvalueToMXN | number: '1.2-2'}}</div>
            <div *ngIf="env.preuds>0" class="mp-i">MXN</div>
            <div class="mp-tp">

              <div class="mp-toc" *ngIf="env.presupuesto==0"></div>

              <span class="mp-i">PRE</span> {{env.presupuesto | number: '1.2-2'}}

            </div>
            <div  class="mp-ti">MXN</div>


            <div *ngIf="env.ocmxn>0" class="mp-tmx">{{env.ocmxn | number: '1.2-2'}}</div>
            <div *ngIf="env.ocmxn>0" class="mp-i">MXN</div>
            <div *ngIf="env.ocuds>0" class="mp-tm">{{env.ocuds | number: '1.2-2'}} <span class="mp-i ">USD</span><span class="bro">{{env.tc}}</span>{{env.ocvalueToMXN | number: '1.2-2'}}</div>
            <div *ngIf="env.ocuds>0" class="mp-i">MXN</div>
            <div class="mp-ttco">
                 <div class="mp-toc" *ngIf="env.ordencompra==0"></div>
              <span class="mp-i">OC</span> {{env.ordencompra | number: '1.2-2'}}
            </div>
            <div class="mp-ti">MXN</div>

          </div>
        </div>
      </mat-card>
      <div class="mp-s">
        Desde {{env.vD}} hasta {{env.vH}}
      </div>
    </div>

  </div>
  <ng-template #nodatam>
    <app-info-data [mensaje]="'Sin presupuesto ni venta'" [pd]=""> </app-info-data>
  </ng-template>
</div>
<ng-template #nodata>

  <div class="ct-global-w">
    <div class="ct-g-info">
      <mat-card class="mt-pre">
        <div class="ct-vol">
          <ngx-skeleton-loader count="1" [theme]="{ 
                  'border-radius': '5px', 
                  'width': '120px',
                  'height': '35px',
                  'background-color': '#daddd8'
              }">
          </ngx-skeleton-loader>
        </div>
        <div class="ct-master">
          <div class="mp-w">



            <div class="mp-tm">
              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '120px',
                      'height': '15px',
                      'background-color': '#daddd8'
                  }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-i">

              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '20px',
                      'height': '9px',
                      'background-color': '#daddd8'
                  }"> </ngx-skeleton-loader>

            </div>


            <div class="mp-tt">
              <span class="mp-i">
                <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '20px',
                      'height': '9px',
                      'margin-right': '5px',
                      'background-color': '#daddd8'
                  }"> </ngx-skeleton-loader>
              </span>
              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '150px',
                      'height': '30px',
                      'background-color': '#daddd8'
                  }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-ti">
              <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    'width': '20px',
                    'height': '9px',
                    'background-color': '#daddd8'
                }"> </ngx-skeleton-loader>
            </div>
            <div class="mp-tmx">
              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '120px',
                      'height': '15px',
                      'margin-top': '7px',
                      'background-color': '#daddd8'
                  }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-i">
              <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    'width': '20px',
                    'height': '9px',
                    'margin-top': '5px',
                    'background-color': '#daddd8'
                }"> </ngx-skeleton-loader>
            </div>
            <div class="mp-tp">
              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '20px',
                      'height': '9px',
                      'margin-right': '5px',
                      'background-color': '#daddd8'
                  }"> </ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '150px',
                      'height': '30px',
                      'background-color': '#daddd8'
                  }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-ti">
              <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    'width': '20px',
                    'height': '9px',
                    'background-color': '#daddd8'
                }"> </ngx-skeleton-loader>
            </div>
            <div class="mp-tp mp-toc">
              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '20px',
                      'height': '9px',
                      'margin-right': '5px',
                      'background-color': '#daddd8'
                  }"> </ngx-skeleton-loader>
              <ngx-skeleton-loader count="1" [theme]="{ 
                      'border-radius': '5px', 
                      'width': '150px',
                      'height': '30px',
                      'background-color': '#daddd8'
                  }">
              </ngx-skeleton-loader>
            </div>
            <div class="mp-ti">
              <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    'width': '20px',
                    'height': '9px',
                    'background-color': '#daddd8'
                }"> </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </mat-card>
      <div class="mp-s">
        <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '180px',
                'height': '9px',
                'margin-top': '5px',
                'background-color': '#daddd8'
            }"> </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>