<div class="conte-pro">

<div class="titulo-row-b">
  <button mat-icon-button>
    <mat-icon>insert_chart_outlined</mat-icon>
  </button>
  Ventas
  <mat-divider></mat-divider>
</div>

<app-gra-gru-mes></app-gra-gru-mes>


<div class="ct-mat-data" fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">

  <div class="dataYear"  *ngFor="let env of obs | async" >
 
    <app-s-ven-pre [vYear]="env.vYear" [env]="env"  [vPre]="env.vPre.value"></app-s-ven-pre>
  </div>
</div>
</div>