import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { default as _rollupMoment, Moment, } from 'moment';
import * as _moment from 'moment';
import { SalesService } from "../../../../services/sales/sales.service";
import { ActivatedRoute } from '@angular/router';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};
@Component({
  selector: 'app-cri-search-vdh',
  templateUrl: './cri-search-vdh.component.html',
  styleUrls: ['./cri-search-vdh.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CriSearchVdhComponent implements OnInit {
  clienteF = new FormControl();
  grupo = new FormControl();
  optionCliente: any[] = [];
  filteredOptionsCliente: Observable<string[]>;
  obs = new BehaviorSubject<any>(null);
  obsp = new BehaviorSubject<any>(null);
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  vCli: any;
  vD: any;
  vH: any;
  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  public searchText: any;
  filt: boolean = false;
  vPro: string;
  vRan: string;
  constructor(
    private salesService: SalesService,
    private route: ActivatedRoute,
  ) {

    this.vCli = this.route.snapshot.paramMap.get('vCli');
    this.vPro = this.route.snapshot.paramMap.get('vPro');
    this.vD = this.route.snapshot.paramMap.get('vD');
    this.vH = this.route.snapshot.paramMap.get('vH');
  }

  ngOnInit() {
    this.range.setValue({ start:this.vD, end: this.vH });
  }

  exeData() {
    let date = this.range.value;
    this.salesSearchProID(this.vCli, this.vPro, date.start, date.end);
  }

  salesSearchProID(vCli, vPro, vD, vH) {
    this.salesService.salesSearchProID({vCli:vCli, vPro:vPro, vD:vD, vH:vH})
      .subscribe((resp) => {        
        this.obsp.next(resp);
      });
  }

  ngAfterViewInit() {
    let date = this.range.value;
    this.salesSearchProID(this.vCli, this.vPro, date.start, date.end);
  }
}
