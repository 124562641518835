<div *ngIf="vShow; else noda">
    <div class="ct-panel" *ngFor="let env of dataPro | async">
        <mat-accordion class="ct-row-producto">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header class="ct-row-header">
                    <mat-panel-title>
                        <div class="ct-model">
                            <div class="item ima-cli">
                                <app-ima-client-id [idcliente]="env._id"></app-ima-client-id>
                            </div>
                            <div class="item name-cli">
                                <span class="idepc"> {{env.id_epicor}} </span><br>
                                {{env.nombre}}<br>
                            </div>

                            <div class="item name-a">
                                <span class="denon">VEN</span> {{env.venta_uds | number: '1.2-2' }} <span
                                    class="denom">USD</span>
                            </div>

                            <div class="item name-a">
                                <span class="denon">VEN</span> {{env.venta | number: '1.2-2' }} <span
                                    class="denom">MXN</span>
                            </div>
                        </div>
                    </mat-panel-title>

                </mat-expansion-panel-header>

                <app-list-search-cli [vPro]="vPro" [vD]="vD" [vH]="vH"></app-list-search-cli>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<ng-template #noda>
    <div class="ct-vlistWiat" *ngFor="let env of vlistWiat">
        <mat-card class="ct-pre">

            <div class="ct-model">
                <div class="item ima-cli">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '50px', 
                        'width': '55px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '55px',
                        'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>
                </div>
                <div class="item name-cli">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '100px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '12px',
                        'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>
                    <br>
                    <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '150px',
                'padding':'1px',
                'margin':'1px',
                'height': '18px',
                'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="item name-a">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '50px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '18px',
                        'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '20px',
                'padding':'1px',
                'margin':'1px',
                'height': '18px',
                'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="item name-a ims2">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '50px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '18px',
                        'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '20px',
                'padding':'1px',
                'margin':'1px',
                'height': '18px',
                'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>
                </div>
            </div>

        </mat-card>

    </div>

</ng-template>


