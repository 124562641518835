import { Pipe, PipeTransform } from '@angular/core';
// import { Post, posts } from './posts';
const { isArray } = Array;

@Pipe({
name: 'filter'
})
export class ProyectoFilterPipe implements PipeTransform {
transform(posts: any[], find: any): any[] {

if (find === '') return posts;
if(!posts) return [];
if(!find) return posts;


find = find.toLowerCase();
return search(posts, find);

}
}


function json2array(json){


var result = [];
var keys = Object.keys(json);
keys.forEach(function(key){
result.push(json[key]);
});
return result;
}
function toType(obj) {
return ({}).toString.call(obj).match(/\s([a-z|A-Z]+)/)[1].toLowerCase()
}


function search(entries: any[], search: string) {

search = search.toLowerCase();

return entries.filter(function (obj) {
const keys: string[] = Object.keys(obj);
return keys.some(function (key) {
const value = obj[key];
const value_a = json2array(value);

if(toType(value)=="object"){
return value_a.some(v => {
return v.toString().toLowerCase().includes(search);
});
}else{
return value.toString().toLowerCase().includes(search);
}

})
});
}
