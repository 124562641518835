<div *ngIf="mats; else nomats" class="ct-mats" >
<mat-card class="mat-card-pre" fxFlex.sm="0 1
calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" *ngFor="let env of obs | async" fxFlex.md="0 1 calc(33%-10px)"
    fxFlex.gt-md="0 1 calc(33%-10px)"  (click)="goToLink(env._id)" >

    <div class="ct-model">
        <div class="item">
            <span class="mat-card-pre-title{{env._id}}">{{ env._id }}</span>
        </div>

        <div class="item">
            <app-grafica-year [vyear]="env._id" [vpre]="getPrem(env.pre,env._id)"></app-grafica-year>
        </div>

    </div>
    <mat-divider></mat-divider>
    <table class="table-data">
        <tr class="table-data-conte" *ngFor="let pre of env.pre">
            <td>
                <span class="tdc-monto"> {{ pre.total | number: '1.2-2' }}</span>
            </td>
            <td>
                <span class="table-data-conte-moneda tdc-monto"> {{ pre.moneda }}</span>
            </td>
            <td>
                <span class="tdc-monto-{{ pre.moneda }}"> {{getCambio(pre.moneda,env._id)}} </span>
                <!-- <span class="tdc-monto-{{ pre.moneda }}"> {{obsTc}}</span> -->
            </td>

            <td>
                <span> {{ getTotal(pre.total, pre.moneda,env._id) | number: '1.2-2' }}</span>
            </td>
            <td>
                <span class="table-data-conte-moneda"> MXN </span>
            </td>
        </tr>
        <tr class="table-data-conte">
            <td colspan="3" class="tta" rowspan="2">
                <div class="table-data-ind">
                    <app-pcr-pre [vyear]="env._id" [vpre]="getPrem(env.pre,env._id)"></app-pcr-pre>
                </div>
            </td>

            <td class="table-data-conte-total">
                <span class="tdc-monto-gp"> {{ getPrem(env.pre,env._id) | number: '1.2-2' }}</span>
            </td>
            <td class="table-data-conte-total">
                <span class="table-data-conte-moneda"> MXN </span>
            </td>
        </tr>
        <tr class="table-data-conte">
            <td>
                <span class="tdc-monto-gv">
                    <app-totalven [vyear]="env._id" [vpre]="getPrem(env.pre,env._id)"></app-totalven>
                </span>
            </td>
            <td>
                <span class="table-data-conte-moneda"> MXN </span>
            </td>
        </tr>
    </table>
</mat-card>

</div>

<ng-template #nomats>




    <mat-card class="mat-card-pre" fxFlex.sm="0 1
    calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)"  fxFlex.md="0 1 calc(33%-10px)"
        fxFlex.gt-md="0 1 calc(33%-10px)">

        <div class="ct-model">
            <div class="item">
                <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '55px',
                'padding':'7px',
                'margin':'7px',
                'height': '34px',
                'background-color': '#daddd8'
            }">
                </ngx-skeleton-loader>
            </div>

            <div class="item">

                <ngx-skeleton-loader count="2" [theme]="{ 
                'border-radius': '5px', 
                'width': '230px',
                'padding':'1px',
                'margin':'1px',
                'height': '14px',
                'background-color': '#daddd8'
            }">
                </ngx-skeleton-loader>

            </div>
        </div>
        <mat-divider></mat-divider>
        <table class="table-data">
            <tr class="table-data-conte">
                <td>
                    <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '140px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '10px',
                        'background-color': '#daddd8'
                    }">
                    </ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '140px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '10px',
                        'background-color': '#daddd8'
                    }">
                    </ngx-skeleton-loader>
                </td>
            </tr>
            <tr class="table-data-conte-c">
                <td>
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '80px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '30px',
                        'background-color': '#daddd8'
                    }">
                    </ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader count="2" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '140px',
                        'padding':'0px',
                        'margin':'0px',
                        'height': '15px',
                        'background-color': '#daddd8'
                    }">
                    </ngx-skeleton-loader>

                </td>


            </tr>

        </table>
    </mat-card>


</ng-template>