import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {
  private URL = GlobalVariable.BASE_API_URL;
    public ventaSer$ = new EventEmitter<any>();
    public ventaSerListadoPre$ = new EventEmitter<any>();
    public getAllPreYearMes$ = new EventEmitter<any>();
    public upPreProMes$ = new EventEmitter<any>();
    public getPreClienteYear$ = new EventEmitter<any>();
    public getPreIdCliente$ = new EventEmitter<any>();



    constructor(
        private http: HttpClient,
        private router: Router) { }

    registroPresupuesto(pre) {
        return this.http.post<any>(this.URL + 'registroPresupuesto', pre);
    }

    getAllPresupuesto() {
        return this.http.get<any>(this.URL + 'getAllPresupuesto');
    }

    getAllPresupuestoAdd() {
        return this.http.get<any>(this.URL + 'getAllPresupuestoAdd');
    }

    getAllClientePre(datos) {
        return this.http.post<any>(this.URL + 'getAllClientesPre', datos);
    }

    delClientePre(id) {
        return this.http.post<any>(this.URL + 'delClientePre', id);
    }

    delPresupuestoAdd(id) {
        return this.http.post<any>(this.URL + 'delPresupuestoAdd', id);
    }

    getAllPreYearMes(id) {
        return this.http.post<any>(this.URL + 'getAllPreYearMes', id);
    }

    getAllPreDetCliente(id) {
        return this.http.post<any>(this.URL + 'getAllPreDetCliente', id);
    }

    getAllProMesActual(id) {
        return this.http.post<any>(this.URL + 'getAllProMesActual', id);
    }

    getPrePro(id) {
        return this.http.post<any>(this.URL + 'getPrePro', id);
    }

    upPreProMes(id) {
        return this.http.post<any>(this.URL + 'upPreProMes', id);
    }

    getPreClienteYear(data) {
        return this.http.post<any>(this.URL + 'getPreClienteYear', data);
    }


    getPreIdCliente(data) {
        return this.http.post<any>(this.URL + 'getPreIdCliente', data);
    }

    getPreClienteDetalleYear(data) {
        return this.http.post<any>(this.URL + 'getPreClienteDetalleYear', data);
    }

    updatePresupuesto(data) {
        return this.http.post<any>(this.URL + 'updatePresupuesto', data);
    }

    getAllPresupuestoAddCri(data) {
        return this.http.post<any>(this.URL + 'getAllPresupuestoAddCri', data);
    }


    getPreDecte() {
        return this.http.get<any>(this.URL + 'getPreDecte');
    }

    getPreMesAdminGlobal(data) {
        return this.http.post<any>(this.URL + 'getPreMesAdminGlobal', data);
    }
    
    getPreSemAdminGlobals(data) {
        return this.http.post<any>(this.URL + 'getPreSemAdminGlobals', data);
    }


    getPMAGCList(data) {
        return this.http.post<any>(this.URL + 'getPMAGCList', data);
    }

    getPMAGCListProducto(data) {
        return this.http.post<any>(this.URL + 'getPMAGCListProducto', data);
    }


    getPMAGCListProductoOut(data) {
        return this.http.post<any>(this.URL + 'getPMAGCListProductoOut', data);
    }


    getPM_CTV(data) {
        return this.http.post<any>(this.URL + 'getPM_CTV', data);
    }


    getPM_CTP(data) {
        return this.http.post<any>(this.URL + 'getPM_CTP', data);
    }

    


    getPMAGCListSem(data) {
        return this.http.post<any>(this.URL + 'getPMAGCListSem', data);
    }



    getPMAGCListProductoSem(data) {
        return this.http.post<any>(this.URL + 'getPMAGCListProductoSem', data);
    }


    getPMAGCListProductoOutSem(data) {
        return this.http.post<any>(this.URL + 'getPMAGCListProductoOutSem', data);
    }


    getPM_CTV_Sem(data) {
        return this.http.post<any>(this.URL + 'getPM_CTV_Sem', data);
    }


    getPM_CTP_Sem(data) {
        return this.http.post<any>(this.URL + 'getPM_CTP_Sem', data);
    }

    getPreGroupYear(data) {
        return this.http.post<any>(this.URL + 'getPreGroupYear', data);
    }
    
    getAllGroupClienteMes(data) {
        return this.http.post<any>(this.URL + 'getAllGroupClienteMes', data);
    }
    

    getPreYearIdCliCus(data) {
        return this.http.post<any>(this.URL + 'getPreYearIdCliCus', data);
    }
    
    masterPreCusVenYear(data) {
        return this.http.post<any>(this.URL + 'masterPreCusVenYear', data);
    }
    

    getTipoCambio(data){
        return this.http.post<any>(this.URL + 'getTipoCambio', data);

    }


    getTipoCambioArray(){
        return this.http.get<any>(this.URL + 'getTipoCambioArray');

    }
    
    
}
