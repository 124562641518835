<form [formGroup]="formPurchaseOrder">

    <div class="ct-for-a">

        <div>
            <mat-form-field class="in-for">
                <mat-label>Tarea</mat-label>
                <input matInput formControlName="tarea" autocomplete="off" type="text" />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="in-for">
                <mat-label>Descripcion</mat-label>
                <input matInput formControlName="des" autocomplete="off" type="text" />
            </mat-form-field>
        </div>
    </div>
    <div class="ct-for">


        <div>
            <mat-form-field class="in-for">
                <mat-label>Entregable</mat-label>
                <input matInput formControlName="entre" autocomplete="off" type="text" />
            </mat-form-field>
        </div>



        <div class="ct-pri-con">
            <span class="ct-pri">Prioridad</span> <br>
            <star-rating value="0" [totalstars]='5' checkedcolor="gold" uncheckedcolor="gray" size="24px"
                readonly="false" (rate)="onRate($event)"></star-rating>
        </div>

        <div>
            <mat-form-field class="mat-form-field-filtro">
                <mat-label>Responsable</mat-label>
                <mat-select formControlName="responsable">
                    <mat-option *ngFor="let cl of miAr" [value]="cl._id"> {{cl.nombre}} {{cl.apellido}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="in-for">
                <mat-label>Dias</mat-label>
                <input matInput formControlName="dias" autocomplete="off" type="text" />
            </mat-form-field>
        </div>


        <div>
            <div class="ct-bt-s">
                <mat-form-field class="cfb-color">
                    <mat-label>Color:</mat-label>
                    <input matInput formControlName="colora" autocomplete="off" type="color" />
                </mat-form-field>
            </div>
        </div>




    </div>

    <div class="ct-for-c">
        <div>
            <mat-form-field class="in-for">
                <mat-label>Prelación:</mat-label>
                <mat-select formControlName="pre" multiple>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping._id">{{topping.cit}}
                        {{topping.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="ct-bt">
            <button (click)="add();" class="btn" color="primary" mat-raised-button mat-button>
                <mat-icon matListIcon>add</mat-icon>
            </button>
        </div>

    </div>



</form>
