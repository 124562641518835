import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  public listNoti$ = new EventEmitter<any>();

  private URL = GlobalVariable.BASE_API_URL;
  public clienteAsignacionSer$ = new EventEmitter<any>();
  constructor(private http: HttpClient, private router: Router) {}

  regLogs(data) {
    return this.http.post<any>(this.URL + 'regLogs', data);
  }

  getLogs() {
    return this.http.get<any>(this.URL + 'getLogs');
  }
  
}
