import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { VenMesAdminService } from "../../../../services/sales/ven-mes-admin.service";


@Component({
  selector: 'app-gre-ven-mes-day',
  templateUrl: './gre-ven-mes-day.component.html',
  styleUrls: ['./gre-ven-mes-day.component.css']
})
export class GreVenMesDayComponent implements OnInit {

  @Input() vMes: any;
  @Input() vCliente: any;
  @Input() vYear: any;
  arrayWait=[1,2,3,4,5,6,7];
  loading: boolean = false;
  bview: any[] = [700, 380];
  bshowXAxis = true;
  bshowYAxis = true;
  bgradient = true;
  bshowLegend = false;
  bshowXAxisLabel = false;
  bxAxisLabel = '';
  blegendTitle = 'Cliente';
  bshowYAxisLabel = true;
  byAxisLabel = 'MXN';
  bcolorScheme = {
    domain: ['#FF1493', '#00BFFF', '#FFD700', '#1a73d9', '#D2B48C', '#800080', '#90EE90', '#e83f6f', '#B0E0E6', '#ef2917', '#CD853F', '#708090']
  };
  dataResult: any;

  constructor(

    private venMesAdminService: VenMesAdminService
  ) { }

  ngOnInit() {

    this.orqVenMesDet(this.vCliente,this.vYear, Number(this.vMes));
  }

  orqVenMesDet(vCliente, vYear, vMes) {
    this.venMesAdminService.orqVenMesDet({ _id_cliente: vCliente, yeara: vYear  , mes: vMes }).subscribe(resp => {
      this.dataResult = resp;      
      this.loading = true;
    });
  }

  diaSemana(value) {
    let dias = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    let date = new Date(value);
    var fechaString = value;
    return dias[date.getDay()];
  }

  mesSemana(value) {
    let meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    let date = new Date(value);
    var fechaString = value;
    var mes_name = parseInt( fechaString.substring(5,7))-1;
    var fechaNum = fechaString.substring(8,10);
    return fechaNum + " " + meses[mes_name];
  }

  getActivo(value){
    if (value>0) {
      return "activo";
    } else {
      return "no-activo";
    }
  }

  getActMon(value){
    if (value>0) {
      return "a";
    } else {
      return "b";
    }
  }
}
