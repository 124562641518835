<div class="conte-pro">
    <div class="titulo-row-b">
        <button mat-icon-button [routerLink]="['/purchase_order']">
            <mat-icon>navigate_before</mat-icon>
        </button>
         {{this.vOc}}
    </div>
    <mat-divider></mat-divider>
    <form [formGroup]="formPurchaseOrder">

        <div class="ct-c">

       
            <div>
                <mat-form-field class="mat-form-fielda">
                    <mat-label>Fecha OC</mat-label>
                    <input matInput [matDatepicker]="dp1" formControlName="fecha_oc" autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1>
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="mat-form-fielda">
                    <mat-label>Fecha Embarque</mat-label>
                    <input matInput [matDatepicker]="dp2" formControlName="fecha_em" autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2>
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="mat-form-fielda">
                    <mat-label>Fecha Entrega</mat-label>
                    <input matInput [matDatepicker]="dp3" formControlName="fecha_ent" autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3>
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>CI</mat-label>
                    <input [disabled]="!bso" matInput formControlName="ci" autocomplete="off" type="text" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>OC</mat-label>
                    <input matInput formControlName="oc" autocomplete="off" type="text" />
                </mat-form-field>
            </div>
        </div>
        <div class="ct-p-listado">
            <div>
                <mat-form-field>
                    <mat-label>Canal:</mat-label>
                    <mat-select formControlName="canal">
                  
                        <mat-option *ngFor="let ch of obsCh | async" [value]="ch._id"> {{ch.canal}} </mat-option>

                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Buscar por:</mat-label>
                    <mat-select formControlName="afor">
                        <mat-option value="razon_social">NOMBRE</mat-option>
                        <mat-option value="id_epicor">COD EPICOR</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div (click)="getEstadoInicial()">
                <mat-form-field class="example-full-width">
                    <input [formControl]="clienteF" type="text" placeholder="Cliente" aria-label="Number" matInput
                        [matAutocomplete]="autoCliente"  required>
                    <mat-autocomplete autoActiveFirstOption #autoCliente="matAutocomplete">
                        <mat-option *ngFor="let optionCliente of filteredOptionsCliente | async"
                            [value]="optionCliente.razon_social" (click)="setCliente(optionCliente._id);">
                            <div class="listadoProducto">
                                {{optionCliente.id_epicor}} - {{optionCliente.razon_social}}
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="ct-p-listado-producto">
            <div>
                <mat-form-field>
                    <mat-label>Buscar por:</mat-label>
                    <mat-select formControlName="bfor">
                        <mat-option value="nombre">NOMBRE</mat-option>
                        <mat-option value="numparte">COD EPICOR</mat-option>
                        <mat-option value="cbag">UPC</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div (click)="getEstadoProducto()">
                <mat-form-field class="example-full-width">
                    <input type="text" placeholder="Producto" aria-label="Number" matInput [formControl]="productoF"
                        [matAutocomplete]="autoProducto" required #elementPro>
                    <mat-autocomplete autoActiveFirstOption #autoProducto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptionsProducto | async" [value]="option.nombre"
                            (click)="setProducto(option._id);">
                            <div class="listadoProducto">
                                {{option.numparte}} - {{option.nombre}}
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>UPC</mat-label>
                    <input matInput formControlName="upc" autocomplete="off" type="text" />
                </mat-form-field>
            </div>
        </div>

        <div class="ct-ban">
            <div>
                <mat-form-field>
                    <mat-label>Unidad</mat-label>
                    <mat-select formControlName="unidad">
                        <mat-option *ngFor="let uni of unidades" [value]="uni._id">
                            {{uni.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Volumen</mat-label>
                    <input matInput formControlName="volumen" autocomplete="off" type="number"
                        (keyup)="ct($event.target.value)" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Precio x Unidad</mat-label>
                    <input matInput formControlName="precio" autocomplete="off" currencyMask
                        [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }" (keyup)="ct($event.target.value)" />
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <mat-label>Moneda:</mat-label>
                    <mat-select formControlName="moneda">
                        <mat-option value="MXN">MXN</mat-option>
                        <mat-option value="USD">USD</mat-option>

                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Total</mat-label>
                    <input matInput formControlName="monto" autocomplete="off" currencyMask
                        [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }" />
                </mat-form-field>
            </div>
        </div>
        <div class="columna5">
            <div class="ct-btn">
                <button (click)="add();" class="btn" [disabled]="btn_add" color="primary" mat-raised-button mat-button>
                    <mat-icon matListIcon>add</mat-icon> Agregar
                </button>
                <button (click)="up();" class="btn-up" [disabled]="btn_up" color="primary" mat-raised-button mat-button>
                    <mat-icon matListIcon>update</mat-icon> Actualizar {{msj}}
                </button>
            </div>
        </div>
    </form>







    <table mat-table [dataSource]="dataSource" class="table-data mat-elevation-z8">

        <ng-container matColumnDef="producto">
            <th mat-header-cell *matHeaderCellDef> Producto </th>
            <td mat-cell *matCellDef="let element"> {{element._id_producto.nombre}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="volumen">
            <th mat-header-cell *matHeaderCellDef> Volumen </th>
            <td mat-cell *matCellDef="let element"> {{element.volumen}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="unidad">
            <th mat-header-cell *matHeaderCellDef> Unidad </th>
            <td mat-cell *matCellDef="let element"> {{element._id_unidad.across}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="precio">
            <th mat-header-cell *matHeaderCellDef> Precio </th>
            <td mat-cell *matCellDef="let element"> {{montoValue(element.precio) }} </td>
            <td mat-footer-cell *matFooterCellDef><b>TOTAL</b></td>
        </ng-container>

        <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef> Monto </th>
            <td mat-cell *matCellDef="let element"> {{montoValue(element.monto)}} </td>
            <td mat-footer-cell *matFooterCellDef> <b>{{getTotalCost() | currency}}</b> </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">

                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button matTooltip="¿Seguro que deseas borrar este producto?" matTooltipClass="example-tooltip-red"
                        mat-menu-item (click)="onDelAsig(element._id, element.ci)">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Eliminar</span>
                    </button>

                </mat-menu>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr (click)="getItem(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>



    <mat-paginator [length]="5" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

</div>