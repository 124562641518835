import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { log } from 'console';
import { AreaService } from 'src/app/services/area.service';
import { AreaDelComponent } from "./../area-del/area-del.component";
var moment = require('moment');

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.css'],
})
export class CardInfoComponent implements OnInit {
  @Input() vMie: string;
  @Input() vTas: string;

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;
  date: any;
  show: boolean = false;
  numTask: any;
  numTaskTer: any;
  constructor(private areaService: AreaService,private dialog: MatDialog) {}

  async ngOnInit() {
    this.areaService.cBeem$.subscribe(async (val) => {
      this.date = await this.getTasDate(this.vTas);
      this.numTask = await this.getTasKCount(this.vTas);
      this.numTaskTer = await this.getTasKCountTer(this.vTas);
    });

    this.date = await this.getTasDate(this.vTas);
    this.numTask = await this.getTasKCount(this.vTas);
    this.numTaskTer = await this.getTasKCountTer(this.vTas);

    this.show = true;
  }

  async getTasDate(vAr) {
    let obj;
    await this.areaService
      .getAllTaskAreaId({ vAr: vAr })
      .toPromise()
      .then((resp) => {
        let oriG = resp;

        if (oriG.length > 0) {
          let dRange = resp;
          let a = dRange.shift();
          let b = dRange.pop();

          if (!b) {
            b = { fecha_end: a.fecha_end };
          }

          obj = {
            fecha_star: moment(a.fecha_star).format('YYYY-MM-DD'),
            fecha_end: moment(b.fecha_end).format('YYYY-MM-DD'),
          };
        } else {
          obj = {
            fecha_star: null,
            fecha_end: null,
          };
        }
      })
      .catch((e) => console.error(e));

    return obj;
  }

  async getTasKCount(vAr) {
    let obj;
    await this.areaService
      .getTasKCount({ vAr: vAr })
      .toPromise()
      .then((resp) => {
        obj = resp.count;

      })
      .catch((e) => console.error(e));

    return obj;
  }

  async getTasKCountTer(vAr) {
    let obj;
    await this.areaService
      .getTasKCountTer({ vAr: vAr })
      .toPromise()
      .then((resp) => {
        obj = resp.count;
      })
      .catch((e) => console.error(e));

    return obj;
  }

  getDayDiffRest(dateStart, dateEnd) {
    if (!dateStart && !dateStart) {
      return 0;
    }

    let rdays;
    let rToSay = moment();
    let rStar = moment(dateStart);
    let rEnd = moment(dateEnd);

    if (moment().isBetween(dateStart, dateEnd)) {
      rdays = rEnd.diff(rToSay, 'days');
    } else {
      rdays = rEnd.diff(rStar, 'days');
    }

    return rdays + 1;
  }

  getDayDiff(dateStart, dateEnd) {
    if (!dateStart && !dateEnd) {
      return 0;
    }

    let rStar = moment(dateStart);
    let rEnd = moment(dateEnd);
    let rdays = rEnd.diff(rStar, 'days');

    return rdays + 1;
  }

  getCronograma(a, b) {
    if (!a && !b) {
      return 0;
    }

    let resul = (a / b) * 100;

    return resul;
  }


  onDialogDel(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '40%';
    dialogConfig.data = { data: { id: id } };
    this.dialog.open(AreaDelComponent, dialogConfig);
  }

}
