import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CustomerService } from "./../../../../services/customer.service";

@Component({
  selector: 'app-list-customer-general-product',
  templateUrl: './list-customer-general-product.component.html',
  styleUrls: ['./list-customer-general-product.component.css']
})
export class ListCustomerGeneralProductComponent implements OnInit {
  @Input() vPro: String;
  obs = new BehaviorSubject<any>(null);
  showp: boolean;

  constructor(
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {

    this.listCustomerGeneralProduct(this.vPro);

  }

  listCustomerGeneralProduct(vPro) {
    this.customerService.listCustomerGeneralProduct({ vPro: vPro }).subscribe(resp => {
      
      if (resp.length>0) {
        this.showp = true;
        this.obs.next(resp);
      } else {
        this.showp = false;
      }
   

    });

  }

}


