import { Injectable, EventEmitter } from '@angular/core';
import { SnackbarService } from '../services/snackbar.service';
import { GlobalVariable } from '../global';
@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private snackbarService: SnackbarService) {}

  copyData(data, title) {
    let dataArray = '';
    dataArray += this.ObjectToArray(title);
    data.forEach((row) => {
      dataArray += this.ObjectToArray(row);
    });
    this.snackbarService.msj('Datos copiados en portapapeles', 'ok');
    return dataArray;
  }

  copyDataLeveTwo(data, pro, title, titlePro) {
    let dataArray = '';

    dataArray += this.ObjectToArray(title);
    data.forEach((row) => {
      dataArray += this.ObjectToArray(row);
      dataArray += this.ObjectToArray(titlePro);
      row.prod.forEach((rowp) => {
        dataArray += this.ObjectToArray(rowp);
      });
      dataArray += '\r\n';
    });

    this.snackbarService.msj('Datos copiados en portapapeles, utiliza Ctrl+V', 'ok');
    return dataArray;
  }

  copyDataLeveThre(data, pro, title, titlePro) {
    let dataArray = '';

    dataArray += this.ObjectToArray(title);
    data.forEach((row) => {

      dataArray += this.ObjectToArray(row);
      dataArray += this.ObjectToArray(titlePro);
      row.prod.forEach((rowp) => {
        dataArray += this.ObjectToArray(rowp);
        rowp.mes.forEach((rowm) => {
          dataArray += '\r\n';
          rowm.series.forEach((rowss) => {
            dataArray += this.ObjectToArray(rowss);
          });
          
        });
        dataArray += '\r\n';
      });
      dataArray += '\r\n';
    });

    this.snackbarService.msj('Datos copiados en portapapeles, utiliza Ctrl+V', 'ok');
    return dataArray;
  }

  ObjectToArray(obj: object): string {
    let result = Object.keys(obj).map((key: keyof typeof obj) => {
      let value = obj[key];
      return value;
    });
    return result.toString() + '\n';
  }
}
