<div class="conte-pro">
    <div class="titulo-row-b">
        <button mat-icon-button [routerLink]="['/shipping']">
            <mat-icon>navigate_before</mat-icon>
        </button>
        Envios Activos
    </div>
    <mat-divider></mat-divider>
    <div class="mat-card conter-ficha" *ngFor="let env of obs | async ">
        <div class="dse">
            <button mat-button matTooltip="Fecha de Registro: {{formatDateFull(env.envios.createdAt)}}">
                <span class="icom">{{env.envios.ci}}</span>
                <mat-icon class="icom" matListIcon>local_shipping</mat-icon>
            </button>
        </div>
        <mat-grid-list cols="20" rowHeight="100px">
            <mat-grid-tile colspan="2">
                <app-ima-cliente [idcliente]="env.cli.id_epicor"></app-ima-cliente>
            </mat-grid-tile>
            <mat-grid-tile colspan="6">
                <div class="text-inside-grid">
                    <table class="table-data">
                        <tr>
                            <td> <span class="sut">{{env.envios.op}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="titulo-cli">{{formatRazonSocial(env.cli.razon)}}
                            </td>
                        </tr>
                        <tr>
                            <td><span class="sut">{{env.envios.destino.toUpperCase() }}</span></td>
                        </tr>
                    </table>
                </div>
            </mat-grid-tile>
            <mat-grid-tile class="cone" colspan="11">
                <div class="cone">
                    <app-table-estados-envios ci="{{env.envios.ci}}" idcliente="{{env.cli._id}}" op="{{env.envios.op}}"
                        idenv="{{env.envios._id}}" dateRepro="{{env.envios.fecha_rep}}"
                        datellegada="{{env.envios.fecha_llegada}}">
                    </app-table-estados-envios>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
                        mat-menu-item (click)="onDelAsig(env.envios._id, env.envios.op, env.envios.ci,env.cli._id);">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Eliminar</span>
                    </button>
                </mat-menu>
            </mat-grid-tile>
        </mat-grid-list>


        <table class="table-data-detalle">
            <tr class="mat-header-row">
                <th class="mat-header-cell">SALIDA</th>
                <th class="mat-header-cell">LLEGADA</th>
                <th *ngIf="env.envios.repro==true" class="mat-header-cell"> >> REPROGRAMADA</th>
                <th class="mat-header-cell">UBICACIÓN ACTUAL</th>
                <th class="mat-header-cell">MAPA</th>
                <th class="mat-header-cell">ESTADO</th>
            </tr>
            <tr class="mat-row">
                <td class="mat-cell">{{formatDate(env.envios.fecha_salida)}} </td>
                <td *ngIf="env.envios.repro==true" class="mat-cell">
                    <del> {{formatDate(env.envios.fecha_llegada)}} </del>
                </td>
                <td *ngIf="env.envios.repro==null" class="mat-cell">
                    {{formatDate(env.envios.fecha_llegada)}}
                </td>
                <td *ngIf="env.envios.repro==true" class="mat-cell">{{formatDate(env.envios.fecha_rep)}}
                </td>
                <td class="mat-cell">{{env.envios.lugar_actual}} </td>
                <td class="mat-cell">
                    <a target="_blank" class="btn" color="primary" mat-button mat-raised-button
                        href="https://maps.google.com/?q={{env.envios.lugar_actual}}">
                        <mat-icon>location_on</mat-icon>
                    </a>
                </td>
                <td class="mat-cell">
                    <div class="row-pro-f {{env.envios.repro}}">{{env.envios.estado_general}}</div>
                </td>
            </tr>
        </table>
        <mat-grid-list cols="1" rowHeight="300px">
            <mat-grid-tile class="mat-figure">
                <div class="table-data">
                    <app-table-productos-envios ci="{{env.envios.ci}}" op="{{env.envios.op}}"
                        idcliente="{{env.cli._id}}"></app-table-productos-envios>
                </div>
            </mat-grid-tile>
        </mat-grid-list>


    </div>
</div>