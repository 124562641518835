<div>
    <div class="ct-vyear">

        <mat-form-field class="selem">
            <mat-label>Año</mat-label>
            <mat-select [formControl]="selep" name="selem" (selectionChange)="updateAn()">
                <mat-option *ngFor="let lv of listRango" value="{{lv.vYear}}">
                    {{ lv.vYear }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div *ngIf="ctpa" class="ctp-a">




    <div class="ct-tra" *ngIf="bttn>0">
        <div>
            <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                <mat-chip class="example-box chip" cdkDrag *ngFor="let ens of selection.selected"
                    [removable]="removable" (removed)="remove(ens)">
                    {{ens.razon_social}}
                    <mat-icon class="ico-rs" matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div>
            <button class="dbtnas" [disabled]="!btt" color="accent" mat-raised-button mat-button
                (click)="onDialogTras()">
                <mat-icon>swap_horiz</mat-icon> Trasladar a <span *ngIf="bttn>0"> ({{bttn}}) </span>
            </button>
        </div>
    </div>

    <mat-grid-list *ngIf="loading;else noda" cols="1" rowHeight="200px">
        <mat-grid-tile>
            <mat-spinner diameter="70" strokeWidth="10"></mat-spinner>
        </mat-grid-tile>
    </mat-grid-list>

    <ng-template #noda>
        <div *ngIf="show; else nodatq">
            <mat-form-field class="mat-form-field-filtro" appearance="fill">
                <mat-label>Filtro</mat-label>
                <input matInput (keyup)="applyFilters($event)" placeholder="Ejemplo: Profusa Product"
                    autocomplete="off" />
            </mat-form-field>
            <table mat-table [dataSource]="dataSourceClis" multiTemplateDataRows class="table-data">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="cod">
                    <th mat-header-cell *matHeaderCellDef> Código </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.id_epicor}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="ima">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <app-ima-cliente [idcliente]="element.id_epicor" [classCss]="'sm'">
                        </app-ima-cliente>
                    </td>
                </ng-container>
                <ng-container matColumnDef="razon_social">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.razon_social}} </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsClis.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <app-ch-product [vCli]="element._id_cliente"></app-ch-product>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsClis" class="ct-he"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumnsClis;" class="example-element-row "
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>
        <ng-template #nodatq>
            <div class="divRespo">
                <app-info-data [pd]="" [mensaje]="'Por clientes, nada que agrupar'"></app-info-data>
            </div>
        </ng-template>
    </ng-template>
    <mat-paginator [length]="5" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

</div>