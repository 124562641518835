import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MargenService } from '../../services/margen.service';
import * as moment from 'moment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-margen',
  templateUrl: './margen.component.html',
  styleUrls: ['./margen.component.css'],
})
export class MargenComponent implements OnInit {
  listClientes: any;
  criterioCliente: FormGroup;
  dataBos = new BehaviorSubject<any>(null);
  public searchText: any;

  multi: any[];
  view: any[] = [700, 200];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  animations: boolean = true;
  legendTitle: string = 'Clientes';

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA'],
  };
  public SalasDataS = new BehaviorSubject(null);
  mat = [];

  customColors = (name) => {
    const value = this.mat.find((s) => s.name === name).value;
    if (value > 0) {
      return '#1cb70a';
    } else {
      return '#ed0000';
    }
  };
  s1: boolean = false;
  s2: boolean;
  private _id_cliente: any;
  sb: boolean;
  sa: boolean = true;
  yeara: any;
  mes: any;
  idcliente: any;
  nombre_cliente: any;
  e1: boolean;
  newlist: any;
  mylist: any;
  mylistgra: any;

  constructor(
    private margenServices: MargenService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.createForm();
    const date = this.getWeek();
    this.getAllEnvioCri(date.ya, null);
  }

  reset() {
    this.searchText = '';
  }

  getWeek() {
    var date = new Date();
    const mes = moment(date).format('M');
    const ya = moment(date).format('YYYY');

    let dataFecha = {
      m: mes,
      ya: ya,
    };
    return dataFecha;
  }

  setMeno(val) {
    this.resetObs();

    let value = this.dataBos.getValue();

    const result = value.filter((word) => word.pcr < Number(val));
    this.dataBos.next(result);

    const resultgra = this.mat.filter((gra) => gra.value < Number(0+'.'+val));

    this.mat=[...resultgra];
    

  }

  setMenom(val) {
    this.resetObs();
    let value = this.dataBos.getValue();

    const result = value.filter((word) => word.pcr > Number(val));
    this.dataBos.next(result);


    const resultgra = this.mat.filter((gra) => gra.value > Number(0+'.'+val));

    this.mat=[...resultgra];

  }

  resetObs() {
    //

    this.mat=this.mylistgra;
    this.dataBos.next(this.mylist);
  }

  getAllEnvioCri(yeara, mes) {
    this.e1 = true;
    this.margenServices
      .getMargenClien({ yeara: yeara, mes: mes })
      .subscribe((resp) => {
        // this.listClientes = resp;
        this.mylist = resp;
        this.dataBos.next(resp);
        this.multi = resp.grafica;
        this.e1 = false;
        if (resp.length > 0) {
          this.s1 = true;
        } else {
          this.s1 = false;
        }
      });

    this.margenServices
      .graficaMargenClien({ yeara: yeara, mes: mes })
      .subscribe((resp) => {
        this.mylistgra = resp;
        this.mat = resp;
      });
  }

  createForm() {
    const dateObj = this.getWeek();
    this.criterioCliente = this._formBuilder.group({
      an: new FormControl(Number(dateObj.ya), [Validators.required]),
      mes: new FormControl(Number(dateObj.m), [Validators.required]),
    });
  }

  updateAn(event) {
    const yeara = this.criterioCliente.controls.an.value;
    const mes = this.criterioCliente.controls.mes.value;
    this.getAllEnvioCri(yeara, mes);
  }

  updateAna(_id_cliente, nombre_cliente) {
    const yeara = this.criterioCliente.controls.an.value;
    const mes = this.criterioCliente.controls.mes.value;
    this.idcliente = _id_cliente;
    this.yeara = yeara;
    this.nombre_cliente = nombre_cliente;
    this.mes = mes;
    this.sa = false;
    this.sb = true;
  }

  resetDivGlobal() {
    this.sa = true;
    this.sb = false;
  }
}
