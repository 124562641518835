<div *ngIf="g && d; else jose" class="ct-gra">

  <div class="g1">
    <div class="g2">
      <div class="g3">
        <combo-chartt-component [scheme]="comboBarScheme" [colorSchemeLine]="lineChartScheme" [results]="barChart"
          [animations]="animations" [lineChart]="linChart" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
          [legend]="showLegend" [legendTitle]="legendTitle" [legendPosition]="legendPosition"
          [showGridLines]="showGridLines" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
          [showRightYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
        </combo-chartt-component>
      </div>
    </div>
  </div>
</div>

<ng-template class="cards-cli" #jose>
<div class="block-wiat">
  <ngx-skeleton-loader *ngFor="let iWiat of wiat"  count="1" 
  [theme]="iWiat">
  </ngx-skeleton-loader>
</div>
</ng-template>

