

<div *ngIf="mats; else nomats" class="ct-mats">
    <!-- <mat-card [routerLink]="['/ventas_detalles', _id_epicor, sem, yeara]"   class="mat-card-pre" fxFlex.sm="0 1 -->
        <mat-card (click)="goToLink(_id_epicor,sem, yeara)"  class="mat-card-pre" fxFlex.sm="0 1
  
        calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(33%-10px)">
        <span *ngIf="vendedor" class="content-login--logo">{{vendedor}}</span>
        <span class="ct-numer">{{vNum+1}}</span>
        <div class="ct-model">
            <div class="item ima-cli">
                <app-ima-cliente [idcliente]="_id_epicor"></app-ima-cliente>
            </div>
            <div class="item name-cli">
                <span class="idE">{{_id_epicor}}</span> <br>
                {{name_cli}}
            </div>
        </div>
        <mat-divider></mat-divider>




        <div class="ct-totals">


            <div class="item-value-total ct-totals-item1">
                <app-pcr-ven-pre [vVen]="series | async"></app-pcr-ven-pre>
            </div>

            <div *ngFor="let tvps of series | async" class="item-value">

                <div class="ct-value-cero" *ngIf="tvps.value==0;else nodata">
                    <div class="item-value-cero"> Sin Presupuesto </div>
                </div>
                <ng-template #nodata>
                    <span class="item-ind">{{ tvps.extra.tipo }}</span>


                    <span *ngIf="tvps.extra.tipo=='VEN'" class="item-value-total-{{tvps.extra.tipo}}">{{
                        getValueDat(tvps.value) | number: '1.2-2' }} </span>



                    <span *ngIf="tvps.extra.tipo=='PRE'" class="item-value-total-{{tvps.extra.tipo}}" matTooltip="PRE-Mensual: {{tvps.extra.acm[0] |
                            number: '1.2-2'}}">{{ getValueDat(tvps.value) |
                        number: '1.2-2'}} </span>

                    <span class="item-moneda">MXN</span>

                </ng-template>






            </div>




        </div>




    </mat-card>

</div>

<ng-template #nomats>
<div class="mat-card-pre" >
    <mat-card class="mat-card-pre" fxFlex.sm="0 1
        calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(33%-10px)"
        fxFlex.gt-md="0 1 calc(33%-10px)">

        <div class="ct-model">
            <div class="item">
                <ngx-skeleton-loader count="1" [theme]="{ 
                    'border-radius': '5px', 
                    width: '55px',
                    'padding':'7px',
                    'margin':'7px',
                    height: '34px',
                    'background-color': '#daddd8'
                }">
                </ngx-skeleton-loader>
            </div>

            <div class="item">

                <ngx-skeleton-loader count="2" [theme]="{ 
                    'border-radius': '5px', 
                    width: '230px',
                    'padding':'1px',
                    'margin':'1px',
                    height: '14px',
                    'background-color': '#daddd8'
                }">
                </ngx-skeleton-loader>

            </div>
        </div>
        <mat-divider></mat-divider>
        <table class="table-data">
            <tr class="table-data-conte">

                <td>
                    <ngx-skeleton-loader count="2" [theme]="{ 
                            'border-radius': '5px', 
                            width: '140px',
                            'padding':'0px',
                            'margin':'0px',
                            height: '10px',
                            'background-color': '#daddd8'
                        }">
                    </ngx-skeleton-loader>

                </td>

                <td>
                    <ngx-skeleton-loader count="2" [theme]="{ 
                            'border-radius': '5px', 
                            width: '140px',
                            'padding':'0px',
                            'margin':'0px',
                            height: '10px',
                            'background-color': '#daddd8'
                        }">
                    </ngx-skeleton-loader>

                </td>
            </tr>


            <tr class="table-data-conte-c">

                <td>
                    <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            width: '80px',
                            'padding':'0px',
                            'margin':'0px',
                            height: '30px',
                            'background-color': '#daddd8'
                        }">
                    </ngx-skeleton-loader>

                </td>

                <td>
                    <ngx-skeleton-loader count="2" [theme]="{ 
                            'border-radius': '5px', 
                            width: '140px',
                            'padding':'0px',
                            'margin':'0px',
                            height: '15px',
                            'background-color': '#daddd8'
                        }">
                    </ngx-skeleton-loader>

                </td>


            </tr>

        </table>
    </mat-card>
</div>

</ng-template>