<ng-container *ngIf="this.loginService.loginIn();">
    <a routerLink="/home" mat-button>
        <mat-icon matListIcon>assessment</mat-icon> Inicio
    </a>
</ng-container>
<ng-container *ngIf="this.loginService.loginIn();">
    <ng-container *appDroles="rol_Vendedor">
        <a routerLink="/proyectos" mat-button>
            <mat-icon matListIcon>how_to_reg</mat-icon>Mis Proyectos
        </a>
    </ng-container>
</ng-container>
<ng-container *ngIf="this.loginService.loginIn();">
        <span class="fecha"> <span class="fecha-a">Actualizado: </span> <span class="fecha-a">{{formatDate(this.fecha )}}</span>  </span>
</ng-container>