import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from "./../../../services/customer.service";
@Component({
  selector: 'app-ejecutivo',
  templateUrl: './ejecutivo.component.html',
  styleUrls: ['./ejecutivo.component.css']
})



export class EjecutivoComponent implements OnInit {
  datosEjecutivos: Ejecutivo=new Ejecutivo();
@Input() idcliente:string;
  constructor(
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.getDatosEjecutivo_ID_Actual(this.idcliente);
  }

  getDatosEjecutivo_ID_Actual(_id_cliente) {
    this.customerService.getDatosEjecutivo_ID_Actual({ _id_cliente: _id_cliente }).subscribe(
      resp => {
        this.datosEjecutivos = resp;
      });
  }

  validateIma(valor) {
    if (valor != "null")
      return valor;
    else
      return "assets/ima/no-ima.png";
  }

}
export class Ejecutivo {
  id:string;
  nombre:string;
  apellido:string;
  email:string;
  imagen?:string;
}
