import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { startWith, map } from 'rxjs/operators';
import { ChannelService } from 'src/app/services/channel.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AreaService } from './../../services/area.service';
@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css'],
})
export class AreaComponent implements OnInit {
  clienteF = new FormControl();
  optionCliente: any[] = [];

  formPurchaseOrder: FormGroup;
  obs = new BehaviorSubject<any>(null);
  obsArea = new BehaviorSubject<any>(null);
  toppingList: any;
  newObj = false;
  filteredOptionsCliente: Observable<string[]>;
  vCli: any;
  ci: any;
  public searchText: any;
  data: boolean;
  constructor(
    private customerService: CustomerService,

    private channelService: ChannelService,
    private _formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private areaService: AreaService
  ) {}

  ngOnInit(): void {




    this.getRefreshArea();
    this.getAllArea();
    this.createForm();
    this.getAllCanal();
    this.getAllUsuariosTrue();
    this.filOper();
    this.getAllClientes();
  }

  getRefreshArea() {
    this.areaService.oArea$.subscribe(() => {
      this.getAllArea();
    });
  }


  filOper() {
    this.filteredOptionsCliente = this.clienteF.valueChanges.pipe(
      startWith('SIN CLIENTE'),
      map((value) => this._filterCliente(value))
    );
  }

  async add() {
    if (this.formPurchaseOrder.valid) {
      const ci = await this.getCiTask();
      const tipo = this.formPurchaseOrder.controls.tipo.value;
      const nombre = this.formPurchaseOrder.controls.nombre.value;
      const colora = this.formPurchaseOrder.controls.colora.value;
      const colorb = this.formPurchaseOrder.controls.colorb.value;
      const colorc = this.formPurchaseOrder.controls.colorc.value;
      const descripcion = this.formPurchaseOrder.controls.descripcion.value;
      const visible = this.formPurchaseOrder.controls.visible.value;
      const miembros = this.formPurchaseOrder.controls.miembros.value;
      const canal = this.formPurchaseOrder.controls.canal.value;

      let newObj = {
        ci: ci.cod_interno,
        _id_canal: canal,
        _id_cliente: this.vCli,
        tipo: tipo,
        nombre: nombre,
        colora: colora,
        colorb: colorb,
        colorc: colorc,
        descripcion: descripcion,
        visible: visible,
        miembros: miembros,
      };
      this.areaService.addArea(newObj).subscribe((resp) => {

        this.areaService.oArea$.emit();
        this.newObj=false;

      });
    } else {
    }
  }

  getAllCanal() {
    this.channelService.getAllChannel().subscribe((resp) => {
      this.obs.next(resp);
    });
  }

  getAllArea() {
    this.areaService.getAllArea().subscribe((resp) => {
      if (resp.length > 0) {
        this.data = true;
      } else {
        this.data = false;
      }

      this.obsArea.next(resp);
    });
  }

  getAllUsuariosTrue() {
    this.usuarioService.getAllUsuariosTrue().subscribe((resp) => {
      this.toppingList = resp.usuario;
    });
  }

  newArea() {
    this.newObj = this.newObj ? false : true;
  }

  setCliente(valor) {
    this.formPurchaseOrder.controls.cliente.setValue(valor);
    const cliente = this.formPurchaseOrder.get('cliente').value;
    this.vCli = valor;
  }

  getAllClientes() {
    this.customerService.getAllClientes().subscribe((resp) => {
      this.optionCliente = resp.clientes;
    });
  }

  _filterCliente(value: string) {
    return this.optionCliente.filter((optionCliente) =>
      optionCliente.razon_social.toLowerCase().includes(value.toLowerCase())
    );
  }

  createForm() {
    this.formPurchaseOrder = this._formBuilder.group({
      tipo: new FormControl('', [Validators.required]),
      nombre: new FormControl('', [Validators.required]),
      colora: new FormControl(this.generarNuevoColor(), [Validators.required]),
      colorb: new FormControl(this.generarNuevoColor(), [Validators.required]),
      colorc: new FormControl(this.generarNuevoColor(), [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      visible: new FormControl('', [Validators.required]),
      canal: new FormControl('', [Validators.required]),
      miembros: new FormControl(''),
      cliente: new FormControl('', [Validators.required]),
      afor: new FormControl('razon_social', [Validators.required]),
    });
  }

  async getCiTask() {
    let respuesta;
    await this.areaService
      .getCiTask()
      .toPromise()
      .then((response) => {
        respuesta = response;
      })
      .catch((e) => console.error(e));
    return respuesta;
  }

  generarNuevoColor() {
    const minL = 0.75;
    const maxL = 0.9;
    const interval = 3000;
    const colors = {
      bgColor: '',
      txtColor: '',
      btnColor: '',
      btnFocus: '',
    };

    const hBase = Math.random();
    const newH = Math.floor(hBase * 360);
    const newL = Math.floor(Math.random() * 16) + 75;

    colors.bgColor = `hsl(${newH}, 100%, ${newL}%)`;
    colors.txtColor = `hsl(${newH}, 100%, 5%)`;
    colors.btnColor = `hsl(${newH}, 100%, 98%)`;
    colors.btnFocus = `hsl(${newH}, 100%, 95%)`;

    const [r, g, b] = this.HSLtoRGB(hBase, 1, newL * 0.01);

    // update css vars
    Object.entries(colors).forEach(([k, v]) => {
      document.documentElement.style.setProperty(`--${k}`, `${v}`);
    });

    return this.RGBtoHex(r, g, b);
  }

  HSLtoRGB(h, s, l) {
    let r, g, b;

    const rd = (a) => {
      return Math.floor(Math.max(Math.min(a * 256, 255), 0));
    };

    const hueToRGB = (m, n, o) => {
      if (o < 0) o += 1;
      if (o > 1) o -= 1;
      if (o < 1 / 6) return m + (n - m) * 6 * o;
      if (o < 1 / 2) return n;
      if (o < 2 / 3) return m + (n - m) * (2 / 3 - o) * 6;
      return m;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRGB(p, q, h + 1 / 3);
    g = hueToRGB(p, q, h);
    b = hueToRGB(p, q, h - 1 / 3);

    return [rd(r), rd(g), rd(b)];
  }

  RGBtoHex(r, g, b) {
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  }
}
