import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { GlobalVariable } from '../global';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private URL = GlobalVariable.BASE_API_URL;
    constructor(private http: HttpClient,
        private router: Router, ) { }
    public clienteSer$ = new EventEmitter<any>();
    public clienteSerDe$ = new EventEmitter<any>();
    public clienteSerVa$ = new EventEmitter<any>();
    public canalSerPro$ = new EventEmitter<any>();

    public listProSn$ = new EventEmitter<any>();
    public listCliSn$ = new EventEmitter<any>();



    registroCliente(cliente) {
        return this.http.post<any>(this.URL + 'registraCliente', cliente);
    }

    getAllClientes() {

        return this.http.get<any>(this.URL + 'getAllCliente');
    }

    updateCliente(cliente) {
        return this.http.post<any>(this.URL + 'updateCliente', cliente);
    }

    delCliente(id) {
        return this.http.post<any>(this.URL + 'delCliente', id);
    }

    countClientes() {
        return this.http.get<any>(this.URL + 'countClientes');
    }

    getDatosCliente(data: object) {
        return this.http.post<any>(this.URL + 'getDatosCliente', data);
    }

    getDatosEjecutivo(data: object) {
        return this.http.post<any>(this.URL + 'getDatosEjecutivo', data);
    }

    imaIdCliente(data) {
        return this.http.post<any>(this.URL + 'imaIdCliente',data);
    }

    getDatosEjecutivoID(data: object) {
        return this.http.post<any>(this.URL + 'getDatosEjecutivoID', data);
    }

    getDatosEjecutivo_ID_Actual(data: object) {
        return this.http.post<any>(this.URL + 'getDatosEjecutivo_ID_Actual', data);
    }


    getDatosEjecutivo_ID(data: object) {
        return this.http.post<any>(this.URL + 'getDatosEjecutivo_ID', data);
    }

    getDatosClienteSI(data: object) {
        return this.http.post<any>(this.URL + 'getDatosClienteSI', data);
    }


    getIdEpicorCli(data: object) {
        return this.http.post<any>(this.URL + 'getIdEpicorCli', data);
    }


    imaClienteId(data: object) {
        return this.http.post<any>(this.URL + 'imaClienteId', data);
    }

    getCustChannelId(data: object) {
        return this.http.post<any>(this.URL + 'getCustChannelId', data);
    }

    getCustChannelAll(data: object) {
        return this.http.post<any>(this.URL + 'getCustChannelAll', data);
    }
    

    getCustChannelAllp(data: object) {
        return this.http.post<any>(this.URL + 'getCustChannelAllp', data);
    }

    listCustomerGeneralProduct(data: object) {
        return this.http.post<any>(this.URL + 'listCustomerGeneralProduct', data);
    }
   
    

}
