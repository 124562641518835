<div *ngFor="let env of obsArea" class="conte-pro">
  <div class="">
    <div class="ct-m">
      <div class="ct-cli">

        <app-ima-card-area [idcliente]="env._id_cliente"></app-ima-card-area>

      </div>
      <div class="ct-pro">
        <div class="ct-d-ima-due-user">
          <app-ima-user-id [vUser]="env._id_usuario"></app-ima-user-id>
        </div>
      </div>
      <div class="ct-a">
        <div class="ct-meb">
          <div class="ct-meta">
            <span class="ct-d-st-text">{{ getDate(env.createdAt)}}</span>
            <span class="ct-d-st-est">ABIERTO</span>
            <span class="ct-d-st-ci">{{ env.ci}}</span>
          </div>

          <span class="ct-ts"> {{env.nombre}} </span>
          <div class="ct-col" [ngStyle]="getcolor(env.colora,env.colorb,env.colorc)"></div>
          <span class="ct-st">{{env.descripcion }}</span>
        </div>
      </div>
      <div class="ct-a-info">
        <div class="ct-info">
          <app-card-info [vTas]="env._id" [vMie]="env.miembros.length"></app-card-info>
        </div>
      </div>
      <div class="ct-mi">
        <div class="ct-mi-fl">
          <div *ngFor="let mi of env.miembros" class="ct-d-ima-due-user">
            <app-ima-user-id [vUser]="mi"></app-ima-user-id>
          </div>
        </div>
      </div>
      <div class="ct-fechas">
        <div class="ct-ff" *ngIf="resol">
          <div>
            <span class="ct-fg">INICIO:</span> <span class="ct-fgst">{{resol.dateStar}}</span>
          </div>
          <div>
            <span class="ct-fg">RESOLUCIÓN:</span> <span class="ct-fgs">{{resol.dateEnd}}</span>
          </div>
        </div>


      </div>
    </div>


  </div>
  <!-- <mat-divider></mat-divider> -->


  <mat-tab-group class="tabs-master-pro" mat-align-tabs="end">
    <mat-tab>

      <ng-template mat-tab-label>

        <span class="tb-ti">Tareas</span>
        <mat-icon class="example-tab-icon">splitscreen</mat-icon>
      </ng-template>


      <div class="ct-menu">
        <button mat-button color="primary" (click)="getNeTask()">+ Tarea Simple</button>
        <button mat-button color="primary" (click)="getNeTaskChema()">+ Tarea Esquema</button>
        <button mat-button color="primary" (click)="getUserMie()">+ Integrante</button>

      </div>

      <section *ngIf="ngTaks">
        <app-task-add [vAr]="env._id"></app-task-add>
      </section>


      <section *ngIf="ngTaksChema">
        <app-task-add-sh [vAr]="env._id"></app-task-add-sh>
      </section>

      <section *ngIf="ngMiembro">
        <app-task-mb-user [vAr]="env._id"></app-task-mb-user>
      </section>


      <app-task-table [vAr]="vAr"></app-task-table>

      <section *ngIf="resol" class="ct-gantt">
        <div class="titulo-row-b">
          <div>
            Planificación Gantt
          </div>
        </div>
        <mat-divider></mat-divider>



        <app-task-gantt [vAr]="env._id"></app-task-gantt>


      </section>
    </mat-tab>
    <mat-tab>

      <ng-template mat-tab-label>

        <span class="tb-ti">Chat</span>

        <mat-icon class="example-tab-icon">question_answer</mat-icon>
      </ng-template>

      <app-sms-activador [vRo]="vAr"></app-sms-activador>

    </mat-tab>
  </mat-tab-group>



</div>

<div #target class="footer"></div>