<!-- <table class="table_data" multiTemplateDataRows mat-table [dataSource]="dataSource"> -->
<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table_data">

    <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef> INFO </th>
        <td mat-cell *matCellDef="let element">

            <mat-icon class="ct-ico" matTooltip="{{formatDate(element.fecha)}} ">
                calendar_month
            </mat-icon>

            <mat-icon class="ct-ico" matTooltip=" {{element.factura}} ">
                description
            </mat-icon>
            <mat-icon class="ct-ico" matTooltip="  {{element.cantidad | number}} {{element.uni.across}}  ">
                1k
            </mat-icon>

        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>



    <ng-container matColumnDef="pv">
        <th mat-header-cell *matHeaderCellDef  matTooltipClass="infomat" matTooltip="Precio de Venta"> P. V. </th>
        <td mat-cell *matCellDef="let element"> {{element.precio_venta | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalpv()| number: '1.2-2'}} </td>

    </ng-container>

    <ng-container matColumnDef="imv">
        <th mat-header-cell *matHeaderCellDef matTooltip="Importe de Venta"> I. V. </th>
        <td mat-cell *matCellDef="let element"> {{element.importe_venta | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalimv() | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="mp">
        <th mat-header-cell *matHeaderCellDef matTooltip="Materia Prima"> M. P. </th>
        <td mat-cell *matCellDef="let element"> {{element.materia_prima | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalmp() | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="mo">
        <th mat-header-cell *matHeaderCellDef matTooltip="Mano do Obra"> M. 0. </th>
        <td mat-cell *matCellDef="let element"> {{element.mano_obra | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalmp() | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="gif">
        <th mat-header-cell *matHeaderCellDef> GIF </th>
        <td mat-cell *matCellDef="let element"> {{element.gif | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalgif() | number: '1.2-2'}} </td>
    </ng-container>



    <ng-container matColumnDef="ic">
        <th mat-header-cell *matHeaderCellDef matTooltip="Importe de Costo (MP+MO+GIF+CA)"> I. C. </th>
        <td mat-cell *matCellDef="let element" matTooltip="Importe de Costo (
            {{element.materia_prima |  number: '1.2-2'}} 
            +
            {{element.mano_obra |  number: '1.2-2'}} 
            +
            {{element.gif |  number: '1.2-2'}}
            +
            {{element.costo_adicional |  number: '1.2-2'}} )"> {{element.importe_costo | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalic() | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="ub">
        <th mat-header-cell *matHeaderCellDef matTooltip="Utilidad Bruta (IV-IC)"> U. B. </th>
        <td mat-cell *matCellDef="let element" matTooltip="Utilidad Bruta (
            {{element.importe_venta |  number: '1.2-2'}}
            -
            {{element.importe_costo |  number: '1.2-2'}}
            )"> {{element.utilidad_bruta | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalub() | number: '1.2-2'}} </td>
    </ng-container>



    <ng-container matColumnDef="UTC">
        <th mat-header-cell *matHeaderCellDef matTooltip="Utilidad/Costo (IC/UB)"> U/C </th>
        <td mat-cell *matCellDef="let element" matTooltip="Utilidad/Costo (
            {{element.importe_costo |  number: '1.2-2'}}
            /
            {{element.utilidad_bruta |  number: '1.2-2'}}
            * 100
            )"> <span class="ct-pod"> {{getUTC(element.importe_costo,element.utilidad_bruta) | number: '1.2-2'}}%
            </span> </td>
        <td mat-footer-cell *matFooterCellDef>

            {{getUTC(getTotalic(),getTotalub()) | number: '1.2-2'}}%

        </td>
    </ng-container>

    <ng-container matColumnDef="UTV">
        <th mat-header-cell *matHeaderCellDef matTooltip="Utilidad/Venta (IC/UV)"> U/V </th>
        <td mat-cell *matCellDef="let element" matTooltip="Utilidad/Venta  (
            {{element.importe_venta |  number: '1.2-2'}}
            /
            {{element.utilidad_bruta |  number: '1.2-2'}}
            * 100
            )"> <span class="ct-pod "> {{getUTC(element.importe_venta,element.utilidad_bruta) | number: '1.2-2'}}%
            </span>
        
        <div class="una">
            jose
        </div>
        
        </td>
        <td mat-footer-cell *matFooterCellDef>

            {{getUTC(getTotalic(),getTotalimv()) | number: '1.2-2'}}%

        </td>
    </ng-container>

    <ng-container matColumnDef="ca">
        <th mat-header-cell *matHeaderCellDef matTooltip="Costo  Adicional. "> C. A. </th>
        <td mat-cell *matCellDef="let element"> {{element.costo_adicional | number: '1.2-2'}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalcf() | number: '1.2-2'}} </td>
    </ng-container>



    <ng-container matColumnDef="ct">
        <th mat-header-cell *matHeaderCellDef matTooltip="Costo Total Flete | C.F.  KG. /LB. * VOL."> C. T. F. </th>
        <td mat-cell *matCellDef="let element"
            matTooltip=" C.F  KG. /LB. : {{element.costo_flete | number: '1.2-2'}} * VOL: {{element.cantidad | number: '1.2-2'}}}">
            {{element.costo_total | currency}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalct() | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="un">
        <th mat-header-cell *matHeaderCellDef matTooltip="Utilidad Neta | (UB-CFT)"> U. N. </th>
        <td mat-cell matTooltip="{{element.observaciones}}" *matCellDef="let element" matTooltip="Utilidad Neta (
            {{element.utilidad_bruta  |  number: '1.2-2'}} 
            -
            {{element.costo_total |  number: '1.2-2'}}
            )"> {{element.utilidad_neta |
            currency}} </td>
        <td mat-footer-cell *matFooterCellDef> {{getTotalun() | number: '1.2-2'}} </td>
    </ng-container>


    <ng-container matColumnDef="UTN">
        <th mat-header-cell *matHeaderCellDef matTooltip="Utilidad Neta/Importe de Venta (IN/IV)"> UN/IV </th>
        <td mat-cell *matCellDef="let element" matTooltip="Utilidad Neta/Importe de Venta  (
            {{element.importe_venta |  number: '1.2-2'}}
            /
            {{element.utilidad_neta |  number: '1.2-2'}}
            * 100
            )"> <span class="div-un ct-pod"> {{getUTC(element.importe_venta,element.utilidad_neta) | number: '1.2-2'}}%
            </span></td>
        <td mat-footer-cell *matFooterCellDef> {{getUTC(getTotalimv(),getTotalun()) | number: '1.2-2'}}% </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="obs">

                    <span class="obs-s">{{getObersv(element.observaciones.toUpperCase() )}}</span>

                </div>
            </div>
        </td>
    </ng-container>


    <!-- matTooltip="Info about the action" -->

    <!-- <ng-container matColumnDef="oc">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">     <app-pcrsma [value]="element.oc"></app-pcrsma> </td>
        <td mat-footer-cell *matFooterCellDef>  </td>
    </ng-container> -->


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>




</table>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>


<div class="div-un">jose ignacio </div>
<div class="una">
    epale
</div>
