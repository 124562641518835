import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { channelGroupProductServices } from './../../../../../../services/sales/channel-group-product.services';

@Component({
  selector: 'app-scg-table-gra-mxn',
  templateUrl: './scg-table-gra-mxn.component.html',
  styleUrls: ['./scg-table-gra-mxn.component.css'],
})
export class ScgTableGraMxnComponent implements OnInit {
  @Input() vYear: any;
  @Input() vCli: string;
  @Input() vCh: string;
  @Input() vPro: string;

  wiat = [];
  multi: any[];
  view: any[] = [700, 300];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'MXN';
  timeline: boolean = false;
  legendPosition = 'below';
  customColors: any[] = [
    { name: 'PRE 2022', value: '#0f69f1' },
    { name: 'OC 2022', value: '#efb810' },
    { name: 'VEN 2022', value: '#26a116' },

    { name: 'PRE 2021', value: '#0f69f1' },
    { name: 'OC 2021', value: '#efb810' },
    { name: 'VEN 2021', value: '#26a116' },

    { name: 'PRE 2020', value: '#0f69f1' },
    { name: 'OC 2020', value: '#efb810' },
    { name: 'VEN 2020', value: '#26a116' },

    { name: 'PRE 2019', value: '#0f69f1' },
    { name: 'OC 2019', value: '#efb810' },
    { name: 'VEN 2019', value: '#26a116' },

    { name: 'PRE 2018', value: '#0f69f1' },
    { name: 'OC 2018', value: '#efb810' },
    { name: 'VEN 2018', value: '#26a116' },

    { name: 'PRE 2017', value: '#0f69f1' },
    { name: 'OC 2017', value: '#efb810' },
    { name: 'VEN 2017', value: '#26a116' },
  ];
  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  gd2m: boolean;
  gd2: boolean;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['num', 'presupuesto','oc', 'venta', 'indi'];
  volSumaPre: any;
  volSumaVen: any;
  show: boolean;
  showa: boolean;
  ss1: boolean;
  volSumaOc: any;
  constructor(
    private _channelGroupProductServices: channelGroupProductServices
  ) {}
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  ngOnInit(): void {
    this.show = true;
    this.showa = false;

    // this.scgTableProXCliGraMXN(this.vYear, this.vCli)
  }

  ngOnChanges(changes: any) {
    this.show = true;
    this.showa = false;
    const nvYear = changes.vYear.currentValue;
    this.scgTableProXCliGraMXN(String(nvYear), this.vCli, this.vPro, this.vCh);
  }

  scgTableProXCliGraMXN(vYear, vCli, vPro, vCh) {
    this._channelGroupProductServices
      .scgTableProXCliGraMXN({ vYear: vYear, vCli: vCli, vPro: vPro, vCh: vCh })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }
        this.multi = resp;

        let da = resp.map((val) => val.series);
        let dataGra = [];
        for (let i = 0; i < 1; i++) {
          for (let j = 0; j < da[i].length; j++) {
            const elementa = da[i][j];
            const elementc = da[1][j];
            const elementb = da[2][j];

            dataGra.push({
              num: j + 1,
              mes: elementa.name,
              presupuesto: elementa.value,
              oc: elementc.value,
              venta: elementb.value,
            });
          }
        }

        this.volSumaPre = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.presupuesto;
        }, 0);

        this.volSumaOc = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.oc;
        }, 0);

        this.volSumaVen = dataGra.reduce(function (total, currentValue) {
          return total + currentValue.venta;
        }, 0);

        this.dataSource.data = dataGra;

        this.show = false;
        this.showa = true;
      });
  }
}
