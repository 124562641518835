import { Component, Input, OnInit } from '@angular/core';
import { SalesService } from "../../../../services/sales/sales.service";
import { BudgetService } from "../../../../services/budget.service";
@Component({
  selector: 'app-gra-cli-mes',
  templateUrl: './gra-cli-mes.component.html',
  styleUrls: ['./gra-cli-mes.component.css']
})
export class GraCliMesComponent implements OnInit {
  @Input() vYear: string;
  @Input() vMes: string;
  grafCli: boolean = false;
  single: any[];
  multi: any[];
  view: any[] = [700, 400];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Normalized Population';
  wiat: any[] = [];
  animations: boolean = true;

  colorScheme = {
    domain: ['#0081a7', '#26a116']
  };
  constructor(
    private salesService: SalesService,
    private budgetService: BudgetService
  ) { }

  ngOnInit(): void {
    this.generateRandomInt();
    this.getAllGroupClienteMes(this.vYear, this.vMes)
  }

  getAllGroupClienteMes(vYear, vMes) {
    this.budgetService.getAllGroupClienteMes({ vYear: vYear, vMes: vMes }).subscribe(
      resp => {
        if (resp.length > 0) {
          this.multi = resp;
          this.grafCli = true;
        }
      });
  }

  generateRandomInt() {
    for (let i = 0; i < 20; i++) {
      this.wiat.push({
        'border-radius': '5px',
        'width': '30px',
        'padding': '5px',
        'margin': '7px',
        'height': Math.floor((Math.random() * (310 - 20)) + 20) + 'px',
        'background-color': '#daddd8'
      });
    }
    return this.wiat;
  }
}
