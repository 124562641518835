import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-card-area',
  templateUrl: './card-area.component.html',
  styleUrls: ['./card-area.component.css'],
})
export class CardAreaComponent implements OnInit {
  @Input() Env: any;
  loading = true;
  stb: { background: string };
  constructor() {}

  ngOnInit(): void {}

  getcolor() {
    let stb = {
      background:
        'radial-gradient(circle at top left, ' +
        this.Env.colora +
        ', ' +
        this.Env.colorb +
        ', ' +
        this.Env.colorc +
        ')',
    };

    return stb;
  }

  validateIma(valor) {
    if (valor === undefined || valor === null || valor === '') {
      return 'assets/ima/no-ima.png';
    } else {
      return valor;
    }
  }

  getDate(date) {
    return moment(String(date)).format('YYYY-MM-DD');
  }


  goToLink(_id) {
    window.open('/area_detail/' + _id, '_blank');
  }

}
