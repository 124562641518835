import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { channelGroupProductServices } from '../../../../../services/sales/channel-group-product.services';

@Component({
  selector: 'app-tbl-pro-cli',
  templateUrl: './tbl-pro-cli.component.html',
  styleUrls: ['./tbl-pro-cli.component.css'],
})
export class TblProCliComponent implements OnInit {
  @Input() vCh: string;
  @Input() vYear: string;
  @Input() vCli: string;


  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  copyToClipboard: string;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }
  ss1: boolean;
  show: boolean;
  venta_tol: any;
  pres_tol: any;
  venta_vol: any;
  pres_vol: any;
  pres_vols: any;
  oc_tol: any;
  oc_vol: any;
  dataSource = new MatTableDataSource<any>();

  displayedColumns: string[] = [
    // 'id_epicor',
    'nombre',
    'pres_vol',
    'oc_vol',
    'venta_vol',
    'pres_tol',
    'oc_tol',
    'venta_tol',
    'indi',
  ];
  constructor(
    private _channelGroupProductServices: channelGroupProductServices
  ) {}

  ngOnInit(): void {



    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.show = false;
  }

  ngOnChanges(changes: any) {
    this.show = false;
    const nvYear = changes.vYear.currentValue;
    this.sgcTablePro(this.vCh, String(nvYear), this.vCli);
  }

  sgcTablePro(vCh, vYear, vCli) {
    this._channelGroupProductServices
      .sgcTableProSecAcuCli({ vCh: vCh, vCli: vCli, vYear: vYear })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.ss1 = true;
        } else {
          this.ss1 = false;
        }
        this.show = true;

        this.dataSource.data = resp;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.venta_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_tol;
        }, 0);

        this.pres_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_tol;
        }, 0);

        this.oc_tol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_tol;
        }, 0);

        this.venta_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.venta_vol;
        }, 0);

        this.pres_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.pres_vol;
        }, 0);

        this.oc_vol = resp.reduce(function (total, currentValue) {
          return total + currentValue.oc_vol;
        }, 0);
      });
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
