import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "../../services/sales/sales.service";

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);

  constructor(
    private salesService: SalesService
  ) { }

  ngOnInit(): void {

    this.orqVenYear();
  }


  orqVenYear() {
    this.salesService.orqVenYear().subscribe((resp) => {
      let b = resp.filter((element) => element.cdo === 'VEN');
      this.obs.next(resp);

     


    });
  }

}
