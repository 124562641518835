<mat-dialog-content>
	<p class="text-t">
		Se eliminará esta tarea <br>
		{{message}}
	</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button mat-raised-button color="warn" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>
