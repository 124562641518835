import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ProductService } from "../../services/product.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import { AddProductoComponent } from "./add-producto/add-producto.component";
import { DelProductoComponent } from "./del-producto/del-producto.component";
import { UpProductoComponent } from "./up-producto/up-producto.component";
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
selector: 'app-producto',
templateUrl: './producto.component.html',
styleUrls: ['./producto.component.css'],
animations: [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
],
})
export class ProductoComponent implements OnInit {
  expandedElement: PeriodicElement | null;
displayedColumns: string[] = ['numparte','nombre','descripcion','Opciones'];
dataSource = new MatTableDataSource<any>();
aFormGroup: FormGroup;
fFormGroup: FormGroup;
photoSelected: String | ArrayBuffer;

constructor(
private productoService: ProductService,
private changeDetectorRef: ChangeDetectorRef,
private _snackBar: MatSnackBar,
private dialog: MatDialog,
private _formBuilder: FormBuilder,

) { }


@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;


ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
}

ngOnInit(){
this.getAllProductos();
this.productoService.productoSer$.subscribe(() => {
this.getAllProductos();
});

this.aFormGroup = this._formBuilder.group({
tipo_proyecto: ['', Validators.required]
});

this.fFormGroup = this._formBuilder.group({
rb: [Validators.required],
});

}

onDialogAdd() {
const dialogConfig = new MatDialogConfig();
dialogConfig.disableClose = false;
dialogConfig.autoFocus = true;
dialogConfig.width = "80%";
this.dialog.open(AddProductoComponent,dialogConfig);
}

onDialogUp(_id,numeroparte,nombre,descripcion,presentacion,tipo_proyecto,imagen,unidad,canal,ubox,cbox,cbag,moneda) {
const dialogConfig = new MatDialogConfig();
dialogConfig.disableClose = false;
dialogConfig.autoFocus = false;
dialogConfig.width = "80%";
dialogConfig.data = {data: {_id:_id, numeroparte:numeroparte,nombre:nombre,descripcion:descripcion,presentacion:presentacion,tipo_proyecto:tipo_proyecto,imagen:imagen,unidad:unidad,
  canal:canal,
  ubox:ubox,
  cbox:cbox,
  cbag:cbag,
  moneda:moneda
  }};
this.dialog.open(UpProductoComponent,dialogConfig);
}

onDialogDel(id) {
const dialogConfig = new MatDialogConfig();
dialogConfig.disableClose = false;
dialogConfig.autoFocus = false;
dialogConfig.width = "40%";
dialogConfig.data = {data: {id: id}};
this.dialog.open(DelProductoComponent,dialogConfig);
}



getAllProductos(){
this.productoService.getAllProductos().subscribe(
resp => {
this.dataSource.data = resp.productos;
this.dataSource.paginator = this.paginator;
this.dataSource.sort = this.sort;

});
}

openSnackBar(message: string, tipo: string) {
this._snackBar.open(message, '', {
duration: 5000,
horizontalPosition: 'center',
verticalPosition: 'bottom',
panelClass: [tipo]
});
}

applyFilter(event: Event) {
const filterValue = (event.target as HTMLInputElement).value;
this.dataSource.filter = filterValue.trim().toLowerCase();
}

}


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}