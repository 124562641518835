import { Component, Input, OnInit } from '@angular/core';
import { VenMesAdminService } from '../../../../../services/sales/ven-mes-admin.service';
import * as moment from 'moment';

@Component({
  selector: 'app-scg-gra-glo-yea-bar-cli',
  templateUrl: './scg-gra-glo-yea-bar-cli.component.html',
  styleUrls: ['./scg-gra-glo-yea-bar-cli.component.css'],
})
export class ScgGraGloYeaBarCliComponent implements OnInit {
  @Input() vCh: string;
  @Input() vCli: string;
  @Input() vCliEpi: string;
  @Input() vYear: string;
  listGraAl: any;
  // Grafica A
  showXAxis = true;
  tCop = 'mM';
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = false;
  showYAxisLabel = true;
  legendTitle = '';
  yAxisLabel = 'MXN';
  colorScheme = {
    domain: ['#ffb703','#33a8c7', '#0081a7','#ffb703', '#7fd14d', '#26a116'],
  };
  show: boolean;
  gd2m: boolean;
  wiat = [];
  constructor(private venMesAdminService: VenMesAdminService) {}

  ngOnInit(): void {
    this.show = true;
     this.generateRandomInt();
    // this.show = true;
    // let dataRest = this.getWeek(this.vYear);
    // this.scgGraficaGlobalYearBarCliente(
    //   this.vCli,
    //   dataRest.yeara,
    //   dataRest.yearb,
    //   dataRest.mouth,
    //   dataRest.dA.convera,
    //   dataRest.dA.converb,
    //   dataRest.dB.convera,
    //   dataRest.dB.converb,
    //   this.vCh
    // );
  }

  ngOnChanges(changes: any) {
    this.show = true;
    const nvYear = changes.vYear.currentValue;
    let dataRest = this.getWeek(nvYear);

    this.scgGraficaGlobalYearBarCliente(
      this.vCliEpi,
      this.vCli,
      dataRest.yeara,
      dataRest.yearb,
      dataRest.mouth,
      dataRest.dA.convera,
      dataRest.dA.converb,
      dataRest.dB.convera,
      dataRest.dB.converb,
      this.vCh
    );
  }

  getWeek(vYear) {
    var mouth = moment().format('MM');
    var aconvera = moment().format(vYear + '-01-01');
    var aconverb = moment().format(vYear + '-12-31');
    const yeara = moment(aconvera).format('YYYY');
    const yearb = moment(aconvera).subtract(1, 'year').format('YYYY');
    var bconvera = moment().format(yearb + '-01-01');
    var bconverb = moment().format(yearb + '-12-31');

    let dataFecha = {
      mouth: mouth,
      yeara: yeara,
      yearb: yearb,
      dA: {
        convera: aconvera,
        converb: aconverb,
      },
      dB: {
        convera: bconvera,
        converb: bconverb,
      },
    };

    return dataFecha;
  }

  scgGraficaGlobalYearBarCliente(
    vCliEpi,
    vCli,
    ya,
    yb,
    mes,
    da,
    ha,
    db,
    hb,
    vCh
  ) {
    this.venMesAdminService
      .scgGraficaGlobalYearBarCliente({
        vCliEpi: vCliEpi,
        vCli: vCli,
        yeara: ya,
        yearb: yb,
        mes: mes,
        da: da,
        ha: ha,
        db: db,
        hb: hb,
        vCh: vCh,
      })
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.gd2m = true;
        } else {
          this.gd2m = false;
        }
        this.show = false;
        this.listGraAl = resp;
      });
  }

  generateRandomInt() {
    for (let i = 0; i < 4; i++) {
      this.wiat.push({
        'border-radius': '5px',
        width: '80px',
        padding: '5px',
        margin: '7px',
        height: Math.floor(Math.random() * (214 - 20) + 20) + 'px',
        'background-color': '#daddd8',
      });
    }
    return this.wiat;
  }
}
