import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LoaderService } from '../../services/loader.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  show: boolean = false;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
  ) {

    this.show = false;

   }

  ngOnInit() {
    this.loaderService.status$.subscribe(resp => {
      this.show = resp;
    });
  }

}
