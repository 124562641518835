<div class="ct-d-dac" *ngIf="show; else noda">
    <div class="ct-d-dac"   *ngFor="let env of obsTask | async">
        <div class="ct-d-status" [ngStyle]="{'background-color':env.color}">
            {{env.nam}}
        </div>
    </div>
</div>
<ng-template #noda>


    <ngx-skeleton-loader count="1" [theme]="{ 
        'border-radius': '5px', 
        'width': '100%',
        'height': '100%',
        'background-color': '#daddd8'
    }">
    </ngx-skeleton-loader>

</ng-template>