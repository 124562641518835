import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-sms-send',
  templateUrl: './sms-send.component.html',
  styleUrls: ['./sms-send.component.css'],
})
export class SmsSendComponent implements OnInit {
 @Input() vRo:string;
 @Input() vUs:string;
 
  formSmsSend: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.formSmsSend = new FormGroup({
      mensaje: new FormControl('', [Validators.required]),
    });
  }

  add() {
    const mensaje = this.formSmsSend.controls.mensaje.value;
    let obj = {
      _id_vro:this.vRo,
      _id_usr:this.vUs,
      mensaje:mensaje
    };


    
  }
}
