<div class="titulo-row-b">
  <button mat-icon-button>
    <mat-icon>insert_chart_outlined</mat-icon>
  </button>
  Ventas Semana:
  <mat-form-field class="sele" (change)="updateAn($event.target.value)">
    <select matNativeControl>
      <option *ngFor="let lv of listRango" [value]="lv.semana">
        {{ lv.semana }}
      </option>
    </select>
  </mat-form-field>
  <span class="listfs"> desde <span class="datef">{{ convera }}</span> hasta <span class="datef">{{ converb
      }}</span></span>
  <mat-divider></mat-divider>
</div>

<div *ngIf="respmats; else nitem" class="ct-master">
  <div *ngIf="mats; else nomats" class="ct-master-x">
    <div class="ct-master-a">
      <div class="ct-master-b">
        <div class="ct-master-c">
          <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="listGraAl" [gradient]="gradient"
            [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
            (select)="aSelectVencli($event)" [legendTitle]="legendTitle">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
  </div>

  <ng-template #nomats>
    <div class="cards-cli">

      <ngx-skeleton-loader count="1" [theme]="{ 
    'border-radius': '5px', 
    width: '120px',
    'padding':'7px',
    'margin':'7px',
    height: '320px',
    'background-color': '#daddd8'
}">
      </ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ 
        'border-radius': '5px', 
        width: '120px',
        'padding':'7px',
        'margin':'7px',
        height: '300px',
        'background-color': '#daddd8'
    }">
      </ngx-skeleton-loader>

      <ngx-skeleton-loader count="1" [theme]="{ 
      'border-radius': '5px', 
      width: '120px',
      'padding':'7px',
      'margin':'7px',
      height: '200px',
      'background-color': '#daddd8'
  }">
      </ngx-skeleton-loader>

      <ngx-skeleton-loader count="1" [theme]="{ 
    'border-radius': '5px', 
    width: '120px',
    'padding':'7px',
    'margin':'7px',
    height: '100px',
    'background-color': '#daddd8'
}">
      </ngx-skeleton-loader>
    </div>

  </ng-template>

  <div class="ct-master-d">
    <div *ngFor="let env of obs | async">
      <!-- <app-cvenpre [vYear]="env.extra.yeara" [env]="env" [vSema]="env.extra.sema" [vPre]="env.pre"></app-cvenpre> -->
      <app-cvenpre [vYear]="env.extra.yeara" [env]="env" [vD]="env.extra.vD" [vH]="env.extra.vH" [vPre]="env.pre">
      </app-cvenpre>

    </div>
  </div>
</div>

<div class="dataCli" *ngIf="dataCli">
  <div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/dashboard']">
      <mat-icon>attach_money</mat-icon>
    </button>
    Actividad Clientes Semana<span class="listf"> desde <span class="datef">{{ convera }}</span> hasta <span class="datef">{{ converb
    }}</span></span>
  </div>
  <mat-divider></mat-divider>




  <div class="ct-titulo-glo">
    <div>
      <mat-form-field class="mat-form-field-filtro" appearance="fill">
        <mat-label>Filtro</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Ejemplo: Profusa Product" autocomplete="off" />
      </mat-form-field>    
    </div>
    <div>

      <form [formGroup]="criterioChVeSem" method="post">
        <mat-form-field appearance="fill" class="mat-form-field-filtro">
          <mat-label>Canal</mat-label>
          <mat-select (selectionChange)="updateChVenSem()" formControlName="canalvensem" >

            <mat-option [value]=""> TODOS </mat-option>
            <mat-option *ngFor="let ch of obsCh | async" [value]="ch._id"> {{ch.canal}} </mat-option>

          </mat-select>
        </mat-form-field>
      </form>

    
    </div>

  </div>



</div>




<div *ngIf="filte; else noa">
<div class="ct-mat-data" fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
  <div class="mat-card-pre" *ngFor="let env of obsVenSem | async | filter: searchText ; index as i " fxFlex.sm="0 1
  calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(33%-10px)">
    <app-car-ven-sem [vNum]="i"  [env]="env" [vRowGet]="vRowGet"></app-car-ven-sem>
  </div>
</div>
</div>
<ng-template #noa>
  <div class="nodasem">
    <app-info-data [mensaje]="'No hay ventas en este canal'" [pd]=""> </app-info-data>
  </div>
</ng-template>





<ng-template #nitem>
  <div class="nodata">
    <app-no-data [mensaje]="'No hay ventas registradas'" [pd]=""> </app-no-data>
  </div>
</ng-template>




<ng-template #nitem>
  <div class="nodata">
    <app-no-data [mensaje]="'No hay ventas registradas'" [pd]=""> </app-no-data>
  </div>
</ng-template>

<div class="dataCli" *ngIf="dataCliPre">







  <div class="ct-titulo-glo">

    <div class="titulo-row-b">
      <button mat-icon-button [routerLink]="['/dashboard']">
        <mat-icon>attach_money</mat-icon>
      </button>
      Actividad Global Pre Semana<span class="listfs"> desde 
        <span class="datef">{{ convera }}</span> 
        hasta 
        <span  class="datef">{{ converb }}</span>
        </span>
    </div>
  

    <div>
      <form [formGroup]="criterioCh" method="post">
        <mat-form-field appearance="fill" class="mat-form-f">
          <mat-label>Canal</mat-label>
          <mat-select (selectionChange)="updateCh()" formControlName="canal" required>

            <mat-option  [value]=""> TODOS </mat-option>
            <mat-option *ngFor="let ch of obsCh | async" [value]="ch._id"> {{ch.canal}} </mat-option>

          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>


  <mat-divider></mat-divider>


  <div *ngFor="let env of obsGlo | async">
    <app-pre-mensual [tCop]="tCop" [vCh]="env.vCh" [vEnv]="env"></app-pre-mensual>
  </div>


</div>
<div class="ct-footer">

</div>