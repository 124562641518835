<section class="client-ima-container">

    
<div *ngIf="!imaf; else node1">
    <ngx-skeleton-loader [theme]="{
        'width': '70px',
        'height':'30px',
        'padding': '0px',
        'background-color': '#daddd8',
        'display': 'table-cell'
      }">
    </ngx-skeleton-loader>
</div>
<ng-template #node1>
    <div class="element">
        <img class="logo" *ngIf="imaf" [src]="validateIma(obs | async)">
    </div>

</ng-template>

 
</section>


