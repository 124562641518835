import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AreaService } from 'src/app/services/area.service';

@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.css'],
})
export class TaskStatusComponent implements OnInit {
  @Input() vAr: string;
  @Input() vTa: string;
  show: boolean;
  constructor(private areaService: AreaService) {}
  obsTask = new BehaviorSubject<any>(null);

  ngOnInit() {
    this.show=false;
 
    this.getVerStaTaskId(this.vAr, this.vTa);
  }

  getVerStaTaskId(vAr, vTa) {
    this.areaService
      .getVerStaTaskId({ vAr: vAr, vTa: vTa })
      .subscribe((resp) => {

        this.obsTask.next(resp);
        this.show=true;
      });
  }
}
