import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VenSemAdminService } from "../../../../services/sales/ven-sem-admin.service";

@Component({
  selector: 'app-cvenpre',
  templateUrl: './cvenpre.component.html',
  styleUrls: ['./cvenpre.component.css']
})
export class CvenpreComponent implements OnInit {
  @Input() vYear: number;
  @Input() vD: string;
  @Input() vH: string;
  @Input() vPre: number;
  @Input() env: Object;
  obs = new BehaviorSubject<any>(null);
  total = new BehaviorSubject<any>(null);
  tVen: any;
  dataGra: any[] = [];
  mats = false;
  // options
  showXAxis: boolean = false;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  roundDomains: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  customColors: any[] = [
    { name: "INDUSTRIAL", value: '#99c1de' },
    { name: "RETAIL", value: '#eddcd2' },
    { name: "FRANQUICIA", value: '#c7f9cc' },
    { name: "SUMINISTROS", value: '#c25cd8' },
    { name: "MAQUILAS", value: '#f3e440' },
  ];

  colorScheme = {
    domain: ['#3498DB', '#26a116']
  };

  constructor(
    private venSemAdminService: VenSemAdminService
  ) { }

  ngOnInit(): void {
    this.getVenYearCanal(this.vYear, this.vD, this.vH);
  }

  getVenYearCanal(ya, vD, vH) {
    let series = [];
    this.venSemAdminService.getVenYearCanal({ yeara: ya, d: vD, h: vH }).subscribe((resp) => {
      this.obs.next(resp);
      const sumall = resp.map(item => item.value).reduce((prev, curr) => prev + curr, 0);
      this.total.next(sumall);
      this.tVen = sumall;
      let dar = resp.map((val) => {
        series.push(val
        );
        return val;
      });
      this.dataGra.push(
        {
          name: "VEN ",
          series: dar
        },
      );
      this.mats = true;
      this.dataGra = [...this.dataGra];
    });
  }


}


