import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BudgetService } from "./../../../services/budget.service";
@Component({
  selector: 'app-pre-mensual',
  templateUrl: './pre-mensual.component.html',
  styleUrls: ['./pre-mensual.component.css']
})
export class PreMensualComponent implements OnInit {
  @Input() vEnv: any;
  @Input() tCop: any;
  @Input() vCh: any;
  
  public searchText: any;
  loading = false;
  showXAxis: boolean = false;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  roundDomains: boolean = true;
  xAxisLabel: string = 'Venta Mensual';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  customColors: any[] = [
  ];
  colorScheme = {
    domain: ['#26a116', '#3498DB']
  };
  matrizGra: any[] = [];
  bmulti: any[] = [];
  blegend: boolean = false;
  bshowLabels: boolean = true;
  banimations: boolean = true;
  bxAxis: boolean = true;
  byAxis: boolean = true;
  bshowYAxisLabel: boolean = false;
  bshowXAxisLabel: boolean = false;
  bxAxisLabel: string = 'Histórico mes';
  byAxisLabel: string = 'Population';
  btimeline: boolean = false;
  bcolorScheme = {
    domain: ['#26a116']
  }
  showdetail: boolean=false;
  texts: string="Mas detalle";

  constructor(
    private budgetService: BudgetService
  ) { }

  ngOnInit(): void {



    this.vEnv.grave.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    this.bmulti.push({
      name: "Venta",
      series: this.vEnv.grave
    });
    this.bmulti = [...this.bmulti];


  }


  showdetailF() {
    if (this.showdetail) {
      this.showdetail = false;
      this.texts="Mas detalle";
    } else {
      this.showdetail = true;
      this.texts="Menos detalle";

    }


  }


  getCal(p, v) {
    if (p === undefined || v === undefined) {
      return 0;
    } else {
      return v * 100 / p;
    }
  }



  ngAfterViewInit() {
    setTimeout(() => {
      this.loading = true;
    }, 3000)
  }
}