<div class="conte-pro">
    <div *ngIf="sa">
        <div class="contenedor">
            <div class="titulo-row-b">
                <button mat-icon-button [routerLink]="['/dashboard']">
                    <mat-icon>navigate_before</mat-icon>
                </button>
                Margen de Ganancias (Clientes)
            </div>
            <div class="formulario">
                <form [formGroup]="criterioCliente">
                    <mat-form-field class="sele">
                        <mat-label>Mes</mat-label>
                        <mat-select formControlName="mes" (selectionChange)="updateAn($event)">
                            <mat-option [value]="1">ENERO</mat-option>
                            <mat-option [value]="2">FEBRERO</mat-option>
                            <mat-option [value]="3">MARZO</mat-option>
                            <mat-option [value]="4">ABRIL</mat-option>
                            <mat-option [value]="5">MAYO</mat-option>
                            <mat-option [value]="6">JUNIO</mat-option>
                            <mat-option [value]="7">JULIO</mat-option>
                            <mat-option [value]="8">AGOSTO</mat-option>
                            <mat-option [value]="9">SEPTIEMBRE</mat-option>
                            <mat-option [value]="10">OCTUBRE</mat-option>
                            <mat-option [value]="11">NOVIEMBRE</mat-option>
                            <mat-option [value]="12">DICIEMBRE</mat-option>
                            <mat-option [value]="'ACUMULADO'">ACUMULADO</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="sele">
                        <mat-label>Año</mat-label>
                        <mat-select formControlName="an" (selectionChange)="updateAn($event)">
                            <mat-option [value]="2022">2022</mat-option>
                            <mat-option [value]="2021">2021</mat-option>
                            <mat-option [value]="2020">2020</mat-option>
                            <mat-option [value]="2019">2019</mat-option>
                            <mat-option [value]="2018">2018</mat-option>
                            <mat-option [value]="2017">2017</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="e1" class="wit">
            <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: '250px' }"></ngx-skeleton-loader>
        </div>
        <div *ngIf="e1;else cona" class="wit">
            <ngx-skeleton-loader count="3" appearance="circle" [theme]="{
            width: '32%',
            height: '200px',
            'border-radius': '10px'
          }">
            </ngx-skeleton-loader>
        </div>

        <ng-template #cona>


        <div *ngIf="s1;else contentc" class="content">
            <div>
                <div style="height: 100%; width:100%;">
                    <div style="display:flex; flex-direction:column;">
                        <div style="height:350px">
                            <ngx-charts-bar-horizontal [scheme]="colorScheme" [results]="mat" [gradient]="gradient"
                                [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                [legendTitle]="legendTitle" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                [customColors]="customColors">
                            </ngx-charts-bar-horizontal>
                        </div>
                    </div>
                </div>
            </div>
            <br>


            <div class="ct-gris-sa">
                <div>
                    <div>
                        <mat-form-field class="mat-form-field-filtro" >
                          <mat-label>Filtro</mat-label>
                          <input matInput [(ngModel)]="searchText" (click)="reset()" placeholder="Ejemplo: Profusa Product" autocomplete="off" />
                        </mat-form-field>
                      </div>
        
                </div>
                <div>


              <mat-chip-list aria-label="Fish selection">
                <mat-chip (click)="setMeno(0)" [ngStyle]="{'background-color': '#ff0000'}" selected> M < 0%</mat-chip>
                <mat-chip (click)="setMeno(15)" [ngStyle]="{'background-color': '#ff972c'}" selected> M < 15%</mat-chip>
                <mat-chip (click)="setMenom(15)" [ngStyle]="{'background-color': '#26a516'}" selected> M > 15%</mat-chip>
                <mat-chip (click)="resetObs()"  selected> Reiniciar</mat-chip>

              </mat-chip-list>

                </div>
            </div>


            

            <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
                <div *ngFor="let env of dataBos  | async | filter: searchText; index as i" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.lt="0 1 calc(33%-10px)"
                    fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(33%-10px)">
                    <mat-card class="mc mat-elevation-z2" (click)="updateAna(env.id,env.razon_social)">
                        <mat-grid-list cols="10" rowHeight="90px">
                            <mat-grid-tile colspan="3">
                                <app-ima-cliente [idcliente]="env.id_epicor"></app-ima-cliente>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="7">
                                <mat-card-title class="tc">{{ env.razon_social }}</mat-card-title>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="6">
                                <div class="listData">
                                    <div class="sm">Importe de Venta:</div>
                                    <div class="ct-un">{{env.importe_venta | number }}</div>
                                    <div class="sm">Utilidad Neta: </div>
                                    <div class="ct-un"> {{env.utilidad_neta | number }}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="4">
                                <app-pcr [value]="env.pcr"></app-pcr>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-card>
                </div>
            </div>
        </div>
        <ng-template #contentc>
            <app-no-data [mensaje]="'No se han registrados nuevos margenes de ganancia.'" [pd]=""> </app-no-data>
        </ng-template>
    </ng-template>
    </div>
    <div *ngIf="sb">
        <div class="titulo-row-b">
            <button mat-icon-button (click)="resetDivGlobal();">
                <mat-icon>navigate_before</mat-icon>
            </button> {{this.nombre_cliente}} | Mes: {{this.mes}} Año:{{this.yeara}}
        </div>
        <app-margen-producto [idcliente]="this.idcliente" [yeara]="this.yeara" [mes]="this.mes"> </app-margen-producto>
    </div>
</div>


<div class="margen">
  
</div>