import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scg-acum-pro-g',
  templateUrl: './scg-acum-pro-g.component.html',
  styleUrls: ['./scg-acum-pro-g.component.css'],
})
export class ScgAcumProGComponent implements OnInit {
  @Input() vPro: string;
  @Input() vYear: string;
  @Input() vCh: string;
  constructor() {}

  ngOnInit(): void {




  }
}
