import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesService } from "../../../../services/sales/sales.service";

@Component({
  selector: 'app-sales-cli',
  templateUrl: './sales-cli.component.html',
  styleUrls: ['./sales-cli.component.css']
})
export class SalesCliComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);
  panelOpenState = false;
  @Input() vCli: string;




  @Input() vD: Date;
  @Input() vH: Date;
  @Input() vT: String;
  @Input() vCh: String;
  public searchText: any;
  constructor(
    private salesService: SalesService
  ) { }

  ngOnInit(): void {



    if (this.vT === "C") {



      
      this.getDataCliSearchChannel(this.vCli, this.vD, this.vH, this.vCh);
    } else {
      this.getDataCliSearch(this.vCli, this.vD, this.vH);
    }
  }


  getDataCliSearchChannel(vCli, vD, vH, vCh) {
  
    this.salesService.getDataCliSearchChannel({
      vCli: vCli,
      vD: vD,
      vH: vH,
      vCh: vCh
    }).subscribe((resp) => {
      this.obs.next(resp);
    });
  }

  getDataCliSearch(vCli, vD, vH) {
    this.salesService.getDataCliSearch({
      vCli: vCli,
      vD: vD,
      vH: vH,
    }).subscribe((resp) => {
      this.obs.next(resp);
    });
  }


}
