import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GlobalVariable } from '../global';
import { Router } from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class MargenService {
  private URL = GlobalVariable.BASE_API_URL;

  constructor(private http: HttpClient,
    private router: Router,) { }

    getMargenClien(data) {
    return this.http.post<any>(this.URL + 'getMargenClien', data);
  }

  graficaMargenClien(data) {
    return this.http.post<any>(this.URL + 'graficaMargenClien', data);
  }


  graficaMargenClienProducto(data) {
    return this.http.post<any>(this.URL + 'graficaMargenClienProducto', data);
  }

  graficaMargenClienPP(data) {
    return this.http.post<any>(this.URL + 'graficaMargenClienPP', data);
  }

  graficaMargenClienPPDetalle(data) {
    return this.http.post<any>(this.URL + 'graficaMargenClienPPDetalle', data);
  }


}
