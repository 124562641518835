import { Component, OnInit } from '@angular/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { LoginService } from './../../../services/login.service';
import { CustomerService } from './../../../services/customer.service';
import { FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
const moment = _rollupMoment || _moment;

interface HtmlInputEvent extends Event {
target: HTMLInputElement  & EventTarget;
}

export const MY_FORMATS = {
parse: {
dateInput: 'YYYY/MM/DD',
},
display: {
dateInput: 'YYYY/MM/DD',
monthYearLabel: 'YYYY MMM DDD',
dateA11yLabel: 'LL',
monthYearA11yLabel: 'YYYY MMM DDD',
},
};

@Component({
selector: 'app-perfilUsuario',
templateUrl: './perfilUsuario.component.html',
styleUrls: ['./perfilUsuario.component.css'],
providers: [

{
provide: DateAdapter,
useClass: MomentDateAdapter,
deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
},

{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
],
})

export class PerfilUsuarioComponent implements OnInit {

form: FormGroup;
public loginInvalid: boolean;
private formSubmitAttempt: boolean;
private returnUrl: string;
private idCliente:string;
termino:boolean;
isChecked:boolean;
newUsuario = {}
hide = true;
file: File;
ima64: String | ArrayBuffer;
ima64Decode: String | ArrayBuffer;
photoSelected: String | ArrayBuffer;
date = new FormControl(moment(), [Validators.required]);
nombre = new FormControl('', [Validators.required]);
apellido = new FormControl('', [Validators.required]);
telefono = new FormControl('', [Validators.required]);
email = new FormControl('', [Validators.required, Validators.email]);
clave = new FormControl('', [Validators.required]);
dp = new FormControl('', [Validators.required]);
ima = new FormControl('', [Validators.required]);
cod_epicor = new FormControl('', [Validators.required]);

cod_epicor_admin:String;

chosenYearHandler(normalizedYear: Moment) {
const ctrlValue = this.date.value;
ctrlValue.year(normalizedYear.year());
this.date.setValue(ctrlValue);
}
chosenDayHandler(normalizedDay: Moment, datepicker: MatDatepicker<Moment>) {
const ctrlValue = this.date.value;
ctrlValue.day(normalizedDay.day());
this.date.setValue(ctrlValue);
datepicker.close();
}
chosenMonthHandler(normalizedMonth: Moment) {
const ctrlValue = this.date.value;
ctrlValue.month(normalizedMonth.month());
this.date.setValue(ctrlValue);
}

constructor(
private _sanitizer: DomSanitizer,
private loginService: LoginService,
private _snackBar: MatSnackBar,
private fb: FormBuilder,
private router: Router,) { }

ngOnInit(): void {
this.getPerfilUsuaio();
this.form = this.fb.group({
cod_epicor: ['', Validators.required],
nombre: ['', Validators.required],
apellido: ['', Validators.required],
telefono: ['', Validators.required],
email: ['', Validators.email],
clave: ['', Validators.required],
date: ['', Validators.required]
});
}

getPerfilUsuaio(){
this.loginService.infoUsuario()
.subscribe(
res => {
this.form.get('email').setValue(res.usuario.email);

this.form.get('nombre').setValue(res.usuario.nombre);
this.form.get('apellido').setValue(res.usuario.apellido);
this.form.get('telefono').setValue(res.usuario.telefono);
this.form.get('date').setValue(res.usuario.fecha_n);
this.form.get('cod_epicor').setValue(res.usuario.cod_epicor);
this.cod_epicor_admin=res.usuario.cod_epicor;
this.decodeBase64(res.usuario.imagen);
},
err => {
console.log(err);
});
}

updatePerfil(){
if (this.form.valid) {
const nombre = this.form.get('nombre').value;
const apellido = this.form.get('apellido').value;
const telefono = this.form.get('telefono').value;
const email = this.form.get('email').value;
const clave = this.form.get('clave').value;
const date = _moment(this.form.get('date').value).format('YYYY-MM-DD');
const cod_epicor = this.form.get('cod_epicor').value;
this.newUsuario = {
nombre: nombre,
apellido: apellido,
telefono: telefono,
email: email,
clave: clave,
date: date,
termino: null,
imagen: this.ima64,
fecha_n: date,
cod_epicor: this.cod_epicor_admin
}
this.updateApiRest(this.newUsuario);
} else {
this.openSnackBar("Ha ocurrido un error en la validacion de los campos, por favor veriifique y vuelva a intentarlo",'alert')
}
}

updateApiRest(usuario){
this.loginService.updateUsuario(usuario)
.subscribe(
res => {
this.getPerfilUsuaio();
this.openSnackBar(res.status,'ok');
this.router.navigate(['login']);
},
err =>{
this.openSnackBar(err.error.error,'alert')
});
}


openSnackBar(message: string, tipo: string) {
this._snackBar.open(message, '', {
duration: 2000,
horizontalPosition: 'center',
verticalPosition: 'bottom',
panelClass: [tipo]
});
}


getErrorMessageNombre() {
if (this.nombre.hasError('required')) {
return 'Por favor, ingresa tu nombre principal';
}
}

getErrorMessageApellido() {
if (this.apellido.hasError('required')) {
return 'Por favor ingresa tu apellido';
}
}

getErrorMessageTelefono() {
if (this.telefono.hasError('required')) {
return 'Por favor ingresa tu telefono';
}
}

getErrorMessageEmail() {
if (this.email.hasError('required')) {
return 'Por favor ingresa un correo valido';
}
return this.email.hasError('email') ? 'Por favor ingresa un correo valido' : '';
}

getErrorMessageClave() {
if (this.clave.hasError('required')) {
return 'Por favor, elige una contraseña';
}
}

getErrorMessageDate() {
if (this.date.hasError('required')) {
return 'Por favor, indicanos tu fecha de nacimiento';
}
}

getErrorMessageDp() {
if (this.dp.hasError('required')) {
return 'Por favor, indicanos tu fecha de nacimiento';
}
}

getErrorMessageIma() {
if (this.ima.hasError('required')) {
return 'Tendra acceso a un centro de notificacion, tu imagen de perfil ayudara a tus companeros a saber graficamente quien eres.';
}
}

getErrorMessageCod() {
  if (this.cod_epicor.hasError('required')) {
    return 'Por favor, indicanos tu cod en EPICOR';
  }
}


onFileSelected(event: HtmlInputEvent): void {
if (event.target.files && event.target.files[0]){
this.file= <File>event.target.files[0];
const reader = new FileReader();
reader.onload = e => this.photoSelected = reader.result;
reader.readAsDataURL(this.file);
reader.onload = () => {
this.ima64=reader.result;
this.photoSelected = reader.result;
};
}
}

decodeBase64(ima64Endode){
this.photoSelected = ima64Endode;
}

}
