
<div *ngIf="loading;else nodata">
    <mat-card class="mat-card-pre" >
        <div class="ct-ima" [ngStyle]="getcolor()" (click)="goToLink(Env._id)">
            <div class="ct-vol"> {{ Env.tipo}} </div>
            <div class="ct-n"> {{ Env.ci}} </div>
        </div>

        <div class="ct-cli">
            <app-ima-clien-id-circle [idcliente]="Env._id_cliente"></app-ima-clien-id-circle>
        </div>
        <div class="ct-d">
            <div class="ct-d-title">
                <span class="ct-d-t-text">{{Env.nombre.toUpperCase()}}</span>
                <br>
                <span class="ct-d-st-text">{{ getDate(Env.createdAt)}}</span>
                <span class="ct-d-st-est">ABIERTO</span>
            </div>
            <div class="ct-d-ima-due">
                <div class="ct-d-ima-due-user">
                    <app-ima-user-id [vUser]="Env._id_usuario"></app-ima-user-id>
                </div>
            </div>
        </div>
        <app-card-info [vTas]="Env._id" [vMie]="Env.miembros.length"></app-card-info>
    </mat-card>
</div>

<ng-template #nodata>
</ng-template>