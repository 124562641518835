import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaService } from 'src/app/services/area.service';
import { StarRatingComponent } from 'ng-starrating';

export interface DialogData {
  vTs: string;
  vPro: string;
}

@Component({
  selector: 'app-up-task-scheme',
  templateUrl: './up-task-scheme.component.html',
  styleUrls: ['./up-task-scheme.component.css'],
})
export class UpTaskSchemeComponent implements OnInit {
  raking: any;
  idPro: any;
  codTas: any;
  toppingList: any;
  obsTask: any;
  datas: boolean;
  vTs: string;
  vPro: string;
  miAr: any;

  constructor(
    private areaService: AreaService,
    public dialogRef: MatDialogRef<UpTaskSchemeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.vTs = this.data.vTs;
    this.vPro = this.data.vPro;
  }
  formPurchaseOrder: FormGroup;

  ngOnInit(): void {
    this.createForm();
    this.getMiembrosGen();
    this.getTaskOneShcheme(this.vTs);
    this.getTaskSchemeDepe(this.vPro, this.vTs);
    this.getAllTaskPro(this.vPro);
  }

  createForm() {
    this.formPurchaseOrder = new FormGroup({
      tarea: new FormControl('', [Validators.required]),
      des: new FormControl('', [Validators.required]),
      entre: new FormControl('', [Validators.required]),
      responsable: new FormControl('', [Validators.required]),
      dias: new FormControl('', [Validators.required]),
      pre: new FormControl(''),
      colora: new FormControl(this.generarNuevoColor(), [Validators.required]),
    });
  }

  add() {
    if (this.formPurchaseOrder.valid) {
      const tarea = this.formPurchaseOrder.controls.tarea.value;
      const des = this.formPurchaseOrder.controls.des.value;
      const responsable = this.formPurchaseOrder.controls.responsable.value;
      const prioridad = this.raking;
      const dias = this.formPurchaseOrder.controls.dias.value;
      const entre = this.formPurchaseOrder.controls.entre.value;
      const pre = this.formPurchaseOrder.controls.pre.value;
      const color = this.formPurchaseOrder.controls.colora.value;

      let newObj = {
        _id_pro: this.vPro,

        nombre: tarea,
        des: des,
        entre: entre,
        responsable: responsable,
        dias: dias,
        prioridad: prioridad,
        color: color,
        pre: pre,
        _id_status: '62f6691aaab86d26ed2318d9',
      };



      this.areaService
        .upTaksShchemeMi({ _id: this.vTs, obj: newObj })
        .subscribe((resp) => {

          this.areaService.oTaskSchemen$.emit(this.vPro);
     
          this.onClose();

        });
    } else {
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getMiembrosGen() {
    this.areaService.getMiembrosGen().subscribe((resp) => {
      this.miAr = resp;
    });
  }

  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    this.raking = $event.newValue;
  }

  getTaskOneShcheme(vTs) {
    this.areaService.getTaskOneShcheme({ vTs: vTs }).subscribe((resp) => {
      if (resp.length > 0) {
        this.datas = true;
        this.raking = resp[0].prioridad;

        this.formPurchaseOrder.patchValue({
          tarea: resp[0].nombre,
          des: resp[0].des,
          cit: resp[0].cit,
          responsable: resp[0].responsable,
          entre: resp[0].entre,
          prioridad: resp[0].prioridad,
          dias: resp[0].dias,
          color: resp[0].color,
        });
      } else {
        this.datas = false;
      }
    });
  }

  generarNuevoColor() {
    const minL = 0.75;
    const maxL = 0.9;
    const interval = 3000;
    const colors = {
      bgColor: '',
      txtColor: '',
      btnColor: '',
      btnFocus: '',
    };

    const hBase = Math.random();
    const newH = Math.floor(hBase * 360);
    const newL = Math.floor(Math.random() * 16) + 75;

    colors.bgColor = `hsl(${newH}, 100%, ${newL}%)`;
    colors.txtColor = `hsl(${newH}, 100%, 5%)`;
    colors.btnColor = `hsl(${newH}, 100%, 98%)`;
    colors.btnFocus = `hsl(${newH}, 100%, 95%)`;

    const [r, g, b] = this.HSLtoRGB(hBase, 1, newL * 0.01);

    // update css vars
    Object.entries(colors).forEach(([k, v]) => {
      document.documentElement.style.setProperty(`--${k}`, `${v}`);
    });

    return this.RGBtoHex(r, g, b);
  }

  HSLtoRGB(h, s, l) {
    let r, g, b;

    const rd = (a) => {
      return Math.floor(Math.max(Math.min(a * 256, 255), 0));
    };

    const hueToRGB = (m, n, o) => {
      if (o < 0) o += 1;
      if (o > 1) o -= 1;
      if (o < 1 / 6) return m + (n - m) * 6 * o;
      if (o < 1 / 2) return n;
      if (o < 2 / 3) return m + (n - m) * (2 / 3 - o) * 6;
      return m;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hueToRGB(p, q, h + 1 / 3);
    g = hueToRGB(p, q, h);
    b = hueToRGB(p, q, h - 1 / 3);

    return [rd(r), rd(g), rd(b)];
  }

  RGBtoHex(r, g, b) {
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  }

  getAllTaskPro(vPro) {
    this.areaService
      .getTaskAllShchemeMicroIdPro({ vTs: vPro })
      .subscribe((resp) => {
        if (resp.length > 0) {
        } else {
        }

        this.toppingList = resp;
      });
  }

  getTaskSchemeDepe(vAr, vTa) {

    this.areaService
      .getTaskSchemeDepe({ vAr: vAr, vTs: vTa })
      .subscribe((resp) => {

        this.formPurchaseOrder.patchValue({
          pre: resp,
        });
      });
  }
}
