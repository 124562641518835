<div class="titulo-row-b">
Nuevo Cliente
</div>
<mat-divider></mat-divider>

  <form [formGroup]="aFormGroup" method="post">
<div class="columna1">

  <input formControlName="ima" value=""  hidden (change)="onFileSelected($event)" #fileInput type="file" id="ima">
  <br>
  <mat-card class="mat-card">
    <mat-card-title class="titulo-row-b"></mat-card-title>
  <mat-card-content>
  <img mat-card-avatar  [src]="photoSelected || 'assets/ima/no-ima.png'" class="imgfluid" (click)="fileInput.click()">
  </mat-card-content>
  <mat-card-subtitle>Click para seleccionar una imagen</mat-card-subtitle>
  </mat-card>

</div>

<div class="columna2">
  <mat-form-field class="mat-form" appearance="fill">
    <mat-label>Codigo EPICOR</mat-label>
    <input matInput formControlName="cod_epicor" value="" required autocomplete="off">
    <mat-error *ngIf="cod_epicor.invalid">{{getErrorMessageCodEpicor()}}</mat-error>
  </mat-form-field>

  <mat-form-field class="mat-form" appearance="fill">
    <mat-label>Razon Social</mat-label>
    <input matInput formControlName="nombre" value="" required autocomplete="off">
    <mat-error *ngIf="nombre.invalid">{{getErrorMessageRazon_social()}}</mat-error>
  </mat-form-field>

  <mat-form-field class="mat-form" appearance="fill">
    <mat-label>Descripcion</mat-label>
    <input matInput formControlName="descripcion" value=""  autocomplete="off">
    <mat-error *ngIf="descripcion.invalid">{{getErrorMessageDes()}}</mat-error>
  </mat-form-field>



    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Ciudad</mat-label>
      <input matInput formControlName="ciudad" value=""  autocomplete="off">
      <mat-error *ngIf="ciudad.invalid">{{getErrorMessageCiudad()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Estado</mat-label>
      <input matInput formControlName="provincia" value=""  autocomplete="off">
      <mat-error *ngIf="provincia.invalid">{{getErrorMessageProvincia()}}</mat-error>
    </mat-form-field>
    <!-- <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Telefono</mat-label>
      <input matInput formControlName="telefono" value=""  autocomplete="off">
      <mat-error *ngIf="telefono.invalid">{{getErrorMessageTelefono()}}</mat-error>
    </mat-form-field> -->
    <mat-form-field class="mat-form" appearance="fill">
      <mat-label>Grupo</mat-label>
      <input matInput formControlName="grupo" value=""  autocomplete="off">
      <mat-error *ngIf="grupo.invalid">{{getErrorMessageGrupo()}}</mat-error>
    </mat-form-field>
    <!-- <mat-form-field appearance="fill" class="mat-form-f">
      <mat-label>Canal</mat-label>
      <mat-select formControlName="canal" required>
        <mat-option value="60215c356a2bbf1d34a2f818"> Industrial </mat-option>
        <mat-option value="60215cab6a2bbf1d34a2f819"> Food Service </mat-option>
        <mat-option value="60215d0c6a2bbf1d34a2f81b"> Retail </mat-option>
        <mat-option value="60215ccb6a2bbf1d34a2f81a"> Franquicia </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field appearance="fill">
      <mat-label>Canales</mat-label>
      <mat-select [formControl]="canales" multiple>
        <mat-option *ngFor="let uni of obs | async" [value]="uni._id" (onSelectionChange)="getValues($event)">
          {{uni.canal}}</mat-option>
      </mat-select>
    </mat-form-field>


  <button class="btn"  color="primary" mat-raised-button  mat-button (click)="add()">Registrar</button>
</div>
</form>
