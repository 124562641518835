import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GlobalVariable } from '../global';
import { Router } from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class PurshaseOrderService {
  private URL = GlobalVariable.BASE_API_URL;
  public listItem$ = new EventEmitter<any>();
  public listOc$ = new EventEmitter<any>();
  constructor(private http: HttpClient,
    private router: Router,) { }

    getCodInterno() {
    return this.http.get<any>(this.URL + 'getCodInternoOC');
  }

  registroOrde(data) {
    return this.http.post<any>(this.URL + 'registroOrde', data);
  }

  getAllItemOrd(data) {
    return this.http.post<any>(this.URL + 'getAllItemOrd', data);
  }

  getAllOrder(data) {
    return this.http.post<any>(this.URL + 'getAllOrder',data);
  }

  delOrdenItem(data) {
    return this.http.post<any>(this.URL + 'delOrdenItem',data);
  }


  delOrdenItemList(data) {
    return this.http.post<any>(this.URL + 'delOrdenItemList',data);
  }

  getOneOrder(data) {
    return this.http.post<any>(this.URL + 'getOneOrder',data);
  }

  updateBasicOc(data) {
    return this.http.post<any>(this.URL + 'updateBasicOc',data);
  }

  updateOrdeItemPro(data) {
    return this.http.post<any>(this.URL + 'updateOrdeItemPro',data);
  }

  getGraVet(data) {
    return this.http.post<any>(this.URL + 'getGraVet',data);
  }

  
  

  // graficaMargenClienProducto(data) {
  //   return this.http.post<any>(this.URL + 'graficaMargenClienProducto', data);
  // }

  // graficaMargenClienPP(data) {
  //   return this.http.post<any>(this.URL + 'graficaMargenClienPP', data);
  // }

  // graficaMargenClienPPDetalle(data) {
  //   return this.http.post<any>(this.URL + 'graficaMargenClienPPDetalle', data);
  // }


}
