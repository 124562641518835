import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VenMesAdminService {
  private URL = GlobalVariable.BASE_API_URL;
  constructor(private http: HttpClient) {}
  private dataGraf$: Subject<DataGrafa[]> = new Subject<DataGrafa[]>();
  private dataF: DataGrafa[] = [];

  aadGraf(arraGra: DataGrafa) {
    this.dataF.push(arraGra);
    this.dataGraf$.next(this.dataF);
  }

  getGraf$(): Observable<any[]> {
    return this.dataGraf$.asObservable();
  }

  orqVenMes(data) {
    return this.http.post<any>(this.URL + 'orqVenMes', data);
  }

  getVenCliMesPre(data) {
    return this.http.post<any>(this.URL + 'getVenCliMesPre', data);
  }

  orqVenMesDet(data) {
    return this.http.post<any>(this.URL + 'orqVenMesDet', data);
  }

  getYearPreVenMes(data) {
    return this.http.post<any>(this.URL + 'getYearPreVenMes', data);
  }

  acuCliGen(data) {
    return this.http.post<any>(this.URL + 'acuCliGen', data);
  }

  masterPreCusVenYearBlo(data) {
    return this.http.post<any>(this.URL + 'masterPreCusVenYearBlo', data);
  }

  acuCliGenGrupo(data) {
    return this.http.post<any>(this.URL + 'acuCliGenGrupo', data);
  }
  acuCliGenGlobalYear(data) {
    return this.http.post<any>(this.URL + 'acuCliGenGlobalYear', data);
  }

  scgGraficaGlobalYear(data) {
    return this.http.post<any>(this.URL + 'scgGraficaGlobalYear', data);
  }

  scgGraficaGlobalYearVol(data) {
    return this.http.post<any>(this.URL + 'scgGraficaGlobalYearVol', data);
  }

  acuCliGenGrupoTable(data) {
    return this.http.post<any>(this.URL + 'acuCliGenGrupoTable', data);
  }

  scgGraficaCliYearVol(data) {
    return this.http.post<any>(this.URL + 'scgGraficaCliYearVol', data);
  }

  scgGraficaGlobalYearBar(data) {
    return this.http.post<any>(this.URL + 'scgGraficaGlobalYearBar', data);
  }

  scgGraficaGlobalYearBarCliente(data) {
    return this.http.post<any>(
      this.URL + 'scgGraficaGlobalYearBarCliente',
      data
    );
  }

  ScgGraGenericoGlobalYearVol(data) {
    return this.http.post<any>(this.URL + 'ScgGraGenericoGlobalYearVol', data);
  }
  acuCliGenGrupoTableGen(data) {
    return this.http.post<any>(this.URL + 'acuCliGenGrupoTableGen', data);
  }



  venCSVScgTableAcumCli(data) {
    return this.http.post<any>(this.URL + 'venCSVScgTableAcumCli', data);
  }


  venCSVScgTableAcumPro(data) {
    return this.http.post<any>(this.URL + 'venCSVScgTableAcumPro', data);
  }


}

export interface DataGrafa {
  year: number;
  series: {
    name: string;
    value: number;
  };
}
