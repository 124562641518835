import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class EnviosService {
  public envSer$ = new EventEmitter<any>();
  public envEstado$ = new EventEmitter<any>();
  public envEstadolist$ = new EventEmitter<any>();

  public envlc$ = new EventEmitter<any>();
  public envlcg$ = new EventEmitter<any>();



  private URL = GlobalVariable.BASE_API_URL;
  constructor(private http: HttpClient,
    private router: Router,) { }

  regProductoEnvio(data) {
    return this.http.post<any>(this.URL + 'regProductoEnvio', data);
  }


  regEnvio(data) {
    return this.http.post<any>(this.URL + 'regEnvio', data);
  }

  getAllProductoEnvio(data) {
    return this.http.post<any>(this.URL + 'getAllProductoEnvio', data);
  }

  getAllEnvio() {
    return this.http.get<any>(this.URL + 'getAllEnvio');
  }

  getAllEnvioCri(data) {

    return this.http.post<any>(this.URL + 'getAllEnvioCri', data);
  }

  getAllEstado(data) {
    return this.http.post<any>(this.URL + 'getAllEstado', data);
  }


  deleteEnvio(data) {
    return this.http.post<any>(this.URL + 'deleteEnvio', data);
  }


  regEstadoEnvios(data) {
    return this.http.post<any>(this.URL + 'regEstadoEnvios', data);
  }



  regEstadoHist(data) {
    return this.http.post<any>(this.URL + 'regEstadoHist', data);
  }

  getAllEstadoID(data) {
    return this.http.post<any>(this.URL + 'getAllEstadoID', data);
  }


  updateEstadoUp(data) {
    return this.http.post<any>(this.URL + 'updateEstadoUp', data);
  }


  delEveHist(data) {
    return this.http.post<any>(this.URL + 'delEveHist', data);
  }

  getAllEstadoFind(data) {
    return this.http.post<any>(this.URL + 'getAllEstadoFind', data);
  }

  rmdelallEnvio(data) {
    return this.http.post<any>(this.URL + 'rmdelallEnvio', data);
  }


  getIdProducto(data) {
    return this.http.post<any>(this.URL + 'getIdProducto', data);
  }

  updateEstadoUpRe(data) {
    return this.http.post<any>(this.URL + 'updateEstadoUpRe', data);
  }

  updateSeguimiento(data) {
    return this.http.post<any>(this.URL + 'updateSeguimiento', data);
  }

  countEnvios() {
    return this.http.get<any>(this.URL + 'countEnvios');
  }

  rEnCli(data) {
    return this.http.post<any>(this.URL + 'rEnCli', data);
  }

  getOpCliente(data) {
    return this.http.post<any>(this.URL + 'getOpCliente', data);
  }

  delrEnCli(data) {
    return this.http.post<any>(this.URL + 'delrEnCli', data);
  }

  getCodInterno() {
    return this.http.get<any>(this.URL + 'getCodInterno');
  }

  regEnvioCodInterno(data) {
    return this.http.post<any>(this.URL + 'regEnvioCodInterno', data);
  }

  getAllEnvioId(data) {
    return this.http.post<any>(this.URL + 'getAllEnvioId', data);
  }

  getNumEnvios() {
    return this.http.get<any>(this.URL + 'getNumEnvios');
  }

  getNumEnviosRepro() {
    return this.http.get<any>(this.URL + 'getNumEnviosRepro');
  }

  regEnvioMulta(data) {
    return this.http.post<any>(this.URL + 'regEnvioMulta', data);
  }

  getMulta() {
    return this.http.get<any>(this.URL + 'getMulta');
  }


  getListadoActivo() {
    return this.http.get<any>(this.URL + 'getListadoActivo');
  }

  getListadoActivoCri(data) {
    return this.http.post<any>(this.URL + 'getListadoActivoCri',data);
  }

  getCodIntOp() {
    return this.http.get<any>(this.URL + 'getCodIntOp');
  }


  getAllEnvioPDF(data) {
    return this.http.post<any>(this.URL + 'getAllEnvioPDF',data);
  }


  getNumEnviosActivos() {
    return this.http.get<any>(this.URL + 'getNumEnviosActivos');
  }


  getEnvProCliPan(data) {
    return this.http.post<any>(this.URL + 'getEnvProCliPan',data);
  }

  getAllEnvioCriDet(data) {
    return this.http.post<any>(this.URL + 'getAllEnvioCriDet',data);
  }


  
  
}
