import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { AuthLoginGuard } from "./auth-login.guard";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { BudgetComponent } from "./components/budget/budget.component";
import { CustomerComponent } from "./components/customer/customer.component";
import { PanelVentaSemanaAdminDetalleComponent } from "./components/sales/panel-venta-semana-admin-detalle/panel-venta-semana-admin-detalle.component";
import { PanelVentaMesAdminDetalleComponent } from "./components/sales/panel-venta-mes-admin-detalle/panel-venta-mes-admin-detalle.component";
import { MargenComponent } from "./components/margen/margen.component";
import { ProductoComponent } from "./components/product/producto.component";
import { AsigEjecutivoComponent } from "./components/customer/asig-ejecutivo/asig-ejecutivo.component";
import { UsuariosComponent } from "./components/user/usuarios.component";
import { RolesComponent } from "./components/user/roles/roles.component";
import { PerfilUsuarioComponent } from './components/user/perfilUsuario/perfilUsuario.component';
import { BudgetYearComponent } from "./components/budget/budget-year/budget-year.component";
import { SalesComponent } from "./components/sales/sales.component";
import { SalesYearComponent } from "./components/sales/sales-year/sales-year.component";
import { SearchVenComponent } from "./components/sales/search-ven/search-ven.component";
import { CriSearchRadComponent } from "./components/sales/search-ven/cri-search-rad/cri-search-rad.component";
import { CriSearchVdhComponent } from "./components/sales/search-ven/cri-search-vdh/cri-search-vdh.component";
import { ChannelComponent } from "./components/channel/channel.component";
import { SalesChannelGroupComponent } from "./components/sales/sales-channel-group/sales-channel-group.component";
import { PurchaseOrderComponent } from "./components/purchase-order/purchase-order.component";
import { PurchaseOrderAddRqComponent } from "./components/purchase-order/purchase-order-add-rq/purchase-order-add-rq.component";
import { EnviosComponent } from "./components/shipping/envios.component";
import { AddEnviMultipleComponent } from "./components/shipping/add-envi-multiple/add-envi-multiple.component";
import { PanelAdminEnviosDetalleComponent } from "./components/shipping/panel-admin-envios-detalle/panel-admin-envios-detalle.component";
import { SalesChannelGenericoComponent } from "./components/sales/sales-channel-generico/sales-channel-generico.component";
import { UnidadesComponent } from "./components/units/unidades.component";
import { PurchaseOrderUpRqComponent } from "./components/purchase-order/purchase-order-up-rq/purchase-order-up-rq.component";
import { AreaComponent } from "./components/area/area.component";
import { AreaDetailComponent } from "./components/area/area-detail/area-detail.component";
import { GalleryComponent } from "./components/gallery/gallery.component";

import { TaskGanttComponent } from "./components/area/area-detail/task-gantt/task-gantt.component";
import { TaskSchemeComponent } from "./components/area/area-detail/task-scheme/task-scheme.component";

import { ChatComponent } from "./components/chat/chat.component";



const routes: Routes = [

  { path: 'login', component: LoginComponent, },


  {
    path: '', component: HomeComponent, canActivate: [AuthLoginGuard],
    children: [
      { path: '', component: DashboardComponent, canActivate: [AuthLoginGuard] },
      { path: 'home', component: DashboardComponent, canActivate: [AuthLoginGuard] },
      { path: 'customer', component: CustomerComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin', 'gerente', 'vendedor'] } },
      { path: 'product', component: ProductoComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin', 'gerente', 'vendedor'] } },
      { path: 'budget', component: BudgetComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin', 'gerente', 'vendedor'] } },
      { path: 'margen', component: MargenComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin', 'gerente', 'vendedor'] } },
      { path: 'ventas_detalles_semana/:id/:sem/:yeara', component: PanelVentaSemanaAdminDetalleComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
      { path: 'ventas_detalles_mes/:id/:mes/:yeara', component: PanelVentaMesAdminDetalleComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
      { path: 'customer_asig', component: AsigEjecutivoComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'rol', component: RolesComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'users', component: UsuariosComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'pusers', component: PerfilUsuarioComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'budget_detail/:yeara', component: BudgetYearComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
      { path: 'sales', component: SalesComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'sales_detail/:yeara', component: SalesYearComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
      { path: 'sales_search', component: SearchVenComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'sales_search_cri_p/:vCli/:vPro/:vRan', component: CriSearchRadComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
      { path: 'sales_search_cri_vdh/:vCli/:vPro/:vD/:vH', component: CriSearchVdhComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
      { path: 'channel', component: ChannelComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'sales_channel_group/:vCh', component: SalesChannelGroupComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'purchase_order', component: PurchaseOrderComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'purchase_order_add', component: PurchaseOrderAddRqComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'purchase_order_up/:vOc', component: PurchaseOrderUpRqComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
     
      { path: 'shipping', component: EnviosComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'nuevo_envio_multiple', component: AddEnviMultipleComponent, data: { privileges: ['admin'] } },
      { path: 'listado_envios_detalles/:id/:op', component: PanelAdminEnviosDetalleComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin', 'logistica'] } },
      { path: 'sales_channel_generico/:vCh/:vYear', component: SalesChannelGenericoComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      { path: 'units', component: UnidadesComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin', 'gerente', 'vendedor'] } },
      
      { path: 'areas', component: AreaComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'area_detail/:vAr', component: AreaDetailComponent, canActivate: [AuthLoginGuard], data: { privileges: ['gerente', 'admin'] } },
      
      { path: 'gallery', component: GalleryComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'gantt', component: TaskGanttComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'area_scheme', component: TaskSchemeComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },
      { path: 'chat', component: ChatComponent, canActivate: [AuthLoginGuard], data: { privileges: ['admin'] } },

      

      
    ]

  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
