<mat-grid-list cols="10" rowHeight="60px">
  <mat-grid-tile colspan="1">
    <div class="ima_cliente">
      <app-ima-cliente [idcliente]="idepicor"></app-ima-cliente>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="9">
    <div class="title text-inside-grid">
      <div class="tc">{{ gname }}</div>
    </div>
  </mat-grid-tile>
</mat-grid-list>


<mat-divider></mat-divider>



<mat-tab-group>
  <mat-tab label="Contactos">

    <div class="wrapper" *ngIf="loading">
      <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
    </div>

    <div class="conten" *ngIf="datav; else nodata">

      <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
        <mat-card class="mc" *ngFor="let env of obs | async" fxFlex.sm="0 1 calc(50%-10px)"
          fxFlex.lt="0 1 calc(33%-10px)" fxFlex.md="0 1 calc(25%-10px)" fxFlex.gt-md="0 1 calc(33%-10px)">

          <mat-card-title>{{ env.nombre }}</mat-card-title>
          <mat-card-subtitle>{{ env.cargo }}</mat-card-subtitle>

          <mat-card-subtitle>{{ env.mail }}</mat-card-subtitle>
          <mat-card-subtitle>{{ env.telefono | mask: '(000) 000-0000' }}</mat-card-subtitle>
          <mat-card-subtitle>{{ env.ext }}</mat-card-subtitle>

        </mat-card>

      </div>

    </div>












  </mat-tab>
  <mat-tab label="Agregar Nuevo">
    <div class="ddco">
      <form [formGroup]="cCliente">
        <mat-grid-list cols="10" rowHeight="50px">
          <mat-grid-tile colspan="4">
            <mat-form-field class="fidel">
              <mat-label>Nombre y Apellido</mat-label>
              <input formControlName="nombre" matInput autocomplete="off" />
            </mat-form-field>
            <br />
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
            <mat-form-field class="fidel">
              <mat-label>Cargo</mat-label>
              <input formControlName="cargo" matInput autocomplete="off" />
            </mat-form-field>
            <br />
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="fidel">
              <mat-label>Teléfono</mat-label>
              <input formControlName="telefono" mask="(000) 000-0000" matInput autocomplete="off" />
            </mat-form-field>
            <br />
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="fidel">
              <mat-label>Ext</mat-label>
              <input formControlName="ext" matInput autocomplete="off" />
            </mat-form-field>
            <br />
          </mat-grid-tile>

          <mat-grid-tile colspan="8">
            <mat-form-field class="fidel">
              <mat-label>Correo</mat-label>
              <input formControlName="mail" matInput autocomplete="off" />
            </mat-form-field>
            <br />
          </mat-grid-tile>

          <mat-grid-tile class="fidel" colspan="2">
            <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button (click)="add()">
              <mat-icon matListIcon>add</mat-icon>Agregar
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>

    <div class="wrapper" *ngIf="loading">
      <mat-spinner class="inner" diameter="80" strokeWidth="10"></mat-spinner>
    </div>

    <div class="conten" *ngIf="datav; else nodata">
      <div class="table-container">
        <table mat-table [dataSource]="lstsem" class="table-data mat-elevation-z8">
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef>Nombre y Apellido</th>
            <td mat-cell *matCellDef="let element">
              {{ element.nombre }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cargo">
            <th class="cell-center" mat-header-cell *matHeaderCellDef>Cargo</th>
            <td class="cell-center" mat-cell *matCellDef="let element">
              <div class="sp">{{ element.cargo }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="telefono">
            <th class="cell-center" mat-header-cell *matHeaderCellDef>
              Teléfono
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.telefono }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ext">
            <th class="cell-center" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element.ext }}</td>
          </ng-container>

          <ng-container matColumnDef="mail">
            <th class="cell-center" mat-header-cell *matHeaderCellDef>
              Correo
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.mail }}
            </td>
          </ng-container>
          <ng-container matColumnDef="opciones">
            <th class="cell-center" mat-header-cell *matHeaderCellDef>
              Opciones
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
                  mat-menu-item (click)="onDialogDel(element._id)">
                  <mat-icon>delete_forever</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>

    <ng-template #nodata>

      <app-no-data [mensaje]="'No hay datos de contacto.'" [pd]=""> </app-no-data>
    </ng-template>
  </mat-tab>
</mat-tab-group>