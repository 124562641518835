<div *ngIf="loading;else nodata" class="ct-global">
    <div class="ctg-pre">
        <mat-card class="mat-card-pre">
            <div class="ct-pre">
                <div class="ct-pre-i">
                    <span>PRE {{vEnv.year}} {{vEnv.mes}}</span>
                </div>
                <div class="ct-pre-k">{{vEnv.premKg | number: '1.2-2'}} <span class="ct-den">KGS.</span> </div>
                <div class="ct-pre-l">{{vEnv.premLb | number: '1.2-2'}} <span class="ct-den">LBS.</span> </div>
                <div class="ct-pre-p">{{vEnv.premPz | number: '1.2-2'}} <span class="ct-den">PZA.</span> </div>
                <div class="ct-pre-mxn">{{vEnv.premMxn | number: '1.2-2'}} <span class="ct-den">MXN</span></div>
                <div class="ct-pre-usd">{{vEnv.premUsd | number: '1.2-2'}} <span class="ct-den">USD</span></div>
                <div class="ct-pre-usdc"><span>{{vEnv.preTc | number: '1.2-2'}}</span> </div>
                <div class="ct-pre-usdttc">{{ vEnv.vTotalPreUsdTc | number: '1.2-2'}} <span class="ct-den">MXN</span>
                </div>
                <div class="ct-pre-total">{{vEnv.vTotalPreMxn | number: '1.2-2'}} <span class="ct-den">MXN</span></div>
            </div>
        </mat-card>
    </div>
    <div class="ctg-gra">

        <app-pre-mensual-ind [value]="getCal(vEnv.vTotalPreMxn,vEnv.vTotalVenMxn)"></app-pre-mensual-ind>

    </div>
    <div class="ctg-ven">


        <mat-card class="mat-card-pre">
            <div class="ct-ven">
                <div class="ct-ven-i">
                    <span>VEN {{vEnv.year}} {{vEnv.mes}}</span>
                </div>
                <div class="ct-ven-k"><span class="ct-den">KGS. </span>{{vEnv.venKg | number: '1.2-2'}} </div>
                <div class="ct-ven-l"><span class="ct-den">LBS. </span>{{vEnv.venLb | number: '1.2-2'}} </div>
                <div class="ct-ven-p"><span class="ct-den">PZA. </span>{{vEnv.venPz | number: '1.2-2'}} </div>

                <div class="ct-ven-mxn"><span class="ct-den">MXN</span> {{vEnv.venmMxn | number: '1.2-2'}} </div>
                <div class="ct-ven-usd"> <span class="ct-den">USD</span> {{vEnv.venmUsd | number: '1.2-2'}}</div>
                <div class="ct-ven-usdtomxn"><span class="ct-den">MXN</span> {{ vEnv.venmUsdtoMxn | number: '1.2-2'}}
                </div>
                <div class="ct-ven-total"><span class="ct-den">MXN</span> {{vEnv.vTotalVenMxn | number: '1.2-2'}}</div>
            </div>
        </mat-card>
    </div>
    <div class="ctg-gra-ven">
        <div class="gv1">
            <div class="gv2">
                <div class="gv3">
                    <ngx-charts-line-chart [scheme]="bcolorScheme" [legend]="blegend" [showXAxisLabel]="bshowXAxisLabel"
                        [showYAxisLabel]="bshowYAxisLabel" [xAxis]="bxAxis" [yAxis]="byAxis" [xAxisLabel]="bxAxisLabel"
                        [yAxisLabel]="byAxisLabel" [timeline]="btimeline" [results]="bmulti">
                    </ngx-charts-line-chart>
                </div>
            </div>
        </div>
    </div>






</div>

<ng-template #nodata>
    <div class="ct-global">
        <div class="ctg-pre">
            <mat-card class="mat-card-pre">
                <div class="ct-pre">
                    <div class="ct-pre-i">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '40px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '30px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-k">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '150px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '20px',
                        'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-l">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-p">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-mxn">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-usd">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-usdc">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '20px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-usdttc">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-pre-total">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="ctg-gra">

            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '100%',
                'padding':'1px',
                'margin':'1px',
                'height': '100px',
                'background-color': '#daddd8'}">
            </ngx-skeleton-loader>


        </div>
        <div class="ctg-ven">


            <mat-card class="mat-card-pre">
                <div class="ct-ven">
                    <div class="ct-ven-i">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '40px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '30px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-ven-k">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-ven-l">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-ven-p">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>

                    <div class="ct-ven-mxn">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-ven-usd">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '150px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '20px',
                        'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-ven-usdtomxn">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                        'border-radius': '5px', 
                        'width': '150px',
                        'padding':'1px',
                        'margin':'1px',
                        'height': '20px',
                        'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="ct-ven-total">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            'border-radius': '5px', 
                            'width': '150px',
                            'padding':'1px',
                            'margin':'1px',
                            'height': '20px',
                            'background-color': '#daddd8'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="ctg-gra-ven">
            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '80%',
                'padding':'1px',
                'margin':'1px',
                'height': '101px',
                'background-color': '#daddd8'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
<button class="btn-d" (click)="showdetailF();" mat-flat-button>{{texts}}</button>

<section *ngIf="showdetail">
<div class="titulo-row-b">
    <button mat-icon-button [routerLink]="['/dashboard']">
        <mat-icon>attach_money</mat-icon>
    </button>
    Actividad Especifica
</div>
<mat-divider></mat-divider>
<br>

<mat-form-field class="mat-form-field" appearance="fill">
    <mat-label>Filtro</mat-label>
    <input matInput [(ngModel)]="searchText" placeholder="Ejemplo: fresa" autocomplete="off" />
</mat-form-field>

<div class="ct-list" *ngFor="let enl of vEnv.listado | filter: searchText">
    <app-pre-mensual-cliente-lis [vCh]="vCh" [tCop]="tCop" [vYear]="vEnv.vYear" [vCliente]="enl._id_cliente" [vEpicor]="enl._id_epicor"
        [vNombre]="enl.nombre" [vD]="vEnv.vD" [vMes]="vEnv.vMes" [vH]="vEnv.vH">
    </app-pre-mensual-cliente-lis>
</div>
</section>