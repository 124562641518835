import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class DirectorioService {
  private URL = GlobalVariable.BASE_API_URL;
  public dirlist$ = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  registrarDir(data) {
    return this.http.post<any>(this.URL + 'registrarDir', data);
  }

  getAllDir(data) {
    return this.http.post<any>(this.URL + 'getAllDir', data);
  }


  delDir(data) {
    return this.http.post<any>(this.URL + 'delDir', data);
  }



  getAllDirList(data) {
    return this.http.post<any>(this.URL + 'getAllDirList', data);
  }

  

}