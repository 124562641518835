<div class="ct-boxge" *ngIf="boxge;else bx">


    <div>
        <div class="ct-ti-b">
            <div> <button mat-icon-button>
                    <mat-icon>navigate_before</mat-icon>
                </button></div>
            <div> Actualizar tarea</div>

            <div class="ct-ti-b-s"> <span class="ct-bt-c" (click)="onClose()">Cancelar</span></div>
        </div>
    </div>
    <mat-divider></mat-divider>

    <div class="ct-content" *ngIf="t2 && t3 && t4;else nodata">


        <form [formGroup]="formPurchaseOrder">
            <div class="ct-for">

                <div>
                    <mat-form-field class="in-for">
                        <mat-label>Tarea</mat-label>
                        <input matInput formControlName="tarea" autocomplete="off" type="text" />
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="in-for">
                        <mat-label>Responsable</mat-label>
                        <mat-select (selectionChange)="updateCh()" formControlName="responsable">
                            <mat-option *ngFor="let cl of miAr" [value]="cl._id"> {{cl.nombre}} {{cl.apellido}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="ct-pri-con">
                    <span class="ct-pri">Prioridad</span> <br>
                    <star-rating value="{{proVetor}}" [totalstars]='5' checkedcolor="gold" uncheckedcolor="gray"
                        size="24px" readonly="false" (rate)="onRate($event)"></star-rating>
                </div>
                <div>


                    <mat-form-field class="cfb-color">
                        <mat-label>Color:</mat-label>
                        <input matInput formControlName="colora" autocomplete="off" type="color" />
                    </mat-form-field>
                </div>
            </div>

            <div class="ct-for-b">
                <div>
                    <mat-form-field class="in-for">
                        <mat-label>Prelación:</mat-label>
                        <mat-select formControlName="pre" (selectionChange)="evaPre($event.value)" multiple>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping._id_pre">{{topping.cit}} -
                                {{topping.nombre}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="ct-bt-s">
                    <mat-form-field class="in-for">
                        <mat-label>Fecha:</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="minDate">
                            <input matStartDate formControlName="start" placeholder="Desde">
                            <input matEndDate formControlName="end" placeholder="Hasta">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                        </mat-error>
                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>

                    </mat-form-field>
                </div>

                <div class="ct-bt-s">
                    <mat-form-field class="mat-form-field-filtro">
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="status_acr">
                            <mat-option *ngFor="let sl of obsAllSta | async" [value]="sl._id"> {{sl.nam}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="ct-for-bf">
                <div>
                    <mat-form-field class="in-for-des">
                        <mat-label>Descripción</mat-label>
                        <input matInput formControlName="des" autocomplete="off" type="text" />
                    </mat-form-field>
                </div>
                <div class="ct-bt">
                    <button (click)="add();" class="btn" color="primary" mat-raised-button mat-button>
                        <mat-icon matListIcon>update</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>

<ng-template #nodata>
    <div class="ct-for">
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '90%',
                'height': '40px',
                'pandding':'5px',
                'background-color': '#daddd8'
            }">
            </ngx-skeleton-loader>
        </div>
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '90%',
                'height': '40px',
                'pandding':'5px',
                'background-color': '#daddd8'
            }">
            </ngx-skeleton-loader>
        </div>
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '90%',
                'height': '40px',
                'pandding':'5px',
                'background-color': '#daddd8'
            }">
            </ngx-skeleton-loader>
        </div>
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '90%',
                'height': '40px',
                'pandding':'5px',
                'background-color': '#daddd8'
            }">
            </ngx-skeleton-loader>
        </div>
    </div>

    <div class="ct-for-b">
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '90%',
            'height': '40px',
            'pandding':'5px',
            'background-color': '#daddd8'
        }">
            </ngx-skeleton-loader>
        </div>
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
                'border-radius': '5px', 
                'width': '90%',
                'height': '40px',
                'pandding':'5px',
                'background-color': '#daddd8'
            }">
            </ngx-skeleton-loader>
        </div>
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '90%',
            'height': '40px',
            'pandding':'5px',
            'background-color': '#daddd8'
        }">
            </ngx-skeleton-loader>
        </div>
    </div>

    <div class="ct-for-bf">
        <div>
            <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '98%',
            'height': '40px',
            'pandding':'5px',
            'background-color': '#daddd8'
        }">
            </ngx-skeleton-loader>
        </div>
        <div >
            <ngx-skeleton-loader count="1" [theme]="{ 
            'border-radius': '5px', 
            'width': '50%',
            'height': '40px',
            'pandding':'5px',
            'text-align':'center',
            'background-color': '#daddd8'
        }">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-template>


</div>




<ng-template #bx>
    <div class="ct-bes">
        <div class="ct-title">Por favor espere...</div>
        <div class="ct-progreso">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="ct-sub-title">
            <span class="ct-rf"> Recalculando fechas </span>
        </div>
    </div>
</ng-template>