import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProyectoService } from "../../../services/proyecto.service";
import { ProductService } from "../../../services/product.service";
import { UnidadNegocio } from "../../../services/unidadNegocio.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ChannelService } from "./../../../services/channel.service";

interface HtmlInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

@Component({
    selector: 'app-add-producto',
    templateUrl: './add-producto.component.html',
    styleUrls: ['./add-producto.component.css'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
    }]
})
export class AddProductoComponent implements OnInit {

    aFormGroup: FormGroup;
    hide = true;
    file: File;
    ima64: String | ArrayBuffer;
    ima64Decode: String | ArrayBuffer;
    photoSelected: String | ArrayBuffer;
    id_parte = new FormControl('', [Validators.required]);
    nombre = new FormControl('', [Validators.required]);
    ima = new FormControl('');
    unidad_negocio = new FormControl('', [Validators.required]);
    tipo_proyecto = new FormControl('', [Validators.required]);
    canal = new FormControl('', [Validators.required]);
    tipos = [];
    unidadNegocio = [];
    newProducto = {}
    obs = new BehaviorSubject<any>(null);

    constructor(
        private channelService:ChannelService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private fb: FormBuilder,
        public proyectoService: ProyectoService,
        public unidadNegocioService: UnidadNegocio,
        private productoService: ProductService,
        public dialogRef: MatDialogRef<AddProductoComponent>,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: '') {

        this.aFormGroup = this._formBuilder.group({
            canal: ['', Validators.required],
            cod_epicor: ['', Validators.required],
            nombre: ['', Validators.required],
            ima: [''],
            unidad_negocio: ['', Validators.required],
            des: ['', Validators.required],
            pre: ['', Validators.required],
            ubox: [''],
            upc: [''],
            moneda: [''],
            cbox: [''],
            cbag: [''],
        });



    }

    ngOnInit() {



        this.getAllCanal();
        this.getAllUnidadNegocio();

    }

    openSnackBar(message: string, tipo: string) {
        this._snackBar.open(message, '', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: [tipo]
        });
    }

    onClose() {
        this.dialogRef.close();
    }

    onFileSelected(event: HtmlInputEvent): void {
        if (event.target.files && event.target.files[0]) {
            this.file = <File>event.target.files[0];
            const reader = new FileReader();
            reader.onload = e => this.photoSelected = reader.result;
            reader.readAsDataURL(this.file);
            reader.onload = () => {
                this.ima64 = reader.result;
                this.photoSelected = reader.result;
            };
        }
    }



    getAllCanal() {
        this.channelService.getAllChannel().subscribe(resp => {
          this.obs.next(resp);
        });
      }
    

 
    getAllUnidadNegocio() {
        this.unidadNegocioService.getAllUnidadNegocio()
            .subscribe(
                resp => {
                    this.unidadNegocio = resp.UnidadNegocioRespo;
                });
    }

    add() {
        const dateNa = new Date();
        const ima = this.aFormGroup.get('ima').value;
        // if (!ima) {
        // this.openSnackBar("Selecciona una imagen para el producto", 'alert');
        // }
        // else 


        if (this.aFormGroup.valid) {
            const cod_epicor = this.aFormGroup.get('cod_epicor').value;
            const nombre = this.aFormGroup.get('nombre').value;
            const canal = this.aFormGroup.get('canal').value;
            const unidad_negocio = this.aFormGroup.get('unidad_negocio').value;
            const des = this.aFormGroup.get('des').value;
            const pre = this.aFormGroup.get('pre').value;
            const upc = this.aFormGroup.get('upc').value;
            const ubox = this.aFormGroup.get('ubox').value;
            const moneda = this.aFormGroup.get('moneda').value;
            const ima = this.aFormGroup.get('ima').value;
            const cbox = this.aFormGroup.get('cbox').value;
            const cbag = this.aFormGroup.get('cbag').value;
            this.newProducto = {
                numparte: cod_epicor,
                nombre: nombre,
                descripcion: des,
                presentacion: pre,
                canal: canal,
                unidad: unidad_negocio,
                upc: upc,
                moneda: moneda,
                ubox: ubox,
                cbox: cbox,
                cbag: cbag,
                imagen: this.ima64
            }


            this.productoService.registroProducto(this.newProducto)
                .subscribe(
                    res => {
                        this.onClose();
                        this.openSnackBar(res.status, 'ok');
                        this.productoService.productoSer$.emit();
                    },
                    err => {
                        this.openSnackBar(err.error.error, 'alert');
                    });
        }
    }

    decodeBase64(ima64Endode) {
        this.photoSelected = ima64Endode;
    }

    getErrorMessageCodEpicor() {
        if (this.id_parte.hasError('required')) {
            return 'Se requiere el Codigo EPICOR';
        }
    }

    getErrorMessageNombre() {
        if (this.nombre.hasError('required')) {
            return 'Se requiere el nombre del producto';
        }
    }

    getErrorMessagePre() {
        if (this.nombre.hasError('required')) {
            return 'Se requiere la presentacion del producto';
        }
    }

    getErrorMessageDes() {
        if (this.nombre.hasError('required')) {
            return 'Se requiere la descripcion del producto';
        }
    }

    getErrorMessageCanal() {
        if (this.tipo_proyecto.hasError('required')) {
            return 'Se requiere el Canal';
        }
    }

    getErrorMessageUnidad() {
        if (this.unidad_negocio.hasError('required')) {
            return 'Se requiere la descripcion de la Unidad';
        }
    }


}
