import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { EnviosService } from "../../../services/envios.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-table-productos-envios',
  templateUrl: './table-productos-envios.component.html',
  styleUrls: ['./table-productos-envios.component.css']
})
export class TableProductosEnviosComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() op: number;
  @Input() ci: String;
  @Input() idcliente: String;
  constructor(
    private enviosService: EnviosService,
    public datepipe: DatePipe,
  ) { }
  dataSource = new MatTableDataSource<any>();
  dataSources = new MatTableDataSource<any>();
  displayedColumns: string[] = ['pro', 'qty', 'uni'];
  displayedColumnss: string[] = ['pro', 'qty'];
  ngOnInit(): void {
    this.getAllProductoEnvio();
    this.getAllEstadoFind();
  }

  getAllProductoEnvio() {
    this.enviosService.getAllProductoEnvio({ op: this.op, ci: this.ci, _id_cliente: this.idcliente }).subscribe(
      resp => {

        this.dataSource.data = resp.segProd;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  getAllEstadoFind() {

    this.enviosService.getAllEstadoFind({ op: this.op, ci: this.ci, _id_cliente: this.idcliente }).subscribe(
      resp => {

        this.dataSources.data = resp.segObs;
        this.dataSources.paginator = this.paginator;
        this.dataSources.sort = this.sort;

      });
  }


  formatDate(fecha) {
    return fecha.substr(0,10);
  }

  formatNumber(value) {
    return value.toLocaleString();
  }

  getTotalgb() {
    return this.dataSource.data.map(t => t.volumen).reduce((acc, value) => acc + value, 0);
  }


}
