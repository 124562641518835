import { Component, Input, OnInit } from '@angular/core';
import { ProductService } from "./../../../services/product.service";
import * as moment from 'moment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.css']
})
export class ListProductComponent implements OnInit {
  @Input() idCli: string;
  data: any;
  show: boolean;
  listRango: any=[];
  tTol: any;
  tTolu: any;
  criterioCh: FormGroup;

  constructor(
    private productService: ProductService,
    private _formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {
    
    this.setList();
    var y = moment().format('YYYY');
    this.crearFormCh(y);
    this.listProduct(this.idCli, y);
    this.listProductYear(this.idCli, y);
  }

  crearFormCh(y) {
    this.criterioCh = this._formBuilder.group({
      canal: new FormControl(Number(y)),
    });
  }
  setList() {
    var y = moment().format('YYYY');
    for (let i = 2017; i <= Number(y); i++) {
      this.listRango.push({ vYear: i });
    }
    this.listRango = this.listRango.sort(function (a, b) {
      if (a.vYear < b.vYear) {
        return 1;
      }
      if (a.vYear > b.vYear) {
        return -1;
      }
      return 0;
    });
  }


  getColorl(i) {

    const val = i % 2;
    if (val === 0) {
      return "divRowb";
    } else {
      return "divRoww";
    }

  }

  listProduct(_id_cliente, vYear) {
    this.productService.listProduct({ _id_cliente: _id_cliente, vYear: vYear }).subscribe(
      resp => {
        this.data = resp;
        const numRe = resp.length;
        if (numRe > 0) {
          this.show = true;
        } else {
          this.show = false;
        }
      });
  }

  updateAn() {
    const vYear = this.criterioCh.controls.canal.value;


    this.listProduct(this.idCli, vYear);
    this.listProductYear(this.idCli, vYear);

  }


  listProductYear(_id_cliente, vYear) {
    this.productService.listProductYear({ _id_cliente: _id_cliente, vYear: vYear }).subscribe(
      resp => {

        if (resp) {
          this.tTol = resp.tol;
          this.tTolu = resp.tolu;
        } else {
          this.tTol = 0;
          this.tTolu = 0;
        }
      });
  }



}
