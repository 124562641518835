import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { AreaService } from 'src/app/services/area.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM DDD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMM DDD',
  },
};

@Component({
  selector: 'app-task-add-sh',
  templateUrl: './task-add-sh.component.html',
  styleUrls: ['./task-add-sh.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TaskAddShComponent implements OnInit {
  formPurchaseOrder: FormGroup;
  obsAreaSv: any;

  constructor(private areaService: AreaService) {}
  @Input() vAr: string;

  ngOnInit(): void {
    this.getTaskAllShchemes();
    this.createForm();
  }

  createForm() {
    this.formPurchaseOrder = new FormGroup({
      esquema: new FormControl('', [Validators.required]),
      fechaStar: new FormControl('', [Validators.required]),
    });
  }

  getTaskAllShchemes() {
    this.areaService.getTaskAllShchemes().subscribe((resp) => {
      this.obsAreaSv = resp;
    });
  }

  ptakOff() {
    this.areaService.cBeem$.emit(false);
  }

  add() {
    if (this.formPurchaseOrder.valid) {
      const esquema = this.formPurchaseOrder.controls.esquema.value;
      const fechaStar = this.formPurchaseOrder.controls.fechaStar.value;

      let newObj = {
        vAr: this.vAr,
        vFt: fechaStar,
        vTs: esquema,
      };

      this.areaService.getMacroSchemeGlo(newObj).subscribe((resp) => {
        this.areaService.cBeem$.emit(false);
        this.areaService.oTask$.emit();
      });
    } else {
    }
  }
}

// "vTs":"62ed2e180ca7fc2b240c2274",
// "vFt":"2022-07-10",
// "vAr":"62f499319c8b9411d07ff529"
