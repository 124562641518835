import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private URL = GlobalVariable.BASE_API_URL;
    constructor(private http: HttpClient,
    private router: Router, ) { }
    public rolSer$ = new EventEmitter<any>();


    regRol(data) {
        return this.http.post<any>(this.URL + 'regRol', data);
    }

    getAllRoles() {
        return this.http.get<any>(this.URL + 'getAllRoles');
    }

    getAllRolesVendedor() {
        return this.http.get<any>(this.URL + 'getAllRolesVendedor');
    }

    updateRol(data) {
        return this.http.post<any>(this.URL + 'updateMaquila', data);
    }

    delRol(id) {
        return this.http.post<any>(this.URL + 'delRol', id);
    }



    
}
