import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerService } from "./../../../services/customer.service";
@Component({
  selector: 'app-ima-cliente',
  templateUrl: './ima-cliente.component.html',
  styleUrls: ['./ima-cliente.component.css']
})
export class ImaClienteComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);

  @Input() idcliente: string;
  @Input() classCss: string;

  datas: boolean;
  constructor(
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.datas = true;
    this.imaIdCliente(this.idcliente);
  }

  imaIdCliente(idcliente) {
    this.customerService.imaIdCliente({ id_epicor: idcliente }).subscribe((resp) => {
      this.obs.next(resp[0].ima_cliente)
      this.datas = false;
    });
  }

  validateIma(valor) {
    
    if (valor != "null")
      return valor;
    else
      return "assets/ima/no-ima.png";
  }
}
