<div *ngIf="grafCli;else jose">
    <div class="g1">
        <div class="g2">
            <div class="g3">
                <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="multi" [gradient]="gradient"
                    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                    [animations]="animations" >
                </ngx-charts-bar-vertical-stacked>
            </div>
        </div>
    </div>
</div>

<ng-template class="cards-cli" #jose>
    <div class="block-wiat">
        <ngx-skeleton-loader *ngFor="let iWiat of wiat" count="1" [theme]="iWiat">
        </ngx-skeleton-loader>
    </div>

</ng-template>