<div class="contenedor">
    <div class="item">
        <!-- <div class="trs vp">Ejecutivo:</div> -->
        
        <mat-grid-list cols="10" rowHeight="150px">
            
       
            
            
            <mat-grid-tile colspan="4">
                <img mat-card-avatar class="imgfluid" [src]="this.datosEjecutivos.imagen || 'assets/ima/no-ima.png'">
            </mat-grid-tile>
            <mat-grid-tile colspan="6">
                <mat-card class="mat-card mat-elevation-z0">
                    <mat-card-title class="tit">{{ this.datosEjecutivos.nombre }} {{ this.datosEjecutivos.apellido }}
                    </mat-card-title>
                    <mat-card-subtitle>{{ this.datosEjecutivos.email }}
                    </mat-card-subtitle>
                    <mat-card-subtitle>Ejecutivo Asignado (Actual)</mat-card-subtitle>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <div class="item">
        <div>
      
            <div class="table-container">
            <mat-grid-list cols="10" rowHeight="55px" *ngFor="let data of arraydata">
                
                <mat-grid-tile colspan="2" rowspan="2" >
                    <div class="cnum">8</div>
                    <br>
                    <div class="trs vp">Clientes</div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="2">
                    <app-ima-cliente [idcliente]="data._id_cliente.id_epicor"></app-ima-cliente>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                    <div class="info-razon">
                        {{ data._id_cliente.razon_social }}
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div> 

            <!-- <div class="table-container">
                <div class="pan" *ngFor="let data of arraydata">
                    <div class="dc">
                        <div class="ima">
                            <app-ima-cliente [idcliente]="data._id_cliente.id_epicor"></app-ima-cliente>
                        </div>
                        <div class="nom">
                            {{ data._id_cliente.razon_social }}
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>   
</div>


<!-- 

    <mat-grid-tile colspan="4">

        <div>
            <div class="trs vp">Clientes:</div>
            <div class="table-container">
                <div class="pan" *ngFor="let data of arraydata">
                    <div class="dc">
                        <div class="ima">
                            <app-ima-cliente [idcliente]="data._id_cliente.id_epicor"></app-ima-cliente>
                        </div>
                        <div class="nom">
                            {{ data._id_cliente.razon_social }}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </mat-grid-tile>

</mat-grid-list> -->