import { Component, OnInit } from '@angular/core';
import { EnviosService } from "../../services/envios.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-envios',
  templateUrl: './envios.component.html',
  styleUrls: ['./envios.component.css']
})
export class EnviosComponent implements OnInit {
  obs = new BehaviorSubject<any>(null);
  public lista = ["IN PROGRESS", "ON THE WAY", "UNLOADING", "DELIVERED"];
  panelOpenState = false;
  public searchText: any;
  displayedColumns: string[] = ['pro', 'qty'];
  displayedColumnss: string[] = ['info', 'valor'];
  date: Date;
  selecm = new FormControl();
  datPDF: any[] = [];
  loading: boolean;
  dataSource: MatTableDataSource<null>;
  pdfb: boolean;
  constructor(
    private enviosService: EnviosService,
    public datepipe: DatePipe,
    private _snackBar: MatSnackBar,
  ) {
  }
  ngOnInit(): void {
    this.loading = true;
    this.selecm.setValue(1);





    this.getAllEnvioCri( { ops: "ENTREGADO" });
    this.refresh();
    this.getAllEnvioPDF( { ops: "ENTREGADO" });



  }


  updateAns(event) {
    let objq = {};
    const tipo = this.selecm.value;
    if (tipo === 2 || tipo === "") {
      objq = { ops: "" };
    } else {
      objq = { ops: "ENTREGADO" };
    }


    this.getAllEnvioCri(objq);
    this.getAllEnvioPDF(objq);
  }
  refresh() {
    this.enviosService.envlcg$.subscribe(() => {
      this.getAllEnvioCri({ ops: "ENTREGADO" });
    });
  }
  onDelAsig(_id, op, ci, _id_cliente) {
    this.enviosService.rmdelallEnvio({ _id: _id, op: op, ci: ci, _id_cliente: _id_cliente }).subscribe(
      resp => {
        this.enviosService.envlcg$.emit();
        this.openSnackBar(resp.status, 'ok');
      });
  }
  openSnackBar(message: string, tipo: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [tipo]
    });
  }
  getAllEnvioCri(data) {
    this.enviosService.getAllEnvioCri(data).subscribe(
      resp => {


        this.obs.next(resp);
        if (resp.length > 0) {

        }

        this.loading = false;
      });
    this.loading = false;
  }
  formatDate(fecha) {
    return fecha.substr(0,10);
  }
  validateIma(valor) {
    const v = valor;
    if (v !== 'null' && v !== undefined) return valor;
    else return 'assets/ima/no-ima.png';
  }
  formatRazonSocial(val) {
    var rzo: String;
    if (val.length > 50) {
      rzo = val.substr(0, 50) + "...";
    } else {
      rzo = val;
    }
    return rzo;
  }
  formatDateFull(fecha) {
    this.date = new Date();
    let latest_date = this.datepipe.transform(fecha, 'yyyy-MM-dd HH:mm:ss');
    return latest_date;
  }
  // async generador(data) {
  //   var doc = new jsPDF('l', 'pt', 'letter');
  //   doc.setFontSize(10);
  //   var logo = new Image();
  //   logo.src = './assets/ima/lappro.png';
  //   doc.addImage(logo, 'JPEG', 50, 30, 80, 35);
  //   doc.setFontSize(10);
  //   doc.text('LISTADO DE ENVIOS DETALLADOS', 140, 40);
  //   doc.setFontSize(8);
  //   doc.text(`Fecha: ${this.formatDateFull(new Date().toISOString())}`, 140, 55);
  //   doc.setLineWidth(0.5);
  //   doc.line(50, 70, 740, 70);
  //   doc.autoTable({
  //     headStyles: { fillColor: [30, 40, 49] },
  //     margin: { top: 80 },
  //     // head: [['CÓDIGO', 'OPERACIÓN', 'RAZÓN SOCIAL', 'SALIDA', 'LLEGADA', 'DESTINO', 'ESTADO']],
  //     head: [['OPERACIÓN', 'RAZÓN SOCIAL', 'DESTINO', 'SALIDA', 'LLEGADA', 'ESTADO']],
  //     body: data
  //   })
  //   const addFooters = doc => {
  //     const pageCount = doc.internal.getNumberOfPages()
  //     doc.setFont('helvetica', 'italic')
  //     doc.setFontSize(8)
  //     for (var i = 1; i <= pageCount; i++) {
  //       doc.setPage(i)
  //       doc.text('Pag ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width - 100, 600, {
  //         align: 'center'
  //       })
  //     }
  //   }
  //   addFooters(doc);
  //   doc.save(`${this.formatDateFull(new Date().toISOString())}_envios.pdf`);

  //   this.openSnackBar("TU REPORTE ESTA LISTO...", 'info');
  // }

  // procesamiento() {
  //   var data = [];
  //   let matriz: any[];
  //   var total: number;
  //   matriz = this.datPDF.map((val) => {

  //     total = 0;
  //     data.push([
  //       val[1],
  //       val[2],
  //       val[5],
  //       val[3],
  //       val[4],
  //       val[6],
  //     ]);
  //     data.push(['', 'PRODUCTOS:', 'VOLUMEN', 'UNIDAD', '']);
  //     val[7].map((va) => {
  //       data.push([
  //         '',
  //         va.nombre,
  //         this.formatNumber(va.volumen),
  //         va.unidad
  //       ]);
  //       total += va.volumen;
  //     });
  //     data.push(['', 'TOTAL:', this.formatNumber(total), '', '']);
  //   });
  //   this.generador(data);
  // }
  // repPdfCompleto() {

  //   // this.filterMatriz("DANTRADE");
  //   this.openSnackBar("GENERANDO REPORTE...", 'info');
  //   this.procesamiento();
  // }
  formatNumber(value) {
    return value.toLocaleString();
  }
  getAllEnvioPDF(data) {

    this.pdfb=true;

    this.datPDF.length=0;
    this.enviosService.getAllEnvioPDF(data).subscribe((resp) => {
      this.dataSource = new MatTableDataSource(resp);
      let dar = resp.map((val) => {
        this.datPDF.push([
          val.ci,
          val.op,
          val.razon,
          this.formatDate(val.fechas),
          this.formatDate(val.fechall),
          val.destino,
          val.estado,
          val.productos
        ],
        );
        return val;
      });

      this.pdfb=false;


    });



  }

  filterMatriz(name: String) {

    const found = this.datPDF.filter(element => element[2] === name);



  }


  applyFilter(event: Event) {
    this.datPDF.length = 0;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    var resp: any[] = this.dataSource.filteredData;
    resp.map((val) => {
      this.datPDF.push([
        val.ci,
        val.op,
        val.razon,
        this.formatDate(val.fechas),
        this.formatDate(val.fechall),
        val.destino,
        val.estado,
        val.productos
      ],
      );
      return val;
    });



  }

}
