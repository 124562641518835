<div class="ct-tra" *ngIf="bttn>0">
  <div>
    <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
      <mat-chip class="example-box chip" cdkDrag *ngFor="let ens of selection.selected" [removable]="removable"
        (removed)="remove(ens)">
        {{ens.nombre}}
        <mat-icon class="ico-rs" matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <div>
    <button class="dbtnas" [disabled]="!btt" color="accent" mat-raised-button mat-button (click)="onDialogTras()">
      <mat-icon>swap_horiz</mat-icon> Trasladar a <span *ngIf="bttn>0"> ({{bttn}}) </span>
    </button>
  </div>
</div>

<div *ngIf="showp; else nodatq">

  <mat-form-field class="mat-form-field-filtro" appearance="fill">
    <mat-label>Filtro</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ejemplo: Profusa Product" autocomplete="off" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="ima">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <div class="ct-imp">
          <app-ima-producto matTooltip="" [idproducto]="element._id"></app-ima-producto>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="cod">
      <th mat-header-cell *matHeaderCellDef> Cod Epicor </th>
      <td mat-cell *matCellDef="let element"> {{element.id_epicor}} </td>
    </ng-container>

    <ng-container matColumnDef="fechar">
      <th mat-header-cell *matHeaderCellDef> Fecha Registro </th>
      <td mat-cell *matCellDef="let element"> {{element.fechar}} </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef> Producto </th>
      <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="listCli">
      <th mat-header-cell *matHeaderCellDef> Cliente(s) </th>
      <td mat-cell *matCellDef="let element">
        <app-list-customer-general-product [vPro]="element._id"></app-list-customer-general-product>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [length]="5" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</div>
<ng-template #nodatq>
  <div class="divRespo">
    <app-info-data [pd]="" [mensaje]="'Todos los productos tienen un canal asignado'"></app-info-data>
  </div>
</ng-template>