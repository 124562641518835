<div class="conte-pro">
    <div class="ct-model">
        <div class="item name-c">
            <div class="titulo-row-b">
                <button mat-icon-button>
                    <mat-icon>list</mat-icon>
                </button>
                Ordenes de compras
            </div>
        </div>
        <div class="item name-c">
            <form [formGroup]="criterioCh" method="post">
                <mat-form-field class="mat-form-field-filtro">
                    <mat-label>Canal:</mat-label>
                    <mat-select (selectionChange)="updateCh()" formControlName="canal">
                        <mat-option [value]=""> TODOS </mat-option>
                        <mat-option *ngFor="let cl of obsCh | async" [value]="cl._id"> {{cl.canal}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
        <div class="item name-c">
            <form [formGroup]="criterioChvYear" method="post">
                <mat-form-field class="mat-form-field-filtro">
                    <mat-label>Año:</mat-label>
                    <mat-select (selectionChange)="updateCh()" formControlName="yeara">
                        <mat-option *ngFor="let lv of listRango" [value]="lv.vYear"> {{lv.vYear}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </div>
    <mat-divider></mat-divider>



    <div class="ct-g">
        <app-g-combo-chart [vData]="vData" [vYearOls]="vYearOls" [vYearOld]="vYearOld"></app-g-combo-chart>
    </div>


    <div *ngIf="show;else wnoda">
        <div *ngIf="ss1;else rsnoda">

    <div class="ct-p">
        <div class="columna1">
            <mat-form-field class="fiel">
                <mat-label>Filtro</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Frio Frut" #input autocomplete="off">

            </mat-form-field>
        </div>
        <div class="columna2">

            <button class="btn" class="mat-list-item" color="primary" mat-raised-button mat-button
                [routerLink]="['/purchase_order_add']">
                <mat-icon matListIcon>add</mat-icon>
            </button>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="table-data mat-elevation-z8">

        <ng-container matColumnDef="ci">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CI </th>
            <td mat-cell *matCellDef="let element"> {{element.ci}} </td>
        </ng-container>

        <ng-container matColumnDef="oc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> OC </th>
            <td mat-cell *matCellDef="let element"> {{element.oc}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha_oc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha OC </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_oc}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha_em">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Embarque </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_em}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha_ent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Entrega </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_ent}} </td>
        </ng-container>

        <ng-container matColumnDef="razon_social">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.razon_social}} </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
            <td mat-cell *matCellDef="let element"> {{element.value | number: '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="moneda">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let element"> {{element.moneda}} </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element">

                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
                        mat-menu-item routerLink="/purchase_order_up/{{element.ci}}">
                        <mat-icon>visibility</mat-icon>
                        <span>Ver Detalle</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button matTooltip="¿Seguro que deseas borrar este proyecto?" matTooltipClass="example-tooltip-red"
                        mat-menu-item (click)="onDelAsig(element.ci)">
                        <mat-icon>delete_forever</mat-icon>
                        <span>Eliminar</span>
                    </button>

                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>


<ng-template #rsnoda>

  <app-info-data [mensaje]="'No hay ordenes de compras registradas'" [pd]=""> </app-info-data>

</ng-template>


</div>
<ng-template #wnoda>

  <div class="ct-cadew">
    <ngx-skeleton-loader count="5" [theme]="{ 
          'border-radius': '5px', 
          'width': '900px',
          'height': '30px',
          'background-color': '#daddd8'
      }">
    </ngx-skeleton-loader>
  </div>
</ng-template>


<mat-paginator [length]="3" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
</div>

