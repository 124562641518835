<div *ngIf="loadinga && loadingb;else nodata">

    <mat-accordion class="matc-p">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="matc-p">
                <mat-panel-title>

                    <div class="ct-model">
                        <div class="item ima-cli">
                            <app-ima-cliente [idcliente]="vEpicor"></app-ima-cliente>
                        </div>
                        <div class="item name-cli">
                            {{ vNombre }}

                            <br>
                            <mat-divider class="resp-d"></mat-divider>

                            <span *ngIf="dataresp" class="resp-dato">{{dattres.nombre}} {{dattres.apellido}}</span>


                        </div>

                        <div class="item dat-cli">

                            <div *ngFor="let vEnv of obs | async" class="ct-global">
                                <div class="ctg-pre">
                                    <div class="mat-card-pre">
                                        <div class="ct-pre">
                                            <div class="ct-pre-i">
                                                <span>PRE {{vEnv.year}} {{vEnv.mes}}</span>
                                            </div>

                                            <div class="ct-pre-ka">
                                                <div *ngIf="vEnv.pre.premKg>0">
                                                    {{vEnv.pre.premKg | number: '1.2-2'}}
                                                    <span class="ct-den">KGS.</span>
                                                </div>
                                                <div *ngIf="vEnv.pre.premLb>0" class="ct-pre-ka">
                                                    {{vEnv.pre.premLb | number:
                                                    '1.2-2'}} <span class="ct-den">LBS.</span>
                                                </div>
                                                <div *ngIf="vEnv.pre.premPz>0" class="ct-pre-ka">
                                                    {{vEnv.pre.premPz | number:
                                                    '1.2-2'}} <span class="ct-den">PZA.</span>
                                                </div>
                                            </div>


                                            <div class="ct-pre-deno">

                                                <div *ngIf="vEnv.pre.premUsd>0" class="ct-pre-usd">
                                                    {{vEnv.pre.premUsd | number: '1.2-2'}}
                                                    <span class="ct-den">USD</span>
                                                </div>
                                                <div *ngIf="vEnv.pre.premUsd>0 && vEnv.pre.premMxn==0"
                                                    class="ct-pre-usdc">
                                                    <span>{{vEnv.pre.vTc | number: '1.2-2'}}</span>
                                                </div>
                                                <div *ngIf="vEnv.pre.premUsd>0" class="ct-pre-usdttc">
                                                    {{ vEnv.pre.vTPUsdTc | number: '1.2-2'}}
                                                    <span class="ct-den">MXN</span>
                                                </div>

                                                <div *ngIf="vEnv.pre.premMxn>0" class="ct-pre-mxn">
                                                    {{vEnv.pre.premMxn | number:'1.2-2'}} <span
                                                        class="ct-den">MXN</span>
                                                </div>



                                            </div>
                                            <div class="ct-pre-total">{{vEnv.pre.vTPMxn | number: '1.2-2'}} <span
                                                    class="ct-den">MXN</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ctg-gra">
                                    <app-pre-mensual-ind [value]="getCal(vEnv.pre.vTPMxn,vEnv.ven.vTVMxn)">
                                    </app-pre-mensual-ind>

                                    <div class="ct-btn-p">
                                        <span class="ct-btn-send" (click)="onDialogSend(
                                            vNombre,
                                            vEpicor,
                                            vMes,
                                            vYear,
                                            vD,
                                            vH,
                                            vEnv.pre.vTPMxn,
                                            vEnv.ven.vTVMxn
                                            );">@</span>
                                    </div>

                                </div>

                                <div class="ctg-ven">


                                    <div class="mat-card-pre">
                                        <div class="ct-ven c{{vEnv.ven.vTVMxn}}">
                                            <div class="ct-ven-i">
                                                <span>VEN {{vEnv.year}} {{vEnv.mes}}</span>
                                            </div>

                                            <div class="ct-ven-ka">

                                                <div *ngIf="vEnv.ven.venKg>0" class="ct-ven-k"> <span
                                                        class="ct-den">KGS.
                                                    </span> {{vEnv.ven.venKg | number: '1.2-2'}}
                                                </div>
                                                <div *ngIf="vEnv.ven.venLb>0" class="ct-ven-l"><span class="ct-den">LBS.
                                                    </span> {{vEnv.ven.venLb | number: '1.2-2'}}
                                                </div>
                                                <div *ngIf="vEnv.ven.venPz>0" class="ct-ven-p"><span class="ct-den">PZA.
                                                    </span> {{vEnv.ven.venPz | number: '1.2-2'}}
                                                </div>
                                            </div>
                                            <div class="ct-ven-deno">
                                                <div *ngIf="vEnv.ven.venmUsdtoMxn>0" class="ct-ven-usdtomxn"><span
                                                        class="ct-den">MXN</span> {{ vEnv.ven.venmUsdtoMxn |
                                                    number: '1.2-2'}}
                                                </div>
                                                <div *ngIf="vEnv.ven.venmMxn>0" class="ct-ven-mxn"><span
                                                        class="ct-den">MXN</span> {{vEnv.ven.venmMxn | number:
                                                    '1.2-2'}} </div>
                                                <div *ngIf="vEnv.ven.venmUsd>0" class="ct-ven-usd"> <span
                                                        class="ct-den">USD</span> {{vEnv.ven.venmUsd | number:
                                                    '1.2-2'}}</div>


                                            </div>
                                            <div *ngIf="vEnv.ven.vTVMxn>0;else ctot" class="ct-ven-total"><span
                                                    class="ct-den">MXN</span> {{vEnv.ven.vTVMxn | number:
                                                '1.2-2'}}</div>
                                            <ng-template #ctot>
                                                <div class="ct-ven-total"><span class="ct-den">MXN</span> 0.00</div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-pre-mensual-producto-list [tCop]="tCop" [vYear]="vYear" [vCliente]="vCliente" [vEpicor]="vEpicor"
                [vD]="vD" [vH]="vH" [vMes]="vMes" [vCh]="vCh">

                ></app-pre-mensual-producto-list>
        </mat-expansion-panel>

        <mat-divider></mat-divider>
    </mat-accordion>
</div>
<ng-template #nodata>




    <div class="ct-model">
        <div class="item ima-cli">
            <ngx-skeleton-loader count="1" [theme]="{ 
                                'border-radius': '5px', 
                                'width': '60px',
                                'padding':'1px',
                                'margin':'1px',
                                'height': '60px',
                                'background-color': '#daddd8'}">
            </ngx-skeleton-loader>
        </div>
        <div class="item name-cli">
            <ngx-skeleton-loader count="2" [theme]="{ 
                                'border-radius': '5px', 
                                'width': '100%',
                                'padding':'1px',
                                'margin':'1px',
                                'height': '20px',
                                'background-color': '#daddd8'}">
            </ngx-skeleton-loader>
        </div>
        <div class="item dat-cli">

            <div class="ct-global">
                <div class="ctg-pre">
                    <div class="mat-card-pre">
                        <div class="ct-pre">
                            <div class="ct-pre-i">
                                <ngx-skeleton-loader count="1" [theme]="{ 
                                                    'border-radius': '5px', 
                                                    'width': '50px',
                                                    'padding':'2px',
                                                    'margin':'1px',
                                                    'height': '20px',
                                                    'background-color': '#daddd8'}">
                                </ngx-skeleton-loader>
                            </div>

                            <div class="ct-pre-ka">
                                <div>
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-pre-ka">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-pre-ka">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>


                            <div class="ct-pre-deno">



                                <div class="ct-pre-mxn">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '40px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>

                                <div class="ct-pre-usd">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '10px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-pre-usdc">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '10px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-pre-usdttc">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>

                            </div>
                            <div class="ct-pre-total">
                                <ngx-skeleton-loader count="1" [theme]="{ 
                                                    'border-radius': '5px', 
                                                    'width': '100px',
                                                    'padding':'1px',
                                                    'margin':'1px',
                                                    'height': '10px',
                                                    'background-color': '#daddd8'}">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ctg-gra">

                    <ngx-skeleton-loader count="1" [theme]="{ 
                                        'border-radius': '5px', 
                                        'width': '50px',
                                        'padding':'1px',
                                        'margin':'1px',
                                        'height': '30px',
                                        'background-color': '#daddd8'}">
                    </ngx-skeleton-loader>

                </div>

                <div class="ctg-ven">


                    <div class="mat-card-pre">
                        <div class="ct-ven ">
                            <div class="ct-ven-i">
                                <ngx-skeleton-loader count="1" [theme]="{ 
                                                    'border-radius': '5px', 
                                                    'width': '50px',
                                                    'padding':'1px',
                                                    'margin':'1px',
                                                    'height': '20px',
                                                    'background-color': '#daddd8'}">
                                </ngx-skeleton-loader>
                            </div>

                            <div class="ct-ven-ka">

                                <div class="ct-ven-k">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-ven-k">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div *class="ct-ven-l">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-ven-p">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                            <div class="ct-ven-deno">
                                <div class="ct-ven-mxn">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-ven-usd">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '10px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-ven-usd">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '10px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>
                                <div class="ct-ven-usdtomxn">
                                    <ngx-skeleton-loader count="1" [theme]="{ 
                                                        'border-radius': '5px', 
                                                        'width': '80px',
                                                        'padding':'1px',
                                                        'margin':'1px',
                                                        'height': '10px',
                                                        'background-color': '#daddd8'}">
                                    </ngx-skeleton-loader>
                                </div>

                            </div>
                            <div class="ct-ven-total">
                                <ngx-skeleton-loader count="1" [theme]="{ 
                                                    'border-radius': '5px', 
                                                    'width': '100px',
                                                    'padding':'1px',
                                                    'margin':'1px',
                                                    'height': '10px',
                                                    'background-color': '#daddd8'}">
                                </ngx-skeleton-loader>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-divider></mat-divider>
</ng-template>